// @flow

const CKDReportStrings = {
  WHAT_IS_CKD_TEXT1:
    'Chronic kidney disease, also called chronic kidney failure, ' +
    'involves a gradual loss of kidney function. Your kidneys filter wastes and excess fluids from your blood, ' +
    'which are then removed in your urine. Advanced chronic kidney disease can cause dangerous levels of fluid, ' +
    'electrolytes and wastes to build up in your body. ' +
    'In the early stages of chronic kidney disease, you might have few signs or symptoms. ' +
    'You might not realize that you have kidney disease until the condition is advanced.',
  WHAT_IS_CKD_TEXT2:
    'In 2017, 697.5 million cases of CKD (all stages) were recorded worldwide, ' +
    'and about 1.2 million people died from CKD.',
  WHAT_IS_CKD_REF1:
    'GBD Chronic Kidney Disease Collaboration. Global, regional, and national burden of chronic kidney disease, 1990–2017: ' +
    'a systematic analysis for the Global Burden of Disease Study 2017. The Lancet. 13 February 2020. doi: 10.1016/S0140-6736(20)30045-3.',
  HOW_DID_WE_CALC_TEXT1:
    'OpenDNA calculates your score based on analysis of your ' +
    'genetic variants. Genetic variation is the difference in DNA ' +
    'sequences between individuals within a population. These are the ' +
    'differences in DNA that make every individual different. OpenDNA ' +
    'scans about 265 significant genetic variants and calculates a ' +
    'Polygenic Risk Score (PRS) based on the contribution of each ' +
    'genetic variant to your genetic risk for having chronic kidney disease ',
  HOW_DID_WE_CALC_TEXT2:
    'Once your PRS is calculated it is compared ' +
    'to a group of about 315,000 people of European descent to ' +
    'calculate your relative risk of developing chronic kidney ' +
    'disease compared to that population.',
  HOW_DID_WE_CALC_REF:
    'Wuttke, M., Li, Y., Li, M. et al. A catalog of genetic loci associated with kidney ' +
    'function from analyses of a million individuals. Nat Genet 51, 957–972 (2019). ' +
    'https://doi.org/10.1038/s41588-019-0407-x',
  DISCLAIMER1:
    'THIS REPORT MAY NOT INCLUDE EVERY GENETIC MARKER RELATED TO CHRONIC KIDNEY DISEASE.',
  DISCLAIMER2:
    'THIS TEST IS NOT DIAGNOSTIC, IT IS A PREDICTION BASED ON PREDISPOSITION. ' +
    'AN INCREASED RISK SCORE DOES NOT MEAN THAT A PERSON WILL DEFINITELY DEVELOP A DISEASE ' +
    'AND A DECREASED RISK SCORE DOES NOT MEAN THAT A PERSON WILL DEFINITELY NOT DEVELOP A DISEASE.',
  DISCLAIMER3:
    'THE TEST CANNOT REPLACE MEDICAL TESTING OR MEDICAL MANAGEMENT THROUGH A HEALTHCARE PROVIDER.',
  DISCLAIMER4:
    'THE RISK MAY ALSO BE INFLUENCED BY OTHER LIFESTYLE FACTORS, OTHER CLINICAL FACTORS AND ' +
    'GENETIC VARIANTS THAT ARE NOT INCLUDED IN THE ANALYSIS OR WERE NOT REPORTED PROPERLY.',
  DISCLAIMER5:
    'YOUR RISK IS COMPARED TO A POPULATION WHERE THE MAJORITY IS OF EUROPEAN DESCENT. ' +
    'THE ACCURACY OF THE RESULTS MAY VARY IF YOU HAVE A DIFFERENT BACKGROUND.',
  DISCLAIMER6:
    'TEST RESULTS SHOULD BE INTERPRETED BY A HEALTHCARE PROVIDER. MEDICAL MANAGEMENT AND ' +
    'DECISION-MAKING FOR PREVENTION PRACTICES SHOULD BE DONE IN THE CONTEXT OF THE PATIENT’S ' +
    'FULL CLINICAL HISTORY AND NOT BASED SOLELY ON THE TEST RESULTS.',
};

export default CKDReportStrings;
