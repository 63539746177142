// @flow
import { ROOT_DOMAIN } from './constants';
import { ENV } from './env_config';

export const SERVICES = {
  PATIENT: 'patient',
  FILES: 'files',
  GRS: 'grs',
  CLINGEN: 'clingen',
  CAPILOTS: 'capilots',
  USERS: 'users',
  ATHENAAPI: 'athenaapi'
};

// this allows setting different domain for each local service
export const LOCAL_DOMAINS = {
  [SERVICES.PATIENT]: 'http://localhost:5001',
  [SERVICES.FILES]: 'http://localhost:5002',
  [SERVICES.GRS]: 'http://localhost:5003',
  [SERVICES.CLINGEN]: 'http://localhost:5004',
  [SERVICES.CAPILOTS]: 'http://localhost:5008',
  [SERVICES.USERS]: 'http://localhost:5009',
  [SERVICES.ATHENAAPI]: 'http://localhost:5010'
};

export function getDomain(serviceName: string): string {
  if (process.env.REACT_APP_LOCAL_SERVICE === 'true') {
    return LOCAL_DOMAINS[serviceName];
  }

  return `https://${serviceName}.${ENV}.${ROOT_DOMAIN}`;
}

export const API_URLS = {
  GRS: {
    GRS: '/api/grs',
    SCORE: '/api/grs/score'
  },
  CLINGEN: {
    BP_PREDICT: '/api/clingen/bp/predict',
    BP_RECOMMEND: '/api/clingen/bp/recommend',
    BP_GRS_RANK: '/api/clingen/bp/grs',
    CHEMO_RISK: '/api/clingen/chemo/risk',
    CAD_POPHEALTH: '/api/clingen/population/cad',
    PATIENT_CV_SUMMARY: '/api/clingen/cv/patient/summary'
  },
  PATIENT: {
    PATIENT: '/api/patient'
  },
  CAPILOTS: {
    RISK: '/api/risk',
    GET_PATIENTS: '/api/patients',
    ADD_PATIENT: '/api/patient',
    ADD_VISIT: '/api/visit',
    UPDATE_PATIENT: '/api/patient',
    UPDATE_POTENTIAL: '/api/potential',
    INCREASE_POTENTIAL_ACCESS_COUNT: '/api/potential',
    UNION_VISIT: '/api/union',
    GET_PATIENT: '/api/patient',
    GET_PATIENT_BY_USERNAME: '/api/user',
    GET_PATIENT_BY_EXTERNAL: '/api/external',
    URL: '/api/url',
    KITID: '/api/kit',
    ASSIGN_USER: '/api/user',
    KITID_AVAILABILITY: '/api/kit/<kit>/availability',
    ADD_ORDER: '/api/order'
  },
  USERS: {
    SMS: '/api/sms',
    CHANGE_PWD: '/api/changepwd'
  },
  ATHENAAPI: {
    EMBEDDED_AUTH: '/api/embedded/auth',
    EMBEDDED_TOKEN: '/api/embedded/token',
    PATIENT: '/api/patient'
  }
};
