// @flow
import React from 'react';
import PropTypes from 'prop-types';
import {Flex, Spacer, Text} from '@chakra-ui/react';
import COLORS from "../colors";

type Props = {
  isIdentifiable: boolean,
  patientName?: ?string,
  patientNum?: ?number,
  geneticKitId: string,
  isMobile?: boolean
};

export default function PatientNameNumberKitTopPanel(props: Props) {
  return (
      <Flex color={COLORS.REPORT_TEXT_GRAY} align={"end"} fontStyle={'normal'} ml={"7%"} fontSize={14}>
        {!props.isIdentifiable &&
          <Text minW="115px" mr="3px" textAlign="left" color={COLORS.REPORT_TEXT_GRAY} fontSize={14}>
            PATIENT NUMBER:
          </Text>}
        {!props.isIdentifiable &&
          <Text textAlign="left" mr="20px">
            <b>{props.patientNum}</b>
          </Text> }
        {props.isIdentifiable &&
          <Text minW="50px" mr="3px" textAlign="left" color={COLORS.REPORT_TEXT_GRAY} fontSize={14}>
            NAME:
          </Text>}
        {props.isIdentifiable &&
          <Text textAlign="left" mr="20px">
            <b>{props.patientName}</b>
          </Text> }
        <Text minW="105px" mr="3px" textAlign="left" color={COLORS.REPORT_TEXT_GRAY} fontSize={14}>
          GENETIC KIT ID:
        </Text>
        <Text textAlign="left">
          <b>{props.geneticKitId}</b>
        </Text>
        <Spacer/>
      </Flex>
  );
}

PatientNameNumberKitTopPanel.propTypes = {
  isMobile: PropTypes.bool,
  patientName: PropTypes.string,
  patientNum: PropTypes.number
};

PatientNameNumberKitTopPanel.defaultProps = {
  isMobile: false,
  patientName: undefined,
  patientNum: undefined
};
