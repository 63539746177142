// @flow
import React, {useCallback, useEffect, useState} from 'react';
import {Authenticator, useAuthenticator} from '@aws-amplify/ui-react';

import {Flex, Image, Box, Alert, AlertIcon} from '@chakra-ui/react';
import withNavigation from './WithRoutes';
import logo from '../images/opendna_logo_large.png';
import { amplifyComponents, amplifyFormFields } from '../auth/amplify-components'
import queryString from "query-string";
import {dc, du} from "../utils/b";
import {Auth} from "aws-amplify";
import InProgress from "../common/InProgress";

type Props = {
  params: any,
  location: any
}

export function LoginImpl(props: Props) {
  const { route, user } = useAuthenticator(context => [context.route]);
  const [message, setMessage] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const queryParams = queryString.parse(props.location.search);
  const [autoLogin, setAutoLogin] = useState(!!queryParams.p);
  const [autoLoginSuccess, setAutoLoginSuccess] = useState(false);

  const ampFormFields = { ...amplifyFormFields };
  let deu = undefined;
  let dec = undefined;
  if (queryParams.u) {
    deu = du(queryParams.u)
    ampFormFields.signIn.username['defaultValue'] = deu;
    if (queryParams.c) {
      dec = dc(queryParams.c, queryParams.u)
      ampFormFields.signIn.password['defaultValue'] = dec;
    }
  }

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const onLoggedIn = useCallback( () => {
    setMessage("Logged in successfully, loading your data... ")
    setTimeout(function () {
      console.log("on timer");
      if (window.location.href.includes("/login")) {
        window.location.href = "/";
      }
    }, 2000);
  }, [setMessage]);

  useEffect(() => {
    async function signIn() {
      Auth.signIn(deu, dec).then(() => {
        onLoggedIn();
        setAutoLogin(false);
        setAutoLoginSuccess(true);
      }).catch(()=>setAutoLogin(false));
    }
    if (autoLogin) {
      signIn().then();
    }
  }, [deu, dec, autoLogin, onLoggedIn]);

  useEffect(() => {
      console.log(`route is ${route}`);
      if (route === 'authenticated') {
        onLoggedIn();
      }
  }, [user, route, onLoggedIn]);

  return (
    <Box h="100%">
      <Flex align="top" justify="center" minW="100%" mt={"100px"} mb={"50px"}>
        {!autoLogin && !autoLoginSuccess && <Authenticator hideSignUp={true} components={amplifyComponents} formFields={ampFormFields}/>}
        {autoLogin && !autoLoginSuccess && (
          <InProgress label="Login in progress..." />
        )}
        {windowWidth >= 1280 && <Image src={logo} h="128px" alt="Open-DNA" ml="100px" />}
      </Flex>
      {message && (
        <Box mx="25%" fontSize={13} mt={"30px"}>
          <Alert status="success" mt="30px" borderRadius="10px">
            <AlertIcon/>
            {message}
          </Alert>
        </Box>
      )}
    </Box>
  );
}

export default withNavigation(LoginImpl);
