// @flow
import React from 'react';
import {Box, Button, Flex, Spacer} from '@chakra-ui/react';
import withNavigation from '../../main/WithRoutes';
import cadImage from '../../images/heart-attack.png';
import {cadCalculatorUserAttributes, getFileNameOnly} from '../../common/reports/report-common';
import {
  getPatientId,
  loadClinicalData
} from '../filters';
import CadExplanatorySection from '../../cad/CadExplanatorySection';
import CadRecommendationSection from '../../cad/CadRecommendationSection';
import CadChartsSection from '../../cad/CadChartsSection';
import cadReportCommon from '../../cad/cad-report-common';
import PatientAttributes from "../../common/reports/PatientAttributes";
import {roundWithPrecision} from "../../utils/numbers";
import {getRiskLevelByPercentile} from "../../common/risk-colors";
import {mmolL2mgdl} from "../../utils/cholesterol";
import CadCalculatorEx from "../../cad/calculator/CadCalculatorEx";
import COLORS from "../../common/colors";
import GeneticReportOrMobile from "../../common/reports/GeneticReportOrMobile";
import withMobile from "../../common/mobile/withMobile";

type Props = {
  location: any,
  params: any,
  printing: boolean,
  callbackSwitchToSimple: any,
  isMobile: boolean
};

type State = {
  age: ?number,
  overallRiskPercentile: ?number
};

export class DemoCadRiskReportExImpl extends React.Component<Props, State> {
  static loadDemoData() {
    // $FlowFixMe[prop-missing]
    const requireContext: any = require.context('./data', true, /\.json$/);
    const json = {};
    requireContext.keys().forEach(key => {
      const obj = requireContext(key);
      const simpleKey = getFileNameOnly(key);
      json[simpleKey] = obj;
    });
    return json;
  }
  constructor(props: Props) {
    super(props);
    (this: any).setAge = this.setAge.bind(this);
    (this: any).onInitOverallRiskPercentile = this.onInitOverallRiskPercentile.bind(this);
    (this: any).onSwitchToSimpleCadView = this.onSwitchToSimpleCadView.bind(this);
    this.patientId = getPatientId(props.params, props.location);
    const cads = DemoCadRiskReportExImpl.loadDemoData();
    const key = `cad${this.patientId}`;
    this.cad = cads[key] === undefined ? cads.cad1 : cads[key];
    this.clinical = loadClinicalData(this.patientId);
    this.state = {
      age: this.clinical.age,
      overallRiskPercentile: undefined
    };
  }

  state: State;

  setAge(newAge: number) {
    this.setState({
      age: newAge
    });
  }

  onInitOverallRiskPercentile(percentile: number) {
    this.setState({
      overallRiskPercentile: percentile
    });
  }

  onSwitchToSimpleCadView() {
    if (this.props.callbackSwitchToSimple)
      this.props.callbackSwitchToSimple();
  }

  patientId: number;
  cad: Object;
  clinical: Object;

  render() {
    const {isMobile} = this.props;
    const oneBasedPercentile = this.cad.cad.percentile + 1;
    const showHeartAge = false;
    const pcs = this.clinical.pca;
    const chartsSection = <CadChartsSection
      percentile={this.cad.cad.percentile}
      quintile={Math.floor(this.cad.cad.percentile / 20)}
      oddsRatios={this.cad.cad.odds_list}
      deciles={this.cad.cad.deciles}
      age={this.state.age}
      showHeartAge={showHeartAge}
      printing={this.props.printing}
      overallRiskPercentile={this.state.overallRiskPercentile ? this.state.overallRiskPercentile + 1 : 1}
      overallRiskOddsRatios={this.cad.cad.insights.overall_risk.odds_ratios}
      overallRiskDeciles={this.cad.cad.insights.overall_risk.prevalence}
      isMobile={isMobile}
    />;

    return (
      <Box sx={{ '@media print': { '@page': { size: 'landscape;' } } }}>
        {!isMobile && !this.props.printing &&
          <Flex mr={"8%"}>
            <Spacer/>
            <Button
              mt={isMobile ? "40px" : "40px"}
              fontSize={13}
              bg="transparent"
              color={COLORS.REPORT_TEXT}
              ml={isMobile ? "2%" : "7%"}
              onClick={this.onSwitchToSimpleCadView}
            >
              switch to 10 year risk view
            </Button>
          </Flex>
        }
        <Box mt={isMobile ? 0 : "-80px"}>
          <GeneticReportOrMobile
            reportTitle="CORONARY ARTERY DISEASE RISK REPORT"
            configTrait="cad"
            titleImage={cadImage}
            patientNum={this.patientId}
            geneticKitId={`OD000${this.patientId}`}
            printing={this.props.printing}
            riskPercentile={this.cad.cad.percentile}
            geneticRiskInfoText={cadReportCommon.GENETIC_RISK_INFO_TEXT}
            patientAttributesSection={this.props.printing &&
              <Box w={"420px"} borderWidth={1} borderColor="gray.100">
                <PatientAttributes
                  gender={this.clinical.sex ? "Male": "Female"}
                  age={this.clinical.age}
                  sbp={this.clinical.sbp}
                  dbp={this.clinical.dbp}
                  hdl={roundWithPrecision(mmolL2mgdl(this.clinical.hdl), 0)}
                  ldl={roundWithPrecision(mmolL2mgdl(this.clinical.ldl), 0)}
                  tc={roundWithPrecision(mmolL2mgdl(this.clinical.tc), 0)}
                  riskTrait="CAD"
                  riskLevel={getRiskLevelByPercentile(oneBasedPercentile)}
                  bpTreated={this.clinical.bp_treated}
                  diabetes={this.clinical.t2d}
                  smoker={this.clinical.smoking}
                  orderLabs={false}
                  labelPrefix="Current "
                  showBorder={false}
                  showAttributeFlags={cadCalculatorUserAttributes}
                >
                </PatientAttributes>
              </Box>
            }

            chartsSection={chartsSection}
            calculatorSection={
              <Box mt="20px"
                    sx={{
                      '@media print': {
                        pageBreakBefore: 'always;'
                      }
                    }}
              >
                <CadCalculatorEx
                  sbp={this.clinical.sbp}
                  dbp={this.clinical.dbp}
                  sex={this.clinical.sex}
                  age={this.state.age}
                  hdl={this.clinical.hdl}
                  ldl={this.clinical.ldl_direct}
                  tc={this.clinical.tc}
                  bpTreated={this.clinical.bp_treated}
                  diabetes={this.clinical.t2d}
                  smoker={this.clinical.smoking}
                  cadScore={this.cad.cad.overall_risk_cad}
                  stdizedCadScore={this.cad.cad.stdized_overall_risk_cad}
                  cadQuintile={this.cad.cad.quintile}
                  cadPercentile={oneBasedPercentile}
                  cadLogReg={this.cad.cad.insights.ex_log_reg}
                  callbackOnChange={null}
                  orderLabs={false}
                  pcs={pcs}
                  printing={this.props.printing}
                  callbackInitRiskPercentile={this.onInitOverallRiskPercentile}
                  apolipoproteinB={this.clinical.apolipoprotein_b}
                  alcohol={this.clinical.alcohol}
                  statinsTreated={this.clinical.statins_treated}
                  whiteBloodCellLeukocyteCount={this.clinical.white_blood_cell_leukocyte_count}
                  alkalinePhosphatase={this.clinical.alkaline_phosphatase}
                  cholesterol={this.clinical.cholesterol}
                  creatinine={this.clinical.creatinine}
                  cystatinC={this.clinical.cystatin_c}
                  gammaGlutamyltransferase={this.clinical.gamma_glutamyltransferase}
                  glucose={this.clinical.glucose}
                  glycatedHaemoglobinHba1c={this.clinical.glycated_haemoglobin_hba1c}
                  phosphate={this.clinical.phosphate}
                  shbg={this.clinical.shbg}
                  totalBilirubin={this.clinical.total_bilirubin}
                  testosterone={this.clinical.testosterone}
                  totalProtein={this.clinical.total_protein}
                  urate={this.clinical.urate}
                  vitaminD={this.clinical.vitamin_d}
                  lipoproteinA={this.clinical.lipoprotein_a}
                />
              </Box>
            }
            recommendationSection={<Box
                sx={{
                  '@media print': {
                    pageBreakBefore: 'always'
                  }
                }}
              >
                <CadRecommendationSection />
            </Box>}
            explanatorySection={<CadExplanatorySection isMobile={isMobile} />}
          />
        </Box>
      </Box>
    );
  }
}

export default withNavigation(withMobile(DemoCadRiskReportExImpl));
