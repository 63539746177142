// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { Button, Stack } from '@chakra-ui/react';

type Props = {
  onStepUp: any,
  onStepDown: any,
  shiftButtonsSize?: string,
  shiftButtonsBorderRadius?: string,
  enabled?: boolean
};

export default function ChangeSliderShiftButtonsImpl(props: Props) {
  return (
    <Stack direction={"row"} spacing="5px">
      <Button
        color="black"
        fontWeight="semibold"
        height={props.shiftButtonsSize}
        width={props.shiftButtonsSize}
        borderWidth={1}
        borderColor="gray.400"
        onClick={props.onStepDown}
        borderRadius={props.shiftButtonsBorderRadius}
        fontSize={30}
        isDisabled={!props.enabled}
      >
        -
      </Button>
      <Button
        color="black"
        fontWeight="semibold"
        height={props.shiftButtonsSize}
        width={props.shiftButtonsSize}
        bg="transparent"
        borderWidth={1}
        borderColor="gray.400"
        onClick={props.onStepUp}
        borderRadius={props.shiftButtonsBorderRadius}
        fontSize={30}
        isDisabled={!props.enabled}
      >
        +
      </Button>
    </Stack>
  );
}

ChangeSliderShiftButtonsImpl.propTypes = {
  shiftButtonsSize: PropTypes.string,
  shiftButtonsBorderRadius: PropTypes.string,
  enabled: PropTypes.bool
};

ChangeSliderShiftButtonsImpl.defaultProps = {
  shiftButtonsSize: '60px',
  shiftButtonsBorderRadius: '30px',
  enabled: true
};
