// @flow
import React, { useCallback } from "react";
import {
  Flex,
  Box,
  Text,
  Stack,
  Spacer,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  FormControl,
  useDisclosure,
  Alert,
  AlertIcon,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from "@chakra-ui/react";

import ChangeSlider from "../../common/ChangeSlider";
import LabeledSwitch from "../../common/LabeledSwitch";
import COLORS from "../../common/colors";
import { getRelativeRiskLabel } from "../../common/relative-risk";
import ActionButton from "../../common/ActionButton";
import RelativeRiskGauge from "../../common/RelativeRiskGauge";
import OverallRiskGauge from "../../common/OverallRiskGauge";
import GaugeChangeDetails from "../../common/GaugeChangeDetails";
import {
  getRiskColorByQuintile,
  getRiskColorByPercentile,
  getRiskLevelByQuintile,
  getRiskLevelByPercentile,
} from "../../common/risk-colors";
import CalculatorHeader from "../../common/reports/CalculatorHeaderBar";
import withT2dCalculator from "./WithT2dCalculator";
import {
  agePopInfoTexts,
  olderParticipant,
  youngerParticipant
} from '../../common/reports/report-common';
import traitRanges from "../../common/reports/trait-ranges.json";
import {getAgeColor} from "../../common/calculator/calculator-common";
import { t2dCalculatorUserAttributes } from "../../common/reports/report-common";
import PatientAttributes from "../../common/reports/PatientAttributes";
import EditablePatientAttributes from "../../common/reports/EditablePatientAttributes";
import { roundWithPrecision } from "../../utils/numbers";
import capitalizeFirstLetter from "../../utils/string";
import PopoverInfo from "../../common/PopoverInfo";
import t2dReportStrings from "../t2d-report-common";
import InfoButton from "../../common/InfoButton";

type Props = {
  onGenderSelected: any,
  onCurrentAgeChange: any,
  onAgeSliderChange: any,
  getAgeDiffText: any,
  onCurrentBmiChange: any,
  onBmiSliderChange: any,
  getBmiDiffText: any,
  getSliderBmiColor: any,
  onCurrentTcChange: any,
  onTcSliderChange: any,
  getTcDiffText: any,
  getSliderTcColor: any,
  onCurrentHdlChange: any,
  onHdlSliderChange: any,
  getHdlDiffText: any,
  getSliderHdlColor: any,
  onCurrentA1cChange: any,
  onA1cSliderChange: any,
  getA1cDiffText: any,
  getSliderA1cColor: any,
  onCurrentSbpChange: any,
  onCurrentDbpChange: any,
  onCurrentBpTreatedChange: any,
  onCurrentSmokerChange: any,
  onCurrentFhDiabetesChange: any,
  onBpTreatedSwitchChange: any,
  onFhDiabetesSwitchChange: any,
  onResetAttributes: any,
  getSwitchColor: any,
  getSwitchText: any,
  gender: string,
  age: ?number,
  bmi: ?number,
  tc: number,
  hdl: number,
  a1c: number,
  fhDiabetes: boolean,
  smoker: boolean,
  bpTreated: boolean,
  minBmi: number,
  maxBmi: number,
  resetAge: number,
  resetBmi: number,
  resetTc: number,
  resetHdl: number,
  resetA1c: number,
  currentAge: number,
  currentBmi: number,
  currentSbp: number,
  currentDbp: number,
  currentTc: number,
  currentHdl: number,
  currentA1c: number,
  t2dQuintile: number,
  t2dPercentile: number,
  playingEnabled: boolean,
  ageSliderEnabled: boolean,
  bmiSliderEnabled: boolean,
  tcSliderEnabled: boolean,
  hdlSliderEnabled: boolean,
  a1cSliderEnabled: boolean,
  current: number,
  expected: number,
  shortT2dText: string,
  longT2dText: string,
  summaryChangeLine: string,
  t2dTrendIcon: any,
  changeColor: string,
  relativeRisk: number,
  relativeRiskOddsRatio: number,
  currentPercentile: number,
  currentRisk: number,
  expectedPercentile: number,
  shortT2dTextPercentile: string,
  longT2dTextPercentile: string,
  summaryChangeLinePercentile: string,
  t2dTrendIconPercentile: any,
  changeColorPercentile: string,
  printing: boolean,
  callbackGetRecommendations: any
};

export function T2dCalculatorImpl(props: Props) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const initialRef = React.useRef();
  const finalRef = React.useRef();

  const longT2dLines = props.longT2dText ? props.longT2dText.split("\n") : [""];
  const longT2dLinesPercentile = props.longT2dTextPercentile
    ? props.longT2dTextPercentile.split("\n")
    : [""];

  const { onResetAttributes } = props;
  const onApplyAndClose = useCallback(() => {
    onResetAttributes();
    onClose();
  }, [onResetAttributes, onClose]);
  const missingAttrText = `Missing attributes, click the 'Edit' button to provide the missing information`;
  const getOverallRiskLabel = useCallback((current, expected) => {
    const diff = expected - current;
    if (diff === 0) return `${current + 1}%`;
    return `${expected}%`;
  }, []);
  const [tabIndex, setTabIndex] = React.useState(0);
  const onPopulationClick = useCallback(() => setTabIndex(0), [setTabIndex]);
  const onCurrentClick = useCallback(() => setTabIndex(1), [setTabIndex]);
  const handleTabsChange = useCallback(
    (index) => {
      setTabIndex(index);
    },
    [setTabIndex]
  );
  const leftSizeWidth = props.printing ? "30%" : "45%";
  const isYoungerThanMin = props.currentAge && props.currentAge < traitRanges.age.lowLimit;
  const isOlderThanMax = props.currentAge && props.currentAge > traitRanges.age.highLimit;
  const recommendations = props.callbackGetRecommendations ? props.callbackGetRecommendations(
    props.currentPercentile,
    props.currentRisk
  ) : null;
  return (
    <Box borderWidth={1} borderColor="gray.200" color={COLORS.REPORT_TEXT}>
      <CalculatorHeader />
      <Flex mt="10px" minW="100%">
        <Flex borderColor="gray.100" borderWidth={1} flex="1" mx="1%" w="75%">
          <Box minW={leftSizeWidth} w={leftSizeWidth} ml="3%" mr="2%" my="20px" fontSize={13}>
            <Stack spacing={props.printing ? "1px" : "15px"}>
              {!props.printing && <Text fontSize={13}>
                <i>
                  Change risk factor values to check the effect on your relative
                  risk to develop type 2 diabetes mellitus
                </i>
                </Text>}
              {/* age */}
              <ChangeSlider
                val={props.age}
                minVal={props.resetAge}
                maxVal={traitRanges.age.highLimit}
                stepVal={1}
                resetVal={props.resetAge}
                leftLabel="Age"
                callbackGetColor={getAgeColor}
                callbackRightLabel={props.getAgeDiffText}
                callbackOnValueChange={props.onAgeSliderChange}
                callbackOnValueEndChange={props.onAgeSliderChange}
                enabled={props.playingEnabled && props.ageSliderEnabled}
                popoverInfo={isYoungerThanMin || isOlderThanMax ? agePopInfoTexts : undefined}
              />
              {/* BMI */}
              <ChangeSlider
                val={props.bmi}
                minVal={props.minBmi}
                maxVal={props.maxBmi}
                stepVal={0.5}
                resetVal={props.resetBmi}
                leftLabel="BMI"
                callbackGetColor={props.getSliderBmiColor}
                callbackRightLabel={props.getBmiDiffText}
                callbackOnValueChange={props.onBmiSliderChange}
                callbackOnValueEndChange={props.onBmiSliderChange}
                enabled={props.playingEnabled && props.bmiSliderEnabled}
              />
              {/* tc */}
              <ChangeSlider
                val={props.tc}
                minVal={traitRanges.tc.lowLimit}
                maxVal={traitRanges.tc.highLimit}
                stepVal={1}
                resetVal={props.resetTc}
                leftLabel="Total Cholesterol (mg/dL)"
                callbackGetColor={props.getSliderTcColor}
                callbackRightLabel={props.getTcDiffText}
                callbackOnValueChange={props.onTcSliderChange}
                callbackOnValueEndChange={props.onTcSliderChange}
                enabled={props.playingEnabled && props.tcSliderEnabled}
              />
              {/* hdl */}
              <ChangeSlider
                val={props.hdl}
                minVal={traitRanges.hdl.lowLimit}
                maxVal={traitRanges.hdl.highLimit}
                stepVal={1}
                resetVal={props.resetHdl}
                leftLabel="HDL (mg/dL)"
                callbackGetColor={props.getSliderHdlColor}
                callbackRightLabel={props.getHdlDiffText}
                callbackOnValueChange={props.onHdlSliderChange}
                callbackOnValueEndChange={props.onHdlSliderChange}
                enabled={props.playingEnabled && props.hdlSliderEnabled}
              />
              {/* a1c */}
              <ChangeSlider
                val={props.a1c}
                minVal={traitRanges.a1c.lowLimit}
                maxVal={traitRanges.a1c.highLimit}
                stepVal={0.1}
                resetVal={props.resetA1c}
                leftLabel="A1C (%)"
                callbackGetColor={props.getSliderA1cColor}
                callbackRightLabel={props.getA1cDiffText}
                callbackOnValueChange={props.onA1cSliderChange}
                callbackOnValueEndChange={props.onA1cSliderChange}
                enabled={props.playingEnabled && props.a1cSliderEnabled}
              />
              <LabeledSwitch
                mt="10px"
                isChecked={props.bpTreated}
                leftLabel="Treated for high blood pressure"
                leftLabelWidth="210px"
                callbackGetColor={props.getSwitchColor}
                callbackOnValueChange={props.onBpTreatedSwitchChange}
                callbackRightLabel={props.getSwitchText}
                enabled={props.playingEnabled}
              />
              <LabeledSwitch
                isChecked={props.fhDiabetes}
                leftLabel="Diabetes family history"
                leftLabelWidth="210px"
                callbackGetColor={props.getSwitchColor}
                callbackOnValueChange={props.onFhDiabetesSwitchChange}
                callbackRightLabel={props.getSwitchText}
                enabled={props.playingEnabled}
              />
              {/* <LabeledSwitch */}
              {/*  isChecked={props.smoker} */}
              {/*  leftLabel="Smoker" */}
              {/*  leftLabelWidth="210px" */}
              {/*  callbackGetColor={props.getSwitchColor} */}
              {/*  callbackOnValueChange={props.onSmokerSwitchChange} */}
              {/*  callbackRightLabel={props.getSwitchText} */}
              {/* /> */}
              <ActionButton
                fontSize={14}
                onClick={props.onResetAttributes}
                borderWidth={1}
                borderColor={COLORS.REPORT_TEXT}
                name="Reset"
                color={COLORS.REPORT_TEXT}
              />
            </Stack>
          </Box>
          <Tabs
            variant="soft-rounded"
            my="10px"
            size="lg"
            index={tabIndex}
            mb="20px"
            spacing="20px"
            minW="48%"
            onChange={handleTabsChange}
          >
            <TabList>
              <Spacer />
              <Box>
                <Tab>Relative to Population</Tab>
                <PopoverInfo
                  trigger={
                    <Box>
                      <InfoButton
                        onClick={onPopulationClick}
                        mt="-20px"
                        minW="200px"
                        minH="20px"
                      />
                    </Box>
                  }
                  header={t2dReportStrings.RELATIVE_TO_POPULATION_INFO_HEADER}
                  text={t2dReportStrings.RELATIVE_TO_POPULATION_INFO_TEXT}
                  footer={
                    t2dReportStrings.RELATIVE_TO_POPULATION_OR_RELATIVE_INFO_FOOTER
                  }
                />
              </Box>
              <Spacer />
              <Box>
                <Tab>Relative to Current</Tab>
                <PopoverInfo
                  trigger={
                    <Box>
                      <InfoButton
                        onClick={onCurrentClick}
                        mt="-20px"
                        minW="200px"
                        minH="20px"
                      />
                    </Box>
                  }
                  header={t2dReportStrings.RELATIVE_TO_CURRENT_INFO_HEADER}
                  text={t2dReportStrings.RELATIVE_TO_CURRENT_INFO_TEXT}
                  footer={
                    t2dReportStrings.RELATIVE_TO_POPULATION_OR_RELATIVE_INFO_FOOTER
                  }
                />
              </Box>
              <Spacer />
            </TabList>
            <TabPanels>
              <TabPanel>
                <OverallRiskGauge
                  percentile={props.t2dPercentile}
                  callbackGetColor={getRiskColorByPercentile}
                  callbackGetRiskLevel={getRiskLevelByPercentile}
                  callbackGetOverallRiskLabel={getOverallRiskLabel}
                  currentPercentile={props.currentPercentile}
                  expectedPercentile={props.expectedPercentile}
                  showSubtitle={false}
                />
                {props.currentPercentile !== props.expectedPercentile && (
                  <GaugeChangeDetails
                    headerLine="Estimated risk percentile change"
                    changeColor={props.changeColorPercentile}
                    changeLines={recommendations ? [] : longT2dLinesPercentile}
                    trendIcon={props.t2dTrendIconPercentile}
                    shortText={props.shortT2dTextPercentile}
                    summaryLine={recommendations ? null : props.summaryChangeLinePercentile}
                    printing={props.printing}
                  />
                )}
                {recommendations}
              </TabPanel>
              <TabPanel>
                <RelativeRiskGauge
                  percentile={props.t2dPercentile}
                  quantile={props.t2dQuintile}
                  title={undefined}
                  callbackGetColor={getRiskColorByQuintile}
                  callbackGetRiskLevel={getRiskLevelByQuintile}
                  callbackGetRelativeRiskLabel={getRelativeRiskLabel}
                  relativeRisk={props.relativeRisk}
                  relativeRiskOddsRatio={props.relativeRiskOddsRatio}
                  showSubtitle={false}
                />
                {props.current !== props.expected && (
                  <GaugeChangeDetails
                    headerLine="Estimated relative risk change"
                    changeColor={props.changeColor}
                    changeLines={recommendations ? [] : longT2dLines}
                    trendIcon={props.t2dTrendIcon}
                    shortText={props.shortT2dText}
                    summaryLine={recommendations ? null : props.summaryChangeLine}
                    printing={props.printing}
                  />
                )}
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Flex>
        {!props.printing && <Box borderColor="gray.100" borderWidth={1} mr="1%" w="23%" minW="340px">
          <PatientAttributes
              gender={capitalizeFirstLetter(props.gender)}
              age={props.currentAge}
              sbp={props.currentSbp}
              dbp={props.currentDbp}
              hdl={roundWithPrecision(props.currentHdl, 0)}
              tc={roundWithPrecision(props.currentTc, 0)}
              a1c={roundWithPrecision(props.currentA1c, 1)}
              bmi={roundWithPrecision(props.currentBmi, 1)}
              fhDiabetes={!!props.fhDiabetes}
              smoker={!!props.smoker}
              riskTrait="T2D"
              riskLevel={getRiskLevelByPercentile(props.t2dPercentile)}
              bpTreated={!!props.bpTreated}
              labelPrefix="Current "
              showBorder={false}
              showAttributeFlags={t2dCalculatorUserAttributes}
          />
          <Flex align="center" mt="30px">
            <ActionButton
                name="Edit..."
                size="sm"
                w="70px"
                onClick={onOpen}
                ml="30px"
                color={COLORS.REPORT_TEXT}
            />
            <Text ml="15px" color={COLORS.REPORT_TEXT_GRAY} fontSize={10}>editing in order to check risk effect scenarios.<br/>{`the changes are not persistent.`}</Text>
          </Flex>
          {!props.playingEnabled && (
              <Box mx="30px" fontSize={13}>
                <Alert status="warning" mt="30px" borderRadius="10px">
                  <AlertIcon/>
                  {missingAttrText}
                </Alert>
              </Box>
          )}
        </Box>
        }
      </Flex>
      <Flex my="20px" minW="100%">
        <Box mx="20px" mb="20px">
          <Text fontSize={13}>
            <i>
              Calculations are based on a machine learning (AI) model derived
              from analyzing data of ~30K people of European descent and
              includes genetic risk (PRS), age, sex, cholesterol levels, blood
              pressure levels, treatment for high blood pressure, diabetes
              family history and smoking status. The estimated relative risk
              change for developing type 2 diabetes mellitus is a statistical
              measurement and reflects the risk change in the test population.
              Type 2 diabetes mellitus risk depends on other factors such as
              physical activity, stress and other factors and therefore the
              values presented serve as estimations only.
            </i>
          </Text>
          {isYoungerThanMin && (<Text mt="10px" color={COLORS.RED_STATUS} fontSize={13} fontWeight={"bold"}>
            <sup>*</sup>
            <i>
              {youngerParticipant}
            </i>
          </Text>)}
          {isOlderThanMax && (<Text mt="10px" color={COLORS.RED_STATUS} fontSize={13} fontWeight={"bold"}>
            <sup>*</sup>
            <i>
              {olderParticipant}
            </i>
          </Text>)}
        </Box>
      </Flex>
      <Modal
        blockScrollOnMount={false}
        isOpen={isOpen}
        onClose={onClose}
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
      >
        <ModalOverlay />
        <ModalContent color={COLORS.REPORT_TEXT}>
          <ModalBody color={COLORS.REPORT_TEXT}>
            <FormControl>
              <EditablePatientAttributes
                gender={props.gender}
                age={props.currentAge}
                bmi={props.currentBmi}
                sbp={props.currentSbp}
                dbp={props.currentDbp}
                hdl={props.currentHdl}
                tc={props.currentTc}
                a1c={props.currentA1c}
                bpTreated={props.bpTreated}
                fhDiabetes={props.fhDiabetes}
                smoker={props.smoker}
                riskTrait={null}
                labelPrefix="Current "
                callbackGenderSelected={props.onGenderSelected}
                callbackAgeChange={props.onCurrentAgeChange}
                callbackBmiChange={props.onCurrentBmiChange}
                callbackTcChange={props.onCurrentTcChange}
                callbackHdlChange={props.onCurrentHdlChange}
                callbackSbpChange={props.onCurrentSbpChange}
                callbackDbpChange={props.onCurrentDbpChange}
                callbackA1cChange={props.onCurrentA1cChange}
                callbackSmokerChange={props.onCurrentSmokerChange}
                callbackFhDiabetesChange={props.onCurrentFhDiabetesChange}
                callbackBpTreatedChange={props.onCurrentBpTreatedChange}
                onCancel={onClose}
                onApply={onApplyAndClose}
                showAttributeFlags={t2dCalculatorUserAttributes}
              />
            </FormControl>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default withT2dCalculator(T2dCalculatorImpl);
