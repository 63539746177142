// @flow
import AccPanel from "../cad/AccPanel";
import React, {useCallback} from "react";
import {Box, Flex, Spacer, Text} from "@chakra-ui/react";
import COLORS from "../common/colors";
import {phosphorImageComp} from "../common/image";
import {TestTube} from "@phosphor-icons/react";
import ActionButton from "../common/ActionButton";

type Props = {
  patient: Object,
	callbackOnClose: any
};


export default function DemoBaselineRisk(props: Props) {
	const getFlatPatientInfo = useCallback((patient: Object) => {
		const patientInfo = {...patient}
		const visit = patient.visits[0];
		for (let key of Object.keys(visit)) {
			patientInfo[key] = visit[key];
		}
		return patientInfo;
	}, []);
	const patientInfo = getFlatPatientInfo(props.patient);

	const {callbackOnClose} = props;
	const onOrder = useCallback(()=> {
		callbackOnClose(true);
	}, [callbackOnClose])

	const onClose = useCallback(()=> {
		callbackOnClose(false);
	}, [callbackOnClose]);
	return (
			<Box >
				<Flex align={"center"} ml={"80px"} mt={"50px"}>
					<Text  fontSize={18} color={COLORS.REPORT_TEXT_GRAY} fontWeight={"bold"}>ACC CARDIOVASCULAR RISK</Text>
					<Spacer/>
					<Box color={COLORS.REPORT_TEXT_GRAY}>
						{phosphorImageComp(TestTube, "20px", "20px")}
					</Box>
				</Flex>
				<AccPanel patientInfo={patientInfo}/>
      	<ActionButton ml="100px" w="120px" name="Close" onClick={onClose} />
      	<ActionButton ml="30px" w="180px" name="Order OpenDNA ..." onClick={onOrder} />
			</Box>
	);
}
