// @flow
import React, { useCallback } from 'react';
import {
  Flex,
  Box,
  Text,
  Stack,
  Spacer,
  Image,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  FormControl
} from '@chakra-ui/react';

import cautionImage from '../../images/caution-sign-blue.png';
import detailsImage from '../../images/details.png';
import { LabeledTextWithSpacer } from '../../common/LabeledText';
import COLORS from '../../common/colors';
import {getAgeColor} from '../../common/calculator/calculator-common'
import {
  getBloodPressureRelativeRiskLabel
} from './bp-calculator-utils';
import ActionButton from '../../common/ActionButton';
import withBpCalculator from './WithBpCalculator';
import {
  bpCalculatorUserAttribute,
} from '../../common/reports/report-common';
import MobileChangeSlider from '../../common/MobileChangeSlider';
import RelativeRiskChart from '../../common/RelativeRiskGauge';
import {
  getRiskColorByQuartile, getRiskLevelByPercentile,
  getRiskLevelByQuartile
} from '../../common/risk-colors';
import EditablePatientAttributes from '../../common/reports/EditablePatientAttributes';
import MobileMissingAttributes from '../../common/reports/MobileMissingAttributes';

type Props = {
  onCurrentAgeChange: any,
  onAgeSliderChange: any,
  getAgeDiffText: any,
  onCurrentBmiChange: any,
  onBmiSliderChange: any,
  getBmiDiffText: any,
  getSliderBmiColor: any,
  onGenderSelected: any,
  onResetAttributes: any,
  ageLowLimit: number,
  ageHighLimit: number,
  bmiLowLimit: number,
  bmiHighLimit: number,
  sbpPercentile: number,
  dbpPercentile: number,
  sbpQuartile: number,
  dbpQuartile: number,
  age: number,
  resetAge: number,
  bmi: number,
  currentAge: ?number,
  currentBmi: ?number,
  resetBmi: number,
  recentSbpChange: number,
  recentDbpChange: number,
  recentMinSbp: number,
  recentMaxSbp: number,
  recentMinDbp: number,
  recentMaxDbp: number,
  gender: ?string,
  playingEnabled: boolean,
  shortSbpText: string,
  sbpTrendIcon: any,
  sbpColor: string,
  shortDbpText: string,
  dbpTrendIcon: any,
  dbpColor: string
};

export function MobileBpCalculatorImpl(props: Props) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const initialRef = React.useRef();
  const finalRef = React.useRef();
  const refHeader = React.useRef();
  const scrollToTop = () => {
    if (refHeader.current) refHeader.current.scrollIntoView();
  };

  const onApplyAndClose = useCallback(() => {
    scrollToTop();
    onClose();
  }, [onClose]);

  return (
    <Box borderColor="gray.300" borderWidth={1} borderRadius="10px" mx="1%">
      <Box mx="5px" my="5px">
        <Flex
          mb="10px"
          bg="transparent"
          align="center"
          borderWidth={1}
          borderRadius="10px"
          ref={refHeader}
        >
          <Text fontSize={13} fontWeight="semibold" ml="10px" my="10px">
            CLINICAL FACTORS EFFECT ON BLOOD PRESSURE
          </Text>
          <Spacer />
          <Image src={cautionImage} h="20px" alt="" ml="10px" mr="10px" />
        </Flex>
      </Box>
      <Box mx="3%">
        {!props.playingEnabled && <MobileMissingAttributes onOpen={onOpen} />}
        <Text fontSize={12} textAlign="justify" my="10px">
          <i>
            change the age and BMI values and check the mean effect on blood
            pressure in the population.
          </i>
        </Text>
        <Stack spacing="20px" mt="20px">
          <Stack spacing="30px">
            {/* age */}
            <MobileChangeSlider
              val={props.age}
              minVal={props.ageLowLimit}
              maxVal={props.ageHighLimit}
              stepVal={1}
              resetVal={props.resetAge}
              leftLabel="AGE:"
              callbackOnValueChange={props.onAgeSliderChange}
              callbackOnValueEndChange={props.onAgeSliderChange}
              callbackGetColor={getAgeColor}
              callbackRightLabel={props.getAgeDiffText}
              enabled={props.playingEnabled}
            />
            {/* bmi */}
            <MobileChangeSlider
              val={props.bmi}
              minVal={props.bmiLowLimit}
              maxVal={props.bmiHighLimit}
              stepVal={0.5}
              resetVal={props.resetBmi}
              leftLabel="BMI:"
              callbackOnValueChange={props.onBmiSliderChange}
              callbackOnValueEndChange={props.onBmiSliderChange}
              callbackGetColor={props.getSliderBmiColor}
              callbackRightLabel={props.getBmiDiffText}
              enabled={props.playingEnabled}
            />
          </Stack>
          <Flex>
            <Spacer />
            <ActionButton
              width="100px"
              height="30px"
              onClick={props.onResetAttributes}
              px="10%"
              fontWeight="normal"
              borderWidth={1}
              borderColor={COLORS.REPORT_TEXT}
              name="Reset"
              fontSize={13}
            />
            <Spacer />
          </Flex>
          <Flex my="10px" bg="gray.300">
            <Text mx="20px" my="1px" />
          </Flex>
          <Flex align="center">
            <Box minW="49%" mr="2%">
              <Text fontSize={12} fontWeight="semibold" textAlign="center">
                Systolic Blood Pressure
              </Text>
              <RelativeRiskChart
                percentile={props.sbpPercentile}
                quantile={props.sbpQuartile}
                title={undefined}
                showSubtitle={false}
                callbackGetColor={getRiskColorByQuartile}
                callbackGetRiskLevel={getRiskLevelByQuartile}
                callbackGetRelativeRiskLabel={getBloodPressureRelativeRiskLabel}
                relativeRisk={props.recentSbpChange}
                relativeRiskOddsRatio={1}
                minRelativeRisk={props.recentMinSbp}
                maxRelativeRisk={props.recentMaxSbp}
                relativeRiskTitle=""
                maxHeight={100}
                isMobile
              />
              <Text
                fontSize={12}
                textAlign="center"
                mt="5px"
                color={props.sbpColor}
              >
                Estimated SBP Change
              </Text>
              <Spacer />
              <Flex align="center">
                <Spacer />
                {props.sbpTrendIcon && (
                  <Image src={props.sbpTrendIcon} h="24px" w="24px" mr="20px" />
                )}
                <Text color={props.sbpColor} minH="24px">
                  <b>{props.shortSbpText}</b>
                </Text>
                <Spacer />
              </Flex>
            </Box>
            <Box minW="49%">
              <Text fontSize={12} fontWeight="semibold" textAlign="center">
                Diastolic Blood Pressure
              </Text>
              <Box mx="1%" />
              <RelativeRiskChart
                percentile={props.dbpPercentile}
                quantile={props.dbpQuartile}
                title={undefined}
                showSubtitle={false}
                callbackGetColor={getRiskColorByQuartile}
                callbackGetRiskLevel={getRiskLevelByQuartile}
                callbackGetRelativeRiskLabel={getBloodPressureRelativeRiskLabel}
                relativeRisk={props.recentDbpChange}
                relativeRiskOddsRatio={1}
                minRelativeRisk={props.recentMinDbp}
                maxRelativeRisk={props.recentMaxDbp}
                relativeRiskTitle=""
                maxHeight={100}
                isMobile
              />

              <Text
                fontSize={12}
                mt="5px"
                textAlign="center"
                color={props.dbpColor}
              >
                Estimated DBP Change
              </Text>
              <Flex align="center">
                <Spacer />
                {props.dbpTrendIcon && (
                  <Image src={props.dbpTrendIcon} h="24px" w="24px" mr="20px" />
                )}
                <Text color={props.dbpColor} minH="24px">
                  <b>{props.shortDbpText}</b>
                </Text>
                <Spacer />
              </Flex>
            </Box>
          </Flex>
          <Box ml="20px" fontSize={13}>
            <Flex my="10px" bg="gray.300">
              <Text mx="20px" my="1px" />
            </Flex>
            <Flex align="center">
              <Image src={detailsImage} h="16px" w="16px" mr="10px" />
              <Text fontWeight="semibold" color={COLORS.REPORT_TEXT_GRAY}>
                Your Attributes:
              </Text>
              <Spacer />
              <ActionButton
                name="Edit..."
                size="xs"
                w="70px"
                onClick={onOpen}
              />
            </Flex>
            <Flex align="justify" mt="5px">
              <Box w="45%" mr="5%" fontSize={12}>
                <LabeledTextWithSpacer
                  align="left"
                  labelWidth="100px"
                  textWidth="60px"
                  title="Gender"
                  value={props.gender}
                  marginBottom="5px"
                  textWeight="semibold"
                  labelColor={COLORS.REPORT_TEXT_GRAY}
                  textColor={COLORS.REPORT_TEXT_GRAY}
                />
                <LabeledTextWithSpacer
                  align="left"
                  labelWidth="100px"
                  textWidth="60px"
                  title="Current Age"
                  value={props.currentAge}
                  marginBottom="5px"
                  textWeight="semibold"
                  labelColor={COLORS.REPORT_TEXT_GRAY}
                  textColor={COLORS.REPORT_TEXT_GRAY}
                />
                <LabeledTextWithSpacer
                  align="left"
                  labelWidth="100px"
                  textWidth="60px"
                  title="Current BMI"
                  value={props.currentBmi ? props.currentBmi.toFixed(1) : ''}
                  marginBottom="5px"
                  textWeight="semibold"
                  labelColor={COLORS.REPORT_TEXT_GRAY}
                  textColor={COLORS.REPORT_TEXT_GRAY}
                />
              </Box>
              <Box w="50%" fontSize={12}>
                <Text mb="5px" color={COLORS.REPORT_TEXT_GRAY}>
                  Genetic risk of HTN:
                </Text>
                <LabeledTextWithSpacer
                  align="left"
                  labelWidth="70px"
                  textWidth="100px"
                  title="Systolic"
                  value={getRiskLevelByPercentile(props.sbpPercentile)}
                  marginBottom="5px"
                  textWeight="semibold"
                  labelColor={COLORS.REPORT_TEXT_GRAY}
                  textColor={COLORS.REPORT_TEXT_GRAY}
                />
                <LabeledTextWithSpacer
                  align="left"
                  labelWidth="70px"
                  textWidth="100px"
                  title="Diastolic"
                  value={getRiskLevelByPercentile(props.dbpPercentile)}
                  marginBottom="5px"
                  textWeight="semibold"
                  labelColor={COLORS.REPORT_TEXT_GRAY}
                  textColor={COLORS.REPORT_TEXT_GRAY}
                />
              </Box>
            </Flex>
          </Box>
        </Stack>
      </Box>
      <Modal
        blockScrollOnMount={false}
        isOpen={isOpen}
        onClose={onClose}
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
      >
        <ModalOverlay />
        <ModalContent color={COLORS.REPORT_TEXT}>
          <ModalBody color={COLORS.REPORT_TEXT}>
            <FormControl>
              <EditablePatientAttributes
                gender={props.gender}
                age={props.currentAge}
                bmi={props.currentBmi}
                riskTrait={null}
                labelPrefix="Current "
                callbackGenderSelected={props.onGenderSelected}
                callbackAgeChange={props.onCurrentAgeChange}
                callbackBmiChange={props.onCurrentBmiChange}
                onCancel={onClose}
                onApply={onApplyAndClose}
                showAttributeFlags={bpCalculatorUserAttribute}
              />
            </FormControl>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default withBpCalculator(MobileBpCalculatorImpl);
