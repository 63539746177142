// @flow
import React from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  AlertIcon,
  Box,
  Flex,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Stack,
  Text,
  Spacer,
  Input,
  InputGroup,
  InputRightElement,
  InputLeftElement,
  Divider,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  IconButton, Modal, ModalOverlay, ModalContent, ModalBody
} from '@chakra-ui/react';
import '../phone/phone-style.css'
import PhoneInput, {isPossiblePhoneNumber} from 'react-phone-number-input'
import {AiOutlineMail} from 'react-icons/ai';
import { EditIcon } from '@chakra-ui/icons';
import LabeledSwitch from '../LabeledSwitch';
import {isNullOrUndefined, roundWithPrecision, undefinedForNan} from '../../utils/numbers';
import ActionButton from '../ActionButton';
import traitRanges from '../reports/trait-ranges.json';
import COLORS from "../colors";
import calcBmi from "../../utils/bmi";
import {feetInchToCm, poundsToKg} from "../../utils/unit_translation";
import {createPresignedUrlForUpload, getKitAvailability, loadPreScannedKitId} from "../../api/capilots";
import {sendSmsToUser} from "../../api/users";
import {CurrentUserContext} from "../../context/CurrentUserContext";
import {getFolderFromPresigned, linkFromPresigned} from "../barcode/kit-barcode-url";
import {is_localhost_url, isProdEnv} from "../../config/env_config";
import isEmail from "validator/es/lib/isEmail";
import isMobilePhone from "validator/es/lib/isMobilePhone";

type Props = {
  adding: boolean,
  name?: ?string,
  id?: ?string,
  num?: ?number,
  kitId?: ?string,
  externalId?: ?string,
  email?: ?string,
  phone?: ?string,
  gender?: ?boolean,
  age?: ?number,
  sbp?: ?number,
  dbp?: ?number,
  hdl?: ?number,
  ldl?: ?number,
  tc?: ?number,
  bmi?: ?number,
  inch?: ?number,
  feet?: ?number,
  weight?: ?number,
  a1c?: ?number,
  smoker?: ?boolean,
  riskTrait?: ?string,
  riskLevel?: ?string,
  bpTreated?: ?boolean,
  diabetes?: ?boolean,
  fhDiabetes?: ?boolean,
  birthYear?: ?number,
  birthMonth?: ?number,
  birthDay?: ?number,
  showBorder?: boolean,
  fontSize?: number,
  title?: string,
  onCancel: any,
  onApply: any,
  labelPrefix?: string
};

type State = {
  error: ?string,
  isApplyEnabled: boolean,
  bmi: ?number,
  calculatedBmi: boolean,
  needApproval: boolean,
  showApproval:  boolean,
  patientRecordEnabled: boolean,
  isOpenEditBarcode: boolean,
  barcodeScanLinkSent: boolean,
  approvedKitScan: boolean,
  kitId: ?string,
  barcodeUploadFolder: ?string,
  loadingScannedKit: boolean,
  failedLoadingScannedKit: boolean
};

export default class IdentifiedPatientAttributesImpl extends React.Component<
  Props,
  State
> {
  static contextType = CurrentUserContext;

  static getSwitchColor() {
    return 'blue';
  }

  static getSwitchText(isChecked: boolean) {
    return isChecked ? 'YES' : 'NO';
  }

  static isInRange(val: ?number, lowLimit: number, highLimit: number) {
    if (val === undefined || val === null || Number.isNaN(val)) return false;
    const numVal: number = val;
    return numVal >= lowLimit && numVal <= highLimit;
  }

  static onFloatValChange(
    val: number,
    lowLimit: number,
    highLimit: number
  ) {
    const newVal = parseFloat(val);
    return newVal;
  }

  static onIntValChange(
    val: number,
    lowLimit: number,
    highLimit: number,
    callback?: any
  ) {
    const newVal = parseInt(val, 10);
    let callbackVal = newVal;
    if (newVal < lowLimit || newVal > highLimit) callbackVal = Number.NaN;
    if (callback) callback(callbackVal);
    return newVal;
  }

  constructor(props: Props) {
    super(props);
    (this: any).onApply = this.onApply.bind(this);
    (this: any).onCancel = this.onCancel.bind(this);
    (this: any).onApproval = this.onApproval.bind(this);
    (this: any).onCancelApproval = this.onCancelApproval.bind(this);
    (this: any).onEditPatientRecord = this.onEditPatientRecord.bind(this);
    (this: any).onGenderSelected = this.onGenderSelected.bind(this);
    (this: any).onExternalIdChange = this.onExternalIdChange.bind(this);
    (this: any).onEmailChange = this.onEmailChange.bind(this);
    (this: any).onPhoneChange = this.onPhoneChange.bind(this);
    (this: any).onAgeChange = this.onAgeChange.bind(this);
    (this: any).onBirthYearChange = this.onBirthYearChange.bind(this);
    (this: any).onBirthMonthChange = this.onBirthMonthChange.bind(this);
    // (this: any).onHeightChange = this.onHeightChange.bind(this);
    (this: any).onWeightChange = this.onWeightChange.bind(this);
    (this: any).onFeetChange = this.onFeetChange.bind(this);
    (this: any).onInchChange = this.onInchChange.bind(this);
    (this: any).onBmiChange = this.onBmiChange.bind(this);
    (this: any).onTcChange = this.onTcChange.bind(this);
    (this: any).onHdlChange = this.onHdlChange.bind(this);
    (this: any).onLdlChange = this.onLdlChange.bind(this);
    (this: any).onSbpChange = this.onSbpChange.bind(this);
    (this: any).onDbpChange = this.onDbpChange.bind(this);
    (this: any).onA1cChange = this.onA1cChange.bind(this);
    (this: any).onNameChange = this.onNameChange.bind(this);
    (this: any).onNumChange = this.onNumChange.bind(this);
    (this: any).onKitChange = this.onKitChange.bind(this);
    (this: any).checkValidKitId = this.checkValidKitId.bind(this);
    (this: any).onBpTreatedSwitchChange = this.onBpTreatedSwitchChange.bind(
      this
    );
    (this: any).onDiabetesSwitchChange = this.onDiabetesSwitchChange.bind(this);
    (this: any).onFhDiabetesSwitchChange = this.onFhDiabetesSwitchChange.bind(
      this
    );
    (this: any).onSmokerSwitchChange = this.onSmokerSwitchChange.bind(this);
    (this: any).getError = this.getError.bind(this);
    (this: any).getErrorsWithGenderArg = this.getErrorsWithGenderArg.bind(this);
    (this: any).getRangeAttributeError = this.getRangeAttributeError.bind(this);

    (this: any).updateError = this.updateError.bind(this);
    (this: any).onAutoFill = this.onAutoFill.bind(this);
    (this: any).onEditBarcode = this.onEditBarcode.bind(this);

    (this: any).onSendLink = this.onSendLink.bind(this);
    (this: any).onLoadScannedKit = this.onLoadScannedKit.bind(this);
    (this: any).onDoneBarcodeEditing = this.onDoneBarcodeEditing.bind(this);
    (this: any).getKitIdError = this.getKitIdError.bind(this);

    this.cancelRef = React.createRef();
    this.name = this.props.name;
    this.externalId = this.props.externalId;
    this.email = this.props.email;
    this.phone = this.props.phone;
    this.gender = undefined;
    if (this.props.gender !== undefined) {
      this.gender = this.props.gender ? 'male' : 'female';
    }
    this.age = this.props.age;
    // this.height = this.props.height;
    this.weight = this.props.weight;
    this.feet = this.props.feet;
    this.inch = this.props.inch;
    this.sbp = this.props.sbp;
    this.dbp = this.props.dbp;
    this.tc = this.props.tc;
    this.hdl = this.props.hdl;
    this.ldl = this.props.ldl;
    this.a1c = this.props.a1c;
    this.smoker = this.props.smoker;
    this.bpTreated = this.props.bpTreated;
    this.diabetes = this.props.diabetes;
    this.fhDiabetes = this.props.fhDiabetes;
    this.num = this.props.num;
    this.birthYear = this.props.birthYear;
    this.birthMonth = this.props.birthMonth;
    this.kitError = undefined;
    let bmi = this.props.bmi;
    const calculatedBmi = !!(this.feet && !isNullOrUndefined(this.inch) && this.weight);
    if (calculatedBmi && !bmi) {
      bmi = roundWithPrecision(calcBmi(poundsToKg(this.weight), feetInchToCm(this.feet, this.inch) / 100.0), 1)
    }
    this.patientRecordChangesApproved = false;
    this.state = {
      kitId: this.props.kitId,
      error: this.getErrorsWithGenderArg(this.gender),
      isApplyEnabled: !!(this.name && this.props.kitId && this.gender && this.birthMonth && this.birthMonth && this.feet && !isNullOrUndefined(this.inch)),
      bmi,
      calculatedBmi,
      needApproval: false,
      showApproval: false,
      patientRecordEnabled: this.props.adding,
      isOpenEditBarcode: false,
      barcodeScanLinkSent: false,
      approvedKitScan: false,
      barcodeUploadFolder: undefined,
      loadingScannedKit: false,
      failedLoadingScannedKit: false
    };
    this.itemHeight = "35px"
  }

  checkAndUpdateBmi() {
    const calculatedBmi = !!(this.feet && !isNullOrUndefined(this.inch) && this.weight);
    if (calculatedBmi) {
      const bmi = roundWithPrecision(calcBmi(poundsToKg(this.weight), feetInchToCm(this.feet, this.inch) / 100.0), 1)
      this.setState({
        bmi,
        calculatedBmi
      });
    } else {
      this.setState({
        calculatedBmi
      });
    }
  }

  onAutoFill() {
    // temp code
    console.log(this.age);
  }

  onGenderSelected(event: any) {
    const strGender: string = event.target.value;
    this.gender = strGender;
    this.updateError();
  }

  onAgeChange(val: number) {
    this.age = IdentifiedPatientAttributesImpl.onIntValChange(
      val,
      traitRanges.age.lowLimit,
      traitRanges.age.highLimit
    );
    this.updateError();
  }

  onNameChange(event: any) {
    this.name = event.target.value;
    this.updateError();
  }


  async checkValidKitId(kitId: ?string) {
    if (!kitId || kitId.toLowerCase().includes("demo")) {
      this.kitError = null;
      this.setState({kitId: !kitId ? undefined : kitId.toUpperCase()}, ()=> this.updateError())
      return;
    }
    kitId = kitId.toUpperCase()
    console.log(kitId);
    if (kitId.slice(0, 2) !== 'OD') {
      this.kitError = "Kit ID must begin with 'OD'";
      this.setState({kitId: kitId}, ()=> this.updateError())
      return;
    }
    console.log(kitId.length);
    if (kitId.length !== 8) {
      this.kitError = "Kit ID must contain 8 characters";
      console.log("8 chars error");
      this.setState({kitId: kitId}, ()=> this.updateError())
      return;
    }
    try {
      const res = await getKitAvailability(kitId);
      const {status} = res.data;
      console.log(status)
      if (status === "used") {
        this.kitError = "This kit ID is already used by another patient";
        console.log("used by another")
      } else if (status === "notfound") {
        this.kitError = "The specified kit ID cannot be found in the system";
        console.log("not found")
      } else {
        console.log("no error")
        this.kitError = null;
      }
    } catch {
      this.kitError = "The specified kit ID cannot be found in the system";
    }
    this.setState({kitId: kitId}, ()=> this.updateError())
  }

  onKitChange(event: any) {
    this.checkValidKitId(event.target.value)
  }

  onExternalIdChange(event: any) {
    this.externalId = event.target.value;
    this.updateError();
  }

  onEmailChange(event: any) {
    this.email = event.target.value;
    this.updateError();
  }
  onPhoneChange(phone: string) {
    this.phone = phone;
    this.updateError();
  }

  onWeightChange(val: number) {
    this.weight = IdentifiedPatientAttributesImpl.onFloatValChange(
      val,
      traitRanges.weight.lowLimit,
      traitRanges.weight.highLimit
    );
    this.checkAndUpdateBmi();
    this.updateError();
  }
  onFeetChange(val: number) {
    this.feet = IdentifiedPatientAttributesImpl.onFloatValChange(
      val,
      traitRanges.feet.lowLimit,
      traitRanges.feet.highLimit
    );
    this.checkAndUpdateBmi();
    this.updateError();
  }
  onInchChange(val: number) {
    this.inch = IdentifiedPatientAttributesImpl.onFloatValChange(
      val,
      traitRanges.inch.lowLimit,
      traitRanges.inch.highLimit
    );
    this.checkAndUpdateBmi();
    this.updateError();
  }

  onBmiChange(val: number) {
    const bmi = IdentifiedPatientAttributesImpl.onFloatValChange(
      val,
      traitRanges.bmi.lowLimit,
      traitRanges.bmi.highLimit
    );
    this.setState({bmi});
    this.updateError();
  }

  onTcChange(val: number) {
    this.tc = IdentifiedPatientAttributesImpl.onFloatValChange(
      val,
      traitRanges.tc.lowLimit,
      traitRanges.tc.highLimit
    );
    this.updateError();
  }

  onHdlChange(val: number) {
    this.hdl = IdentifiedPatientAttributesImpl.onFloatValChange(
      val,
      traitRanges.hdl.lowLimit,
      traitRanges.hdl.highLimit
    );
    this.updateError();
  }

  onLdlChange(val: number) {
    this.ldl = IdentifiedPatientAttributesImpl.onFloatValChange(
      val,
      traitRanges.ldl.lowLimit,
      traitRanges.ldl.highLimit
    );
    this.updateError();
  }

  onSbpChange(val: number) {
    this.sbp = IdentifiedPatientAttributesImpl.onIntValChange(
      val,
      traitRanges.sbp.lowLimit,
      traitRanges.sbp.highLimit
    );
    this.updateError();
  }

  onDbpChange(val: number) {
    this.dbp = IdentifiedPatientAttributesImpl.onIntValChange(
      val,
      traitRanges.dbp.lowLimit,
      traitRanges.dbp.highLimit
    );
    this.updateError();
  }

  onBpTreatedSwitchChange(val: boolean) {
    this.bpTreated = val;
  }
  onDiabetesSwitchChange(val: boolean) {
    this.diabetes = val;
  }
  onFhDiabetesSwitchChange(val: boolean) {
    this.fhDiabetes = val;
  }
  onSmokerSwitchChange(val: boolean) {
    this.smoker = val;
  }
  onA1cChange(val: number) {
    this.a1c = IdentifiedPatientAttributesImpl.onFloatValChange(
      val,
      traitRanges.a1c.lowLimit,
      traitRanges.a1c.highLimit,
    );
    this.updateError();
  }

  onNumChange(val: number) {
    this.num = IdentifiedPatientAttributesImpl.onFloatValChange(
      val,
      traitRanges.num.lowLimit,
      traitRanges.num.highLimit
    );
    this.updateError();
  }

  onBirthYearChange(val: number) {
    this.birthYear = IdentifiedPatientAttributesImpl.onFloatValChange(
      val,
      traitRanges.birthYear.lowLimit,
      new Date().getFullYear()
    );
    this.updateError();
  }

  onBirthMonthChange(val: number) {
    this.birthMonth = IdentifiedPatientAttributesImpl.onFloatValChange(
      val,
      traitRanges.birthMonth.lowLimit,
      traitRanges.birthMonth.highLimit
    );
    this.updateError();
  }

  isPatientRecordChanged() {
    return this.props.name !== this.name ||
        this.props.kitId !== this.state.kitId ||
        (this.props.gender && this.gender === 'female') ||
        (!this.props.gender && this.gender === 'male') ||
        this.props.feet !== this.feet ||
        this.props.inch !== this.inch ||
        this.props.birthYear !== this.birthYear ||
        this.props.birthMonth !== this.birthMonth ||
        this.props.externalId !== this.externalId ||
        this.props.email !== this.email ||
        this.props.phone !== this.phone;
  }

  onCancel() {
    if (this.props.onCancel) this.props.onCancel();
  }

  onApply() {
    if (!this.props.adding) {
      if (this.state.needApproval) {
        this.setState({showApproval: true})
        return;
      }
    }
    // call apply callback
    if (this.props.onApply) this.props.onApply(this);
  }

  onApproval() {
    this.patientRecordChangesApproved = true;
    this.setState({showApproval: false, needApproval: false}, this.onApply)
  }

  onEditPatientRecord() {
    this.setState({patientRecordEnabled: true})
  }

  onCancelApproval() {
    this.setState({showApproval: false})
  }
  getRangeAttributeError(
    val: ?number,
    lowLimit: number,
    highLimit: number,
    name: string
  ) {
    const labelPrefix = this.props.labelPrefix
      ? this.props.labelPrefix.toLowerCase()
      : '';
    if (val === undefined || val === null || Number.isNaN(val))
      return `Fill in your ${labelPrefix}${name}`;
    const numVal: number = val;
    if (numVal < lowLimit || numVal > highLimit) {
      return `Allowed range for ${name} is ${lowLimit} - ${highLimit}`;
    }
    return null;
  }

  getError() {
    const error = this.getErrorsWithGenderArg(this.gender);
    return error;
  }

  getErrorsWithGenderArg(gender: ?string) {
    let error;
    error = this.getMissingNameError();
    if (error) return error;
    if (error) return error;
    error = this.getKitIdError(isProdEnv());
    if (error) return error;
    if (!this.gender) return 'Select gender';
    error = this.getRangeAttributeError(
      this.birthYear,
      traitRanges.birthYear.lowLimit,
      new Date().getFullYear(),
      'Birth Year'
    );
    if (error) return error;
    error = this.getRangeAttributeError(
      this.birthMonth,
      traitRanges.birthMonth.lowLimit,
      traitRanges.birthMonth.highLimit,
      'Birth Month'
    );
    if (error) return error;
    error = this.getRangeAttributeError(
      this.feet,
      traitRanges.feet.lowLimit,
      traitRanges.feet.highLimit,
      'Feet'
    );
    if (error) return error;
    error = this.getRangeAttributeError(
      this.inch,
      traitRanges.inch.lowLimit,
      traitRanges.inch.highLimit,
      'Inch'
    );
    if (error) return error;
    error = this.getKitIdError(true);
    if (error) return error;
    if (this.email && !isEmail(this.email)) {
      return `Invalid email address`;
    }
    if (this.props.adding && !this.email) {
      return `Email address allows forwarding reports to patient`;
    }
    if (this.phone && !isMobilePhone(this.phone, undefined, {strictMode: true})) {
      return `Invalid cell phone number`;
    }
    if (this.props.adding && !this.phone) {
      return `Cell phone allows forwarding reports to patient`;
    }
    error = this.getRangeAttributeError(
      this.weight,
      traitRanges.weight.lowLimit,
      traitRanges.weight.highLimit,
      'Weight'
    );
    if (error) return error;
    error = this.getRangeAttributeError(
      this.weight,
      traitRanges.weight.lowLimit,
      traitRanges.weight.highLimit,
      'Weight'
    );
    if (error) return error;
    if (this.state && !this.state.calculatedBmi) {
      error = this.getRangeAttributeError(
          this.state.bmi,
          traitRanges.bmi.lowLimit,
          traitRanges.bmi.highLimit,
          'BMI'
      );
      if (error) return error;
    }
    if (error) return error;
    error = this.getRangeAttributeError(
      this.sbp,
      traitRanges.sbp.lowLimit,
      traitRanges.sbp.highLimit,
      'SBP'
    );
    if (error) return error;
    error = this.getRangeAttributeError(
      this.dbp,
      traitRanges.dbp.lowLimit,
      traitRanges.dbp.highLimit,
      'DBP'
    );
    if (error) return error;
    if (
      this.sbp !== undefined &&
      this.sbp !== null &&
      this.dbp !== undefined &&
      this.dbp !== null &&
      this.sbp <= this.dbp
    ) {
      error = 'SBP must be greater than DBP';
      return error;
    }
    error = this.getRangeAttributeError(
      this.tc,
      traitRanges.tc.lowLimit,
      traitRanges.tc.highLimit,
      'total cholesterol'
    );
    if (error) return error;
    error = this.getRangeAttributeError(
      this.hdl,
      traitRanges.hdl.lowLimit,
      traitRanges.hdl.highLimit,
      'HDL'
    );
    if (error) return error;
    error = this.getRangeAttributeError(
      this.ldl,
      traitRanges.ldl.lowLimit,
      traitRanges.ldl.highLimit,
      'LDL'
    );
    if (error) return error;
    error = this.getRangeAttributeError(
      this.a1c,
      traitRanges.a1c.lowLimit,
      traitRanges.a1c.highLimit,
      'A1C'
    );
    return error;
  }

  getMissingNameError() {
    // name
    if (!this.name) {
      return `Name is a mandatory field`;
    }
    return null;
  }

  getKitIdError(checkKitError: boolean) {
    if (checkKitError) {
      if (this.kitError) {
        console.log("kit error")
        console.log(this.kitError)
        return this.kitError
      }
    }
    // kit ID
    if (this.state && !this.state.kitId) {
      return `Kit ID is a mandatory field`;
    }
    return null;
  }

  async onSendLink() {
    try {
      const response = await createPresignedUrlForUpload();
      const presignedUrl = response.data.url;
      console.log(presignedUrl);

      const linkUrl = linkFromPresigned(presignedUrl, window.location.href.includes("localhost"));
      const barcodeFolder = getFolderFromPresigned(presignedUrl);
      const message = `Click on the following link to initiate kit ID scanning: ${linkUrl}`;
      console.log(linkUrl);

      // const revertedUrl = presignedFromLink(linkUrl);
      // console.log(revertedUrl);
      // if (revertedUrl !== presignedUrl) console.log("different");

      if (!is_localhost_url(window.location.href)) {
        await sendSmsToUser(this.context.currentUser.username, message);
      }
      this.setState({barcodeScanLinkSent: true, barcodeUploadFolder: barcodeFolder, failedLoadingScannedKit: false, loadingScannedKit: false});
    } catch (err) {
      console.log("failed to get presigned URL")
      console.log(err);
    }
  }
  async onLoadScannedKit() {
    if (this.state.barcodeUploadFolder) {
      const barcodeUploadFolder: string = this.state.barcodeUploadFolder;
      this.setState({loadingScannedKit: true, failedLoadingScannedKit: false}, async () => {
        try {
          const response = await loadPreScannedKitId(barcodeUploadFolder);
          if (response.status === 200) {
            this.setState({
              kitId: response.data.kitId,
              approvedKitScan: true,
              loadingScannedKit: false,
              failedLoadingScannedKit: false
            });
          } else {
            this.setState({failedLoadingScannedKit: true, loadingScannedKit: false});
          }
        } catch (ex) {
          this.setState({failedLoadingScannedKit: true, loadingScannedKit: false});
        }
      });
    }
  }
  onDoneBarcodeEditing() {
    this.setState({
      isOpenEditBarcode: false,
      barcodeScanLinkSent: false,
      approvedKitScan: false,
      barcodeUploadFolder: undefined,
      loadingScannedKit: false,
      failedLoadingScannedKit: false
    }, () => this.updateError())
  }

  getApplyEnabled() {
    if (this.email) {
      if (!isEmail(this.email)) return false
    }
    if (this.phone) {
      if (!isMobilePhone(this.phone)) return false
    }
    if (this.kitError && isProdEnv()) return false;
    return !!(this.name && this.state && this.state.kitId && this.gender && this.birthMonth && this.birthMonth && this.feet && !isNullOrUndefined(this.inch));
  }

  updateError() {
    const error = this.getError();
    const isApplyEnabled = this.getApplyEnabled();
    this.setState({
      error,
      isApplyEnabled,
      needApproval: this.isPatientRecordChanged()
    });
  }

  onEditBarcode() {
    this.setState({isOpenEditBarcode: true});
  }

  gender: ?string;
  age: ?number;
  weight: ?number;
  feet: ?number;
  inch: ?number;
  externalId: ?string;
  email: ?string;
  phone: ?string;
  tc: ?number;
  hdl: ?number;
  ldl: ?number;
  sbp: ?number;
  dbp: ?number;
  a1c: ?number;
  bpTreated: ?boolean;
  smoker: ?boolean;
  diabetes: ?boolean;
  fhDiabetes: ?boolean;
  name: ?string;
  num: ?number;
  birthYear: ?number;
  birthMonth: ?number;
  cancelRef: any;
  patientRecordChangesApproved: boolean;
  itemHeight: string;
  kitError: ?string;

  render() {
    const labelPrefix = this.props.labelPrefix
      ? this.props.labelPrefix.toLowerCase()
      : '';
    const patientRecordLabelColor = (!this.props.adding && !this.state.patientRecordEnabled) ? COLORS.REPORT_TEXT_GRAY : COLORS.REPORT_TEXT;
    return (
      <Box>
        <Flex align="center" mt="20px">
          <Text fontWeight="bold" fontSize={18} color={COLORS.GRAY_128}>{this.props.title}</Text>
          <Spacer />
        </Flex>
        <Divider mt="20px"/>
        <Box >
          <Flex align="top"  fontSize={this.props.fontSize} mt="15px">
            <Box w={"48%"} color={patientRecordLabelColor}>
              <Flex align="center" mb="10px">
                <Text w="120px">Name:</Text>
                <Input
                  maxW="200px"
                  defaultValue={this.props.name}
                  onChange={this.onNameChange}
                  fontSize={this.props.fontSize}
                  h={this.itemHeight}
                  isDisabled={!this.state.patientRecordEnabled}
                />
              </Flex>
              <Flex align="center" mb="10px">
                <Text w="110px">Kit ID:</Text>
                <InputGroup maxW="200px">
                  <Input
                    value={this.state.kitId}
                    onChange={this.onKitChange}
                    fontSize={this.props.fontSize}
                    h={this.itemHeight}
                    isDisabled={!this.state.patientRecordEnabled}
                  />
                  <InputRightElement width='30px' ml={"10px"}>
                      <IconButton
                        icon={<EditIcon/>}
                        // icon={<Box as={FiDelete} h="18px" w="18px" mb={0} />}
                        bg="transparent"
                        aria-label="scan code"
                        title="scan code"
                        onClick={this.onEditBarcode}
                        size={"sm"}
                        // isDisabled={!this.state.patientRecordEnabled}
                        isDisabled={true}
                        visibility={"hidden"}
                      />
                  </InputRightElement>
                </InputGroup>
              </Flex>
              <Flex align="center" mb="10px">
                <Text w="120px">Gender:</Text>
                <Select
                  placeholder="select gender"
                  onChange={this.onGenderSelected}
                  defaultValue={this.gender}
                  fontSize={this.props.fontSize}
                  w="285px"
                  h={this.itemHeight}
                  isDisabled={!this.state.patientRecordEnabled}
                >
                  <option key="female" value="female">
                    female
                  </option>
                  <option key="male" value="male">
                    male
                  </option>
                </Select>
              </Flex>
              <Flex align="center" mb="10px">
                <Text w="75px">Email:</Text>
                <InputGroup maxW="183px">
                  {!this.email && <InputLeftElement pl={"5px"}>
                    <Box
                      as={AiOutlineMail}
                      h="18px"
                      w="18px"
                      mb={"3px"}
                      mt={"-1px"}
                    />
                  </InputLeftElement>
                  }
                  <Input
                      color={this.state.patientRecordEnabled ? COLORS.REPORT_TEXT : COLORS.REPORT_TEXT_GRAY}
                      ml={"5px"}
                      defaultValue={this.props.email}
                      onChange={this.onEmailChange}
                      fontSize={this.props.fontSize}
                      h={this.itemHeight}
                      isDisabled={!this.state.patientRecordEnabled}
                      placeholder={"email address"}
                      type={"email"}
                  />
                </InputGroup>
              </Flex>
              <Flex align="center" mb="10px" fontSize={this.props.fontSize} mt={0}>
                <Text w="75px">Phone:</Text>
                <Box borderColor={"gray.100"} borderWidth={1} h={"35px"} borderRadius={"5px"} fontSize={"13px"} py={"7px"} pl={"10px"} w={"178px"} color={COLORS.REPORT_TEXT}>
                  <PhoneInput
                      borderWidth={0}
                      placeholder="phone number"
                      value={this.props.phone}
                      onChange={this.onPhoneChange}
                      rules={{ validate: isPossiblePhoneNumber }}
                      isDisabled={!this.state.patientRecordEnabled}
                  />
                </Box>
              </Flex>
            </Box>
            <Box w={"48%"} ml={"4%"} color={patientRecordLabelColor}>
              <Flex align="center" mb="10px">
                <Text w="110px">{`${labelPrefix}Birth Year:`}</Text>
                <NumberInput
                  maxW="90px"
                  defaultValue={undefinedForNan(this.props.birthYear)}
                  min={traitRanges.birthYear.lowLimit}
                  max={new Date().getFullYear()}
                  onChange={this.onBirthYearChange}
                  isDisabled={!this.state.patientRecordEnabled}
                >
                  <NumberInputField
                    fontSize={this.props.fontSize}
                    h={this.itemHeight}
                  />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </Flex>
              <Flex align="center" mb="10px">
                <Text w="110px">{`${labelPrefix}Birth Month:`}</Text>
                <NumberInput
                  maxW="90px"
                  defaultValue={undefinedForNan(this.props.birthMonth)}
                  min={traitRanges.birthMonth.lowLimit}
                  max={traitRanges.birthMonth.highLimit}
                  onChange={this.onBirthMonthChange}
                  isDisabled={!this.state.patientRecordEnabled}
                >
                  <NumberInputField
                    fontSize={this.props.fontSize}
                    h={this.itemHeight}
                  />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </Flex>
              <Flex align="center" >
                <Text w="55px" mr={"5px"}>{`${labelPrefix}Height:`}</Text>
                <NumberInput
                  w="65px"
                  defaultValue={undefinedForNan(this.feet)}
                  min={traitRanges.feet.lowLimit}
                  max={traitRanges.feet.highLimit}
                  onChange={this.onFeetChange}
                  step={1}
                  isDisabled={!this.state.patientRecordEnabled}
                >
                  <NumberInputField
                    fontSize={this.props.fontSize}
                    h={this.itemHeight}
                />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
                <NumberInput
                  maxW="65px"
                  ml={"10px"}
                  defaultValue={undefinedForNan(this.inch)}
                  min={traitRanges.inch.lowLimit}
                  max={traitRanges.inch.highLimit}
                  onChange={this.onInchChange}
                  step={1}
                  isDisabled={!this.state.patientRecordEnabled}
                >
                  <NumberInputField
                    fontSize={this.props.fontSize}
                    h={this.itemHeight}
                  />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </Flex>
              <Flex>
                <Text ml="70px" fontSize={10} >feet</Text>
                <Text ml="60px" fontSize={10} >inch</Text>
              </Flex>
              <Flex align="center" mt="10px">
                <Text w="70px">Patient ID:</Text>
                <Input
                  maxW="130px"
                  defaultValue={this.props.externalId}
                  onChange={this.onExternalIdChange}
                  fontSize={this.props.fontSize}
                  h={this.itemHeight}
                  isDisabled={!this.state.patientRecordEnabled}
                  placeholder="Patient ID"
                />
              </Flex>
              <Text  ml={"70px"} mb="10px" fontSize={10} color={COLORS.REPORT_TEXT_GRAY}>patient ID used by clinic</Text>
            </Box>
            {!this.props.adding && (
                <IconButton
                    icon={<EditIcon/>}
                    onClick={this.onEditPatientRecord}
                    bg={"transparent"}
                    borderWidth={1}
                    borderColor={"gray.100"}
                    aria-label={"edit"}
                    title={"edit patient record"}
                />
            )}
          </Flex>
        </Box>
        <Divider my={"10px"}/>
        <Flex align="top" mt="20px" fontSize={this.props.fontSize}>
          <Box w={"48%"}>
            {/*<Stack my="20px" spacing="10px" fontSize={this.props.fontSize} >*/}
              <Flex align="center" mb="10px">
                <Text w="110px">{`${labelPrefix}Weight:`}</Text>
                <NumberInput
                  maxW="90px"
                  defaultValue={undefinedForNan(
                    roundWithPrecision(this.props.weight, 1)
                  )}
                  min={traitRanges.weight.lowLimit}
                  max={traitRanges.weight.highLimit}
                  onChange={this.onWeightChange}
                  step={1}
                >
                    <NumberInputField
                      fontSize={this.props.fontSize}
                      h={this.itemHeight}
                    />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
                <Text ml="10px" fontSize={10}>pound</Text>
              </Flex>
              <Flex align="center" mb="10px">
                <Text w="110px">{`${labelPrefix}BMI:`}</Text>
                <NumberInput
                  maxW="90px"
                  precision={1}
                  value={undefinedForNan(this.state.bmi)}
                  defaultValue={undefinedForNan(this.state.bmi)}
                  min={traitRanges.bmi.lowLimit}
                  max={traitRanges.bmi.highLimit}
                  onChange={this.onBmiChange}
                  step={1}
                  disabled
                >
                  <NumberInputField
                    fontSize={this.props.fontSize}
                    h={this.itemHeight}
                  />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
                <Text ml="10px" fontSize={10}>units</Text>
              </Flex>
              <Flex align="center" mb="10px">
                <Text w="110px">{`${labelPrefix}SBP:`}</Text>
                <NumberInput
                  maxW="90px"
                  defaultValue={this.props.sbp}
                  min={traitRanges.sbp.lowLimit}
                  max={traitRanges.sbp.highLimit}
                  onChange={this.onSbpChange}
                  step={1}
                >
                  <NumberInputField
                    fontSize={this.props.fontSize}
                    h={this.itemHeight}
                  />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
                <Text ml="10px" fontSize={10}>mmHg</Text>
              </Flex>
              <Flex align="center" mb="10px">
                <Text w="110px">{`${labelPrefix}DBP:`}</Text>
                <NumberInput
                  maxW="90px"
                  defaultValue={undefinedForNan(this.props.dbp)}
                  min={traitRanges.dbp.lowLimit}
                  max={traitRanges.dbp.highLimit}
                  onChange={this.onDbpChange}
                  step={1}
                >
                  <NumberInputField
                    fontSize={this.props.fontSize}
                    h={this.itemHeight}
                  />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
                <Text ml="10px" fontSize={10}>mmHg</Text>
              </Flex>
            {/*</Stack>*/}
          </Box>
          <Box w={"48%"} ml={"4%"}>
            <Flex align="center" mb="10px">
              <Text w="110px">{`${labelPrefix}Total Cholesterol:`}</Text>
              <NumberInput
                maxW="90px"
                defaultValue={undefinedForNan(
                  roundWithPrecision(this.props.tc, 0)
                )}
                min={traitRanges.tc.lowLimit}
                max={traitRanges.tc.highLimit}
                onChange={this.onTcChange}
                step={1}
              >
                <NumberInputField
                  fontSize={this.props.fontSize}
                  h={this.itemHeight}
                />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
              <Text ml="10px" fontSize={10}>mg/dL</Text>
            </Flex>
            <Flex align="center" mb="10px">
              <Text w="110px">{`${labelPrefix}HDL:`}</Text>
              <NumberInput
                maxW="90px"
                defaultValue={undefinedForNan(
                  roundWithPrecision(this.props.hdl, 0)
                )}
                min={traitRanges.hdl.lowLimit}
                max={traitRanges.hdl.highLimit}
                onChange={this.onHdlChange}
                step={1}
              >
                <NumberInputField
                  fontSize={this.props.fontSize}
                  h={this.itemHeight}
                />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
              <Text ml="10px" fontSize={10}>mg/dL</Text>
            </Flex>
            <Flex align="center" mb="10px">
              <Text w="110px">{`${labelPrefix}LDL:`}</Text>
              <NumberInput
                maxW="90px"
                defaultValue={undefinedForNan(
                  roundWithPrecision(this.props.ldl, 0)
                )}
                min={traitRanges.ldl.lowLimit}
                max={traitRanges.ldl.highLimit}
                onChange={this.onLdlChange}
                step={1}
              >
                <NumberInputField
                  fontSize={this.props.fontSize}
                  h={this.itemHeight}
                />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
              <Text ml="10px" fontSize={10}>mg/dL</Text>
            </Flex>
            <Flex align="center" mb="10px">
              <Text w="110px">{`${labelPrefix}A1C`}:</Text>
              <NumberInput
                maxW="90px"
                defaultValue={undefinedForNan(
                  roundWithPrecision(this.props.a1c, 1)
                )}
                min={traitRanges.a1c.lowLimit}
                max={traitRanges.a1c.highLimit}
                onChange={this.onA1cChange}
                step={0.1}
              >
                <NumberInputField
                  fontSize={this.props.fontSize}
                  h={this.itemHeight}
                />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
              <Text ml="10px" fontSize={10}>%</Text>
            </Flex>
          </Box>
        </Flex>
        <Stack fontSize={this.props.fontSize} mt={"20px"} w={"97%"}>
          <LabeledSwitch
            mt="10px"
            isChecked={!!this.props.bpTreated}
            leftLabel="Treated for high blood pressure"
            leftLabelWidth="200px"
            callbackGetColor={
              IdentifiedPatientAttributesImpl.getSwitchColor
            }
            callbackOnValueChange={this.onBpTreatedSwitchChange}
            callbackRightLabel={
              IdentifiedPatientAttributesImpl.getSwitchText
            }
            enabled
          />
          <LabeledSwitch
            mt="10px"
            isChecked={!!this.props.diabetes}
            leftLabel="Diabetes"
            leftLabelWidth="200px"
            callbackGetColor={
              IdentifiedPatientAttributesImpl.getSwitchColor
            }
            callbackOnValueChange={this.onDiabetesSwitchChange}
            callbackRightLabel={
              IdentifiedPatientAttributesImpl.getSwitchText
            }
            enabled
          />
          <LabeledSwitch
            mt="10px"
            isChecked={!!this.props.fhDiabetes}
            leftLabel="Diabetes family history"
            leftLabelWidth="200px"
            callbackGetColor={
              IdentifiedPatientAttributesImpl.getSwitchColor
            }
            callbackOnValueChange={this.onFhDiabetesSwitchChange}
            callbackRightLabel={
              IdentifiedPatientAttributesImpl.getSwitchText
            }
            enabled
          />
          <LabeledSwitch
            mt="10px"
            isChecked={!!this.props.smoker}
            leftLabel="Smoker"
            leftLabelWidth="200px"
            callbackGetColor={
              IdentifiedPatientAttributesImpl.getSwitchColor
            }
            callbackOnValueChange={this.onSmokerSwitchChange}
            callbackRightLabel={
              IdentifiedPatientAttributesImpl.getSwitchText
            }
            enabled
          />
        </Stack>
          {this.state.error && (
            <Alert
              status={this.state.isApplyEnabled ? 'warning' : 'error'}
              mt="20px"
              borderRadius="10px"
            >
              <AlertIcon />
              {this.state.error}
            </Alert>
          )}
        <Flex my="20px">
          <Spacer />
          <ActionButton name="Cancel" onClick={this.onCancel} mr="20px"/>
          <ActionButton
            name={this.props.adding ? "Next >" : "Apply"}
            onClick={this.onApply}
            isDisabled={!this.state.isApplyEnabled}
            minW={"100px"}
          />
          <Spacer />
        </Flex>
        <Modal blockScrollOnMount isOpen={this.state.isOpenEditBarcode} onClose={this.onDoneBarcodeEditing} size={"xl"}>
          <ModalOverlay />
          <ModalContent color={COLORS.REPORT_TEXT}>
            <ModalBody color={COLORS.REPORT_TEXT}>
              <Box m={"20px"}>
                <Text fontSize={20} color={COLORS.REPORT_TEXT_GRAY} fontWeight={"bold"}>KIT ID BARCODE SCANNING</Text>
                {!this.state.barcodeScanLinkSent && (
                    <Box fontSize={12}>
                      <Text mt="30px">You can scan the kit ID using your mobile device.</Text>
                      <Text mt="10px">Click the 'Send link' button to get a link that initiates the scanning process on your mobile device.</Text>
                    </Box>
                )}
                {this.state.barcodeScanLinkSent && (
                  <Box fontSize={12}>
                    <Text mt="30px">We have just sent you a link to your mobile device. The link is valid for 15 min.</Text>
                    <Text mt="5px">On your mobile device, click on the link to trigger kit ID reading.</Text>
                    <Text mt="20px">When the kit ID is scanned click on the 'Load Scanned Kit ID' button below.</Text>
                  </Box>
                )}
                {!this.state.barcodeScanLinkSent && <Box minH={"60px"}/>}
                {this.state.barcodeScanLinkSent && (
                  <Flex align={"center"} my={"20px"}>
                    <Text>Kit ID: </Text>
                    {!this.state.approvedKitScan && <Text px="10px" fontSize={18} fontWeight={"bold"} minH="36px" borderColor={"gray.100"} borderWidth={1} minW="82%" ml={"3%"}></Text>}
                    {this.state.approvedKitScan && <Text px="10px" pt={"4px"} fontSize={18} fontWeight={"bold"} minH="36px" borderColor={"gray.100"} borderWidth={1} minW="82%" ml={"3%"}>{this.state.kitId}</Text>}
                  </Flex>
                )}
                {this.state.loadingScannedKit && <Text fontSize={13}>loading ...</Text>}
                {this.state.failedLoadingScannedKit && <Text color={COLORS.RED_STATUS} fontSize={13}>Failed to load kit ID</Text>}
                {this.state.approvedKitScan && <Text color={COLORS.GREEN_STATUS} fontSize={13}>Kit ID loaded successfully</Text>}
                <Flex>
                  {!this.state.barcodeScanLinkSent && <ActionButton mt="20px" onClick={this.onSendLink} name="Send link"/>}
                  {this.state.barcodeScanLinkSent && <ActionButton mt="20px" onClick={this.onLoadScannedKit} name={'Load Scanned Kit ID'} isDisabled={this.state.loadingScannedKit}/>}
                  <Spacer/>
                  <ActionButton mt="20px" onClick={this.onDoneBarcodeEditing} name={"Done"} />
                </Flex>
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>
        <AlertDialog
          isOpen={this.state.showApproval}
          leastDestructiveRef={this.cancelRef}
          onClose={this.onApply}
          color={COLORS.REPORT_TEXT}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize='lg' fontWeight='bold' color={COLORS.REPORT_TEXT}>
                Patient record changes:
              </AlertDialogHeader>

              <AlertDialogBody color={COLORS.REPORT_TEXT}>
                <>
                  <Text>You have made changes to the patient record.</Text>
                  <Text mt={"5px"}>Are you sure you want to save the changes?</Text>
                </>
              </AlertDialogBody>

              <AlertDialogFooter color={COLORS.REPORT_TEXT}>
                <ActionButton name="Cancel"  ref={this.cancelRef} onClick={this.onCancelApproval}/>
                <ActionButton name="Approve" onClick={this.onApproval} ml={3}/>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </Box>
    );
  }
}

IdentifiedPatientAttributesImpl.propTypes = {
  fontSize: PropTypes.number,
  showBorder: PropTypes.bool,
  title: PropTypes.string,
  name: PropTypes.string,
  kitId: PropTypes.string,
  gender: PropTypes.bool,
  age: PropTypes.number,
  sbp: PropTypes.number,
  dbp: PropTypes.number,
  hdl: PropTypes.number,
  ldl: PropTypes.number,
  tc: PropTypes.number,
  bmi: PropTypes.number,
  feet: PropTypes.number,
  inch: PropTypes.number,
  weight: PropTypes.number,
  a1c: PropTypes.number,
  smoker: PropTypes.bool,
  riskTrait: PropTypes.string,
  riskLevel: PropTypes.string,
  bpTreated: PropTypes.bool,
  diabetes: PropTypes.bool,
  fhDiabetes: PropTypes.bool,
  labelPrefix: PropTypes.string,
  externalId: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string
};
// $FlowFixMe[prop-missing]
IdentifiedPatientAttributesImpl.defaultProps = {
  fontSize: 12,
  showBorder: true,
  title: 'Patient attributes',
  name: undefined,
  kitId: undefined,
  gender: undefined,
  age: undefined,
  sbp: undefined,
  dbp: undefined,
  hdl: undefined,
  ldl: undefined,
  tc: undefined,
  feet: undefined,
  inch: undefined,
  weight: undefined,
  bmi: undefined,
  a1c: undefined,
  smoker: undefined,
  riskTrait: undefined,
  riskLevel: undefined,
  bpTreated: false,
  diabetes: false,
  fhDiabetes: false,
  num: undefined,
  birthYear: undefined,
  birthMonth: undefined,
  labelPrefix: '',
  externalId: undefined,
  email: undefined,
  phone: undefined
};
