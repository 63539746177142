// @flow
import React, { useCallback } from 'react';
import {Image} from '@chakra-ui/react';

import withNavigation from '../main/WithRoutes';
import COLORS from '../common/colors';
import LeftBarButton from "../common/left-bar/LeftBarButton";

type Props = {
  name: string,
  idToken: string,
  level: number,
  isDisabled: boolean,
  icon: any,
  selected: boolean,
  onClick: any,
  isMobile: boolean
};


export function LeftBarItemImpl(props: Props) {
  // const buttonProps: Object = { ...props };
  const ml = props.level <= 1 ? 0 : `${props.level * 5}px`;
  let fontSize = props.level > 0 ? 13 : 14;
  const {isMobile} = props;
  if (isMobile) { fontSize -= 2; }
  const imageComp = useCallback(
    (image) => <Image h="16px" w="16px" src={image} mr={isMobile ? "0px" : "10px"}/>,
    [isMobile]
  );

  const {onClick, idToken} = props;
  const onButtonClick = useCallback(() => {
    onClick(idToken)
  }, [onClick, idToken])

  return (
    <LeftBarButton
      name={props.name}
      onClick={onButtonClick}
      minW={isMobile ? "40px" : "200px"}
      justifyContent={"left"}
      ml={isMobile ? 0 : ml}
      pl={0}
      isDisabled={props.isDisabled}
      leftIcon={props.icon ? imageComp(props.icon) : null}
      bg={props.selected ? COLORS.REPORT_SELECTED_ITEM : "transparent"}
      fontWeight={props.selected || props.level === 0 ? "bold" : "normal"}
      fontSize={fontSize}
      color={"gray.600"}
      h={"30px"}
    />
  );
}

export default withNavigation(LeftBarItemImpl);
