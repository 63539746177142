// @flow
export const consumerUserAttributesFlags = {
  gender: 1,
  birthYear: 2,
  birthMonth: 4,
  height: 8,
  weight: 16,
  hypertensive: 32,
  hypercholesterol: 64,
  exercise: 128,
  diabetes: 256,
  fhDiabetes: 512,
  smoker: 1024,
  age: 2048,
  bmi: 4096
};


/* eslint-disable no-bitwise */

/* eslint-disable no-bitwise */
export const consumerCadCalculatorUserAttributes: number =
  consumerUserAttributesFlags.gender |
  consumerUserAttributesFlags.age |
  consumerUserAttributesFlags.bmi |
  consumerUserAttributesFlags.hypertensive |
  consumerUserAttributesFlags.hypercholesterol |
  consumerUserAttributesFlags.exercise |
  consumerUserAttributesFlags.diabetes |
  consumerUserAttributesFlags.smoker;

/* eslint-disable no-bitwise */
export const consumerBpCalculatorUserAttribute: number =
  consumerUserAttributesFlags.gender |
  consumerUserAttributesFlags.age |
  consumerUserAttributesFlags.bmi;

/* eslint-disable no-bitwise */
export const consumerHypercholesterolCalculatorUserAttribute: number =
  consumerUserAttributesFlags.gender |
  consumerUserAttributesFlags.age |
  consumerUserAttributesFlags.bmi |
  consumerUserAttributesFlags.smoker;


/* eslint-disable no-bitwise */
export const consumerMandatoryUserAttributes: number =
  consumerUserAttributesFlags.gender |
    consumerUserAttributesFlags.birthYear |
    consumerUserAttributesFlags.birthMonth |
    consumerUserAttributesFlags.height |
    consumerUserAttributesFlags.weight;
