// @flow
import React from 'react';
import RiskChartsSection from '../../common/reports/RiskChartsSection';

type Props = {
  percentile: number,
  quintile: number,
  oddsRatios: Array<number>,
  deciles: Array<number>
};

export default function CkdChartsSection(props: Props) {
  return (
    <RiskChartsSection
      percentile={props.percentile}
      quintile={props.quintile}
      deciles={props.deciles}
      oddsRatios={props.oddsRatios}
      minY={3}
      maxY={5.5}
      yTickInterval={0.5}
      shortTrait="CKD"
      longTrait="chronic kidney disease"
    />
  );
}
