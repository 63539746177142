// @flow
import React from 'react';
import { Box, Text, Flex } from '@chakra-ui/react';

type Props = {
  precedingLine: boolean
};

export default function IconAttribute(props: Props) {
  return (
    <Box my="30px" color="gray.500" fontSize={12}>
      {props.precedingLine && (
        <Flex mt="20px" bg="gray.100">
          <Text mx="20px" my="1px" />
        </Flex>
      )}
      <div>
        <i>
          Icons made by{' '}
          <a href="https://www.freepik.com" title="Freepik">
            Freepik
          </a>{' '}
          from{' '}
          <a href="https://www.flaticon.com/" title="Flaticon">
            www.flaticon.com
          </a>
        </i>
      </div>
    </Box>
  );
}
