// @flow
import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import COLORS from '../../common/colors';

require('highcharts/modules/annotations')(Highcharts);
require('highcharts/modules/treemap')(Highcharts);
require('highcharts/modules/heatmap')(Highcharts);

type Props = {
  title: string,
  subTypeScoring: Object
};

export default function DiabetesSubtypeChartImpl(props: Props) {
  const subtypeSeries = [
    {
      type: 'treemap',
      layoutAlgorithm: 'stripes',
      alternateStartingDirection: true,
      levels: [
        {
          level: 1,
          layoutAlgorithm: 'stripes',
          layoutStartingDirection: true,
          dataLabels: {
            enabled: true,
            align: 'center',
            verticalAlign: 'top',
            style: {
              fontSize: '11px',
              fontWeight: 300,
              fontStyle: 'italic'
            }
          }
        },
        {
          level: 2,
          layoutAlgorithm: 'stripes',
          layoutStartingDirection: false,
          dataLabels: {
            enabled: true,
            align: 'center',
            verticalAlign: 'center',
            style: {
              fontSize: '12px',
              fontWeight: 600,
              textOutline: 'none'
            },
            y: 30
          }
        }
      ],
      data: [
        {
          id: 'A',
          name: 'Adverse impact on \u03B2 cell function',
          color: 'white'
        },
        {
          name: 'High proinsulin',
          parent: 'A',
          value: 30,
          colorValue: props.subTypeScoring.betacell
        },
        {
          name: 'Low proinsulin',
          parent: 'A',
          value: 30,
          colorValue: props.subTypeScoring.proinsulin
        },
        {
          id: 'B',
          name: 'Reduced insulin sensitivity',
          color: 'white'
        },
        {
          name: 'Lipodystrophy',
          parent: 'B',
          value: 20,
          colorValue: props.subTypeScoring.lipodystrophy
        },
        {
          name: 'Obesity',
          parent: 'B',
          value: 20,
          colorValue: props.subTypeScoring.obesity
        },
        {
          name: 'Liver/lipid',
          parent: 'B',
          value: 20,
          colorValue: props.subTypeScoring.liver
        }
      ]
    }
  ];
  const options = {
    credits: {
      enabled: false
    },
    chart: {
      backgroundColor: 'transparent',
      height: '250px',
      marginLeft: 0
    },
    title: {
      text: props.title,
      style: {
        color: COLORS.HIGHCHARTS_TITLE,
        fontSize: '12px',
        fontWeight: 'bold'
      }
    },
    // legend: false,
    series: subtypeSeries,
    colorAxis: {
      minColor: '#FFFFFF',
      maxColor: Highcharts.getOptions().colors[5]
    }
  };
  return <HighchartsReact highcharts={Highcharts} options={options} />;
}
