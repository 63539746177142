// @flow

const t2dReportStrings = {
  WHAT_IS_T2D_TEXT1:
    'Type 2 diabetes mellitus is a common condition that causes the ' +
    'level of sugar (glucose) in the blood to become too high. Common ' +
    'symptoms may include increased thirst, frequent urination, ' +
    'unexplained tiredness and sore that don’t heal. Often symptoms ' +
    'develop slowly. Long-term complications from high blood sugar ' +
    'include heart disease, strokes, diabetic retinopathy which can ' +
    'result in blindness, kidney failure and poor blood flow in the ' +
    'limbs which may lead to amputations',
  WHAT_IS_T2D_TEXT2:
    'Genetics plays a role and multiple genes are involved, with each ' +
    'being a small contributor to an increased probability of ' +
    'becoming a type 2 diabetic. More than 550 single nucleotide ' +
    'polymorphisms (SNPs) had been found contributing to the risk of ' +
    'type 2 diabetes',
  WHAT_IS_T2D_REF:
    'Vos, Theo; et al. (October 2016). Global, ' +
    '  regional, and national incidence, prevalence, and years lived ' +
    '  with disability for 310 diseases and injuries, 1990-2015: a ' +
    '  systematic analysis for the Global Burden of Disease Study ' +
    '  2015',
  HOW_DID_WE_CALC_TEXT1:
    'OpenDNA calculates your score based on analysis of your ' +
    'genetic variants. Genetic variation is the difference in DNA ' +
    'sequences between individuals within a population. These are the ' +
    'differences in DNA that make every individual different. ' +
    'OpenDNA bases the calculation on a list of 5,053,015 variants that are known to be associated ' +
    'with type 2 diabetes and calculates a Polygenic Risk Score (PRS) ' +
    'based on the contribution of each genetic variant to the overall ' +
    'genetic risk for having type 2 diabetes. ',
  HOW_DID_WE_CALC_TEXT2:
    'Once your PRS is calculated it is compared to a group of about ' +
    '338,000 people to calculate your relative ' +
    'risk of having type 2 diabetes compared to that population.',
  HOW_DID_WE_CALC_REF:
    'Xue, A., Wu, Y., Zhu, Z. et al. Genome-wide association ' +
    'analyses identify 143 risk variants and putative regulatory ' +
    'mechanisms for type 2 diabetes. Nat Commun 9, 2941 (2018). ' +
    'https://doi.org/10.1038/s41467-018-04951-w ',
  DISCLAIMER1:
    'THIS REPORT MAY NOT INCLUDE EVERY GENETIC MARKER RELATED TO TYPE 2 DIABETES MELLITUS.',
  DISCLAIMER2:
    'THIS TEST IS NOT DIAGNOSTIC, IT IS A PREDICTION BASED ON PREDISPOSITION. ' +
    'AN INCREASED RISK SCORE DOES NOT MEAN THAT A PERSON WILL DEFINITELY DEVELOP A DISEASE ' +
    'AND A DECREASED RISK SCORE DOES NOT MEAN THAT A PERSON WILL DEFINITELY NOT DEVELOP A DISEASE.',
  DISCLAIMER3:
    'THE TEST CANNOT REPLACE MEDICAL TESTING OR MEDICAL MANAGEMENT THROUGH A HEALTHCARE PROVIDER.',
  DISCLAIMER4:
    'THE RISK MAY ALSO BE INFLUENCED BY OTHER LIFESTYLE FACTORS, OTHER CLINICAL FACTORS AND ' +
    'GENETIC VARIANTS THAT ARE NOT INCLUDED IN THE ANALYSIS OR WERE NOT REPORTED PROPERLY.',
  DISCLAIMER5:
    'YOUR RISK IS COMPARED TO A POPULATION WHERE THE MAJORITY IS OF EUROPEAN DESCENT. ' +
    'THE ACCURACY OF THE RESULTS MAY VARY IF YOU HAVE A DIFFERENT BACKGROUND.',
  DISCLAIMER6:
    'TEST RESULTS SHOULD BE INTERPRETED BY A HEALTHCARE PROVIDER. MEDICAL MANAGEMENT AND ' +
    'DECISION-MAKING FOR PREVENTION PRACTICES SHOULD BE DONE IN THE CONTEXT OF THE PATIENT’S ' +
    'FULL CLINICAL HISTORY AND NOT BASED SOLELY ON THE TEST RESULTS.',
  RELATIVE_TO_POPULATION_INFO_HEADER: 'Relative to Population',
  RELATIVE_TO_POPULATION_INFO_TEXT:
    'An overall risk score of the patient is compared to a reference ' +
    'scale built from overall risk scores of ~30K individuals ' +
    'with diverse risk factors. The scale is divided to ' +
    'percentiles, and the patient risk percentile is determined by ' +
    "comparing the individual's risk score against the threshold risk values of " +
    'each percentile. ',
  RELATIVE_TO_POPULATION_OR_RELATIVE_INFO_FOOTER:
    'See also more details about the overall risk score calculation in the footer of the overall risk frame',
  RELATIVE_TO_CURRENT_INFO_HEADER: 'Relative to Current',
  RELATIVE_TO_CURRENT_INFO_TEXT:
    'Current risk factor values of the patient are used to calculate the baseline. ' +
    'Upon risk factor changes, the risk is recalculated and compared to the baseline, to show the ' +
    'relative increase or decrease as a result of the changes',
  GENETIC_RISK_INFO_TEXT:
    'The polygenic risk score (PRS) of the patient is calculated by analyzing ' +
    'more than 120K variants known to be associated with T2D. ' +
    'The PRS is then compared to a reference scale, built from scores of ~338K individuals. ' +
    'The scale is divided into percentiles that reflect the genetic risk level. ' +
    "The patient risk percentile is determined by comparing the individual's PRS value against the threshold risk values of each percentile.",
  T2D_GENETIC_RISK_IN_POPULATION:
    'The graph shows the risk for having type 2 diabetes vs. the genetic risk level. Genetic risk (PRS) was calculated for ' +
    'more than 330K individuals. The scores were divided into deciles and the risk of having type 2 diabetes was calculated for each decile. ' +
    "Gray bars indicate the risk level per decile. The highlighted bar represents lifetime absolute patient risk level based on genetics.",
  T2D_OVERALL_RISK_IN_POPULATION:
    'The graph shows the risk for having type 2 diabetes vs. the overall risk level. Overall risk level takes into account various risk factors including the genetic risk as well as other risk factors ' +
    'such as age, gender, cholesterol levels, blood pressure levels, blood pressure treatment status, smoking habits and having diabetes. The overall risk level was calculated for ' +
    'more than 267K individuals with various risk factors. The scores were divided into deciles and the risk of having type 2 diabetes was calculated for each decile. ' +
    "Gray bars indicate the risk level per decile. The highlighted bar represents lifetime absolute patient risk based on aforementioned risk factors including genetics.",
  MODEL_YOUNGER_PARTICIPANT: "This risk assessment model is based on data of participants of age range 38-73 and therefore can assess the risk using an age within this range. The assessed risk is based on the minimum age (38). As risk is associated with ageing, a younger participant will have lower risk than the assessed.",
  MODEL_OLDER_PARTICIPANT: "This risk assessment model is based on data of participants of age range 38-73 and therefore can assess the risk using an age within this range. The assessed risk is based on the maximum age (55). As risk is associated with ageing, an older participant will have higher risk than the assessed.",
  MODEL_AGE_POP_INFO_TEXT: {
    "header": "Age range limitation",
    "text": "This risk assessment model is based on data of participants of age range 38-73 and therefore can only assess the risk using an age within this range. As risk is associated with ageing, a participant younger than 38 will have lower risk than the assessed and an participant older than 73 will have higher risk than the assessed.",
    "footer": "NOTE: age range limitation"
  },
  MODEL_OVERALL_RISK_INFO:
    'Calculations are based on a machine learning (AI) model derived' +
    'from analyzing data of ~30K people of European descent and' +
    'includes genetic risk (PRS), age, sex, cholesterol levels, blood' +
    'pressure levels, treatment for high blood pressure, diabetes' +
    'family history and smoking status. The estimated relative risk' +
    'change for developing type 2 diabetes mellitus is a statistical' +
    'measurement and reflects the risk change in the test population.' +
    'Type 2 diabetes mellitus risk depends on other factors such as' +
    'physical activity, stress and other factors and therefore the' +
    'values presented serve as estimations only.'

};

export default t2dReportStrings;
