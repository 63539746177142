export const consentStrings = {
	CONSENT1:
			'Giving consent by checking the appropriate box below means that you agree ' +
			'to let OpenDNA use your de-identified individual-level with approved researchers as Research Information. ',
	INFORMATION_ITEMS:
			['Your genetic information', 'Your age', 'Clinical and demographic information you enter into the website '],
	DEINDETIFIED_MEANING:
			'De-identified means that we strip the data of information that could directly identify you ' +
			'(such as name, email address) and connect any study information you provide by using a random code. ' +
			'Any Research Information you allow us to share will be associated with that code, not with your name.',

	DISCLAIMERS: [
		'THE REPORTS MAY NOT INCLUDE EVERY GENETIC MARKER RELATED TO EACH OF THE DISEASE.',

		'THE TESTS ARE NOT DIAGNOSTIC, BUT PRESENT A PREDICTION BASED ON PREDISPOSITION. AN INCREASED RISK SCORE ' +
		'DOES NOT MEAN THAT A PERSON WILL DEFINITELY DEVELOP A DISEASE AND A DECREASED RISK SCORE DOES NOT MEAN THAT' +
		'A PERSON WILL DEFINITELY NOT DEVELOP A DISEASE.',

		'THE TESTS CANNOT REPLACE MEDICAL TESTING OR MEDICAL MANAGEMENT THROUGH A HEALTHCARE PROVIDER.',
		'THE RISKS MAY ALSO BE INFLUENCED BY OTHER LIFESTYLE FACTORS, OTHER CLINICAL FACTORS AND GENETIC VARIANTS ' +
		'THAT ARE NOT INCLUDED IN THE ANALYSIS OR WERE NOT REPORTED PROPERLY.',

		'YOUR RISK IS COMPARED TO A POPULATION WHERE THE MAJORITY IS OF EUROPEAN DESCENT. ' +
		'THE ACCURACY OF THE RESULTS MAY VARY IF YOU HAVE A DIFFERENT BACKGROUND.',

		'TEST RESULTS SHOULD BE INTERPRETED BY A HEALTHCARE PROVIDER. MEDICAL MANAGEMENT AND DECISION-MAKING ' +
		'FOR PREVENTION PRACTICES SHOULD BE DONE IN THE CONTEXT OF THE PATIENT’S FULL CLINICAL HISTORY AND NOT BASED ' +
		'SOLELY ON THE TEST RESULTS.'
	]
}
