// @flow
import React from 'react';
import {Box, Text, Flex, Image, Spacer} from "@chakra-ui/react";
import logo from "../images/opendna_logo_large.png";
import COLORS from "../common/colors";

export default function AthenaLoggedOff() {
  console.log("athenahealth loggedoff")
  return (
      <Box mt="20px" align="center" mx={"8%"}>
        <Text color="gray.600" fontSize={24} textAlign={"left"} my={"30px"}>
          Patient context has changed
        </Text>
        <Text color={COLORS.REPORT_TEXT} fontSize={18} textAlign={"left"} my={"30px"}>
          In order to see patient results, close this window and relaunch OpenDNA application from the patient chart.
        </Text>
        <Flex my={"30px"}>
          <Spacer/>
          <Box mt={"50px"}>
            <Image src={logo} h="128px" alt="Open-DNA" ml="100px" />
          </Box>
          <Spacer/>
        </Flex>
      </Box>
  );
}

