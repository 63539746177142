// @flow
import React, {useState, useCallback} from 'react';
import {Box, Flex, Text} from '@chakra-ui/react';
import {calcAccScore} from "./calculator/acc";
import COLORS from "../common/colors";
import getPatientAge from "../utils/age";
import EditablePatientAttributes from "../common/reports/EditablePatientAttributes";
import {accCalculatorUserAttribute} from "../common/reports/report-common";
import {mgdl2mmolL, mmolL2mgdl} from "../utils/cholesterol";
import AccVsOptimalChartImpl from "../common/AccVsOptimal";

type Props = {
  patientInfo: Object,
	footer?: any
};

export default function AccPanel(props: Props) {
  const [age, setAge] = useState(getPatientAge(props.patientInfo.birth_year, props.patientInfo.birth_month));
  const [sbp, setSbp] = useState(props.patientInfo.sbp);
  const [tc, setTc] = useState(props.patientInfo.tc);
  const [hdl, setHdl] = useState(props.patientInfo.hdl);
  const [diabetes: boolean, setDiabetes] = useState(props.patientInfo.diabetes ?? false);
  const [smoker: boolean, setSmoker] = useState(props.patientInfo.smoker ?? false);
  const [bpTreated: boolean, setBpTreated] = useState(props.patientInfo.bp_treated ?? false);
	const onAgeChange = useCallback((newAge: number) => setAge(newAge), []);
	const onSbpChange = useCallback((newSbp: number) => setSbp(newSbp), []);
	const onHdlChange = useCallback((newHdl: number) => setHdl(mgdl2mmolL(newHdl)), []);
	const onTcChange = useCallback((newTc: number) => setTc(mgdl2mmolL(newTc)), []);
	const onDiabetesChange = useCallback((newDiabetes: boolean) => setDiabetes(newDiabetes), []);
	const onSmokerChange = useCallback((newSmoker: boolean) => setSmoker(newSmoker), []);
	const onBpTreatedChange = useCallback((newBpTreated: boolean) => setBpTreated(newBpTreated), []);
	const getAccColor = useCallback(score=> {
		if (isNaN(score)) return COLORS.REPORT_TEXT_GRAY;
		if (score < 5) return COLORS.GREEN_STATUS;
		if (score <= 7.5) return COLORS.YELLOW_STATUS;
		if (score <= 20) return COLORS.ORANGE_STATUS;
		return COLORS.RED_STATUS;
	}, [])
	const getAccLevelText = useCallback(score=> {
		if (isNaN(score)) return "";
		if (score < 5) return "LOW";
		if (score <= 7.5) return "BORDERLINE";
		if (score <= 20) return "INTERMEDIATE";
		return "HIGH";
	}, [])
	const acc = calcAccScore(
		props.patientInfo.sex,
		age ?? 38,
		sbp,
		tc,
		hdl,
		diabetes ? 1 : 0,
		smoker ? 1 : 0,
		bpTreated ? 1 : 0
	);
	const accOptimal = calcAccScore(
		props.patientInfo.sex,
		age ?? 38,
		120,
		mgdl2mmolL(150),
		mgdl2mmolL(50),
		0,
		0,
		0
	);
	let accScore = "missing data";
	let accOptimalScore = "missing data";
	if (acc && !isNaN(acc)) {
		accScore = `${(acc * 100).toFixed(1)}%`
	}
	if (accOptimal && !isNaN(accOptimal)) {
		accOptimalScore = `${(accOptimal * 100).toFixed(1)}%`
	}
	const riskColor = getAccColor(acc*100);
  return (
		<Box >
			<Flex color={COLORS.REPORT_TEXT} my={"20px"} mt={"50px"} align={"center"}>
				<Box mx={"80px"} my={"25px"} borderWidth={1} borderColor={"gray.100"}>
					<Box mb={"30px"} >
					<EditablePatientAttributes
						title={"Risk calculation is based on the following patient attributes:"}
						showBorder={false}
						gender={props.patientInfo.sex ? "male" : "female"}
						age={age}
						riskTrait={null}
						labelPrefix=""
						sbp={sbp}
						hdl={mmolL2mgdl(hdl)}
						tc={mmolL2mgdl(tc)}
						diabetes={diabetes}
						bpTreated={bpTreated}
						smoker={smoker}
						callbackGenderSelected={null}
						callbackAgeChange={onAgeChange}
						callbackSbpChange={onSbpChange}
						callbackHdlChange={onHdlChange}
						callbackTcChange={onTcChange}
						callbackDiabetesChange={onDiabetesChange}
						callbackSmokerChange={onSmokerChange}
						callbackBpTreatedChange={onBpTreatedChange}
						onCancel={()=>{}}
						onApply={()=>{}}
						showAttributeFlags={accCalculatorUserAttribute}
						showButtons={false}
						footer={props.footer}
					/>
						</Box>
				</Box>
				<Box mt={"10px"}>
					<AccVsOptimalChartImpl acc={acc*100} accOptimal={accOptimal*100} height={450}/>
				</Box>
				<Box mx={"50px"} w="270px" maxW="270px">
					<Text color={COLORS.REPORT_TEXT} fontSize={16} fontWeight={"bold"}>10-YEAR CARDIOVASCULAR RISK:</Text>
					{/*<Text color={COLORS.REPORT_TEXT_GRAY} fontSize={14}>(ACC/AHA score)</Text>*/}
					<Box borderWidth={1} borderColor={riskColor} color={riskColor} mt={"10px"}>
						<Text m="20px" fontWeight={"bold"} textAlign={"center"} >{accScore}</Text>
					</Box>
					{!isNaN(acc) && <Flex align={"center"} mt={"5px"}>
						<Text color={COLORS.REPORT_TEXT_GRAY} fontSize={16} mr={"15px"}>Risk level:</Text>
						<Text color={riskColor} fontWeight={"bold"} fontSize={16}>{getAccLevelText(acc*100)}</Text>
					</Flex>}
					<Text color={COLORS.REPORT_TEXT} fontSize={16} fontWeight={"normal"} mt={"50px"}>OPTIMAL RISK:</Text>
					<Box borderWidth={1} borderColor={COLORS.GRAY_192} my={"10px"} color={COLORS.GRAY_128}>
						<Text m="20px" fontWeight={"bold"} textAlign={"center"} >{accOptimalScore}</Text>
					</Box>
					{isNaN(acc) && <Text color={COLORS.REPORT_TEXT} fontSize={16}>Fill in the missing patient attributes in order to get a score.</Text>}
				</Box>
			</Flex>
		</Box>
  );
}
