// @flow
import React, {useCallback, useEffect, useState} from 'react';

import {Box, Flex, Spacer, Input, Text, Select, InputGroup, InputLeftElement} from "@chakra-ui/react";
import COLORS from "../../common/colors";
import {AiOutlineMail} from "react-icons/ai";
import isEmail from "validator/es/lib/isEmail";

type Props = {
	callbackOnEditChange: any
}

export default function DemoSignUp(props: Props) {

	const [nextEnabled, setNextEnabled] = useState(false);
	const [genderSelected, setGenderSelected] = useState(false);
	const [genderColor, setGenderColor] = useState("gray.300");
	const [nameApplied, setNameApplied] = useState(false);
	const [pwdApplied, setPwdApplied] = useState(false);
	const [pwd, setPwd] = useState(undefined);
	const [pwdReplicate, setPwdReplicate] = useState(undefined);
	const [pwdReplicateApplied, setPwdReplicateApplied] = useState(false);
	const [email, setEmail] = useState(undefined);
	const [emailApplied, setEmailApplied] = useState(false);
	const {callbackOnEditChange} = props;

  const onGenderSelected = useCallback((event: any) => {
    const strGender: string = event.target.value;
		const maleOrFemale = strGender === "male" || strGender === "female";
		setGenderSelected(maleOrFemale);
		setGenderColor(maleOrFemale ? COLORS.REPORT_TEXT : "gray.300");
  }, [setGenderSelected]);

  const onNameChange = useCallback((event: any) => {
		const name = event.target.value;
		setNameApplied(name && name.length > 0);
  }, [setNameApplied]);
  const onEmailChange = useCallback((event: any) => {
    const email: string = event.target.value;
		setEmail(email);
		setEmailApplied(email && email.length> 0);
  }, [setEmailApplied]);
  const onPwdChange = useCallback((event: any) => {
		const pwd: string = event.target.value;
		setPwd(pwd);
		setPwdApplied(pwd && pwd.length > 0);
  }, [setPwdApplied]);
  const onPwdReplicateChange = useCallback((event: any) => {
		const replicatePwd: string = event.target.value;
		setPwdReplicate(replicatePwd);
		setPwdReplicateApplied(replicatePwd && replicatePwd === pwd)
  }, [setPwdReplicateApplied, pwd]);

  useEffect(() => {
		setNextEnabled(pwdApplied && pwdReplicateApplied && genderSelected && emailApplied && nameApplied && pwd === pwdReplicate && isEmail(email));
  }, [setNextEnabled, pwdApplied, pwdReplicateApplied, genderSelected, emailApplied, nameApplied, pwd, pwdReplicate, email]);

  // const onEditChange = useCallback(() => {
	// 	setNextEnabled(pwdApplied && pwdReplicateApplied && genderSelected && emailApplied && nameApplied);
  // }, [setNextEnabled, pwdApplied, pwdReplicateApplied, genderSelected, emailApplied, nameApplied, callbackOnEditChange, nextEnabled]);

  useEffect(() => {
		if (callbackOnEditChange) {
			callbackOnEditChange(nextEnabled);
		}
  }, [nextEnabled, callbackOnEditChange]);
  return (
		<Flex color={COLORS.REPORT_TEXT} >
			<Box m="10px">
				<Flex align={"center"}>
					<Text  fontSize={17} color={COLORS.REPORT_TEXT} fontWeight={"bold"}>Sign up</Text>
					<Spacer/>
					{/*<Box color={"gray.800"}>*/}
					{/*	{phosphorImageComp(TestTube, "20px", "20px")}*/}
					{/*</Box>*/}
				</Flex>
				<Box color={COLORS.REPORT_TEXT}>
					<Text color={"gray.800"} mt={"15px"} ml={"3px"} fontSize={15}>full name</Text>
					<Input placeholder={'full name'} onChange={onNameChange}
						_placeholder={{ opacity: 0.4, color: COLORS.REPORT_TEXT_GRAY }}
					></Input>
					<Text color={"gray.800"} mt={"15px"} ml={"3px"} fontSize={15}>email address</Text>
						<InputGroup maxW="500px">
							{!email && <InputLeftElement pl={"5px"}>
								<Box
									as={AiOutlineMail}
									h="18px"
									w="18px"
									mt={"5px"}
								/>
							</InputLeftElement>
							}
							<Input
									color={COLORS.REPORT_TEXT}
									defaultValue={email}
									onChange={onEmailChange}
									fontSize={16}
									h={"45px"}
									w={"400px"}
									isDisabled={false}
									placeholder={"email address"}
									_placeholder={{ opacity: 0.4, color: COLORS.REPORT_TEXT_GRAY }}
									type={"email"}
							/>
						</InputGroup>
					<Text color={"gray.800"} mt={"15px"} ml={"3px"} fontSize={15}>gender at birth</Text>
					<Select
						placeholder="select gender"
						color={genderColor}
						onChange={onGenderSelected}
						defaultValue={null}
					>
						<option style={{ color: COLORS.REPORT_TEXT }} key="female" value="female">
							female
						</option>
						<option style={{ color: COLORS.REPORT_TEXT }} key="male" value="male">
							male
						</option>
					</Select>
					<Text color={"gray.800"} mt={"15px"} ml={"3px"} fontSize={15}>password</Text>
					<Input placeholder={'password'} onChange={onPwdChange} type={'password'}
						_placeholder={{ opacity: 0.4, color: COLORS.REPORT_TEXT_GRAY }}
					></Input>
					<Text color={"gray.800"} mt={"15px"} ml={"3px"} fontSize={15} >confirm password</Text>
					<Input placeholder={'confirm password'} onChange={onPwdReplicateChange} type={'password'}
						_placeholder={{ opacity: 0.4, color: COLORS.REPORT_TEXT_GRAY }}
					></Input>
				</Box>
			</Box>
		</Flex>
  );
}

