// @flow
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import routes, {isPilotsUrl} from '../main/routes';
import NotFoundPage from '../main/NotFoundPage';
import PilotLabPatients from './PilotLabPatients';
import withNavigation from '../main/WithRoutes';
import {canSeeClinicsClinicPages, canSeePilotClinicPages} from '../auth/permissions';
import {useAuthenticator} from "@aws-amplify/ui-react";
import PilotPatientRisks from "./PilotPatientRisks";

type Props = {
  params: Object,
  location: Object
};

function PilotLabPages(props: Props) {
  const { user } = useAuthenticator((context) => [context.user]);
  const urlClinic = props.params.lab;
  if (isPilotsUrl(props.location.pathname)) {
    const canSeeClinic = canSeePilotClinicPages(user, urlClinic);
    if (!canSeeClinic) {
      return (
          <Routes>
            <Route path={routes.ROUTE_NOT_FOUND} element={<NotFoundPage/>}/>;
          </Routes>
      );
    }
  } else {
    const canSeeClinic = canSeeClinicsClinicPages(user, urlClinic);
    if (!canSeeClinic) {
      return (
          <Routes>
            <Route path={routes.ROUTE_NOT_FOUND} element={<NotFoundPage/>}/>;
          </Routes>
      );
    }
  }
  return (
    <Routes>
      <Route path={routes.PATIENT_LIST} element={<PilotLabPatients />} />
      <Route
        path={routes.PATIENT_RISKS}
        element={<PilotPatientRisks />}
      />
      {/* <Route path={`${routes.MOBILE}/*`} element={<PilotLabPages user={props.user}/>} /> */}
      <Route path={routes.ROUTE_NOT_FOUND} element={<NotFoundPage />} />
    </Routes>
  );
}

export default withNavigation(PilotLabPages);
