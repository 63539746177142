// @flow
import React from 'react';
import upRed from '../../images/up-red.png';
import downGreen from '../../images/down-green.png';
import {kgToPoundsNumeric, poundsToKg} from "../../utils/unit_translation";
import {
  getMinWeight, getMaxWeight
} from '../../common/reports/trait-defaults';

import COLORS from '../../common/colors';
import {calcBmiNumeric} from "../../utils/bmi";

type Props = {
  age: ?number,
  sex: number,
  weight: number,
  height: number,
  sbpScore: number,
  dbpScore: number,
  sbpLinReg: Object,
  dbpLinReg: Object,
  sbpQuartile: number,
  sbpPercentile: number,
  dbpQuartile: number,
  dbpPercentile: number,
  callbackOnChange: any,
  orderLabs: boolean,
  showEdit: boolean
};

type State = {
  age: number,
  weight: number,
  sex: number,
  recentSbpChange: number,
  recentDbpChange: number,
  recentMinSbp: number,
  recentMaxSbp: number,
  recentMinDbp: number,
  recentMaxDbp: number,
  gender: ?string,
  playingEnabled: boolean,
  error: ?string,
  shortSbpText: string,
  longSbpText: string,
  sbpTrendIcon: any,
  sbpColor: string,
  shortDbpText: string,
  longDbpText: string,
  dbpTrendIcon: any,
  dbpColor: string
};

export default function withBpCalculator(WrappedComponent: any) {
  return class extends React.Component<Props, State> {
    constructor(props: Props) {
      super(props);
      (this: any).recalcBpChanges = this.recalcBpChanges.bind(this);
      (this: any).onAgeSliderChange = this.onAgeSliderChange.bind(this);
      (this: any).getAgeDiffText = this.getAgeDiffText.bind(this);
      (this: any).onWeightSliderChange = this.onWeightSliderChange.bind(this);
      (this: any).getWeightDiffText = this.getWeightDiffText.bind(this);
      (this: any).calcExpectedDbpChange = this.calcExpectedDbpChange.bind(this);
      (this: any).calcExpectedSbpChange = this.calcExpectedSbpChange.bind(this);
      (this: any).runLinRegDbp = this.runLinRegDbp.bind(this);
      (this: any).runLinRegSbp = this.runLinRegSbp.bind(this);
      (this: any).onResetAttributes = this.onResetAttributes.bind(this);
      (this: any).getSliderWeightColor = this.getSliderWeightColor.bind(this);
      (this: any).onGenderSelected = this.onGenderSelected.bind(this);
      (this: any).onCurrentAgeChange = this.onCurrentAgeChange.bind(this);
      (this: any).onCurrentWeightChange = this.onCurrentWeightChange.bind(this);
      (this: any).getError = this.getError.bind(this);

      this.resetWeight = this.props.weight;
      this.AGE_ANALYSIS_LOW_LIMIT = 38;
      this.AGE_ANALYSIS_HIGH_LIMIT = 73;
      this.resetAge = this.getDefaultAge();
      const defaultGender = this.getDefaultGender();
      this.manualCurrentAge = this.props.age ? this.props.age : undefined;
      this.minWeight = getMinWeight(this.props.weight, this.props.height, false);
      this.maxWeight = getMaxWeight(this.props.weight, this.props.height, false);
      this.minAge = this.props.age ? this.props.age : this.resetAge;
      if (this.minAge < this.AGE_ANALYSIS_LOW_LIMIT)
        this.minAge = this.AGE_ANALYSIS_LOW_LIMIT;
      if (this.minAge > this.AGE_ANALYSIS_HIGH_LIMIT)
        this.minAge = this.AGE_ANALYSIS_HIGH_LIMIT;
      this.maxAge = this.AGE_ANALYSIS_HIGH_LIMIT;

      this.state = {
        age: this.resetAge,
        weight: this.resetWeight,
        gender: defaultGender,
        sex: this.props.sex === undefined ? 0 : this.props.sex,
        recentSbpChange: 0,
        recentMinSbp: -10,
        recentMaxSbp: 10,
        recentDbpChange: 0,
        recentMinDbp: -10,
        recentMaxDbp: 10,
        playingEnabled: !!defaultGender,
        error: !defaultGender ? 'Select gender' : undefined,
        shortSbpText: '',
        longSbpText: '',
        sbpTrendIcon: undefined,
        sbpColor: 'transparent',
        shortDbpText: '',
        longDbpText: '',
        dbpTrendIcon: undefined,
        dbpColor: 'transparent'
      };
    }

    onGenderSelected(eventOrValue: any) {
      const strGender: string = eventOrValue.target
        ? eventOrValue.value
        : eventOrValue;
      let gender: ?string;
      let sex = 0;
      if (strGender === 'male') {
        gender = 'male';
        sex = 1;
      }
      if (strGender === 'female') {
        gender = 'female';
        sex = 0;
      }
      this.setState({
        gender,
        sex,
        playingEnabled:
          gender !== undefined && gender !== null && gender.length > 0,
        error: this.getErrorsWithGenderArg(gender)
      });
    }

    onCurrentWeightChange(val: number) {
      const newWeight = parseFloat(val);
      if (
        Number.isNaN(newWeight) ||
        newWeight < this.minWeight ||
        newWeight > this.maxWeight
      ) {
        this.setState({
          error: this.getError()
        });
        return;
      }

      this.resetWeight = newWeight;
      this.setState(
        {
          weight: newWeight,
          error: this.getError()
        },
        this.onResetAttributes
      );
    }

    onCurrentAgeChange(e: any) {
      const newAge = e.target ? parseInt(e.target.value, 10) : e;
      this.manualCurrentAge = newAge;
      if (
        Number.isNaN(newAge) ||
        newAge < this.AGE_ANALYSIS_LOW_LIMIT ||
        newAge > this.AGE_ANALYSIS_HIGH_LIMIT
      ) {
        this.setState({
          error: this.getError()
        });
      }

      this.minAge = newAge;
      this.resetAge = newAge;
      this.setState(
        {
          age: newAge,
          error: this.getError()
        },
        this.onResetAttributes
      );
    }

    onAgeSliderChange(newAge: number) {
      this.setState(
        {
          age: newAge
        },
        this.recalcBpChanges
      );
    }

    onWeightSliderChange(newWeight: number) {
      this.setState(
        {
          weight: poundsToKg(newWeight)
        },
        this.recalcBpChanges
      );
    }

    onResetAttributes() {
      this.setState(
        {
          age: this.resetAge,
          weight: this.resetWeight,
          recentSbpChange: 0,
          recentMinSbp: -10,
          recentMaxSbp: 10,
          recentDbpChange: 0,
          recentMinDbp: -10,
          recentMaxDbp: 10
        },
        this.recalcBpChanges
      );
    }

    getError() {
      return this.getErrorsWithGenderArg(this.state.gender);
    }

    getErrorsWithGenderArg(gender: ?string) {
      if (!gender) return 'Select gender';
      if (!this.manualCurrentAge || Number.isNaN(this.manualCurrentAge))
        return 'Fill in your current age';
      if (
        this.manualCurrentAge < this.AGE_ANALYSIS_LOW_LIMIT ||
        this.manualCurrentAge > this.AGE_ANALYSIS_HIGH_LIMIT
      ) {
        return `Allowed range for age is ${this.AGE_ANALYSIS_LOW_LIMIT} - ${this.AGE_ANALYSIS_HIGH_LIMIT}`;
      }
      if (!this.resetWeight || Number.isNaN(this.resetWeight))
        return 'Fill in your current weight';
      if (
        this.resetWeight < this.minWeight ||
        this.resetWeight > this.maxWeight
      ) {
        return `Allowed range for weight is ${this.minWeight} - ${this.maxWeight}`;
      }
      return undefined;
    }

    getDefaultAge() {
      let { age } = this.props;
      if (!age) age = this.AGE_ANALYSIS_LOW_LIMIT;
      if (age < this.AGE_ANALYSIS_LOW_LIMIT) age = this.AGE_ANALYSIS_LOW_LIMIT;
      if (age > this.AGE_ANALYSIS_HIGH_LIMIT)
        age = this.AGE_ANALYSIS_HIGH_LIMIT;
      return age;
    }

    getDefaultGender() {
      const { sex } = this.props;
      let gender;
      if (sex === 0) gender = 'female';
      if (sex === 1) gender = 'male';
      return gender;
    }

    getChangeDetails(expectedBp: number, currentBp: number, isSbp: boolean) {
      const diff: number = expectedBp - currentBp;
      const fixedDiff = Math.abs(diff).toFixed(1);
      const isSignificantDiff = fixedDiff !== '0.0';
      const sign: string = diff >= 0 ? '+' : '-';
      const shortBpChange = `${sign} ${Math.abs(diff).toFixed(1)}  mmHg`;
      let color = COLORS.REPORT_TEXT;
      let trend: string = '';
      if (diff !== 0) {
        trend = diff > 0 ? 'increase' : 'decrease';
      }
      const genderText = this.state.sex === 1 ? 'men' : 'women';
      const ageDiff: number = this.state.age - this.resetAge;
      const ageTrend: string = ageDiff > 0 ? 'increase' : 'decrease';
      const weightDiff: number = kgToPoundsNumeric(this.state.weight - this.resetWeight);
      const weightTrend: string = weightDiff > 0 ? 'increase' : 'decrease';
      const trait = isSbp ? 'systolic' : 'diastolic';
      if (isSignificantDiff) {
        color = diff > 0 ? COLORS.RED_STATUS : COLORS.GREEN_STATUS;
      }

      let longText = '';
      if (ageDiff !== 0 && weightDiff !== 0) {
        longText = `In ${genderText} population, an age ${ageTrend} by ${ageDiff.toFixed(
          0
        )} years and a weight ${weightTrend} by ${Math.abs(weightDiff).toFixed(
          1
        )} pounds are expected to have a mean ${trend} of ${trait} blood pressure by ${diff.toFixed(
          1
        )} mmHg.`;
      } else if (ageDiff !== 0) {
        longText = `In ${genderText} population of the same weight, an age ${ageTrend} by ${ageDiff.toFixed(
          0
        )} years is expected to have a mean ${trend} of ${trait} blood pressure by ${diff.toFixed(
          1
        )} mmHg.`;
      } else if (weightDiff !== 0) {
        longText = `In ${genderText} population of the same age, a weight ${weightTrend} by ${Math.abs(
          weightDiff
        ).toFixed(
          1
        )} pounds is expected to have a mean ${trend} of ${trait} blood pressure by ${diff.toFixed(
          1
        )} mmHg.`;
      } else {
        return {
          shortBpChange: '0 mmHg',
          longText: '',
          diffIcon: undefined,
          color: 'transparent'
        };
      }
      let diffIcon: any = null;
      if (diff > 0) diffIcon = upRed;
      if (diff < 0) diffIcon = downGreen;
      return {
        shortBpChange,
        longText,
        diffIcon,
        color
      };
    }

    getWeightDiffText() {
      if (this.state.weight === this.resetWeight) return 'current';
      const diff: number = kgToPoundsNumeric(this.state.weight - this.resetWeight);
      const sign: string = diff > 0 ? '+' : '-';
      return `${sign} ${Math.abs(diff).toFixed(1)} pounds`;
    }

    getAgeDiffText() {
      if (this.state.age > this.resetAge)
        return `+ ${this.state.age - this.resetAge} years`;
      return 'current';
    }

    getSliderWeightColor() {
      return COLORS.REPORT_TEXT;
    }

    recalcBpChanges() {
      const sbpChange = this.calcExpectedSbpChange();
      const dbpChange = this.calcExpectedDbpChange();

      let newState: any = {};
      if (
        sbpChange.sbpDiff !== this.state.recentDbpChange ||
        dbpChange.dbpDiff !== this.state.recentSbpChange
      ) {
        newState = {
          recentSbpChange: sbpChange.sbpDiff,
          recentMaxSbp: sbpChange.sbpMaxAbsExpected,
          recentMinSbp: -sbpChange.sbpMaxAbsExpected,
          recentDbpChange: dbpChange.dbpDiff,
          recentMaxDbp: dbpChange.dbpMaxAbsExpected,
          recentMinDbp: -dbpChange.dbpMaxAbsExpected
        };
      }
      newState.shortSbpText = sbpChange.shortSbpText;
      newState.longSbpText = sbpChange.longSbpText;
      newState.sbpTrendIcon = sbpChange.sbpTrendIcon;
      newState.sbpColor = sbpChange.sbpColor;
      newState.shortDbpText = dbpChange.shortDbpText;
      newState.longDbpText = dbpChange.longDbpText;
      newState.dbpTrendIcon = dbpChange.dbpTrendIcon;
      newState.dbpColor = dbpChange.dbpColor;

      this.setState(newState);
    }

    AGE_ANALYSIS_LOW_LIMIT: number;
    AGE_ANALYSIS_HIGH_LIMIT: number;
    minWeight: number;
    maxWeight: number;
    resetWeight: number;
    minAge: number;
    maxAge: number;
    resetAge: number;
    manualCurrentAge: ?number;

    calcExpectedSbpChange() {
      const current = this.runLinRegSbp(
        this.state.sex,
        this.resetAge,
        calcBmiNumeric(this.resetWeight, this.props.height / 100)
      );
      const expected = this.runLinRegSbp(
        this.state.sex,
        this.state.age,
        calcBmiNumeric(this.state.weight, this.props.height / 100)
      );
      const maxExpected: number = this.runLinRegSbp(
        this.state.sex,
        this.maxAge,
        calcBmiNumeric(this.maxWeight, this.props.height / 100)
      );
      const minExpected: number = this.runLinRegSbp(
        this.state.sex,
        this.minAge,
        calcBmiNumeric(this.minWeight, this.props.height / 100)
      );
      const sbpDiff = expected - current;

      const sbpMaxAbsExpected = Math.max(
        Math.abs(maxExpected - current),
        Math.abs(minExpected - current)
      );
      const {
        shortBpChange,
        longText,
        diffIcon,
        color
      } = this.getChangeDetails(expected, current, true);
      return {
        shortSbpText: shortBpChange,
        longSbpText: longText,
        sbpTrendIcon: diffIcon,
        sbpColor: color,
        sbpDiff,
        sbpMaxAbsExpected
      };
    }

    calcExpectedDbpChange() {
      const current: number = this.runLinRegDbp(
        this.state.sex,
        this.resetAge,
        this.resetWeight
      );
      const expected: number = this.runLinRegDbp(
        this.state.sex,
        this.state.age,
        this.state.weight
      );
      const maxExpected: number =
        this.runLinRegDbp(this.state.sex, this.maxAge, this.maxWeight) + 1;
      const minExpected: number = this.runLinRegDbp(
        this.state.sex,
        this.minAge,
        this.minWeight
      );
      const dbpDiff = expected - current;

      const dbpMaxAbsExpected: number = Math.max(
        Math.abs(maxExpected - current),
        Math.abs(minExpected - current)
      );
      const {
        shortBpChange,
        longText,
        diffIcon,
        color
      } = this.getChangeDetails(expected, current, false);
      return {
        shortDbpText: shortBpChange,
        longDbpText: longText,
        dbpTrendIcon: diffIcon,
        dbpColor: color,
        dbpDiff,
        dbpMaxAbsExpected
      };
    }

    runLinRegSbp(sex: number, age: number, weight: number) {
      const ageSquare: number = age * age;
      const sbp: number =
        sex * this.props.sbpLinReg.coefficients.sex_1 +
        calcBmiNumeric(weight, this.props.height / 100) * this.props.sbpLinReg.coefficients.bmi_1 +
        age * this.props.sbpLinReg.coefficients.age_1 +
        ageSquare * this.props.sbpLinReg.coefficients.age_square +
        this.props.sbpScore *
          this.props.sbpLinReg.coefficients.stdized_overall_risk_sbp +
        this.props.sbpLinReg.constant;
      return sbp;
    }

    runLinRegDbp(sex: number, age: number, weight: number) {
      const ageSquare: number = age * age;
      const dbp: number =
        sex * this.props.dbpLinReg.coefficients.sex_1 +
        calcBmiNumeric(weight, this.props.height / 100) * this.props.dbpLinReg.coefficients.bmi_1 +
        age * this.props.dbpLinReg.coefficients.age_1 +
        ageSquare * this.props.dbpLinReg.coefficients.age_square +
        this.props.dbpScore *
          this.props.dbpLinReg.coefficients.stdized_overall_risk_dbp +
        this.props.dbpLinReg.constant;
      return dbp;
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          onCurrentAgeChange={this.onCurrentAgeChange}
          onAgeSliderChange={this.onAgeSliderChange}
          getAgeDiffText={this.getAgeDiffText}
          onCurrentWeightChange={this.onCurrentWeightChange}
          onWeightSliderChange={this.onWeightSliderChange}
          getWeightDiffText={this.getWeightDiffText}
          getSliderWeightColor={this.getSliderWeightColor}
          onGenderSelected={this.onGenderSelected}
          onResetAttributes={this.onResetAttributes}
          ageLowLimit={this.minAge}
          ageHighLimit={this.maxAge}
          weightLowLimit={this.minWeight}
          weightHighLimit={this.maxWeight}
          age={this.state.age}
          weight={this.state.weight}
          currentAge={this.manualCurrentAge}
          currentWeight={this.resetWeight}
          resetWeight={this.resetWeight}
          recentSbpChange={this.state.recentSbpChange}
          recentDbpChange={this.state.recentDbpChange}
          recentMinSbp={this.state.recentMinSbp}
          recentMaxSbp={this.state.recentMaxSbp}
          recentMinDbp={this.state.recentMinDbp}
          recentMaxDbp={this.state.recentMaxDbp}
          gender={this.state.gender}
          playingEnabled={this.state.playingEnabled}
          error={this.state.error}
          shortSbpText={this.state.shortSbpText}
          longSbpText={this.state.longSbpText}
          sbpTrendIcon={this.state.sbpTrendIcon}
          sbpColor={this.state.sbpColor}
          shortDbpText={this.state.shortDbpText}
          longDbpText={this.state.longDbpText}
          dbpTrendIcon={this.state.dbpTrendIcon}
          dbpColor={this.state.dbpColor}
          showEdit={this.props.showEdit}
        />
      );
    }
  };
}
