// @flow
import React, {useCallback, useEffect, useState} from 'react';
import { Box, Text, Flex, Image, Spacer, Select } from '@chakra-ui/react';
import { useAuthenticator } from '@aws-amplify/ui-react';
import {v4 as uuid4} from "uuid";
import LinkButton from '../common/LinkButton';
import logo from '../images/opendna_logo_large.png';
import routes from '../main/routes';
import { canSeeInternalDemoPages } from '../auth/permissions';
import {getUserClinics, getUserDefaultUrl, isClinicsUser, isPilotUser} from '../auth/auth';
import HomeImagePane from './HomeImagePane';
import COLORS from '../common/colors';
import genomics from '../images/genomics.png';
import takingAction from '../images/taking_action.png';
import betterCare from '../images/better_care.png';
import heartDisease from '../images/heart_disease.png';
import tech1 from '../images/technology1.png';
import tech2 from '../images/technology2.png';
import investors from '../images/inverstors.png';
import { useCurrentUserContext } from '../context/CurrentUserContext';
import {getClinicDisplayName, isPilotsGroup} from "../capilots/clinics";
import withNavigation from "../main/WithRoutes";

type Props = {
  navigate: any
}

export function HomeImpl(props: Props) {
  const { user } = useAuthenticator((context) => [context.user]);
  const { currentPatientId } = useCurrentUserContext();
  const [myClinicUrl, setMyClinicUrl] = useState("/");
  const isPatientUser = myClinicUrl.includes("/self/");
  console.log(myClinicUrl)

  const alignTopBar = canSeeInternalDemoPages(user) ? 'top' : 'center';
  const clinics = user ? getUserClinics(user) : [];

  useEffect(() => {
      const url = getUserDefaultUrl(user, currentPatientId);
      console.log(`home ${user}, ${currentPatientId}, ${url}`)
      console.log(user);
      setMyClinicUrl(url);
      navigate(url);
  }, [user, currentPatientId]);  // eslint-disable-line

  const { navigate } = props;
  const onClinicSelected = useCallback((event) => {
    const clinic: string = event.target.value;
    if (clinic === "select clinic") return;
    let url = undefined;
    if (isPilotsGroup(clinic)) {
      url = `${routes.APP}/pilots/${clinic}/${routes.PATIENT_LIST}`;
    } else {
      url = `${routes.APP}/clinics/${clinic}/${routes.PATIENT_LIST}`;
    }
    if(url) {
      console.log("navigating to:");
      console.log(url);
      navigate(url);
    }
  }, [navigate]);
  return (
    <Box>
      <Flex mx="12%" direction="column">
        {/* top row with company image */}
        <Flex align={alignTopBar} justify="center" mt="100px" mb="80px">
          <Spacer />
          <Image src={logo} alt="Open-DNA" w="450px" maxH={"90px"}/>
          {/*{canSeeInternalDemoPages(user) && (*/}
          {/*  <Box w="450px">*/}
          {/*    <Text fontSize={18}>Precision medicine for</Text>*/}
          {/*    <Text fontSize={32}>Hypertension</Text>*/}
          {/*    <Text fontSize={18} my="10px">*/}
          {/*      Get a personalized recommendation for a high blood pressure*/}
          {/*      treatment by analyzing your genomic and clinical data.*/}
          {/*    </Text>*/}
              {/*<LinkButton*/}
              {/*  to={`${routes.APP}/${routes.BP}/${routes.UPLOAD}`}*/}
              {/*  bg={COLORS.DARK_BLUE}*/}
              {/*  color="white"*/}
              {/*  h="50px"*/}
              {/*  w="150px"*/}
              {/*  fontWeight="500"*/}
              {/*  isDisabled={!canSeeInternalDemoPages(user)}*/}
              {/*>*/}
              {/*  Get Started!*/}
              {/*</LinkButton>*/}
            {/*</Box>*/}
          {/*)}*/}
          {(isPilotUser(user) || isClinicsUser(user)) && (
            <Box w="450px" ml="80px">
              <Text fontSize={18}>Precision medicine for</Text>
              <Text fontSize={32}>Cardiometabolic Diseases</Text>
              <Text fontSize={18} my="10px">
                Assess patient risks for cardiometabolic diseases
              </Text>
              {clinics.length === 1 && <LinkButton
                to={myClinicUrl}
                bg={COLORS.DARK_BLUE}
                color="white"
                h="50px"
                w="150px"
                fontWeight="500"
              >
                My Clinic
              </LinkButton>}
              {(clinics.length > 1) && (
                  <Flex align={"center"} mt={"20px"} >
                    <Text >My clinics:</Text>
                    <Select
                      ml={"10px"}
                      h={"50px"}
                      fontSize={16}
                      bg={COLORS.DARK_BLUE}
                      color={"white"}
                      onChange={onClinicSelected}
                      w={"285px"}
                    >
                      <option
                          key={uuid4()}
                          value={"select clinic"}
                          color={"white"}
                          style={{
                            backgroundColor: COLORS.DARK_BLUE,
                          }}
                      >
                        {"select clinic"}
                      </option>
                      {clinics.map(clinic => {
                        return (
                          <option
                              key={uuid4()}
                              value={clinic}
                              color={isPilotsGroup(clinic) ? "white" : COLORS.RED_LIGHT}
                              style={{
                                backgroundColor: COLORS.DARK_BLUE,
                                color: isPilotsGroup(clinic) ? "white" : COLORS.ORANGE_STATUS
                              }}
                          >
                            {getClinicDisplayName(clinic)}
                          </option>
                        )})}
                    </Select>
                  </Flex>
              )}
            </Box>
          )}
          <Spacer />
          {isPatientUser && (
            <LinkButton
              to={myClinicUrl}
              bg={COLORS.DARK_BLUE}
              color="white"
              h="50px"
              w="150px"
              fontWeight="500"
            >
              My Reports
            </LinkButton>
          )}
          {isPatientUser && <Spacer/>}
        </Flex>
        {/* middle row with general text */}
        <Flex>
          <Spacer />
          <Box
            h="100px"
            align="center"
            rounded="8px"
            color="white"
            bg={COLORS.HOME_GREEN}
            w="980px"
          >
            <Text fontSize={24} fontWeight={500} mt="30px">
              Precision genomic medicine for complex diseases
            </Text>
          </Box>
          <Spacer />
        </Flex>
        <HomeImagePane image={heartDisease} />
        <HomeImagePane image={genomics} />
        <HomeImagePane image={takingAction} />
        <HomeImagePane image={betterCare} />
        <HomeImagePane image={tech1} marginBottom={0} />
        <HomeImagePane image={tech2} marginTop={-100} />
      </Flex>
      <Box bg="rgba(250, 250, 250, 1)">
        <HomeImagePane image={investors} />
      </Box>
    </Box>
  );
}

export default withNavigation(HomeImpl);
