// @flow
import axios from 'axios';
import { getDomain } from '../config/url_config';
import { makeApiGatewayCall } from './api_gateway';
import {CognitoUser} from "amazon-cognito-identity-js";

type RequestBodyType = Object | Array<any>;
type RequestMethodTypes = 'POST' | 'GET' | 'PUT' | 'DELETE' | 'PATCH';

export function request(
  method: RequestMethodTypes,
  domain: string,
  params: Object,
  pathTemplate: string,
  additionalParams: Object,
  body: ?RequestBodyType,
  isLocal: ?boolean,
  cognitoUser: typeof CognitoUser
): Promise<*> {
  if (additionalParams === undefined || additionalParams === null) {
    additionalParams = {}
  }
  if (isLocal) {
    let url = `${domain}${pathTemplate}`;
    if (additionalParams.queryParams !== null && additionalParams.queryParams !== undefined) {
      Object.keys(additionalParams.queryParams).forEach((param, index) => {
        url = url.concat(index === 0 ? '?' : '&');
        url = url.concat(`${param}=${additionalParams.queryParams[param]}`);
      });
    }
    const jwtToken = localStorage.getItem("st");
    const headers: {"X-OpenDna-Jwt"?: ?string, withCredentials: boolean} = {
      withCredentials: false
    }
    if (jwtToken !== null && jwtToken !== undefined) {
      headers["X-OpenDna-Jwt"] = jwtToken
    }
    return axios({
      method,
      url,
      data: body,
      headers: headers
    });
  }
  // console.log(pathTemplate);
  // console.log(body);
  // console.log(additionalParams);
  return new Promise((resolve, reject) => {
    if (!additionalParams) {
      additionalParams = {}
    }
    if (!additionalParams.headers) {
      additionalParams.headers = {}
    }
    const jwtToken = localStorage.getItem("st");
    if (jwtToken !== undefined && jwtToken !== null) {
      additionalParams.headers["X-OpenDna-Jwt"] = jwtToken
    }
    makeApiGatewayCall(apigClient => {
      apigClient
        .invokeApi({}, pathTemplate, method, additionalParams, body)
        .then(result => resolve(result))
        .catch(err => reject(err));
    }, domain, cognitoUser);
  });
}

const requests = {
  post: (
    service: string,
    params: Object,
    pathTemplate: string,
    additionalParams: Object,
    body: RequestBodyType
  ): Promise<*> => {
    const isLocal: boolean = process.env.REACT_APP_LOCAL_SERVICE === 'true';
    const domain = getDomain(service);
    return request(
      'POST',
      domain,
      params,
      pathTemplate,
      additionalParams,
      body,
      isLocal
    );
  },
  put: (
    service: string,
    params: Object,
    pathTemplate: string,
    additionalParams: Object,
    body: RequestBodyType
  ): Promise<*> => {
    const isLocal: boolean = process.env.REACT_APP_LOCAL_SERVICE === 'true';
    const domain = getDomain(service);
    return request(
      'PUT',
      domain,
      params,
      pathTemplate,
      additionalParams,
      body,
      isLocal
    );
  },
  get: (
    service: string,
    params: Object,
    pathTemplate: string,
    additionalParams: Object,
    cognitoUser: typeof CognitoUser
  ): Promise<*> => {
    const isLocal: boolean = process.env.REACT_APP_LOCAL_SERVICE === 'true';
    const domain = getDomain(service);
    return request(
      'GET',
      domain,
      params,
      pathTemplate,
      additionalParams,
      undefined,
      isLocal,
      cognitoUser
    );
  }
};

export default requests;