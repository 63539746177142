// @flow
import React from 'react';
import { Box } from '@chakra-ui/react';
import { v4 as uuid4 } from 'uuid';
import HypercholesterolRecommendationSection from '../hypercholesterol/HypercholesterolRecommendationSection';
import HypercholesterolExplanatorySection from '../hypercholesterol/HypercholesterolExplanatorySection';
import withNavigation from '../main/WithRoutes';
import LdlChartsSection from '../hypercholesterol/LdlChartsSection';
import HdlChartsSection from '../hypercholesterol/HdlChartsSection';
import cholesterolImage from '../images/cholesterol.png';
import { getPatientId } from './capilots-utils';
import hypercholesterolReportCommon from '../hypercholesterol/hypercholesterol-report-common';
import PatientAttributes from "../common/reports/PatientAttributes";
import {getRiskLevelByPercentile} from "../common/risk-colors";
import {hypercholesterolCalculatorUserAttribute} from "../common/reports/report-common";
import {roundWithPrecision} from "../utils/numbers";
import GeneticReportOrMobile from "../common/reports/GeneticReportOrMobile";
import MobileLdlChartsSection from "../hypercholesterol/MobileLdlChartsSection";
import MobileHdlChartsSection from "../hypercholesterol/MobileHdlChartsSection";
import withMobile from "../common/mobile/withMobile";

type Props = {
  location: any,
  params: any,
  visit: Object,
  printing: boolean,
  patientId: ?string,
  cholesterol: Object,
  loading: boolean,
  isMobile: boolean,
  isClinicianView: boolean
};

export class PilotHypercholesterolRiskReportImpl extends React.Component<
  Props
> {
  constructor(props: Props) {
    super(props);
    this.patientId = props.patientId ?? getPatientId(props.params, props.location);
  }
  
  patientId: string;

  render() {
    const {isMobile} = this.props;

    const printingPatientAttr = this.props.printing && this.props.cholesterol &&
      <Box w={"420px"} borderWidth={1} borderColor="gray.100">
        <PatientAttributes
            gender={this.props.visit.sex ? "Male": "Female"}
            age={this.props.visit.age}
            bmi={roundWithPrecision(this.props.visit.bmi, 1)}
            riskTrait="Hypercholesterolemia (LDL)"
            riskLevel={getRiskLevelByPercentile(this.props.cholesterol.ldl.percentile + 1)}
            riskTrait2="Hypercholesterolemia (HDL)"
            riskLevel2={getRiskLevelByPercentile(100 - this.props.cholesterol.hdl.percentile)}
            orderLabs={false}
            labelPrefix="Current "
            showBorder={false}
            showAttributeFlags={hypercholesterolCalculatorUserAttribute}
            spacing={"7px"}
          >
          </PatientAttributes>
        </Box>;

    let chartsSection = null;
    if (this.props.cholesterol) {
      if (isMobile) {
        chartsSection = <Box>
          <MobileLdlChartsSection
            percentile={this.props.cholesterol.ldl.percentile}
            quintile={Math.floor(this.props.cholesterol.ldl.percentile / 20)}
            oddsRatios={this.props.cholesterol.ldl.odds_ratio_list}
            deciles={this.props.cholesterol.ldl.deciles}
          />
          <MobileHdlChartsSection
            percentile={this.props.cholesterol.hdl.percentile}
            quintile={Math.floor(this.props.cholesterol.hdl.percentile / 20)}
            oddsRatios={this.props.cholesterol.hdl.odds_ratio_list}
            deciles={this.props.cholesterol.hdl.deciles}
          />
        </Box>
      } else {
        chartsSection = [
          <Box mt="30px" key={uuid4()}
               sx={{
                 '@media print': {
                   pageBreakBefore: 'always;'
                 }
               }}
          >
            <LdlChartsSection
              percentile={this.props.cholesterol.ldl.percentile}
              quintile={Math.floor(
                this.props.cholesterol.ldl.percentile / 20
              )}
              oddsRatios={this.props.cholesterol.ldl.odds_ratio_list}
              deciles={this.props.cholesterol.ldl.deciles}
              isMobile={false}
              key="ldl_charts"
              printing={this.props.printing}
            />
          </Box>,
          <Box
            key={uuid4()}
            mt="30px"
          >
            <HdlChartsSection
              percentile={this.props.cholesterol.hdl.percentile}
              quintile={Math.floor(
                this.props.cholesterol.hdl.percentile / 20
              )}
              oddsRatios={this.props.cholesterol.hdl.odds_ratio_list}
              deciles={this.props.cholesterol.hdl.deciles}
              isMobile={false}
              key="hdl_charts"
              printing={this.props.printing}
            />
          </Box>
        ]
      }
    }

    return (
      <Box sx={{ '@media print': { '@page': { size: 'landscape;' } } }}>
        <GeneticReportOrMobile
          isDemo={false}
          reportTitle="HYPERCHOLESTEROLEMIA RISK REPORT"
          riskTitle={['Low-density Lipoprotein (LDL)', 'High-density Lipoprotein (HDL)']}
          configTrait="cholesterol"
          loading={this.props.loading}
          titleImage={cholesterolImage}
          patientNum={this.props.visit ? this.props.visit.patient_num : null}
          patientName={this.props.visit ? this.props.visit.patient_name : null}
          geneticKitId={this.props.visit ? this.props.visit.kit_id : ''}
          printing={this.props.printing}
          riskPercentile={
            this.props.cholesterol
              ? [
                  this.props.cholesterol.ldl.percentile,
                  99 - this.props.cholesterol.hdl.percentile
                ]
              : null
          }
          personChartTitle={[
            'Low-density Lipoprotein (LDL)',
            'High-density Lipoprotein (HDL)'
          ]}
          geneticRiskInfoText={[
            hypercholesterolReportCommon.GENETIC_RISK_LDL_INFO_TEXT,
            hypercholesterolReportCommon.GENETIC_RISK_HDL_INFO_TEXT
          ]}
          patientAttributesSection={printingPatientAttr}
          chartsSection={chartsSection}
          recommendationSection={!this.props.isClinicianView && <HypercholesterolRecommendationSection isMobile={isMobile}/>}
          explanatorySection={
            <HypercholesterolExplanatorySection isMobile={isMobile} />
          }
        />
      </Box>
    );
  }
}

// $FlowFixMe
export default withNavigation(withMobile(PilotHypercholesterolRiskReportImpl));
