import DemoClinicalData1 from './data/clinical1.json';
import DemoClinicalData2 from './data/clinical2.json';
import DemoClinicalData3 from './data/clinical3.json';
import DemoClinicalData4 from './data/clinical4.json';
import DemoClinicalData5 from './data/clinical5.json';

export function getDemoClinical(patientId: number) {
    let clinical = DemoClinicalData1;
    if (patientId === 2) clinical = DemoClinicalData2;
    if (patientId === 3) clinical = DemoClinicalData3;
    if (patientId === 4) clinical = DemoClinicalData4;
    if (patientId === 5) clinical = DemoClinicalData5;
    if (patientId > 5)
      clinical = {
        sex: undefined,
        age: undefined,
        sbp: undefined,
        dbp: undefined,
        hdl: undefined,
        ldl: undefined,
        tc: undefined,
        bpTreated: undefined,
        diabetes: undefined,
        smoker: undefined
      };
		return clinical;
}

export function isMaleDemoPatient(patientId: number) {
	return !!getDemoClinical(patientId).sex;
}