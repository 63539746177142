import React, { forwardRef } from 'react';

import { useLocation, useNavigate, useParams } from 'react-router';

export default function withNavigation(Component) {
  return forwardRef(({ ...props }, ref) => (
    <Component
      {...props}
      location={useLocation()}
      navigate={useNavigate()}
      params={useParams()}
      ref={ref}
    />
  ));
}
