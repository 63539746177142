// @flow
import React, {useCallback, useMemo, useState} from 'react';
import {Box} from '@chakra-ui/react';
import {
	getAgeAttr, getApobNumericAttr,
	getBasophillCountAttr, getBooleanAttr, getCReactiveProteinAttr,
	getCystatinCNumericAttr, getDbpNumericAttr,
	getHdlNumericAttr, getLdlNumericAttr, getLpaNumericAttr, getMchAttr,
	getSbpNumericAttr,
	getTcNumericAttr, getTestosteroneAttr, getUsualWalkingPaceAttr
} from "../../common/calculator/calculator-attr";
import {boolToOneOrZero} from "../../utils/numbers";
import {
	calcOdds,
	calcRiskPercentile,
	calcRiskUsingSurvival,
	calcSumCoefByValue,
	transformRisk
} from "../../utils/log_reg_utils";
import {getCadRiskLevel, getCadRiskColor} from "../../common/risk-colors";
import cadReportStrings from "../cad-report-common";
import {getCadOuterPanePlotBands} from "../../common/risk-gauge-utils";
import CadPersonalizedRecommendations from "./CadPersonalizedRecommendations";
import CalculatorOrMobile from "../../common/calculator/CalculatorOrMobile";
import logReg10Years55 from './cad-10-yrs-age-55.log_reg.json';
import logReg10YearsAll from './cad-10-yrs-age-all.log_reg.json';
import linRegLifetime75 from './cad-lifetime_0.1.0_75.json';
import linRegLifetime95 from './cad-lifetime_0.1.0_95.json';

type Props = {
	riskQuintile: number,
	riskPercentile: number,
	stdizedOverallRiskCad: number,
	sbp: number,
	dbp: number,
	tc: number,
	cystatinC: number,
	lpa: number,
	apob: number,
	testosterone: number,
	mch: number,
	basophillCount: number,
	crp: number,
	pcs: Array<number>,
	age: ?number,
	hdl: number,
	ldl: number,
	sex: number,
	smoking: boolean,
	bpTreated: boolean,
	statins: boolean,
	t2d: boolean,
	lonelinessIsolation: boolean,
	seenDoctorForNervesAnxietyTensionDepression: boolean,
	moodSwings: boolean,
	asthma: boolean,
	usualWalkingPace: number,
	cadNnProbs: ?number,
	cadRfProbs: ?number,
	printing: boolean,
	callbackRiskChange: any,
	callbackInitialRisks: any,
	callbackOnSave: any,
	showTreatmentRecommendations: boolean,
	allowPatientAttrsEditing: boolean,
	allowPatientAttrsFirstMissingEditing: boolean,
	lifetimeBy75: boolean
};

export default function Cad10YearsCalculator(props: Props) {
	const {pcs, callbackRiskChange} = props;
	const std_adj_overall_risk_cad = props.stdizedOverallRiskCad;
	const cad_nn_probs = props.cadNnProbs;
	const cad_rf_probs = props.cadRfProbs;
	const [initialAttrs, setInitialAttrs] = useState()
	const {age} = props;
	const getLogAgeInput = useCallback(() => {
		if (age && age <= 55) return logReg10Years55;
		return logReg10YearsAll;
	}, [age]);

	const {sex, lifetimeBy75} = props;
	const calcLifetimeRisk = useCallback((percentile: ?number, linRegData: ?Object) => {
		if (!linRegData) {
			linRegData = lifetimeBy75 ? linRegLifetime75 : linRegLifetime95;
		}
		if (percentile === null || percentile === undefined) return undefined;
		const linReg = sex ? linRegData.male : linRegData.female;
		const a = linReg.coefficients.x;
		const b = linReg.constant;
		const risk = a * percentile + b;
		return risk;
	}, [sex, lifetimeBy75]);

	const logRegInput = getLogAgeInput();

	const runLogReg = useCallback((sex: number, input: Object, skipCallback: boolean, quiet: boolean) => {
		const hdl = input.hdl;
		const tc = input.tc;
		const hypertension= input.sbp>=140 || input.dbp>=90;
		const includeProbs = input.include_non_traditional;

		const attrs = {
			sex: sex,
			sbp: input.sbp,
			ldl: input.ldl,
			smoking: boolToOneOrZero(input.smoking),
			statins: boolToOneOrZero(input.statins),
			cystatin_c: input.include_non_traditional ? input.cystatin_c : undefined,
			t2d: boolToOneOrZero(input.diabetes),
			lpa: input.include_non_traditional ? input.lpa : undefined,
			crp: input.include_non_traditional ? input.crp : undefined,
			basophill_count: input.include_non_traditional ? input.basophill_count : undefined,
			pc3: input.include_genetics ? pcs[2] : undefined,
			log_age_squared: Math.log(input.age) * Math.log(input.age),
			tc_by_hdl: tc && hdl ? tc / hdl : undefined,
			std_adj_overall_risk_cad: input.include_genetics ? std_adj_overall_risk_cad : undefined,
			usual_walking_pace: input.include_non_traditional && input.usual_walking_pace ? Number(input.usual_walking_pace) : undefined,
			hypertension_or_bp_treated: boolToOneOrZero(hypertension || input.bp_treated),
			asthma: input.include_non_traditional ? boolToOneOrZero(input.asthma) : 0,
			mood_swings: input.include_non_traditional ? boolToOneOrZero(input.mood_swings) : 0,
			seen_doctor_gp_for_nerves_anxiety_tension_or_depression: input.include_non_traditional ? boolToOneOrZero(input.seen_doctor_gp_for_nerves_anxiety_tension_or_depression) : 0,
			nn_prob: includeProbs ? cad_nn_probs : undefined,
			rf_prob: includeProbs ? cad_rf_probs : undefined
		}
		if (age && age > 55) {
	    // $FlowFixMe[prop-missing]
			attrs.loneliness_isolation = input.include_non_traditional ? boolToOneOrZero(input.loneliness_isolation) : 0;
	    // $FlowFixMe[prop-missing]
			attrs.testosterone = input.include_non_traditional ? input.testosterone : undefined;
		}
		const s10yrs = calcSumCoefByValue(attrs, logRegInput, true, quiet);
		const survival_rate = sex === 1 ? logRegInput.risk_params.male.survival_rate : logRegInput.risk_params.female.survival_rate;
		const mean_formula = sex === 1 ? logRegInput.risk_params.male.mean_formula : logRegInput.risk_params.female.mean_formula;
		const riskByLogReg = calcRiskUsingSurvival(s10yrs, survival_rate, mean_formula);
		const risk10yrs = transformRisk(riskByLogReg, logRegInput.transform, "log_reg", "acc");
		const riskPercentile10yrs = calcRiskPercentile(risk10yrs, logRegInput.percentiles);

		// lifetime with age = 50
		attrs["log_age_squared"] = Math.log(50) * Math.log(50);
		const sWithAge50 = calcSumCoefByValue(attrs, logRegInput, true);
		const riskByLogRegWithAge50 = calcRiskUsingSurvival(sWithAge50, survival_rate, mean_formula);
		const riskWithAge50 = transformRisk(riskByLogRegWithAge50, logRegInput.transform, "log_reg", "acc");
		const riskPercentileWithAge50 = calcRiskPercentile(riskWithAge50, logRegInput.percentiles);
		const riskLifetime = calcLifetimeRisk(riskPercentileWithAge50)
		if (callbackRiskChange && !skipCallback) callbackRiskChange(risk10yrs * 100, riskPercentile10yrs, riskLifetime, riskPercentileWithAge50)
		return {
			percentile: riskPercentileWithAge50,
			risk: risk10yrs * 100,
			odds: calcOdds(s10yrs),
			lifetimeRisk: riskLifetime,
			lifetimeRiskPercentile: riskPercentileWithAge50
		}
	}, [pcs, std_adj_overall_risk_cad, callbackRiskChange, logRegInput, calcLifetimeRisk, age, cad_nn_probs, cad_rf_probs]);

	const {ldl, hdl, tc, sbp, dbp, bpTreated, smoking, statins, lonelinessIsolation, usualWalkingPace} = props;
	const {cystatinC, testosterone, basophillCount, lpa, apob, crp, mch} = props;

	const getIncludeGeneticsColor = useCallback(() => {
		return 'green';
	}, []);

	const numericAttrs = useMemo(()=> {
		return [
			getAgeAttr(age, [true, false, false]),
			getSbpNumericAttr(sbp),
			getDbpNumericAttr(dbp),
			getTcNumericAttr(tc),
			getHdlNumericAttr(hdl),
			getLdlNumericAttr(ldl),
			getCystatinCNumericAttr(cystatinC),
			getLpaNumericAttr(lpa),
			getApobNumericAttr(apob),
			getBasophillCountAttr(basophillCount),
			getCReactiveProteinAttr(crp),
			getTestosteroneAttr(testosterone),
			getMchAttr(mch)
		]
	}, [age, sbp, dbp,  tc, hdl, ldl, cystatinC, lpa, basophillCount, crp, testosterone, mch, apob]);

	const numericAttrTranslationFuncs = useMemo(() => {
		const translations = {};
		numericAttrs.forEach(attr => {
			translations[attr.name] = attr.fTranslateFromDisplayValue;
		});
		return translations;
	}, [numericAttrs]);

	const booleanAttrs = [
		// getBooleanAttr("hypertension", "Hypertension", props.hypertension, false, false),
		getBooleanAttr("smoking", "Smoking", props.smoking, false, true, undefined, true),
		getBooleanAttr("diabetes", "Diabetes", props.t2d, false, false, undefined, true),
		getBooleanAttr("bp_treated", "Treated for high blood pressure", props.bpTreated, false, false, undefined, true),
		getBooleanAttr("statins", "Treated with statins", props.statins, false, false, undefined, true),
		getBooleanAttr("asthma", "Asthma", props.asthma, false, false, undefined, true),
		getBooleanAttr("loneliness_isolation", "Loneliness, isolation", props.lonelinessIsolation, false, false, undefined, true),
		getBooleanAttr("seen_doctor_gp_for_nerves_anxiety_tension_or_depression", "Seen GP for nerves, anxiety, tension or depression", props.seenDoctorForNervesAnxietyTensionDepression, false, false, undefined, true),
		getBooleanAttr("mood_swings", "Mood swings", props.moodSwings, false, false, undefined, true),
		getBooleanAttr("include_genetics", "Include genetics", true, false, true, getIncludeGeneticsColor, false),
		getBooleanAttr("include_non_traditional", "Include non traditional risk factors", true, false, true, getIncludeGeneticsColor, false, cadReportStrings.MODEL_INCLUDE_NON_TRADITIONAL_RISK_FACTORS),
	];
	const selectionAttrs = [
		getUsualWalkingPaceAttr(props.usualWalkingPace, true)
	]

	const {callbackInitialRisks} = props;
	const onInitialRisks = useCallback((risks: Object, resetAttrs: Object) => {
		risks['lifetimeRisk95'] = calcLifetimeRisk(risks.lifetimeRiskPercentile, linRegLifetime95);
		if (callbackInitialRisks) callbackInitialRisks(risks);
		setInitialAttrs(resetAttrs);
	}, [callbackInitialRisks, calcLifetimeRisk]);

	const translateNumericAttr = useCallback((attr: string, value: number) => {
		if (numericAttrTranslationFuncs[attr]) {
			return numericAttrTranslationFuncs[attr](value);
		}
		return value;
	}, [numericAttrTranslationFuncs])

	const translateNumericAttrs = useCallback((attrs: Object) => {
      Object.keys(attrs).forEach(attr => {
        attrs[attr] = attrs[attr] !== null ? translateNumericAttr(attr, attrs[attr]) : null;
      })
	}, [translateNumericAttr]);

	const calcQuitSmokingRiskEffect = useCallback((baseRisk: number) => {
		if (!smoking || !initialAttrs) return 0;
		const newAttrs = {...initialAttrs};
		newAttrs.smoking = false;
		translateNumericAttrs(newAttrs);
		const risks = runLogReg(sex, newAttrs, true, true);
		return baseRisk - risks.risk;
	}, [smoking, sex, runLogReg, initialAttrs, translateNumericAttrs]);

	const calcBpTreatmentRiskEffect = useCallback((baseRisk: number) => {
		if (!initialAttrs || !initialAttrs.sbp || initialAttrs.sbp < 120) return 0;
		const newAttrs = {...initialAttrs};
		newAttrs.sbp = 120;
		translateNumericAttrs(newAttrs);
		const risks = runLogReg(sex, newAttrs, true, true);
		return baseRisk - risks.risk;
	}, [sex, runLogReg, initialAttrs, translateNumericAttrs]);

	const calcStatinsRiskEffect = useCallback((baseRisk: number) => {
		if (!initialAttrs || !initialAttrs.ldl || initialAttrs.ldl < 100) return 0;
		const newAttrs = {...initialAttrs};
		newAttrs.ldl = 100;
		translateNumericAttrs(newAttrs);
		const risks = runLogReg(sex, newAttrs, true, true);
		return baseRisk - risks.risk;
	}, [sex, runLogReg, initialAttrs, translateNumericAttrs]);

	const callbackGetRecommendations = useCallback((percentile, risk) => {
		return (
			<CadPersonalizedRecommendations
				risk={risk}
				ldl={ldl}
				hdl={hdl}
				tc={tc}
				sbp={sbp}
				dbp={dbp}
				statins={statins}
				bpTreated={bpTreated}
				loneliness_isolation={lonelinessIsolation}
				usual_walking_pace={usualWalkingPace}
				smoking={smoking}
				quitSmokingRiskEffect={calcQuitSmokingRiskEffect(risk)}
				bpTreatmentRiskEffect={calcBpTreatmentRiskEffect(risk)}
				statinsRiskEffect={calcStatinsRiskEffect(risk)}
			/>
		);
	}, [sbp, dbp, hdl, ldl, tc, smoking, statins, lonelinessIsolation, usualWalkingPace, bpTreated, calcQuitSmokingRiskEffect, calcBpTreatmentRiskEffect, calcStatinsRiskEffect])

	const infoTextForAgeGroup = useCallback((infoText: string) => {
		if (!infoText) return '';
		const datasetSize = age && age <= 55 ? '~85K' : '185K';
		const datasetAgeRange =  age && age <= 55 ? '39-55' : '39-71';
		infoText = infoText.split('{dataset-size}').join(datasetSize);
		infoText = infoText.split('{dataset-age-range}').join(datasetAgeRange);
		return infoText;
	}, [age]);

	const popupTexts = cadReportStrings.MODEL_10_YEARS_AGE_POP_INFO_TEXT
	popupTexts.text = infoTextForAgeGroup(popupTexts.text)
	const infoTexts = {
		riskLevel: {
			header: cadReportStrings.RISK_LEVEL_INFO_HEADER,
			text: infoTextForAgeGroup(cadReportStrings.RISK_LEVEL_INFO_TEXT),
			footer: cadReportStrings.INFO_FOOTER
		},
		relativeToPopulation: {
			header: cadReportStrings.LIFETIME_RISK_GAUGE_INFO_HEADER,
			text: infoTextForAgeGroup(cadReportStrings.LIFETIME_RISK_GAUGE_INFO_TEXT),
			footer: cadReportStrings.INFO_FOOTER
		},
		relativeToCurrent: {
			header: cadReportStrings.RELATIVE_TO_CURRENT_INFO_HEADER,
			text: cadReportStrings.RELATIVE_TO_CURRENT_INFO_TEXT,
			footer: cadReportStrings.INFO_FOOTER
		},
		model: infoTextForAgeGroup(cadReportStrings.CAD_10_YEAR_RISK_INFO_TEXT),
		youngerParticipant:  infoTextForAgeGroup(cadReportStrings.MODEL_10_YEARS_YOUNGER_PARTICIPANT),
		olderParticipant: infoTextForAgeGroup(cadReportStrings.MODEL_10_YEARS_OLDER_PARTICIPANT),
		agePopInfoTexts: popupTexts
	};

	const {callbackOnSave} = props;
	const onSaveAttrs = useCallback((attrs: Object) => {
		if (callbackOnSave) {
			callbackOnSave(attrs)
		}
		numericAttrs.forEach(attr => {
			attr.isSliderEnabled = attr.isAdjustable && attr.currentValue !== null && attr.currentValue !== undefined;
		})
	}, [callbackOnSave, numericAttrs]);

	const translateOverallRiskValue = useCallback((percentile: number) => {
		if (percentile === null || percentile === undefined) return percentile;
		const risk = calcLifetimeRisk(percentile);
		// console.log(`percentile:${percentile}, risk:${risk}`)
		return risk;
	}, [calcLifetimeRisk]);

	return (
		<Box>
			<CalculatorOrMobile
				sex={props.sex}
				riskQuintile={props.riskQuintile}
				riskPercentile={props.riskPercentile}
				numericAttrs={numericAttrs}
				booleanAttrs={booleanAttrs}
				selectionAttrs={selectionAttrs}
				stdizedPrs={props.stdizedOverallRiskCad}
				callbackRunLogReg={runLogReg}
				callbackInitialRisks={onInitialRisks}
        callbackGetRecommendations={props.showTreatmentRecommendations ? callbackGetRecommendations : null}
				disease='coronary artery disease'
				maxRisk={30}
        showRisk={true}
				showRelativeToPop={true}
				riskGauge={{
						title: "10 Year Risk",
						callbackGetOuterPlotBands: getCadOuterPanePlotBands,
						lines: [7.5]
					}
				}
				relativeToPopGauge={{
					title: "Lifetime Risk",
					changeLabel: 'Estimated risk change'
				}}
        callbackGetColorByRisk={getCadRiskColor}
			  callbackGetRiskLevel={getCadRiskLevel}
				infoTexts={infoTexts}
				printing={props.printing}
				callbackOnSavePatientAttrs={onSaveAttrs}
				callbackTranslateOverallRiskValue={translateOverallRiskValue}
				allowPatientAttrsEditing={props.allowPatientAttrsEditing}
				allowPatientAttrsFirstMissingEditing={props.allowPatientAttrsFirstMissingEditing}
				relativeToPopTitle={"Lifetime Risk"}
				callbackGetGaugeHeader={null}
			/>
		</Box>);
}
