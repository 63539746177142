// @flow
import React, {useCallback} from 'react';
import {Box, Text, Divider, Flex, useToast, Spacer} from '@chakra-ui/react';
import LabeledText from '../common/LabeledText';
import COLORS from '../common/colors';
import {getFullUserName} from "../auth/auth";
import {useAuthenticator} from "@aws-amplify/ui-react";
import ActionButton from "../common/ActionButton";
import {forceChangePassword} from "../api/users";
import PopupMessage from "../common/PopupMessage";

type Props = {
	onClose: any
};

export default function UserProfile(props: Props) {
  const initialRef = React.useRef();
  const { user } = useAuthenticator((context) => [context.user]);
	const fullName = getFullUserName(user);
  const [popupMessage, setPopupMessage] = React.useState({show: false, msg: undefined, title: undefined, color: COLORS.REPORT_TEXT});
  const onClosePopup = useCallback(()=> setPopupMessage({show: false, msg: undefined, title: undefined, color: COLORS.REPORT_TEXT}), [setPopupMessage])
  const toast = useToast();
	const onBeginChangePassword = useCallback(() => {
		setPopupMessage({
			show: true,
			color: COLORS.REPORT_TEXT,
			msg: "Are you sure you want to change your password?",
			title: "CONFIRM CHANGE PASSWORD"
		});
	}, [setPopupMessage]);

	const onChangePassword= useCallback(async ()=> {
		forceChangePassword(user.username).then(() => {
        toast({
              description: `Success`,
              status: 'info',
              duration: 9000,
              isClosable: true
             })}
				).catch((e)=> {
					toast({
									description: `Failed to force change password. Check internet connection and try again.`,
									status: 'warning',
									duration: 9000,
									isClosable: true
								 })
				})
		onClosePopup();
	}, [toast, user, onClosePopup]);

  return (
    <Box color={COLORS.REPORT_TEXT} mx="8%" my="25px" maxW="600px">
      <Text fontSize={20} fontWeight="bold">
        USER PROFILE
      </Text>
      <Divider my="20px" />
			<LabeledText title={"Name"} value={fullName} labelWidth={"100px"} textWidth={"200px"} align={"left"} textWeight={"bold"}/>
			<LabeledText title={"Username"} value={user ? user.username : ""} labelWidth={"100px"} textWidth={"200px"} align={"left"} textWeight={"bold"}/>
			<ActionButton
					name="Change password ..."
					size="sm"
					w="200px"
					onClick={onBeginChangePassword}
					my={"20px"}
					visibility={"hidden"}
			/>
			<Divider my={"20px"}/>
			<Flex my={"20px"}>
				<Spacer/>
				<ActionButton
						name="Close"
						size="sm"
						w="100px"
						ml={"40px"}
						onClick={props.onClose}
				/>
				<Spacer/>
			</Flex>
      <PopupMessage
				onClose={onChangePassword}
				isOpen={popupMessage.show}
				title={popupMessage.title}
				message={popupMessage.msg}
				parentRef={initialRef}
				messageColor={popupMessage.color}
				onCancel={onClosePopup}
				closeButtonText={"Change Password"}
			/>
    </Box>
  );
}
