// @flow
import React from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  AlertIcon,
  Box,
  Flex,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Stack,
  Text,
  Spacer,
  Input
} from '@chakra-ui/react';
import LabeledText from '../LabeledText';
import LabeledSwitch from '../LabeledSwitch';
import { roundWithPrecision, undefinedForNan } from '../../utils/numbers';
import ActionButton from '../ActionButton';
import traitRanges from '../reports/trait-ranges.json';
import {
  userAttributesFlags,
  minCalculatorUserAttributes
} from './report-common';

type Props = {
  name?: ?string,
  num?: ?number,
  kitId?: ?string,
  gender?: ?string,
  age?: ?number,
  sbp?: ?number,
  dbp?: ?number,
  hdl?: ?number,
  ldl?: ?number,
  tc?: ?number,
  bmi?: ?number,
  // height?: ?number,
  inch?: ?number,
  feet?: ?number,
  weight?: ?number,
  a1c?: ?number,
  smoker?: ?boolean,
  riskTrait?: ?string,
  riskLevel?: ?string,
  bpTreated?: ?boolean,
  diabetes?: ?boolean,
  fhDiabetes?: ?boolean,
  birthYear?: ?number,
  birthMonth?: ?number,
  showBorder?: boolean,
  fontSize?: number,
  title?: string,
  mbTitle?: string,
  callbackNameChange?: any,
  callbackKitIdChange?: any,
  callbackGenderSelected?: any,
  callbackAgeChange?: any,
  // callbackHeightChange?: any,
  callbackFeetChange?: any,
  callbackInchChange?: any,
  callbackWeightChange?: any,
  callbackBmiChange?: any,
  callbackA1cChange?: any,
  callbackTcChange?: any,
  callbackHdlChange?: any,
  callbackLdlChange?: any,
  callbackSbpChange?: any,
  callbackDbpChange?: any,
  callbackBpTreatedChange?: any,
  callbackSmokerChange?: any,
  callbackDiabetesChange?: any,
  callbackFhDiabetesChange?: any,
  callbackBirthYearChange?: any,
  callbackBirthMonthChange?: any,
  callbackNumChange?: any,
  onCancel: any,
  onApply: any,
  showAttributeFlags: number,
  mustHaveUserAttributes?: number,
  labelPrefix?: string,
  showButtons?: boolean,
  footer?: any
};

type State = {
  error: ?string,
  isApplyEnabled: boolean
};

export default class EditablePatientAttributesImpl extends React.Component<
  Props,
  State
> {
  static getSwitchColor() {
    return 'blue';
  }

  static getSwitchText(isChecked: boolean) {
    return isChecked ? 'YES' : 'NO';
  }

  static isInRange(val: ?number, lowLimit: number, highLimit: number) {
    if (val === undefined || val === null || Number.isNaN(val)) return false;
    const numVal: number = val;
    return numVal >= lowLimit && numVal <= highLimit;
  }

  static onFloatValChange(
    val: number,
    lowLimit: number,
    highLimit: number,
    callback?: any
  ) {
    const newVal = parseFloat(val);
    let callbackVal = newVal;
    if (newVal < lowLimit || newVal > highLimit) callbackVal = Number.NaN;
    if (callback) callback(callbackVal);
    return newVal;
  }

  static onIntValChange(
    val: number,
    lowLimit: number,
    highLimit: number,
    callback?: any
  ) {
    const newVal = parseInt(val, 10);
    let callbackVal = newVal;
    if (newVal < lowLimit || newVal > highLimit) callbackVal = Number.NaN;
    if (callback) callback(callbackVal);
    return newVal;
  }

  constructor(props: Props) {
    super(props);
    (this: any).onApply = this.onApply.bind(this);
    (this: any).onCancel = this.onCancel.bind(this);
    (this: any).onGenderSelected = this.onGenderSelected.bind(this);
    (this: any).onAgeChange = this.onAgeChange.bind(this);
    (this: any).onBirthYearChange = this.onBirthYearChange.bind(this);
    (this: any).onBirthMonthChange = this.onBirthMonthChange.bind(this);
    // (this: any).onHeightChange = this.onHeightChange.bind(this);
    (this: any).onWeightChange = this.onWeightChange.bind(this);
    (this: any).onFeetChange = this.onFeetChange.bind(this);
    (this: any).onInchChange = this.onInchChange.bind(this);
    (this: any).onBmiChange = this.onBmiChange.bind(this);
    (this: any).onTcChange = this.onTcChange.bind(this);
    (this: any).onHdlChange = this.onHdlChange.bind(this);
    (this: any).onLdlChange = this.onLdlChange.bind(this);
    (this: any).onSbpChange = this.onSbpChange.bind(this);
    (this: any).onDbpChange = this.onDbpChange.bind(this);
    (this: any).onA1cChange = this.onA1cChange.bind(this);
    (this: any).onNameChange = this.onNameChange.bind(this);
    (this: any).onNumChange = this.onNumChange.bind(this);
    (this: any).onKitChange = this.onKitChange.bind(this);
    (this: any).onBpTreatedSwitchChange = this.onBpTreatedSwitchChange.bind(
      this
    );
    (this: any).onDiabetesSwitchChange = this.onDiabetesSwitchChange.bind(this);
    (this: any).onFhDiabetesSwitchChange = this.onFhDiabetesSwitchChange.bind(
      this
    );
    (this: any).onSmokerSwitchChange = this.onSmokerSwitchChange.bind(this);
    (this: any).getError = this.getError.bind(this);
    (this: any).getErrorsWithGenderArg = this.getErrorsWithGenderArg.bind(this);
    (this: any).getRangeAttributeError = this.getRangeAttributeError.bind(this);

    (this: any).updateError = this.updateError.bind(this);
    (this: any).onAutoFill = this.onAutoFill.bind(this);

    this.name = this.props.name;
    this.kitId = this.props.kitId;
    this.gender = this.props.gender;
    this.age = this.props.age;
    // this.height = this.props.height;
    this.weight = this.props.weight;
    this.feet = this.props.feet;
    this.inch = this.props.inch;
    this.bmi = this.props.bmi;
    this.sbp = this.props.sbp;
    this.dbp = this.props.dbp;
    this.tc = this.props.tc;
    this.hdl = this.props.hdl;
    this.ldl = this.props.ldl;
    this.a1c = this.props.a1c;
    this.smoker = this.props.smoker;
    this.bpTreated = this.props.bpTreated;
    this.diabetes = this.props.diabetes;
    this.fhDiabetes = this.props.fhDiabetes;
    this.num = this.props.num;
    this.birthYear = this.props.birthYear;
    this.birthMonth = this.props.birthMonth;
    this.state = {
      error: this.getErrorsWithGenderArg(this.props.gender),
      isApplyEnabled: this.getApplyEnabled()
    };
  }

  onAutoFill() {
    // temp code
    console.log(this.age);
  }

  onGenderSelected(event: any) {
    const strGender: string = event.target.value;
    this.gender = strGender;
    this.updateError();
  }

  onAgeChange(val: number) {
    this.age = EditablePatientAttributesImpl.onIntValChange(
      val,
      traitRanges.age.lowLimit,
      traitRanges.age.highLimit
    );
    this.updateError();
  }

  onNameChange(event: any) {
    this.name = event.target.value;
    this.updateError();
  }

  onKitChange(event: any) {
    this.kitId = event.target.value;
    this.updateError();
  }
  /*
  onHeightChange(val: number) {
    this.height = EditablePatientAttributesImpl.onFloatValChange(
      val,
      traitRanges.height.lowLimit,
      traitRanges.height.highLimit
    );
    this.updateError();
  }
  */

  onWeightChange(val: number) {
    this.weight = EditablePatientAttributesImpl.onFloatValChange(
      val,
      traitRanges.weight.lowLimit,
      traitRanges.weight.highLimit
    );
    this.updateError();
  }
  onFeetChange(val: number) {
    this.feet = EditablePatientAttributesImpl.onFloatValChange(
      val,
      traitRanges.feet.lowLimit,
      traitRanges.feet.highLimit
    );
    this.updateError();
  }
  onInchChange(val: number) {
    this.inch = EditablePatientAttributesImpl.onFloatValChange(
      val,
      traitRanges.inch.lowLimit,
      traitRanges.inch.highLimit
    );
    this.updateError();
  }

  onBmiChange(val: number) {
    this.bmi = EditablePatientAttributesImpl.onFloatValChange(
      val,
      traitRanges.bmi.lowLimit,
      traitRanges.bmi.highLimit
    );
    this.updateError();
  }

  onTcChange(val: number) {
    this.tc = EditablePatientAttributesImpl.onFloatValChange(
      val,
      traitRanges.tc.lowLimit,
      traitRanges.tc.highLimit
    );
    this.updateError();
  }

  onHdlChange(val: number) {
    this.hdl = EditablePatientAttributesImpl.onFloatValChange(
      val,
      traitRanges.hdl.lowLimit,
      traitRanges.hdl.highLimit
    );
    this.updateError();
  }

  onLdlChange(val: number) {
    this.ldl = EditablePatientAttributesImpl.onFloatValChange(
      val,
      traitRanges.ldl.lowLimit,
      traitRanges.ldl.highLimit
    );
    this.updateError();
  }

  onSbpChange(val: number) {
    this.sbp = EditablePatientAttributesImpl.onIntValChange(
      val,
      traitRanges.sbp.lowLimit,
      traitRanges.sbp.highLimit
    );
    this.updateError();
  }

  onDbpChange(val: number) {
    this.dbp = EditablePatientAttributesImpl.onIntValChange(
      val,
      traitRanges.dbp.lowLimit,
      traitRanges.dbp.highLimit
    );
    this.updateError();
  }

  onBpTreatedSwitchChange(val: boolean) {
    this.bpTreated = val;
    if (!this.props.showButtons) {
      this.onApply();
    }
  }
  onDiabetesSwitchChange(val: boolean) {
    this.diabetes = val;
    if (!this.props.showButtons) {
      this.onApply();
    }
  }
  onFhDiabetesSwitchChange(val: boolean) {
    this.fhDiabetes = val;
    if (!this.props.showButtons) {
      this.onApply();
    }
  }
  onSmokerSwitchChange(val: boolean) {
    this.smoker = val;
    if (!this.props.showButtons) {
      this.onApply();
    }
  }
  onA1cChange(val: number) {
    this.a1c = EditablePatientAttributesImpl.onFloatValChange(
      val,
      traitRanges.a1c.lowLimit,
      traitRanges.a1c.highLimit,
      this.props.callbackA1cChange
    );
    this.updateError();
  }

  onNumChange(val: number) {
    this.num = EditablePatientAttributesImpl.onFloatValChange(
      val,
      traitRanges.num.lowLimit,
      traitRanges.num.highLimit
    );
    this.updateError();
  }

  onBirthYearChange(val: number) {
    this.birthYear = EditablePatientAttributesImpl.onFloatValChange(
      val,
      traitRanges.birthYear.lowLimit,
      new Date().getFullYear()
    );
    this.updateError();
  }

  onBirthMonthChange(val: number) {
    this.birthMonth = EditablePatientAttributesImpl.onFloatValChange(
      val,
      traitRanges.birthMonth.lowLimit,
      traitRanges.birthMonth.highLimit
    );
    this.updateError();
  }

  onCancel() {
    if (this.props.onCancel) this.props.onCancel();
  }

  onApply() {
    this.checkAndRunCallback(
      userAttributesFlags.num,
      this.props.num,
      this.num,
      this.props.callbackNumChange
    );
    this.checkAndRunCallback(
      userAttributesFlags.name,
      this.props.name,
      this.name,
      this.props.callbackNameChange
    );
    this.checkAndRunCallback(
      userAttributesFlags.kitId,
      this.props.kitId,
      this.kitId,
      this.props.callbackKitIdChange
    );
    this.checkAndRunCallback(
      userAttributesFlags.gender,
      this.props.gender,
      this.gender,
      this.props.callbackGenderSelected
    );
    this.checkAndRunCallback(
      userAttributesFlags.age,
      this.props.age,
      this.age,
      this.props.callbackAgeChange
    );
    /*
    this.checkAndRunCallback(
      userAttributesFlags.height,
      this.props.height,
      this.height,
      this.props.callbackHeightChange
    );
    */
    this.checkAndRunCallback(
      userAttributesFlags.weight,
      this.props.weight,
      this.weight,
      this.props.callbackWeightChange
    );
    this.checkAndRunCallback(
      userAttributesFlags.feet,
      this.props.feet,
      this.feet,
      this.props.callbackFeetChange
    );
    this.checkAndRunCallback(
      userAttributesFlags.inch,
      this.props.inch,
      this.inch,
      this.props.callbackInchChange
    );
    this.checkAndRunCallback(
      userAttributesFlags.bmi,
      this.props.bmi,
      this.bmi,
      this.props.callbackBmiChange
    );
    this.checkAndRunCallback(
      userAttributesFlags.sbp,
      this.props.sbp,
      this.sbp,
      this.props.callbackSbpChange
    );
    this.checkAndRunCallback(
      userAttributesFlags.dbp,
      this.props.dbp,
      this.dbp,
      this.props.callbackDbpChange
    );
    this.checkAndRunCallback(
      userAttributesFlags.tc,
      this.props.tc,
      this.tc,
      this.props.callbackTcChange
    );
    this.checkAndRunCallback(
      userAttributesFlags.hdl,
      this.props.hdl,
      this.hdl,
      this.props.callbackHdlChange
    );
    this.checkAndRunCallback(
      userAttributesFlags.ldl,
      this.props.ldl,
      this.ldl,
      this.props.callbackLdlChange
    );
    this.checkAndRunCallback(
      userAttributesFlags.a1c,
      this.props.a1c,
      this.a1c,
      this.props.callbackA1cChange
    );
    this.checkAndRunCallback(
      userAttributesFlags.smoker,
      this.props.smoker,
      this.smoker,
      this.props.callbackSmokerChange
    );
    this.checkAndRunCallback(
      userAttributesFlags.diabetes,
      this.props.diabetes,
      this.diabetes,
      this.props.callbackDiabetesChange
    );
    this.checkAndRunCallback(
      userAttributesFlags.fhDiabetes,
      this.props.fhDiabetes,
      this.fhDiabetes,
      this.props.callbackFhDiabetesChange
    );
    this.checkAndRunCallback(
      userAttributesFlags.bpTreated,
      this.props.bpTreated,
      this.bpTreated,
      this.props.callbackBpTreatedChange
    );
    this.checkAndRunCallback(
      userAttributesFlags.birthYear,
      this.props.birthYear,
      this.birthYear,
      this.props.callbackBirthYearChange
    );
    this.checkAndRunCallback(
      userAttributesFlags.birthMonth,
      this.props.birthMonth,
      this.birthMonth,
      this.props.callbackBirthMonthChange
    );
    // call apply callback
    if (this.props.onApply) this.props.onApply(this);
  }
  getRangeAttributeError(
    val: ?number,
    lowLimit: number,
    highLimit: number,
    name: string
  ) {
    const labelPrefix = this.props.labelPrefix
      ? this.props.labelPrefix.toLowerCase()
      : '';
    if (val === undefined || val === null || Number.isNaN(val))
      return `Fill in your ${labelPrefix}${name}`;
    const numVal: number = val;
    if (numVal < lowLimit || numVal > highLimit) {
      return `Allowed range for ${name} is ${lowLimit} - ${highLimit}`;
    }
    return null;
  }

  getError() {
    const error = this.getErrorsWithGenderArg(this.gender);
    return error;
  }

  getErrorsWithGenderArg(gender: ?string) {
    let error;
    if (this.showAttribute(userAttributesFlags.name)) {
      // name
      error = this.getMissingNameError();
      if (error) return error;
    }
    // num
    if (this.showAttribute(userAttributesFlags.num)) {
      error = this.getRangeAttributeError(
        this.num,
        traitRanges.num.lowLimit,
        traitRanges.num.highLimit,
        'Number'
      );
      if (error) return error;
    }
    if (this.showAttribute(userAttributesFlags.kitId)) {
      // name
      error = this.getKitIdError();
      if (error) return error;
    }
    if (this.showAttribute(userAttributesFlags.gender)) {
      if (!gender && !this.gender) return 'Select gender';
    }
    if (this.showAttribute(userAttributesFlags.age)) {
      // age
      error = this.getRangeAttributeError(
        this.age,
        traitRanges.age.lowLimit,
        traitRanges.age.highLimit,
        'age'
      );
      if (error) return error;
    }
    if (this.showAttribute(userAttributesFlags.birthYear)) {
      // birth year
      error = this.getRangeAttributeError(
        this.birthYear,
        traitRanges.birthYear.lowLimit,
        new Date().getFullYear(),
        'Birth Year'
      );
      if (error) return error;
    }
    if (this.showAttribute(userAttributesFlags.birthMonth)) {
      // birth year
      error = this.getRangeAttributeError(
        this.birthMonth,
        traitRanges.birthMonth.lowLimit,
        traitRanges.birthMonth.highLimit,
        'Birth Month'
      );
      if (error) return error;
    }
    // height
    /*
    if (this.showAttribute(userAttributesFlags.height)) {
      error = this.getRangeAttributeError(
        this.height,
        traitRanges.height.lowLimit,
        traitRanges.height.highLimit,
        'Height'
      );
      if (error) return error;
    }
    */
    // feet
    if (this.showAttribute(userAttributesFlags.feet)) {
      error = this.getRangeAttributeError(
        this.feet,
        traitRanges.feet.lowLimit,
        traitRanges.feet.highLimit,
        'Feet'
      );
      if (error) return error;
    }
    // inch
    if (this.showAttribute(userAttributesFlags.inch)) {
      error = this.getRangeAttributeError(
        this.inch,
        traitRanges.inch.lowLimit,
        traitRanges.inch.highLimit,
        'Inch'
      );
      if (error) return error;
    }
    // weight
    if (this.showAttribute(userAttributesFlags.weight)) {
      error = this.getRangeAttributeError(
        this.weight,
        traitRanges.weight.lowLimit,
        traitRanges.weight.highLimit,
        'Weight'
      );
      if (error) return error;
    }
    // bmi
    if (this.showAttribute(userAttributesFlags.bmi)) {
      error = this.getRangeAttributeError(
        this.bmi,
        traitRanges.bmi.lowLimit,
        traitRanges.bmi.highLimit,
        'BMI'
      );
      if (error) return error;
    }
    // tc
    if (this.showAttribute(userAttributesFlags.tc)) {
      error = this.getRangeAttributeError(
        this.tc,
        traitRanges.tc.lowLimit,
        traitRanges.tc.highLimit,
        'total cholesterol'
      );
      if (error) return error;
    }
    // hdl
    if (this.showAttribute(userAttributesFlags.hdl)) {
      error = this.getRangeAttributeError(
        this.hdl,
        traitRanges.hdl.lowLimit,
        traitRanges.hdl.highLimit,
        'HDL'
      );
      if (error) return error;
    }
    // ldl
    if (this.showAttribute(userAttributesFlags.ldl)) {
      error = this.getRangeAttributeError(
        this.ldl,
        traitRanges.ldl.lowLimit,
        traitRanges.ldl.highLimit,
        'LDL'
      );
      if (error) return error;
    }
    // sbp
    if (this.showAttribute(userAttributesFlags.sbp)) {
      error = this.getRangeAttributeError(
        this.sbp,
        traitRanges.sbp.lowLimit,
        traitRanges.sbp.highLimit,
        'SBP'
      );
      if (error) return error;
    }
    // dbp
    if (this.showAttribute(userAttributesFlags.dbp)) {
      error = this.getRangeAttributeError(
        this.dbp,
        traitRanges.dbp.lowLimit,
        traitRanges.dbp.highLimit,
        'DBP'
      );
      if (error) return error;
    }
    if (
      this.sbp !== undefined &&
      this.sbp !== null &&
      this.dbp !== undefined &&
      this.dbp !== null &&
      this.sbp <= this.dbp
    ) {
      error = 'SBP must be greater than DBP';
      return error;
    }
    if (this.showAttribute(userAttributesFlags.a1c)) {
      // a1c
      error = this.getRangeAttributeError(
        this.a1c,
        traitRanges.a1c.lowLimit,
        traitRanges.a1c.highLimit,
        'A1C'
      );
      if (error) return error;
    }
    return error;
  }

  getMissingNameError() {
    // name
    if (!this.name) {
      return `Name is a mandatory field`;
    }
    return null;
  }

  getKitIdError() {
    // kit ID
    if (!this.kitId) {
      return `Kit ID is a mandatory field`;
    }
    return null;
  }

  getValidAttributeFlags() {
    let flags: number = 0;
    if (this.showAttribute(userAttributesFlags.gender)) {
      if (this.gender) {
        // eslint-disable-next-line no-bitwise
        flags |= userAttributesFlags.gender;
      }
    }
    if (this.showAttribute(userAttributesFlags.name)) {
      if (this.name)
        // eslint-disable-next-line no-bitwise
        flags |= userAttributesFlags.name;
    }
    if (this.showAttribute(userAttributesFlags.num)) {
      if (this.num)
        // eslint-disable-next-line no-bitwise
        flags |= userAttributesFlags.num;
    }
    if (this.showAttribute(userAttributesFlags.kitId)) {
      if (this.kitId)
        // eslint-disable-next-line no-bitwise
        flags |= userAttributesFlags.kitId;
    }
    if (this.showAttribute(userAttributesFlags.age)) {
      if (
        EditablePatientAttributesImpl.isInRange(
          this.age,
          traitRanges.age.lowLimit,
          traitRanges.age.highLimit
        )
      )
        // eslint-disable-next-line no-bitwise
        flags |= userAttributesFlags.age;
    }
    // height
    /*
    if (this.showAttribute(userAttributesFlags.height)) {
      if (
        EditablePatientAttributesImpl.isInRange(
          this.height,
          traitRanges.height.lowLimit,
          traitRanges.height.highLimit
        )
      )
        // eslint-disable-next-line no-bitwise
        flags |= userAttributesFlags.height;
    }
    */
    // weight
    if (this.showAttribute(userAttributesFlags.weight)) {
      if (
        EditablePatientAttributesImpl.isInRange(
          this.weight,
          traitRanges.weight.lowLimit,
          traitRanges.weight.highLimit
        )
      )
        // eslint-disable-next-line no-bitwise
        flags |= userAttributesFlags.weight;
    }
    // feet
    if (this.showAttribute(userAttributesFlags.feet)) {
      if (
        EditablePatientAttributesImpl.isInRange(
          this.feet,
          traitRanges.feet.lowLimit,
          traitRanges.feet.highLimit
        )
      )
        // eslint-disable-next-line no-bitwise
        flags |= userAttributesFlags.feet;
    }
    // inch
    if (this.showAttribute(userAttributesFlags.inch)) {
      if (
        EditablePatientAttributesImpl.isInRange(
          this.inch,
          traitRanges.inch.lowLimit,
          traitRanges.inch.highLimit
        )
      )
        // eslint-disable-next-line no-bitwise
        flags |= userAttributesFlags.inch;
    }
    // bmi
    if (this.showAttribute(userAttributesFlags.bmi)) {
      if (
        EditablePatientAttributesImpl.isInRange(
          this.bmi,
          traitRanges.bmi.lowLimit,
          traitRanges.bmi.highLimit
        )
      )
        // eslint-disable-next-line no-bitwise
        flags |= userAttributesFlags.bmi;
    }
    // tc
    if (this.showAttribute(userAttributesFlags.tc)) {
      if (
        EditablePatientAttributesImpl.isInRange(
          this.tc,
          traitRanges.tc.lowLimit,
          traitRanges.tc.highLimit
        )
      )
        // eslint-disable-next-line no-bitwise
        flags |= userAttributesFlags.tc;
    }
    // hdl
    if (this.showAttribute(userAttributesFlags.hdl)) {
      if (
        EditablePatientAttributesImpl.isInRange(
          this.hdl,
          traitRanges.hdl.lowLimit,
          traitRanges.hdl.highLimit
        )
      )
        // eslint-disable-next-line no-bitwise
        flags |= userAttributesFlags.hdl;
    }
    // ldl
    if (this.showAttribute(userAttributesFlags.ldl)) {
      if (
        EditablePatientAttributesImpl.isInRange(
          this.ldl,
          traitRanges.ldl.lowLimit,
          traitRanges.ldl.highLimit
        )
      )
        // eslint-disable-next-line no-bitwise
        flags |= userAttributesFlags.ldl;
    }
    // sbp
    if (this.showAttribute(userAttributesFlags.sbp)) {
      if (
        EditablePatientAttributesImpl.isInRange(
          this.sbp,
          traitRanges.sbp.lowLimit,
          traitRanges.sbp.highLimit
        )
      )
        // eslint-disable-next-line no-bitwise
        flags |= userAttributesFlags.sbp;
    }
    // dbp
    if (this.showAttribute(userAttributesFlags.dbp)) {
      if (
        EditablePatientAttributesImpl.isInRange(
          this.dbp,
          traitRanges.dbp.lowLimit,
          traitRanges.dbp.highLimit
        )
      )
        // eslint-disable-next-line no-bitwise
        flags |= userAttributesFlags.dbp;
    }
    if (this.showAttribute(userAttributesFlags.a1c)) {
      // a1c
      if (
        EditablePatientAttributesImpl.isInRange(
          this.a1c,
          traitRanges.a1c.lowLimit,
          traitRanges.a1c.highLimit
        )
      )
        // eslint-disable-next-line no-bitwise
        flags |= userAttributesFlags.a1c;
    }
    if (this.showAttribute(userAttributesFlags.birthYear)) {
      // birth year
      if (
        EditablePatientAttributesImpl.isInRange(
          this.birthYear,
          traitRanges.birthYear.lowLimit,
          new Date().getFullYear()
        )
      )
        // eslint-disable-next-line no-bitwise
        flags |= userAttributesFlags.birthYear;
    }
    if (this.showAttribute(userAttributesFlags.birthMonth)) {
      // birth month
      if (
        EditablePatientAttributesImpl.isInRange(
          this.birthMonth,
          traitRanges.birthMonth.lowLimit,
          traitRanges.birthMonth.highLimit
        )
      )
        // eslint-disable-next-line no-bitwise
        flags |= userAttributesFlags.birthMonth;
    }
    if (this.showAttribute(userAttributesFlags.diabetes)) {
      // eslint-disable-next-line no-bitwise
      flags |= userAttributesFlags.diabetes;
    }
    if (this.showAttribute(userAttributesFlags.fhDiabetes)) {
      // eslint-disable-next-line no-bitwise
      flags |= userAttributesFlags.fhDiabetes;
    }
    if (this.showAttribute(userAttributesFlags.smoker)) {
      // eslint-disable-next-line no-bitwise
      flags |= userAttributesFlags.smoker;
    }
    if (this.showAttribute(userAttributesFlags.bpTreated)) {
      // eslint-disable-next-line no-bitwise
      flags |= userAttributesFlags.bpTreated;
    }
    return flags;
  }

  getApplyEnabled() {
    const validAttrFlags = this.getValidAttributeFlags();

    const mustHaveUserAttributes: number =
      this.props.mustHaveUserAttributes !== undefined &&
      this.props.mustHaveUserAttributes !== null
        ? this.props.mustHaveUserAttributes
        : minCalculatorUserAttributes;
    const isApplyEnabled =
      // eslint-disable-next-line no-bitwise
      (validAttrFlags & mustHaveUserAttributes) === mustHaveUserAttributes;
    return isApplyEnabled;
  }

  updateError() {
    const error = this.getError();
    const isApplyEnabled = this.getApplyEnabled();
    this.setState({
      error,
      isApplyEnabled
    });
    // auto apply
    if (!this.props.showButtons) this.onApply();
  }

  showAttribute(flag: number) {
    // eslint-disable-next-line no-bitwise
    return flag & this.props.showAttributeFlags;
  }

  checkAndRunCallback(
    attributeFlag: number,
    propValue: any,
    editedValue: any,
    callback: any
  ) {
    if (!this.showAttribute(attributeFlag)) return;
    if (propValue !== editedValue && callback) callback(editedValue);
  }

  checkValueInRange(value: number, lowLimit: number, highLimit: number) {
    if (Number.isNaN(value) || value < lowLimit || value > highLimit) {
      this.updateError();
      return false;
    }
    return true;
  }

  gender: ?string;
  age: ?number;
  // height: ?number;
  weight: ?number;
  feet: ?number;
  inch: ?number;
  bmi: ?number;
  tc: ?number;
  hdl: ?number;
  ldl: ?number;
  sbp: ?number;
  dbp: ?number;
  a1c: ?number;
  bpTreated: ?boolean;
  smoker: ?boolean;
  diabetes: ?boolean;
  fhDiabetes: ?boolean;
  kitId: ?string;
  name: ?string;
  num: ?number;
  birthYear: ?number;
  birthMonth: ?number;

  render() {
    const labelPrefix = this.props.labelPrefix
      ? this.props.labelPrefix.toLowerCase()
      : '';

    return (
      <Box>
        <Box
          // minW="310px"
          // maxW="310px"
          borderColor="gray.100"
          borderWidth={this.props.showBorder ? 1 : 0}
          my="10px"
        >
          <Box mb="10px">
            <Stack mx="20px" fontSize={this.props.fontSize} spacing="10px">
              <Flex aling="center" mt="20px" mb={this.props.mbTitle}>
                {this.props.title && (
                  <Text fontWeight="bold">{this.props.title}</Text>
                )}
                <Spacer />
                {/* {this.state.error && ( */}
                {/*  <ActionButton */}
                {/*    name="auto-fill" */}
                {/*    w="70px" */}
                {/*    h="30px" */}
                {/*    fontSize={12} */}
                {/*    onClick={this.onAutoFill} */}
                {/*  /> */}
                {/* )} */}
              </Flex>
              {this.showAttribute(userAttributesFlags.num) && (
                <Flex align="center" mb="10px">
                  <Text w="120px">{`${labelPrefix}Number:`}</Text>
                  <NumberInput
                    maxW="90px"
                    defaultValue={undefinedForNan(this.props.num)}
                    min={traitRanges.num.lowLimit}
                    max={traitRanges.num.highLimit}
                    onChange={this.onNumChange}
                  >
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </Flex>
              )}
              {this.showAttribute(userAttributesFlags.name) && (
                <Flex align="center" mb="10px">
                  <Text w="120px">Name:</Text>
                  <Input
                    maxW="200px"
                    defaultValue={this.props.name}
                    onChange={this.onNameChange}
                  />
                </Flex>
              )}
              {this.showAttribute(userAttributesFlags.kitId) && (
                <Flex align="center" mb="10px">
                  <Text w="120px">Kit ID:</Text>
                  <Input
                    maxW="200px"
                    defaultValue={this.props.kitId}
                    onChange={this.onKitChange}
                  />
                </Flex>
              )}
              {this.showAttribute(userAttributesFlags.gender) && (
                <Flex align="center" mb="10px">
                  <Text w="120px">Gender:</Text>
                  <Select
                    placeholder="select gender"
                    onChange={this.onGenderSelected}
                    defaultValue={this.props.gender}
                    w="150px"
                    isDisabled={this.props.callbackGenderSelected === null}
                  >
                    <option key="female" value="female">
                      female
                    </option>
                    <option key="male" value="male">
                      male
                    </option>
                  </Select>
                </Flex>
              )}
              {this.showAttribute(userAttributesFlags.age) && (
                <Flex align="center" mb="10px">
                  <Text w="120px">{`${labelPrefix}Age:`}</Text>
                  <NumberInput
                    maxW="90px"
                    defaultValue={undefinedForNan(this.props.age)}
                    min={traitRanges.age.lowLimit}
                    max={traitRanges.age.highLimit}
                    onChange={this.onAgeChange}
                  >
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                  <Text ml="10px">years</Text>
                </Flex>
              )}
              {this.showAttribute(userAttributesFlags.birthYear) && (
                <Flex align="center" mb="10px">
                  <Text w="120px">{`${labelPrefix}Birth Year:`}</Text>
                  <NumberInput
                    maxW="90px"
                    defaultValue={undefinedForNan(this.props.birthYear)}
                    min={traitRanges.birthYear.lowLimit}
                    max={traitRanges.birthYear.highLimit}
                    onChange={this.onBirthYearChange}
                  >
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </Flex>
              )}
              {this.showAttribute(userAttributesFlags.birthMonth) && (
                <Flex align="center" mb="10px">
                  <Text w="120px">{`${labelPrefix}Birth Month:`}</Text>
                  <NumberInput
                    maxW="90px"
                    defaultValue={undefinedForNan(this.props.birthMonth)}
                    min={traitRanges.birthMonth.lowLimit}
                    max={traitRanges.birthMonth.highLimit}
                    onChange={this.onBirthMonthChange}
                  >
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </Flex>
              )}
              {this.showAttribute(userAttributesFlags.feet) && (
                <Flex align="center" mb="10px">
                  <Text w="120px">{`${labelPrefix}Height:`}</Text>
                  <NumberInput
                    maxW="70px"
                    defaultValue={undefinedForNan(this.props.feet)}
                    min={traitRanges.feet.lowLimit}
                    max={traitRanges.feet.highLimit}
                    onChange={this.onFeetChange}
                    step={1}
                  >
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                  <Text ml="5px" mr="5px">
                    feet
                  </Text>
                  <NumberInput
                    maxW="70px"
                    defaultValue={undefinedForNan(this.props.inch)}
                    min={traitRanges.inch.lowLimit}
                    max={traitRanges.inch.highLimit}
                    onChange={this.onInchChange}
                    step={1}
                  >
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                  <Text ml="5px">inch</Text>
                </Flex>
              )}
              {this.showAttribute(userAttributesFlags.weight) && (
                <Flex align="center" mb="10px">
                  <Text w="120px">{`${labelPrefix}Weight:`}</Text>
                  <NumberInput
                    maxW="90px"
                    defaultValue={undefinedForNan(
                      roundWithPrecision(this.props.weight, 1)
                    )}
                    min={traitRanges.weight.lowLimit}
                    max={traitRanges.weight.highLimit}
                    onChange={this.onWeightChange}
                    step={1}
                  >
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                  <Text ml="10px">pound</Text>
                </Flex>
              )}
              {this.showAttribute(userAttributesFlags.bmi) && (
                <Flex align="center" mb="10px">
                  <Text w="120px">{`${labelPrefix}BMI:`}</Text>
                  <NumberInput
                    maxW="90px"
                    defaultValue={undefinedForNan(this.props.bmi)}
                    min={traitRanges.bmi.lowLimit}
                    max={traitRanges.bmi.highLimit}
                    onChange={this.onBmiChange}
                    step={1}
                  >
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                  <Text ml="10px">units</Text>
                </Flex>
              )}
              {this.showAttribute(userAttributesFlags.tc) && (
                <Flex align="center" mb="10px">
                  <Text w="120px">{`${labelPrefix}Total Cholesterol:`}</Text>
                  <NumberInput
                    maxW="90px"
                    defaultValue={undefinedForNan(
                      roundWithPrecision(this.props.tc, 0)
                    )}
                    min={traitRanges.tc.lowLimit}
                    max={traitRanges.tc.highLimit}
                    onChange={this.onTcChange}
                    step={1}
                  >
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                  <Text ml="10px">mg/dL</Text>
                </Flex>
              )}
              {this.showAttribute(userAttributesFlags.hdl) && (
                <Flex align="center" mb="10px">
                  <Text w="120px">{`${labelPrefix}HDL:`}</Text>
                  <NumberInput
                    maxW="90px"
                    defaultValue={undefinedForNan(
                      roundWithPrecision(this.props.hdl, 0)
                    )}
                    min={traitRanges.hdl.lowLimit}
                    max={traitRanges.hdl.highLimit}
                    onChange={this.onHdlChange}
                    step={1}
                  >
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                  <Text ml="10px">mg/dL</Text>
                </Flex>
              )}
              {this.showAttribute(userAttributesFlags.ldl) && (
                <Flex align="center" mb="10px">
                  <Text w="120px">{`${labelPrefix}LDL:`}</Text>
                  <NumberInput
                    maxW="90px"
                    defaultValue={undefinedForNan(
                      roundWithPrecision(this.props.ldl, 0)
                    )}
                    min={traitRanges.ldl.lowLimit}
                    max={traitRanges.ldl.highLimit}
                    onChange={this.onLdlChange}
                    step={1}
                  >
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                  <Text ml="10px">mg/dL</Text>
                </Flex>
              )}
              {this.showAttribute(userAttributesFlags.sbp) && (
                <Flex align="center" mb="10px">
                  <Text w="120px">{`${labelPrefix}SBP:`}</Text>
                  <NumberInput
                    maxW="90px"
                    defaultValue={this.props.sbp}
                    min={traitRanges.sbp.lowLimit}
                    max={traitRanges.sbp.highLimit}
                    onChange={this.onSbpChange}
                    step={1}
                  >
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                  <Text ml="10px">mmHg</Text>
                </Flex>
              )}
              {this.showAttribute(userAttributesFlags.dbp) && (
                <Flex align="center" mb="10px">
                  <Text w="120px">{`${labelPrefix}DBP:`}</Text>
                  <NumberInput
                    maxW="90px"
                    defaultValue={undefinedForNan(this.props.dbp)}
                    min={traitRanges.dbp.lowLimit}
                    max={traitRanges.dbp.highLimit}
                    onChange={this.onDbpChange}
                    step={1}
                  >
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                  <Text ml="10px">mmHg</Text>
                </Flex>
              )}
              {this.showAttribute(userAttributesFlags.a1c) && (
                <Flex align="center" mb="10px">
                  <Text w="120px">{`${labelPrefix}A1C`}:</Text>
                  <NumberInput
                    maxW="90px"
                    defaultValue={undefinedForNan(
                      roundWithPrecision(this.props.a1c, 1)
                    )}
                    min={traitRanges.a1c.lowLimit}
                    max={traitRanges.a1c.highLimit}
                    onChange={this.onA1cChange}
                    step={0.1}
                  >
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                  <Text ml="10px">%</Text>
                </Flex>
              )}
              {this.showAttribute(userAttributesFlags.bpTreated) && (
                <LabeledSwitch
                  mt="10px"
                  isChecked={!!this.props.bpTreated}
                  leftLabel="Treated for high blood pressure"
                  leftLabelWidth="200px"
                  callbackGetColor={
                    EditablePatientAttributesImpl.getSwitchColor
                  }
                  callbackOnValueChange={this.onBpTreatedSwitchChange}
                  callbackRightLabel={
                    EditablePatientAttributesImpl.getSwitchText
                  }
                  enabled
                />
              )}
              {this.showAttribute(userAttributesFlags.diabetes) && (
                <LabeledSwitch
                  mt="10px"
                  isChecked={!!this.props.diabetes}
                  leftLabel="Diabetes"
                  leftLabelWidth="200px"
                  callbackGetColor={
                    EditablePatientAttributesImpl.getSwitchColor
                  }
                  callbackOnValueChange={this.onDiabetesSwitchChange}
                  callbackRightLabel={
                    EditablePatientAttributesImpl.getSwitchText
                  }
                  enabled
                />
              )}
              {this.showAttribute(userAttributesFlags.fhDiabetes) && (
                <LabeledSwitch
                  mt="10px"
                  isChecked={!!this.props.fhDiabetes}
                  leftLabel="Diabetes family history"
                  leftLabelWidth="200px"
                  callbackGetColor={
                    EditablePatientAttributesImpl.getSwitchColor
                  }
                  callbackOnValueChange={this.onFhDiabetesSwitchChange}
                  callbackRightLabel={
                    EditablePatientAttributesImpl.getSwitchText
                  }
                  enabled
                />
              )}
              {this.showAttribute(userAttributesFlags.smoker) && (
                <LabeledSwitch
                  mt="10px"
                  isChecked={!!this.props.smoker}
                  leftLabel="Smoker"
                  leftLabelWidth="200px"
                  callbackGetColor={
                    EditablePatientAttributesImpl.getSwitchColor
                  }
                  callbackOnValueChange={this.onSmokerSwitchChange}
                  callbackRightLabel={
                    EditablePatientAttributesImpl.getSwitchText
                  }
                  enabled
                />
              )}
              {this.props.riskTrait && (
                <LabeledText
                  align="left"
                  labelWidth="210px"
                  textWidth="100px"
                  title={`Genetic risk for ${this.props.riskTrait}`}
                  value={this.props.riskLevel}
                />
              )}
              {this.state.error && (
                <Alert
                  status={this.state.isApplyEnabled ? 'warning' : 'error'}
                  mt="30px"
                  mr="20px"
                  borderRadius="10px"
                >
                  <AlertIcon />
                  {this.state.error}
                </Alert>
              )}
      			  {this.props.footer && (this.props.footer)}
            </Stack>
          </Box>
        </Box>
        {this.props.showButtons && (<Flex my="10px">
          <Spacer />
          <ActionButton name="Cancel" onClick={this.onCancel} mr="20px" />
          <ActionButton
            name="Apply"
            onClick={this.onApply}
            isDisabled={!this.state.isApplyEnabled}
          />
          <Spacer />
        </Flex>)}
      </Box>
    );
  }
}

EditablePatientAttributesImpl.propTypes = {
  fontSize: PropTypes.number,
  showBorder: PropTypes.bool,
  title: PropTypes.string,
  mbTitle: PropTypes.string,
  callbackGenderSelected: PropTypes.any,
  callbackAgeChange: PropTypes.any,
  // callbackHeightChange: PropTypes.any,
  callbackFeetChange: PropTypes.any,
  callbackInchChange: PropTypes.any,
  callbackWeightChange: PropTypes.any,
  callbackBmiChange: PropTypes.any,
  callbackA1cChange: PropTypes.any,
  callbackTcChange: PropTypes.any,
  callbackHdlChange: PropTypes.any,
  callbackLdlChange: PropTypes.any,
  callbackSbpChange: PropTypes.any,
  callbackDbpChange: PropTypes.any,
  callbackBpTreatedChange: PropTypes.any,
  callbackSmokerChange: PropTypes.any,
  callbackDiabetesChange: PropTypes.any,
  callbackFhDiabetesChange: PropTypes.any,
  callbackNameChange: PropTypes.any,
  callbackKitIdChange: PropTypes.any,
  callbackNumChange: PropTypes.any,
  callbackBirthYearChange: PropTypes.any,
  callbackBirthMonthChange: PropTypes.any,
  name: PropTypes.string,
  kitId: PropTypes.string,
  gender: PropTypes.string,
  age: PropTypes.number,
  sbp: PropTypes.number,
  dbp: PropTypes.number,
  hdl: PropTypes.number,
  ldl: PropTypes.number,
  tc: PropTypes.number,
  bmi: PropTypes.number,
  // height: PropTypes.number,
  feet: PropTypes.number,
  inch: PropTypes.number,
  weight: PropTypes.number,
  a1c: PropTypes.number,
  smoker: PropTypes.bool,
  riskTrait: PropTypes.string,
  riskLevel: PropTypes.string,
  bpTreated: PropTypes.bool,
  diabetes: PropTypes.bool,
  fhDiabetes: PropTypes.bool,
  labelPrefix: PropTypes.string,
  mustHaveUserAttributes: PropTypes.number,
  showButtons: PropTypes.bool,
  footer: PropTypes.any
};
// $FlowFixMe[prop-missing]
EditablePatientAttributesImpl.defaultProps = {
  fontSize: 12,
  showBorder: true,
  title: 'Patient attributes',
  mbTitle: '10px',
  callbackGenderSelected: undefined,
  callbackAgeChange: undefined,
  // callbackHeightChange: undefined,
  callbackFeetChange: undefined,
  callbackInchChange: undefined,
  callbackWeightChange: undefined,
  callbackBmiChange: undefined,
  callbackA1cChange: undefined,
  callbackTcChange: undefined,
  callbackHdlChange: undefined,
  callbackLdlChange: undefined,
  callbackSbpChange: undefined,
  callbackDbpChange: undefined,
  callbackBpTreatedChange: undefined,
  callbackSmokerChange: undefined,
  callbackDiabetesChange: undefined,
  callbackFhDiabetesChange: undefined,
  callbackNameChange: undefined,
  callbackKitIdChange: undefined,
  callbackNumChange: undefined,
  callbackBirthYearChange: undefined,
  callbackBirthMonthChange: undefined,
  name: undefined,
  kitId: undefined,
  gender: undefined,
  age: undefined,
  sbp: undefined,
  dbp: undefined,
  hdl: undefined,
  ldl: undefined,
  tc: undefined,
  // height: undefined,
  feet: undefined,
  inch: undefined,
  weight: undefined,
  bmi: undefined,
  a1c: undefined,
  smoker: undefined,
  riskTrait: undefined,
  riskLevel: undefined,
  bpTreated: false,
  diabetes: false,
  fhDiabetes: false,
  num: undefined,
  birthYear: undefined,
  birthMonth: undefined,
  labelPrefix: 'Current ',
  mustHaveUserAttributes: minCalculatorUserAttributes,
  showButtons: true,
  footer: undefined
};
