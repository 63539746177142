// @flow

import {mmolL2mgdl} from "../../utils/cholesterol";

export function calcAccScoreMen(
  age: number,
  sbp: number,
  tc: number,
  hdl: number,
  diabetes: number,
  smoker: number,
  bpTreated: number
) {
  const hdlMgdl = mmolL2mgdl(hdl);
  const tcMgdl = mmolL2mgdl(tc);
  const logAge = 12.344 * Math.log(age);
  const logTc = 11.853 * Math.log(tcMgdl);
  const logAgeByLogTc = -2.664 * Math.log(age) * Math.log(tcMgdl);
  const logHdl = -7.99 * Math.log(hdlMgdl);
  const logAgeByLogHdl = 1.769 * Math.log(age) * Math.log(hdlMgdl);
  const smokingPart = 7.837 * smoker;
  const logAgeBySmoking = -1.795 * Math.log(age) * smoker;
  const diabetesPart = 0.658 * diabetes;
  let bpPart = 0;
  if (bpTreated) {
    bpPart = 1.797 * Math.log(sbp);
  } else {
    bpPart = 1.764 * Math.log(sbp);
  }
  const s =
    logAge +
    logTc +
    logAgeByLogTc +
    logHdl +
    logAgeByLogHdl +
    bpPart +
    smokingPart +
    logAgeBySmoking +
    diabetesPart;
  const smean = 61.18;
  return 1 - 0.9144 ** Math.exp(s - smean);
}

export function calcAccScoreWomen(
  age: number,
  sbp: number,
  tc: number,
  hdl: number,
  diabetes: number,
  smoker: number,
  bpTreated: number
) {
  const hdlMgdl = mmolL2mgdl(hdl);
  const tcMgdl = mmolL2mgdl(tc);
  const logAge = -29.799 * Math.log(age);
  const logAgeSquared = 4.884 * Math.log(age) * Math.log(age);
  const logTc = 13.54 * Math.log(tcMgdl);
  const logAgeByLogTc = -3.114 * Math.log(age) * Math.log(tcMgdl);
  const logHdl = -13.578 * Math.log(hdlMgdl);
  const logAgeByLogHdl = 3.149 * Math.log(age) * Math.log(hdlMgdl);
  let bpPart = 0;
  if (bpTreated) {
    bpPart = 2.019 * Math.log(sbp);
  } else {
    bpPart = 1.957 * Math.log(sbp);
  }
  const smokingPart = 7.574 * smoker;
  const logAgeBySmoking = -1.665 * Math.log(age) * smoker;
  const diabetesPart = 0.661 * diabetes;

  const s =
    logAge +
    logAgeSquared +
    +logTc +
    logAgeByLogTc +
    logHdl +
    logAgeByLogHdl +
    bpPart +
    smokingPart +
    logAgeBySmoking +
    diabetesPart;
  const smean = -29.18;
  return 1 - 0.9665 ** Math.exp(s - smean);
}

export function calcAccScore(
  sex: boolean,
  age: number,
  sbp: number,
  tc: number,
  hdl: number,
  diabetes: number,
  smoker: number,
  bpTreated: number
) {
  return sex
    ? calcAccScoreMen(age, sbp, tc, hdl, diabetes, smoker, bpTreated)
    : calcAccScoreWomen(age, sbp, tc, hdl, diabetes, smoker, bpTreated);
}
