// @flow

const AtrialReportStrings = {
  WHAT_IS_ATRIAL_TEXT1:
    'Atrial fibrillation, often called AFib or AF, is the most common type of treated heart arrhythmia. ' +
    'An arrhythmia is when the heart beats too slowly, too fast, or in an irregular way. ' +
    'When a person has AFib, the normal beating in the upper chambers of the heart (the two atria) is irregular, ' +
    'and blood doesn’t flow as well as it should from the atria to the lower chambers of the heart (the two ventricles). ' +
    'AFib may happen in brief episodes, or it may be a permanent condition.',
  WHAT_IS_ATRIAL_TEXT2:
    'It is estimated that 12.1 million people in the United States will have AFib in 2030.',
  WHAT_IS_ATRIAL_TEXT3:
    'In 2019, AFib was mentioned on 183,321 death certificates and was the underlying cause of death in 26,535 of those deaths.',
  WHAT_IS_ATRIAL_REF1:
    'Miyasaka Y, Barnes ME, Gersh BJ, et al. Secular trends in incidence of atrial fibrillation in Olmsted County, Minnesota, 1980 to 2000, and implications on the projections for future prevalenceexternal icon. Circulation. 2006;114:199–225.',
  WHAT_IS_ATRIAL_REF2:
    'Colilla S, Crow A, Petkun W, Singer DE, Simon T, Liu X. Estimates of current and future incidence and prevalence of atrial fibrillation in the U.S. adult population. Am J Cardiol. 2013;112:1142–1147. doi: 10.1016/j.amjcard.2013.05.063.',
  WHAT_IS_ATRIAL_REF3:
    'Centers for Disease Control and Prevention, National Center for Health Statistics. About Multiple Cause of Death, 1999–2019. CDC WONDER Online Database website. Atlanta, GA: Centers for Disease Control and Prevention; 2019. Accessed February 1, 2021.',
  HOW_DID_WE_CALC_TEXT1:
    'OpenDNA calculates your score based on analysis of your ' +
    'genetic variants. Genetic variation is the difference in DNA ' +
    'sequences between individuals within a population. These are the ' +
    'differences in DNA that make every individual different. ' +
    'OpenDNA bases the calculation on a list of 4,696,776 variants that are known to be associated ' +
    'with atrial fibrillation and calculates a Polygenic Risk Score (PRS) ' +
    'based on the contribution of each genetic variant to the overall genetic risk ' +
    'for having atrial fibrillation.',
  HOW_DID_WE_CALC_TEXT2:
    'Once your PRS is calculated it is compared ' +
    'to a group of about 339,000 people to ' +
    'calculate your relative risk of developing atrial fibrillation ' +
    'compared to that population.',
  HOW_DID_WE_CALC_REF1:
    'Nielsen, J.B., Thorolfsdottir, R.B., Fritsche, L.G. et al. Biobank-driven genomic discovery yields new insight into atrial fibrillation biology. Nat Genet 50, 1234–1239 (2018). https://doi.org/10.1038/s41588-018-0171-3',
  DISCLAIMER1:
    'THIS REPORT MAY NOT INCLUDE EVERY GENETIC MARKER RELATED TO ATRIAL FIBRILLATION.',
  DISCLAIMER2:
    'THIS TEST IS NOT DIAGNOSTIC, IT IS A PREDICTION BASED ON PREDISPOSITION. ' +
    'AN INCREASED RISK SCORE DOES NOT MEAN THAT A PERSON WILL DEFINITELY DEVELOP A DISEASE ' +
    'AND A DECREASED RISK SCORE DOES NOT MEAN THAT A PERSON WILL DEFINITELY NOT DEVELOP A DISEASE.',
  DISCLAIMER3:
    'THE TEST CANNOT REPLACE MEDICAL TESTING OR MEDICAL MANAGEMENT THROUGH A HEALTHCARE PROVIDER.',
  DISCLAIMER4:
    'THE RISK MAY ALSO BE INFLUENCED BY OTHER LIFESTYLE FACTORS, OTHER CLINICAL FACTORS AND ' +
    'GENETIC VARIANTS THAT ARE NOT INCLUDED IN THE ANALYSIS OR WERE NOT REPORTED PROPERLY.',
  DISCLAIMER5:
    'YOUR RISK IS COMPARED TO A POPULATION WHERE THE MAJORITY IS OF EUROPEAN DESCENT. ' +
    'THE ACCURACY OF THE RESULTS MAY VARY IF YOU HAVE A DIFFERENT BACKGROUND.',
  DISCLAIMER6:
    'TEST RESULTS SHOULD BE INTERPRETED BY A HEALTHCARE PROVIDER. MEDICAL MANAGEMENT AND ' +
    'DECISION-MAKING FOR PREVENTION PRACTICES SHOULD BE DONE IN THE CONTEXT OF THE PATIENT’S ' +
    'FULL CLINICAL HISTORY AND NOT BASED SOLELY ON THE TEST RESULTS.',
  GENETIC_RISK_ATRIAL_INFO_TEXT:
    'The polygenic risk score (PRS) of the patient is calculated by analyzing ' +
    '527,209 variants known to be associated with atrial fibrillation. ' +
    'The PRS is then compared to a reference scale, built from scores of ~339K individuals. ' +
    'The scale is divided into percentiles that reflect the genetic risk level. ' +
    "The patient risk percentile is determined by comparing the individual's PRS value against the threshold risk values of each percentile.",
  ATRIAL_RISK_IN_POPULATION:
    'The graph shows the risk for having atrial fibrillation vs. the genetic risk level. Genetic risk (PRS) was calculated for ' +
    'more than 339K individuals. The scores were divided into deciles and the risk of having atrial fibrillation was calculated for each decile. ' +
    "Gray bars indicate the risk level per decile. The highlighted bar represents patient risk level based on the individual's genetic risk.",
  ATRIAL_RISK_VS_AVERAGE:
    'The graph shows patient risk level for having atrial fibrillation vs. the average risk in the population. ' +
    "The risk of the patient is determined based on the individual's genetic risk level. "
};

export default AtrialReportStrings;
