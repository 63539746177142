// @flow
import React from 'react';
import {
  Box, useDisclosure, Flex, Text, Collapse, IconButton, Spacer
} from '@chakra-ui/react';
import COLORS from './colors';
import {InfoIcon, CloseIcon} from "@chakra-ui/icons";
import PropTypes from "prop-types";

type Props = {
  children: any,
  infoText: string,
  infoLocation?: string,
  ml?: string
};

export default function InfoWrapper(props: Props) {
  const { isOpen, onToggle } = useDisclosure();

  const info = <Collapse in={isOpen} animateOpacity>
        <Flex
          p='10px'
          color='white'
          bg={COLORS.REPORT_TEXT}
          rounded='md'
          shadow='md'
          align={"top"}
        >
          <Text mx={"10px"}>{props.infoText}</Text>
          <Spacer/>
          <IconButton
            isRound={false}
            variant='solid'
            aria-label='Done'
            onClick={onToggle}
            bg={"transparent"}
            borderColor={"gray.500"}
            borderWidth={1}
            color={"white"}
            size={"sm"}
            fontSize={10}
            icon={<CloseIcon />}
          />
        </Flex>
      </Collapse>;

  return (
    <Box>
      <Flex align={"top"}>
        {props.children}
        <IconButton
          ml={props.ml}
          isRound={true}
          variant='solid'
          aria-label='Done'
          // colorScheme='blue'
          onClick={onToggle}
          bg={"transparent"}
          color={COLORS.REPORT_TEXT}
          icon={<InfoIcon />}
        />
        {props.infoLocation === "right" && info}
      </Flex>
      {props.infoLocation === "bottom" && info}
    </Box>)
}

InfoWrapper.propTypes = {
  infoLocation: PropTypes.string,
  ml: PropTypes.string
};

InfoWrapper.defaultProps = {
  infoLocation: "bottom",
  ml: "10px"
};
