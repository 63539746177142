// @flow
import React from 'react';
import { Box, Link, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import COLORS from '../common/colors';
import IconTextAndLink from '../common/IconTextAndLink';
import alcoholImage from '../images/alcohol.png';
import dietImage from '../images/diet.png';
import runningImage from '../images/running.png';
import breastfeedingImage from '../images/breastfeeding.png';
import bloodTestImage from '../images/blood-tube.png';

type Props = {
  isMobile: boolean
};

export default function BreastCancerRecommendationSection(props: Props) {
  return (
    <Box
      sx={{
        '@media print': {
          pageBreakBefore: 'always;'
        }
      }}
    >
      <Text mt="40px" color={COLORS.REPORT_TEXT} fontWeight="semibold">
        To reduce risk for developing breast cancer you can refer to the
        following:
      </Text>
      <Link
        color="teal.500"
        href="https://www.cancer.org/latest-news/five-ways-to-reduce-your-breast-cancer-risk.html"
        isExternal
        mb="20px"
      >
        https://www.cancer.org/latest-news/five-ways-to-reduce-your-breast-cancer-risk.html
      </Link>
      <IconTextAndLink
        imageSrc={bloodTestImage}
        text="Get screened"
        linkText="Get screened for breast cancer"
        link="https://www.cdc.gov/cancer/breast/basic_info/screening.htm"
        isMobile={props.isMobile}
      />
      <IconTextAndLink
        imageSrc={dietImage}
        text="Diet"
        linkText="Preserve a healthy diet"
        link="https://www.heart.org/en/healthy-living/healthy-eating/losing-weight"
        isMobile={props.isMobile}
      />
      <IconTextAndLink
        imageSrc={runningImage}
        text="Exercise"
        linkText="Recommendations for physical activity"
        link="https://www.heart.org/en/healthy-living/fitness/fitness-basics/aha-recs-for-physical-activity-in-adults"
        isMobile={props.isMobile}
      />
      <IconTextAndLink
        imageSrc={alcoholImage}
        text="Limited alcohol"
        linkText="Limit alcohol consumption"
        link="https://www.wcrf-uk.org/preventing-cancer/our-cancer-prevention-recommendations/limit-alcohol-consumption/"
        isMobile={props.isMobile}
      />
      <IconTextAndLink
        imageSrc={breastfeedingImage}
        text="Breastfeeding your children"
        linkText="Why breastfeeding matters"
        link="https://www.cdc.gov/breastfeeding/about-breastfeeding/why-it-matters.html"
        isMobile={props.isMobile}
      />
    </Box>
  );
}

BreastCancerRecommendationSection.propTypes = {
  isMobile: PropTypes.bool
};

BreastCancerRecommendationSection.defaultProps = {
  isMobile: false
};
