// @flow
import React from 'react';
import { Box } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import COLORS from '../colors';

type Props = {
  children: any,
  color?: string,
  topm?: string,
  bottomm?: string
};

export default function WhitePanel(props: Props) {
  const innerProps: Object = { ...props };
  innerProps.p = '1%';
  return (
    <Box borderRadius="8px" bg="white" mt={props.topm} mb={props.bottomm}>
      <Box {...innerProps}>{props.children}</Box>
    </Box>
  );
}

WhitePanel.propTypes = {
  color: PropTypes.string,
  topm: PropTypes.string,
  bottomm: PropTypes.string
};

WhitePanel.defaultProps = {
  color: COLORS.REPORT_TEXT,
  topm: '10px',
  bottomm: '10px'
};
