// @flow
import React from 'react';
import { Box, Link, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import COLORS from '../../common/colors';
import IconTextAndLink from '../../common/IconTextAndLink';
import htnGaugeImage from '../../images/blood-pressure-gauge-light.png';
import dietImage from '../../images/diet.png';
import runningImage from '../../images/running.png';
import stressImage from '../../images/yoga.png';
import noSmokingImage from '../../images/no-smoking.png';
import bloodTestImage from '../../images/blood-tube.png';

type Props = {
  isMobile: boolean
};

export default function CkdRecommendationSection(props: Props) {
  return (
    <Box>
      <Text mt="40px" color={COLORS.REPORT_TEXT} fontWeight="semibold">
        To reduce risk for developing chronic kidney disease (CKD) you can refer
        to the following:
      </Text>
      <Link
        color="teal.500"
        href="https://www.cdc.gov/kidneydisease/prevention-risk.html"
        isExternal
        mb="20px"
      >
        https://www.cdc.gov/kidneydisease/prevention-risk.htmls
      </Link>
      <IconTextAndLink
        imageSrc={htnGaugeImage}
        text="Monitoring your blood pressure regularly"
        linkText="Monitoring your blood pressure at home"
        link="https://www.heart.org/en/health-topics/high-blood-pressure/understanding-blood-pressure-readings/monitoring-your-blood-pressure-at-home"
        isMobile={props.isMobile}
      />
      <IconTextAndLink
        imageSrc={dietImage}
        text="Diet"
        linkText="Preserve a heart-healthy diet"
        link="https://www.heart.org/en/healthy-living/healthy-eating/losing-weight"
        isMobile={props.isMobile}
      />
      <IconTextAndLink
        imageSrc={runningImage}
        text="Exercise"
        linkText="Recommendations for physical activity"
        link="https://www.heart.org/en/healthy-living/fitness/fitness-basics/aha-recs-for-physical-activity-in-adults"
        isMobile={props.isMobile}
      />
      <IconTextAndLink
        imageSrc={stressImage}
        text="Stress reduction"
        linkText="Stress management"
        link="https://www.heart.org/en/healthy-living/healthy-lifestyle/stress-management"
        isMobile={props.isMobile}
      />
      <IconTextAndLink
        imageSrc={noSmokingImage}
        text="Smoking cessation"
        linkText="Quit smoking, vaping and tobacco use"
        link="https://www.heart.org/en/healthy-living/healthy-lifestyle/quit-smoking-tobacco"
        isMobile={props.isMobile}
      />
      <Text mt="40px" color="gray.600">
        If you are at risk, get tested for CKD regularly. Ask your doctor to
        test your blood or urine. <br />
        If you have diabetes, get tested yearly.
      </Text>
      <IconTextAndLink
        imageSrc={bloodTestImage}
        text="Get tested"
        linkText="Get tested for CKD"
        link="https://www.cdc.gov/kidneydisease/publications-resources/get-tested.html?CDC_AA_refVal=https%3A%2F%2Fwww.cdc.gov%2Ffeatures%2Fkidney-health%2Findex.html"
        isMobile={props.isMobile}
      />
    </Box>
  );
}

CkdRecommendationSection.propTypes = {
  isMobile: PropTypes.bool
};

CkdRecommendationSection.defaultProps = {
  isMobile: false
};
