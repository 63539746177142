// @flow
import React from 'react';

import { Box } from '@chakra-ui/react';
import withNavigation from '../main/WithRoutes';
import htnGaugeImage from '../images/blood-pressure-gauge-light.png';

import BpCalculator from '../bp/calculator/BpCalculator';
import BpRecommendationSection from '../bp/BpRecommendationSection';
import BpExplanatorySection from '../bp/BpExplanatorySection';
import { getPatientId } from './capilots-utils';
import calcBmi from '../utils/bmi';
import { bpReportStrings } from '../bp/bp-report-common';
import PatientAttributes from "../common/reports/PatientAttributes";
import {getRiskLevelByPercentile} from "../common/risk-colors";
import {bpCalculatorUserAttribute} from "../common/reports/report-common";
import {roundWithPrecision} from "../utils/numbers";
import {isNullOrUndefined} from "url/util";
import {isSelfUrl} from "../main/routes";
import GeneticReportOrMobile from "../common/reports/GeneticReportOrMobile";
import MobileBpCalculator from "../bp/calculator/MobileBpCalculator";
import withMobile from "../common/mobile/withMobile";

type Props = {
  params: any,
  location: any,
  visit: Object,
  age: ?number,
  printing: boolean,
  patientId: ?string,
  bp: Object,
  loading: boolean,
  isMobile: boolean,
  isClinicianView: boolean
};


export class PilotBpRiskReportImpl extends React.Component<Props> {
  /* eslint-disable no-unused-vars */
  constructor(props: Props) {
    super();
    this.patientId = props.patientId ?? getPatientId(props.params, props.location);
    (this: any).getPartialDataAlert = this.getPartialDataAlert.bind(this);
  }

  getPartialDataAlert() {
    if (isSelfUrl(this.props.location.pathname)) {
      return 'The estimated blood pressure changes as a result of risk factor value changes are based on partial clinical data.\nFill in your attributes in order to get more accurate estimations.'
    }
    return 'The estimated blood pressure changes as a result of risk factor value changes are based on partial clinical data.\nFill in patient attributes in order to get more accurate estimations.'
  }

  patientId: string;

  render() {
    let bmi: ?number;
    if (this.props.visit) {
      if (this.props.visit.bmi) {
        ({ bmi } = this.props.visit);
      } else {
        bmi = calcBmi(this.props.visit.weight, this.props.visit.height / 100);
      }
    }
    const {isMobile} = this.props;
    const showAlert = !this.props.loading && !this.props.printing && this.props.visit && (
        isNullOrUndefined(this.props.visit.sex) ||
        !this.props.visit.age ||
        !bmi
    );
    const alertMessage = showAlert ? this.getPartialDataAlert() : undefined;

    const printingPatientAttr= this.props.printing &&
      <Box w={"420px"} borderWidth={1} borderColor="gray.100">
        <PatientAttributes
            gender={this.props.visit.sex ? "Male": "Female"}
            age={this.props.visit.age}
            bmi={roundWithPrecision(this.props.visit.bmi, 1)}
            riskTrait="HTN (systolic)"
            riskLevel={getRiskLevelByPercentile(this.props.bp.sbp.percentile + 1)}
            riskTrait2="HTN (diastolic)"
            riskLevel2={getRiskLevelByPercentile(this.props.bp.dbp.percentile + 1)}
            orderLabs={false}
            labelPrefix="Current "
            showBorder={false}
            showAttributeFlags={bpCalculatorUserAttribute}
            spacing={"7px"}
          >
          </PatientAttributes>
        </Box>;

    let calculator = null;
    if (this.props.bp && this.props.visit) {
      calculator = <Box
          mt="20px"
          sx={{
            '@media print': {
              pageBreakBefore: 'always;'
            }
          }}
        >
        {isMobile && <MobileBpCalculator
            sex={this.props.visit.sex ? 1 : 0}
            age={this.props.age}
            bmi={bmi}
            sbpScore={this.props.bp.sbp.stdized_overall_risk_sbp}
            dbpScore={this.props.bp.dbp.stdized_overall_risk_dbp}
            sbpLinReg={this.props.bp.sbp.lin_reg_all_params}
            dbpLinReg={this.props.bp.dbp.lin_reg_all_params}
            sbpQuartile={this.props.bp.sbp.quartile}
            sbpPercentile={this.props.bp.sbp.percentile + 1}
            dbpQuartile={this.props.bp.dbp.quartile}
            dbpPercentile={this.props.bp.dbp.percentile + 1}
            callbackOnChange={null}
            orderLabs={false}
            printing={this.props.printing}
          />}
        {!isMobile && <BpCalculator
            sex={this.props.visit.sex ? 1 : 0}
            age={this.props.age}
            bmi={bmi}
            sbpScore={this.props.bp.sbp.stdized_overall_risk_sbp}
            dbpScore={this.props.bp.dbp.stdized_overall_risk_dbp}
            sbpLinReg={this.props.bp.sbp.lin_reg_all_params}
            dbpLinReg={this.props.bp.dbp.lin_reg_all_params}
            sbpQuartile={this.props.bp.sbp.quartile}
            sbpPercentile={this.props.bp.sbp.percentile + 1}
            dbpQuartile={this.props.bp.dbp.quartile}
            dbpPercentile={this.props.bp.dbp.percentile + 1}
            callbackOnChange={null}
            orderLabs={false}
            printing={this.props.printing}
          />}
        </Box>
    }

    return (
      <Box sx={{ '@media print': { '@page': { size: 'landscape;' } } }}>
        <GeneticReportOrMobile
          isDemo={false}
          reportTitle="HYPERTENSION RISK REPORT"
          configTrait="bp"
          loading={this.props.loading}
          titleImage={htnGaugeImage}
          patientNum={this.props.visit ? this.props.visit.patient_num : null}
          patientName={this.props.visit ? this.props.visit.patient_name : null}
          geneticKitId={this.props.visit ? this.props.visit.kit_id : ''}
          riskTitle={['Systolic Blood Pressure (SBP)', 'Diastolic Blood Pressure (DBP)']}
          printing={this.props.printing}
          alertMessage={alertMessage}
          showAlert={showAlert}
          geneticRiskInfoText={[
            bpReportStrings.GENETIC_RISK_SBP_INFO_TEXT,
            bpReportStrings.GENETIC_RISK_DBP_INFO_TEXT
          ]}
          riskPercentile={
            this.props.bp
              ? [this.props.bp.sbp.percentile, this.props.bp.dbp.percentile]
              : null
          }
          personChartTitle={[
            'Systolic Blood Pressure (SBP)',
            'Diastolic Blood Pressure (DBP)'
          ]}
          patientAttributesSection={printingPatientAttr}
          chartsSection={null}
          calculatorSection={calculator}
          recommendationSection={!this.props.isClinicianView && <BpRecommendationSection isMobile={isMobile} />}
          explanatorySection={<BpExplanatorySection isMobile={isMobile} />}
        />
      </Box>
    );
  }
}

// $FlowFixMe
export default withNavigation(withMobile(PilotBpRiskReportImpl));
