import {Authenticator} from '@aws-amplify/ui-react';
import {useTheme, Heading} from "@aws-amplify/ui-react";
import {
  Box,
  Flex,
  Text
} from '@chakra-ui/react';
import { BiMobileVibration } from 'react-icons/bi';
import React from "react";
import COLORS from "../common/colors";

export const amplifyComponents = {
  ForceNewPassword: {
    Header() {
      const { tokens } = useTheme();

      return (
        <Box padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}>
          <Heading
            level={5}
          >
            Change your password
          </Heading>
        </Box>
      );
    },
    FormFields() {
      return (
          <>
            <Text
              color={COLORS.REPORT_TEXT}
              fontSize={13}
              mt={"10px"}
            >
              A new password is required.
            </Text>
            <Text
              color={COLORS.REPORT_TEXT}
              fontSize={13}
              mt={"10px"}
            >
              The new password must contain at least 8 characters including uppercase, lowercase, number and special character.
            </Text>
            <Authenticator.ForceNewPassword.FormFields/>
          </>
      )
    }
  },
  SignIn: {
    Header() {
      const { tokens } = useTheme();

      return (
        <Box padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}>
          <Heading
            level={5}
          >
            Sign in to your account
          </Heading>
        </Box>
      );
    }
  },

  ConfirmSignIn: {
    Header() {
      const { tokens } = useTheme();
      return (
        <>
          <Heading
            padding={`0 0 ${tokens.space.xl} 0`}
            level={5}
          >
            Confirm sign in:
          </Heading>
          <Flex align={"top"} mb={"10px"}>
            <Box as={BiMobileVibration} h="32px" w="32px" mb={0} mr={"20px"}/>
            <Text
              color={COLORS.REPORT_TEXT}
            >
              We've just sent you a confirmation code to your mobile phone.
            </Text>
          </Flex>
        </>
      );
    }
  },
  ResetPassword: {
    Header() {
      return (
        <Heading
          level={5}
        >
          Reset your password code
        </Heading>
      );
    }
  },
  ConfirmResetPassword: {
    Header() {
      return (
        <Heading
          level={5}
        >
          Change your password
        </Heading>
      );
    }
  },
  // SignUp: {
  //   Header() {
  //     const { tokens } = useTheme();
  //
  //     return (
  //       <Heading
  //         padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
  //         level={3}
  //       >
  //         Create a new account
  //       </Heading>
  //     );
  //   },
  //   Footer() {
  //     // const { toSignIn } = useAuthenticator();
  //
  //     return (
  //       <View marginLeft={"35px"} fontSize={12}>
  //         <Text>OpenDNA uses SMS notifications for authentication purpose.</Text>
  //         <CheckboxField name="consent" label="I consent receiving SMS messages for authentication" marginTop={"10px"} marginBottom={"20px"} fontSize={13}></CheckboxField>
  //       </View>
  //     );
  //   },
  // },
  // ConfirmSignUp: {
  //   Header() {
  //     const { tokens } = useTheme();
  //     return (
  //       <Heading
  //         padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
  //         level={3}
  //       >
  //         Enter Information:
  //       </Heading>
  //     );
  //   },
  //   Footer() {
  //     return <Text>Footer Information</Text>;
  //   },
  // },
};

export const amplifyFormFields = {
  signIn: {
    username: {
      label: 'Username',
      labelHidden: false,
      placeholder: 'Enter your username'
    },
    password: {
      label: 'Password',
      labelHidden: false,
      placeholder: 'Enter your password'
    }
  },
  // signUp: {
  //   password: {
  //     labelHidden: false,
  //     label: 'Password:',
  //     placeholder: 'Enter your Password:',
  //     isRequired: false,
  //     order: 2,
  //   },
  //   confirm_password: {
  //     labelHidden: false,
  //     label: 'Confirm Password:',
  //     order: 1,
  //   },
  // },
  forceNewPassword: {
    password: {
      label: 'New password:',
      labelHidden: false,
      placeholder: 'Enter your new password'
    },
    confirm_password: {
      labelHidden: false,
      label: 'Confirm password:',
      placeholder: 'Confirm your new password '
    }
//     phone_number: {
//       labelHidden: false,
//       label: 'Mobile phone number:',
//       placeholder: 'Mobile phone '
//     },
//     name: {
//       labelHidden: false,
//       label: 'Name:',
//       placeholder: 'Enter your name '
//     }
  },
  resetPassword: {
    username: {
      label: 'Username',
      labelHidden: false,
      placeholder: 'Enter your username'
    }
  },
  confirmResetPassword: {
    confirmation_code: {
      labelHidden: false,
      placeholder: 'Enter your confirmation code',
      label: 'Confirmation code:',
      isRequired: false,
    },
    password: {
      labelHidden: false,
      label: 'New password:',
      placeholder: 'Enter your new password ',

    },
    confirm_password: {
      labelHidden: false,
      label: 'Confirm password:',
      placeholder: 'Confirm your new password ',
    },
  },
  confirmSignIn: {
    confirmation_code: {
      labelHidden: false,
      label: 'Confirmation code:',
      placeholder: 'Enter your authentication code ',
      isRequired: false,
    }
  }
};
