// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Text, Stack } from '@chakra-ui/react';
import { v4 as uuid4 } from 'uuid';
import COLORS from '../common/colors';
import shortDateStringToDisplay from '../utils/date_utils';

type RowProps = {
  number: number | string,
  group: ?string,
  drug: string,
  dosage: string,
  lastPrescriptionDate: ?string,
  isHeader: ?boolean,
  fontSize?: number
};

export function MedicationHistoryRow(props: RowProps) {
  return (
    <Flex
      fontWeight={props.isHeader ? 'bold' : 'normal'}
      align="center"
      color={COLORS.LABEL_TEXT_COLOR}
      fontSize={props.fontSize}
    >
      <Text minW="15px" textAlign="left">
        {props.number}
      </Text>
      <Text
        minW="100px"
        maxW="100px"
        textAlign="left"
        ml="10px"
        textOverflow="ellipsis"
      >
        {props.group}
      </Text>
      <Text minW="90px" textAlign="left" ml="10px" textOverflow="ellipsis">
        {props.drug}
      </Text>
      <Text minW="60px" textAlign="left">
        {props.dosage}
      </Text>
      <Text minW="80px" textAlign="left">
        {props.lastPrescriptionDate}
      </Text>
    </Flex>
  );
}

MedicationHistoryRow.propTypes = {
  fontSize: PropTypes.number
};

MedicationHistoryRow.defaultProps = {
  fontSize: 12
};

type Props = {
  medications: Array<Object>,
  fontSize?: number
};

export default function MedicationHistoryImpl(props: Props) {
  return (
    <Stack
      fontSize={props.fontSize}
      color={COLORS.LABEL_TEXT_COLOR}
      spacing="4px"
      align="top"
    >
      <MedicationHistoryRow
        number="#"
        group="group"
        drug="drug"
        dosage="dosage"
        lastPrescriptionDate="date"
        fontSize={props.fontSize}
        isHeader
      />
      {props.medications.map((medication, index) => (
        <MedicationHistoryRow
          key={uuid4()}
          number={index + 1}
          group={medication.group}
          drug={medication.drug}
          dosage={medication.dosage}
          lastPrescriptionDate={shortDateStringToDisplay(medication.date)}
          isHeader={false}
          fontSize={props.fontSize}
          mb="4px"
        />
      ))}
    </Stack>
  );
}

MedicationHistoryImpl.propTypes = {
  fontSize: PropTypes.number
};

MedicationHistoryImpl.defaultProps = {
  fontSize: 12
};
