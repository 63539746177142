import React from 'react';
import {Box, Flex, Image, Spacer, Text} from "@chakra-ui/react";
import ssoImage from "../images/key.png";
import InProgressImpl from "../common/InProgress";
import IconAttribute from "../common/IconAttributes";

export function SingleSignOn() {
	return (<Box mt="20px" align="center" mx={"8%"}>
			<Text color="gray.600" fontSize={24} textAlign={"left"} my={"30px"}>
				SINGLE SIGN ON (SSO)
			</Text>
			<Flex mb={"30px"}>
				<Spacer/>
				<Box>
					<Image src={ssoImage} h="256px" alt="" mt="50px"/>
					<InProgressImpl label="In progress, please wait..."/>
				</Box>
				<Spacer/>
			</Flex>
			<Box mx="5%" mt={"30px"}>
				<Box minH={"30px"}/>
				<IconAttribute precedingLine/>
			</Box>
		</Box>
	);
}