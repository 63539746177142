// @flow
import React from 'react';
import { Box, Link, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import COLORS from '../../common/colors';
import IconTextAndLink from '../../common/IconTextAndLink';
import skinImage from '../../images/skin-cancer.png';
import protectiveWearImage from '../../images/protective-wear.png';
import uvProtectionImage from '../../images/uv-protection.png';
import noSunImage from '../../images/no-sunlight.png';
import noTanningImage from '../../images/no-tanning.png';

type Props = {
  isMobile: boolean
};

export default function MelanomaRecommendationSection(props: Props) {
  return (
    <Box
      sx={{
        '@media print': {
          pageBreakBefore: 'always;'
        }
      }}
    >
      <Text mt="40px" color={COLORS.REPORT_TEXT} fontWeight="semibold">
        To know more about melanoma cancer, its risk factors and how to reduce risk for developing melanoma cancer you can refer to the
        following:
      </Text>
      <Link
        color="teal.500"
        href="https://www.mayoclinic.org/diseases-conditions/melanoma/symptoms-causes/syc-20374884"
        isExternal
        mb="20px"
      >
        https://www.mayoclinic.org/diseases-conditions/melanoma/symptoms-causes
      </Link>
      <Text mt="40px" color={COLORS.REPORT_TEXT} fontWeight="semibold">
        To reduce risk for developing melanoma cancer:
      </Text>
      <IconTextAndLink
        imageSrc={noSunImage}
        text="Avoid the sun during the middle of the day"
        linkText={undefined}
        link={undefined}
        isMobile={props.isMobile}
      />
      <IconTextAndLink
        imageSrc={uvProtectionImage}
        text="Wear sunscreen year-round"
        linkText={undefined}
        link={undefined}
        isMobile={props.isMobile}
      />
      <IconTextAndLink
        imageSrc={protectiveWearImage}
        text="Wear protective clothing"
        linkText={undefined}
        link={undefined}
        isMobile={props.isMobile}
      />
      <IconTextAndLink
        imageSrc={noTanningImage}
        text="Avoid tanning lamps and beds"
        linkText={undefined}
        link={undefined}
        isMobile={props.isMobile}
      />
      <IconTextAndLink
        imageSrc={skinImage}
        text="Become familiar with your skin so that you'll notice changes"
        linkText={undefined}
        link={undefined}
        isMobile={props.isMobile}
      />
    </Box>
  );
}

MelanomaRecommendationSection.propTypes = {
  isMobile: PropTypes.bool
};

MelanomaRecommendationSection.defaultProps = {
  isMobile: false
};
