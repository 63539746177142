// @flow
import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import COLORS from '../common/colors';
import {
  calcHeartAge,
  calcPrevalence,
  getCurve,
  getHeartAgeQuantile,
  // getMaxAgeForHeartAgeCalc,
  HEART_AGE_MAX_AGE,
  HEART_AGE_MID_QUANTILE,
  HEART_AGE_MIN_AGE
} from './heart_age/heart-age';
import { getRiskColorByPercentile } from '../common/risk-colors';

require('highcharts/modules/annotations')(Highcharts);

type Props = {
  geneticRiskPercentile: number,
  age: ?number,
  height: number,
  isMobile?: boolean,
  title?: ?string
};

type State = {};

export default class HeartAgeChart extends React.Component<Props, State> {
  static defaultProps = {
    isMobile: false
  };
  constructor(props: Props) {
    super(props);
    (this: any).prepareDatasets = this.prepareDatasets.bind(this);
    (this: any).prepareAnnotations = this.prepareAnnotations.bind(this);

    this.intervalX = 1;
    this.minX = HEART_AGE_MIN_AGE;
    this.maxX = HEART_AGE_MAX_AGE;
    this.minY = 0;
    this.maxY = 38;
    this.yTickInterval = 2;
    this.yUnit = this.yTickInterval / 5;
    this.geneticRiskQuantile = getHeartAgeQuantile(
      this.props.geneticRiskPercentile
    );
  }

  getQuantileCurvePoints(
    q: number,
    xWithRadius: ?number = undefined,
    maxX: ?number = undefined
  ) {
    const upperX = maxX || this.maxX;
    const { a, b, c } = getCurve(q);
    const data: Array<Object> = [];
    for (let x = this.minX; x <= upperX; x += this.intervalX) {
      const y = (a * x ** 2 + b * x + c) * 100;
      data.push({
        x,
        y,
        marker: {
          radius: xWithRadius === x ? 5 : 0,
          symbol: 'circle'
        }
      });
    }
    return data;
  }

  prepareDatasets() {
    const avgSeriesColor = COLORS.REPORT_TEXT;
    // if (this.geneticRiskQuantile === HEART_AGE_MID_QUANTILE)
    //   avgSeriesColor = 'transparent';
    return [
      {
        type: 'line',
        dashStyle: 'Solid',
        name: 'your risk curve',
        color: this.riskColor,
        marker: {
          enabled: true,
          radius: 0
        },
        data: this.personSeries
      },
      {
        type: 'line',
        dashStyle: 'Dash',
        name: 'average population risk curve',
        color: avgSeriesColor,
        marker: {
          enabled: true,
          radius: 0
        },
        data: this.avgSeries
      }
    ];
  }

  prepareAnnotations() {
    if (!this.props.age) {
      return [
        {
          labels: [],
          shapes: [],
          draggable: ''
        }
      ];
    }
    const labels = [];
    const shapes = [];
    const heartAge = this.heartAge ? Math.round(this.heartAge) : undefined;
    let yAge = this.props.age
      ? calcPrevalence(this.props.age, this.geneticRiskQuantile) * 100
      : 0;
    if (this.props.isMobile && this.props.age > 68) yAge -= 2;

    if (heartAge) {
      const yHeartAgeLabel = Math.min(
        calcPrevalence(heartAge, HEART_AGE_MID_QUANTILE) * 100 + 5,
        30
      );
      labels.push({
        point: {
          x: heartAge,
          y: yHeartAgeLabel,
          xAxis: 0,
          yAxis: 0
        },
        text: 'your heart age',
        overflow: 'none',
        backgroundColor: COLORS.REPORT_TEXT,
        borderWidth: this.props.isMobile ? 0 : 0.5,
        padding: this.props.isMobile ? 1 : 5
      });
      shapes.push({
        fill: 'none',
        stroke: COLORS.REPORT_TEXT,
        strokeWidth: 1,
        type: 'path',
        points: [
          {
            x: heartAge,
            y: 0,
            xAxis: 0,
            yAxis: 0
          },
          {
            x: heartAge,
            y: yHeartAgeLabel + 4,
            xAxis: 0,
            yAxis: 0
          }
        ]
      });
    }
    labels.push({
      point: {
        x: this.props.age,
        y: yAge,
        xAxis: 0,
        yAxis: 0
      },
      text: 'your age',
      overflow: 'none',
      backgroundColor: COLORS.REPORT_TEXT_GRAY,
      borderWidth: this.props.isMobile ? 0 : 0.5,
      padding: this.props.isMobile ? 1 : 5
    });
    shapes.push({
      fill: 'none',
      stroke: COLORS.REPORT_TEXT_GRAY,
      strokeWidth: 1,
      type: 'path',
      points: [
        {
          x: this.props.age,
          y: 0,
          xAxis: 0,
          yAxis: 0
        },
        {
          x: this.props.age,
          y: yAge > 85 ? yAge : yAge + 1,
          xAxis: 0,
          yAxis: 0
        }
      ]
    });
    return [
      {
        labels,
        shapes,
        draggable: ''
      }
    ];
  }

  minX: number;
  maxX: number;
  intervalX: number;
  minY: number;
  maxY: number;
  yUnit: number;
  seriesData: Array<Object>;
  personSeries: Array<Object>;
  avgSeries: Array<number>;
  geneticRiskQuantile: number;
  riskColor: string;
  heartAge: ?number;
  yTickInterval: number;

  render() {
    this.heartAge = this.props.age
      ? calcHeartAge(this.props.age, this.geneticRiskQuantile)
      : undefined;
    this.riskColor = getRiskColorByPercentile(this.props.geneticRiskPercentile);
    this.avgSeries = this.getQuantileCurvePoints(
      HEART_AGE_MID_QUANTILE,
      this.heartAge ? Math.round(this.heartAge) : undefined,
      undefined
    );
    this.personSeries = this.getQuantileCurvePoints(
      this.geneticRiskQuantile,
      this.props.age,
      HEART_AGE_MAX_AGE
    );
    const yTicks: Array<number> = [];
    const xTicks: Array<number> = [];
    for (let i = this.minX; i <= this.maxX; i += 10) {
      xTicks.push(i);
    }
    for (let i = this.minY; i <= this.maxY; i += this.yTickInterval) {
      yTicks.push(i);
    }
    const options = {
      credits: {
        enabled: false
      },
      chart: {
        backgroundColor: 'transparent',
        height: this.props.height,
        marginLeft: 50
      },
      title: {
        text: this.props.title ?? 'Genetic risk effect on heart age',
        style: {
          color: COLORS.REPORT_TEXT,
          fontSize: '12px',
          fontWeight: 'bold'
        }
      },
      xAxis: {
        min: this.minX - 0.5,
        max: this.props.isMobile ? this.maxX + 2 : this.maxX + 1,
        gridLineWidth: 1,
        gridLineColor: COLORS.GRAY_192_TRANSPARENCY_0_2,
        title: {
          text: 'age'
        },
        tickPositioner() {
          return xTicks;
        }
      },
      yAxis: {
        min: this.minY - 0.5,
        max: this.maxY + 1,
        gridLineWidth: 1,
        gridLineColor: COLORS.GRAY_192_TRANSPARENCY_0_2,
        title: {
          text: 'CAD prevalence (%)'
        },
        tickPositioner() {
          return yTicks;
        },
        opposite: false
      },
      legend: {
        align: 'right',
        verticalAlign: 'top',
        layout: 'horizontal',
        y: this.props.isMobile ? -10 : 0,
        itemStyle: {
          color: COLORS.REPORT_TEXT_GRAY,
          fontWeight: 'normal'
        }
      },
      series: this.prepareDatasets(),
      annotations: this.prepareAnnotations(),
      tooltip: {
        formatter() {
          return `age=<b>${this.x}</b>, CAD prevalence=<b>${this.y.toFixed(
            1
          )}%</b>`;
        }
      }
    };
    return <HighchartsReact highcharts={Highcharts} options={options} />;
  }
}
