// @flow
import React from 'react';
import { Flex, Box, Text } from '@chakra-ui/react';
import COLORS from '../common/colors';
import ProstateReportStrings from './prostate-report-common';
import Disclaimers from '../common/reports/Disclaimers';

type Props = {
  isMobile: boolean
};

export default function ProstateExplanatorySection(props: Props) {
  const headerFontSize = props.isMobile ? 17 : 20;
  const textFontSize = props.isMobile ? 12 : 14;
  return (
    <Box fontSize={textFontSize}>
      <Flex align="center" justify="space-between">
        <Text fontSize={headerFontSize} color="gray.600" mt="30px" mb="20px">
          What is Prostate Cancer ?
        </Text>
      </Flex>
      <Text mb="20px" color={COLORS.REPORT_TEXT}>
        {ProstateReportStrings.WHAT_IS_PROSTATE_TEXT1}
        <br />
        <br />
        {ProstateReportStrings.WHAT_IS_PROSTATE_TEXT2}
        <sup>*</sup>.
        <br />
      </Text>
      <Text mb="5px">
        <i>
          <sup>*</sup> {ProstateReportStrings.WHAT_IS_PROSTATE_REF1}
        </i>
      </Text>
      <Text fontSize={headerFontSize} color="gray.600" my="20px">
        How did we calculate your score ?
      </Text>
      <Text mb="15px" color={COLORS.REPORT_TEXT}>
        {ProstateReportStrings.HOW_DID_WE_CALC_TEXT1}
        <sup>*</sup>. {ProstateReportStrings.HOW_DID_WE_CALC_TEXT2}
      </Text>
      <Text mb="20px" fontSize={textFontSize}>
        <sup>*</sup>
        <i>{ProstateReportStrings.HOW_DID_WE_CALC_REF1}</i>
      </Text>
s      <Disclaimers
        isMobile={props.isMobile}
        texts={[
          ProstateReportStrings.DISCLAIMER1,
          ProstateReportStrings.DISCLAIMER2,
          ProstateReportStrings.DISCLAIMER3,
          ProstateReportStrings.DISCLAIMER4,
          ProstateReportStrings.DISCLAIMER5,
          ProstateReportStrings.DISCLAIMER6
        ]}
      />
    </Box>
  );
}
