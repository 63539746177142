import React, {useCallback} from "react";
import {Button, Box, Text, Flex} from "@chakra-ui/react";
import {Link as ReactRouterLink} from "react-router-dom";
import COLORS from "./colors";

export default React.forwardRef((props, ref) => {
  const polyColor = props.isPolyEnabled ? COLORS.GREEN_STATUS : COLORS.GRAY_192;
  const monoColor = props.isMonoEnabled ? COLORS.GREEN_STATUS : COLORS.GRAY_192;
  const isSecondLineVisible = props.isPolyVisible || props.isPolyVisible;
  const {isDisabled} = props;

  const onClick = useCallback((event)=>{
      if (isDisabled) {
        event.stopPropagation();
        event.preventDefault();
      }
    }, [isDisabled]);

  return (
    <Button ref={ref} as={ReactRouterLink} {...props} >
      <Box onClick={onClick}>
        <Text mt={isSecondLineVisible ? "8px": 0} onClick={onClick}>{props.children}</Text>
        {isSecondLineVisible && <Flex fontWeight={"semibold"} mt={"-4px"}>
          {props.isPolyVisible && <Text fontSize={12} color={polyColor} onClick={onClick}>poly</Text>}
          {props.isMonoVisible && <Text fontSize={12} color={monoColor} onClick={onClick} ml={"7px"}>mono</Text>}
        </Flex>}
      </Box>
    </Button>)
  }
);