// @flow
import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import COLORS from './colors';

type Props = {
  acc: number,
  accOptimal: number,
  height: number
};

type State = {};

export default class AccVsOptimalChartImpl extends React.Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);
    (this: any).getAccColor = this.getAccColor.bind(this);
  }

	getAccColor(score: ?number) {
		if (isNaN(score)) return COLORS.GRAY_128;
		if (!score) return COLORS.GRAY_128;
		if (score < 5) return COLORS.GRAY_128;
		if (score <= 7.5) return COLORS.YELLOW_STATUS;
		if (score <= 20) return COLORS.ORANGE_STATUS;
		return COLORS.RED_STATUS;
	}

  render() {
    const yTicks: Array<number> = [];
    const minY = 0;
    const maxY = this.props.acc && this.props.accOptimal ? Math.round(this.props.acc) + Math.round(this.props.accOptimal) + 1 : 10;
    const yInterval = maxY > 20 ? 5 : 1;
    for (
      let i = minY;
      i <= maxY;
      i += yInterval
    ) {
      yTicks.push(i);
    }
    const options = {
      credits: {
        enabled: false
      },
      chart: {
        backgroundColor: 'transparent',
        height: this.props.height,
        width: this.props.height * 1.4,
        marginLeft: 50,
        type: 'column'
      },
      dataLabels: {
        enabled: true,
        align: 'center',
        formatter() {
          return `${this.y.toFixed(1)}%`;
        },
        inside: true,
        style: {
          fontWeight: 1,
          textOutline: 'transparent',
          strokeWidth: 1,
          color: 'white'
        },
      },
      categories: ["RISK"],
      title: {
        text: "ASCVD Risk Profile",
        style: {
          color: COLORS.HIGHCHARTS_TITLE,
          fontSize: '12px',
          fontWeight: 'bold'
        }
      },
      xAxis: {
        categories: ['RISK'],
        margin: 0,
      },
      yAxis: {
        min: minY,
        max: maxY,
        title: {
          text: "risk level (%)"
        },
        tickPositioner() {
          return yTicks;
        },
        opposite: false,
        labels: {
          enabled: false
        }
      },
      legend: false,
      series: [
        {
         name: 'YOUR RISK',
         data: [Math.round(this.props.acc*10)/10],
         color: this.getAccColor(this.props.acc)
        },
        {
         name: 'OPTIMAL RISK',
         data: [Math.round(this.props.accOptimal*10)/10],
         color: COLORS.GRAY_192
        }
      ],
      plotOptions: {
          series: {
              stacking: 'normal',
              dataLabels: {
                enabled: true,
                format: '{series.name}: {point.y:.1f}%',
                style: {
                  fontSize: 15,
                  fontWeight: "bold",
                  textOutline: 'transparent',
                  strokeWidth: 1,
                  color: 'white'
                },
              }
          }
      },
      tooltip: {
        formatter() {
          return `</b>${this.x}</b>, ${this.series.name}=<b>${this.y.toFixed(
              1
          )}</b>`;
        }
      },
    };
    return <HighchartsReact highcharts={Highcharts} options={options} />;
  }
}
