// @flow
import React, {useEffect, useState} from 'react';

import withNavigation from '../main/WithRoutes';
import queryString from "query-string";
import {authorizeAthenaHealth} from "./athena";
import {SingleSignOn} from "./SingleSignOn";

type Props = {
  location: any
};

export function AthenaPreLoginImpl(props: Props) {
  console.log("athenahealth prelogin");
  console.log(props.location.pathname);

  const queryParams = queryString.parse(props.location.search);
  const iss = queryParams.iss;
  const launch = queryParams.launch;

  const [authorized, setAuthorized] = useState(false);

  useEffect(() => {
    async function authorize() {
      const res = await authorizeAthenaHealth(iss, launch);
      setAuthorized(true)
      window.location.href = res.url;
    }
    if (!authorized) {
      authorize()
    }
  }, [authorized, iss, launch])

  return (
      <SingleSignOn/>
  );
}

export default withNavigation(AthenaPreLoginImpl);
