// @flow
import React from 'react';
import { Box, Link, Text, Divider, Image } from '@chakra-ui/react';
import LabeledText from '../common/LabeledText';
import COLORS from '../common/colors';
import ceImage from '../images/ce.png';

type Props = {
  version: string
};

export default function AboutImpl(props: Props) {
  return (
    <Box color={COLORS.REPORT_TEXT} mx="8%" mt="50px" maxW="800px">
      <Text fontSize={20} fontWeight="bold">
        CardioRisk+
      </Text>
      <LabeledText
        title="version"
        value={props.version}
        labelWidth="100px"
        textWidth="120px"
        align="left"
      />
      <Divider mb="20px" />
      <Text mt="40px" fontWeight="bold">
        Open-DNA LTD
      </Text>
      <Text>1 Nativ Haor</Text>
      <Text>Haifa 3508510</Text>
      <Text>Israel</Text>
      <Text mt="20px" fontWeight="semibold">
        contact:
      </Text>
      <Text>eran@open-dna.com</Text>
      <Link href="https://open-dna.com" isExternal h="30px">
        <Text color="teal.500">https://open-dna.com</Text>
      </Link>
      <Text mt="20px" fontWeight="semibold">
        European representative
      </Text>
      <Text>MedNet EC-REP GmbH Borkstrasse 10 48163 Münster Germany</Text>
      <Text>www.mednet-eurep.com</Text>
      <Image src={ceImage} h="48px" mt="25px" />
      <Divider mt="20px" />
      <Text mt="30px" fontSize={13}>
        Open-DNA CardioRisk+ precision medicine software helps predict, prevent
        and treat cardiometabolic diseases by analyzing genomic data, clinical
        data and demographic data and using AI techniques.
      </Text>
    </Box>
  );
}
