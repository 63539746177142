// @flow

const cadReportStrings = {
  WHAT_IS_CAD_TEXT1:
    'Coronary artery disease (CAD), also called coronary heart ' +
    'disease (CHD), ischemic heart disease (IHD), or simply heart ' +
    'disease, involves the reduction of blood flow to the heart ' +
    'muscle due to build-up of plaque (atherosclerosis) in the ' +
    'arteries of the heart. It is the most common of the ' +
    'cardiovascular diseases. CAD can result in a heart attack, also ' +
    'called a myocardial infarction, which happens when a part of the ' +
    'heart muscle doesn’t get enough blood. The more time that passes ' +
    'without treatment to restore blood flow, the greater the damage ' +
    'to the heart muscle',
  WHAT_IS_CAD_TEXT2:
    'In 2015, CAD affected 110 million people and resulted in 8.9 ' +
    'million deaths. It makes up 15.6% of all deaths, making it the ' +
    'most common cause of death globally',
  WHAT_IS_CAD_REF1:
    'Vos, Theo; et al. (October 2016). Global, ' +
    'regional, and national incidence, prevalence, and years lived ' +
    'with disability for 310 diseases and injuries, 1990-2015: a ' +
    'systematic analysis for the Global Burden of Disease Study 2015',
  WHAT_IS_CAD_REF2:
    'Wang, Haidong; et al. (October 2016). Global, ' +
    'regional, and national life expectancy, all-cause mortality, ' +
    'and cause-specific mortality for 249 causes of death, ' +
    '1980-2015: a systematic analysis for the Global Burden of ' +
    'Disease Study 2015',
  HOW_DID_WE_CALC_TEXT1:
    'OpenDNA calculates your score based on analysis of your ' +
    'genetic variants. Genetic variation is the difference in DNA ' +
    'sequences between individuals within a population. These are the ' +
    'differences in DNA that make every individual different. ' +
    'OpenDNA bases the calculation on a list of 2,420,360 variants that are known to be associated ' +
    'with coronary artery disease and calculates a Polygenic Risk Score (PRS) ' +
    'based on the contribution of each genetic variant to the overall ' +
    'genetic risk for having coronary artery disease. ',
  HOW_DID_WE_CALC_TEXT2:
    'Once your PRS is calculated it is compared ' +
    'to a group of about 339,000 people to ' +
    'calculate your relative risk of developing coronary artery ' +
    'disease compared to that population.',
  HOW_DID_WE_CALC_REF:
    'Elliott J, Bodinier B, Bond TA, et al. Predictive Accuracy of ' +
    'a Polygenic Risk Score–Enhanced Prediction Model vs a Clinical ' +
    'Risk Score for Coronary Artery Disease. JAMA. ' +
    '2020;323(7):636–645. doi:10.1001/jama.2019.22241',
  DISCLAIMER1:
    'THIS REPORT MAY NOT INCLUDE EVERY GENETIC MARKER RELATED TO CORONARY ARTERY DISEASE.',
  DISCLAIMER2:
    'THIS TEST IS NOT DIAGNOSTIC, IT IS A PREDICTION BASED ON PREDISPOSITION. ' +
    'AN INCREASED RISK SCORE DOES NOT MEAN THAT A PERSON WILL DEFINITELY DEVELOP A DISEASE ' +
    'AND A DECREASED RISK SCORE DOES NOT MEAN THAT A PERSON WILL DEFINITELY NOT DEVELOP A DISEASE.',
  DISCLAIMER3:
    'THE TEST CANNOT REPLACE MEDICAL TESTING OR MEDICAL MANAGEMENT THROUGH A HEALTHCARE PROVIDER.',
  DISCLAIMER4:
    'RISKS SHOULD BE USED FOR PRIMARY PREVENTION ONLY (PATIENTS WITHOUT ASCVD).',
  DISCLAIMER5:
    'THE RISKS MAY ALSO BE INFLUENCED BY OTHER LIFESTYLE FACTORS, OTHER CLINICAL FACTORS AND ' +
    'GENETIC VARIANTS THAT ARE NOT INCLUDED IN THE ANALYSIS OR WERE NOT REPORTED PROPERLY.',
  DISCLAIMER6:
    'YOUR RISK IS COMPARED TO A POPULATION WHERE THE MAJORITY IS OF EUROPEAN DESCENT. ' +
    'THE ACCURACY OF THE RESULTS MAY VARY IF YOU HAVE A DIFFERENT BACKGROUND.',
  DISCLAIMER7:
    'TEST RESULTS SHOULD BE INTERPRETED BY A HEALTHCARE PROVIDER. MEDICAL MANAGEMENT AND ' +
    'DECISION-MAKING FOR PREVENTION PRACTICES SHOULD BE DONE IN THE CONTEXT OF THE PATIENT’S ' +
    'FULL CLINICAL HISTORY AND NOT BASED SOLELY ON THE TEST RESULTS.',
  RISK_LEVEL_INFO_HEADER: '10 Year Risk Level',
  RISK_LEVEL_INFO_TEXT:
    'OpenDNA runs multiple machine learning and deep learning models to predict the 10 year risk probability for having coronary artery disease. ' +
    'Stacking ensemble learning with logistic regression as the end model is used to combine the probability outcomes from the models into a single risk prediction level. ' +
    'The models were trained over a dataset containing {dataset-size} individuals of age {dataset-age-range} with diverse risk factors at baseline time, ' +
    'and were tested over a dataset containing {dataset-size} individuals. ' +
    'The presented value is the estimated risk for having coronary artery disease in the next 10 years. ',
  RELATIVE_TO_POPULATION_INFO_HEADER: 'Relative to Population',
  LIFETIME_RISK_GAUGE_INFO_HEADER: 'Lifetime Risk Level',
  LIFETIME_RISK_GAUGE_INFO_TEXT:
    'OpenDNA calculates the risk percentile of a 50 year individual with the provided risk factors values, ' +
    'relative to a population of {dataset-size} individuals with diverse risk factors. ' +
    'The lifetime risk is calculated by finding the risk point on a precalculated risk vs. risk percentile curve. ',
  RELATIVE_TO_POPULATION_INFO_TEXT:
    'An overall risk score of the patient is compared to a reference ' +
    'scale built from overall risk scores of ~43K individuals ' +
    'with diverse risk factors. The scale is divided to ' +
    'percentiles, and the patient risk percentile is determined by ' +
    "comparing the individual's risk score against the threshold risk values of " +
    'each percentile. ',
  INFO_FOOTER:
    'See also more details about the overall risk score calculation in the footer of the overall risk frame',
  RELATIVE_TO_CURRENT_INFO_HEADER: 'Relative to Current',
  RELATIVE_TO_CURRENT_INFO_TEXT:
    'Current risk factor values of the patient are used to calculate the baseline. ' +
    'Upon risk factor changes, the risk is recalculated and compared to the baseline, to show the ' +
    'relative increase or decrease as a result of the changes',
  GENETIC_RISK_INFO_TEXT:
    'The polygenic risk score (PRS) of the patient is calculated by analyzing ' +
    'more than 500K variants known to be associated with CAD. ' +
    'The PRS is then compared to a reference scale, built from scores of ~339K individuals. ' +
    'The scale is divided into percentiles that reflect the genetic risk level. ' +
    "The patient risk percentile is determined by comparing the individual's PRS value against the threshold risk values of each percentile.",
  CAD_GENETIC_RISK_IN_POPULATION:
    'The graph shows the risk for having coronary artery disease vs. the genetic risk level. Genetic risk (PRS) was calculated for ' +
    'more than 330K individuals. The scores were divided into deciles and the risk of having coronary artery disease was calculated for each decile. ' +
    "Gray bars indicate the risk level per decile. The highlighted bar represents lifetime absolute patient risk level based on genetics.",
  CAD_OVERALL_RISK_IN_POPULATION:
    'The graph shows the risk for having coronary artery disease vs. the overall risk level. Overall risk level takes into account various risk factors including the genetic risk as well as other risk factors ' +
    'such as age, gender, cholesterol levels, blood pressure levels, blood pressure treatment status, smoking habits and having diabetes. The overall risk level was calculated for ' +
    'more than 223K individuals with various risk factors. The scores were divided into deciles and the risk of having coronary artery disease was calculated for each decile. ' +
    "Gray bars indicate the risk level per decile. The highlighted bar represents lifetime absolute patient risk based on aforementioned risk factors including genetics.",
  CAD_10_YEAR_RISK_HEADER: '10 Year Risk',
  CAD_10_YEAR_RISK_INFO_TEXT: 'Calculations are based on machine learning and deep learning (AI) models derived from analyzing data of {dataset-size} individuals of age {dataset-age-range}. ' +
      'Trained model data include genetic risk (PRS), age, sex, cholesterol levels, blood pressure levels, treatment for high blood pressure, diabetes, smoking status, ' +
      'cholesterol lowering treatment status, and additional clinical, life style and mental health related parameters. The estimated risk change for coronary artery disease is a statistical measurement ' +
      'and reflects the risk change in the test population. Coronary artery disease risk may depend on other factors and therefore the ' +
      'values presented serve as estimations only.',
  MODEL_10_YEARS_YOUNGER_PARTICIPANT: "This risk assessment model is based on data of participants of age range {dataset-age-range} and therefore can assess the risk using an age within this range. The assessed risk is based on the minimum age (3). As risk is associated with ageing, a younger participant will have lower risk than the assessed.",
  MODEL_10_YEARS_OLDER_PARTICIPANT: "This risk assessment model is based on data of participants of age range {39-71} and therefore can assess the risk using an age within this range. The assessed risk is based on the maximum age (71). As risk is associated with ageing, an older participant will have higher risk than the assessed.",
  MODEL_10_YEARS_AGE_POP_INFO_TEXT: {
    "header": "Age range limitation",
    "text": "This risk assessment model is based on data of participants of age range {dataset-age-range} and therefore can only assess the risk using an age within this range. As risk is associated with ageing, a participant younger than 39 will have lower risk than the assessed and an participant older than 71 will have higher risk than the assessed.",
    "footer": "NOTE: age range limitation"
  },
  MODEL_OVERALL_RISK: 'Calculations are based on an a machine learning (AI) model derived from analyzing data of ~43K people of age 38-73 ' +
      'and includes genetic risk (PRS), age, sex, cholesterol levels, blood pressure levels, treatment for high blood pressure, diabetes, smoking status and ' +
      'additional life style and clinical parameters. The estimated risk change for coronary artery disease is a statistical measurement ' +
      'and reflects the risk change in the test population. Coronary artery disease risk may depend on other factors and therefore the ' +
      'values presented serve as estimations only.',
  MODEL_OVERALL_RISK_YOUNGER_PARTICIPANT: "This risk assessment model is based on data of participants of age range 38-73 and therefore can assess the risk using an age within this range. The assessed risk is based on the minimum age (38). As risk is associated with ageing, a younger participant will have lower risk than the assessed.",
  MODEL_OVERALL_RISK_OLDER_PARTICIPANT: "This risk assessment model is based on data of participants of age range 38-73 and therefore can assess the risk using an age within this range. The assessed risk is based on the maximum age (73). As risk is associated with ageing, an older participant will have higher risk than the assessed.",
  MODEL_OVERALL_RISK_AGE_POP_INFO_TEXT: {
    "header": "Age range limitation",
    "text": "This risk assessment model is based on data of participants of age range 38-73 and therefore can only assess the risk using an age within this range. As risk is associated with ageing, a participant younger than 38 will have lower risk than the assessed and an participant older than 71 will have higher risk than the assessed.",
    "footer": "NOTE: age range limitation"
  },
  LIFETIME_RISK_HEADER: 'Lifetime Risk',
  LIFETIME_RISK_INFO_TEXT: 'Estimation of the lifetime risk for ASCVD. Calculated for a 50-year old without ASCVD who has the risk factor ' +
    'values entered at the initial visit. The primary use of these lifetime risk estimates is to ' +
    'facilitate an important discussion regarding risk reduction through lifestyle change. ' +
    'The risk is calculated by finding the risk point of the individual on a precalculated risk vs. risk percentile curve.',
  MODEL_INCLUDE_NON_TRADITIONAL_RISK_FACTORS: {
    "header": "Non traditional risk factors ",
    "text": "Traditional risk factors include gender, age, cholesterol levels and statins treatment status, blood pressure levels and " +
            "anti-hypertension treatment status, diabetes and smoking. All other factors are considered non-traditional. " +
            "Non-traditional factors help OpenDNA's proprietary model provide a more accurate risk assessments. " +
            "You can decide whether to include or exclude the non-traditional risk factors in the risk calculations.",
    "footer": undefined
  },
};

export default cadReportStrings;
