// @flow
import React from 'react';
import RiskChartsSection from '../../common/reports/RiskChartsSection';
import hypercholesterolReportCommon from './hypercholesterol-report-common';

type Props = {
  percentile: number,
  quintile: number,
  oddsRatios: Array<number>,
  deciles: Array<number>,
  printing: boolean
};

export default function HdlChartsSection(props: Props) {
  return (
    <RiskChartsSection
      percentile={99 - props.percentile}
      quintile={props.quintile}
      deciles={[...props.deciles].sort((a, b) => a - b)}
      oddsRatios={[...props.oddsRatios].sort((a, b) => a - b)}
      minY={0}
      maxY={40}
      yTickInterval={5}
      shortTrait="HDL"
      longTrait="low levels of HDL"
      absRiskTrendInfoText={hypercholesterolReportCommon.HDL_RISK_IN_POPULATION}
      riskVsAvgInfoText={hypercholesterolReportCommon.HDL_RISK_VS_AVERAGE}
      printing={props.printing}
    />
  );
}
