// @flow
import React from 'react';
import RiskChartsSection from '../common/reports/RiskChartsSection';
import hypercholesterolReportCommon from './hypercholesterol-report-common';

type Props = {
  percentile: number,
  quintile: number,
  oddsRatios: Array<number>,
  deciles: Array<number>,
  printing: boolean
};

export default function LdlChartsSection(props: Props) {
  return (
      <RiskChartsSection
        percentile={props.percentile}
        quintile={props.quintile}
        deciles={[...props.deciles].sort((a, b) => a - b)}
        oddsRatios={[...props.oddsRatios].sort((a, b) => a - b)}
        minY={5}
        maxY={40}
        yTickInterval={5}
        shortTrait="LDL"
        longTrait="high levels of LDL"
        absRiskTrendInfoText={hypercholesterolReportCommon.LDL_RISK_IN_POPULATION}
        riskVsAvgInfoText={hypercholesterolReportCommon.LDL_RISK_VS_AVERAGE}
        printing={props.printing}
      />
  );
}
