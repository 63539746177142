// @flow
import React from 'react';
import {Box} from "@chakra-ui/react";
import {BarcodeScanner, EnumBarcodeFormat} from 'dynamsoft-javascript-barcode';

import "../../dbr";
import "./dynamsoft.css";

type Props = {
	onScanned: any,
	onScanning: any,
	isMobile: boolean
}

type State = {}

export default class DynamsoftBarcodeReader extends React.Component<Props, State> {
  constructor(props: Props) {
		super(props);
    (this: any).onDynamsoftBarcodeRead = this.onDynamsoftBarcodeRead.bind(this);
		this.pScanner = null;
		this.elRef = React.createRef();
		this.licenseKey = this.getLicenseKey();
	}
	getLicenseKey() {
		return "DLS2eyJoYW5kc2hha2VDb2RlIjoiMTAxNDUyMzcyLVRYbFhaV0pRY205cVgyUmljZyIsIm1haW5TZXJ2ZXJVUkwiOiJodHRwczovL21sdHMuZHluYW1zb2Z0LmNvbS8iLCJvcmdhbml6YXRpb25JRCI6IjEwMTQ1MjM3MiIsInN0YW5kYnlTZXJ2ZXJVUkwiOiJodHRwczovL3NsdHMuZHluYW1zb2Z0LmNvbS8iLCJjaGVja0NvZGUiOi01OTY1NTY4MTl9"
		// "DLS2eyJoYW5kc2hha2VDb2RlIjoiMTAxNDUyMzcyLVRYbFhaV0pRY205cVgyUmljZyIsIm9yZ2FuaXphdGlvbklEIjoiMTAxNDUyMzcyIiwiY2hlY2tDb2RlIjotMTY5NTQ3ODI4MX0="
	}
	async componentDidMount() {
		try {
			const scanner = await (this.pScanner = BarcodeScanner.createInstance());
			await scanner.setUIElement(this.elRef.current);
			scanner.setVideoFit("cover");

			// scan settings
			let scanSettings = await scanner.getScanSettings();
			scanSettings.whenToPlaySoundforSuccessfulRead = "frame";
			await scanner.updateScanSettings(scanSettings);

			// runtime settings
			let settings = await scanner.getRuntimeSettings();
			settings.furtherModes.deformationResistingModes = [2, 0, 0, 0, 0, 0, 0, 0];
			settings.deblurModes = [1, 2, 4, 8, 0, 0, 0, 0, 0, 0];
			settings.localizationModes = [2, 16, 4, 8, 32, 64, 0, 0];
			// settings.barcodeFormatIds = Dynamsoft.DBR.EnumBarcodeFormat.BF_ONED;
			settings.barcodeFormatIds = EnumBarcodeFormat.BF_QR_CODE | EnumBarcodeFormat.BF_ONED;
			settings.expectedBarcodesCount = 1;
			await scanner.updateRuntimeSettings(settings);

			scanner.onFrameRead = results => {
				for (let result of results) {
						const format = result.barcodeFormat ? result.barcodeFormatString : result.barcodeFormatString_2;
						this.onDynamsoftBarcodeRead({ format, text: result.barcodeText, type: "result" });
						if (result.barcodeText.indexOf("Attention(exceptionCode") !== -1) {
								this.onDynamsoftBarcodeRead({ msg: result.exception.message, type: "error" });
						}
				}
			};
			await scanner.open();
		} catch (ex) {
			let errMsg;
			if (ex.message.includes("network connection error")) {
					errMsg = "Failed to connect to Dynamsoft License Server: network connection error. Check your Internet connection or contact Dynamsoft Support (support@dynamsoft.com) to acquire an offline license.";
			} else {
					errMsg = ex.message||ex;
			}
			console.error(errMsg);
			alert(errMsg);
		}
	}
	shouldComponentUpdate() {
		// Never update UI after mount, dbrjs sdk use native way to bind event, update will remove it.
		return false;
	}
	async componentWillUnmount() {
			if (this.pScanner) {
					(await this.pScanner).destroyContext();
					console.log('BarcodeScanner Component Unmount');
			}
	}
	licenseKey: string;
	dynamsoft: boolean;
  pScanner: any;
	elRef: any;

	onDynamsoftBarcodeRead(result: Object) {
		if (result.type === "result") {
      console.log("barcode read: ", result.text);
			if (this.props.onScanned) {
				this.props.onScanned(result.text);
			}
		}
	}
    render() {
        return (
            <Box ref={this.elRef} className="component-barcode-scanner">
                <svg className="dce-bg-loading" viewBox="0 0 1792 1792"><path d="M1760 896q0 176-68.5 336t-184 275.5-275.5 184-336 68.5-336-68.5-275.5-184-184-275.5-68.5-336q0-213 97-398.5t265-305.5 374-151v228q-221 45-366.5 221t-145.5 406q0 130 51 248.5t136.5 204 204 136.5 248.5 51 248.5-51 204-136.5 136.5-204 51-248.5q0-230-145.5-406t-366.5-221v-228q206 31 374 151t265 305.5 97 398.5z"></path></svg>
                <div className="dce-video-container"></div>
                <div className="dce-scanarea">
                    <div className="dce-scanlight"></div>
                </div>
            </Box>
        );
    }
}
