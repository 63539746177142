// @flow
export const gxgUserAttributesFlags = {
  gender: 1,
  age: 2,
  bmi: 4,
  hypercholesterol: 8,
  hypertensive: 16,
  diabetes: 32,
  fhDiabetes: 64,
  smoker: 128,
  exercise: 256,
  weight: 512,
  height: 1024
};

/* eslint-disable no-bitwise */
export const gxgAllUserAttributes: number =
  gxgUserAttributesFlags.gender |
  gxgUserAttributesFlags.age |
  gxgUserAttributesFlags.bmi |
  gxgUserAttributesFlags.hypertensive |
  gxgUserAttributesFlags.hypercholesterol |
  gxgUserAttributesFlags.exercise |
  gxgUserAttributesFlags.diabetes |
  gxgUserAttributesFlags.fhDiabetes |
  gxgUserAttributesFlags.smoker;

/* eslint-disable no-bitwise */
export const gxgT2dCalculatorUserAttributes: number =
  gxgUserAttributesFlags.gender |
  gxgUserAttributesFlags.age |
  gxgUserAttributesFlags.bmi |
  gxgUserAttributesFlags.hypertensive |
  gxgUserAttributesFlags.fhDiabetes |
  gxgUserAttributesFlags.smoker;

/* eslint-disable no-bitwise */
export const gxgCadCalculatorUserAttributes: number =
  gxgUserAttributesFlags.gender |
  gxgUserAttributesFlags.age |
  gxgUserAttributesFlags.bmi |
  gxgUserAttributesFlags.hypertensive |
  gxgUserAttributesFlags.hypercholesterol |
  gxgUserAttributesFlags.exercise |
  gxgUserAttributesFlags.diabetes |
  gxgUserAttributesFlags.smoker;

/* eslint-disable no-bitwise */
export const gxgBpCalculatorUserAttribute: number =
  gxgUserAttributesFlags.gender |
  gxgUserAttributesFlags.age |
  gxgUserAttributesFlags.bmi;

/* eslint-disable no-bitwise */
export const gxgHypercholesterolCalculatorUserAttribute: number =
  gxgUserAttributesFlags.gender |
  gxgUserAttributesFlags.age |
  gxgUserAttributesFlags.bmi |
  gxgUserAttributesFlags.smoker;


/* eslint-disable no-bitwise */
export const gxgMinCalculatorUserAttributes: number =
  gxgUserAttributesFlags.gender | gxgUserAttributesFlags.age;
