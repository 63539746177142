// @flow
import {kgToPounds} from "./unit_translation";

export default function calcBmi(weightKg: ?number, heightM: ?number) {
  if (!weightKg || !heightM) return null;
  return weightKg / (heightM * heightM);
}

export function calcBmiNumeric(weightKg: number, heightM: number) {
  return weightKg / (heightM * heightM);
}

export function getWeightFromBmiAndHeight(bmi: ?number, heightM: ?number, outKg: boolean) {
  if (!bmi || !heightM) return null;
  const weightKg = bmi * (heightM * heightM);
  return outKg ? weightKg : kgToPounds(weightKg);
}
