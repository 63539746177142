// @flow
import React, { useCallback, useEffect, useState } from 'react';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useIdleTimer } from 'react-idle-timer';
import {getUserDefaultUrl, signOutUser} from '../auth/auth';
import IdlePrompt from '../auth/IdlePrompt';
import withNavigation from '../main/WithRoutes';
import { ENV } from '../config/env_config';
import {getPatientIdByUsername} from "../api/capilots";
import routes from "../main/routes";

export const CurrentUserContext: Object = React.createContext<Object>({
  currentUser: null,
  updateCurrentUser: (user: typeof CognitoUser) => {
    console.log(user);
  },
  currentPatientId: null
});

type Props = {
  children: any,
  navigate: any,
  location: any,
};

export function CurrentUserProviderImpl(props: Props) {
  const { user, route } = useAuthenticator((context) => [context.user, context.route]);

  const getInactiveIntervalSec = () => {
    const isLocalHost = window.location.hostname === "localhost";
    const isProdEnv = (ENV === "prod");
    let inactiveTimeoutIntervalSec = 3600; // 1 hour
    if (isProdEnv) inactiveTimeoutIntervalSec = 900; // 15 min
    else if (isLocalHost) inactiveTimeoutIntervalSec = 14400; // 4 hours
    console.log(inactiveTimeoutIntervalSec);
    return inactiveTimeoutIntervalSec;
  }

  let inactiveTimeoutIntervalSec = getInactiveIntervalSec();
  const promptIntervalSec = 90;

  // const [currentUser, setCurrentUser] = React.useState(null);
  const [currentPatientId, setCurrentPatientId] = React.useState(null);
  const [showPrompt, setShowPrompt] = useState(false);
  const [remainingPrompt, setRemainingPrompt] = useState(0);
  const [needResetIdleTimer, setNeedResetIdleTimer] = useState(false);

  const { navigate, location } = props;

  const fetchUserPatient = useCallback(async (user, route) => {
    if (user && route === 'authenticated') {
      const patientId = await getPatientIdByUsername(user);
      setCurrentPatientId(patientId);
      console.log(`user patient is ${patientId ?? 'null'}`)
    } else {
      console.log("user patient is null")
      setCurrentPatientId(null);
    }

  }, [setCurrentPatientId])

  useEffect(() => {
    console.log("context user changed");
    console.log(user);

    fetchUserPatient(user, route).then().catch(e => {
        console.log("error fetching patient ID, refreshing to apply clock offset");
        console.log(user);
        console.error(e);
        // when fetching fails it is likely to be caused by a clock offset
        // this block force getting the clock offset and then
        // perform single page refresh in order to apply the changes
        const handledClockOffset = sessionStorage.getItem("handledClockOffset");
        if (handledClockOffset === null) {
          localStorage.setItem("systemClockOffset", "0");
          sessionStorage.setItem("handledClockOffset", "1");
          window.location.reload();
        }
      });
   }, [user, fetchUserPatient]);  // eslint-disable-line

  useEffect(() => {
    console.log(`user patient is ${currentPatientId ?? 'null'}`)
    if (currentPatientId) {
      if (location.pathname.includes(routes.LOGIN)) {
        const url = getUserDefaultUrl(user, currentPatientId);
        navigate(url);
      }
    }
   }, [currentPatientId]);  // eslint-disable-line


  const onPrompt = () => {
    if (user) {
      setShowPrompt(true);
      setRemainingPrompt(promptIntervalSec * 1000);
    }
  };

  const onActive = () => {
    setShowPrompt(false);
    setRemainingPrompt(0);
  };

  const onIdle = useCallback(() => {
    console.log('on idle');
    setShowPrompt(false);
    setRemainingPrompt(0);
    if (user) {
      signOutUser(user, navigate).then();
    }
  }, [user, navigate]);

  const { getRemainingTime, isPrompted, activate, reset } = useIdleTimer({
    onPrompt,
    onIdle,
    onActive,
    timeout: inactiveTimeoutIntervalSec * 1000,
    promptTimeout: promptIntervalSec * 1000,
    stopOnIdle: true
  });

  const handleStillHere = useCallback(() => {
    setShowPrompt(false);
    activate();
  }, [activate]);

  if (needResetIdleTimer) {
    setNeedResetIdleTimer(false);
    reset();
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (isPrompted()) {
        setRemainingPrompt(Math.ceil(getRemainingTime() / 1000));
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [getRemainingTime, isPrompted]);


  return (
    <CurrentUserContext.Provider value={{ currentPatientId }}>
      <>
        {props.children}
        {showPrompt && (
          <IdlePrompt
            remainingTime={
              remainingPrompt > promptIntervalSec
                ? promptIntervalSec
                : remainingPrompt
            }
            onContinue={handleStillHere}
            onSignOut={onIdle}
          />
        )}
      </>
    </CurrentUserContext.Provider>
  );
}

export const CurrentUserProvider = withNavigation(CurrentUserProviderImpl);

export const useCurrentUserContext = () => React.useContext(CurrentUserContext);
