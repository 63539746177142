// @flow
import React, { useCallback, useState } from "react";
import {
  Text,
  Box,
  Flex,
  Spacer,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  FormControl, useDisclosure
} from "@chakra-ui/react";

import COLORS from "../common/colors";
import LinkButton from "../common/LinkButton";
import {AiOutlineToTop} from "react-icons/ai";

import routes from "../main/routes";
import getPatientAge from "../utils/age";
import cadImage from "../images/heart-attack.png";
import addTest from "../images/add-test.png";
import withNavigation from "../main/WithRoutes";
import DemoPilotPatientVisitUnion from "./DemoPilotPatientVisitUnion";
import cancerImage from "../images/cancer_bw.png";
import {BsShare} from "react-icons/bs";
import ActionButtonImpl from "../common/ActionButton";
import PilotNewOrder from "../capilots/PilotNewOrder";
import {isCancerMonogenicTestCompleted, isTestPresent, TestType} from "../utils/test_types";
import DemoBaselineRisk from "./DemoBaselineRisk";
import CancerButton from "../common/CancerButton";

type Props = {
  patient: Object,
  bg: string,
  currentOrders: number
};

function DemoPatientCardImpl(props: Props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const disclosure = useDisclosure();
  const isOpenBaseline = disclosure.isOpen;
  const onOpenBaseline = disclosure.onOpen;
  const onCloseBaseline = disclosure.onClose;

  const [isShowDetails: boolean, setShowDetails: func] = useState(false);
  const [selectedOrders, setSelectedOrders] = useState(props.currentOrders);

  const initialRef = React.useRef();
  const finalRef = React.useRef();

  const onClick = useCallback(() => {
    setShowDetails(!isShowDetails);
  }, [isShowDetails]);

  const age = getPatientAge(
    props.patient.birth_year,
    props.patient.birth_month
  );

  const patientId = props.patient.patient_id;
  const getReportPageUrl = useCallback(
    (report) =>
      `/${routes.DEMO}/${routes.PATIENT_RISKS}?id=${patientId}&tab=${report}`,
    [patientId]
  );

  const onOrderTest = useCallback(()=>{
      onOpen();
      }, [onOpen]
  );

  const onBaselineRisk = useCallback(()=>{
      onOpenBaseline();
      }, [onOpenBaseline]
  );

  const [currentStatus, setCurrentStatus] = useState(props.patient.status);
  const onNextStatus = useCallback(()=>{
    let nextStatus = currentStatus;
    if (currentStatus === 'NO_ORDER') {
      nextStatus = 'SENT_TO_LAB';
    } else if (currentStatus === 'SENT_TO_LAB') {
      nextStatus = 'IN_LAB';
    } else if (currentStatus === 'IN_LAB') {
      nextStatus = 'READY';
      if (selectedOrders === 0) {
        setSelectedOrders(1);
      }
    } else if (currentStatus === 'READY') {
      nextStatus = 'NO_ORDER';
      setSelectedOrders(0);
    }
    setCurrentStatus(nextStatus);
  }, [setCurrentStatus, currentStatus, setSelectedOrders, selectedOrders]);

  const isReportReady = currentStatus.toLowerCase() === "ready";
  const imageComp = useCallback(
    (image) => <Image h="16px" w="16px" src={image} />,
    []
  );

  const onCallbackCloseBaseline = useCallback(order => {
    onCloseBaseline();
    if (order) {
      onOpen();
    }
  }, [onCloseBaseline, onOpen]);

  const onApplyOrder = useCallback((selectedTests: number)=>{
    setSelectedOrders(selectedTests);
    onClose();
    if (currentStatus === "NO_ORDER") {
      onNextStatus();
    }
  }, [setSelectedOrders, onClose, currentStatus, onNextStatus]);

  const hasCancerOrder = isTestPresent(TestType.CANCER_RISK, selectedOrders);
  const hasCancerMonoOrder = isTestPresent(TestType.CANCER_MONOGENIC_RISK, selectedOrders);
  const hasCancerReport = true;
  const hasCancerMonoReport = isCancerMonogenicTestCompleted(props.patient.completed_tests);

  return (
    <Flex w="100%">
      <Box
        onClick={onClick}
        bg="transparent"
        borderColor="transparent"
        borderWidth={2}
        borderRadius="5px"
        fontSize={18}
        _hover={{
          background: props.bg,
          borderColor: "gray.400",
          borderWidth: 2,
          color: COLORS.REPORT_TEXT,
        }}
        w="100%"
      >
        <Box
          my="5px"
          w="100%"
          _hover={{
            cursor: "pointer",
          }}
        >
          <Flex align="center" minW="100%" fontSize={14}>
            <Text textAlign="left" minW="50px">
              {props.patient.patient_num}
            </Text>
            <Text textAlign="left" minW="150px" fontWeight="bold">
              {props.patient.kit_id}
            </Text>
            <Text textAlign="left" minW="100px">
              {props.patient.sex ? "Male" : "Female"}
            </Text>
            <Text minW="100px" textAlign="center">
              {age}
            </Text>
            <Text
              ml="20px"
              minW="110px"
              textAlign="left"
              color={isReportReady ? COLORS.GREEN_STATUS : COLORS.REPORT_TEXT}
              fontWeight={isReportReady ? "bold" : "normal"}
            >
              {currentStatus.split("_").join(" ")}
            </Text>
            <ActionButtonImpl
              ml="0px"
              minW="20px"
              textAlign="left"
              color={"transparent"}
              bg={"transparent"}
              fontWeight={isReportReady ? "bold" : "normal"}
              onClick={onNextStatus}
              name={">"}
            >
            </ActionButtonImpl>
            <Box ml="40px" w={"500px"}>
              {isReportReady && (
                <Flex align={"center"} w={"500px"}>
                  <LinkButton
                    fontWeight="normal"
                    fontSize={16}
                    to={getReportPageUrl("cad")}
                    bg="gray.100"
                    color={COLORS.REPORT_TEXT}
                    h="40px"
                    w="120px"
                    mr="20px"
                    leftIcon={imageComp(cadImage)}
                  >
                    CARDIO
                  </LinkButton>
                  {hasCancerOrder ? <CancerButton
                    fontWeight="normal"
                    fontSize={16}
                    to={getReportPageUrl("cancer")}
                    bg="gray.100"
                    color={COLORS.REPORT_TEXT}
                    h="40px"
                    w="120px"
                    mr="20px"
                    leftIcon={imageComp(cancerImage)}
                    isDisabled={false}
                    isPolyVisible={hasCancerMonoOrder}
                    isPolyEnabled={hasCancerReport}
                    isMonoVisible={hasCancerMonoOrder}
                    isMonoEnabled={hasCancerMonoReport}
                  >
                    CANCER
                  </CancerButton> : <Box minW={"140px"}/>}
                  {isReportReady && <Box as={BsShare} h="18px" w="18px" ml={"30px"} color={COLORS.GREEN_STATUS}/>}
                </Flex>
              )}
              {!isReportReady && <Flex align={"center"}>
                <ActionButtonImpl
                  fontWeight="normal"
                  fontSize={16}
                  bg="gray.100"
                  color={COLORS.REPORT_TEXT}
                  h="40px"
                  w="120px"
                  leftIcon={<Box color={"black"} as={AiOutlineToTop}/>}
                  onClick={onBaselineRisk}
                  name={"ACC"}
                />
                <Text ml={"22px"}>reports are not available yet </Text>
              </Flex>}
            </Box>
            <Spacer />
            <ActionButtonImpl
              name={"orders  ..." }
              fontWeight="normal"
              fontSize={16}
              bg="gray.100"
              color={COLORS.REPORT_TEXT}
              h="40px"
              w="175x"
              mx="20px"
              leftIcon={imageComp(addTest)}
              onClick={onOrderTest}
            >
            </ActionButtonImpl>
          </Flex>
        </Box>
        {isShowDetails && (
          <Box>
            <DemoPilotPatientVisitUnion patientId={props.patient.patient_id} />
          </Box>
        )}
      </Box>
      <Spacer />
      <Modal
        blockScrollOnMount={false}
        isOpen={isOpen}
        onClose={onClose}
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        size={"xl"}
      >
        <ModalOverlay />
        <ModalContent color={COLORS.REPORT_TEXT}>
          <ModalBody color={COLORS.REPORT_TEXT}>
            <FormControl>
              <PilotNewOrder
                currentTests={selectedOrders}
                callbackOnCancel={onClose}
                callbackOnApply={onApplyOrder}
                editingEnabled={true}
              />
            </FormControl>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal
        blockScrollOnMount={false}
        isOpen={isOpenBaseline}
        onClose={onCloseBaseline}
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        size={"full"}
      >
        <ModalOverlay />
        <ModalContent color={COLORS.REPORT_TEXT}>
          <ModalBody color={COLORS.REPORT_TEXT}>
            <FormControl>
              <DemoBaselineRisk
                patient={props.patient}
                callbackOnClose={onCallbackCloseBaseline}
              />
            </FormControl>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
}

export default withNavigation(DemoPatientCardImpl);
