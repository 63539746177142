// @flow
import React, { useCallback } from 'react';
import {Image} from '@chakra-ui/react';

import withNavigation from '../main/WithRoutes';
import COLORS from '../common/colors';
import LeftBarButton from "../common/left-bar/LeftBarButton";
import PropTypes from "prop-types";

type Props = {
  name: string,
  idToken: string,
  level: number,
  disabled: boolean,
  icon: any,
  selected: boolean,
  onClick: any,
  color?: string
};


export function DemoLeftBarItemImpl(props: Props) {
  // const buttonProps: Object = { ...props };
  const ml = props.level <= 1 ? 0 : `${props.level * 5}px`;
  const imageComp = useCallback(
    (image) => <Image h="16px" w="16px" src={image} mr={"10px"}/>,
    []
  );

  const {onClick, idToken} = props;
  const onButtonClick = useCallback(() => {
    onClick(idToken)
  }, [onClick, idToken])

  return (
    <LeftBarButton
      name={props.name}
      onClick={onButtonClick}
      minW={"200px"}
      justifyContent={"left"}
      ml={ml}
      isDisabled={props.disabled}
      leftIcon={props.icon ? imageComp(props.icon) : null}
      bg={props.selected ? COLORS.REPORT_SELECTED_ITEM : "transparent"}
      fontWeight={props.selected || props.level === 0 ? "bold" : "normal"}
      fontSize={props.level > 0 ? 13 : 14}
      color={props.color}
      h={"30px"}
    />
  );
}

DemoLeftBarItemImpl.propTypes = {
	color: PropTypes.string
}

DemoLeftBarItemImpl.defaultProps = {
	color: "gray.600"
}
export default withNavigation(DemoLeftBarItemImpl);

