// @flow
import React from 'react';
import { Box, Flex, Image, Spacer, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import firstAidImage from '../../images/first-aid-kit.png';

type Props = {
  askText: string,
  isMobile?: boolean
};

export default function MedicationsAskDoctorPanel(props: Props) {
  return (
    <>
      {!props.isMobile && (
        <Flex align="center" mt="40px" mb="10px">
          <Image src={firstAidImage} h="32px" minW="32px" alt="" mr="10px" />
          <Text fontSize={17} color="gray.500" fontWeight="semibold">
            Medications - {props.askText}
          </Text>
        </Flex>
      )}
      {props.isMobile && (
        <Flex align="top" mt="40px" mb="10px">
          <Box>
            <Text fontSize={16} color="gray.500" fontWeight="semibold">
              Medications -
            </Text>
            <Text
              mt="10px"
              fontSize={16}
              color="gray.500"
              fontWeight="semibold"
            >
              {props.askText}
            </Text>
          </Box>
          <Spacer />
          <Image src={firstAidImage} h="32px" minW="32px" alt="" />
        </Flex>
      )}
    </>
  );
}

MedicationsAskDoctorPanel.propTypes = {
  isMobile: PropTypes.bool
};

MedicationsAskDoctorPanel.defaultProps = {
  isMobile: false
};
