// @flow
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Flex,
  Box,
  Text,
  Image,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper
} from '@chakra-ui/react';
import {
  calcHeartAge,
  getHeartAgeQuantile,
  getMaxAgeForHeartAgeCalc,
  HEART_AGE_MIN_AGE
} from './heart_age/heart-age';
import { undefinedForNan } from '../utils/numbers';
import heartImageRed from '../images/heart-solid-red.png';
import heartImageDarkGreen from '../images/heart-dark-green.png';
import heartImageLightGreen from '../images/heart-light-green.png';
import heartImageYellow from '../images/heart-yellow.png';
import heartImageOrange from '../images/heart-orange.png';

type Props = {
  percentile: number,
  age: ?number,
  isMobile?: boolean,
  callbackAgeChange: any,
  isEditable: boolean,
  title?: ?string
};

export default function HeartAgePanel(props: Props) {
  const getHeartImage = () => {
    const decile = props.percentile / 10;
    if (decile === 0) return heartImageDarkGreen;
    if (decile < 5) return heartImageLightGreen;
    if (decile < 7) return heartImageYellow;
    if (decile < 9) return heartImageOrange;
    return heartImageRed;
  };

  const [age, setAge] = React.useState(props.age);
  const { callbackAgeChange } = props;
  const onAgeChange = useCallback((strAge: string) => {
    const newAge = parseInt(strAge, 10);
    setAge(newAge);
    if (callbackAgeChange) callbackAgeChange(newAge);
  }, [callbackAgeChange]);
  const q = getHeartAgeQuantile(props.percentile);
  const maxAge = getMaxAgeForHeartAgeCalc(q);
  const heartAge = age ? calcHeartAge(age, q) : undefined;
  const mxMainPane = props.isMobile ? '20px' : '30px';
  const widthMainPane = props.isMobile ? '100%' : '60%';
  const heartImage = getHeartImage();
  const outOfRangeText = props.isEditable ? 'Use the minimum/maximum value if your age is outside of the\n                range.\n' : '';

  return (
    <Flex>
      <Flex
        borderRadius="10px"
        borderColor="gray.200"
        borderWidth="1px"
        align="center"
      >
        <Box my="20px" mx={mxMainPane} w={widthMainPane}>
          {props.isEditable && <Flex align="center" my="10px">
            <Text>How old are you?</Text>
            <NumberInput
              ml="16px"
              maxW="80px"
              defaultValue={undefinedForNan(age)}
              min={HEART_AGE_MIN_AGE}
              max={maxAge}
              onChange={onAgeChange}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </Flex>}
          {/* {age && ( */}
          <Flex align="center" mb="10px">
            <Text mr="10px">Your heart age is:</Text>
            <Image src={heartImage} h="60px" w="60px" ml="10px" />
            <Text fontWeight="bold" ml="-40px" color="white">
              {heartAge ? heartAge.toFixed(0) : ''}
            </Text>
          </Flex>
          {/* )} */}
        </Box>
        {!props.isMobile && (
          <Box bg="gray.50" h="100%">
            <Box m="20px">
              <Text fontSize="11" mt="30px">
                {`We can calculate your heart age if your age is between ${HEART_AGE_MIN_AGE}-${maxAge}.`}
                <br />
                <br />
                {outOfRangeText}
              </Text>
            </Box>
          </Box>
        )}
      </Flex>
    </Flex>
  );
}

HeartAgePanel.propTypes = {
  isMobile: PropTypes.bool,
  title: PropTypes.string
};

HeartAgePanel.defaultProps = {
  isMobile: false,
  title: undefined
};
