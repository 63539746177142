// @flow
import React, {useCallback, useState} from 'react';
import {Box, Checkbox, Divider, Flex, ListItem, Spacer, Text, UnorderedList} from '@chakra-ui/react';
import { v4 as uuid4 } from 'uuid';
import {consentStrings} from './consent-texts'
import COLORS from "../common/colors";
import ActionButton from "../common/ActionButton";
import withMobile from "../common/mobile/withMobile";
import PropTypes from "prop-types";

type Props = {
  onConsent: any,
  isMobile: boolean,
  fontSize?: ?number,
  showContinue?: boolean,
  callbackOnConsentCheckChange?: any,
  decreaseVerticalSpacing?: boolean,
  consentAgreed?: boolean
};

function ConsentImpl(props: Props) {
  const {onConsent, callbackOnConsentCheckChange} = props;
  console.log(props.consentAgreed)
  const [consentAgreed, setConsentAgreed] = useState(props.consentAgreed);
  const onConsentCheckChange = useCallback((e)=> {
        setConsentAgreed(e.target.checked);
        if (callbackOnConsentCheckChange) {
          callbackOnConsentCheckChange(e.target.checked);
        }
      }, [setConsentAgreed, callbackOnConsentCheckChange]
  )
  const onContinue = useCallback(()=>onConsent(), [onConsent]);
  const {isMobile} = props;
  let fontSize= isMobile ? 13 : 16;
  let disclaimerFontSize = isMobile ? 12 : 16;
  if (props.fontSize !== null && props.fontSize !== undefined) {
    fontSize = props.fontSize;
    disclaimerFontSize = props.fontSize;
  }
  return (
    <Box mr={"10%"} color={COLORS.REPORT_TEXT_GRAY} fontSize={fontSize}>
      <Text>{consentStrings.CONSENT1}</Text>
      <Text mt={isMobile || props.decreaseVerticalSpacing ? "10px" : "30px"}>{'This includes:'}</Text>
      <UnorderedList ml={isMobile ? "20px" : "40px"} mt={props.decreaseVerticalSpacing ? '3px' : "10px"}>
        {consentStrings.INFORMATION_ITEMS.map(text => (
          <ListItem key={uuid4()}>{text}</ListItem>
        ))}
      </UnorderedList>
      <Text mt={props.decreaseVerticalSpacing || isMobile ? "10px" : "30px"}>{consentStrings.DEINDETIFIED_MEANING}</Text>
      <Text mt={props.decreaseVerticalSpacing ? '10px' : "20px"}>DISCLAIMERS</Text>
      <UnorderedList ml={isMobile ? "20px" : "40px"} mt={props.decreaseVerticalSpacing ? '3px' : "10px"}>
        {consentStrings.DISCLAIMERS.map(text => (
          <ListItem fontSize={disclaimerFontSize} key={uuid4()}>{text}</ListItem>
        ))}
      </UnorderedList>
      <Divider mt={props.decreaseVerticalSpacing || isMobile ? "10px" : "20px"}/>
      <Checkbox
        color={COLORS.REPORT_TEXT}
        ml={isMobile ? "10px" : "20px"}
        size={"sm"}
        onChange={onConsentCheckChange}
        mt={props.decreaseVerticalSpacing ? '10px' : (isMobile ? "25px" : "30px")}
        defaultChecked={props.consentAgreed}
      >
        I understand and agree to proceed
      </Checkbox>
      {props.showContinue && <Flex mt={isMobile ? "25px" : "30px"}>
        <ActionButton
          color={COLORS.REPORT_TEXT}
          name="Continue..."
          onClick={onContinue}
          isDisabled={!consentAgreed}
        />
        <Spacer />
      </Flex>}
    </Box>
  );
}


ConsentImpl.propTypes = {
  fontSize: PropTypes.number,
  showContinue: PropTypes.bool,
  callbackOnConsentCheckChange: PropTypes.any,
  decreaseVerticalSpacing: PropTypes.bool,
  consentAgreed: PropTypes.bool,
};
// $FlowFixMe[prop-missing]
ConsentImpl.defaultProps = {
  fontSize: null,
  showContinue: true,
  callbackOnConsentCheckChange: null,
  decreaseVerticalSpacing: false,
  consentAgreed: false
};


export default withMobile(ConsentImpl);