// @flow
import React, { useCallback } from 'react';
import {Text, Flex, Switch, Spacer, Stack} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import PopoverInfo from "./PopoverInfo";

type Props = {
  isChecked: boolean,
  leftLabel: string,
  leftLabelWidth: string,
  callbackOnValueChange: any,
  callbackGetColor: any,
  callbackRightLabel: any,
  enabled?: boolean,
  attrName?: ?string,
  popoverInfo?: Object
};

export default function LabeledSwitchImpl(props: Props) {
  const [value, setValue] = React.useState(props.isChecked);
  const {callbackOnValueChange, attrName} = props;
  const onSwitchChange = useCallback(() => {
    setValue(!value);
    callbackOnValueChange(!value, attrName);
  }, [value, callbackOnValueChange, attrName]);
  React.useEffect(() => {
    setValue(props.isChecked);
  }, [props.isChecked]);
  return (
    <Flex>
      {props.popoverInfo && (
        <PopoverInfo
          trigger={<Stack direction={"horizontal"} minW="25%">
            <Text minW={props.leftLabelWidth}>{props.leftLabel}</Text>
          </Stack>}
          header={props.popoverInfo.header}
          text={props.popoverInfo.text}
        />
      )}
      {!props.popoverInfo && <Text minW={props.leftLabelWidth}>{props.leftLabel}</Text>}
      <Spacer />
      <Switch
        colorScheme={props.callbackGetColor()}
        isChecked={value}
        onChange={onSwitchChange}
        isDisabled={!props.enabled}
      />
      <Text ml="20px">{props.callbackRightLabel(value)}</Text>
    </Flex>
  );
}

LabeledSwitchImpl.propTypes = {
  enabled: PropTypes.bool,
  attrName: PropTypes.string,
  popOver: PropTypes.object
};

LabeledSwitchImpl.defaultProps = {
  enabled: true,
  attrName: undefined,
  popOver: undefined
};
