// @flow
import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton } from '@chakra-ui/react';
import { BsShare } from 'react-icons/bs';
import ActionButtonImpl from "./ActionButton";
import COLORS from "./colors";

type Props = {
  onShare: any,
	title: string,
	isTextDisplay?: boolean
};

export default function ShareButton(props: Props) {
  const getShareButtonContent = () => (
    <Box as={BsShare} h="18px" w="18px" mb={0} />
  );
	// eslint-disable-next-line no-undef
	const onShare = useCallback(() => {
		props.onShare()
	}, [props]);

	return (
		<>
			{!props.isTextDisplay && <IconButton
				icon={getShareButtonContent()}
				bg="transparent"
				aria-label={props.title}
				title={props.title}
				onClick={onShare}
			/>}
			{props.isTextDisplay &&
				<ActionButtonImpl
					leftIcon={getShareButtonContent()}
					fontWeight="bold"
					fontSize={16}
					color={COLORS.REPORT_TEXT}
					h="40px"
					w="220px"
					onClick={onShare}
					name={"Share with patient..."}
					aria-label={props.title}
					borderColor={COLORS.REPORT_TEXT}
					borderWidth={1}
			/>}
		</>
	);
}

ShareButton.propTypes = {
	isTextDisplay: PropTypes.bool
}

ShareButton.defaultProps = {
	isTextDisplay: true
}