// @flow

const StrokeReportStrings = {
  WHAT_IS_STROKE_TEXT1:
    'A stroke occurs when the blood supply to part of your brain is interrupted or reduced, ' +
    'preventing brain tissue from getting oxygen and nutrients. Brain cells begin to die in minutes. ' +
    'A stroke is a medical emergency, and prompt treatment is crucial. Early action can reduce brain damage and other complications. ',
  WHAT_IS_STROKE_TEXT2:
    'Stroke is the second leading cause of death and disability-adjusted life years worldwide.',
  WHAT_IS_STROKE_REF1:
    'GBD 2015 DALYs and HALE Collaborators. Global regional and national disability-adjusted life-' +
    'years (DALYs) for 315 diseases injuries and healthy life expectancy (HALE) 1990–2015: a ' +
    'systematic analysis for the Global Burden of Disease Study 2015. Lancet. 2016; 388:1603–1658.',
  HOW_DID_WE_CALC_TEXT1:
    'OpenDNA calculates your score based on analysis of your ' +
    'genetic variants. Genetic variation is the difference in DNA ' +
    'sequences between individuals within a population. These are the ' +
    'differences in DNA that make every individual different. OpenDNA ' +
    'scans 35 significant genetic variants and calculates a ' +
    'Polygenic Risk Score (PRS) based on the contribution of each ' +
    'genetic variant to your genetic risk for having a stroke ' +
    'disease',
  HOW_DID_WE_CALC_TEXT2:
    'Once your PRS is calculated it is compared ' +
    'to a group of about 200,000 people of European descent to ' +
    'calculate your relative risk of having a stroke ' +
    'compared to that population.',
  HOW_DID_WE_CALC_REF1:
    'Malik, R., Chauhan, G., Traylor, M. et al. Multiancestry genome-wide association study of 520,000 subjects ' +
    'identifies 32 loci associated with stroke and stroke subtypes. Nat Genet 50, 524–537 (2018). https://doi.org/10.1038/s41588-018-0058-3',
  HOW_DID_WE_CALC_REF2:
    'Malik, Rainer & Rannikmäe, Kristiina & TRAYLOR, M. & Georgakis, Marios & Sargurupremraj, Muralidharan & MARKUS, ' +
    'H. & Hopewell, Jemma & Debette, Stéphanie & SUDLOW, C. & DICHGANS, M.. ' +
    '(2018). Genome-wide meta-analysis identifies 3 novel loci associated with stroke.',
  DISCLAIMER1:
    'THIS REPORT MAY NOT INCLUDE EVERY GENETIC MARKER RELATED TO STROKE.',
  DISCLAIMER2:
    'THIS TEST IS NOT DIAGNOSTIC, IT IS A PREDICTION BASED ON PREDISPOSITION. ' +
    'AN INCREASED RISK SCORE DOES NOT MEAN THAT A PERSON WILL DEFINITELY DEVELOP A DISEASE ' +
    'AND A DECREASED RISK SCORE DOES NOT MEAN THAT A PERSON WILL DEFINITELY NOT DEVELOP A DISEASE.',
  DISCLAIMER3:
    'THE TEST CANNOT REPLACE MEDICAL TESTING OR MEDICAL MANAGEMENT THROUGH A HEALTHCARE PROVIDER.',
  DISCLAIMER4:
    'THE RISK MAY ALSO BE INFLUENCED BY OTHER LIFESTYLE FACTORS, OTHER CLINICAL FACTORS AND ' +
    'GENETIC VARIANTS THAT ARE NOT INCLUDED IN THE ANALYSIS OR WERE NOT REPORTED PROPERLY.',
  DISCLAIMER5:
    'YOUR RISK IS COMPARED TO A POPULATION WHERE THE MAJORITY IS OF EUROPEAN DESCENT. ' +
    'THE ACCURACY OF THE RESULTS MAY VARY IF YOU HAVE A DIFFERENT BACKGROUND.',
  DISCLAIMER6:
    'TEST RESULTS SHOULD BE INTERPRETED BY A HEALTHCARE PROVIDER. MEDICAL MANAGEMENT AND ' +
    'DECISION-MAKING FOR PREVENTION PRACTICES SHOULD BE DONE IN THE CONTEXT OF THE PATIENT’S ' +
    'FULL CLINICAL HISTORY AND NOT BASED SOLELY ON THE TEST RESULTS.',
};

export default StrokeReportStrings;
