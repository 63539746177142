// @flow
import React from 'react';
import { Flex, Text, Image } from '@chakra-ui/react';
import COLORS from '../colors';
import treatmentImage from '../../images/treatment.png';

type Props = {
  recommendations: Object
};

export default function TreatmentRecommendationImpl(props: Props) {
  return (
    <Flex align="top">
      <Flex align="center" fontSize={20}>
        <Image src={treatmentImage} h="24px" w="24px" mr="20px" />
        <Text color={COLORS.REPORT_TEXT_GRAY} mr="20px">
          Treatment suggestions:
        </Text>
        <Text color={COLORS.REPORT_TEXT} fontWeight="bold">
          {props.recommendations.treat}
        </Text>
      </Flex>
    </Flex>
  );
}
