import COLORS, {changeTransparency} from "../common/colors";

export const amplifyTheme = {
  name: 'amplify-theme',
  tokens: {
    font: {
      sizes: {
        xl: {value: "1.25rem"},
        xxl: {value: "1.25rem"},
        xxxl: {value: "1.25rem"},
        xxxxl: {value: "1.25rem"}
      }
    },
    colors: {
      font: {
        primary: { value: COLORS.REPORT_TEXT },
        secondary: { value: changeTransparency(COLORS.DARK_BLUE, 1) },
      },
      border: {
        primary: { value: changeTransparency(COLORS.REPORT_TEXT_GRAY, 0.5) },
        secondary: { value: changeTransparency(COLORS.REPORT_TEXT, 0.3) },
        tertiary: { value: changeTransparency(COLORS.REPORT_TEXT, 0.2) },
        focus: { value: changeTransparency(COLORS.REPORT_TEXT, 0.7) },
        pressed: { value: changeTransparency(COLORS.REPORT_TEXT, 0.8) }
      },
      // background: {
        // secondary: { value: COLORS.REPORT_TEXT }
      //   tertiary: { value: COLORS.REPORT_TEXT },
      //   quaternary: { value: COLORS.REPORT_TEXT }
      // }
      brand: {
          primary: {
            '10': { value: changeTransparency(COLORS.REPORT_TEXT, 0.2) },
            '20': { value: changeTransparency(COLORS.REPORT_TEXT, 0.4) },
            '40': { value: changeTransparency(COLORS.REPORT_TEXT, 0.6) },
            '60': { value: changeTransparency(COLORS.REPORT_TEXT, 0.8) },
            '80': { value: changeTransparency(COLORS.REPORT_TEXT, 1) },
            '90': { value: changeTransparency(COLORS.REPORT_TEXT, 1) },
            '100': { value: COLORS.REPORT_TEXT }
          }
      },
      pallete: {
          neutral: {
            '10': { value: changeTransparency(COLORS.REPORT_TEXT, 0.1) },
            '20': { value: changeTransparency(COLORS.REPORT_TEXT, 0.2) },
            '40': { value: changeTransparency(COLORS.REPORT_TEXT, 0.4) },
            '60': { value: changeTransparency(COLORS.REPORT_TEXT, 0.8) },
            '80': { value: changeTransparency(COLORS.REPORT_TEXT, 0.8) },
            '90': { value: changeTransparency(COLORS.REPORT_TEXT, 0.9) },
            '100': { value: COLORS.REPORT_TEXT }
          }
    }
    // components: {
    //   heading: {
    //     1: {
    //       fontSize: {value: "1.25rem"},
    //       fontWeight: { value: "bold" }
    //     },
    //     2: {
    //       fontSize: {value: "1.25rem"},
    //       fontWeight: { value: "bold" }
    //     },
    //     3: {
    //       fontSize: {value: "1.25rem"},
    //       fontWeight: { value: "bold" }
    //     },
    //     4: {
    //       fontSize: {value: "1.25rem"},
    //       fontWeight: { value: "bold" }
    //     },
    //     5: {
    //       fontSize: {value: "1.25rem"},
    //       fontWeight: { value: "bold" }
    //     }
    //   }
    // }
    // components: {
    //     button: {
    //       primary: {
    //         backgroundColor: { value: COLORS.REPORT_TEXT }
    //       },
    //       link: {
    //         color: { value: COLORS.REPORT_TEXT }
    //       }
    //     },
    //     tabs: {
    //       item: {
    //         _focus: {
    //           color: {
    //             value: "white"
    //           },
    //           backgroundColor: {
    //             value: COLORS.REPORT_TEXT
    //           }
    //         },
    //         _hover: {
    //           color: {
    //             value: COLORS.REPORT_TEXT_GRAY
    //           },
    //           backgroundColor: {
    //             value: COLORS.REPORT_TEXT
    //           }
    //         },
    //         _active: {
    //           color: {
    //             value: "white"
    //           },
    //           backgroundColor: {
    //             value: COLORS.REPORT_TEXT
    //           }
    //         }
    //       }
    //   }
    }
  }
};
