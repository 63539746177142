// @flow
import React from 'react';
import { Flex, Box, Text } from '@chakra-ui/react';
import COLORS from '../common/colors';
import ColorectalReportStrings from './colorectal-report-common';
import Disclaimers from '../common/reports/Disclaimers';

type Props = {
  isMobile: boolean
};

export default function ColorectalExplanatorySection(props: Props) {
  const headerFontSize = props.isMobile ? 17 : 20;
  const textFontSize = props.isMobile ? 12 : 14;
  return (
    <Box fontSize={textFontSize}>
      <Flex align="center" justify="space-between">
        <Text fontSize={headerFontSize} color="gray.600" mt="30px" mb="20px">
          What is Colorectal Cancer ?
        </Text>
      </Flex>
      <Text mb="20px" color={COLORS.REPORT_TEXT}>
        {ColorectalReportStrings.WHAT_IS_COLORECTAL_TEXT1}
        <br />
        <br />
        {ColorectalReportStrings.WHAT_IS_COLORECTAL_TEXT2}
        <sup>*</sup>.
        <br />
      </Text>
      <Text mb="5px">
        <i>
          <sup>*</sup> {ColorectalReportStrings.WHAT_IS_COLORECTAL_REF1}
        </i>
      </Text>
      <Text fontSize={headerFontSize} color="gray.600" my="20px">
        How did we calculate your score ?
      </Text>
      <Text mb="15px" color={COLORS.REPORT_TEXT}>
        {ColorectalReportStrings.HOW_DID_WE_CALC_TEXT1}
        <sup>*</sup>. {ColorectalReportStrings.HOW_DID_WE_CALC_TEXT2}
      </Text>
      <Text mb="20px" fontSize={textFontSize}>
        <sup>*</sup>
        <i>{ColorectalReportStrings.HOW_DID_WE_CALC_REF1}</i>
      </Text>
      <Text mb="20px" fontSize={textFontSize}>
        <sup>*</sup>
        <i>{ColorectalReportStrings.HOW_DID_WE_CALC_REF2}</i>
      </Text>
      <Text mb="20px" fontSize={textFontSize}>
        <sup>*</sup>
        <i>{ColorectalReportStrings.HOW_DID_WE_CALC_REF3}</i>
      </Text>
      <Disclaimers
        isMobile={props.isMobile}
        texts={[
          ColorectalReportStrings.DISCLAIMER1,
          ColorectalReportStrings.DISCLAIMER2,
          ColorectalReportStrings.DISCLAIMER3,
          ColorectalReportStrings.DISCLAIMER4,
          ColorectalReportStrings.DISCLAIMER5,
          ColorectalReportStrings.DISCLAIMER6
        ]}
      />
    </Box>
  );
}
