// @flow
import React from 'react';
import {Box} from '@chakra-ui/react';
import {v4 as uuid4} from "uuid";
import HeartAgeChart from './HeartAgeChart';
import CadReportCommon from "./cad-report-common";
import DoubleRiskChartsSection from "../common/reports/DoubleRiskChartsSection";

type Props = {
  percentile: number,
  quintile: number,
  oddsRatios: Array<number>,
  deciles: Array<number>,
  overallRiskPercentile: number,
  overallRiskOddsRatios: Array<number>,
  overallRiskDeciles: Array<number>,
  age: ?number,
  showHeartAge: boolean,
  printing: ?boolean,
  isMobile: boolean
};

export default function CadChartsSection(props: Props) {
  return (
    <Box key={uuid4()}
      sx={{
        '@media print': {
          pageBreakBefore: 'always;'
        }
      }}
    >
      {props.showHeartAge && (
        <HeartAgeChart
          age={props.age}
          isMobile={false}
          geneticRiskPercentile={props.percentile}
          height={350}
        />
      )}
      <Box mt={props.printing ? "30px": "0px"} key={uuid4()}>
        <DoubleRiskChartsSection
          shortTrait="CAD"
          longTrait="coronary artery disease"
          geneticPercentile={props.percentile}
          geneticDeciles={props.deciles}
          geneticOddsRatios={props.oddsRatios}
          geneticMinY={3}
          geneticMaxY={props.percentile > 90 ? 8.0 : 7.5}
          geneticYTickInterval={0.5}
          geneticTrendInfoText={CadReportCommon.CAD_GENETIC_RISK_IN_POPULATION}
          overallPercentile={props.overallRiskPercentile}
          overallDeciles={props.overallRiskDeciles}
          overallOddsRatios={props.overallRiskOddsRatios}
          overallMinY={0}
          overallMaxY={props.overallRiskPercentile > 90 ? 10 : 9}
          overallYTickInterval={1}
          overallTrendInfoText={CadReportCommon.CAD_OVERALL_RISK_IN_POPULATION}
          printing={props.printing}
          isMobile={props.isMobile}
        />
      </Box>
    </Box>
  );
}
