import React from 'react';
import GeneticReport from "./GeneticReport";
import MobileGeneticReport from "./MobileGeneticReport";
import withMobile from "../mobile/withMobile";

function GeneticReportOrMobileImpl(props) {
  const {isMobile} = props;
  return <>
    {!isMobile && (<GeneticReport {...props}/>)}
    {isMobile && (<MobileGeneticReport {...props}/>)}
  </>
}

export default withMobile(GeneticReportOrMobileImpl);
