// @flow
import React, {useCallback, useState} from 'react';
import {Box, Text} from "@chakra-ui/react";
import BarcodeReaderWrapper from "./BarcodeReaderWrapper";
import ActionButton from "../ActionButton";
import {presignedFromLink} from "./kit-barcode-url";
import COLORS from "../colors";
import {is_localhost_url} from "../../config/env_config";
import withMobile from "../mobile/withMobile";

type Props = {
	isMobile: boolean
}

function MobileScanKitId(props: Props) {
	const [kitId, setKitId] = useState("DUMMY123");
	const [uploaded, setUploaded] = useState(false);
	const [uploadError, setUploadError] = useState(false);
	const onScanning = useCallback((scanning: boolean) => {
		if (scanning) {
			setUploaded(false);
		}
	}, [setUploaded]);
	const onUpload = useCallback(async() => {
		if (!kitId) return;
		const presignedUrl = presignedFromLink(window.location.href);
		const response = await fetch(presignedUrl, {
        method: 'PUT',
				headers: {
					'Content-Type': 'application/json'
				},
        body: JSON.stringify({ kitId: kitId })
     	});
		console.log(response.status);
		if (response.status >= 200 && response.status < 300) {
			setUploadError(false);
			setUploaded(true);
		} else {
			setUploadError(true);
			setUploaded(false);
		}
	}, [kitId]);
  const {isMobile} = props;
  return (
    <Box mx={"10%"} my={isMobile ? "20px" : "50px"} color={COLORS.REPORT_TEXT}>
      <BarcodeReaderWrapper
					title={"SCAN GENETIC KIT ID"}
					barcodeLabel={"Kit ID:"}
					onScanned={setKitId}
					onScanning={onScanning}
					isLocalhost={is_localhost_url(window.location.href)}
					scannedText={"Kit ID barcode was successfully read. Click on 'Upload kit ID' to finish the process."}
			/>
			<ActionButton name={"Upload kit ID"} isDisabled={!kitId || uploaded} mt={isMobile ? 0 : "15px"} onClick={onUpload} w={isMobile ? "100%" : "260px"}/>
			{kitId && (
				<Box my={"20px"} color={COLORS.REPORT_TEXT}>
					{uploaded && <Text color={COLORS.GREEN_STATUS} fontSize={16}>Kit ID was successfully uploaded.</Text>}
					{uploadError && (
						<Box>
							<Text fontSize={15} color={COLORS.RED_STATUS}>Could not upload the kit ID.</Text>
							<Text fontSize={13} color={COLORS.RED_STATUS} mt={"20px"}>If the link to this page was sent 15 minutes or more ago
								then use the website to send a new upload page link to your mobile device, otherwise please check that your
								mobile device has internet access.</Text>
						</Box>
					)}
				</Box>
			)}
		</Box>
	)
}

export default withMobile(MobileScanKitId);