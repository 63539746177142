// @flow
import React from 'react';
import {Flex, Image, Spacer, Text, Box} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import COLORS from './colors';

type Props = {
  title: string,
  value: ?string | number | null,
  labelWidth: string,
  textWidth: string,
  align: string,
  marginBottom?: string,
  textWeight?: string,
  labelColor?: string,
  textColor?: string,
  textAlign?: string,
  useMaxTextWidth?: boolean,
  unitsWidth?: string,
  units: string,
  unitsFontSize: number,
  unitsColor: string,
  trendIcon?: any,
  isMobile?: boolean

};

export default function LabeledTextWithUnits(props: Props) {
  const minW = props.useMaxTextWidth ? undefined : props.textWidth;
  const maxW = props.useMaxTextWidth ? props.textWidth : undefined;
  return (
    <Flex mb={props.marginBottom} align="center">
      {props.trendIcon && (
        <Image src={props.trendIcon} h="15px" w="15px" mr="5px" />
      )}
      {(props.trendIcon === null) && (
        <Box minW="15px" mr="5px" />
      )}
      <Text
        minW={props.labelWidth}
        color={props.labelColor}
      >{`${props.title}:`}</Text>
      <Spacer />
      <Flex align="center">
        <Text
          minW={minW}
          maxW={maxW}
          align={props.align}
          fontWeight={props.textWeight}
          color={props.textColor}
          textAlign={props.textAlign}
        >
          {props.value}
        </Text>
        <Text
          minW={props.unitsWidth}
          align={props.align}
          fontWeight="normal"
          color={props.unitsColor}
          fontSize={props.unitsFontSize}
          ml={props.isMobile ? "2px" : "5px"}
        >
          {props.units}
        </Text>
      </Flex>
    </Flex>
  );
}

LabeledTextWithUnits.propTypes = {
  marginBottom: PropTypes.string,
  textWeight: PropTypes.string,
  labelColor: PropTypes.string,
  textColor: PropTypes.string,
  textAlign: PropTypes.string,
  useMaxTextWidth: PropTypes.bool,
  units: PropTypes.string,
  unitsWidth: PropTypes.string,
  unitsFontSize: PropTypes.number,
  trendIcon: PropTypes.any,
  isMobile: PropTypes.bool
};

LabeledTextWithUnits.defaultProps = {
  marginBottom: '10px',
  textWeight: 'normal',
  labelColor: COLORS.REPORT_TEXT,
  textColor: COLORS.REPORT_TEXT,
  textAlign: 'right',
  useMaxTextWidth: false,
  units: 'kg',
  unitsWidth: '40',
  unitsFontSize: 10,
  trendIcon: undefined,
  isMobile: false
};
