// @flow
import React from 'react';
import { Text } from '@chakra-ui/react';
import { calcAbsRisk, calcAvgPrevalence } from '../../utils/absolute_risk';

type Props = {
  trait: string,
  oddsRatio: number,
  prevalences: Array<number>
};

export default function AbsRiskPanel(props: Props) {
  const prevalence = calcAvgPrevalence(props.prevalences);
  const absRisk = calcAbsRisk(props.oddsRatio, prevalence) * 100;
  return (
    <Text textAlign="left">
      Your life time absolute risk to have {props.trait} is {absRisk.toFixed(1)}
      %
    </Text>
  );
}
