// @flow
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Box, Text, Image, Flex, Spacer, UnorderedList, ListItem} from '@chakra-ui/react';
import COLORS from '../common/colors';
import flyer1 from '../images/flyer1.png';
import flyer6 from '../images/flyer6.png';
import ActionButton from "../common/ActionButton";
import {Auth} from "aws-amplify";
import {amplifyFormFields} from "../auth/amplify-components";
import withNavigation from "../main/WithRoutes";
import {dc, du} from "../utils/b";
import InProgress from "../common/InProgress";
import {v4 as uuid4} from "uuid";
import withMobile from "../common/mobile/withMobile";
import queryString from "query-string";
import {useAuthenticator} from "@aws-amplify/ui-react";
import {increasePotentialPatientAccessCount, updatePotentialPatient} from "../api/capilots";
import AWS from "aws-sdk";

type Props = {
  params: any,
  location: any,
  isMobile: boolean,
};

export function TestInfoPageImpl(props: Props) {
  const queryParams = queryString.parse(props.location.search);
  const access = queryParams.access === 'true';

  const { user } = useAuthenticator((context) => [context.user]);
  const [processing, setProcessing] = useState(false);
  const [message, setMessage] = useState("Updating your selection...");
  const [updated, setUpdated] = useState(false);
  const [increased, setIncreased] = useState(false);

  const a = useMemo(() => du('yxCnwCrju'), []);
  const b = useMemo(() => dc('jwm-wnyx@ujrCwnCxy-Va', 'yzcnactkL'), []);
  const recordId = props.params.record;

  const signOut = useCallback(async () => {
    if (user) {
      console.log("signing out")
      if (AWS.config.credentials) {
        AWS.config.credentials.clearCachedId();
      }
      await Auth.signOut();
      sessionStorage.removeItem("handledClockOffset");
    }
  }, [user]);

  useEffect(() => {
    async function signIn() {
      setProcessing(true);
      const ampFormFields = { ...amplifyFormFields };
      ampFormFields.signIn.username['defaultValue'] = a;
      ampFormFields.signIn.password['defaultValue'] = b;
      Auth.signIn(a, b).then()
    }
    async function increaseAccessCount() {
      increasePotentialPatientAccessCount(recordId).then();
    }
    if (!access && !user) {
      signIn().then(()=>{
        setTimeout(function () {
          window.location.href = window.location.href + "/update?access=true";
        }, 3000)}
      );
    } else if (access && user && !updated && !increased) {
      setIncreased(true);
      increaseAccessCount().then();
    }
  }, [updated, a, b, access, increased, signOut, recordId, user]);

  const onUpdateInterested = useCallback(async(recordId, isInterested) => {
    async function onSignOut() {
      await signOut();
    }
    await updatePotentialPatient(recordId, isInterested);
    if (isInterested) {
      setMessage("Your doctor has been notified of your interest in genetic testing and you can discuss it further during your upcoming visit.")
    } else {
      setMessage("Your selection has been updated.")
    }
    setUpdated(true);
    await onSignOut();
  }, [signOut]);

  const onNotInterested = useCallback(() => {
    onUpdateInterested(recordId, false).then();
  }, [onUpdateInterested, recordId]);
  const onInterested = useCallback(() => {
    onUpdateInterested(recordId, true).then();
  }, [onUpdateInterested, recordId]);

  return (
      <Flex>
        {!updated && <Box color={COLORS.REPORT_TEXT} mx={props.isMobile ? "0%" : "5%"} mt={props.isMobile ? "10px" : "50px"}>
          <Image src={flyer1} />
          <Box mx={props.isMobile ? "2%" : 0}>
            <Text my={"20px"} >Your individualized report includes risk assessment for:</Text>
            <Flex mt={"10px"} align={"top"}>
              <UnorderedList ml="20px" spacing={"10px"} mt={"5px"} fontWeight={"bold"}>
                <ListItem key={uuid4()}>Coronary artery disease</ListItem>
                <ListItem key={uuid4()} color={COLORS.HOME_GREEN}>Hypercholesterolemia</ListItem>
                <ListItem key={uuid4()} >Hypertension</ListItem>
                <ListItem key={uuid4()} color={COLORS.HOME_GREEN}>Type 2 diabetes</ListItem>
              </UnorderedList>
              <Spacer/>
              {!props.isMobile && <Box borderWidth={1} borderColor={"gray.100"} >
                <Box m={"15px"} >
                  <Image src={flyer6}/>
                </Box>
              </Box>}
            </Flex>
            {props.isMobile && <Box m={"15px"} >
                <Image src={flyer6}/>
            </Box>}
            <Flex align={"center"} my={"40px"}>
              <ActionButton
                onClick={onInterested}
                borderWidth={1}
                borderColor={COLORS.REPORT_TEXT}
                name="Yes, I am interested"
                w={props.isMobile ? "45%" : "300px"}
                minW={props.isMobile ? "45%" : "150px"}
                h={"50px"}
                color={COLORS.REPORT_TEXT}
                isDisabled={!recordId || processing || !increased}
                fontSize={props.isMobile ? 12: 16}
              />
              {!props.isMobile && <Spacer/>}
              <ActionButton
                onClick={onNotInterested}
                borderWidth={1}
                borderColor={COLORS.REPORT_TEXT}
                name="No, not interested"
                w={props.isMobile ? "45%" : "280px"}
                minW={props.isMobile ? "45%" : "150px"}
                h={"50px"}
                ml={props.isMobile ? "10px" : "0px"}
                color={COLORS.REPORT_TEXT}
                isDisabled={!recordId || processing || !increased}
                fontSize={props.isMobile ? 12: 16}
              />
            </Flex>
            {processing && !updated && (
              <InProgress label="Loading, please wait ..." />
            )}
          </Box>
        </Box>}
        {updated && <Box color={COLORS.REPORT_TEXT} mx={props.isMobile ? "0%" : "5%"} mt={props.isMobile ? "10px" : "50px"}>
            <Text mb={"10px"} color={"gray.600"}>{message}</Text>
            <Text mb={"10px"} color={"gray.600"}>{"Thank you!"}</Text>
          </Box>
        }
    </Flex>
  );
}

export default withNavigation(withMobile(TestInfoPageImpl));
