import {
	Flex,
	NumberInput,
	NumberInputField,
	Text, Tooltip
} from "@chakra-ui/react";
import {undefinedForNan} from "../utils/numbers";
import React, {useCallback} from "react";
import PropTypes from "prop-types";
import COLORS from "./colors";

type Props = {
	label: string,
	value: ?number,
	lowLimit: number,
	highLimit: number,
	onChange: any,
	units?: string,
	defaultValue?: ?Number,
	labelWidth?: string,
	numberWidth?: string,
	size?: string
}

export default function EditableNumericAttrImpl(props: Props) {
	const [value, setValue] = React.useState(props.defaultValue);
	const onChange = useCallback((val)=>{
		setValue(val)
	}, [setValue]);
	const onChangeProps = props.onChange;
	const {lowLimit, highLimit} = props;
	const onBlur = useCallback((_el)=>{
		if (value === undefined || value === null) return;
		let updateValue = value;
		if (value < lowLimit)	updateValue = lowLimit;
		if (value > highLimit) updateValue = highLimit;
		onChangeProps(updateValue);
	}, [onChangeProps, value, lowLimit, highLimit]);

	return <Flex align="center" mb="10px">
		<Text w={props.labelWidth}>{`${props.label}:`}</Text>
		<NumberInput
			w={props.numberWidth}
			defaultValue={undefinedForNan(props.value)}
			min={props.lowLimit}
			max={props.highLimit}
			onChange={onChange}
			onBlur={onBlur}
			step={props.step}
			size={props.size}
		>
			<Tooltip label={`${props.label} allowed range: ${lowLimit} - ${highLimit} ${props.units}`} bg={COLORS.REPORT_TEXT} color={"white"} w={"200px"}>
				<NumberInputField px={1}/>
			</Tooltip>
		</NumberInput>
			<Tooltip label={`${props.label} allowed range: ${lowLimit} - ${highLimit} ${props.units}`} bg={COLORS.REPORT_TEXT} color={"white"} w={"200px"}>
				<Text ml="5px" fontSize={9} color={COLORS.REPORT_TEXT_GRAY} maxW={"30px"}>{props.units}</Text>
			</Tooltip>
	</Flex>
}

EditableNumericAttrImpl.propTypes = {
	defaultValue: PropTypes.number,
	labelWidth: PropTypes.string,
	numberWidth: PropTypes.string,
	units: PropTypes.string,
	step: PropTypes.number,
	size: PropTypes.string
}

EditableNumericAttrImpl.defaultProps = {
	defaultValue: undefined,
	labelWidth: "180px",
	numberWidth: "80px",
	units: "",
	step: 1,
	size: "xs"
}
