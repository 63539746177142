// @flow
import React from 'react';
import { Flex, Box, Text, Image } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import COLORS from '../common/colors';
import IconTextAndLink from '../common/IconTextAndLink';
import dietImage from '../images/diet.png';
import runningImage from '../images/running.png';
import stressImage from '../images/yoga.png';
import noSmokingImage from '../images/no-smoking.png';
import firstAidImage from '../images/first-aid-kit.png';

type Props = {
  isMobile: boolean
};

export default function T2dRecommendationSection(props: Props) {
  return (
    <Box
      sx={{
        '@media print': {
          pageBreakBefore: !props.isMobile ? 'always' : 'auto'
        }
      }}
    >
      <Text mt="40px" color={COLORS.REPORT_TEXT} fontWeight="semibold">
        To reduce risk for developing T2D you can refer to the following:
      </Text>
      <IconTextAndLink
        imageSrc={dietImage}
        text="Diet"
        linkText="Preserve a healthy diet"
        link="https://www.heart.org/en/health-topics/high-blood-pressure/changes-you-can-make-to-manage-high-blood-pressure/managing-blood-pressure-with-a-heart-healthy-diet"
        isMobile={props.isMobile}
      />
      <IconTextAndLink
        imageSrc={runningImage}
        text="Exercise"
        linkText="Recommendations for physical activity"
        link="https://www.heart.org/en/healthy-living/fitness/fitness-basics/aha-recs-for-physical-activity-in-adults"
        isMobile={props.isMobile}
      />
      <IconTextAndLink
        imageSrc={stressImage}
        text="Stress reduction"
        linkText="Stress management"
        link="https://www.heart.org/en/healthy-living/healthy-lifestyle/stress-management"
        isMobile={props.isMobile}
      />
      <IconTextAndLink
        imageSrc={noSmokingImage}
        text="Smoking cessation"
        linkText="Quit smoking, vaping and tobacco use"
        link="https://www.heart.org/en/healthy-living/healthy-lifestyle/quit-smoking-tobacco"
        isMobile={props.isMobile}
      />
      <Flex align="center" mt="40px" mb="10px">
        <Image src={firstAidImage} h="32px" minW="32px" alt="" mr="10px" />
        <Text fontSize={17} color="gray.500" fontWeight="semibold">
          Medications - ask your doctor
        </Text>
      </Flex>
    </Box>
  );
}

T2dRecommendationSection.propTypes = {
  isMobile: PropTypes.bool
};

T2dRecommendationSection.defaultProps = {
  isMobile: false
};
