// @flow
import React, { useCallback, useEffect, useState } from 'react';
import { Box, Flex, Divider, Spacer } from '@chakra-ui/react';
import LabeledTextWithUnits from '../common/LabelTextAndUnits';
import COLORS from '../common/colors';
import ActionButton from '../common/ActionButton';
import {
  kgToPounds,
  heightCmToString
} from '../utils/unit_translation';
import { mmolL2mgdl } from '../utils/cholesterol';
import calcBmi from '../utils/bmi';
import { mmolmol2percents } from '../utils/a1c';
import patient1visits from './data/patient1-visits.json';
import patient2visits from './data/patient2-visits.json';
import {toFixedHandleUndefined} from "../utils/numbers";

type Props = {
  patientId: string
};

export default function DemoPilotPatientVisitUnion(props: Props) {
  const [visit, setVisit] = useState({});
  const [patient, setPatient] = useState({});
  const {patientId} = props;

  // declare the data fetching function
  const fetchData = useCallback(() => {
    const unionVisit =
      patientId === '1' ? patient1visits : patient2visits;
    setVisit(unionVisit.visit);
    setPatient(unionVisit);
    console.log(unionVisit.visits);
  }, [patientId]);
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const calcBMI = useCallback((weight: number, height: number) => {
    if (!weight || !height) return '';
    const bmi = calcBmi(weight, height / 100);
    if (bmi === null) return '';
    return bmi.toFixed(1);
  }, []);

  return (
    <Box ml="200px">
      <Divider w="925px" mt="15px" />
      <Flex align="top" justify="left" fontSize={13} mt="10px">
        <Box w="145px" mr="75px">
          <LabeledTextWithUnits
            align="left"
            labelWidth="60px"
            textWidth="60px"
            title="BMI"
            value={calcBMI(visit.weight, patient.height)}
            marginBottom="1px"
            textWeight="semibold"
            labelColor={COLORS.REPORT_TEXT_GRAY}
            textColor={COLORS.REPORT_TEXT_GRAY}
            unitsWidth="45px"
            unitsColor={COLORS.REPORT_TEXT_GRAY}
            units={'kg/m\u00B2'}
          />
          <LabeledTextWithUnits
            align="left"
            labelWidth="60px"
            textWidth="60px"
            title="Weight"
            value={visit.weight ? toFixedHandleUndefined(kgToPounds(visit.weight), 1) : ''}
            marginBottom="1px"
            textWeight="semibold"
            labelColor={COLORS.REPORT_TEXT_GRAY}
            textColor={COLORS.REPORT_TEXT_GRAY}
            unitsWidth="45px"
            unitsColor={COLORS.REPORT_TEXT_GRAY}
            units="pound"
          />
          <LabeledTextWithUnits
            align="left"
            labelWidth="60px"
            textWidth="60px"
            title="Height"
            value={heightCmToString(patient.height)}
            marginBottom="1px"
            textWeight="semibold"
            labelColor={COLORS.REPORT_TEXT_GRAY}
            textColor={COLORS.REPORT_TEXT_GRAY}
            unitsWidth="45px"
            unitsColor={COLORS.REPORT_TEXT_GRAY}
            units=""
          />
        </Box>
        <Box w="125px" mr="65px">
          <LabeledTextWithUnits
            align="left"
            labelWidth="30px"
            textWidth="50px"
            title="SBP"
            value={visit.sbp}
            marginBottom="1px"
            textWeight="semibold"
            labelColor={COLORS.REPORT_TEXT_GRAY}
            textColor={COLORS.REPORT_TEXT_GRAY}
            unitsWidth="45px"
            unitsColor={COLORS.REPORT_TEXT_GRAY}
            units="mmHg"
          />
          <LabeledTextWithUnits
            align="left"
            labelWidth="30px"
            textWidth="50px"
            title="DBP"
            value={visit.dbp}
            marginBottom="1px"
            textWeight="semibold"
            labelColor={COLORS.REPORT_TEXT_GRAY}
            textColor={COLORS.REPORT_TEXT_GRAY}
            unitsWidth="45px"
            unitsColor={COLORS.REPORT_TEXT_GRAY}
            units="mmHg"
          />
        </Box>
        <Box w="130px" mr="70px">
          <LabeledTextWithUnits
            align="left"
            labelWidth="35px"
            textWidth="50px"
            title="A1C"
            value={visit.a1c ? mmolmol2percents(visit.a1c).toFixed(1) : ''}
            marginBottom="1px"
            textWeight="semibold"
            labelColor={COLORS.REPORT_TEXT_GRAY}
            textColor={COLORS.REPORT_TEXT_GRAY}
            unitsWidth="45px"
            unitsColor={COLORS.REPORT_TEXT_GRAY}
            units="%"
          />
          <LabeledTextWithUnits
            align="left"
            labelWidth="35px"
            textWidth="50px"
            title="HDL"
            value={visit.hdl ? mmolL2mgdl(visit.hdl).toFixed(1) : ''}
            marginBottom="1px"
            textWeight="semibold"
            labelColor={COLORS.REPORT_TEXT_GRAY}
            textColor={COLORS.REPORT_TEXT_GRAY}
            unitsWidth="45px"
            unitsColor={COLORS.REPORT_TEXT_GRAY}
            units="mg/dL"
          />
          <LabeledTextWithUnits
            align="left"
            labelWidth="35px"
            textWidth="50px"
            title="LDL"
            value={visit.ldl ? mmolL2mgdl(visit.ldl).toFixed(1) : ''}
            marginBottom="1px"
            textWeight="semibold"
            labelColor={COLORS.REPORT_TEXT_GRAY}
            textColor={COLORS.REPORT_TEXT_GRAY}
            unitsWidth="45px"
            unitsColor={COLORS.REPORT_TEXT_GRAY}
            units="mg/dL"
          />
          <LabeledTextWithUnits
            align="left"
            labelWidth="35px"
            textWidth="50px"
            title="TC"
            value={visit.tc ? mmolL2mgdl(visit.tc).toFixed(1) : ''}
            marginBottom="1px"
            textWeight="semibold"
            labelColor={COLORS.REPORT_TEXT_GRAY}
            textColor={COLORS.REPORT_TEXT_GRAY}
            unitsWidth="45px"
            unitsColor={COLORS.REPORT_TEXT_GRAY}
            units="mg/dL"
          />
        </Box>
        <Box w="200px" maxW="200px">
          <LabeledTextWithUnits
            align="left"
            labelWidth="150px"
            textWidth="30px"
            title="Treated for high BP"
            value={visit.bp_treated ? 'Yes' : 'No'}
            marginBottom="1px"
            textWeight="semibold"
            labelColor={COLORS.REPORT_TEXT_GRAY}
            textColor={COLORS.REPORT_TEXT_GRAY}
            unitsWidth="45px"
            unitsColor={COLORS.REPORT_TEXT_GRAY}
            units=""
          />
          <LabeledTextWithUnits
            align="left"
            labelWidth="150px"
            textWidth="30px"
            title="Diabetes"
            value={visit.diabetes ? 'Yes' : 'No'}
            marginBottom="1px"
            textWeight="semibold"
            labelColor={COLORS.REPORT_TEXT_GRAY}
            textColor={COLORS.REPORT_TEXT_GRAY}
            unitsWidth="45px"
            unitsColor={COLORS.REPORT_TEXT_GRAY}
            units=""
          />
          <LabeledTextWithUnits
            align="left"
            labelWidth="150px"
            textWidth="30px"
            title="Diabetes family history"
            value={visit.fh_diabetes ? 'Yes' : 'No'}
            marginBottom="1px"
            textWeight="semibold"
            labelColor={COLORS.REPORT_TEXT_GRAY}
            textColor={COLORS.REPORT_TEXT_GRAY}
            unitsWidth="45px"
            unitsColor={COLORS.REPORT_TEXT_GRAY}
            units=""
          />
          <LabeledTextWithUnits
            align="left"
            labelWidth="150px"
            textWidth="30px"
            title="Smoker"
            value={visit.smoker ? 'Yes' : 'No'}
            marginBottom="1px"
            textWeight="semibold"
            labelColor={COLORS.REPORT_TEXT_GRAY}
            textColor={COLORS.REPORT_TEXT_GRAY}
            unitsWidth="45px"
            unitsColor={COLORS.REPORT_TEXT_GRAY}
            units=""
          />
        </Box>
        <ActionButton
          ml="50px"
          name="Edit..."
          fontSize={13}
          disabled
          // onClick={onEdit}
          color={COLORS.LABEL_TEXT_COLOR}
        />
        <Spacer />
      </Flex>
    </Box>
  );
}
