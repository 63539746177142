// @flow
import React, {useCallback, useEffect} from 'react';
import {Box, Flex, Spacer} from '@chakra-ui/react';
import {
	getAgeAttr, getBooleanAttr, getWeightAttr
} from "../../common/calculator/calculator-attr";
import {calcOdds, calcRiskPercentile, calcSumCoefByValue} from "../../utils/log_reg_utils";
import {getCadRiskLevel, getCadRiskColor} from "../../common/risk-colors";
import cadReportStrings from "../../cad/cad-report-common";
import {boolToOneOrZero} from "../../utils/numbers";
import {calcBmiNumeric} from "../../utils/bmi";
import CalculatorOrMobile from "../../common/calculator/CalculatorOrMobile";
import traitRanges from "../../common/reports/trait-ranges.json";
import ActionButton from "../../common/ActionButton";
import COLORS from "../../common/colors";

type Props = {
		sex: number,
		age: ?number,
		weight: number,
		height: number,
		hypertensive: boolean,
		hypercholesterol: boolean,
		exercise: boolean,
		diabetes: boolean,
		smoker: boolean,
		// cadScore: number,
		stdizedCadScore: number,
		printing: boolean,
		callbackInitRiskPercentile: any,
		logReg: Object,
		geneticRiskPercentile: number,
		physicalActivity: ?number,
		sleep: ?number,
		heartRate: ?number,
		bloodOxygen: ?number,
		callbackOnUpdateFromWearables: any
};

export default function DemoMyGeneInsightsCadCalculator(props: Props) {
	const {logReg, stdizedCadScore, height} = props;
	delete logReg.col_standardization.overall_risk_cad;

	const runLogReg = useCallback((sex: number, input: Object) => {
		const bmi = calcBmiNumeric(input.weight, height / 100)
		const attrs = {
			sex: sex,
			age: input.age,
			bmi: bmi,
			t2d: boolToOneOrZero(input.diabetes),
			smoking: boolToOneOrZero(input.smoker),
			hypertensive: boolToOneOrZero(input.hypertensive),
			hypercholesterol: boolToOneOrZero(input.hypercholesterol),
			exercise: boolToOneOrZero(input.exercise),
			overall_risk_cad: stdizedCadScore
		}
		const s = calcSumCoefByValue(attrs, logReg);
		return {
			percentile: calcRiskPercentile(s, logReg.percentiles),
			// risk: calcRiskUsingSurvival(s, logReg.mean_coef_by_value, logReg.survival_rate) * 100,
			risk: null,
			odds: calcOdds(s)
		}
	}, [logReg, stdizedCadScore, height]);

	const {weight, sex, age, diabetes, smoker,hypertensive, hypercholesterol, exercise, callbackInitRiskPercentile} = props;
	useEffect(() => {
		if (callbackInitRiskPercentile) {
			const initResults = runLogReg(
				sex,
	{
					age: age,
					height: height,
					weight: weight,
					t2d: boolToOneOrZero(diabetes),
					smoking: boolToOneOrZero(smoker),
					hypertensive: boolToOneOrZero(hypertensive),
					hypercholesterol: boolToOneOrZero(hypercholesterol),
					exercise: boolToOneOrZero(exercise),
					overall_risk_cad: stdizedCadScore
				}
			);
			callbackInitRiskPercentile(initResults.percentile);
		}
	}, 	[runLogReg, height, weight, sex, age, diabetes, smoker, hypertensive, hypercholesterol, exercise, stdizedCadScore, callbackInitRiskPercentile]);

	const getHeartRateAttr = useCallback((currentHeartRate: ?number) => {
		const heartRateMin = currentHeartRate && currentHeartRate > traitRanges.heartRate.lowLimit ? currentHeartRate : traitRanges.heartRate.lowLimit;
		const heartRateMax = !currentHeartRate || currentHeartRate < traitRanges.heartRate.highLimit ? traitRanges.heartRate.highLimit : currentHeartRate;
		const defaultHeartRate: number = currentHeartRate ?? traitRanges.heartRate.lowLimit;

		return {
			name: "heart_rate",
			displayText: "Heart rate",
			min: heartRateMin,
			max: heartRateMax,
			units: "bmp",
			onChange: undefined,
			defaultValue: defaultHeartRate,
			resetValue: currentHeartRate ?? defaultHeartRate,
			currentValue: currentHeartRate,
			step: 1,
			fTranslateToDisplayValue: null,
			fTranslateFromDisplayValue: null,
			fGetColorByValue: null,
			fGetDiffText: undefined,
			isAdjustable: false,
			isSliderEnabled: !!currentHeartRate,
			fixDigits: 0,
			isEditable: true
		}
	}, []);

	const getBloodOxygenAttr = useCallback((currentBloodOxygen: ?number) => {
		const bloodOxygenMin = currentBloodOxygen && currentBloodOxygen > traitRanges.bloodOxygen.lowLimit ? currentBloodOxygen : traitRanges.bloodOxygen.lowLimit;
		const bloodOxygenMax = !currentBloodOxygen || currentBloodOxygen < traitRanges.bloodOxygen.highLimit ? traitRanges.bloodOxygen.highLimit : currentBloodOxygen;
		const defaultBloodOxygen: number = currentBloodOxygen ?? traitRanges.bloodOxygen.lowLimit;

		return {
			name: "blood_oxygen",
			displayText: "Blood oxygen",
			min: bloodOxygenMin,
			max: bloodOxygenMax,
			units: "SpO2%",
			onChange: undefined,
			defaultValue: defaultBloodOxygen,
			resetValue: currentBloodOxygen ?? defaultBloodOxygen,
			currentValue: currentBloodOxygen,
			step: 1,
			fTranslateToDisplayValue: null,
			fTranslateFromDisplayValue: null,
			fGetColorByValue: null,
			fGetDiffText: undefined,
			isAdjustable: false,
			isSliderEnabled: !!currentBloodOxygen,
			fixDigits: 0,
			isEditable: true
		}
	}, []);

	const numericAttrs = [
		getAgeAttr(props.age),
    getWeightAttr(props.weight),
    getHeartRateAttr(props.heartRate),
    getBloodOxygenAttr(props.bloodOxygen)
	];

	const booleanAttrs = [
		getBooleanAttr("smoker", "Smoker", props.smoker, false, true, undefined, false),
		getBooleanAttr("exercise", "Exercise physical activity", props.exercise, false, true, undefined, false),
		getBooleanAttr("hypertensive", "High blood pressure", props.hypertensive, false, true, undefined, false),
		getBooleanAttr("hypercholesterol", "High cholesterol levels", props.hypercholesterol, false, true, undefined, false),
		getBooleanAttr("diabetes", "Diabetes", props.diabetes, false, true, undefined, false)
	];
	const getPhysicalActivityAttr = useCallback((activity: ?number) => {
		return {
			name: "physical_activity",
			displayText: "Physical activity",
			onChange: undefined,
			keyNameOptions: {
				"1": "none",
				"2": "light",
				"3": "moderate",
				"4": "intensive",
			},
			defaultValue: 2,
			resetValue: activity,
			currentValue: activity,
			isEditable: false
		}
	}, []);

	const getSleepAttr = useCallback((sleep: ?number) => {
		return {
			name: "sleep",
			displayText: "Sleep",
			onChange: undefined,
			keyNameOptions: {
				"1": "good",
				"2": "average",
				"3": "poor",
			},
			defaultValue: 2,
			resetValue: sleep,
			currentValue: sleep,
			isEditable: false,
		}
	}, []);

	const selectionAttrs = [
		getPhysicalActivityAttr(props.physicalActivity),
		getSleepAttr(props.sleep)
	];

	const infoTexts = {
		relativeToPopulation: {
			header: cadReportStrings.RELATIVE_TO_POPULATION_INFO_HEADER,
			text: cadReportStrings.RELATIVE_TO_POPULATION_INFO_TEXT,
			footer: cadReportStrings.INFO_FOOTER
		},
		relativeToCurrent: {
			header: cadReportStrings.RELATIVE_TO_CURRENT_INFO_HEADER,
			text: cadReportStrings.RELATIVE_TO_CURRENT_INFO_TEXT,
			footer: cadReportStrings.INFO_FOOTER
		},
		model: cadReportStrings.MODEL_OVERALL_RISK,
		youngerParticipant: cadReportStrings.MODEL_OVERALL_RISK_YOUNGER_PARTICIPANT,
		olderParticipant: cadReportStrings.MODEL_OVERALL_RISK_OLDER_PARTICIPANT,
		agePopInfoTexts: cadReportStrings.MODEL_OVERALL_RISK_AGE_POP_INFO_TEXT
	};

	const geneticRiskQuintile = Math.floor(props.geneticRiskPercentile / 20);
	const {callbackOnUpdateFromWearables} = props;
	const onUpateFromWearables = useCallback(() => {
		callbackOnUpdateFromWearables();
}, [callbackOnUpdateFromWearables]);
	const updateFromWearables = <Flex>
		<Spacer/>
		<ActionButton
        color={COLORS.REPORT_TEXT}
        name="Update from wearables ..."
        onClick={onUpateFromWearables}
        isDisabled={false}
      />
		<Spacer/>
		</Flex>;

	return (
		<Box>
			{props.hypertensive && <CalculatorOrMobile
				sex={props.sex}
				riskQuintile={geneticRiskQuintile}
				riskPercentile={props.geneticRiskPercentile}
				numericAttrs={numericAttrs}
				booleanAttrs={booleanAttrs}
				selectionAttrs={selectionAttrs}
				callbackRunLogReg={runLogReg}
				callbackInitialRisks={callbackInitRiskPercentile}
        callbackGetRecommendations={null}
				disease='coronary artery disease'
				maxRisk={null}
        showRisk={false}
				showRelativeToPop={true}
				showRelativeToCurrent={true}
				riskGauge={null}
        callbackGetColorByRisk={getCadRiskColor}
			  callbackGetRiskLevel={getCadRiskLevel}
				infoTexts={infoTexts}
				printing={props.printing}
				patientAttrFooter={updateFromWearables}
			/>}
			{!props.hypertensive && <CalculatorOrMobile
				sex={props.sex}
				riskQuintile={geneticRiskQuintile}
				riskPercentile={props.geneticRiskPercentile}
				numericAttrs={numericAttrs}
				booleanAttrs={booleanAttrs}
				selectionAttrs={selectionAttrs}
				callbackRunLogReg={runLogReg}
				callbackInitialRisks={callbackInitRiskPercentile}
        callbackGetRecommendations={null}
				disease='coronary artery disease'
				maxRisk={null}
        showRisk={false}
				showRelativeToPop={true}
				showRelativeToCurrent={true}
				riskGauge={null}
        callbackGetColorByRisk={getCadRiskColor}
			  callbackGetRiskLevel={getCadRiskLevel}
				infoTexts={infoTexts}
				printing={props.printing}
				patientAttrFooter={updateFromWearables}
			/>}
		</Box>);
}
