// @flow
import React from 'react';
import { Button } from '@chakra-ui/react';

type Props = {
  name: string
};

export default function ActionButtonImpl(props: Props) {
  const buttonProps: Object = { ...props };
  return (
    <Button
      {...buttonProps}
      _hover={{
        background: 'gray.200',
        borderColor: 'gray.300',
        borderWidth: 3,
        color: 'white'
      }}
      sx={{
        '@media print': {
          display: 'none'
        }
      }}
    >
      {props.name}
    </Button>
  );
}
