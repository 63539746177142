// @flow

const HypercholesterolReportStrings = {
  WHAT_IS_HYPERCHOLESTEROL_TEXT1:
    'Hypercholesterolemia is the term used to refer to a high blood cholesterol level. ' +
    'Cholesterol is a waxy substance that is produced by the liver and is a component of all cells found in the body.\n' +
    '\n' +
    'All of the cholesterol a person needs is produced in the liver, but another source is dietary cholesterol, ' +
    'which comes from animal food products such as meat, poultry, dairy, egg yolk and fish. ' +
    'Such foods are rich in saturated fats and trans fats, substances that can trigger the liver to make an excess ' +
    'of cholesterol and in some cases, this can lead to hypercholesterolemia.\n' +
    '\n' +
    'Cholesterol is required for various bodily functions including the synthesis of cell membranes and certain ' +
    'hormones and the production of substances required for fat digestion. ' +
    'However, a cholesterol level that is too high can increase the risk of coronary artery disease.\n',
  WHAT_IS_HYPERCHOLESTEROL_TEXT2:
    'About 38% of American adults have high cholesterol (total blood cholesterol ≥ 200 mg/dL).',
  WHAT_IS_HYPERCHOLESTEROL_REF1:
    'Virani SS, Alonso A, Aparicio HJ, Benjamin EJ, Bittencourt MS, Callaway CW, et al. ' +
    'Heart disease and stroke statistics—2021 update: a report from the American Heart Associationexternal icon. ' +
    'Circulation. 2021;143:e254–e743.',
  HOW_DID_WE_CALC_TEXT1:
    'OpenDNA calculates your score based on analysis of your ' +
    'genetic variants. Genetic variation is the difference in DNA ' +
    'sequences between individuals within a population. These are the ' +
    'differences in DNA that make every individual different. ' +
    'OpenDNA bases the calculation on a list of 23,728 variants that are known to be associated ' +
    'with HDL levels and on a list of 15,288 variants that are known to be associated with LDL levels ' +
    'and calculates a Polygenic Risk Score (PRS) ' +
    'based on the contribution of each genetic variant to the overall ' +
    'genetic risk for having hypercholesterolemia. ',
  HOW_DID_WE_CALC_TEXT2:
    'Once your PRS is calculated it is compared ' +
    'to a group of about 295,000 people to ' +
    'calculate your relative risk of having hypercholesterolemia ' +
    'compared to that population.',
  HOW_DID_WE_CALC_REF:
    'Sinnott-Armstrong, N., Tanigawa, Y., Amar, D. et al. ' +
    'Genetics of 35 blood and urine biomarkers in the UK Biobank. Nat Genet 53, 185–194 (2021). ' +
    'https://doi.org/10.1038/s41588-020-00757-z ',
  DISCLAIMER1:
    'THIS REPORT MAY NOT INCLUDE EVERY GENETIC MARKER RELATED TO HYPERCHOLESTEROLEMIA.',
  DISCLAIMER2:
    'THIS TEST IS NOT DIAGNOSTIC, IT IS A PREDICTION BASED ON PREDISPOSITION. ' +
    'AN INCREASED RISK SCORE DOES NOT MEAN THAT A PERSON WILL DEFINITELY DEVELOP HYPERCHOLESTEROLEMIA ' +
    'AND A DECREASED RISK SCORE DOES NOT MEAN THAT A PERSON WILL DEFINITELY NOT DEVELOP HYPERCHOLESTEROLEMIA.',
  DISCLAIMER3:
    'THE TEST CANNOT REPLACE MEDICAL TESTING OR MEDICAL MANAGEMENT THROUGH A HEALTHCARE PROVIDER.',
  DISCLAIMER4:
    'THE RISK MAY ALSO BE INFLUENCED BY OTHER LIFESTYLE FACTORS, OTHER CLINICAL FACTORS AND ' +
    'GENETIC VARIANTS THAT ARE NOT INCLUDED IN THE ANALYSIS OR WERE NOT REPORTED PROPERLY.',
  DISCLAIMER5:
    'YOUR RISK IS COMPARED TO A POPULATION WHERE THE MAJORITY IS OF EUROPEAN DESCENT. ' +
    'THE ACCURACY OF THE RESULTS MAY VARY IF YOU HAVE A DIFFERENT BACKGROUND.',
  DISCLAIMER6:
    'TEST RESULTS SHOULD BE INTERPRETED BY A HEALTHCARE PROVIDER. MEDICAL MANAGEMENT AND ' +
    'DECISION-MAKING FOR PREVENTION PRACTICES SHOULD BE DONE IN THE CONTEXT OF THE PATIENT’S ' +
    'FULL CLINICAL HISTORY AND NOT BASED SOLELY ON THE TEST RESULTS.',
  GENETIC_RISK_LDL_INFO_TEXT:
    'The polygenic risk score (PRS) of the patient is calculated by analyzing ' +
    'more than 13K variants known to be associated with LDL. ' +
    'The PRS is then compared to a reference scale, built from scores of ~295K individuals. ' +
    'The scale is divided into percentiles that reflect the genetic risk level. ' +
    "The patient risk percentile is determined by comparing the individual's PRS value against the threshold risk values of each percentile.",
  GENETIC_RISK_HDL_INFO_TEXT:
    'The polygenic risk score (PRS) of the patient is calculated by analyzing ' +
    'more than 23K variants known to be associated with HDL. ' +
    'The PRS is then compared to a reference scale, built from scores of ~295K individuals. ' +
    'The scale is divided into percentiles that reflect the genetic risk level. ' +
    "The patient risk percentile is determined by comparing the individual's PRS value against the threshold risk values of each percentile.",
  LDL_RISK_IN_POPULATION:
    'The graph shows the risk for having high LDL levels vs. the genetic risk level. Genetic risk (PRS) was calculated for ' +
    'more than 295K individuals. The scores were divided into deciles and the risk of having high LDL levels was calculated for each decile. ' +
    "Gray bars indicate the risk level per decile. The highlighted bar represents patient risk level based on the individual's genetic risk.",
  LDL_RISK_VS_AVERAGE:
    'The graph shows patient risk level for having high LDL levels vs. the average risk in the population. ' +
    "The risk of the patient is determined based on the individual's genetic risk level. ",
  HDL_RISK_IN_POPULATION:
    'The graph shows the risk for having low HDL levels vs. the genetic risk level. ' +
    'The higher the genetic risk, the more likely it is to develop low HDL levels. ' +
    'the Genetic risk (PRS) was calculated for ' +
    'more than 295K individuals. The scores were divided into deciles and the risk of having low HDL levels was calculated for each decile. ' +
    "Gray bars indicate the risk level per decile. The highlighted bar represents patient risk level based on the individual's genetic risk.",
  HDL_RISK_VS_AVERAGE:
    'The graph shows patient risk level for having low HDL levels vs. the average risk in the population. ' +
    "The risk of the patient is determined based on the individual's genetic risk level. ",
  HDL_TREND_EXPLANATION:
    'Note: the higher the genetic risk, the more likely it is to develop low HDL levels'
};

export default HypercholesterolReportStrings;
