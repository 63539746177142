// @flow
import React from 'react';
import {Box} from '@chakra-ui/react';
import withNavigation from '../../main/WithRoutes';
import cadImage from '../../images/heart-attack.png';
import {getFileNameOnly} from '../../common/reports/report-common';
import {
  getPatientId,
} from '../filters';
import CadExplanatorySection from '../../cad/CadExplanatorySection';
import AbsRiskPanel from '../../common/reports/AbsRiskPanel';
// import CadChartsSection from '../../cad/CadChartsSection';
import HeartAgePanel from '../../cad/HeartAgePanel';
import { isShowItem } from '../../common/reports/report-config';
import cadReportCommon from '../../cad/cad-report-common';
import GxgPatientAttributes from "../../common/reports/GxgPatientAttributes";
import {getRiskLevelByPercentile} from "../../common/risk-colors";
import gxgDemoClinical1 from "./data/clinical1.json"
import {gxgCadCalculatorUserAttributes} from "../../common/reports/gxg-report-common";
import GxgCadRecommendationSection from "../../cad/GxgCadRecommendationSection";
import GeneticReportOrMobile from "../../common/reports/GeneticReportOrMobile";
import cadConsumerLogReg from "../../consumer/cad-consumer-log-reg.json";
import withMobile from "../../common/mobile/withMobile";
import DemoMyGeneInsightsCadCalculator from "../cad/DemoMyGeneInsightsCadCalculator";
type Props = {
  location: any,
  params: any,
  printing: boolean,
  isMobile: boolean
};

type State = {
  age: ?number,
  overallRiskPercentile: ?number,
  hypertensive: boolean
};

export class DemoMyGeneInsightsCadRiskReportImpl extends React.Component<Props, State> {
  static loadDemoData() {
    // $FlowFixMe[prop-missing]
    const requireContext: any = require.context('./data', true, /\.json$/);
    const json = {};
    requireContext.keys().forEach(key => {
      const obj = requireContext(key);
      const simpleKey = getFileNameOnly(key);
      json[simpleKey] = obj;
    });
    return json;
  }
  constructor(props: Props) {
    super(props);
    (this: any).setAge = this.setAge.bind(this);
    (this: any).onInitOverallRiskPercentile = this.onInitOverallRiskPercentile.bind(this);
    (this: any).onUpdateFromWearables = this.onUpdateFromWearables.bind(this);
    this.patientId = getPatientId(props.params, props.location);
    const cads = DemoMyGeneInsightsCadRiskReportImpl.loadDemoData();
    const key = `cad${this.patientId}`;
    this.cad = cads[key] === undefined ? cads.cad1 : cads[key];
    this.clinical = gxgDemoClinical1;
    this.state = {
      age: this.clinical.age,
      overallRiskPercentile: undefined,
      hypertensive: this.clinical.hypertensive
    };
  }

  state: State;

  setAge(newAge: number) {
    this.setState({
      age: newAge
    });
  }

  onInitOverallRiskPercentile(percentile: number) {
    this.setState({
      overallRiskPercentile: percentile
    });
  }

  onUpdateFromWearables() {
    this.setState({
      hypertensive: true,
    })
  }

  patientId: number;
  cad: Object;
  clinical: Object;

  render() {
    const {isMobile} = this.props;
    const oneBasedPercentile = this.cad.cad.percentile + 1;
    const showHeartAge = isShowItem(this.props.location, 'cad', 'showHeartAge');
    return (
      <Box sx={{ '@media print': { '@page': { size: 'landscape;' } } }}>
        <GeneticReportOrMobile
          xMargin={"5%"}
          reportTitle="CORONARY ARTERY DISEASE RISK REPORT"
          configTrait="cad"
          titleImage={cadImage}
          patientNum={this.patientId}
          geneticKitId={`OD000${this.patientId}`}
          printing={this.props.printing}
          riskPercentile={this.cad.cad.percentile}
          geneticRiskInfoText={cadReportCommon.GENETIC_RISK_INFO_TEXT}
          patientAttributesSection={this.props.printing &&
            <Box w={"420px"} borderWidth={1} borderColor="gray.100">
              <GxgPatientAttributes
                  gender={this.clinical.sex ? "Male": "Female"}
                  age={this.state.age}
                  bmi={this.clinical.bmi}
                  hypertensive={this.state.hypertensive}
                  hypercholesterol={this.clinical.hypercholesterol}
                  exercise={this.clinical.exercise}
                  riskTrait="CAD"
                  riskLevel={getRiskLevelByPercentile(oneBasedPercentile)}
                  diabetes={this.clinical.t2d}
                  smoker={this.clinical.smoking}
                  labelPrefix="Current "
                  showBorder={false}
                  showAttributeFlags={gxgCadCalculatorUserAttributes}
                >
                </GxgPatientAttributes>
              </Box>
          }
          topRightPane={
            <Box>
              <AbsRiskPanel
                trait="coronary artery disease"
                oddsRatio={this.cad.cad.odds_list[this.cad.cad.odds_quantile]}
                prevalences={this.cad.cad.deciles}
              />
              {showHeartAge && (
                <Box mt="20px">
                  <HeartAgePanel
                    percentile={this.cad.cad.percentile}
                    age={this.state.age}
                    callbackAgeChange={this.setAge}
                    isEditable={true}
                  />
                </Box>
              )}
            </Box>
          }
          chartsSection={null}
          // chartsSection={
          //   <CadChartsSection
          //     percentile={this.cad.cad.percentile}
          //     quintile={Math.floor(this.cad.cad.percentile / 20)}
          //     oddsRatios={this.cad.cad.odds_list}
          //     deciles={this.cad.cad.deciles}
          //     isMobile={isMobile}
          //     age={this.state.age}
          //     showHeartAge={false}
          //     printing={this.props.printing}
          //     overallRiskPercentile={this.state.overallRiskPercentile ? this.state.overallRiskPercentile + 1 : 1}
          //     overallRiskOddsRatios={this.cad.cad.insights.overall_risk.odds_ratios}
          //     overallRiskDeciles={this.cad.cad.insights.overall_risk.prevalence}
          //   />
          // }
          calculatorSection={
            <Box mt="20px"
                  sx={{
                    '@media print': {
                      pageBreakBefore: 'always;'
                    }
                  }}
            >
              <DemoMyGeneInsightsCadCalculator
                sex={this.clinical.sex ? 1 : 0}
                age={this.state.age}
                weight={this.clinical.weight}
                height={this.clinical.height}
                hypertensive={this.state.hypertensive}
                hypercholesterol={this.clinical.hypercholesterol}
                exercise={this.clinical.exercise}
                diabetes={this.clinical.t2d}
                smoker={this.clinical.smoking}
                cadPercentile={oneBasedPercentile}
                cadScore={this.cad.cad.overall_risk_cad}
                stdizedCadScore={this.cad.cad.std_adj_overall_risk_cad}
                printing={this.props.printing}
                callbackInitRiskPercentile={this.onInitOverallRiskPercentile}
                logReg={cadConsumerLogReg}
                geneticRiskPercentile={oneBasedPercentile}
                sleep={2}
                physicalActivity={3}
                callbackOnUpdateFromWearables={this.onUpdateFromWearables}
                heartRate={70}
                bloodOxygen={98}
              />
            </Box>
          }
          recommendationSection={
            <Box
              sx={{
                '@media print': {
                  pageBreakBefore: 'always'
                }
              }}
            >
              <GxgCadRecommendationSection isMobile={isMobile}/>
            </Box>
          }
          explanatorySection={<CadExplanatorySection isMobile={isMobile} />}
        />
      </Box>
    );
  }
}

export default withNavigation(withMobile(DemoMyGeneInsightsCadRiskReportImpl));
