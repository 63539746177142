// @flow
import React from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Box, Text } from '@chakra-ui/react';
import COLORS, { rgbaToHex } from '../common/colors';
import {getDefaultRiskPlotBands} from "./risk-gauge-utils";
import withMobile from "./mobile/withMobile";

require('highcharts/highcharts-more')(Highcharts);
require('highcharts/modules/solid-gauge')(Highcharts);

export type Line = {
  value: number,
  color: string,
  name: string
}

type Props = {
  callbackGetColor: any,
  callbackGetRiskLevel: any,
  callbackGetOverallRiskLabel: any,
  title?: string,
  maxHeight?: number,
  currentPercentile: number,
  expectedPercentile: number,
  overallRiskTitle?: string,
  showSubtitle?: boolean,
  fixDigits?: number,
  callbackGetPlotBands: any,
  callbackGetOuterPlotBands: any,
  lines?: ?Array<number>,
  isMobile: boolean,
  screenWidth: number
};

function OverallRiskGaugeImpl(props: Props) {
  const maxHeight =
    props.maxHeight && props.maxHeight > 0 ? props.maxHeight : 225;
  const targetRef = React.useRef();
  const defaultWidth = maxHeight * 2;
  const [width, setWidth] = React.useState(defaultWidth > props.screenWidth ? props.screenWidth : defaultWidth);
  // console.log(`width is ${width}`)

  const plotBands = props.callbackGetPlotBands ? props.callbackGetPlotBands() : getDefaultRiskPlotBands();
  const highLimit = plotBands[plotBands.length-1].to;
  const expectedOrHigh = props.expectedPercentile > highLimit ? highLimit : props.expectedPercentile;
  const currentOrHigh = props.currentPercentile > highLimit ? highLimit : props.currentPercentile;

  const updateWidth = () => {
    let newWidth = defaultWidth;
    if (targetRef.current) {
      newWidth = targetRef.current.offsetWidth;
    }
    setWidth(newWidth);
    // console.log(`width is ${newWidth}`)
  };

  React.useEffect(() => {
    window.addEventListener('resize', updateWidth);
    return () => window.removeEventListener('resize', updateWidth);
  });
  const overallRiskLabel = props.callbackGetOverallRiskLabel(
    props.currentPercentile,
    props.expectedPercentile,
    props.fixDigits,
    highLimit
  );
  const riskColor = props.callbackGetColor(props.expectedPercentile);
  let relativeRiskStop1 = currentOrHigh;
  let relativeRiskStop2 = currentOrHigh;
  const diff = expectedOrHigh - currentOrHigh;
  if (diff > 0) {
    relativeRiskStop1 = currentOrHigh;
    relativeRiskStop2 = expectedOrHigh;
  } else if (diff < 0) {
    relativeRiskStop1 = expectedOrHigh;
    relativeRiskStop2 = currentOrHigh;
  }

  const spanStyle = `"font-size:20px; color:${rgbaToHex(riskColor)}"`;
  const spanItem = `<span style=${spanStyle}>${overallRiskLabel}</span><br/>`;
  const item = props.isMobile
    ? `<div>${spanItem}<span style="color:transparent">.</span></div>`
    : `<div>${spanItem}<span style="color:transparent">.</span></div>`;

  const yAxisOptions = [
    {
      min: 0,
      max: highLimit,
      labels: {
        enabled: false
      },
      title: {
        text: props.overallRiskTitle,
        y: width < 400 && !props.isMobile ? width / 8 : 25,
        style: {
          fontWeight: 'bold',
          fontSize: props.isMobile ? '8px' : '14px',
          color: riskColor
        }
      },
      plotBands: plotBands
    },
    {
      pane: 1,
      min: 0,
      max: highLimit,
      labels: {
        enabled: false
      },
      title: {
        y: -25,
        text: '',
        style: {
          fontWeight: 'bold',
          fontSize: '14px',
          color: COLORS.REPORT_TEXT_GRAY
        }
      },
      tickInterval: 0.1,
      minorTickInterval: null,
      tickAmount: 0,
      tickWidth: 0,
      plotBands: [
        {
          from: 0,
          to: relativeRiskStop1,
          color: 'transparent',
          thickness: '2%'
        },
        {
          from: relativeRiskStop1,
          to: relativeRiskStop2,
          color: 'black',
          thickness: '2%'
        },
        {
          from: relativeRiskStop2,
          to: highLimit,
          color: 'transparent',
          thickness: '2%'
        }
      ]
    }
  ]
  if (props.callbackGetOuterPlotBands) {
    yAxisOptions.push({
        pane: 2,
        min: 0,
        max: highLimit,
        lineWidth: 0,
        labels: {
          enabled: false
        },
        tickInterval: 0.1,
        tickPositions: props.lines ?? null,
        tickWidth: 2,
        tickLength: 90,
        tickColor: 'gray',
        minorTickInterval: null,
        plotBands: props.callbackGetOuterPlotBands()
      });
  }


  const options = {
    credits: {
      enabled: false
    },
    chart: {
      type: 'gauge',
      backgroundColor: null,
      style: {
        fontFamily: 'century-gothic'
      },
      marginBottom: 0,
      height: width > 0 && width / 2 < maxHeight ? width / 2 : maxHeight,
      animation: {
        duration: 0
      }
    },
    tooltip: {
      enabled: false
    },

    pane: [
      {
        center: ['50%', '90%'],
        size: '160%',
        startAngle: -90,
        endAngle: 90,
        background: [
          {
            backgroundColor:
              Highcharts.defaultOptions.legend.backgroundColor || '#EEE',
            innerRadius: '60%',
            outerRadius: '100%',
            shape: 'arc'
          }
        ]
      },
      {
        center: ['50%', '90%'],
        size: '92%',
        startAngle: -90,
        endAngle: 90,
        background: [
          {
            backgroundColor:
              Highcharts.defaultOptions.legend.backgroundColor || '#EEE',
            innerRadius: '96%',
            outerRadius: '99%',
            shape: 'arc'
          }
        ]
      },
      {
        center: ['50%', '90%'],
        size: '175%',
        startAngle: -90,
        endAngle: 90,
        background: [
          {
            borderWidth: 0,
            backgroundColor: 'transparent',
            innerRadius: '98%',
            outerRadius: '99%',
            shape: 'arc'
          }
        ]
      }
    ],

    // the value axis
    yAxis: yAxisOptions,
    title: '',
    series: [
      {
        data: [expectedOrHigh],
        dataLabels: {
          borderColor: 'transparent',
          y: -50,
          formatter: () => item
        },
        dial: {
          backgroundColor: COLORS.GRAY_128, // 'black',
          borderColor: 'rgba(192, 192, 192, 0.5)',
          baseLength: '50%',
          borderWidth: 1,
          baseWidth: 2,
          topWidth: 1,
          zIndex: 999
        }
      }
    ],
    plotOptions: {
      series: {
        animation: false
      }
    }
  };
  return (
    <Box ref={targetRef}>
      {props.title && (
        <Text textAlign="center" color="gray.600">
          {props.title}
        </Text>
      )}
      <HighchartsReact highcharts={Highcharts} options={options} />
    </Box>
  );
}

OverallRiskGaugeImpl.propTypes = {
  maxHeight: PropTypes.number,
  overallRiskTitle: PropTypes.string,
  showSubtitle: PropTypes.bool,
  title: PropTypes.string,
  fixDigits: PropTypes.number,
  callbackGetPlotBands: PropTypes.any,
  callbackGetOuterPlotBands: PropTypes.any,
  lines: PropTypes.array
};

OverallRiskGaugeImpl.defaultProps = {
  maxHeight: undefined,
  overallRiskTitle: 'RISK PERCENTILE',
  showSubtitle: true,
  title: undefined,
  fixDigits: 0,
  callbackGetPlotBands: undefined,
  callbackGetOuterPlotBands: undefined,
  lines: undefined
};

export default withMobile(OverallRiskGaugeImpl)
