// @flow
import React, { useCallback, useState } from 'react';

import {
  Box,
  Text,
  Flex,
  Spacer,
  Image,
  Divider,
  IconButton
} from '@chakra-ui/react';
import { AiOutlineDoubleLeft, AiOutlineDoubleRight } from 'react-icons/ai';
import queryString from 'query-string';

import withNavigation from '../../main/WithRoutes';
import htnGaugeImage from '../../images/blood-pressure-gauge-light.png';
import firstAidKit from '../../images/first-aid-kit.png';
import lineChartImage from '../../images/line-chart.png';
import dnaImage from '../../images/dna-ver-bw.png';
import comorbiditiesImage from '../../images/illness.png';
import aiImage from '../../images/ai-bw.png';

import COLORS from '../../common/colors';
import DemoData1 from './data/bp1.json';
import DemoData2 from './data/bp2.json';
import DemoData3 from './data/bp3.json';
import clinical1 from '../data/clinical1.json';
import clinical2 from '../data/clinical2.json';
import clinical3 from '../data/clinical3.json';
import clinical4 from '../data/clinical4.json';
import clinical5 from '../data/clinical5.json';
import RelativeRiskPersonChart from '../../common/RelativeRiskPersonChart';
import TreatmentRecommendation from '../../common/reports/TreatmentRecommendation';
import IconAttribute from '../../common/IconAttributes';
import TrendChart from '../../common/TrendChart';
import { sbpCriteria } from '../../common/colored-section-criteria';
import MedicationHistory from '../../common/MedicationHistory';
import PatientAttributesImpl from '../../common/reports/PatientAttributes';
import { mmolL2mgdl } from '../../utils/cholesterol';

type Props = {
  location: any
};

export function DemoBpTreatReportImpl(props: Props) {
  const [showPatientDetails, setShowPatientDetails] = useState(true);
  const leftBorderShowDetails = showPatientDetails ? '1px' : '0px';

  const togglePatientDetails = useCallback(() => {
    setShowPatientDetails(!showPatientDetails);
  }, [showPatientDetails]);

  const getToggleButton = () => (
    <Box
      as={showPatientDetails ? AiOutlineDoubleRight : AiOutlineDoubleLeft}
      h="18px"
      w="18px"
      mb={0}
    />
  );

  const mainPaneMinW = showPatientDetails ? '76%' : '92%';
  const rightPaneMinW = showPatientDetails ? '22%' : '8%';

  const queryParams = queryString.parse(props.location.search);
  let patientId = 1;
  if (queryParams.id) patientId = parseInt(queryParams.id, 10);
  let bp = DemoData1;
  let clinical = clinical1;
  if (patientId === 2) {
    bp = DemoData2;
    clinical = clinical2;
  } else if (patientId === 3) {
    bp = DemoData3;
    clinical = clinical3;
  } else if (patientId === 4) {
    bp = DemoData3;
    clinical = clinical4;
  } else if (patientId === 5) {
    bp = DemoData3;
    clinical = clinical5;
  }
  const recommendations = {
    treat: 'Consider BETAXOLOL',
    supporting: [
      'SBP level is uncontrolled',
      'using Beta Blocker for patient that is post myocardial infarction',
      'high genetic predisposition to BETAXOLOL',
      'system predicts success in reducing SBP level below 140mmHg'
    ]
  };
  const sbpMeasures = {
    sbp: bp.sbp.measurements
  };
  const comorbidities = clinical.bp_indications.join(', ');
  return (
    <Box color={COLORS.REPORT_TEXT} ml="8%">
      <Flex mt="50px" align="center">
        <Spacer />
        <Text fontSize={24} color="gray.600">
          HYPERTENSION TREATMENT
        </Text>
        <Image src={htnGaugeImage} h="24px" w="24px" ml="20px" />
        <Text mx="8%" fontSize={24} color="red.200" mr="50px">
          ( DEMO )
        </Text>
        <Spacer />
      </Flex>
      <Flex>
        {/* main panel */}
        <Box minW={mainPaneMinW}>
          <Box>
            {bp && (
              <Box mt="40px" mb="20px" color={COLORS.REPORT_TEXT} fontSize={14}>
                {/* BP */}
                <Box
                  fontSize={16}
                  borderWidth={1}
                  borderRadius="10px"
                  borderColor={COLORS.REPORT_TEXT_GRAY}
                  bg="gray.50"
                  mr="30px"
                >
                  <Box p="30px">
                    <TreatmentRecommendation
                      recommendations={recommendations}
                    />
                  </Box>
                </Box>
                {/* <Divider my="20px" /> */}
                <Box mr="30px">
                  <Box my="30px">
                    <Flex align="top">
                      <Text color={COLORS.REPORT_TEXT_GRAY} fontWeight="normal">
                        Supporting information:
                      </Text>
                    </Flex>
                    <Flex my="20px" align="center">
                      <Image src={lineChartImage} h="24px" w="24px" mr="20px" />
                      <Flex minW="35%">
                        <Text mr="20px">SBP level:</Text>
                        <Text color={COLORS.RED_STATUS} fontWeight="bold">
                          uncontrolled
                        </Text>
                      </Flex>
                      <Box mr="5%" maxW="60%">
                        <TrendChart
                          title="SBPs Trend Chart"
                          measurements={sbpMeasures}
                          treatments={[]}
                          yTitle="Level (mmHg)"
                          maxHeight={200}
                          traits={['sbp']}
                          traitSectionCriteria={[sbpCriteria]}
                        />
                      </Box>
                    </Flex>
                    <Flex my="20px" align="center">
                      <Image
                        src={comorbiditiesImage}
                        h="24px"
                        w="24px"
                        mr="20px"
                      />
                      <Flex minW="35%">
                        <Text mr="20px">Comorbidities:</Text>
                        <Text color={COLORS.RED_STATUS} fontWeight="bold">
                          {comorbidities}
                        </Text>
                      </Flex>
                      <Spacer />
                    </Flex>
                    <Flex my="30px" align="center">
                      <Image src={dnaImage} h="24px" w="24px" mr="20px" />
                      <Flex minW="40%">
                        <Text mr="20px">
                          Genetic predisposition to BETAXOLOL:
                        </Text>
                        <Text color={COLORS.GREEN_STATUS} fontWeight="bold">
                          high
                        </Text>
                      </Flex>
                      <RelativeRiskPersonChart
                        percentile={bp.predisposition.betaxolol.percentile + 1}
                        iconHeight={48}
                        iconWidth={24}
                        borderRadius="80px"
                        showText={false}
                        isSmall={false}
                        showBorder
                        reverse
                      />
                      <Spacer />
                    </Flex>
                    <Flex my="20px" align="center">
                      <Image src={aiImage} h="24px" w="24px" mr="20px" />
                      <Flex minW="35%">
                        <Text mr="20px">
                          {'Treatment prediction (SBP < 140)'}:
                        </Text>
                        <Text color={COLORS.GREEN_STATUS} fontWeight="bold">
                          success
                        </Text>
                      </Flex>
                      <Spacer />
                    </Flex>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
          <Divider mb="30px" />
          {/* medication panel */}
          <Flex align="top">
            <Image src={firstAidKit} h="24px" w="24px" mr="20px" />
            <Box>
              <Text mb="10px" color={COLORS.REPORT_TEXT_GRAY}>
                Medication history:
              </Text>
              <MedicationHistory
                medications={clinical.bp_medications}
                fontSize={13}
              />
            </Box>
          </Flex>
          <IconAttribute precedingLine />
        </Box>
        {/* right panel */}
        <Box
          w={rightPaneMinW}
          maxW={rightPaneMinW}
          borderLeftWidth={leftBorderShowDetails}
          borderColor="gray.300"
        >
          <Flex minW="100%">
            <Spacer />
            <IconButton
              variant="transparent"
              colorScheme="gray"
              aria-label="Call Sage"
              fontSize="20px"
              icon={getToggleButton()}
              mr="50px"
              onClick={togglePatientDetails}
              _hover={{
                background: 'gray.200',
                borderColor: 'gray.300',
                borderWidth: 3,
                color: 'white'
              }}
            />
          </Flex>
          {showPatientDetails && (
            <Box mt="-20px">
              <PatientAttributesImpl
                gender={clinical.sex ? 'Male' : 'Female'}
                age={clinical.age}
                bmi={clinical.bmi}
                sbp={clinical.sbp}
                dbp={clinical.dbp}
                hdl={mmolL2mgdl(clinical.hdl)}
                tc={mmolL2mgdl(clinical.tc)}
                smoker={clinical.smoking}
                bpTreated={clinical.bp_treated}
                diabetes={clinical.t2d}
                orderLabs={false}
                title="PATIENT DETAILS"
                mbTitle="20px"
                showBorder={false}
                fontSize={12}
              />
            </Box>
          )}
        </Box>
      </Flex>
    </Box>
  );
}

export default withNavigation(DemoBpTreatReportImpl);
