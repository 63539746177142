// @flow
import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { getPatientId } from './capilots-utils';
import cancerImage from '../images/cancer_bw.png';
import withNavigation from '../main/WithRoutes';
import GeneticReportOrMobile from "../common/reports/GeneticReportOrMobile";
import withMobile from "../common/mobile/withMobile";
import {getObjectUrlFromBase64Data} from "../utils/string";

type Props = {
  location: any,
  params: any,
  visit: Object,
  printing: boolean,
  patientId: ?string,
  loading: boolean,
  isMobile: boolean,
  reportContent: any
};
type State = {};

export class PilotMonogenicCancerRiskReportImpl extends React.Component<
  Props,
  State
> {

  constructor(props: Props) {
    super(props);
    this.patientId = props.patientId ?? getPatientId(props.params, props.location);
  }

  patientId: string;

  render() {
    const {isMobile} = this.props;
    const loading = this.props.loading || !this.props.reportContent;
    const objectURL = getObjectUrlFromBase64Data(this.props.reportContent);
    const pdfDisplayToken = isMobile ? "zoom=auto" : "zoom=100";
    const iframeSrc = objectURL ? `${objectURL}#${pdfDisplayToken}` : null;
    const iframeComp = this.props.reportContent && <iframe src={iframeSrc}
                width={isMobile ? "98%": "100%"} height={isMobile ? "400px" : '650px'}
                title="MONOGENIC CANCER RISK REPORT"
    />

    return (
      <Box>
        {!this.props.printing && <GeneticReportOrMobile
          isDemo={false}
          reportTitle="MONOGENIC CANCER RISK REPORT"
          loading={loading}
          printing={this.props.printing}
          configTrait="monogenicCancer"
          titleImage={cancerImage}
          patientNum={this.props.visit ? this.props.visit.patient_num : null}
          patientName={this.props.visit ? this.props.visit.patient_name : null}
          geneticKitId={this.props.visit ? this.props.visit.kit_id : ''}
          riskPercentile={[]}
          chartsSection={
            <Box m={isMobile ? "1%" : "20px"}>
              {iframeComp}
              {!this.props.reportContent && <Text>Report is not available</Text>}
            </Box>}
          recommendationSection={null}
          explanatorySection={null}
        />}
        {this.props.printing && iframeComp}
      </Box>
    );
  }
}

export default withNavigation(withMobile(PilotMonogenicCancerRiskReportImpl));
