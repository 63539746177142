// @flow
import React from 'react';
import MobileRiskChartsSection from '../../common/reports/MobileRiskChartsSection';

type Props = {
  percentile: number,
  quintile: number,
  oddsRatios: Array<number>,
  deciles: Array<number>
};

export default function MobileLdlChartsSection(props: Props) {
  return (
    <MobileRiskChartsSection
      percentile={props.percentile}
      quintile={props.quintile}
      deciles={props.deciles}
      oddsRatios={props.oddsRatios}
      minY={5}
      maxY={40}
      yTickInterval={5}
      shortTrait="LDL"
      longTrait="high levels of LDL"
    />
  );
}
