// @flow
import React from 'react';

import { Button, Flex, Text, Image } from '@chakra-ui/react';
import PropTypes from "prop-types";
type Props = {
  name: string,
  link: string,
  image: any,
  fullImageButton?: boolean,
  imageW?: string,
  imageH?: string
};

export default function ExternalLinkButtonImpl(props: Props) {
  const { link } = props;
  const onExternalLink: any = React.useCallback(() => {
    window.open(link);
  }, [link]);
  const buttonProps: Object = { ...props };
  buttonProps.onClick = onExternalLink;
  return (
    <Button
      {...buttonProps}
      _hover={{
        background: 'gray.200',
        borderColor: 'gray.300',
        borderWidth: 3,
        color: 'white'
      }}
    >
      {!props.image && props.name}
      {props.image && <Flex align={"center"} minW={"85%"}>
        <Image src={props.image} w={props.imageW ?? "24px"} h={props.imageH ?? "24px"} mr={props.fullImageButton ? 0: "20px"} />
        {!props.fullImageButton && <Text>{props.name}</Text>}
      </Flex>}
    </Button>
  );
}

ExternalLinkButtonImpl.propTypes = {
  fullImageButton: PropTypes.bool,
  imageW: PropTypes.string,
  imageH: PropTypes.string,
};

ExternalLinkButtonImpl.defaultProps = {
  fullImageButton: false,
  imageW: undefined,
  imageH: undefined,
};
