// @flow
import React from 'react';
import LinkButton from '../common/LinkButton';

type Props = {
  to: string,
  text: string
};

export default function TopBarLinkImpl(props: Props) {
  return (
    <LinkButton
      to={props.to}
      color="white"
      bg="transparent"
      fontSize={16}
      fontWeight="400"
    >
      {props.text}
    </LinkButton>
  );
}
