// @flow
import React from 'react';
import { Box } from '@chakra-ui/react';
import DoubleRiskChartsSection from "../common/reports/DoubleRiskChartsSection";
import T2dReportCommon from "./t2d-report-common";
// import T2dBmiEffect from './T2dBmiEffect';

type Props = {
  patientId: string,
  percentile: number,
  quintile: number,
  oddsRatios: Array<number>,
  deciles: Array<number>,
  overallRiskPercentile: number,
  overallRiskOddsRatios: Array<number>,
  overallRiskDeciles: Array<number>,
  printing: ?boolean,
  isMobile: boolean
};

export default function T2dChartsSection(props: Props) {
  let maxYGenetics = 10;
  if (props.percentile > 90) maxYGenetics = 12;
  else if (props.percentile > 81) maxYGenetics = 11;

  let maxYOverall = 9;
  if (props.overallRiskPercentile > 90) maxYOverall = 11;
  else if (props.overallRiskPercentile > 81) maxYOverall = 10;

  return (
    <Box
      sx={{
        '@media print': {
          pageBreakBefore: 'always;'
        }
      }}
    >
      <Box mt={props.printing ? "30px": "0px"}>
        <DoubleRiskChartsSection
          shortTrait="T2D"
          longTrait="type 2 diabetes"
          geneticPercentile={props.percentile}
          geneticDeciles={props.deciles}
          geneticOddsRatios={props.oddsRatios}
          geneticMinY={0}
          geneticMaxY={maxYGenetics}
          geneticYTickInterval={1}
          geneticTrendInfoText={T2dReportCommon.T2D_GENETIC_RISK_IN_POPULATION}
          overallPercentile={props.overallRiskPercentile}
          overallDeciles={props.overallRiskDeciles}
          overallOddsRatios={props.overallRiskOddsRatios}
          overallMinY={0}
          overallMaxY={maxYOverall}
          overallYTickInterval={1}
          overallTrendInfoText={T2dReportCommon.T2D_OVERALL_RISK_IN_POPULATION}
          printing={props.printing}
          isMobile={props.isMobile}
        />
      </Box>
      {/*{props.patientId === 1 && (*/}
      {/*  <Box ml="20px">*/}
      {/*    <T2dBmiEffect fontSize={16} />*/}
      {/*  </Box>*/}
      {/*)}*/}
    </Box>
  );
}
