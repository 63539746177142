import React from 'react';
import RiskChartsSection from "./RiskChartsSection";
import MobileRiskChartsSection from "./MobileRiskChartsSection";
import withMobile from "../mobile/withMobile";

function RiskChartsSectionOrMobileImpl(props) {
  const {isMobile} = props;
  return <>
    {isMobile && (<MobileRiskChartsSection {...props}/>)}
    {!isMobile && (<RiskChartsSection {...props}/>)}
  </>
}

export default withMobile(RiskChartsSectionOrMobileImpl);

