// @flow
import React, { useCallback, useState } from 'react';

import {
  Box,
  Text,
  Flex,
  Spacer,
  Image,
  Divider,
  IconButton
} from '@chakra-ui/react';
import { AiOutlineDoubleLeft, AiOutlineDoubleRight } from 'react-icons/ai';
import queryString from 'query-string';

import withNavigation from '../../main/WithRoutes';
import diabetesImage from '../../images/sugar-blood-level.png';
import firstAidKit from '../../images/first-aid-kit.png';
import lineChartImage from '../../images/line-chart.png';
import dnaImage from '../../images/dna-ver-bw.png';
import classifyImage from '../../images/classiciation.png';

import COLORS from '../../common/colors';
import DemoData1 from './data/t2d1.json';
import DemoData2 from './data/t2d2.json';
import clinical1 from '../data/clinical1.json';
import clinical2 from '../data/clinical2.json';
import RelativeRiskPersonChart from '../../common/RelativeRiskPersonChart';
import DiabetesSubtypeChart from './DiabetesSubtypeChart';
import TreatmentRecommendation from '../../common/reports/TreatmentRecommendation';
import IconAttribute from '../../common/IconAttributes';
import TrendChart from '../../common/TrendChart';
import { a1cCriteriaPercent } from '../../common/colored-section-criteria';
import { mmolmol2percents } from '../../utils/a1c';
import translateMeasurements from '../../utils/translate';
import MedicationHistory from '../../common/MedicationHistory';
import PatientAttributesImpl from '../../common/reports/PatientAttributes';
import { mmolL2mgdl } from '../../utils/cholesterol';

type Props = {
  location: any
};

export function DemoT2dTreatReportImpl(props: Props) {
  const [showPatientDetails, setShowPatientDetails] = useState(true);
  const leftBorderShowDetails = showPatientDetails ? '1px' : '0px';

  const togglePatientDetails = useCallback(() => {
    setShowPatientDetails(!showPatientDetails);
  }, [showPatientDetails]);

  const getToggleButton = () => (
    <Box
      as={showPatientDetails ? AiOutlineDoubleRight : AiOutlineDoubleLeft}
      h="18px"
      w="18px"
      mb={0}
    />
  );

  const mainPaneMinW = showPatientDetails ? '78%' : '92%';
  const rightPaneMinW = showPatientDetails ? '22%' : '8%';

  const queryParams = queryString.parse(props.location.search);
  let patientId = 1;
  if (queryParams.id) patientId = parseInt(queryParams.id, 10);
  const t2d = patientId === 1 ? DemoData1 : DemoData2;
  const clinical = patientId === 1 ? clinical1 : clinical2;
  const recommendations = {
    treat: 'Consider SGLT2-i',
    supporting: [
      'A1C level is uncontrolled',
      'high genetic predisposition to SGLT2-i',
      'high risk for CKD',
      'reduced insulin sensitivity'
    ]
  };
  const a1cMeasures = {
    a1c: translateMeasurements(t2d.a1c, mmolmol2percents)
  };
  return (
    <Box color={COLORS.REPORT_TEXT} ml="8%">
      <Flex mt="50px" align="center">
        <Spacer />
        <Text fontSize={24} color="gray.600">
          TYPE 2 DIABETES MELLITUS TREATMENT
        </Text>
        <Image src={diabetesImage} h="24px" w="24px" ml="20px" />
        <Text mx="8%" fontSize={24} color="red.200" mr="50px">
          ( DEMO )
        </Text>
        <Spacer />
      </Flex>
      <Flex>
        {/* main panel */}
        <Box minW={mainPaneMinW}>
          <Box>
            {t2d && (
              <Box mt="40px" mb="20px" color={COLORS.REPORT_TEXT} fontSize={14}>
                {/* T2D */}
                <Box
                  fontSize={16}
                  borderWidth={1}
                  borderRadius="10px"
                  borderColor={COLORS.REPORT_TEXT_GRAY}
                  bg="gray.50"
                  mr="30px"
                >
                  <Box p="30px">
                    <TreatmentRecommendation
                      recommendations={recommendations}
                    />
                  </Box>
                </Box>
                {/* <Divider my="20px" /> */}
                <Box mr="30px">
                  <Box my="30px">
                    <Flex align="top">
                      <Text color={COLORS.REPORT_TEXT_GRAY} fontWeight="normal">
                        Supporting information:
                      </Text>
                    </Flex>
                    <Flex my="20px" align="center">
                      <Image src={lineChartImage} h="24px" w="24px" mr="20px" />
                      <Flex minW="40%">
                        <Text mr="20px">A1C level:</Text>
                        <Text color={COLORS.RED_STATUS} fontWeight="bold">
                          uncontrolled
                        </Text>
                      </Flex>
                      <TrendChart
                        title="A1C Trend Chart"
                        measurements={a1cMeasures}
                        treatments={[]}
                        yTitle="Level (%)"
                        maxHeight={200}
                        traits={['a1c']}
                        traitSectionCriteria={[a1cCriteriaPercent]}
                      />
                      <Spacer />
                    </Flex>
                    <Flex my="30px" align="center">
                      <Image src={dnaImage} h="24px" w="24px" mr="20px" />
                      <Flex minW="40%">
                        <Text mr="20px">
                          Genetic predisposition to SGLT2-i:
                        </Text>
                        <Text color={COLORS.GREEN_STATUS} fontWeight="bold">
                          high
                        </Text>
                      </Flex>
                      <RelativeRiskPersonChart
                        percentile={t2d.sglt2.percentile + 1}
                        iconHeight={48}
                        iconWidth={24}
                        borderRadius="80px"
                        showText={false}
                        isSmall={false}
                        showBorder
                        reverse
                      />
                      <Spacer />
                    </Flex>
                    <Flex my="20px" align="center">
                      <Image src={dnaImage} h="24px" w="24px" mr="20px" />
                      <Flex minW="40%">
                        <Text mr="20px">Genetic risk for CKD:</Text>
                        <Text color={COLORS.RED_STATUS} fontWeight="bold">
                          high
                        </Text>
                      </Flex>
                      <RelativeRiskPersonChart
                        percentile={t2d.ckd.percentile + 1}
                        iconHeight={48}
                        iconWidth={24}
                        borderRadius="80px"
                        showText={false}
                        isSmall={false}
                        showBorder
                      />
                      <Spacer />
                    </Flex>
                    <Flex my="20px" align="center">
                      <Image src={classifyImage} h="24px" w="24px" mr="20px" />
                      <Flex minW="40%">
                        <Text mr="20px">Subtype classification: </Text>
                        <Text fontWeight={600} color="blue.700">
                          {t2d.t2d.subtype.class}
                        </Text>
                      </Flex>
                      <DiabetesSubtypeChart
                        title="Genetic Subtype Scoring"
                        subTypeScoring={t2d.t2d.subtype.scores}
                      />
                      <Spacer />
                    </Flex>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
          <Divider mb="30px" />
          {/* medication panel */}
          <Flex align="top">
            <Image src={firstAidKit} h="24px" w="24px" mr="20px" />
            <Box>
              <Text mb="10px" color={COLORS.REPORT_TEXT_GRAY}>
                Medication history:
              </Text>
              <MedicationHistory
                medications={clinical.t2d_medications}
                fontSize={13}
              />
            </Box>
          </Flex>
          <IconAttribute precedingLine />
        </Box>
        {/* right panel */}
        <Box
          w={rightPaneMinW}
          maxW={rightPaneMinW}
          borderLeftWidth={leftBorderShowDetails}
          borderColor="gray.300"
        >
          <Flex minW="100%">
            <Spacer />
            <IconButton
              variant="transparent"
              colorScheme="gray"
              aria-label="Call Sage"
              fontSize="20px"
              icon={getToggleButton()}
              mr="50px"
              onClick={togglePatientDetails}
              _hover={{
                background: 'gray.200',
                borderColor: 'gray.300',
                borderWidth: 3,
                color: 'white'
              }}
            />
          </Flex>
          {showPatientDetails && (
            <Box mt="-20px">
              <PatientAttributesImpl
                gender={clinical.sex ? 'Male' : 'Female'}
                age={clinical.age}
                bmi={clinical.bmi}
                sbp={clinical.sbp}
                dbp={clinical.dbp}
                hdl={mmolL2mgdl(clinical.hdl)}
                tc={mmolL2mgdl(clinical.tc)}
                smoker={clinical.smoking}
                bpTreated={clinical.bp_treated}
                diabetes={clinical.t2d}
                orderLabs={false}
                title="PATIENT DETAILS"
                mbTitle="20px"
                showBorder={false}
                fontSize={12}
              />
            </Box>
          )}
        </Box>
      </Flex>
    </Box>
  );
}

export default withNavigation(DemoT2dTreatReportImpl);
