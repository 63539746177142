// @flow
import React from 'react';
import RiskChartsSection from '../../common/reports/RiskChartsSection';

type Props = {
  percentile: number,
  quintile: number,
  deciles: Array<number>,
  oddsRatios: Array<number>
};

export default function StrokeChartsSection(props: Props) {
  return (
    <RiskChartsSection
      percentile={props.percentile}
      quintile={props.quintile}
      deciles={props.deciles}
      oddsRatios={props.oddsRatios}
      minY={0.8}
      maxY={1.25}
      yTickInterval={0.1}
      shortTrait="Stroke"
      longTrait="stroke"
      decimalPoints={2}
    />
  );
}
