// @flow
import React, {useEffect} from 'react';
import {Box, Text} from '@chakra-ui/react';
import COLORS from '../common/colors';
import withNavigation from "../main/WithRoutes";
import {useAuthenticator} from "@aws-amplify/ui-react";

type Props = {
  version: string,
  params: any,
  location: any
};

export function TestInfoUpdatePageImpl(props: Props) {
  const { user } = useAuthenticator((context) => [context.user]);

  useEffect(() => {
    async function returnToInfoPage() {
      window.location.href = window.location.href.replace("/update?access=true", "?access=true");
    }
    if (user) {
      returnToInfoPage()
          .then()
          .catch((e) => {
            console.log(e);
          })
    }
  }, [user]);  // eslint-disable-line


  return (
    <Box color={COLORS.REPORT_TEXT} mx="8%" mt="50px" >
      <Text mb={"10px"} color={"gray.600"}>{"loading, please wait..."}</Text>
    </Box>
  );
}

export default withNavigation(TestInfoUpdatePageImpl);
