// @flow
import React from 'react';
import { Box } from '@chakra-ui/react';
import { getPatientId } from '../filters';
import cancerImage from '../../images/cancer_bw.png';
import withNavigation from '../../main/WithRoutes';
import colorectalReportCommon from "../../colorectal/colorectal-report-common";
import GeneticReportOrMobile from "../../common/reports/GeneticReportOrMobile";
import withMobile from "../../common/mobile/withMobile";

type Props = {
  location: any,
  params: any,
  printing: boolean,
  isMobile: boolean
};
type State = {};

export class DemoMonogenicCancerRiskReportImpl extends React.Component<
  Props,
  State
> {

  constructor(props: Props) {
    super(props);
    this.patientId = getPatientId(props.params, props.location);
  }

  patientId: number;

  render() {
    const {isMobile} = this.props;
    return (
      <Box>
        <GeneticReportOrMobile
          reportTitle="MONOGENIC CANCER RISK REPORT"
          configTrait="monogenicCancer"
          titleImage={cancerImage}
          patientNum={this.patientId}
          geneticKitId={`OD000${this.patientId}`}
          geneticRiskInfoText={[
            colorectalReportCommon.GENETIC_RISK_COLORECTAL_INFO_TEXT
          ]}
          printing={false}
          riskPercentile={[]}
          chartsSection={
            <Box m={isMobile ? 0 : "20px"}>
              <iframe src="/CGX_MYDNA_report.pdf#zoom=40"
                      width="100%"
                      height="650px"
                      title="PDF Viewer"
              />
            </Box>}
          recommendationSection={null}
          explanatorySection={null}
        />
      </Box>
    );
  }
}

export default withNavigation(withMobile(DemoMonogenicCancerRiskReportImpl));
