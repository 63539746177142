// @flow

const BreastCancerReportStrings = {
  WHAT_IS_BREAST_CANCER_TEXT1:
    'Breast cancer is a type of cancer that starts in the breast. It can start in one or both breasts. ' +
    'Cancer starts when cells begin to grow out of control. Breast cancer occurs almost entirely in women. ' +
    'It’s important to understand that most breast lumps are benign and not cancer ' +
    '(malignant). Non-cancer breast tumors are abnormal growths, but they do not spread ' +
    'outside of the breast. They are not life threatening, but some types of benign breast ' +
    "lumps can increase a woman's risk of getting breast cancer. Any breast lump or change " +
    'needs to be checked by a health care professional to find out if it is benign or malignant ' +
    '(cancer) and if it might affect yours future cancer risk.',
  WHAT_IS_BREAST_CANCER_TEXT2:
    'Breast cancer is the most common cancer in women in the United States, except for ' +
    'skin cancers. It is about 30% of all new female cancers each year.',
  WHAT_IS_BREAST_CANCER_REF1:
    'DeSantis CE, Ma J, Goding Sauer A, Newman LA, Jemal A. Breast cancer statistics, 2017, ' +
    'racial disparity in mortality by state. CA Cancer J Clin. 2017 Nov;67(6):439-448. doi: 10.3322/caac.21412. Epub 2017 Oct 3. PMID: 28972651.',
  HOW_DID_WE_CALC_TEXT1:
    'OpenDNA calculates your score based on analysis of your ' +
    'genetic variants. Genetic variation is the difference in DNA ' +
    'sequences between individuals within a population. These are the ' +
    'differences in DNA that make every individual different. OpenDNA ' +
    'scans 265 significant genetic variants and calculates a ' +
    'Polygenic Risk Score (PRS) based on the contribution of each ' +
    'genetic variant to your genetic risk for developing breast cancer.',
  HOW_DID_WE_CALC_TEXT2:
    'Once your PRS is calculated it is compared ' +
    'to a group of about 181,000 women to ' +
    'calculate your relative risk of developing breast cancer ' +
    'compared to that population.',
  HOW_DID_WE_CALC_REF1:
    'Mavaddat N, Michailidou K, Dennis J, et al. ' +
    'Polygenic Risk Scores for Prediction of Breast Cancer and Breast Cancer Subtypes. ' +
    '2019 Jan 3;104(1):21-34. doi: 10.1016/j.ajhg.2018.11.002.',
  DISCLAIMER1:
    'THIS REPORT MAY NOT INCLUDE EVERY GENETIC MARKER RELATED TO BREAST CANCER.',
  DISCLAIMER2:
    'THIS RISK REPORT USES POLYGENIC RISK SCORE FOR BREAST CANCER AND DOES NOT INCLUDE A REPORT OF THE BRCA1 OR BRCA2 VARIANT RISK MUTATIONS.',
  DISCLAIMER3:
    'THIS TEST IS NOT DIAGNOSTIC, IT IS A PREDICTION BASED ON PREDISPOSITION. ' +
    'AN INCREASED RISK SCORE DOES NOT MEAN THAT A PERSON WILL DEFINITELY DEVELOP A DISEASE ' +
    'AND A DECREASED RISK SCORE DOES NOT MEAN THAT A PERSON WILL DEFINITELY NOT DEVELOP A DISEASE.',
  DISCLAIMER4:
    'THE TEST CANNOT REPLACE MEDICAL TESTING OR MEDICAL MANAGEMENT THROUGH A HEALTHCARE PROVIDER.',
  DISCLAIMER5:
    'THE RISK MAY ALSO BE INFLUENCED BY OTHER LIFESTYLE FACTORS, OTHER CLINICAL FACTORS AND ' +
    'GENETIC VARIANTS THAT ARE NOT INCLUDED IN THE ANALYSIS OR WERE NOT REPORTED PROPERLY.',
  DISCLAIMER6:
    'YOUR RISK IS COMPARED TO A POPULATION WHERE THE MAJORITY IS OF EUROPEAN DESCENT. ' +
    'THE ACCURACY OF THE RESULTS MAY VARY IF YOU HAVE A DIFFERENT BACKGROUND.',
  DISCLAIMER7:
    'TEST RESULTS SHOULD BE INTERPRETED BY A HEALTHCARE PROVIDER. MEDICAL MANAGEMENT AND ' +
    'DECISION-MAKING FOR PREVENTION PRACTICES SHOULD BE DONE IN THE CONTEXT OF THE PATIENT’S ' +
    'FULL CLINICAL HISTORY AND NOT BASED SOLELY ON THE TEST RESULTS.',
  GENETIC_RISK_BREAST_INFO_TEXT:
    'The polygenic risk score (PRS) of the patient is calculated by analyzing ' +
    '265 variants known to be associated with breast cancer. ' +
    'The PRS is then compared to a reference scale, built from scores of ~181K individuals. ' +
    'The scale is divided into percentiles that reflect the genetic risk level. ' +
    "The patient risk percentile is determined by comparing the individual's PRS value against the threshold risk values of each percentile.",
  BREAST_RISK_IN_POPULATION:
    'The graph shows the risk for having breast cancer vs. the genetic risk level. Genetic risk (PRS) was calculated for ' +
    'more than 181K individuals. The scores were divided into deciles and the risk of having breast cancer was calculated for each decile. ' +
    "Gray bars indicate the risk level per decile. The highlighted bar represents patient risk level based on the individual's genetic risk.",
  BREAST_RISK_VS_AVERAGE:
    'The graph shows patient risk level for having breast cancer vs. the average risk in the population. ' +
    "The risk of the patient is determined based on the individual's genetic risk level. "
};

export default BreastCancerReportStrings;
