import React from 'react';
import Calculator from "./Calculator";
import MobileCalculator from "./MobileCalculator";
import withMobile from "../mobile/withMobile";

function CalculatorOrMobileImpl(props) {
  const {isMobile} = props;
  return <>
    {!isMobile && (<Calculator {...props}/>)}
    {isMobile && (<MobileCalculator {...props}/>)}
  </>
}

export default withMobile(CalculatorOrMobileImpl);
