// @flow
import React from 'react';
import colorectalReportCommon from "./colorectal-report-common";
import RiskChartsSectionOrMobile from "../common/reports/RiskChartsSectionOrMobile";

type Props = {
  percentile: number,
  quintile: number,
  deciles: Array<number>,
  oddsRatios: Array<number>,
  printing: boolean,
};

export default function ColorectalChartsSection(props: Props) {
  return (
     <RiskChartsSectionOrMobile
        percentile={props.percentile}
        quintile={props.quintile}
        deciles={props.deciles}
        oddsRatios={props.oddsRatios}
        minY={1.8}
        maxY={props.printing ? 4.0 : 3.6}
        yTickInterval={0.2}
        shortTrait="Colorectal cancer"
        longTrait="colorectal cancer"
        absRiskTrendInfoText={colorectalReportCommon.COLORECTAL_RISK_IN_POPULATION}
        riskVsAvgInfoText={colorectalReportCommon.COLORECTAL_RISK_VS_AVERAGE}
        printing={props.printing}
     />
  );
}
