// @flow
import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import COLORS from './colors';
import { calcAbsRisk, calcAvgPrevalence } from '../utils/absolute_risk';

require('highcharts/modules/annotations')(Highcharts);

type Props = {
  xTitle: ?string,
  yTitle: string,
  title: string,
  seriesData: Array<number>,
  seriesName: string,
  oddsRatios: Array<number>,
  annotationX: number,
  annotationName: string,
  annotationColor: string,
  height: number,
  minY: number,
  maxY: number,
  yTickInterval: number,
  isMobile?: boolean,
  tooltipDecimalPoints?: number,
  gridlineWidth?: number,
  printing: ?boolean
};

type State = {};

export default class AbsRiskTrendChart extends React.Component<Props, State> {
  static defaultProps = {
    isMobile: false,
    tooltipDecimalPoints: 1,
    gridlineWidth: 1,
    printing: false
  };
  constructor(props: Props) {
    super(props);
    (this: any).prepareDatasets = this.prepareDatasets.bind(this);
    (this: any).prepareAnnotations = this.prepareAnnotations.bind(this);
    (this: any).getAnnotationXPosition = this.getAnnotationXPosition.bind(this);
    (this: any).getRoundedYtickValue = this.getRoundedYtickValue.bind(this);

    this.yUnit = this.props.yTickInterval / 5;
    this.yTickRoundingFactor = this.getYtickRoundingFactor();
    this.seriesData = this.calcQuintileSeries();
  }

  getAnnotationXPosition() {
    const interval = 100 / this.props.oddsRatios.length;
    let lowX = 10;
    let highX = 90;
    if (this.props.isMobile) {
      lowX = 20;
      highX = 80;
    }
    if (this.props.annotationX >= lowX && this.props.annotationX <= highX)
      return (
        Math.floor(this.props.annotationX / interval) * interval +
        Math.floor(interval / 2)
      );
    if (this.props.annotationX < lowX) return lowX;
    return highX;
  }

  getRoundedYtickValue(val: number) {
    return (
      Math.round(val * this.yTickRoundingFactor) / this.yTickRoundingFactor
    );
  }

  getYtickRoundingFactor() {
    let multiple = 1;
    for (let i = 0; i < 5; i += 1) {
      multiple = 10 ** i;
      if (
        this.props.yTickInterval ===
        Math.floor(this.props.yTickInterval * multiple) / multiple
      )
        break;
    }
    return multiple;
  }

  calcQuintileSeries() {
    const seriesData = [];
    for (let i = 0; i < 5; i += 1) {
      seriesData.push(
        (this.props.seriesData[i * 2] + this.props.seriesData[i * 2 + 1]) / 2
      );
    }
    return seriesData;
  }

  prepareDatasets() {
    const data: Array<Object> = [];
    const numItems = this.props.oddsRatios.length;
    const interval = 100 / numItems;
    const riskQuantileIndex = Math.floor(this.props.annotationX / interval);

    const prevalenceEntireCohort = calcAvgPrevalence(this.props.seriesData);
    for (let i = 0; i < numItems; i += 1) {
      const dataItem = {
        x: i * interval + interval / 2,
        y: calcAbsRisk(this.props.oddsRatios[i], prevalenceEntireCohort) * 100,
        color:
          i === riskQuantileIndex
            ? this.props.annotationColor
            : COLORS.GRAY_128_TRANSPARENCY_0_2,
        dataLabels: i === riskQuantileIndex ? {
          enabled: true,
          inside: true,
          style: {
            fontWeight: 0.1,
            textOutline: 'transparent',
            strokeWidth: 1,
            color: 'white'
          },
          format: '{point.y:.1f}%',
        } : undefined
      }
      data.push(dataItem);
    }
    return [
      {
        type: 'column',
        groupPadding: 0,
        pointPadding: 0,
        // pointStart: -5,
        // relativeXValue: true,
        // pointInterval: 20,
        name: this.props.seriesName,
        color: COLORS.GRAY_128_TRANSPARENCY_0_2,
        dataLabels: {
          enabled: true,
          inside: true,
          style: {
            fontWeight: 0.1,
            textOutline: 'transparent',
            strokeWidth: 1,
            color: COLORS.REPORT_TEXT_GRAY
          },
          format: '{point.y:.1f}%',
        },
        data
      },
      {
        name: this.props.seriesName,
        type: 'spline',
        color: COLORS.REPORT_TEXT_GRAY,
        data
      }
    ];
  }

  prepareAnnotations() {
    const labels = [];
    const shapes = [];
    const interval = 100 / this.props.oddsRatios.length;

    // find y of annotation x
    const x = Math.floor(this.props.annotationX / interval);
    const prevalenceEntireCohort = calcAvgPrevalence(this.props.seriesData);
    const y =
      calcAbsRisk(this.props.oddsRatios[x], prevalenceEntireCohort) * 100;
    const yNext = x < this.props.oddsRatios.length ? calcAbsRisk(this.props.oddsRatios[x+1], prevalenceEntireCohort) * 100 : y;
    labels.push({
      point: {
        x: this.getAnnotationXPosition(),
        y: x <= 0 && y < yNext ? yNext - this.props.yTickInterval : y,
        xAxis: 0,
        yAxis: 0
      },
      text: this.props.annotationName,
      shape: 'rect',
      overflow: 'none',
      backgroundColor: this.props.annotationColor,
      borderColor: COLORS.REPORT_TEXT_GRAY,
      borderWidth: this.props.isMobile ? 0 : 0.5,
      padding: this.props.isMobile ? 1 : 5,
      style: {
        color: 'white'
      }
    });
    const yLineTop =
      calcAbsRisk(
        this.props.oddsRatios[Math.floor(this.props.oddsRatios.length / 2)],
        prevalenceEntireCohort
      ) * 100;
    let xAvg = x !== 5 ? 56 : 44;
    if (this.props.isMobile) xAvg = x !== 5 ? 58 : 42;
    let yAvgLabel = this.props.isMobile
      ? yLineTop - this.yUnit * 10
      : yLineTop - this.yUnit * 6;
    if (yAvgLabel < this.props.minY)
      yAvgLabel = this.props.minY + this.props.yTickInterval / 4;
    labels.push({
      point: {
        x: xAvg,
        y: yAvgLabel,
        xAxis: 0,
        yAxis: 0
      },
      text: 'Average',
      shape: 'rect',
      overflow: 'none',
      backgroundColor: 'transparent',
      verticalAlign: 'middle',
      borderWidth: 0,
      style: {
        color: COLORS.REPORT_TEXT_GRAY
      }
    });

    shapes.push({
      fill: 'none',
      stroke: COLORS.REPORT_TEXT_GRAY,
      strokeWidth: 2,
      type: 'path',
      points: [
        {
          x: 50,
          y: this.props.minY,
          xAxis: 0,
          yAxis: 0
        },
        {
          x: 50,
          y: yLineTop - this.yUnit * 2,
          xAxis: 0,
          yAxis: 0
        },
        {
          x: 49,
          y: yLineTop - this.yUnit * 2,
          xAxis: 0,
          yAxis: 0
        },
        {
          x: 50,
          y: yLineTop,
          xAxis: 0,
          yAxis: 0
        },
        {
          x: 51,
          y: yLineTop - this.yUnit * 2,
          xAxis: 0,
          yAxis: 0
        },
        {
          x: 50,
          y: yLineTop - this.yUnit * 2,
          xAxis: 0,
          yAxis: 0
        }
      ]
    });
    return [
      {
        labels,
        shapes,
        draggable: ''
      }
    ];
  }

  yUnit: number;
  yTickRoundingFactor: number;
  seriesData: Array<number>;

  render() {
    const tooltipDecimalPoints = this.props.tooltipDecimalPoints
      ? this.props.tooltipDecimalPoints
      : 1;
    const yTicks: Array<number> = [];
    const tickPositioner = Array.from(Array(6).keys()).map(i => i * 20);
    for (
      let i = this.props.minY;
      i <= this.props.maxY;
      i += this.props.yTickInterval
    ) {
      yTicks.push(this.getRoundedYtickValue(i));
    }
    const upperX = 100;
    const options = {
      credits: {
        enabled: false
      },
      chart: {
        backgroundColor: 'transparent',
        height: this.props.height,
        width: this.props.printing ? 420 : undefined,
        marginLeft: 50
      },
      title: {
        text: this.props.title,
        style: {
          color: COLORS.HIGHCHARTS_TITLE,
          fontSize: '12px',
          fontWeight: 'bold'
        }
      },
      xAxis: {
        min: 0,
        max: upperX,
        gridLineWidth: this.props.gridlineWidth,
        title: {
          text: this.props.xTitle
        },
        tickPositioner() {
          return tickPositioner;
        }
      },
      yAxis: {
        min: this.props.minY,
        max: this.props.maxY,
        gridLineWidth: this.props.gridlineWidth,
        title: {
          text: this.props.yTitle
        },
        tickPositioner() {
          return yTicks;
        },
        opposite: false
      },
      legend: false,
      series: this.prepareDatasets(),
      annotations: this.prepareAnnotations(),
      tooltip: {
        formatter() {
          return `percentile=<b>${this.x.toFixed(0)}</b>, ${
            this.series.name
          }=<b>${this.y ? this.y.toFixed(tooltipDecimalPoints) + '%' : this.y}</b>`;
        }
      },
      plotOptions: {
        series: {
          animation: !this.props.printing
        }
      }
    };
    return <HighchartsReact highcharts={Highcharts} options={options} />;
  }
}

