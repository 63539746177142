// @flow

const MelanomaReportStrings = {
  WHAT_IS_MELANOMA_TEXT1:
    'Melanoma is a type of skin cancer that develops when melanocytes (the cells that give the skin its tan or ' +
    'brown color) start to grow out of control. Cancer starts when cells in the body begin to grow out of control. ' +
    'Cells in nearly any part of the body can become cancer, and can then spread to other areas of the body.',
  WHAT_IS_MELANOMA_TEXT2:
    'Melanoma is much less common than some other types of skin cancers. ' +
    'But melanoma is more dangerous because it’s much more likely to spread to other parts of the body if not ' +
    'caught and treated early.',
  HOW_DID_WE_CALC_TEXT1:
    'OpenDNA calculates your score based on analysis of your ' +
    'genetic variants. Genetic variation is the difference in DNA ' +
    'sequences between individuals within a population. These are the ' +
    'differences in DNA that make every individual different. OpenDNA ' +
    'scans 8109 genetic variants and calculates a ' +
    'Polygenic Risk Score (PRS) based on the contribution of each ' +
    'genetic variant to your genetic risk for developing melanoma cancer.',
  HOW_DID_WE_CALC_TEXT2:
    'Once your PRS is calculated it is compared ' +
    'to a group of about 339,000 people to ' +
    'calculate your relative risk of developing melanoma cancer ' +
    'compared to that population.',
  HOW_DID_WE_CALC_REF1:
    'Landi, M.T., Bishop, D.T., MacGregor, S. et al. Genome-wide association meta-analyses combining multiple risk ' +
    'phenotypes provide insights into the genetic architecture of cutaneous melanoma susceptibility. ' +
    'Nat Genet 52, 494–504 (2020). https://doi.org/10.1038/s41588-020-0611-8',
  DISCLAIMER1:
    'THIS REPORT MAY NOT INCLUDE EVERY GENETIC MARKER RELATED TO MELANOMA CANCER.',
  DISCLAIMER2:
    'THIS TEST IS NOT DIAGNOSTIC, IT IS A PREDICTION BASED ON PREDISPOSITION. ' +
    'AN INCREASED RISK SCORE DOES NOT MEAN THAT A PERSON WILL DEFINITELY DEVELOP A DISEASE ' +
    'AND A DECREASED RISK SCORE DOES NOT MEAN THAT A PERSON WILL DEFINITELY NOT DEVELOP A DISEASE.',
  DISCLAIMER3:
    'THE TEST CANNOT REPLACE MEDICAL TESTING OR MEDICAL MANAGEMENT THROUGH A HEALTHCARE PROVIDER.',
  DISCLAIMER4:
    'THE RISK MAY ALSO BE INFLUENCED BY OTHER LIFESTYLE FACTORS, OTHER CLINICAL FACTORS AND ' +
    'GENETIC VARIANTS THAT ARE NOT INCLUDED IN THE ANALYSIS OR WERE NOT REPORTED PROPERLY.',
  DISCLAIMER5:
    'YOUR RISK IS COMPARED TO A POPULATION WHERE THE MAJORITY IS OF EUROPEAN DESCENT. ' +
    'THE ACCURACY OF THE RESULTS MAY VARY IF YOU HAVE A DIFFERENT BACKGROUND.',
  DISCLAIMER6:
    'TEST RESULTS SHOULD BE INTERPRETED BY A HEALTHCARE PROVIDER. MEDICAL MANAGEMENT AND ' +
    'DECISION-MAKING FOR PREVENTION PRACTICES SHOULD BE DONE IN THE CONTEXT OF THE PATIENT’S ' +
    'FULL CLINICAL HISTORY AND NOT BASED SOLELY ON THE TEST RESULTS.',
  GENETIC_RISK_MELANOMA_INFO_TEXT:
    'The polygenic risk score (PRS) of the patient is calculated by analyzing ' +
    '8109 variants known to be associated with melanoma cancer. ' +
    'The PRS is then compared to a reference scale, built from scores of ~339K individuals. ' +
    'The scale is divided into percentiles that reflect the genetic risk level. ' +
    "The patient risk percentile is determined by comparing the individual's PRS value against the threshold risk values of each percentile.",
  MELANOMA_RISK_IN_POPULATION:
    'The graph shows the risk for having melanoma cancer vs. the genetic risk level. Genetic risk (PRS) was calculated for ' +
    'more than 339K individuals. The scores were divided into deciles and the risk of having melanoma cancer was calculated for each decile. ' +
    "Gray bars indicate the risk level per decile. The highlighted bar represents patient risk level based on the individual's genetic risk.",
  MELANOMA_RISK_VS_AVERAGE:
    'The graph shows patient risk level for having melanoma cancer vs. the average risk in the population. ' +
    "The risk of the patient is determined based on the individual's genetic risk level. "
};

export default MelanomaReportStrings;
