const ConsentTexts = {
  MAIN_TITLE:
    'INFORMED CONSENT FOR POLYGENIC RISK SCORE ASSESSMENT',
  BODY: [
		{
			Title: 'Introduction',
			Content:
				'This consent form discusses the use of a Polygenic Risk Score (PRS) assessment.\n' +
				'This testing will be done through OpenDNA. This is a type of genetic test that estimates your genetic\n' +
				'predisposition to certain conditions, like cardiovascular disease, based on the analysis of multiple genetic\n' +
				'variants. The purpose of this document is to provide you with the information you need to make\n' +
				'an informed decision about undergoing this testing.'
		},
		{
			Title: 'Procedure',
			Content:
				'A sample (usually buccal swab) will be collected from you. This sample will be analyzed in a laboratory to\n' +
				'determine your Polygenic Risk Score.'
		},
		{
			Title: 'Benefits',
			Content:
				'The potential benefits of PRS assessment include providing information about your genetic risk for certain\n' +
				'conditions. This information could guide preventive measures and potential treatments. Your PRS assessment\n' +
				'will be used to assess your risk for developing cardiovascular disease in the future.'
		},
		{
			Title: 'Limitations and Risks',
			Content:
				'PRS is a rough estimate of risk, not a definitive prediction. The tests are not diagnostic, but present a prediction\n' +
				'based on predisposition. The reports may not include every genetic marker related to each of the disease.\n' +
				'It is one of many tools used in your overall health evaluation and does not replace other health screenings or\n' +
				'lifestyle considerations. Apart from genetic factors, environmental and lifestyle factors will have a strong effect on\n' +
				'your lifetime risk of developing a particular condition. Risks may be influenced by lifestyle factors, clinical factors\n' +
				'and genetic variants that are not included in the analysis or were not reported properly.\n\n' +
				'An elevated risk estimate does not mean that you will develop any of the tested conditions during your lifetime,\n' +
				'while a moderate or lower risk does not mean that the probability of developing the disease is zero and an\n' +
				'increased risk score does not mean that you will develop a disease.\n\n' +
				'The risk score tests are based on the most up-to-date scientific data, which may, however, be supplemented and\n' +
				'changed in the future as additional information becomes available. The field of genetics is constantly evolving,\n' +
				'which may lead to changes in risk assessments over time, as well as changes in test selection recommendations\n' +
				'and clinical recommendations based on test results.\n' +
				'The tests cannot replace other medical testing or medical management through a healthcare provider. Test\n' +
				'results should be interpreted by a healthcare provider. Medical management and decision-making prevention\n' +
				'practices should be done in the context of the patient’s full clinical history and not based solely on the test\n' +
				'results.\n\n' +
				'Your PRS assessment might generate uncertain or unexpected results, which could cause worry or distress.\n' +
				'Like any other form of medical data, contained in your PRS assessment could affect your ability to obtain certain\n' +
				'types of insurance. The accuracy of the results may vary if you have a different ethnic background. If you are not\n' +
				'of European ancestry, your PRS assessment may be less accurate. Finally, while a PRS assessment does not\n' +
				'directly assess the risk of your relatives, genetic information might also have implications for family members, as\n' +
				'genetic traits can be inherited.'
		},
		{
			Title: 'Privacy',
			Content:
				'We are committed to protecting your privacy. Your genetic information will be kept confidential and used for\n' +
				'medical purposes only. It will not be shared without your explicit permission. You understand that by signing this\n' +
				'consent form, your DNA will be analyzed to generate a PRS assessment. You also understand that your PRS\n' +
				'assessment will be stored in a secure database and that it will be used only for management of your personal\n' +
				'health. However, OpenDNA may include your data without your identifying information to help refine the test\'s\n' +
				'performance over time and with for approved research purposes. De-identified means that we strip the data of\n' +
				'information that could directly identify you (such as name, email address) and connect any study information you\n' +
				'provide by using a random code. Any Research Information you allow us to share will be associated with that\n' +
				'code, not with your name.'
		},
		{
			Title: 'Cost and Reimbursement',
			Content:
				'You have the right to speak with a genetic counselor or other health care professional to understand the\n' +
				'implications of this test before and after you receive your results. You also have the right and opportunity to ask\n' +
				'any questions of your medical provider about PRS assessments and their implications and may. also decline this\n' +
				'testing at any time.'
		}
	]
};

export default ConsentTexts;
