// @flow
import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import withNavigation from '../main/WithRoutes';
import FollowUpTrendChart from '../common/FollowUpTrendChart';
import {
  cadOverallRiskCriteria,
  t2dOverallRiskCriteria,
  htnOverallRiskCriteria,
  cholOverallRiskCriteria,
  ldlMldlCriteria,
  sbpCriteria,
  bmiCriteria,
  a1cCriteria,
  hdlMldlCriteria,
  dbpCriteria
} from '../common/colored-section-criteria';
import t2d from './data/t2d.json';
import cad from './data/cad.json';
import htn from './data/htn.json';
import chol from './data/chol.json';
import ldl from './data/ldl.json';
import hdl from './data/hdl.json';
import sbp from './data/sbp.json';
import dbp from './data/dbp.json';
import a1c from './data/a1c.json';
import bmi from './data/bmi.json';
import COLORS from '../common/colors';
import MobileReportHeaderBar from "../common/reports/MobileReportHeaderBar";
import withMobile from "../common/mobile/withMobile";

type Props = {
  isMobile: boolean
}

export function DemoLongTermFollowUpImpl(props: Props) {
  const measurements = {
    T2D: t2d.overallRisk,
    CAD: cad.overallRisk,
    HTN: htn.overallRisk,
    HCHOL: chol.overallRisk,
    LDL: ldl.measurements,
    HDL: hdl.measurements,
    SBP: sbp.measurements,
    DBP: dbp.measurements,
    BMI: bmi.measurements,
    A1C: a1c.measurements
  };
  const {isMobile} = props;
  return (
    <Box mx={isMobile ? "1%" : "7%"} mt={isMobile ? "10px" : "50px"}>
      {isMobile && <MobileReportHeaderBar
            reportTitle={"CARDIOMETABOLIC RISK"}
            image={null}
            isDemo={true}
            hideRiskReportText={true}
          />}
      {!isMobile && <Text
        color={isMobile ? COLORS.REPORT_TEXT : "gray.600"}
        fontSize={isMobile ? 18 : 24}
        fontWeight="normal"
        ml="20px"
      >
        CARDIOMETABOLIC RISK
      </Text>}
      <FollowUpTrendChart
        title={null}
        measurements={measurements}
        treatments={[]}
        isMobile={isMobile}
        traits={[
          'CAD',
          'T2D',
          'HTN',
          'HCHOL',
          'HDL',
          'LDL',
          'SBP',
          'DBP',
          'BMI',
          'A1C'
        ]}
        traitSectionCriteria={[
          cadOverallRiskCriteria,
          t2dOverallRiskCriteria,
          htnOverallRiskCriteria,
          cholOverallRiskCriteria,
          hdlMldlCriteria,
          ldlMldlCriteria,
          sbpCriteria,
          dbpCriteria,
          bmiCriteria,
          a1cCriteria
        ]}
      />
    </Box>
  );
}

export default withNavigation(withMobile(DemoLongTermFollowUpImpl));
