// @flow
export default function capitalizeFirstLetter(
  s: ?string,
  lowerAllOthers: boolean = true
) {
  if (!s || s.length === 0) return s;
  return (
    s.charAt(0).toUpperCase() +
    (lowerAllOthers ? s.slice(1).toLowerCase() : s.slice(1))
  );
}

export function reverseString(s: string) {
  return s.split('').reverse().join('');
}

export function asciiToBase64(asciiString: string) {
	let str = "";
	const utf8Array = new TextEncoder().encode(asciiString);
	const len = utf8Array.byteLength;
	for (let i = 0; i < len; i++) {
		str += String.fromCharCode(utf8Array[i]);
	}
  return str;
}

export function getObjectUrlFromBase64Data(base64data: ?string) {
  if (!base64data) return null;

  // prepare object to display
  const binaryData = window.atob(base64data);
  const uint8Array = new Uint8Array(binaryData.length);
  for (let i = 0; i < binaryData.length; i++) {
    uint8Array[i] = binaryData.charCodeAt(i);
  }
  const blob = new Blob([uint8Array], {type: 'application/pdf'});
  const objectURL = URL.createObjectURL(blob);
  return objectURL;
}
