// @flow
import React from 'react';
import {Box, Flex, Text, Spacer} from "@chakra-ui/react";
import {IoSpeedometerOutline} from "react-icons/io5";

type HeaderRowProps = {
  isIdentifiable: boolean
}

export default function HeaderRow(props: HeaderRowProps) {
  return (
    <Flex
      fontWeight="bold"
      align="center"
      color="gray.400"
      fontSize={12}
      justify="left"
    >
      <Text minW={props.isIdentifiable ? "100px" : "50px"} textAlign="left">
        {props.isIdentifiable ? "ID" : "#"}
      </Text>
      {props.isIdentifiable &&
          <Text minW="160px" textAlign="left">
            NAME
          </Text>
      }
      <Text minW="150px" textAlign="left">
        KIT
      </Text>
      <Text minW="80px" textAlign="left">
        GENDER
      </Text>
      <Text minW="60px" textAlign="center">
        AGE
      </Text>
      <Text minW="150px" textAlign="left" ml="40px">
        GENETIC TEST STATUS
      </Text>
      <Flex align="center" ml="20px" w={"350px"}>
        <Box as={IoSpeedometerOutline} mr="10px" h="20px" w="20px" />
        <Text textAlign="left" mr="20px">
          RISK REPORTS
        </Text>
      </Flex>
      <Spacer />
      <Text textAlign="left" mr="40px">
        SHARED
      </Text>
      <Text minW="120px" textAlign="left" mr="20px">
        ORDER
      </Text>
    </Flex>
  );
}
