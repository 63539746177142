// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { Stack, Image, Box, Text, Flex, Spacer } from '@chakra-ui/react';
import grayPerson from '../images/person_gray.png';
import lightGreenPerson from '../images/person_light_green.png';
import lightOrangePerson from '../images/person_light_orange.png';
import orangeHalfLightPerson from '../images/person_orange_half_light.png';
import orangeHalfRedPerson from '../images/person_orange_half_red.png';
import yellowPerson from '../images/person_yellow.png';
import MergePersonImageImpl from './MergePersonImage';
import COLORS from './colors';
import {
  getRiskColorByPercentile,
  getRiskLevelByPercentile
} from './risk-colors';
import PopoverInfo from './PopoverInfo';
import withMobile from "./mobile/withMobile";

type Props = {
  percentile: number,
  iconHeight: number,
  iconWidth?: number,
  showBorder?: boolean,
  showText: boolean,
  borderRadius?: string,
  isSmall?: boolean,
  borderWidth?: number,
  title?: string,
  reverse?: boolean,
  showPercentile?: boolean,
  showLevel?: boolean,
  popoverText?: ?string,
  popoverHeader?: ?string,
  popoverFooter?: ?string,
  isMobile: boolean,
  screenWidth: number
};

function RelativeRiskPersonChartImpl(props: Props) {
  const calcIconWidth = () => {
    if (props.iconWidth) return props.iconWidth;
    const elementWidth = window.screen.width * 0.98 - 20;
    return (elementWidth - 165) / 10;
  };
  const iconWidth = calcIconWidth();

  const getColoredImage = index => {
    if (props.reverse) {
      if (index === 0) return orangeHalfRedPerson;
      if (index <= 1) return orangeHalfLightPerson;
      if (index <= 2) return lightOrangePerson;
      if (index <= 4) return yellowPerson;
      return lightGreenPerson;
    }
    if (index <= 4) return lightGreenPerson;
    if (index <= 6) return yellowPerson;
    if (index <= 7) return lightOrangePerson;
    if (index <= 8) return orangeHalfLightPerson;
    return orangeHalfRedPerson;
  };

  const getRiskColor = () =>
    getRiskColorByPercentile(props.percentile, props.reverse);

  const getRiskLevel = percentile =>
    getRiskLevelByPercentile(percentile).toUpperCase();

  const getImage = (
    index: number,
    width: number,
    height: number,
    percentile: number
  ) => {
    const imageHeight = `${height}px`;
    const imageWidth = `${width}px`;
    const percentileIndex = Math.floor(percentile / 10);
    if (index > percentileIndex)
      return (
        <Image src={grayPerson} h={imageHeight} w={imageWidth} key={index} />
      );
    if (index < percentileIndex)
      return (
        <Image
          src={getColoredImage(index)}
          h={imageHeight}
          w={imageWidth}
          key={index}
        />
      );

    return (
      <Box maxH={height} maxW={width} key={index}>
        <MergePersonImageImpl
          percentile={percentile / 10 - percentileIndex}
          iconHeight={props.iconHeight}
          iconWidth={iconWidth}
          image1={getColoredImage(index)}
          image2={grayPerson}
        />
      </Box>
    );
  };
  let postfix = 'th';
  if (props.percentile % 10 === 1) postfix = 'st';
  if (props.percentile % 10 === 2) postfix = 'nd';
  if (props.percentile % 10 === 3) postfix = 'rd';
  const borderColor = props.showBorder ? 'gray.100' : 'transparent';

  let mxMainBody = props.isSmall ? '35px' : '50px';
  if (props.isMobile) mxMainBody = `${Math.floor((props.screenWidth - (10 * (iconWidth + 10))) / 2)}px`;
  // console.log("mxMainBody")
  // console.log(mxMainBody)
  // console.log(props.screenWidth)
  // console.log(iconWidth)

  const mainBody = (
    <Box
      borderRadius={props.borderRadius}
      borderColor={borderColor}
      borderWidth={props.borderWidth}
      bg="transparent"
    >
      <Box my="2px">
        {props.title && (
          <Flex color={getRiskColor()}>
            <Spacer />
            <Text fontSize={20} textAlign="center">
              {props.title}
            </Text>
            <Spacer />
          </Flex>
        )}
        {props.showLevel && (
          <Flex fontWeight="bold" color={getRiskColor()}>
            <Spacer />
            <Text fontSize={24}>{getRiskLevel(props.percentile)}</Text>
            <Spacer />
          </Flex>
        )}
        {props.showPercentile && (
          <Flex fontWeight="bold" color={getRiskColor()}>
            <Spacer />
            <Text fontSize={20}>{`${props.percentile}${postfix}`}</Text>
            <Spacer />
          </Flex>
        )}
        {props.showPercentile && (
          <Flex mt={-2} mb="0px">
            <Spacer />
            <Text fontSize={16} color={COLORS.REPORT_TEXT_GRAY}>
              percentile
            </Text>
            <Spacer />
          </Flex>
        )}
        <Flex>
          <Spacer/>
          <Stack
            direction={"row"}
            mb="2px"
            ml={props.isMobile ? 0 : mxMainBody}
            mr={props.isMobile ? 0 : mxMainBody}
            // mr={props.isMobile ? 0 : mxMainBody}
            // minW={props.isMobile ? "200px" : `${10 * iconWidth + 70}px`}
          >
            {Array.from(Array(10).keys()).map(i =>
              getImage(i, iconWidth, props.iconHeight, props.percentile)
            )}
          </Stack>
          <Spacer/>
        </Flex>
        {props.showText && props.iconHeight >= 48 && (
          <Flex mt={-2} mx="50px" fontSize={12} color={COLORS.REPORT_TEXT_GRAY}>
            <Spacer />
            <Text textAlign="center">
              Your genetic risk is higher than {props.percentile}% of the
              population
            </Text>
            <Spacer />
          </Flex>
        )}
        {props.showText && props.iconHeight < 48 && (
          <Flex mt={-2} mx="30px" fontSize={12} color={COLORS.REPORT_TEXT_GRAY}>
            <Spacer />
            <Text textAlign="center">
              Your genetic risk is
              <br />
              higher than {props.percentile}% of the population
            </Text>
            <Spacer />
          </Flex>
        )}
      </Box>
    </Box>
  );

  return (
    <>
      {!props.popoverText && mainBody}
      {props.popoverText && (
        <PopoverInfo
          header={props.popoverHeader}
          footer={props.popoverFooter}
          text={props.popoverText}
          trigger={mainBody}
        />
      )}
    </>
  );
}

RelativeRiskPersonChartImpl.propTypes = {
  iconWidth: PropTypes.number,
  showBorder: PropTypes.bool,
  borderRadius: PropTypes.string,
  showText: PropTypes.bool,
  isSmall: PropTypes.bool,
  title: PropTypes.string,
  borderWidth: PropTypes.number,
  reverse: PropTypes.bool,
  showPercentile: PropTypes.bool,
  showLevel: PropTypes.bool,
  popoverFooter: PropTypes.string,
  popoverHeader: PropTypes.string,
};

RelativeRiskPersonChartImpl.defaultProps = {
  iconWidth: undefined,
  showBorder: true,
  borderRadius: '80px',
  showText: true,
  isSmall: false,
  title: undefined,
  borderWidth: 10,
  reverse: false,
  showPercentile: true,
  showLevel: true,
  popoverText: null,
  popoverHeader: 'Genetic Risk',
  popoverFooter: null
};

export default withMobile(RelativeRiskPersonChartImpl);