// @flow
import traitRanges from "../reports/trait-ranges.json";
import {
	getAgeColor,
	getBasophillCountColor,
	getCystatinCColor,
	getHdlColor,
	getLpaColor,
	getTcColor,
	getSbpColor,
	getLdlColor,
	getDbpColor,
	getCReactiveProteinColor,
	getWeightColor,
	getTestosteroneColor,
	getMchColor,
	getApobColor
} from "./calculator-common";
import {mgdl2mmolLNullAllowed, mmolL2mgdlNullAllowed} from "../../utils/cholesterol";
import {kgToPounds, poundsToKg} from "../../utils/unit_translation";
import {
	gL2mgdlApob, mgdl2gLApob,
	mgdl2nmolLLpa,
	ngdl2nmolLTestosterone,
	nmolL2mgdlLpa,
	nmolL2ngdlTestosterone
} from "../../utils/units_translation";

export type NumericAttr = {
  name: string,
	displayText: string,
  min: number,
  max: number,
  units: string,
  onChange: any,
	defaultValue: number,
	resetValue: number,
	currentValue: ?number,
	step: number,
  fTranslateToDisplayValue: any,
  fTranslateFromDisplayValue: any,
	fGetColorByValue: any,
	fGetDiffText: any,
	isAdjustable: boolean,
	isSliderEnabled: boolean | Array<boolean>,
	fixDigits: number,
	isEditable: boolean
};

export type BooleanAttr = {
  name: string,
	displayText: string,
  onChange: any,
	defaultValue: boolean,
	resetValue: boolean,
	currentValue: boolean,
	visible: boolean,
	callbackGetColor: any,
	isEditable: boolean,
	infoTexts: Object
};

export type SelectionAttr = {
  name: string,
	displayText: string,
  onChange: any,
	keyNameOptions: Object,
	defaultValue: number,
	resetValue: ?number,
	currentValue: ?number,
	isEditable: boolean
};

export function getAgeAttr(currentAge: ?number, isSliderEnabled:?Array<boolean>): NumericAttr {
	const ageMin = currentAge && currentAge > traitRanges.age.lowLimit ? currentAge : traitRanges.age.lowLimit;
	const ageMax = !currentAge || currentAge < traitRanges.age.highLimit ? traitRanges.age.highLimit : currentAge;
	const defaultAge: number = currentAge ?? traitRanges.age.lowLimit;
	return {
		name: "age",
		displayText: "Age",
		min: ageMin,
		max: ageMax,
		units: "years",
		onChange: undefined,
		defaultValue: defaultAge,
		resetValue: currentAge ?? defaultAge,
		currentValue: currentAge,
		step: 1,
		fTranslateToDisplayValue: null,
		fTranslateFromDisplayValue: null,
		fGetColorByValue: getAgeColor,
		fGetDiffText: undefined,
		isAdjustable: true,
		isSliderEnabled: isSliderEnabled === null || isSliderEnabled === undefined ? true : isSliderEnabled,
		fixDigits: 0,
		isEditable: false
	}
}

export function getSbpNumericAttr(currentSbp: number) {
	return {
			name: "sbp",
			displayText: "SBP",
			min: traitRanges.sbp.lowLimit,
			max: traitRanges.sbp.highLimit,
			units: "mmHg",
			onChange: undefined,
			defaultValue: currentSbp,
			resetValue: currentSbp,
			currentValue: currentSbp,
			step: 1,
			fTranslateToDisplayValue: undefined,
			fTranslateFromDisplayValue: undefined,
			fGetColorByValue: getSbpColor,
			fGetDiffText: undefined,
			isAdjustable: true,
			isSliderEnabled: true,
			fixDigits: 0,
			isEditable: true
		}
}

export function getDbpNumericAttr(currentDbp: number) {
	return {
			name: "dbp",
			displayText: "DBP",
			min: traitRanges.dbp.lowLimit,
			max: traitRanges.dbp.highLimit,
			units: "mmHg",
			onChange: undefined,
			defaultValue: currentDbp,
			resetValue: currentDbp,
			currentValue: currentDbp,
			step: 1,
			fTranslateToDisplayValue: undefined,
			fTranslateFromDisplayValue: undefined,
			fGetColorByValue: getDbpColor,
			fGetDiffText: undefined,
			isAdjustable: false,
			isSliderEnabled: false,
			fixDigits: 0,
			isEditable: true
		}
}

export function getTcNumericAttr(currentTc: number) {
	return {
			name: "tc",
			displayText: "Total cholesterol",
			min: traitRanges.tc.lowLimit,
			max: traitRanges.tc.highLimit,
			units: "mg/dL",
			onChange: undefined,
			defaultValue: currentTc,
			resetValue: currentTc,
			currentValue: currentTc,
			step: 1,
			fTranslateToDisplayValue: mmolL2mgdlNullAllowed,
			fTranslateFromDisplayValue: mgdl2mmolLNullAllowed,
			fGetColorByValue: getTcColor,
			fGetDiffText: undefined,
			isAdjustable: false,
			isSliderEnabled: false,
			fixDigits: 0,
			isEditable: true
		}
}

export function getHdlNumericAttr(currentHdl: number) {
	return {
			name: "hdl",
			displayText: "HDL",
			min: traitRanges.hdl.lowLimit,
			max: traitRanges.hdl.highLimit,
			units: "mg/dL",
			onChange: undefined,
			defaultValue: currentHdl,
			resetValue: currentHdl,
			currentValue: currentHdl,
			step: 1,
			fTranslateToDisplayValue: mmolL2mgdlNullAllowed,
			fTranslateFromDisplayValue: mgdl2mmolLNullAllowed,
			fGetColorByValue: getHdlColor,
			fGetDiffText: undefined,
			isAdjustable: false,
			isSliderEnabled: false,
			fixDigits: 0,
			isEditable: true
		}
}

export function getLdlNumericAttr(currentLdl: number) {
	return {
			name: "ldl",
			displayText: "LDL",
			min: traitRanges.ldl.lowLimit,
			max: traitRanges.ldl.highLimit,
			units: "mg/dL",
			onChange: undefined,
			defaultValue: currentLdl,
			resetValue: currentLdl,
			currentValue: currentLdl,
			step: 1,
			fTranslateToDisplayValue: mmolL2mgdlNullAllowed,
			fTranslateFromDisplayValue: mgdl2mmolLNullAllowed,
			fGetColorByValue: getLdlColor,
			fGetDiffText: undefined,
			isAdjustable: true,
			isSliderEnabled: true,
			fixDigits: 0,
			isEditable: true
		}
}

export function getWeightAttr(currentWeight: ?number): NumericAttr {
	const weightMin = currentWeight && currentWeight > traitRanges.weight.lowLimit ? currentWeight : traitRanges.weight.lowLimit;
	const weightMax = !currentWeight || currentWeight < traitRanges.weight.highLimit ? traitRanges.weight.highLimit : currentWeight;
	const defaultWeight: number = currentWeight ?? traitRanges.weight.lowLimit;
	return {
		name: "weight",
		displayText: "Weight",
		min: weightMin,
		max: weightMax,
		units: "pounds",
		onChange: undefined,
		defaultValue: defaultWeight,
		resetValue: currentWeight ?? defaultWeight,
		currentValue: currentWeight,
		step: 1,
		fTranslateToDisplayValue: kgToPounds,
		fTranslateFromDisplayValue: poundsToKg,
		fGetColorByValue: getWeightColor,
		fGetDiffText: undefined,
		isAdjustable: true,
		isSliderEnabled: !!currentWeight,
		fixDigits: 0,
		isEditable: true
	}
}


export function getCystatinCNumericAttr(currentCystatinC: number) {
	return {
			name: "cystatin_c",
			displayText: "Cystatin C",
			min: traitRanges.cystatinC.lowLimit,
			max: traitRanges.cystatinC.highLimit,
			units: "mg/L",
			onChange: undefined,
			defaultValue: currentCystatinC,
			resetValue: currentCystatinC,
			currentValue: currentCystatinC,
			step: 1,
			fTranslateToDisplayValue: undefined,
			fTranslateFromDisplayValue: undefined,
			fGetColorByValue: getCystatinCColor,
			fGetDiffText: undefined,
			isAdjustable: false,
			isSliderEnabled: false,
			fixDigits: 2,
			isEditable: true
		}
}

export function getLpaNumericAttr(currentLpa: number) {
	return {
			name: "lpa",
			displayText: "Lipoprotein A",
			min: traitRanges.lpa.lowLimit,
			max: traitRanges.lpa.highLimit,
			units: "mg/dL",
			onChange: undefined,
			defaultValue: currentLpa,
			resetValue: currentLpa,
			currentValue: currentLpa,
			step: 1,
			fTranslateToDisplayValue: nmolL2mgdlLpa,
			fTranslateFromDisplayValue: mgdl2nmolLLpa,
			fGetColorByValue: getLpaColor,
			fGetDiffText: undefined,
			isAdjustable: false,
			isSliderEnabled: false,
			fixDigits: 1,
			isEditable: true
		}
}

export function getApobNumericAttr(currentApob: number) {
	return {
			name: "apob",
			displayText: "Apolipoprotein B",
			min: traitRanges.apob.lowLimit,
			max: traitRanges.apob.highLimit,
			units: "mg/dL",
			onChange: undefined,
			defaultValue: currentApob,
			resetValue: currentApob,
			currentValue: currentApob,
			step: 1,
			fTranslateToDisplayValue: gL2mgdlApob,
			fTranslateFromDisplayValue: mgdl2gLApob,
			fGetColorByValue: getApobColor,
			fGetDiffText: undefined,
			isAdjustable: false,
			isSliderEnabled: false,
			fixDigits: 1,
			isEditable: true
		}
}

export function getCReactiveProteinAttr(crp: number) {
	return {
			name: "crp",
			displayText: "C-Reactive protein",
			min: traitRanges.crp.lowLimit,
			max: traitRanges.crp.highLimit,
			units: "mg/L",
			onChange: undefined,
			defaultValue: crp,
			resetValue: crp,
			currentValue: crp,
			step: 1,
			fTranslateToDisplayValue: undefined,
			fTranslateFromDisplayValue: undefined,
			fGetColorByValue: getCReactiveProteinColor,
			fGetDiffText: undefined,
			isAdjustable: false,
			isSliderEnabled: false,
			fixDigits: 1,
			isEditable: true
		}
}

export function getBasophillCountAttr(basophillCount: number) {
	return {
			name: "basophill_count",
			displayText: "Basophill count",
			min: traitRanges.basophillCount.lowLimit,
			max: traitRanges.basophillCount.highLimit,
			units: "10^9 cells/L",
			onChange: undefined,
			defaultValue: basophillCount,
			resetValue: basophillCount,
			currentValue: basophillCount,
			step: 0.01,
			fTranslateToDisplayValue: undefined,
			fTranslateFromDisplayValue: undefined,
			fGetColorByValue: getBasophillCountColor,
			fGetDiffText: undefined,
			isAdjustable: false,
			isSliderEnabled: false,
			fixDigits: 2,
			isEditable: true
		}
}

export function getTestosteroneAttr(testosterone: number) {
	return {
			name: "testosterone",
			displayText: "Testosterone",
			min: traitRanges.testosterone.lowLimit,
			max: traitRanges.testosterone.highLimit,
			units: "ng/dL",
			onChange: undefined,
			defaultValue: testosterone,
			resetValue: testosterone,
			currentValue: testosterone,
			step: 1,
			fTranslateToDisplayValue: nmolL2ngdlTestosterone,
			fTranslateFromDisplayValue: ngdl2nmolLTestosterone,
			fGetColorByValue: getTestosteroneColor,
			fGetDiffText: undefined,
			isAdjustable: false,
			isSliderEnabled: false,
			fixDigits: 1,
			isEditable: true
		}
}

export function getMchAttr(mch: number) {
	return {
			name: "mch",
			displayText: "Mean corpuscular haemoglobin",
			min: traitRanges.mch.lowLimit,
			max: traitRanges.mch.highLimit,
			units: "pg",
			onChange: undefined,
			defaultValue: mch,
			resetValue: mch,
			currentValue: mch,
			step: 1,
			fTranslateToDisplayValue: undefined,
			fTranslateFromDisplayValue: undefined,
			fGetColorByValue: getMchColor,
			fGetDiffText: undefined,
			isAdjustable: false,
			isSliderEnabled: false,
			fixDigits: 1,
			isEditable: true
		}
}

export function getBooleanAttr(name: string, displayText: string, value: boolean, defaultValue: boolean, visible: boolean, callbackGetColor: any, isEditable: boolean, infoTexts: Object): BooleanAttr {
		return {
			name: name,
			displayText: displayText,
			onChange: undefined,
			defaultValue: defaultValue,
			resetValue: value,
			currentValue: value,
			visible: visible,
			callbackGetColor: callbackGetColor,
			isEditable: isEditable,
			infoTexts: infoTexts
		}
}

export function getUsualWalkingPaceAttr(pace: ?number, isEditable: boolean): SelectionAttr {
	return {
		name: "usual_walking_pace",
		displayText: "Usual walking pace",
		onChange: undefined,
		keyNameOptions: {
			"1": "slow pace",
			"2": "steady average pace",
			"3": "brisk pace",
		},
		defaultValue: 2,
		resetValue: pace,
		currentValue: pace,
		isEditable: isEditable
	}
}
