// @flow
import React from 'react';
import { Flex, Box, Text } from '@chakra-ui/react';
import COLORS from '../common/colors';
import Disclaimers from '../common/reports/Disclaimers';
import HypercholesterolReportStrings from './hypercholesterol-report-common';

type Props = {
  isMobile: boolean
};

export default function HypercholesterolExplanatorySection(props: Props) {
  const headerFontSize = props.isMobile ? 17 : 20;
  const textFontSize = props.isMobile ? 12 : 14;
  return (
    <Box fontSize={textFontSize}>
      <Flex align="center" justify="space-between">
        <Text fontSize={headerFontSize} color="gray.600" mt="30px" mb="20px">
          What is Hypercholesterolemia ?
        </Text>
      </Flex>
      <Text mb="20px" color={COLORS.REPORT_TEXT}>
        {HypercholesterolReportStrings.WHAT_IS_HYPERCHOLESTEROL_TEXT1}
        <br />
        <br />
        {HypercholesterolReportStrings.WHAT_IS_HYPERCHOLESTEROL_TEXT2}
        <sup>*</sup>.
        <br />
      </Text>
      <Text mb="5px">
        <i>
          <sup>*</sup>{' '}
          {HypercholesterolReportStrings.WHAT_IS_HYPERCHOLESTEROL_REF1}
        </i>
      </Text>
      <Text fontSize={headerFontSize} color="gray.600" my="20px">
        How did we calculate your score ?
      </Text>
      <Text mb="15px" color={COLORS.REPORT_TEXT}>
        {HypercholesterolReportStrings.HOW_DID_WE_CALC_TEXT1}
        <sup>*</sup>. {HypercholesterolReportStrings.HOW_DID_WE_CALC_TEXT2}
      </Text>
      <Text mb="20px" fontSize={textFontSize}>
        <sup>*</sup>
        <i>{HypercholesterolReportStrings.HOW_DID_WE_CALC_REF}</i>
      </Text>
      <Disclaimers
        isMobile={props.isMobile}
        texts={[
          HypercholesterolReportStrings.DISCLAIMER1,
          HypercholesterolReportStrings.DISCLAIMER2,
          HypercholesterolReportStrings.DISCLAIMER3,
          HypercholesterolReportStrings.DISCLAIMER4,
          HypercholesterolReportStrings.DISCLAIMER5,
          HypercholesterolReportStrings.DISCLAIMER6
        ]}
      />
    </Box>
  );
}
