// @flow
import React from 'react';
import { Text, Flex, Box, Spacer } from '@chakra-ui/react';

// import { BsInfoCircle } from 'react-icons/bs';
import COLORS from './colors';

type Props = {
  onClick: any
};

export default function InfoButton(props: Props) {
  const inputProps: Object = { ...props };
  return (
    <Box
      onClick={props.onClick}
      sx={{
        '@media print': {
          display: 'none'
        }
      }}
    >
      <Flex align="center">
        <Spacer />
        {/* <Box minW="12px" as={BsInfoCircle} minH="12px" hidden /> */}
        <Text
          {...inputProps}
          textAlign="center"
          fontSize={12}
          fontWeight="bold"
          color="transparent"
          fontStyle="italic"
          _hover={{
            color: COLORS.REPORT_TEXT
          }}
        >
          info
        </Text>
        <Spacer />
      </Flex>
    </Box>
  );
}
