// @flow
import queryString from 'query-string';

export function getMobileUrl(location: string, patientId: number) {
  const i = location.indexOf('patient/');
  return `${location.substring(0, i)}mobile/${location.substr(
    i
  )}?id=${patientId}`;
}

export function getTargetUrl(
  url: string,
  origPostfix: string,
  targetPostfix: string,
  patientId: number
) {
  return `${url.replace(origPostfix, targetPostfix)}?id=${patientId}`;
}

export function getPatientId(params: Object, location: Object) {
  const queryParams = queryString.parse(location.search);
  if (queryParams.id)
    return queryParams.id;
  return params.patient;
}

export function loadClinicalData(patientId: number) {
  console.log(patientId);
  return {
    sex: undefined,
    age: undefined,
    sbp: undefined,
    dbp: undefined,
    bmi: undefined,
    hdl: undefined,
    ldl: undefined,
    tc: undefined,
    bp_treated: undefined,
    t2d: undefined,
    smoking: undefined,
    pca: [
      -11.29146182270174,
      3.2132156996165415,
      -0.9051762359526598,
      -0.3764138302978324,
      -0.43169210587530277,
      -0.08819660435820556
    ]
  };
}
