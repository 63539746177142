// @flow
import queryString from 'query-string';
import DemoClinicalData1 from './data/clinical1.json';
import DemoClinicalData2 from './data/clinical2.json';
import DemoClinicalData3 from './data/clinical3.json';
import DemoClinicalData4 from './data/clinical4.json';
import DemoClinicalData5 from './data/clinical5.json';

export function isClientPage(params: Object) {
  return !!params.client;
}

export function getMobileUrl(location: string, patientId: number) {
  const i = location.indexOf('patient/');
  return `${location.substring(0, i)}mobile/${location.substr(
    i
  )}?id=${patientId}`;
}

export function getTargetUrl(
  url: string,
  origPostfix: string,
  targetPostfix: string,
  patientId: number
) {
  return `${url.replace(origPostfix, targetPostfix)}?id=${patientId}`;
}

export function isGeneByGenePatientId(patientId: number) {
  return patientId > 1000 && patientId < 2000;
}

export function isTechAvivPatientId(patientId: number) {
  return patientId > 2000 && patientId < 3000;
}

export function isPrivatePatientId(patientId: number) {
  return patientId > 3000 && patientId < 4000;
}

export function doesClientMatchPatientId(client: string, patientId: number) {
  const gbg = isGeneByGenePatientId(patientId);
  if (client === 'genebygene') return gbg;
  const ta = isTechAvivPatientId(patientId);
  if (client === 'techaviv') return ta;
  const pr = isPrivatePatientId(patientId);
  if (client === 'private') return pr;
  return !ta && !gbg && !pr;
}

export function getPatientId(params: Object, location: Object) {
  const queryParams = queryString.parse(location.search);
  const patientId = queryParams.id ? parseInt(queryParams.id, 10) : 1;
  if (!doesClientMatchPatientId(params.client, patientId)) return 1;
  return patientId;
}

export function loadClinicalData(patientId: number) {
  let clinical = DemoClinicalData1;
  if (patientId === 2) clinical = DemoClinicalData2;
  if (patientId === 3) clinical = DemoClinicalData3;
  if (patientId === 4) clinical = DemoClinicalData4;
  if (patientId === 5) clinical = DemoClinicalData5;
  if (patientId > 5)
    clinical = {
      sex: undefined,
      age: 38,
      sbp: undefined,
      dbp: undefined,
      bmi: undefined,
      hdl: undefined,
      tc: undefined,
      bp_treated: undefined,
      t2d: undefined,
      smoking: undefined,
      pca: [
        11.29146182270174,
        3.2132156996165415,
        -0.9051762359526598,
        -0.3764138302978324,
        -0.43169210587530277,
        -0.08819660435820556
      ]
    };
  return clinical;
}
