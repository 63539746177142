// @flow
import COLORS from "../colors";
import {mmolL2mgdl} from "../../utils/cholesterol";

export function getAgeColor() {
  return 'blue.500';
}

export function getWeightColor() {
  return 'blue.500';
}

export function getDbpColor(dbp: number) {
	if (dbp > 90) return COLORS.RED_STATUS;
	return COLORS.GREEN_STATUS;
}

export function getSbpColor(sbp: number) {
	if (sbp < 90) return COLORS.RED_STATUS;
	if (sbp > 140) return COLORS.RED_STATUS;
	if (sbp > 130) return COLORS.YELLOW_STATUS;
	return COLORS.GREEN_STATUS;
}

export function getTcColor(tc: number) {
	if (tc < mmolL2mgdl(5.18)) return COLORS.GREEN_STATUS;
	if (tc > mmolL2mgdl(6.18)) return COLORS.RED_STATUS;
	return COLORS.YELLOW_STATUS;
}

export function getHdlColor(hdl: number) {
	const lowThresh = this.props.sex ? mmolL2mgdl(1) : mmolL2mgdl(1.3);
	if (hdl < lowThresh) return COLORS.RED_STATUS;
	return COLORS.GREEN_STATUS;
}

export function getLdlColor(ldl: number) {
	if (ldl < 100) return COLORS.GREEN_STATUS;
	if (ldl < 130) return COLORS.YELLOW_STATUS;
	if (ldl < 160) return COLORS.ORANGE_STATUS;
	return COLORS.RED_STATUS;
}

export function getCystatinCColor(cystatinC: number) {
if (cystatinC > 1.23) return COLORS.YELLOW_STATUS;
if (cystatinC > 1.711) return COLORS.RED_STATUS;
	return COLORS.GREEN_STATUS;
}

export function getLpaColor(lpa: number) {
  if (lpa < 30) return COLORS.GREEN_STATUS;
  if (lpa < 50) return COLORS.YELLOW_STATUS;
  return COLORS.RED_STATUS;
}

export function getApobColor(apob: number) {
  if (apob < 110) return COLORS.GREEN_STATUS;
  return COLORS.RED_STATUS;
}

export function getBasophillCountColor(basophillCount: number) {
	if (basophillCount > 0.2) return COLORS.RED_STATUS;
	return COLORS.GREEN_STATUS;
}


export function getTestosteroneColor(_testosterone: number) {
	return COLORS.GREEN_STATUS;
}

export function getMchColor(mch: number) {
	if (mch >= 26 && mch <= 33) return COLORS.GREEN_STATUS;
	return COLORS.YELLOW_STATUS
}


export function getCReactiveProteinColor(crp: number) {
	if (crp < 0.03) return COLORS.GREEN_STATUS;
	if (crp <= 0.1) return COLORS.YELLOW_STATUS;
	if (crp <= 1) return COLORS.ORANGE_STATUS;
	return COLORS.RED_STATUS;
}
