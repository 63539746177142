// @flow
export function calcRelativeRiskForCallback(oddsRatio: number) {
  if (oddsRatio < 1) return -((1 - oddsRatio) * 100);
  if (oddsRatio > 2) return 100;
  return (oddsRatio - 1) * 100;
}

export function getRelativeRiskLabel(
  relativeRisk: number,
  relativeRiskOddsRatio: number
) {
  let relativeRiskLabel = `${relativeRisk.toFixed(0)}%`;
  if (relativeRiskOddsRatio > 2) {
    relativeRiskLabel = `x ${relativeRiskOddsRatio.toFixed(2)}`;
  } else if (relativeRisk > 0) {
    relativeRiskLabel = `+${relativeRisk.toFixed(0)}%`;
  } else if (relativeRisk < -99) {
    relativeRiskLabel = `almost -100%`;
  }
  if (relativeRiskLabel === '-0%') relativeRiskLabel = '0%';
  return relativeRiskLabel;
}
