// @flow
import React from 'react';
import {
  Flex,
  Box,
  Text,
  Stack,
  Spacer,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  FormControl,
  useDisclosure,
  Alert,
  AlertIcon
} from '@chakra-ui/react';

import COLORS from '../../common/colors';
import ChangeSlider from '../../common/ChangeSlider';
import {
  getBloodPressureRelativeRiskLabel
} from './bp-calculator-utils';
import {getAgeColor} from '../../common/calculator/calculator-common'
import ActionButton from '../../common/ActionButton';
import RelativeRiskChart from '../../common/RelativeRiskGauge';
import {
  getRiskColorByQuartile,
  getRiskLevelByQuartile,
  getRiskLevelByPercentile
} from '../../common/risk-colors';
import {
  agePopInfoTextsHtn,
  olderParticipantHtn,
  youngerParticipantHtn
} from '../../common/reports/report-common';
import CalculatorHeaderImpl from '../../common/reports/CalculatorHeaderBar';
import withGxgBpCalculator from './WithGxgBpCalculator';
import { bpCalculatorUserAttribute } from '../../common/reports/report-common';
import { bpReportStrings } from '../bp-report-common';
import EditablePatientAttributes from '../../common/reports/EditablePatientAttributes';
import GxgPatientAttributes from '../../common/reports/GxgPatientAttributes';
import { roundWithPrecision } from '../../utils/numbers';
import capitalizeFirstLetter from '../../utils/string';
import PopoverInfo from '../../common/PopoverInfo';
import traitRanges from "../../common/reports/trait-ranges.json";
import {kgToPoundsNumeric} from "../../utils/unit_translation";

type Props = {
  onCurrentAgeChange: any,
  onAgeSliderChange: any,
  getAgeDiffText: any,
  onCurrentWeightChange: any,
  onWeightSliderChange: any,
  getWeightDiffText: any,
  getSliderWeightColor: any,
  onGenderSelected: any,
  onResetAttributes: any,
  ageLowLimit: number,
  ageHighLimit: number,
  weightLowLimit: number,
  weightHighLimit: number,
  sbpPercentile: number,
  dbpPercentile: number,
  sbpQuartile: number,
  dbpQuartile: number,
  age: ?number,
  resetAge: number,
  weight: number,
  currentAge: ?number,
  currentWeight: ?number,
  resetWeight: number,
  recentSbpChange: number,
  recentDbpChange: number,
  recentMinSbp: number,
  recentMaxSbp: number,
  recentMinDbp: number,
  recentMaxDbp: number,
  gender: ?string,
  playingEnabled: boolean,
  shortSbpText: string,
  longSbpText: string,
  sbpTrendIcon: any,
  sbpColor: string,
  shortDbpText: string,
  longDbpText: string,
  dbpTrendIcon: any,
  dbpColor: string,
  printing: boolean,
  showEdit: boolean
};

export function GxgBpCalculatorImpl(props: Props) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const initialRef = React.useRef();
  const finalRef = React.useRef();

  const index = props.longDbpText.indexOf('a mean');
  const combinedBpText = props.longSbpText
    ? `${props.longSbpText.slice(0, -1)} \nand ${props.longDbpText.substring(
        index
      )}`
    : '';
  const missingAttrText = `Missing attributes, click the 'Edit' button to provide the missing information`;

  const isYoungerThanMin = props.currentAge && props.currentAge < traitRanges.age.lowLimit;
  const isOlderThanMax = props.currentAge && props.currentAge > traitRanges.age.highLimit;

  return (
    <Box borderWidth={1} borderColor="gray.200" color={COLORS.REPORT_TEXT}>
      <CalculatorHeaderImpl riskTitle="CLINICAL FACTORS EFFECT ON BLOOD PRESSURE" />
      <Flex mt="20px" minW="100%">
        <Flex
          borderColor="gray.100"
          borderWidth={1}
          mx="1%"
          minW={props.printing ? "90%" : "70%"}
        >
          <Box w={props.printing ? "35%" : "45%"} maxW="45%" mx="5%" my="20px">
            <Stack spacing="50px" mt="10px">
              <Text fontSize={14}>
                <i>
                  Use the sliders to change the age and weight values and check the
                  mean effect on blood pressure in the population
                </i>
              </Text>
              {/* age */}
              <ChangeSlider
                val={props.age}
                minVal={props.ageLowLimit}
                maxVal={props.ageHighLimit}
                stepVal={1}
                resetVal={props.resetAge}
                leftLabel="Age"
                callbackOnValueChange={props.onAgeSliderChange}
                callbackOnValueEndChange={props.onAgeSliderChange}
                callbackGetColor={getAgeColor}
                callbackRightLabel={props.getAgeDiffText}
                enabled={props.playingEnabled}
                popoverInfo={isYoungerThanMin || isOlderThanMax ? agePopInfoTextsHtn : undefined}
              />
              {/* weight */}
              <ChangeSlider
                val={kgToPoundsNumeric(props.weight)}
                minVal={props.weightLowLimit}
                maxVal={props.weightHighLimit}
                stepVal={1}
                resetVal={kgToPoundsNumeric(props.resetWeight)}
                leftLabel="Weight"
                callbackOnValueChange={props.onWeightSliderChange}
                callbackOnValueEndChange={props.onWeightSliderChange}
                callbackGetColor={props.getSliderWeightColor}
                callbackRightLabel={props.getWeightDiffText}
                enabled={props.playingEnabled}
              />
              <ActionButton
                onClick={props.onResetAttributes}
                borderWidth={1}
                borderColor={COLORS.REPORT_TEXT}
                name="Reset"
                color={COLORS.REPORT_TEXT}
              />
            </Stack>
          </Box>
          <Box my="30px" w={props.printing ? "45%" : "45%"} maxW={props.printing ? "45%" : "45%"}>
            <Box minH="50%" minW="100%" ml={props.printing ? "-60px" : 0}>
              <Flex mr="20px" align="top" minH="30px" minW="100%">
                {props.printing && <Spacer/>}
                <PopoverInfo
                  text={bpReportStrings.RELATIVE_TO_CURRENT_SBP_INFO_TEXT}
                  header={bpReportStrings.RELATIVE_TO_CURRENT_SBP_INFO_HEADER}
                  trigger={
                    <Text
                      fontSize={15}
                      fontWeight="semibold"
                      textAlign="center"
                    >
                      Estimated SBP Change:
                    </Text>
                  }
                />
                <Spacer />
                {props.sbpTrendIcon && (
                  <Image src={props.sbpTrendIcon} h="30px" w="30px" mr="20px" />
                )}
                <Text color={props.sbpColor}>
                  <b>{props.shortSbpText}</b>
                </Text>
              </Flex>
              <RelativeRiskChart
                percentile={props.sbpPercentile}
                quantile={props.sbpQuartile}
                title={undefined}
                showSubtitle={false}
                callbackGetColor={getRiskColorByQuartile}
                callbackGetRiskLevel={getRiskLevelByQuartile}
                callbackGetRelativeRiskLabel={getBloodPressureRelativeRiskLabel}
                relativeRisk={props.recentSbpChange}
                relativeRiskOddsRatio={1}
                minRelativeRisk={props.recentMinSbp}
                maxRelativeRisk={props.recentMaxSbp}
                relativeRiskTitle="estimated change"
                maxHeight={props.printing ? 200 : 220}
              />
              <Flex mr="20px" align="top" minH="30px">
                {props.printing && <Spacer/>}
                <PopoverInfo
                  text={bpReportStrings.RELATIVE_TO_CURRENT_DBP_INFO_TEXT}
                  header={bpReportStrings.RELATIVE_TO_CURRENT_DBP_INFO_HEADER}
                  trigger={
                    <Text fontSize={15} fontWeight="semibold">
                      Estimated DBP Change:
                    </Text>
                  }
                />
                <Spacer />
                {props.dbpTrendIcon && (
                  <Image src={props.dbpTrendIcon} h="30px" w="30px" mr="20px" />
                )}
                <Text color={props.dbpColor}>
                  <b>{props.shortDbpText}</b>
                </Text>
              </Flex>
              <RelativeRiskChart
                percentile={props.dbpPercentile}
                quantile={props.dbpQuartile}
                title={undefined}
                showSubtitle={false}
                callbackGetColor={getRiskColorByQuartile}
                callbackGetRiskLevel={getRiskLevelByQuartile}
                callbackGetRelativeRiskLabel={getBloodPressureRelativeRiskLabel}
                relativeRisk={props.recentDbpChange}
                relativeRiskOddsRatio={1}
                minRelativeRisk={props.recentMinDbp}
                maxRelativeRisk={props.recentMaxDbp}
                relativeRiskTitle="estimated change"
                maxHeight={props.printing ? 200 : 220}
              />
            </Box>
            <Text fontSize={15}>{combinedBpText}</Text>
            {/* <Text fontSize={15}>{postfixDbpText}</Text> */}
          </Box>
        </Flex>
        {!props.printing && <Box
            w="27%"
            minW="27%"
            maxW="27%"
            minH="100%"
            borderColor="gray.100"
            borderWidth={1}
            mr="1%"
        >
          <Box mt="10px">
            <GxgPatientAttributes
                gender={capitalizeFirstLetter(props.gender)}
                age={props.currentAge}
                weight={
                  Number.isNaN(props.currentWeight)
                      ? undefined
                      : roundWithPrecision(props.currentWeight, 1)
                }
                riskTrait="HTN (systolic)"
                riskLevel={getRiskLevelByPercentile(props.sbpPercentile)}
                riskTrait2="HTN (diastolic)"
                riskLevel2={getRiskLevelByPercentile(props.dbpPercentile)}
                showBorder={false}
                showAttributeFlags={bpCalculatorUserAttribute}
            />
          </Box>
          {props.showEdit && <ActionButton
              name="Edit..."
              size="sm"
              w="70px"
              onClick={onOpen}
              ml="30px"
              mt="10px"
              color={COLORS.REPORT_TEXT}
          />}
          {!props.playingEnabled && (
              <Box mx="30px" fontSize={13}>
                <Alert status="warning" mt="30px" borderRadius="10px">
                  <AlertIcon/>
                  {missingAttrText}
                </Alert>
              </Box>
          )}
        </Box>
        }
      </Flex>
      <Flex my="20px" minW="100%">
        <Box mx="20px" mb="20px">
          <Text fontSize={13}>
            <i>{bpReportStrings.CALCULATION_EXPLAINED}</i>
          </Text>
          {isYoungerThanMin && (<Text mt="10px" color={COLORS.RED_STATUS} fontSize={13} fontWeight={"bold"}>
            <sup>*</sup>
            <i>
              {youngerParticipantHtn}
            </i>
          </Text>)}
          {isOlderThanMax && (<Text mt="10px" color={COLORS.RED_STATUS} fontSize={13} fontWeight={"bold"}>
            <sup>*</sup>
            <i>
              {olderParticipantHtn}
            </i>
          </Text>)}
        </Box>
      </Flex>
      <Modal
        blockScrollOnMount={false}
        isOpen={isOpen}
        onClose={onClose}
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
      >
        <ModalOverlay />
        <ModalContent color={COLORS.REPORT_TEXT}>
          <ModalBody color={COLORS.REPORT_TEXT}>
            <FormControl>
              <EditablePatientAttributes
                gender={props.gender}
                age={props.currentAge}
                weight={props.currentWeight}
                riskTrait={null}
                labelPrefix="Current "
                callbackGenderSelected={props.onGenderSelected}
                callbackAgeChange={props.onCurrentAgeChange}
                callbackWeightChange={props.onCurrentWeightChange}
                onCancel={onClose}
                onApply={onClose}
                showAttributeFlags={bpCalculatorUserAttribute}
              />
            </FormControl>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default withGxgBpCalculator(GxgBpCalculatorImpl);
