// @flow
import React from 'react';

import { Box } from '@chakra-ui/react';
import withNavigation from '../main/WithRoutes';
import { getPatientId } from '../capilots/capilots-utils';
import T2dExplanatorySection from '../t2d/T2dExplanatorySection';
import T2dChartsSection from '../t2d/T2dChartsSection';
import t2dImage from '../images/sugar-blood-level.png';

import t2dReportCommon from '../t2d/t2d-report-common';
import GxgPatientAttributes from "../common/reports/GxgPatientAttributes";
import {roundWithPrecision} from "../utils/numbers";
import {getRiskLevelByPercentile} from "../common/risk-colors";
import {gxgT2dCalculatorUserAttributes} from "../common/reports/gxg-report-common";
import {isNullOrUndefined} from "url/util";
import t2dLogReg from './t2d-consumer-log-reg.json'
import T2dRecommendationSection from "../t2d/T2dRecommendationSection";
import ConsumerT2dCalculator from "../t2d/calculator/ConsumerT2dCalculator";
import GeneticReportOrMobile from "../common/reports/GeneticReportOrMobile";
import withMobile from "../common/mobile/withMobile";

type Props = {
  location: any,
  params: any,
  visit: Object,
  age: ?number,
  printing: boolean,
  t2d: Object,
  pca: Array<number>,
  loading: boolean,
  isMobile: boolean
};

type State = {
  overallRiskPercentile: ?number
};

export class ConsumerT2dRiskReportImpl extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    (this: any).onInitOverallRiskPercentile = this.onInitOverallRiskPercentile.bind(this);
    this.patientId = getPatientId(props.params, props.location);
    this.state = {
      overallRiskPercentile: undefined
    };
  }
  state: State;

  onInitOverallRiskPercentile(percentile: number) {
    this.setState({
      overallRiskPercentile: percentile
    });
  }

  patientId: string;

  render() {
    const {isMobile} = this.props;
    const oneBasedPercentile = this.props.t2d
      ? this.props.t2d.t2d.percentile + 1
      : 0;
    const showAlert = !this.props.loading && this.props.visit && (
        isNullOrUndefined(this.props.visit.sex) ||
        !this.props.visit.age ||
        !this.props.visit.weight ||
        !this.props.visit.height
    );
    const alertMessage = showAlert ? 'The current overall risk assessment is based on partial data.\nFill in your attributes in order to get a more accurate overall risk assessment.' : undefined;

    const printingPatientAttr = this.props.printing && this.props.t2d && (
      <Box w={"420px"} borderWidth={1} borderColor="gray.100">
        <GxgPatientAttributes
            gender={this.props.visit.sex ? "Male": "Female"}
            age={this.props.visit.age}
            bmi={roundWithPrecision(this.props.visit.bmi)}
            riskTrait="T2D"
            riskLevel={getRiskLevelByPercentile(this.props.t2d.t2d.percentile + 1)}
            hypertensive={this.props.visit.hypertension}
            diabetes={this.props.visit.diabetes}
            smoker={this.props.visit.smoker}
            fhDiabetes={this.props.visit.fh_diabetes}
            orderLabs={false}
            labelPrefix="Current "
            showBorder={false}
            showAttributeFlags={gxgT2dCalculatorUserAttributes}
            spacing={"7px"}
          >
          </GxgPatientAttributes>
        </Box>);

    let t2dChartsSection = null;
    if (this.props.t2d) {
      t2dChartsSection = <T2dChartsSection
        patientId={this.patientId}
        percentile={this.props.t2d.t2d.percentile}
        quintile={Math.floor(this.props.t2d.t2d.percentile / 20)}
        oddsRatios={this.props.t2d.t2d.odds_ratio_list}
        deciles={this.props.t2d.t2d.deciles}
        isMobile={isMobile}
        overallRiskPercentile={this.state.overallRiskPercentile ? this.state.overallRiskPercentile + 1 : 1}
        overallRiskDeciles={this.props.t2d.t2d.overall_risk.prevalence}
        overallRiskOddsRatios={this.props.t2d.t2d.overall_risk.odds_ratios}
        printing={this.props.printing}
      />;
    }

    const calculatorSection = this.props.t2d && this.props.visit ? <Box
        mt="20px"
        sx={{
          '@media print': {
            pageBreakBefore: 'always;'
          }
        }}
      >
        <ConsumerT2dCalculator
          sex={this.props.visit.sex ? 1 : 0}
          age={this.props.age}
          weight={this.props.visit.weight}
          height={this.props.visit.height}
          hypertensive={this.props.visit.hypertension}
          fhDiabetes={this.props.visit.fh_diabetes}
          smoker={this.props.visit.smoker}
          stdizedT2dScore={this.props.t2d.t2d.stdized_overall_risk_t2d}
          sbp={this.props.visit.sbp}
          dbp={this.props.visit.dbp}
          printing={this.props.printing}
          callbackInitRiskPercentile={this.onInitOverallRiskPercentile}
          logReg={t2dLogReg}
          geneticRiskPercentile={oneBasedPercentile}
        />
      </Box> : null;

    return (
      <Box sx={{ '@media print': { '@page': { size: 'landscape;' } } }}>
        <GeneticReportOrMobile
          isDemo={false}
          reportTitle="TYPE 2 DIABETES RISK REPORT"
          configTrait="t2d"
          loading={this.props.loading}
          titleImage={t2dImage}
          patientNum={this.props.visit ? this.props.visit.patient_num : null}
          patientName={this.props.visit ? this.props.visit.patient_name : null}
          geneticKitId={this.props.visit ? this.props.visit.kit_id : ''}
          printing={this.props.printing}
          alertMessage={alertMessage}
          showAlert={showAlert}
          riskPercentile={this.props.t2d ? this.props.t2d.t2d.percentile : null}
          geneticRiskInfoText={t2dReportCommon.GENETIC_RISK_INFO_TEXT}
          patientAttributesSection={printingPatientAttr}
          chartsSection={t2dChartsSection}
          calculatorSection={calculatorSection}
          recommendationSection={<T2dRecommendationSection isMobile={isMobile}/>}
          explanatorySection={<T2dExplanatorySection isMobile={isMobile} />}
        />
      </Box>
    );
  }
}

export default withNavigation(withMobile(ConsumerT2dRiskReportImpl));
