// @flow
export function nmolL2ngdlTestosterone(val: number) {
  return 28.85 * val;
}

export function ngdl2nmolLTestosterone(val: number) {
  return val / 28.85;
}

export function nmolL2mgdlLpa(val: number) {
  return val / 2.5;
}

export function mgdl2nmolLLpa(val: number) {
  return val * 2.5;
}

export function gL2mgdlApob(val: number) {
  return val * 100
}

export function mgdl2gLApob(val: number) {
  return val / 100
}
