// @flow
import React from 'react';
import atrialReportCommon from "../atrial/atrial-report-common";
import RiskChartsSectionOrMobile from "../../common/reports/RiskChartsSectionOrMobile";

type Props = {
  percentile: number,
  quintile: number,
  deciles: Array<number>,
  oddsRatios: Array<number>,
  printing: boolean
};

export default function AtrialChartsSection(props: Props) {
  return (
    <RiskChartsSectionOrMobile
      percentile={props.percentile}
      quintile={props.quintile}
      deciles={props.deciles}
      oddsRatios={props.oddsRatios}
      minY={2}
      maxY={props.printing ? 10 : 9}
      yTickInterval={1}
      shortTrait="Atrial fibrillation"
      longTrait="Atrial fibrillation"
      absRiskTrendInfoText={atrialReportCommon.ATRIAL_RISK_IN_POPULATION}
      riskVsAvgInfoText={atrialReportCommon.ATRIAL_RISK_VS_AVERAGE}
      printing={props.printing}
    />
  );
}
