// @flow
import { CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import COGNITO_CONFIG from '../config/auth_config';
import { ENV } from '../config/env_config';
import routes from '../main/routes';
import AWS from "aws-sdk";

export function getCurrentUser() {
  const userPool = new CognitoUserPool({
    UserPoolId: COGNITO_CONFIG[ENV].USERPOOL_ID,
    ClientId: COGNITO_CONFIG[ENV].APPCLIENT_ID
  });

  return userPool.getCurrentUser();
}

export function getCurrentUsername() {
  const user = getCurrentUser()
  if (!user) return null;
  return user.getUsername();
}


export function isLoggedIn(): boolean {
  return getCurrentUser() != null;
}

function getUserGroups(authenticatedUser: typeof CognitoUser) {
  if (
    !authenticatedUser ||
    !authenticatedUser.signInUserSession ||
    !authenticatedUser.signInUserSession.accessToken
  )
    return [];
  const groups =
    authenticatedUser.signInUserSession.accessToken.payload['cognito:groups'];
  return groups ?? [];
}

export function isUserInGroup(
  authenticatedUser: typeof CognitoUser,
  groupName: string
) {
  if (
    !authenticatedUser ||
    !authenticatedUser.signInUserSession ||
    !authenticatedUser.signInUserSession.accessToken
  )
    return false;
  const groups = getUserGroups(authenticatedUser);
  if (!groups) return false;
  return groups.includes(groupName);
}

export function isPilotUser(authenticatedUser: typeof CognitoUser) {
  return isUserInGroup(authenticatedUser, 'pilots');
}

export function isClinicsUser(authenticatedUser: typeof CognitoUser) {
  return isUserInGroup(authenticatedUser, 'clinics');
}

export function isDeveloperUser(authenticatedUser: typeof CognitoUser) {
  return isUserInGroup(authenticatedUser, 'developers');
}

export function isInternalDemoUser(authenticatedUser: typeof CognitoUser) {
  return isUserInGroup(authenticatedUser, 'demo-internal');
}

export function isExternalDemoUser(authenticatedUser: typeof CognitoUser) {
  return isUserInGroup(authenticatedUser, 'demo-external');
}

export function isDemoUser(authenticatedUser: typeof CognitoUser) {
  return (
    isInternalDemoUser(authenticatedUser) ||
    isExternalDemoUser(authenticatedUser)
  );
}

export function isLimitedServiceUser(authenticatedUser: typeof CognitoUser) {
  return isUserInGroup(authenticatedUser, 'limited_service');
}

export function getPilotClinic(authenticatedUser: typeof CognitoUser) {
  const groups = getUserGroups(authenticatedUser);
  for (let i = 0; i < groups.length; i += 1) {
    if (groups[i] !== 'developers' && groups[i] !== 'pilots') return groups[i];
  }
  return null;
}

export function getClinicsClinic(authenticatedUser: typeof CognitoUser) {
  const groups = getUserGroups(authenticatedUser);
  for (let i = 0; i < groups.length; i += 1) {
    if (groups[i] !== 'developers' && groups[i] !== 'clinics') return groups[i];
  }
  return null;
}

export function getFullUserName(authenticatedUser: typeof CognitoUser) {
  if (!authenticatedUser || !authenticatedUser.attributes) return '';
  return authenticatedUser.attributes.name;
}

export async function signOutUser(
  currentUser: typeof CognitoUser,
  navigate: any = null
) {
  if (currentUser) {
    if (AWS.config.credentials) {
      AWS.config.credentials.clearCachedId();
    }
    await Auth.signOut();
    sessionStorage.removeItem("handledClockOffset");
    if (navigate) navigate(routes.LOGIN);
  }
}

export function getUserDefaultUrl(authenticatedUser: typeof CognitoUser, patientId: ?string=null) {
  let url = '/';
  if (!authenticatedUser) return url;
  const clinics = getUserClinics(authenticatedUser);
  if (clinics.length > 1) {
    console.log(`user can view multiple clinics ${clinics.length}`)
    return url;
  }

  if (isPilotUser(authenticatedUser)) {
    const clinic = getPilotClinic(authenticatedUser);
    console.log(`pilot user ${clinic ?? "none"}`)
    if (clinic !== null) {
      url = `${routes.APP}/pilots/${clinic}/${routes.PATIENT_LIST}`;
    }
  } else if (isClinicsUser(authenticatedUser)) {
    const clinic = getClinicsClinic(authenticatedUser);
    console.log(`clinics user ${clinic ?? "none"}`)
    if (clinic !== null) {
      url = `${routes.APP}/clinics/${clinic}/${routes.PATIENT_LIST}`;
    }
  } else {
    if (!patientId) {
      console.log(`no patient ID`)
      return "/";
    }
    if (isConsumerUser(authenticatedUser)) {
      url = `${routes.APP}/self/${patientId}/attributes`;
      console.log(`consumer user, patient url ${url}`)
    } else {
      url = `${routes.APP}/self/${patientId}/risks`;
      console.log(`patient url ${url}`)
    }
  }
  return url;
}

export function getUserClinics(authenticatedUser: typeof CognitoUser) {
  if (!authenticatedUser) return [];
  const groups = getUserGroups(authenticatedUser);
  if (!groups) {
    return [];
  }
  const nonClinicGroups = ['developers', 'clinics', 'pilots']
  const clinics: Array<string> = groups.filter((gr) => { return !nonClinicGroups.includes(gr); });
  clinics.sort();
  return clinics;
}

export function isConsumerUser(authenticatedUser: typeof CognitoUser) {
  if (!authenticatedUser) return false;
  const groups = getUserGroups(authenticatedUser);
  if (!groups) {
    return false;
  }
  return groups.includes("consumers");
}
