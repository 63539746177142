// @flow
import React, { useCallback, useState, useEffect } from "react";
import {
	Text,
	Box,
	Flex,
	Spacer,
	InputGroup,
	InputLeftElement,
	Input,
	Divider,
	Alert,
	AlertIcon, Checkbox
} from "@chakra-ui/react";

import COLORS from "../common/colors";
import {AiOutlineMail} from "react-icons/ai";
import PhoneInput, {isPossiblePhoneNumber} from "react-phone-number-input";
import PropTypes from "prop-types";
import isEmail from "validator/es/lib/isEmail";
import isMobilePhone from "validator/es/lib/isMobilePhone";
import ActionButton from "../common/ActionButton";
import {BsShare} from "react-icons/bs";
import InProgressImpl from "../common/InProgress";

type Props = {
  patient: Object,
  fontSize?: number,
	onCancel: any,
	onApply: any
};

export default function PilotShareWithPatient(props: Props) {
	const [error, setError] = useState(undefined);
	const [email, setEmail] = useState(props.patient.email);
	const [phone, setPhone] = useState(props.patient.phone);
	const [resetAccount, setResetAccount] = useState(false);
	const [applying, setApplying] = useState(false);

  const [useCellPhone, setUseCellPhone] = useState(false);
  const onUseCellPhone = useCallback((e)=> {
        setUseCellPhone(e.target.checked);
      }, [setUseCellPhone]
  )

	const updateError = useCallback(() => {
		let msg = undefined;
		if (email && !isEmail(email)) msg = "Invalid email address"
		else if (!email) msg = "Email address of the patient must be specified in order to create an account."
		else if (phone && useCellPhone && !isMobilePhone(phone)) msg = "Invalid cell phone number"
		// else if (!phone) msg = "Cell phone of the patient must be specified in order to create an account."
		setApplying(false);
		setError(msg);
	}, [email, phone, setError, useCellPhone]);
	const onEmailChange = useCallback((event) => {
		setEmail(event.target.value);
	}, []);
	const onPhoneChange = useCallback((phone) => {
		setPhone(phone);
	}, []);

	useEffect(() => {
		updateError()
	}, [updateError]);

	const propsOnApply = props.onApply;
	const username = props.patient.username;
	const onApply = useCallback(() => {
		setApplying(true);
		propsOnApply(email, useCellPhone ? phone : null, username ? username : null);
	}, [email, phone, username, propsOnApply, useCellPhone])

	const usernameAlreadyExists = !!props.patient.username;
	const onResetAccount = useCallback(() => setResetAccount(true), [setResetAccount] )

  return (
    <Box w="100%" fontSize={props.fontSize} color={COLORS.REPORT_TEXT}>
			<Flex align="center" mt="20px">
				<Text fontWeight="bold" fontSize={18} color={COLORS.REPORT_TEXT}>SHARE REPORTS WITH PATIENT</Text>
				<Spacer />
		    <Box as={BsShare} h="18px" w="18px" mb={0} />
			</Flex>
      <Divider my="20px"/>
			{usernameAlreadyExists && !resetAccount && <Box>
					<Text mb={"10px"}>The patient already has an account for report viewing.</Text>
					<Text mb={"10px"}>Press 'Reset' to create a new account.</Text>
					<Text my={"20px"} fontSize={12}>Recreating a new account will disable access via the existing account and create a new account for patient access.</Text>
			</Box>}
			{(!usernameAlreadyExists || resetAccount) && <Box mb={"15px"}>
					<Text mb={"10px"}>The system will create an account for the patient to view the reports. </Text>
					<Text w="475px" mt={"15px"} bg={COLORS.YELLOW_LIGHT} px="10px" py={"10px"}>Make sure that the email address is correct</Text>
			 	</Box>}
			{(!usernameAlreadyExists || resetAccount) && (
				<Box>
					<Flex align="center" mb="10px">
						<Text w="87px">Email:</Text>
						<InputGroup maxW="500px">
							{!email && <InputLeftElement pl={"5px"}>
								<Box
									as={AiOutlineMail}
									h="18px"
									w="18px"
									mt={"5px"}
								/>
							</InputLeftElement>
							}
							<Input
									color={COLORS.REPORT_TEXT}
									defaultValue={email}
									onChange={onEmailChange}
									fontSize={16}
									h={"45px"}
									w={"400px"}
									isDisabled={false}
									placeholder={"email address"}
									type={"email"}
							/>
						</InputGroup>
					</Flex>
					<Checkbox
						color={COLORS.REPORT_TEXT}
						size={"sm"}
						onChange={onUseCellPhone}
						mt={"15px"}
					>
						Use cell phone for additional sign in protection (optional)
					</Checkbox>
					{useCellPhone && <Flex align="center" mt="15px" fontSize={props.fontSize} >
						<Text w="75px">Phone:</Text>
						<Box borderColor={"gray.100"} borderWidth={1} h={"45px"} borderRadius={"5px"} fontSize={"13px"} pl={"10px"} w={"250px"} color={COLORS.REPORT_TEXT}>
							<PhoneInput
									// borderWidth={0}
									placeholder="phone number"
									value={phone}
									style={{
										height: "45px",
										fontSize: 16
										}}
									onChange={onPhoneChange}
									rules={{ validate: isPossiblePhoneNumber }}
									disabled={false}
							/>
						</Box>
					</Flex>}
					{error && (
						<Alert
							status={'error'}
							mt="20px"
							borderRadius="10px"
						>
							<AlertIcon />
							{error}
						</Alert>
					)}
				</Box>)
			}
			<Flex my="25px">
				<Spacer />
				<ActionButton name="Cancel" onClick={props.onCancel} mr="20px"/>
				{usernameAlreadyExists && !resetAccount && <ActionButton
						name={"Reset..."}
						onClick={onResetAccount}
						isDisabled={false}
						minW={"100px"}
					/>
				}
				{(!usernameAlreadyExists || resetAccount) && <ActionButton
						name={"Share"}
						onClick={onApply}
						isDisabled={!!error}
						minW={"100px"}
					/>
				}
				{applying && <InProgressImpl label="In progress, please wait..."/>}
				<Spacer />
			</Flex>
    </Box>
  );
}

PilotShareWithPatient.propTypes = {
  fontSize: PropTypes.number
};

PilotShareWithPatient.defaultProps = {
    fontSize: 16
};
