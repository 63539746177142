import React from "react";
import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent, AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay, Flex, Text
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import COLORS from "./colors";
import ActionButton from "./ActionButton";

type Props = {
	title: ?string,
  message: string,
	isOpen: boolean,
	onClose: any,
	parentRef: any,
	messageColor?: string,
	onCancel?: any,
	closeButtonText?: string,
	cancelButtonText?: string
};

export default function PopupMessage(props: Props) {
	return (
			<AlertDialog
					isOpen={props.isOpen}
					leastDestructiveRef={props.parentRef}
					onClose={props.onClose}
					color={COLORS.REPORT_TEXT}
			>
				<AlertDialogOverlay>
					<AlertDialogContent>
						{props.title && <AlertDialogHeader fontSize='lg' fontWeight='bold' color={COLORS.REPORT_TEXT_GRAY}>
							{props.title}
						</AlertDialogHeader>
						}
						<AlertDialogBody color={props.messageColor}>
							<Text>{props.message}</Text>
						</AlertDialogBody>
						<AlertDialogFooter color={COLORS.REPORT_TEXT}>
							<Flex align={"center"}>
								<ActionButton name={props.closeButtonText} ref={props.parentRef} onClick={props.onClose}/>
								{props.onCancel && <ActionButton ml="20px" name={props.cancelButtonText} ref={props.parentRef} onClick={props.onCancel}/>}
							</Flex>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
	);
}

PopupMessage.propTypes = {
  messageColor: PropTypes.string,
	closeButtonText: PropTypes.string,
	onCancel: PropTypes.any,
	cancelButtonText: PropTypes.string
};

PopupMessage.defaultProps = {
	messageColor: COLORS.REPORT_TEXT,
	closeButtonText: "Close",
	onCancel: undefined,
	cancelButtonText: "Cancel"
};
