// @flow
import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import COLORS from './colors';

type Props = {
  measurements: Object,
  treatments: Array<Object>,
  title: string,
  yTitle: string,
  traits: Array<string>,
  traitSectionCriteria: Object,
  maxHeight?: number,
  axisLabelFontSize?: string,
  yaxisOpposite?: boolean,
  legendLayout?: Object,
  chartMargins?: Object,
  chartType?: string
};

type State = {};

export default class TrendChartImpl extends React.Component<Props, State> {
  static defaultProps = {
    maxHeight: undefined,
    axisLabelFontSize: undefined,
    yaxisOpposite: true,
    chartType: 'line',
    chartMargins: {
      left: 0,
      right: 55
    },
    legendLayout: {
      align: 'left',
      verticalAlign: 'center',
      x: -15,
      y: 155,
      itemStyle: {
        color: COLORS.GRAY_128,
        fontWeight: 'normal'
      }
    }
  };
  constructor(props: Props) {
    super(props);
    (this: any).prepareDatasets = this.prepareDatasets.bind(this);
    (this: any).prepareTreatmentAnnotations = this.prepareTreatmentAnnotations.bind(
      this
    );
  }

  prepareDatasets() {
    const dataset = [];
    this.props.traits.forEach((trait, index) => {
      const traitData: Array<Object> = this.props.measurements[trait].map(
        measurement => ({
          x: Date.parse(measurement.eventDate),
          y: measurement.value
        })
      );

      dataset.push({
        type: this.props.chartType,
        name: trait.toUpperCase(),
        data: traitData,
        zones: [
          {
            value: this.props.traitSectionCriteria[index].thresh,
            color: this.props.traitSectionCriteria[index].getBelowThreshColor()
          },
          {
            color: this.props.traitSectionCriteria[index].getAboveThreshColor()
          }
        ],
        color: this.props.traitSectionCriteria[index].getSeriesColor()
      });
    });
    return dataset;
  }

  prepareTreatmentAnnotations() {
    const labels = [];
    const shapes = [];
    this.props.treatments.forEach(treatment => {
      if (treatment.prescription_date) {
        labels.push({
          point: {
            x: Date.parse(treatment.prescription_date),
            y: 200,
            xAxis: 0,
            yAxis: 0
          },
          text: `${treatment.drug}`
          // shape: 'rect'
        });
        shapes.push({
          fill: 'none',
          stroke: 'gray',
          strokeWidth: 1,
          dashStyle: 'Dot',
          type: 'path',
          points: [
            {
              x: Date.parse(treatment.prescription_date),
              y: 0,
              xAxis: 0,
              yAxis: 0
            },
            {
              x: Date.parse(treatment.prescription_date),
              y: 190,
              xAxis: 0,
              yAxis: 0
            }
          ]
        });
      }
    });
    return [
      {
        labelOptions: {
          backgroundColor: COLORS.HIGHCHARTS_TITLE,
          verticalAlign: 'bottom',
          borderWidth: 0.5,
          y: 10
        },
        labels,
        shapes
      }
    ];
  }

  render() {
    const options = {
      credits: {
        enabled: false
      },
      chart: {
        backgroundColor: 'transparent',
        style: {
          fontFamily: 'century-gothic'
        },
        marginLeft: this.props.chartMargins ? this.props.chartMargins.left : 0,
        marginRight: this.props.chartMargins
          ? this.props.chartMargins.right
          : 0,
        height:
          this.props.maxHeight && this.props.maxHeight > 0
            ? this.props.maxHeight
            : undefined
      },
      plotOptions: {
        series: {
          lineWidth: 2
        },
        spline: {
          marker: {
            // enabled: true,
            radius: 5,
            fillColor: '#FFFFFF',
            symbol: 'circle',
            lineWidth: 1,
            lineColor: null // inherit from series
          }
        },
        line: {
          marker: {
            // enabled: true,
            radius: 3,
            symbol: 'circle',
            lineWidth: 1,
            lineColor: null // inherit from series
          }
        }
      },
      title: {
        text: this.props.title,
        style: {
          color: COLORS.HIGHCHARTS_TITLE,
          fontSize: '12px',
          fontWeight: 'bold'
        }
      },
      xAxis: {
        type: 'datetime',
        labels: {
          formatter() {
            return Highcharts.dateFormat('%Y', this.value);
          },
          style: {
            fontSize: this.props.axisLabelFontSize
          }
        },
        title: {
          text: null
        }
      },
      yAxis: {
        title: {
          text: this.props.yTitle,
          style: {
            fontSize: this.props.axisLabelFontSize,
            fontWeight: 'bold'
          }
        },
        labels: {
          style: {
            fontSize: this.props.axisLabelFontSize
          }
        },
        visible: true,
        offset: 0,
        opposite: this.props.yaxisOpposite,
        gridLineDashStyle: 'dash'
      },
      legend: this.props.legendLayout,
      series: this.prepareDatasets(),
      annotations: this.prepareTreatmentAnnotations()
    };
    return <HighchartsReact highcharts={Highcharts} options={options} />;
  }
}
