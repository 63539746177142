// @flow
import React, { useCallback } from 'react';
import {Box, Button, Flex, IconButton, Image, Menu, MenuButton, MenuList, Spacer} from '@chakra-ui/react';
import withNavigation from '../main/WithRoutes';
import logo from '../images/logo.png';
import back from '../images/back-button-blue.png';

import SignOutButton from '../auth/SignOutButton';
import {HamburgerIcon} from "@chakra-ui/icons";
import {useAuthenticator} from "@aws-amplify/ui-react";
import routes from "../main/routes";

type Props = {
  navigate: any,
  mainMenuContent: any,
  location: any
  // callbackOnMenu: any
};

export function MobileTopBarImpl(props: Props) {
  const { navigate } = props;
  const onBack = useCallback(() => navigate(-1), [navigate]);
  const showBack = props.location.key !== "default";
  const { user, route } = useAuthenticator((context) => [context.user, context.route]);
  const isLoggedInUser = user && route === 'authenticated';
  const onLoginClick = useCallback(() => {
    navigate(routes.LOGIN);
  }, [navigate]);
  return (
    <Box
      w="100%"
      borderColor="gray.200"
      borderBottomWidth="3px"
      bg="transparent"
    >
      <Flex mx="2%" h="45px" align="center" justify="space-between">
        <Menu autoSelect={false}>
          <MenuButton
            as={IconButton}
            aria-label='Options'
            icon={<HamburgerIcon />}
            variant='outline'
            >
            Options
          </MenuButton>
          {/*<Image src={hamburger} h="20px" alt="Back" mx="10px" onClick={onMenu} />*/}
          <MenuList>{props.mainMenuContent}</MenuList>
        </Menu>

        {showBack && <Image src={back} h="20px" alt="Back" ml="10px" onClick={onBack}/>}
        <Spacer />
        <Image src={logo} h="20px" alt="Open-DNA" />
        <Spacer />
        {isLoggedInUser && <SignOutButton callbackOnSignedOut={null} />}
        {!isLoggedInUser && (
          <Button
            fontSize={16}
            w="80px"
            color="orange.300"
            bg="transparent"
            fontWeight="400"
            onClick={onLoginClick}
          >
            Login
          </Button>
        )}
      </Flex>
    </Box>
  );
}
export default withNavigation(MobileTopBarImpl);
