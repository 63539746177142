// @flow
import React, {useCallback, useState} from 'react';
import {Box, Checkbox, Divider, Flex, Spacer, Text} from '@chakra-ui/react';
import { v4 as uuid4 } from 'uuid';
import consentStrings from './consent-texts'
import COLORS from "../../common/colors";
import ActionButton from "../../common/ActionButton";
import withMobile from "../../common/mobile/withMobile";
import PropTypes from "prop-types";

type Props = {
  onConsent: any,
  isMobile: boolean,
  fontSize?: ?number,
  showContinue?: boolean,
  callbackOnConsentCheckChange?: any,
  decreaseVerticalSpacing?: boolean,
  consentAgreed?: boolean
};

function DemoMyGeneInsightsConsentImpl(props: Props) {
  const {onConsent, callbackOnConsentCheckChange} = props;
  console.log(props.consentAgreed)
  const [consentAgreed, setConsentAgreed] = useState(props.consentAgreed);
  const onConsentCheckChange = useCallback((e)=> {
        setConsentAgreed(e.target.checked);
        if (callbackOnConsentCheckChange) {
          callbackOnConsentCheckChange(e.target.checked);
        }
      }, [setConsentAgreed, callbackOnConsentCheckChange]
  )
  const onContinue = useCallback(()=>onConsent(), [onConsent]);
  const {isMobile} = props;
  let fontSize= isMobile ? 13 : 16;
  if (props.fontSize !== null && props.fontSize !== undefined) {
    fontSize = props.fontSize;
  }
  return (
    <Box mr={"0%"} color={COLORS.REPORT_TEXT_GRAY} fontSize={fontSize} mt={"10px"} overflowY="auto" maxHeight="500px">
      <Text fontWeight={"bold"} color={"gray.600"}>{consentStrings.MAIN_TITLE}</Text>
      {consentStrings.BODY.map(section=>
        <Box key={uuid4()}>
          <Text mt={"10px"} fontWeight={"bold"}>{section.Title}</Text>
          {section.Content.split('\n\n').map(p => <Text mt={"10px"} fontWeight={"normal"}>{p}</Text>)}
        </Box>
      )}
      <Divider mt={props.decreaseVerticalSpacing || isMobile ? "10px" : "20px"}/>
      <Checkbox
        color={COLORS.REPORT_TEXT}
        ml={isMobile ? "10px" : "20px"}
        size={"sm"}
        onChange={onConsentCheckChange}
        mt={props.decreaseVerticalSpacing ? '10px' : (isMobile ? "25px" : "30px")}
        defaultChecked={props.consentAgreed}
      >
        I understand and agree to proceed
      </Checkbox>
      {props.showContinue && <Flex mt={isMobile ? "25px" : "30px"}>
        <ActionButton
          color={COLORS.REPORT_TEXT}
          name="Continue..."
          onClick={onContinue}
          isDisabled={!consentAgreed}
        />
        <Spacer />
      </Flex>}
    </Box>
  );
}


DemoMyGeneInsightsConsentImpl.propTypes = {
  fontSize: PropTypes.number,
  showContinue: PropTypes.bool,
  callbackOnConsentCheckChange: PropTypes.any,
  decreaseVerticalSpacing: PropTypes.bool,
  consentAgreed: PropTypes.bool,
};
// $FlowFixMe[prop-missing]
DemoMyGeneInsightsConsentImpl.defaultProps = {
  fontSize: null,
  showContinue: true,
  callbackOnConsentCheckChange: null,
  decreaseVerticalSpacing: false,
  consentAgreed: false
};


export default withMobile(DemoMyGeneInsightsConsentImpl);