// @flow
export default function translateMeasurements(measurements: Object, func: any) {
  const outMeasurements = [];
  for (let i = 0; i < measurements.length; i += 1) {
    outMeasurements.push({
      eventDate: measurements[i].eventDate,
      value: func(measurements[i].value)
    });
  }
  return outMeasurements;
}
