// @flow
import React from 'react';

const NotFoundPage = () => (
  <div>
    <h1>404 - Page Not Found</h1>
    <p>The requested page cannot be found!</p>
  </div>
);

export default NotFoundPage;
