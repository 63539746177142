// @flow
import React from 'react';
import {Box, Flex, Text, Spacer, Image} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import IconTextAndLink from '../../common/IconTextAndLink';
import htnGaugeImage from '../../images/blood-pressure-gauge-light.png';
import dietImage from '../../images/diet.png';
import runningImage from '../../images/running.png';
import stressImage from '../../images/yoga.png';
import noSmokingImage from '../../images/no-smoking.png';
import firstAidImage from '../../images/first-aid-kit-blue.png';
// import {getCadRiskColor, getCadRiskLevel} from "../../common/risk-colors";
import {mmolL2mgdl} from "../../utils/cholesterol";
import COLORS from "../../common/colors";
import { GiMedicinePills } from "react-icons/gi"

type Props = {
  isMobile: boolean,
  smoking: boolean,
  statins: boolean,
  bpTreated: boolean,
  sbp: number,
  dbp: number,
  ldl: number,
  tc: number,
  hdl: number,
  risk: number,
  loneliness_isolation: boolean,
  usual_walking_pace: number,
  quitSmokingRiskEffect: number,
  bpTreatmentRiskEffect: number,
  statinsRiskEffect: number
};

export default function CadPersonalizedRecommendations(props: Props) {
  const statins = props.risk > 7.5;
  // const riskLevel = getCadRiskLevel(props.risk);
  // const riskColor = getCadRiskColor(props.risk);

  const isLdlGreen = mmolL2mgdl(props.ldl) < 100;
  const isHighLdl = mmolL2mgdl(props.ldl) >= 160;
  const hypertension = props.sbp >= 130 || props.dbp >= 90;
  const reductionColor = COLORS.GREEN_STATUS;
  const reductionWeight = "bold";

  return (
    <Box ml={"20px"} mt="10px" color={COLORS.REPORT_TEXT}>
      {/*<Flex w={"100%"} >*/}
      {/*  <Text >Patient risk level is:</Text>*/}
      {/*  <Text color={riskColor} ml="10px" mb={"15px"} fontWeight={"bold"}>{riskLevel.toLowerCase()}</Text>*/}
      {/*</Flex>*/}
      <Box color={"gray.600"} bg={"gray.50"} borderColor={"gray.400"} borderWidth={1} borderRadius={10}>
        <Box m="20px">
          <Flex w={"100%"} mt={"10px"} align={"center"}>
            <Spacer/>
            <Text as={"u"} fontSize={14} fontWeight={"bold"}>Treatment Suggestions</Text>
            <Spacer/>
            <Image src={firstAidImage} h={"32px"}/>
          </Flex>
          {!props.statins && statins && !isLdlGreen && <Box>
            <IconTextAndLink
              image={GiMedicinePills}
              text="Consider statin treatment to lower LDL level"
              linkText={null}
              link={null}
              isMobile={props.isMobile}
              size={'small'}
              mt={"20px"}
              />
            <Text ml={"30px"} fontSize={12} color={reductionColor} fontWeight={reductionWeight}>{`Lowering LDL level to 100 mg/dL is estimated to reduce 10 year risk by ${props.statinsRiskEffect.toFixed(1)}%`}</Text>
          </Box>
          }
          {props.statins && statins && isHighLdl && <Box>
            <IconTextAndLink
              image={GiMedicinePills}
              text="consider more intensive statin treatment"
              isMobile={props.isMobile}
              size={'small'}
              mt={"20px"}
              />
            <Text ml={"30px"} fontSize={12} color={reductionColor} fontWeight={reductionWeight}>{`Lowering LDL level to 100 mg/dL is estimated to reduce 10 year risk by ${props.statinsRiskEffect.toFixed(1)}%`}</Text>
          </Box>
          }
          {hypertension && !props.bpTreated && <Box>
            <IconTextAndLink
              image={GiMedicinePills}
              text="Consider anti-hypertension treatment"
              isMobile={props.isMobile}
              size={'small'}
              mt={"20px"}
              />
            <Text ml={"30px"} fontSize={12} color={reductionColor} fontWeight={reductionWeight}>{`Lowering SBP level to 120 mmHg is estimated to reduce 10 year risk by ${props.bpTreatmentRiskEffect.toFixed(1)}%`}</Text>
          </Box>
          }
          {hypertension && props.bpTreated && <Box>
            <IconTextAndLink
              image={GiMedicinePills}
              text="Consider changing or intensifying anti-hypertension treatment to have blood pressure controlled"
              isMobile={props.isMobile}
              size={'small'}
              mt={"20px"}
              />
            <Text ml={"30px"} fontSize={12} color={reductionColor} fontWeight={reductionWeight}>{`Lowering SBP level to 120 mmHg is estimated to reduce 10 year risk by ${props.bpTreatmentRiskEffect.toFixed(1)}%`}</Text>
          </Box>
          }
          {props.smoking && <Box>
            <IconTextAndLink
              imageSrc={noSmokingImage}
              text="Smoking cessation"
              linkText="more info..."
              link="https://www.heart.org/en/healthy-living/healthy-lifestyle/quit-smoking-tobacco"
              isMobile={props.isMobile}
              size={'small'}
              mt={"20px"}
              linkStyle={'link'}
              textWidth={"65%"}
              />
            <Text ml={"30px"} fontSize={12} color={reductionColor} fontWeight={reductionWeight}>{`Quit smoking is estimated to reduce 10 year risk by ${props.quitSmokingRiskEffect.toFixed(1)}%`}</Text>
          </Box>
          }
          {(props.bpTreated || hypertension) && <IconTextAndLink
            imageSrc={htnGaugeImage}
            text="Monitoring blood pressure regularly"
            linkText="more info..."
            link="https://www.heart.org/en/health-topics/high-blood-pressure/understanding-blood-pressure-readings/monitoring-your-blood-pressure-at-home"
            isMobile={props.isMobile}
            size={'small'}
            mt={"10px"}
            linkStyle={'link'}
            textWidth={"65%"}
          />
          }
          <IconTextAndLink
            imageSrc={runningImage}
            text="Exercise"
            linkText="more info..."
            link="https://www.heart.org/en/healthy-living/fitness/fitness-basics/aha-recs-for-physical-activity-in-adults"
            isMobile={props.isMobile}
            size={'small'}
            mt={"10px"}
            linkStyle={'link'}
            textWidth={"65%"}
          />
          <IconTextAndLink
            imageSrc={stressImage}
            text="Stress reduction"
            linkText="more info..."
            link="https://www.heart.org/en/healthy-living/healthy-lifestyle/stress-management"
            isMobile={props.isMobile}
            size={'small'}
            mt={"10px"}
            linkStyle={'link'}
            textWidth={"65%"}
          />
          <IconTextAndLink
            imageSrc={dietImage}
            text="Diet"
            linkText="more info..."
            link="https://www.heart.org/en/healthy-living/healthy-eating/losing-weight"
            isMobile={props.isMobile}
            size={'small'}
            mt={"10px"}
            linkStyle={'link'}
            textWidth={"65%"}
          />
        </Box>
      </Box>
    </Box>
  );
}

CadPersonalizedRecommendations.propTypes = {
  isMobile: PropTypes.bool
};

CadPersonalizedRecommendations.defaultProps = {
  isMobile: false
};
