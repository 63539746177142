// @flow
import React from 'react';
import upRed from '../../images/up-red.png';
import downGreen from '../../images/down-green.png';

import COLORS from '../../common/colors';

type Props = {
  age: ?number,
  sex: number,
  bmi: ?number,
  sbpScore: number,
  dbpScore: number,
  sbpLinReg: Object,
  dbpLinReg: Object,
  sbpQuartile: number,
  sbpPercentile: number,
  dbpQuartile: number,
  dbpPercentile: number,
  callbackOnChange: any,
  orderLabs: boolean
};

type State = {
  age: number,
  bmi: number,
  sex: number,
  recentSbpChange: number,
  recentDbpChange: number,
  recentMinSbp: number,
  recentMaxSbp: number,
  recentMinDbp: number,
  recentMaxDbp: number,
  gender: ?string,
  playingEnabled: boolean,
  error: ?string,
  shortSbpText: string,
  longSbpText: string,
  sbpTrendIcon: any,
  sbpColor: string,
  shortDbpText: string,
  longDbpText: string,
  dbpTrendIcon: any,
  dbpColor: string
};

export default function withBpCalculator(WrappedComponent: any) {
  return class extends React.Component<Props, State> {
    constructor(props: Props) {
      super(props);
      (this: any).recalcBpChanges = this.recalcBpChanges.bind(this);
      (this: any).onAgeSliderChange = this.onAgeSliderChange.bind(this);
      (this: any).getAgeDiffText = this.getAgeDiffText.bind(this);
      (this: any).onBmiSliderChange = this.onBmiSliderChange.bind(this);
      (this: any).getBmiDiffText = this.getBmiDiffText.bind(this);
      (this: any).calcExpectedDbpChange = this.calcExpectedDbpChange.bind(this);
      (this: any).calcExpectedSbpChange = this.calcExpectedSbpChange.bind(this);
      (this: any).runLinRegDbp = this.runLinRegDbp.bind(this);
      (this: any).runLinRegSbp = this.runLinRegSbp.bind(this);
      (this: any).onResetAttributes = this.onResetAttributes.bind(this);
      (this: any).getSliderBmiColor = this.getSliderBmiColor.bind(this);
      (this: any).onGenderSelected = this.onGenderSelected.bind(this);
      (this: any).onCurrentAgeChange = this.onCurrentAgeChange.bind(this);
      (this: any).onCurrentBmiChange = this.onCurrentBmiChange.bind(this);
      (this: any).getError = this.getError.bind(this);

      this.BMI_ANALYSIS_LOW_LIMIT = 13;
      this.BMI_ANALYSIS_HIGH_LIMIT = 68;
      this.resetBmi = this.getDefaultBmi();
      this.AGE_ANALYSIS_LOW_LIMIT = 38;
      this.AGE_ANALYSIS_HIGH_LIMIT = 73;
      this.resetAge = this.getDefaultAge();
      const defaultGender = this.getDefaultGender();
      this.manualCurrentBmi = this.props.bmi ? this.props.bmi : undefined;
      this.manualCurrentAge = this.props.age ? this.props.age : undefined;
      this.minBmi =
        this.props.bmi && this.props.bmi < 18.5 ? this.props.bmi : 18.5;
      if (this.minBmi < this.BMI_ANALYSIS_LOW_LIMIT)
        this.minBmi = this.BMI_ANALYSIS_LOW_LIMIT;
      this.maxBmi =
        this.props.bmi && this.props.bmi > 40
          ? this.BMI_ANALYSIS_HIGH_LIMIT
          : 40;
      this.minAge = this.props.age ? this.props.age : this.resetAge;
      if (this.minAge < this.AGE_ANALYSIS_LOW_LIMIT)
        this.minAge = this.AGE_ANALYSIS_LOW_LIMIT;
      if (this.minAge > this.AGE_ANALYSIS_HIGH_LIMIT)
        this.minAge = this.AGE_ANALYSIS_HIGH_LIMIT;
      this.maxAge = this.AGE_ANALYSIS_HIGH_LIMIT;

      this.state = {
        age: this.resetAge,
        bmi: this.resetBmi,
        gender: defaultGender,
        sex: this.props.sex === undefined ? 0 : this.props.sex,
        recentSbpChange: 0,
        recentMinSbp: -10,
        recentMaxSbp: 10,
        recentDbpChange: 0,
        recentMinDbp: -10,
        recentMaxDbp: 10,
        playingEnabled: !!defaultGender,
        error: !defaultGender ? 'Select gender' : undefined,
        shortSbpText: '',
        longSbpText: '',
        sbpTrendIcon: undefined,
        sbpColor: 'transparent',
        shortDbpText: '',
        longDbpText: '',
        dbpTrendIcon: undefined,
        dbpColor: 'transparent'
      };
    }

    onGenderSelected(eventOrValue: any) {
      const strGender: string = eventOrValue.target
        ? eventOrValue.value
        : eventOrValue;
      let gender: ?string;
      let sex = 0;
      if (strGender === 'male') {
        gender = 'male';
        sex = 1;
      }
      if (strGender === 'female') {
        gender = 'female';
        sex = 0;
      }
      this.setState({
        gender,
        sex,
        playingEnabled:
          gender !== undefined && gender !== null && gender.length > 0,
        error: this.getErrorsWithGenderArg(gender)
      });
    }

    onCurrentBmiChange(val: number) {
      const newBmi = parseInt(val, 10);
      this.manualCurrentBmi = newBmi;
      if (
        Number.isNaN(newBmi) ||
        newBmi < this.BMI_ANALYSIS_LOW_LIMIT ||
        newBmi > this.BMI_ANALYSIS_HIGH_LIMIT
      ) {
        this.setState({
          error: this.getError()
        });
        return;
      }

      this.minBmi = newBmi < 18.5 ? newBmi : 18.5;
      if (newBmi < this.BMI_ANALYSIS_LOW_LIMIT)
        this.minBmi = this.BMI_ANALYSIS_LOW_LIMIT;
      this.maxBmi = newBmi > 40 ? newBmi : 40;

      this.resetBmi = newBmi;
      this.setState(
        {
          bmi: newBmi,
          error: this.getError()
        },
        this.onResetAttributes
      );
    }

    onCurrentAgeChange(e: any) {
      const newAge = e.target ? parseInt(e.target.value, 10) : e;
      this.manualCurrentAge = newAge;
      if (
        Number.isNaN(newAge) ||
        newAge < this.AGE_ANALYSIS_LOW_LIMIT ||
        newAge > this.AGE_ANALYSIS_HIGH_LIMIT
      ) {
        this.setState({
          error: this.getError()
        });
      }

      this.minAge = newAge;
      this.resetAge = newAge;
      this.setState(
        {
          age: newAge,
          error: this.getError()
        },
        this.onResetAttributes
      );
    }

    onAgeSliderChange(newAge: number) {
      this.setState(
        {
          age: newAge
        },
        this.recalcBpChanges
      );
    }

    onBmiSliderChange(newBmi: number) {
      this.setState(
        {
          bmi: newBmi
        },
        this.recalcBpChanges
      );
    }

    onResetAttributes() {
      this.setState(
        {
          age: this.resetAge,
          bmi: this.resetBmi,
          recentSbpChange: 0,
          recentMinSbp: -10,
          recentMaxSbp: 10,
          recentDbpChange: 0,
          recentMinDbp: -10,
          recentMaxDbp: 10
        },
        this.recalcBpChanges
      );
    }

    getError() {
      return this.getErrorsWithGenderArg(this.state.gender);
    }

    getErrorsWithGenderArg(gender: ?string) {
      if (!gender) return 'Select gender';
      if (!this.manualCurrentAge || Number.isNaN(this.manualCurrentAge))
        return 'Fill in your current age';
      if (
        this.manualCurrentAge < this.AGE_ANALYSIS_LOW_LIMIT ||
        this.manualCurrentAge > this.AGE_ANALYSIS_HIGH_LIMIT
      ) {
        return `Allowed range for age is ${this.AGE_ANALYSIS_LOW_LIMIT} - ${this.AGE_ANALYSIS_HIGH_LIMIT}`;
      }
      if (!this.manualCurrentBmi || Number.isNaN(this.manualCurrentBmi))
        return 'Fill in your current BMI';
      if (
        this.manualCurrentBmi < this.BMI_ANALYSIS_LOW_LIMIT ||
        this.manualCurrentBmi > this.BMI_ANALYSIS_HIGH_LIMIT
      ) {
        return `Allowed range for BMI is ${this.BMI_ANALYSIS_LOW_LIMIT} - ${this.BMI_ANALYSIS_HIGH_LIMIT}`;
      }
      return undefined;
    }

    getDefaultAge() {
      let { age } = this.props;
      if (!age) age = this.AGE_ANALYSIS_LOW_LIMIT;
      if (age < this.AGE_ANALYSIS_LOW_LIMIT) age = this.AGE_ANALYSIS_LOW_LIMIT;
      if (age > this.AGE_ANALYSIS_HIGH_LIMIT)
        age = this.AGE_ANALYSIS_HIGH_LIMIT;
      return age;
    }

    getDefaultGender() {
      const { sex } = this.props;
      let gender;
      if (sex === 0) gender = 'female';
      if (sex === 1) gender = 'male';
      return gender;
    }

    getDefaultBmi() {
      if (!this.props.bmi) return 25;
      if (this.props.bmi < this.BMI_ANALYSIS_LOW_LIMIT)
        return this.BMI_ANALYSIS_LOW_LIMIT;
      if (this.props.bmi > this.BMI_ANALYSIS_HIGH_LIMIT)
        return this.BMI_ANALYSIS_HIGH_LIMIT;
      const bmi: number = this.props.bmi ? this.props.bmi : 25;
      return bmi;
    }

    getChangeDetails(expectedBp: number, currentBp: number, isSbp: boolean) {
      const diff: number = expectedBp - currentBp;
      const fixedDiff = Math.abs(diff).toFixed(1);
      const isSignificantDiff = fixedDiff !== '0.0';
      const sign: string = diff >= 0 ? '+' : '-';
      const shortBpChange = `${sign} ${Math.abs(diff).toFixed(1)}  mmHg`;
      let color = COLORS.REPORT_TEXT;
      let trend: string = '';
      if (diff !== 0) {
        trend = diff > 0 ? 'increase' : 'decrease';
      }
      const genderText = this.state.sex === 1 ? 'men' : 'women';
      const ageDiff: number = this.state.age - this.resetAge;
      const ageTrend: string = ageDiff > 0 ? 'increase' : 'decrease';
      const bmiDiff: number = this.state.bmi - this.resetBmi;
      const bmiTrend: string = bmiDiff > 0 ? 'increase' : 'decrease';
      const trait = isSbp ? 'systolic' : 'diastolic';
      if (isSignificantDiff) {
        color = diff > 0 ? COLORS.RED_STATUS : COLORS.GREEN_STATUS;
      }

      let longText = '';
      if (ageDiff !== 0 && bmiDiff !== 0) {
        longText = `In ${genderText} population, an age ${ageTrend} by ${ageDiff.toFixed(
          0
        )} years and a BMI ${bmiTrend} by ${Math.abs(bmiDiff).toFixed(
          1
        )} points are expected to have a mean ${trend} of ${trait} blood pressure by ${diff.toFixed(
          1
        )} mmHg.`;
      } else if (ageDiff !== 0) {
        longText = `In ${genderText} population of the same BMI, an age ${ageTrend} by ${ageDiff.toFixed(
          0
        )} years is expected to have a mean ${trend} of ${trait} blood pressure by ${diff.toFixed(
          1
        )} mmHg.`;
      } else if (bmiDiff !== 0) {
        longText = `In ${genderText} population of the same age, a BMI ${bmiTrend} by ${Math.abs(
          bmiDiff
        ).toFixed(
          1
        )} points is expected to have a mean ${trend} of ${trait} blood pressure by ${diff.toFixed(
          1
        )} mmHg.`;
      } else {
        return {
          shortBpChange: '0 mmHg',
          longText: '',
          diffIcon: undefined,
          color: 'transparent'
        };
      }
      let diffIcon: any = null;
      if (diff > 0) diffIcon = upRed;
      if (diff < 0) diffIcon = downGreen;
      return {
        shortBpChange,
        longText,
        diffIcon,
        color
      };
    }

    getBmiDiffText() {
      if (this.state.bmi === this.resetBmi) return 'current';
      const diff: number = this.state.bmi - this.resetBmi;
      const sign: string = diff > 0 ? '+' : '-';
      return `${sign} ${Math.abs(diff).toFixed(1)} units`;
    }

    getAgeDiffText() {
      if (this.state.age > this.resetAge)
        return `+ ${this.state.age - this.resetAge} years`;
      return 'current';
    }

    getSliderBmiColor() {
      if (this.state.bmi > 30) return COLORS.RED_STATUS;
      if (this.state.bmi < 18.5 || this.state.bmi > 25)
        return COLORS.YELLOW_STATUS;
      return COLORS.GREEN_STATUS;
    }

    recalcBpChanges() {
      const sbpChange = this.calcExpectedSbpChange();
      const dbpChange = this.calcExpectedDbpChange();

      let newState: any = {};
      if (
        sbpChange.sbpDiff !== this.state.recentDbpChange ||
        dbpChange.dbpDiff !== this.state.recentSbpChange
      ) {
        newState = {
          recentSbpChange: sbpChange.sbpDiff,
          recentMaxSbp: sbpChange.sbpMaxAbsExpected,
          recentMinSbp: -sbpChange.sbpMaxAbsExpected,
          recentDbpChange: dbpChange.dbpDiff,
          recentMaxDbp: dbpChange.dbpMaxAbsExpected,
          recentMinDbp: -dbpChange.dbpMaxAbsExpected
        };
      }
      newState.shortSbpText = sbpChange.shortSbpText;
      newState.longSbpText = sbpChange.longSbpText;
      newState.sbpTrendIcon = sbpChange.sbpTrendIcon;
      newState.sbpColor = sbpChange.sbpColor;
      newState.shortDbpText = dbpChange.shortDbpText;
      newState.longDbpText = dbpChange.longDbpText;
      newState.dbpTrendIcon = dbpChange.dbpTrendIcon;
      newState.dbpColor = dbpChange.dbpColor;

      this.setState(newState);
    }

    BMI_ANALYSIS_LOW_LIMIT: number;
    BMI_ANALYSIS_HIGH_LIMIT: number;
    AGE_ANALYSIS_LOW_LIMIT: number;
    AGE_ANALYSIS_HIGH_LIMIT: number;
    minBmi: number;
    maxBmi: number;
    resetBmi: number;
    minAge: number;
    maxAge: number;
    resetAge: number;
    manualCurrentAge: ?number;
    manualCurrentBmi: ?number;

    calcExpectedSbpChange() {
      const current = this.runLinRegSbp(
        this.state.sex,
        this.resetAge,
        this.resetBmi
      );
      const expected = this.runLinRegSbp(
        this.state.sex,
        this.state.age,
        this.state.bmi
      );
      const maxExpected: number = this.runLinRegSbp(
        this.state.sex,
        this.maxAge,
        this.maxBmi
      );
      const minExpected: number = this.runLinRegSbp(
        this.state.sex,
        this.minAge,
        this.minBmi
      );
      const sbpDiff = expected - current;

      const sbpMaxAbsExpected = Math.max(
        Math.abs(maxExpected - current),
        Math.abs(minExpected - current)
      );
      const {
        shortBpChange,
        longText,
        diffIcon,
        color
      } = this.getChangeDetails(expected, current, true);
      return {
        shortSbpText: shortBpChange,
        longSbpText: longText,
        sbpTrendIcon: diffIcon,
        sbpColor: color,
        sbpDiff,
        sbpMaxAbsExpected
      };
    }

    calcExpectedDbpChange() {
      const current: number = this.runLinRegDbp(
        this.state.sex,
        this.resetAge,
        this.resetBmi
      );
      const expected: number = this.runLinRegDbp(
        this.state.sex,
        this.state.age,
        this.state.bmi
      );
      const maxExpected: number =
        this.runLinRegDbp(this.state.sex, this.maxAge, this.maxBmi) + 1;
      const minExpected: number = this.runLinRegDbp(
        this.state.sex,
        this.minAge,
        this.minBmi
      );
      const dbpDiff = expected - current;

      const dbpMaxAbsExpected: number = Math.max(
        Math.abs(maxExpected - current),
        Math.abs(minExpected - current)
      );
      const {
        shortBpChange,
        longText,
        diffIcon,
        color
      } = this.getChangeDetails(expected, current, false);
      return {
        shortDbpText: shortBpChange,
        longDbpText: longText,
        dbpTrendIcon: diffIcon,
        dbpColor: color,
        dbpDiff,
        dbpMaxAbsExpected
      };
    }

    runLinRegSbp(sex: number, age: number, bmi: number) {
      const ageSquare: number = age * age;
      const sbp: number =
        sex * this.props.sbpLinReg.coefficients.sex_1 +
        bmi * this.props.sbpLinReg.coefficients.bmi_1 +
        age * this.props.sbpLinReg.coefficients.age_1 +
        ageSquare * this.props.sbpLinReg.coefficients.age_square +
        this.props.sbpScore *
          this.props.sbpLinReg.coefficients.stdized_overall_risk_sbp +
        this.props.sbpLinReg.constant;
      return sbp;
    }

    runLinRegDbp(sex: number, age: number, bmi: number) {
      const ageSquare: number = age * age;
      const dbp: number =
        sex * this.props.dbpLinReg.coefficients.sex_1 +
        bmi * this.props.dbpLinReg.coefficients.bmi_1 +
        age * this.props.dbpLinReg.coefficients.age_1 +
        ageSquare * this.props.dbpLinReg.coefficients.age_square +
        this.props.dbpScore *
          this.props.dbpLinReg.coefficients.stdized_overall_risk_dbp +
        this.props.dbpLinReg.constant;
      return dbp;
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          onCurrentAgeChange={this.onCurrentAgeChange}
          onAgeSliderChange={this.onAgeSliderChange}
          getAgeDiffText={this.getAgeDiffText}
          onCurrentBmiChange={this.onCurrentBmiChange}
          onBmiSliderChange={this.onBmiSliderChange}
          getBmiDiffText={this.getBmiDiffText}
          getSliderBmiColor={this.getSliderBmiColor}
          onGenderSelected={this.onGenderSelected}
          onResetAttributes={this.onResetAttributes}
          ageLowLimit={this.minAge}
          ageHighLimit={this.maxAge}
          bmiLowLimit={this.minBmi}
          bmiHighLimit={this.maxBmi}
          age={this.state.age}
          bmi={this.state.bmi}
          currentAge={this.manualCurrentAge}
          currentBmi={this.manualCurrentBmi}
          recentSbpChange={this.state.recentSbpChange}
          recentDbpChange={this.state.recentDbpChange}
          recentMinSbp={this.state.recentMinSbp}
          recentMaxSbp={this.state.recentMaxSbp}
          recentMinDbp={this.state.recentMinDbp}
          recentMaxDbp={this.state.recentMaxDbp}
          gender={this.state.gender}
          playingEnabled={this.state.playingEnabled}
          error={this.state.error}
          shortSbpText={this.state.shortSbpText}
          longSbpText={this.state.longSbpText}
          sbpTrendIcon={this.state.sbpTrendIcon}
          sbpColor={this.state.sbpColor}
          shortDbpText={this.state.shortDbpText}
          longDbpText={this.state.longDbpText}
          dbpTrendIcon={this.state.dbpTrendIcon}
          dbpColor={this.state.dbpColor}
        />
      );
    }
  };
}
