// @flow
import React from 'react';

import { Box, Image as ChakraImage } from '@chakra-ui/react';

type Props = {
  percentile: number,
  iconHeight: number,
  iconWidth: number,
  image1: string,
  image2: string
};

type State = {};

export default class MergePersonImageImpl extends React.Component<
  Props,
  State
> {
  constructor() {
    super();
    this.canvasRef = React.createRef();
    (this: any).onFirstImageLoad = this.onFirstImageLoad.bind(this);
    (this: any).onSecondImageLoad = this.onSecondImageLoad.bind(this);
  }

  componentDidMount() {
    this.context = this.canvasRef.current.getContext('2d');
  }

  onFirstImageLoad(event: any) {
    this.image1 = event.target;
  }

  onSecondImageLoad(event: any) {
    this.image2 = event.target;
    const mergePercetile = (10 + 0.8 * (100 * this.props.percentile)) / 100;
    const img = this.mergeImage(this.image1, this.image2, mergePercetile);
    this.context.drawImage(
      img,
      0,
      0,
      this.props.iconWidth,
      this.props.iconHeight
    );
  }
  canvasRef: any;
  image1: any;
  image2: any;

  imgToData(img: any) {
    for (let i = 0; i < 3; i += 1) {
      try {
        return this.rawImgToData(img);
      } catch (e) {
        console.log(e);
      }
    }
    return null;
  }
  rawImgToData(img: any) {
    this.context.drawImage(
      img,
      0,
      0,
      this.props.iconWidth,
      this.props.iconHeight
    );
    return this.context.getImageData(
      0,
      0,
      this.props.iconWidth,
      this.props.iconHeight
    );
  }

  combine(a: any, b: any, xPercentile: number) {
    const c = a;
    for (let i = 0; i < a.data.length; i += 1) {
      const x = (i / 4) % c.width;
      if (x > xPercentile * this.props.iconWidth) {
        c.data[i] = b.data[i];
      } else c.data[i] = a.data[i];
    }
    return c;
  }

  dataToImg(a: any) {
    const c = document.createElement('canvas');
    c.width = a.width;
    c.height = a.height;
    this.context.putImageData(a, 0, 0);
    const img = new Image();
    img.src = c.toDataURL();
    return img;
  }

  mergeImage(img1: any, img2: any, xPercentile: number) {
    const data1 = this.imgToData(img1);
    const data2 = this.imgToData(img2);
    const combined = this.combine(data1, data2, xPercentile);
    return this.dataToImg(combined);
  }

  render() {
    const heightPx = `${this.props.iconHeight}px`;
    const widthPx = `${this.props.iconWidth}px`;
    return (
      <Box h={heightPx} w={widthPx}>
        <ChakraImage
          src={this.props.image1}
          onLoad={this.onFirstImageLoad}
          h={0}
          w={0}
        />
        <ChakraImage
          src={this.props.image2}
          onLoad={this.onSecondImageLoad}
          h={0}
          w={0}
        />
        <canvas
          ref={this.canvasRef}
          width={this.props.iconWidth}
          height={this.props.iconHeight}
        />
      </Box>
    );
  }
}
