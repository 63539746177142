import {changeTransparency} from "./colors";
import {getCadRiskColor, getRiskColorByPercentile} from "./risk-colors";

export function getDefaultRiskPlotBands() {
  return [
      {
        from: 0,
        to: 50,
        color: changeTransparency(getRiskColorByPercentile(25), 0.5),
        thickness: '40%'
      },
      {
        from: 50,
        to: 70,
        color: changeTransparency(getRiskColorByPercentile(60), 0.5),
        thickness: '40%'
      },
      {
        from: 70,
        to: 85,
        color: changeTransparency(getRiskColorByPercentile(80), 0.5),
        thickness: '40%'
      },
      {
        from: 85,
        to: 95,
        color: changeTransparency(getRiskColorByPercentile(90), 0.5),
        thickness: '40%'
      },
      {
        from: 95,
        to: 100,
        color: changeTransparency(getRiskColorByPercentile(97), 0.5),
        thickness: '40%'
      }
    ];
}

export function getCadRiskPlotBands() {
   return [
      {
        from: 0,
        to: 5,
        color: changeTransparency(getCadRiskColor(2), 0.5),
        thickness: '40%',
        label: null
      },
      {
        from: 5,
        to: 7.5,
        color: changeTransparency(getCadRiskColor(6), 0.5),
        thickness: '40%',
        label: null
      },
      {
        from: 7.5,
        to: 20,
        color: changeTransparency(getCadRiskColor(10), 0.5),
        thickness: '40%',
        label: null
      },
      {
        from: 20,
        to: 30,
        color: changeTransparency(getCadRiskColor(30), 0.5),
        thickness: '40%',
        label: null
      }
    ];
}

export function getCadOuterPanePlotBands(plotBands: Array<Object>) {
  return [
     {
       from: 0,
       to: 5,
       color: 'transparent',
       thickness: '1%',
         label: {
           textAlign: 'right',
           text: 'low',
           align: 'right',
           style: {
             color: changeTransparency(getCadRiskColor(2), 1),
             fontWeight: 'bold'
           }
        }
     },
     {
       from: 5,
       to: 7.5,
       color: 'transparent',
       thickness: '1%',
       label: {
         textAlign: 'right',
         text: 'borderline',
         align: 'right',
         y: 15,
         style: {
           color: changeTransparency(getCadRiskColor(6), 1),
           fontWeight: 'bold'
         }
       }
    },
    {
      from: 7.5,
      to: 7.5,
      color: 'transparent',
      thickness: '1%',
      label: {
          text: '<b>statins</b><br/>(7.5%)',
          textAlign: 'right',
          align: 'right',
          y: -10,
          style: {
            color: 'black',
            fontWeight: 'normal',
            fontSize: 13
          }
      }
    },
    {
      from: 7.5,
      to: 20,
      color: 'transparent',
      thickness: '1%',
      label: {
        verticalAlign: 'top',
        textAlign: 'right',
        text: 'intermediate',
        align: 'right',
        x: 30,
        y: 15,
        style: {
          color: changeTransparency(getCadRiskColor(10), 0.5),
          fontWeight: 'bold'
        }
      }
    },
    {
      from: 20,
      to: 30,
      color: 'transparent',
      thickness: '1%',
      label: {
            inside: true,
            text: 'high',
            textAlign: 'right',
            align: 'left',
            //rotate: 45
            style: {
              color: changeTransparency(getCadRiskColor(30), 0.5),
              fontWeight: 'bold'
            }
        }
    }
  ];
}