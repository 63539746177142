import React, {useCallback, useState} from "react";
import {
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps, Box, Flex, Spacer, Text, Stack, Checkbox, Image, Divider,
} from '@chakra-ui/react'
import { ArrowForwardIcon, ArrowBackIcon } from '@chakra-ui/icons'
import COLORS from "../../common/colors";
import ActionButtonImpl from "../../common/ActionButton";
// import {Authenticator} from "@aws-amplify/ui-react";
// import {amplifyComponents, amplifyFormFields} from "../../auth/amplify-components";
import Consent from "./DemoMyGeneInsightsConsent";
import DemoOrderTest from "./DemoOrderTest";
import DemoSignUp from "./DemoSignUp";
import opendnaBackround from "../../images/OpenDNA_background.png";
import opendnaBackround2 from "../../images/OpenDNA_background2.png";
import opendnaBackround3 from "../../images/OpenDNA_background3.png";

const steps = [
  { title: 'Sign up', description: null },
  { title: 'Consent', description: null },
  { title: 'Order test', description: null },
  { title: 'End', description: null }
]

export function MyGeneInsightsWorkflow() {
  const { activeStep, setActiveStep } = useSteps({
    index: 1,
    count: steps.length,
  })
  const [ consent, setConsent ] = useState(false)
  const [ isSignUpCompleted, setIsSignUpCompleted ] = useState(false)

  const onNextStep = useCallback(()=>{
      setActiveStep(activeStep + 1)
    }, [setActiveStep, activeStep]
  )
  const onPreviousStep = useCallback(()=>{
      setActiveStep(activeStep - 1)
    }, [setActiveStep, activeStep]
  )
  const onConsentCallbackChange = useCallback((isChecked)=>{
      setConsent(isChecked)
    }, [setConsent]
  )
  const onSignUpChange = useCallback((completed)=>{
      setIsSignUpCompleted(completed)
    }, [setIsSignUpCompleted]);

  if (isSignUpCompleted) {
    console.log("all conditions met for sign up")
  }
  return (
    <Flex minH={"93vh"}>
      <Box bg={"gray.50"}>
        <Stepper index={activeStep} orientation='vertical' height='500px' gap='2' colorScheme={'blue'} pl={"40px"} pr={"30px"} mt={"50px"}>
          {steps.map((step, index) => (
            <Step key={index}>
              <StepIndicator>
                <StepStatus
                  complete={<StepIcon />}
                  incomplete={<StepNumber />}
                  active={<StepNumber />}
                />
              </StepIndicator>

              <Box flexShrink='0'>
                <StepTitle>{step.title}</StepTitle>
                <StepDescription>{step.description}</StepDescription>
              </Box>

              <StepSeparator />
            </Step>
          ))}
        </Stepper>
      </Box>
      <Box ml={"5%"} mr="2%" mt="32px">
        <Box minH={"580px"} color={COLORS.REPORT_TEXT}>
          <Box my={"10px"}>
            {activeStep === 1 && <DemoSignUp callbackOnEditChange={onSignUpChange}/>}
            {activeStep === 2 &&
              <Box ml={"20px"} mt={"20px"} minH={"500px"} color={COLORS.REPORT_TEXT}>
                <Text fontWeight={"bold"} fontSize={17}>Welcome to the OpenDNA system!</Text>
                <Text mt={"6px"} mb={"15px"}>In order to continue you must read the following information and provide your consent.</Text>
                <Divider maxW={"700px"}/>
                <Consent callbackOnConsentCheckChange={onConsentCallbackChange} fontSize={14} showContinue={false} decreaseVerticalSpacing={true} consentAgreed={consent}/>
              </Box>}
            {activeStep === 3 && <Box minH={"500px"} minW={"85%"} >
              <DemoOrderTest />
            </Box>}
            {activeStep === 4 &&
              <Flex>
                <Stack mx={"20px"} minH={"500px"} spacing={"30px"} color={COLORS.REPORT_TEXT} mt={"10px"}>
                  <Text fontWeight={"bold"} mb={"10px"}>Congratulations!</Text>
                  <Text>You have just completed ordering the test.</Text>
                  <Text>A genetic test kit will be sent to the address you provided.</Text>
                  <Text>Follow the instructions in the test kit to perform the test, and then send the sample to the lab.</Text>
                  <Text></Text>
                  <Text></Text>
                  <Text color={COLORS.REPORT_TEXT_GRAY}>You can get notifications about the kit location and the test status.</Text>
                  <Checkbox
                    size={"sm"}
                    defaultChecked mt={"-15px"}>I wish to receive kit location and test progress notifications</Checkbox>
                </Stack>
                {/*<Image src={getPerformSend} h="500px" alt="" ml="100px" mt={"0px"}/>*/}
              </Flex> }
          </Box>
        </Box>
        <Flex my={"20px"}>
          <ActionButtonImpl
            name={"Previous" }
            fontWeight="bold"
            fontSize={14}
            bg="gray.100"
            color={COLORS.REPORT_TEXT}
            h="40px"
            w="180px"
            mx="20px"
            onClick={onPreviousStep}
            isDisabled={activeStep === 1}
            leftIcon={<ArrowBackIcon/>}
          >
          </ActionButtonImpl>
            <ActionButtonImpl
              name={"Next" }
              fontWeight="bold"
              fontSize={14}
              bg="gray.100"
              color={COLORS.REPORT_TEXT}
              h="40px"
              w="180px"
              mx="20px"
              onClick={onNextStep}
              // isDisabled={(activeStep === 1 && !isSignUpCompleted) || (activeStep === 2 && !consent) || activeStep === 4}
              isDisabled={(activeStep === 2 && !consent) || activeStep === 4}
              rightIcon={<ArrowForwardIcon/>}
            >
            </ActionButtonImpl>
          <Spacer/>
        </Flex>
      </Box>
      {(activeStep === 1) && <Image src={opendnaBackround} w="60%" alt="Open-DNA" ml="8%" />}
      {/*{(activeStep === 2) && <Image src={opendnaBackround} w="30%" alt="Open-DNA" ml="0px" />}*/}
      {(activeStep === 3) && <Image src={opendnaBackround2} w="60%" alt="Open-DNA" ml="10%" />}
      {(activeStep === 4) && <Image src={opendnaBackround3} w="60%" alt="Open-DNA" ml="6%" />}
    </Flex>
  )
}
