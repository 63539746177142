// @flow
import React from 'react';
import { Box } from '@chakra-ui/react';
import { getFileNameOnly } from '../../common/reports/report-common';
import { getPatientId } from '../filters';
import MelanomaRecommendationSection from './MelanomaRecommendationSection';
import MelanomaExplanatorySection from './MelanomaExplanatorySection';
import melanomaImage from '../../images/skin-cancer.png';
import withNavigation from '../../main/WithRoutes';
import MelanomaChartsSection from './MelanomaChartsSection';
import melanomaReportCommon from "./melanoma-report-common";
import GeneticReportOrMobile from "../../common/reports/GeneticReportOrMobile";
import withMobile from "../../common/mobile/withMobile";

type Props = {
  location: any,
  params: any,
  printing: boolean,
  isMobile: boolean,
  topMiddlePane: any
};
type State = {};

export class DemoMelanomaRiskReportImpl extends React.Component<
  Props,
  State
> {
  static loadDemoData() {
    // $FlowFixMe[prop-missing]
    const requireContext: any = require.context('./data', true, /\.json$/);
    const json = {};
    requireContext.keys().forEach(key => {
      const obj = requireContext(key);
      const simpleKey = getFileNameOnly(key);
      json[simpleKey] = obj;
    });
    return json;
  }

  constructor(props: Props) {
    super(props);
    this.patientId = getPatientId(props.params, props.location);
    const melanomas = DemoMelanomaRiskReportImpl.loadDemoData();
    const key = `melanoma${this.patientId}`;
    this.melanoma =
      melanomas[key] === undefined
        ? melanomas.melanoma1
        : melanomas[key];
  }

  melanoma: Object;
  patientId: number;

  render() {
    const {isMobile} = this.props;
    return (
      <Box>
        <GeneticReportOrMobile
          reportTitle="MELANOMA CANCER RISK REPORT"
          configTrait="melanoma"
          titleImage={melanomaImage}
          patientNum={this.patientId}
          geneticKitId={`OD000${this.patientId}`}
          printing={this.props.printing}
          geneticRiskInfoText={[
            melanomaReportCommon.GENETIC_RISK_MELANOMA_INFO_TEXT,
          ]}
          riskPercentile={this.melanoma.melanoma_cancer.percentile}
          topMiddlePane={this.props.topMiddlePane}
          chartsSection={
            <MelanomaChartsSection
              percentile={this.melanoma.melanoma_cancer.percentile}
              quintile={Math.floor(
                this.melanoma.melanoma_cancer.percentile / 20
              )}
              deciles={this.melanoma.melanoma_cancer.deciles}
              oddsRatios={this.melanoma.melanoma_cancer.odds_list}
              isMobile={isMobile}
              printing={this.props.printing}
            />
          }
          recommendationSection={
            <MelanomaRecommendationSection isMobile={isMobile} />
          }
          explanatorySection={<MelanomaExplanatorySection isMobile={isMobile} />}
        />
      </Box>
    );
  }
}

export default withNavigation(withMobile(DemoMelanomaRiskReportImpl));
