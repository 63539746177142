// @flow
import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import COLORS from '../common/colors';
import Disclaimers from '../common/reports/Disclaimers';
import t2dReportStrings from './t2d-report-common';

type Props = {
  isMobile: boolean
};

export default function T2dExplanatorySection(props: Props) {
  const headerFontSize = props.isMobile ? 17 : 20;
  const textFontSize = props.isMobile ? 12 : 14;
  return (
    <Box fontSize={textFontSize}
        sx={{
          '@media print': {
            pageBreakBefore: 'always'
          }
        }}
    >
      <Text fontSize={headerFontSize} color="gray.600" mt="30px" mb="20px">
        What is Type 2 Diabetes Mellitus ?
      </Text>
      <Text mb="20px" color={COLORS.REPORT_TEXT}>
        {t2dReportStrings.WHAT_IS_T2D_TEXT1}
        <br />
        <br />
        {t2dReportStrings.WHAT_IS_T2D_TEXT2}
        <sup>*</sup>.<sup>*</sup>.
        <br />
      </Text>
      <Text fontSize={textFontSize} mb="5px">
        <br />
        <i>
          <sup>*</sup> {t2dReportStrings.WHAT_IS_T2D_REF}
        </i>
      </Text>
      <Text fontSize={headerFontSize} color="gray.600" my="20px">
        How did we calculate your score ?
      </Text>
      <Text mb="15px" color={COLORS.REPORT_TEXT}>
        {t2dReportStrings.HOW_DID_WE_CALC_TEXT1}
        <sup>*</sup>. {t2dReportStrings.HOW_DID_WE_CALC_TEXT2}
      </Text>
      <Text mb="20px" fontSize={textFontSize}>
        <sup>*</sup>
        <i>{t2dReportStrings.HOW_DID_WE_CALC_REF}</i>
      </Text>
      <Disclaimers
        isMobile={false}
        texts={[
          t2dReportStrings.DISCLAIMER1,
          t2dReportStrings.DISCLAIMER2,
          t2dReportStrings.DISCLAIMER3,
          t2dReportStrings.DISCLAIMER4,
          t2dReportStrings.DISCLAIMER5,
          t2dReportStrings.DISCLAIMER6
        ]}
      />
    </Box>
  );
}
