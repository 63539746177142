// @flow
import React from 'react';
import { Flex, Spinner, Text } from '@chakra-ui/react';
import COLORS from './colors';

type Props = {
  label: string,
};

export default function InProgressImpl(props: Props) {
  const flexProps: Object = { ...props };
  return (
    <Flex direction="column" minH="30%" align="center" justify="center"
      {...flexProps}
    >
      <Spinner
        thickness="4px"
        emptyColor="gray.200"
        color="gray.400"
        h="50px"
        w="50px"
        speed="1s"
        label={props.label}
      />
      <Text ml="30px" color={COLORS.LABEL_TEXT_COLOR}>
        {props.label}
      </Text>
    </Flex>
  );
}
