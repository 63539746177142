// @flow
import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Flex,
  Divider,
  Spacer,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  FormControl
} from '@chakra-ui/react';
import LabeledTextWithUnits from '../common/LabelTextAndUnits';
import COLORS from '../common/colors';
import {addVisit, unionPatientVisit, updatePatient} from '../api/capilots';
import ActionButton from '../common/ActionButton';
import PilotLabEditPatient from './PilotLabEditPatient';
import {
  poundsToKg,
  kgToPounds,
  heightCmToString, feetInchToCm
} from '../utils/unit_translation';
import { mgdl2mmolL, mmolL2mgdl } from '../utils/cholesterol';
import calcBmi from '../utils/bmi';
import { mmolmol2percents, percents2mmolmol } from '../utils/a1c';
import InProgressImpl from '../common/InProgress';
import {toFixedHandleUndefined} from "../utils/numbers";

type Props = {
  patientId: string,
  allowEditing: boolean,
  onPatientRecordChange: any
};

export default function PilotPatientVisitUnion(props: Props) {
  const [visit, setVisit] = useState({});
  const [patient, setPatient] = useState({});
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = React.useRef();
  const finalRef = React.useRef();
  const {patientId} = props;
  const {onPatientRecordChange} = props;
  const extractPatient = useCallback ((unionVisit: any) => {
        return {
          num: unionVisit.patient_num,
          name: unionVisit.patient_name,
          height: unionVisit.height,
          doctor: unionVisit.doctor,
          kitId: unionVisit.kit_id,
          clinic: unionVisit.clinic,
          birthYear: unionVisit.birth_year,
          birthMonth: unionVisit.birth_month,
          sex: unionVisit.sex,
          status: unionVisit.status,
          externalId: unionVisit.external_id,
          email: unionVisit.email,
          phone: unionVisit.phone
        }
      }, []
  );
  // declare the data fetching function
  const fetchData = useCallback (async () => {
    const unionVisit = await unionPatientVisit(patientId);
    setVisit(unionVisit.data.visit);
    setPatient(extractPatient(unionVisit.data));
  }, [patientId, extractPatient]);
  useEffect(() => {
    fetchData()
      .then()
      // make sure to catch any error
      .catch(console.error);
  }, [fetchData]);

  const onEdit = useCallback(e => {
    e.stopPropagation();
    onOpen();
  }, [onOpen]);

  const calcBMI = useCallback((weight: number, height: number) => {
    if (!weight || !height) return '';
    const bmi = calcBmi(weight, height / 100);
    if (bmi === null) return '';
    return bmi.toFixed(1);
  }, []);

  const onApplyAndClose = useCallback(async (updated: any) => {
    const patientRecordChange = updated.patientRecordChangesApproved;
    console.log(updated.state.kitId);
    if (patientRecordChange) {
      await updatePatient(
          patientId,
          updated.name,
          updated.state.kitId,
          updated.gender === "male",
          updated.birthYear,
          updated.birthMonth,
          feetInchToCm(updated.feet, updated.inch),
          updated.externalId,
          updated.email,
          updated.phone
      );
    }
    await addVisit(
      patientId,
      poundsToKg(updated.weight),
      updated.sbp,
      updated.dbp,
      mgdl2mmolL(updated.hdl),
      mgdl2mmolL(updated.ldl),
      mgdl2mmolL(updated.tc),
      updated.bmi,
      updated.bpTreated,
      updated.diabetes,
      updated.fhDiabetes,
      percents2mmolmol(updated.a1c),
      updated.smoker
    );
    await fetchData();
    onClose();
    if (patientRecordChange && onPatientRecordChange) {
      onPatientRecordChange(patientId);
    }
  }, [patientId, fetchData, onClose, onPatientRecordChange]);

  const loading = Object.keys(visit).length === 0;
  return (
    <Box ml="200px">
      <Divider w="925px" mt="15px" />
      {loading && <InProgressImpl label="loading..." />}
      {!loading && (
        <Flex align="top" justify="left" fontSize={13} mt="10px">
          <Box w="145px" mr="75px">
            <LabeledTextWithUnits
              align="left"
              labelWidth="60px"
              textWidth="60px"
              title="BMI"
              value={calcBMI(visit.weight, patient.height)}
              marginBottom="1px"
              textWeight="semibold"
              labelColor={COLORS.REPORT_TEXT_GRAY}
              textColor={COLORS.REPORT_TEXT_GRAY}
              unitsWidth="45px"
              unitsColor={COLORS.REPORT_TEXT_GRAY}
              units={'kg/m\u00B2'}
            />
            <LabeledTextWithUnits
              align="left"
              labelWidth="60px"
              textWidth="60px"
              title="Weight"
              value={visit.weight ? toFixedHandleUndefined(kgToPounds(visit.weight), 1) : ''}
              marginBottom="1px"
              textWeight="semibold"
              labelColor={COLORS.REPORT_TEXT_GRAY}
              textColor={COLORS.REPORT_TEXT_GRAY}
              unitsWidth="45px"
              unitsColor={COLORS.REPORT_TEXT_GRAY}
              units="pound"
            />
            <LabeledTextWithUnits
              align="left"
              labelWidth="60px"
              textWidth="60px"
              title="Height"
              value={heightCmToString(patient.height)}
              marginBottom="1px"
              textWeight="semibold"
              labelColor={COLORS.REPORT_TEXT_GRAY}
              textColor={COLORS.REPORT_TEXT_GRAY}
              unitsWidth="45px"
              unitsColor={COLORS.REPORT_TEXT_GRAY}
              units=""
            />
          </Box>
          <Box w="125px" mr="65px">
            <LabeledTextWithUnits
              align="left"
              labelWidth="30px"
              textWidth="50px"
              title="SBP"
              value={visit.sbp}
              marginBottom="1px"
              textWeight="semibold"
              labelColor={COLORS.REPORT_TEXT_GRAY}
              textColor={COLORS.REPORT_TEXT_GRAY}
              unitsWidth="45px"
              unitsColor={COLORS.REPORT_TEXT_GRAY}
              units="mmHg"
            />
            <LabeledTextWithUnits
              align="left"
              labelWidth="30px"
              textWidth="50px"
              title="DBP"
              value={visit.dbp}
              marginBottom="1px"
              textWeight="semibold"
              labelColor={COLORS.REPORT_TEXT_GRAY}
              textColor={COLORS.REPORT_TEXT_GRAY}
              unitsWidth="45px"
              unitsColor={COLORS.REPORT_TEXT_GRAY}
              units="mmHg"
            />
          </Box>
          <Box w="130px" mr="70px">
            <LabeledTextWithUnits
              align="left"
              labelWidth="35px"
              textWidth="50px"
              title="A1C"
              value={visit.a1c ? mmolmol2percents(visit.a1c).toFixed(1) : ''}
              marginBottom="1px"
              textWeight="semibold"
              labelColor={COLORS.REPORT_TEXT_GRAY}
              textColor={COLORS.REPORT_TEXT_GRAY}
              unitsWidth="45px"
              unitsColor={COLORS.REPORT_TEXT_GRAY}
              units="%"
            />
            <LabeledTextWithUnits
              align="left"
              labelWidth="35px"
              textWidth="50px"
              title="HDL"
              value={visit.hdl ? mmolL2mgdl(visit.hdl).toFixed(1) : ''}
              marginBottom="1px"
              textWeight="semibold"
              labelColor={COLORS.REPORT_TEXT_GRAY}
              textColor={COLORS.REPORT_TEXT_GRAY}
              unitsWidth="45px"
              unitsColor={COLORS.REPORT_TEXT_GRAY}
              units="mg/dL"
            />
            <LabeledTextWithUnits
              align="left"
              labelWidth="35px"
              textWidth="50px"
              title="LDL"
              value={visit.ldl ? mmolL2mgdl(visit.ldl).toFixed(1) : ''}
              marginBottom="1px"
              textWeight="semibold"
              labelColor={COLORS.REPORT_TEXT_GRAY}
              textColor={COLORS.REPORT_TEXT_GRAY}
              unitsWidth="45px"
              unitsColor={COLORS.REPORT_TEXT_GRAY}
              units="mg/dL"
            />
            <LabeledTextWithUnits
              align="left"
              labelWidth="35px"
              textWidth="50px"
              title="TC"
              value={visit.tc ? mmolL2mgdl(visit.tc).toFixed(1) : ''}
              marginBottom="1px"
              textWeight="semibold"
              labelColor={COLORS.REPORT_TEXT_GRAY}
              textColor={COLORS.REPORT_TEXT_GRAY}
              unitsWidth="45px"
              unitsColor={COLORS.REPORT_TEXT_GRAY}
              units="mg/dL"
            />
          </Box>
          <Box w="200px" maxW="200px">
            <LabeledTextWithUnits
              align="left"
              labelWidth="150px"
              textWidth="30px"
              title="Treated for high BP"
              value={visit.bp_treated ? 'Yes' : 'No'}
              marginBottom="1px"
              textWeight="semibold"
              labelColor={COLORS.REPORT_TEXT_GRAY}
              textColor={COLORS.REPORT_TEXT_GRAY}
              unitsWidth="45px"
              unitsColor={COLORS.REPORT_TEXT_GRAY}
              units=""
            />
            <LabeledTextWithUnits
              align="left"
              labelWidth="150px"
              textWidth="30px"
              title="Diabetes"
              value={visit.diabetes ? 'Yes' : 'No'}
              marginBottom="1px"
              textWeight="semibold"
              labelColor={COLORS.REPORT_TEXT_GRAY}
              textColor={COLORS.REPORT_TEXT_GRAY}
              unitsWidth="45px"
              unitsColor={COLORS.REPORT_TEXT_GRAY}
              units=""
            />
            <LabeledTextWithUnits
              align="left"
              labelWidth="150px"
              textWidth="30px"
              title="Diabetes family history"
              value={visit.fh_diabetes ? 'Yes' : 'No'}
              marginBottom="1px"
              textWeight="semibold"
              labelColor={COLORS.REPORT_TEXT_GRAY}
              textColor={COLORS.REPORT_TEXT_GRAY}
              unitsWidth="45px"
              unitsColor={COLORS.REPORT_TEXT_GRAY}
              units=""
            />
            <LabeledTextWithUnits
              align="left"
              labelWidth="150px"
              textWidth="30px"
              title="Smoker"
              value={visit.smoker ? 'Yes' : 'No'}
              marginBottom="1px"
              textWeight="semibold"
              labelColor={COLORS.REPORT_TEXT_GRAY}
              textColor={COLORS.REPORT_TEXT_GRAY}
              unitsWidth="45px"
              unitsColor={COLORS.REPORT_TEXT_GRAY}
              units=""
            />
          </Box>
          <ActionButton
            ml="50px"
            name="Edit..."
            fontSize={13}
            onClick={onEdit}
            color={COLORS.LABEL_TEXT_COLOR}
            isDisabled={!props.allowEditing}
          />
          <Spacer />
        </Flex>
      )}
      <Modal
        blockScrollOnMount={false}
        isOpen={isOpen}
        onClose={onClose}
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        size="xl"
      >
        <ModalOverlay />
        <ModalContent color={COLORS.REPORT_TEXT}>
          <ModalBody color={COLORS.REPORT_TEXT}>
            <FormControl>
              <PilotLabEditPatient
                visit={visit}
                onCancel={onClose}
                onApply={onApplyAndClose}
                patient={patient}
              />
            </FormControl>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
}
