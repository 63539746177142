import AWS from "aws-sdk";
import {ENV, AWS_ACCOUNT_IDS} from "../config/env_config";

export function setClockSkew() {
  // access the workflow bucket in order to get the time difference
  const s3 = new AWS.S3();
  const bucket = `opendna-workflow-${ENV}`;
  const req = s3.headBucket({Bucket: bucket, ExpectedBucketOwner: AWS_ACCOUNT_IDS[ENV].toString()});
  req.on('httpHeaders', (statusCode, headers, response, statusMessage) => {
    const date = Date.parse(headers.date);
    // this subtraction handles both past and future clock offsets
    const offset = date - new Date();
    console.log("clock offset:")
    console.log(offset);
    AWS.config.systemClockOffset = offset;
    AWS.config.correctClockSkew = true;
    localStorage.setItem("systemClockOffset", offset);
  });
  return req.promise();
}
