// @flow
import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Text,
  Flex,
  Slider,
  SliderThumb,
  SliderTrack,
  Button
} from '@chakra-ui/react';

type Props = {
  val: ?number,
  minVal: number,
  maxVal: number,
  stepVal: number,
  leftLabel: string,
  callbackOnValueChange: any,
  callbackOnValueEndChange: any,
  callbackGetColor: any,
  callbackRightLabel: any,
  resetVal: number,
  enabled?: boolean,
  attrName?: ?string,
};

export default function MobileChangeSliderImpl(props: Props) {
  const preventDefault = React.useCallback(e => e.preventDefault(), []);
  const {
    val,
    stepVal,
    maxVal,
    minVal,
    callbackOnValueEndChange,
    attrName
  } = props;
  const onStepUp = React.useCallback(() => {
    if (!val) return;
    const newVal =
      val + stepVal > maxVal
        ? maxVal
        : val + stepVal;
    callbackOnValueEndChange(newVal, attrName);
  }, [val, stepVal, maxVal, callbackOnValueEndChange, attrName]);
  const onStepDown = React.useCallback(() => {
    if (!val) return;
    const newVal =
      val - stepVal < minVal
        ? minVal
        : val - stepVal;
    callbackOnValueEndChange(newVal, attrName);
  }, [val, stepVal, minVal, callbackOnValueEndChange, attrName]);

  function getTrackBackground() {
    if (!props.val) return 'rgba(226, 232, 240, 1.0)';
    const fullRange = props.maxVal - props.minVal;
    const changeFillColor = 'rgba(60, 157, 168, 1.0)';
    const preGraySection =
      props.val > props.resetVal
        ? Math.round(((props.resetVal - props.minVal) / fullRange) * 100)
        : Math.round(((props.val - props.minVal) / fullRange) * 100);
    const midSection =
      props.val > props.resetVal
        ? Math.round(((props.val - props.minVal) / fullRange) * 100)
        : Math.round(((props.resetVal - props.minVal) / fullRange) * 100);
    const bkg = `linear-gradient(to right, rgba(226, 232, 240, 1.0) ${preGraySection}%, ${changeFillColor} ${preGraySection}%, ${changeFillColor} ${midSection}%, rgba(226, 232, 240, 1.0) ${midSection}%, rgba(226, 232, 240, 1.0) 100%)`;
    return bkg;
  }

  function onValueChange(newVal: number) {
    if (props.callbackOnValueChange) {
      props.callbackOnValueChange(newVal, attrName)
    }
  }

  function onValueEndChange(newVal: number) {
    if (props.callbackOnValueEndChange) {
      props.callbackOnValueEndChange(newVal, attrName)
    }
  }

  const bkg = getTrackBackground();
  return (
    <Flex align="center" minW="100%" justify="stretch">
      <Box w="98%" onClick={preventDefault}>
        <Flex align="center">
          <Text textAlign="left" minW="50px" fontSize={14}>
            {props.leftLabel}
          </Text>
          <Button
            color="black"
            fontWeight="semibold"
            height="36px"
            width="36px"
            borderWidth={1}
            borderColor="gray.400"
            onClick={onStepDown}
            borderRadius="16px"
            fontSize={16}
            isDisabled={!props.enabled}
            mr="20px"
            style={{
              touchAction: 'manipulation'
            }}
          >
            -
          </Button>
          <Slider
            value={props.val}
            onChange={onValueChange}
            onChangeEnd={onValueEndChange}
            min={props.minVal}
            max={props.maxVal}
            step={props.stepVal}
            isDisabled={!props.enabled}
            fontSize={12}
          >
            <SliderTrack
              style={{
                background: bkg
              }}
            />
            <SliderThumb boxSize={8}>
              <Text
                color={props.enabled ? props.callbackGetColor(props.val, attrName) : 'transparent'}
                fontWeight="bold"
              >
                {props.val ? props.val.toFixed(1) : props.val}
              </Text>
            </SliderThumb>{' '}
          </Slider>
          <Button
            color="black"
            fontWeight="semibold"
            height="36px"
            width="36px"
            borderWidth={1}
            borderColor="gray.400"
            onClick={onStepUp}
            borderRadius="16px"
            fontSize={16}
            isDisabled={!props.enabled}
            ml="20px"
            style={{
              touchAction: 'manipulation'
            }}
          >
            +
          </Button>
          <Text minW="50px" fontSize={12} textAlign="right">
            {props.callbackRightLabel(props.val, props.attrName)}
          </Text>
        </Flex>
      </Box>
    </Flex>
  );
}

MobileChangeSliderImpl.propTypes = {
  enabled: PropTypes.bool,
  attrName: PropTypes.string
};

MobileChangeSliderImpl.defaultProps = {
  enabled: true,
  attrName: undefined
};
