// @flow
export const NO_TESTS = 0;

export const TestType = {
	CARDIOMETABOLIC_RISK: 1,
	CANCER_RISK: 2,
	CANCER_MONOGENIC_RISK: 65536,
	TOTAL_RISK: 65539
}

export const TestTypeDisplayNames = {
	CARDIOMETABOLIC_RISK: "CardioRisk+",
	CANCER_RISK: "CancerRisk+",
	CANCER_MONOGENIC_RISK: "CancerRiskMono",
	TOTAL_RISK: "TotalRisk+"
}

export const ALL_TESTS = (TestType.CARDIOMETABOLIC_RISK | TestType.CANCER_RISK | TestType.CANCER_MONOGENIC_RISK);

export function isTestPresent(test_type: number, tests: number): boolean {
	// eslint-disable-next-line no-bitwise
  return (test_type & tests) === test_type;
}


export function isCancerTestCompleted(completed_tests: number): boolean {
	return isTestPresent(TestType.CANCER_RISK, completed_tests)
}


export function isCardiometabolicTestCompleted(completed_tests: number): boolean {
	return isTestPresent(TestType.CARDIOMETABOLIC_RISK, completed_tests)
}


export function isCancerMonogenicTestCompleted(completed_tests: number): boolean {
	return isTestPresent(TestType.CANCER_MONOGENIC_RISK, completed_tests)
}


export function isTotalRiskTestCompleted(completed_tests: number): boolean {
	return isTestPresent(TestType.TOTAL_RISK, completed_tests)
}

export function resolveComposingOrderNames(tests: number) {
	const orders = [];
	// $FlowFixMe
	if (isTestPresent(TestType.TOTAL_RISK, tests)) {
		orders.push("TOTAL_RISK");
		tests -= TestType.TOTAL_RISK;
	}
  for (const [testName, testType] of Object.entries(TestType)) {
		if (testType === TestType.TOTAL_RISK) continue;
		// $FlowFixMe
		if (isTestPresent(testType, tests)) {
			orders.push(testName);
		}
	}
	return orders;
}
