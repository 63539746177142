// @flow
import React, {useCallback, useState} from 'react';
import IdentifiedPatientAttributes from "../common/reports/IdentifiedPatientAttributes";
import {
  Box,
  Step, StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  Stepper, StepSeparator,
  StepStatus,
  StepTitle,
  useSteps
} from "@chakra-ui/react";
import PilotNewOrder from "./PilotNewOrder";
import COLORS from "../common/colors";

type Props = {
  onCancel: any,
  onApply: any
};

const steps = [
  { title: 'Details', description: 'Patient Details' },
  { title: 'Orders', description: 'Test Orders' }
]

export default function PilotLabAddPatient(props: Props) {
  const { activeStep, setActiveStep } = useSteps({
    index: 1,
    count: steps.length,
  })
  const onNextStep = useCallback(()=>{
      setActiveStep(activeStep + 1)
    }, [setActiveStep, activeStep]
  )
  const [newPatientDetails, setNewPatientDetails] = useState(undefined);
  const onPatientDetailsReady = useCallback((newPatient)=>{
      setNewPatientDetails(newPatient);
      onNextStep();
    }, [onNextStep, setNewPatientDetails]
  )
  const {onApply} = props;
  const onOrdersReady = useCallback((orders)=>{
    onApply(newPatientDetails, orders)
  }, [onApply, newPatientDetails])
  return (
        <Box>
          <Box borderWidth={1} borderRadius={8} borderColor={"gray.100"} my={"10px"}>
            <Stepper size="sm" index={activeStep} orientation='horizontal' gap='8' colorScheme={'gray'} mx={"20px"} my={"10px"}  color={COLORS.REPORT_TEXT_GRAY}>
              {steps.map((step, index) => (
                <Step key={index}>
                  <StepIndicator>
                    <StepStatus
                      complete={<StepIcon />}
                      incomplete={<StepNumber />}
                      active={<StepNumber />}
                    />
                  </StepIndicator>
                  <Box flexShrink='0'>
                    <StepTitle>{step.title}</StepTitle>
                    <StepDescription>{step.description}</StepDescription>
                  </Box>
                  <StepSeparator borderWidth={1} h={1}/>
                </Step>
              ))}
            </Stepper>
          </Box>
          <Box >
            {activeStep === 1 && <IdentifiedPatientAttributes
                title="ADD NEW PATIENT"
                riskTrait={null}
                labelPrefix=""
                onCancel={props.onCancel}
                onApply={onPatientDetailsReady}
                adding={true}
              />}
            {activeStep === 2 && <PilotNewOrder
              currentTests={0}
              callbackOnCancel={props.onCancel}
              callbackOnApply={onOrdersReady}
              editingEnabled={true}
            />}
          </Box>
      </Box>
  );
}
