// @flow
import React from 'react';
import { Flex, Alert, AlertIcon } from '@chakra-ui/react';
import ActionButton from '../ActionButton';
import COLORS from '../colors';

type Props = {
  onOpen: any
};

export default function MobileMissingAttributes(props: Props) {
  const missingAttrText = `Missing attributes, click the 'Edit' button to provide the missing information`;
  return (
    <Flex m="1%" align="center">
      <Alert status="warning" borderRadius="10px" fontSize={12} mr="20px">
        <AlertIcon />
        {missingAttrText}
      </Alert>
      <ActionButton
        borderColor={COLORS.REPORT_TEXT}
        borderWidth={1}
        fontSize={13}
        fontWeight="normal"
        color={COLORS.REPORT_TEXT}
        name="Edit..."
        h="24px"
        w="70px"
        onClick={props.onOpen}
      />
    </Flex>
  );
}
