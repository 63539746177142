// @flow
import React, {useEffect} from 'react';
import { Route, Routes } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { Box } from '@chakra-ui/react';
import Home from '../home/Home';
import routes from './routes';
import NotFoundPage from './NotFoundPage';
import withNavigation from './WithRoutes';
import PilotLabPages from '../capilots/PilotLabPages';
import SelfPages from '../capilots/SelfPages';
import {canSeeClinicsPages, canSeePilotPages, canSeeSelfPages} from '../auth/permissions';
import {useCurrentUserContext} from "../context/CurrentUserContext";


type Props = {
  navigate: any,
  params: Object,
};

const App = (props: Props) => {
  // const { navigate } = props;
  const { user } = useAuthenticator((context) => [context.user]);
  const { currentPatientId } = useCurrentUserContext();

  useEffect(() => {
    console.log(`current user ${user ? user.username : "none"}, patient: ${currentPatientId}`)
  }, [user, currentPatientId]);  // eslint-disable-line

  // useEffect(() => {
  //   if (!currentUser) {
  //     console.log("navigating to login")
  //     setTimeout(() =>navigate(routes.LOGIN), 1000);
  //   }
  // }, [navigate, currentUser]);

  return (
    <Box>
      <Routes>
        <Route path={routes.HOME} element={<Home user={user} />} />
        {/* pilot pages */}
        {canSeePilotPages(user) && (
          <Route
            path={`${routes.LAB_PILOT}/*`}
            element={<PilotLabPages />}
          />
        )}
        {/* clinics pages */}
        {canSeeClinicsPages(user) && (
          <Route
            path={`${routes.CLINICS}/*`}
            element={<PilotLabPages />}
          />
        )}
        {/*self pages */}
        {canSeeSelfPages(undefined, currentPatientId, false) && (
          <Route
            path={`${routes.SELF}/*`}
            element={<SelfPages patientId={currentPatientId} />}
          />
        )}
        {/* not found */}
        <Route path={routes.ROUTE_NOT_FOUND} element={<NotFoundPage />} />
      </Routes>
    </Box>
  );
};
export default withNavigation(App);
