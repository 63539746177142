// @flow
import React from 'react';
import { Text } from '@chakra-ui/react';
import COLORS from '../common/colors';

type Props = {
  trait: string
};

export default function BpAssocRiskText(props: Props) {
  return (
    <Text
      fontSize={14}
      mb="2px"
      fontWeight={500}
      mt="20px"
      color={COLORS.REPORT_TEXT_GRAY}
    >
      <i>
        The calculation is based on the genetic risk for high{' '}
        <b>{props.trait}</b> blood pressure.
      </i>
    </Text>
  );
}
