// @flow
/* this file is used to set the 'per environment' settings
   it is handled by the deployment pipeline to adjust it content
  */

// eslint-disable-next-line import/prefer-default-export
import {ROOT_DOMAIN} from "./constants";
import url from "url";

export const ENV = 'dev';

export function get_env_from_domain(domain: string) {
	if (domain.includes("localhost")) return "dev";
	const index = domain.indexOf(ROOT_DOMAIN);
	if (index === -1) return null;
	if (domain.includes("dev.")) return "dev";
	if (domain.includes("staging.")) return "staging";
	return "prod";
}

export function is_localhost_url(currentUrl: string) {
	const parsedUrl = url.parse(currentUrl)
	if (!parsedUrl || !parsedUrl.hostname) return false;
	return parsedUrl.hostname.includes("localhost");
}

export function isProdEnv() {
	return ENV === "prod";
}

export const AWS_ACCOUNT_IDS = {
	dev: 921320062099,
	prod: 184721275706
};

export const AWS_REGIONS = {
	dev: "us-east-1",
	prod: "us-east-1"
}