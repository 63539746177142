// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { Box, ListItem, Text, UnorderedList } from '@chakra-ui/react';
import { v4 as uuid4 } from 'uuid';

type Props = {
  texts: Array<string>,
  mt?: string,
  mb?: string,
  isMobile?: boolean
};

export default function Disclaimers(props: Props) {
  return (
    <Box>
      <Text
        fontSize={props.isMobile ? 17 : 20}
        color="gray.600"
        mt={props.mt}
        mb={props.mb}
      >
        Disclaimers -
      </Text>
      <UnorderedList ml="40px">
        {props.texts.map(text => (
          <ListItem key={uuid4()}>{text}</ListItem>
        ))}
      </UnorderedList>
    </Box>
  );
}

Disclaimers.propTypes = {
  mt: PropTypes.string,
  mb: PropTypes.string,
  isMobile: PropTypes.bool
};

Disclaimers.defaultProps = {
  mt: '20px',
  mb: '20px',
  fontSize: 20,
  isMobile: false
};
