// @flow
import React from 'react';
import { Box, Flex, Image, Spacer, Text } from '@chakra-ui/react';
import { v4 as uuid4 } from 'uuid';

type Props = {
  headerLine: string,
  trendIcon: any,
  changeColor: string,
  shortText: string,
  changeLines: Array<string>,
  summaryLine: ?string,
  printing: boolean
};

export default function GaugeChangeDetails(props: Props) {
  return (
    <Box fontSize={14} mx="20px" mt="0px">
      <Flex mb="10px" align="center">
        <Text fontWeight="normal" textAlign="center" color={props.changeColor}>
          {props.headerLine}
        </Text>
        <Spacer />
        {props.trendIcon && <Image src={props.trendIcon} h="30px" w="30px" mr="5px" />}
        <Text color={props.changeColor} fontWeight="bold" fontSize={18}>
          <b>{props.shortText}</b>
        </Text>
      </Flex>
      {props.changeLines &&
        props.changeLines.map((line, i) => (
          <Text my={props.printing ? "5px": "15px"} ml="20px" id={i} key={uuid4()}>
            {line}
          </Text>
        ))}
      {props.summaryLine && <Text
        mt="25px"
        ml="20px"
        fontSize={15}
        fontWeight="semibold"
        color="gray.500"
      >
        {props.summaryLine}
      </Text>}
    </Box>
  );
}
