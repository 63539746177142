// @flow
import React from 'react';
import {Box, Flex, Text, Spacer, Image} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import IconTextAndLink from '../../common/IconTextAndLink';
import htnGaugeImage from '../../images/blood-pressure-gauge-light.png';
import dietImage from '../../images/diet.png';
import runningImage from '../../images/running.png';
import stressImage from '../../images/yoga.png';
import noSmokingImage from '../../images/no-smoking.png';
import firstAidImage from '../../images/first-aid-kit.png';
import {getCadRiskColor, getCadRiskLevel} from "../../common/risk-colors";
import {mmolL2mgdl} from "../../utils/cholesterol";
import COLORS from "../../common/colors";
import { GiMedicinePills } from "react-icons/gi"

type Props = {
  isMobile: boolean,
  smoking: boolean,
  bpTreated: boolean,
  diabetes: boolean,
  sbp: number,
  dbp: number,
  ldl: number,
  tc: number,
  hdl: number,
  bmi: number,
  risk: number,
  cadHighRisk: boolean,
  ckdHighRisk: boolean
};

export default function T2dPersonalizedRecommendations(props: Props) {
  const riskLevel = getCadRiskLevel(props.risk);
  const riskColor = getCadRiskColor(props.risk);
  const isLdlGreen = mmolL2mgdl(props.ldl) < 100;
  const isHighLdl = mmolL2mgdl(props.ldl) >= 160;
  const hypertension = props.sbp >= 130 || props.dbp >= 90;
  const overweight = props.bmi > 25;
  const obese = props.bmi > 30;
  return (
    <Box ml={"20px"} mt="10px" color={COLORS.REPORT_TEXT}>
      {!props.diabetes && <Flex w={"100%"} >
        <Text >Patient risk level is:</Text>
        <Text color={riskColor} ml="10px" mb={"15px"} fontWeight={"bold"}>{riskLevel.toLowerCase()}</Text>
      </Flex>}
      <Box color={"gray.600"} bg={"gray.50"} borderColor={"gray.400"} borderWidth={1}>
        <Box m="20px">
          <Flex w={"100%"} mt={"10px"} align={"center"}>
            <Spacer/>
            <Text as={"u"} fontSize={14} fontWeight={"bold"}>Treatment Suggestions</Text>
            <Spacer/>
            <Image src={firstAidImage} h={"32px"}/>
          </Flex>
          {props.diabetes && <IconTextAndLink
            image={GiMedicinePills}
            text="Consider metformin treatment to lower blood glucose level"
            linkText={null}
            link={null}
            isMobile={props.isMobile}
            size={'small'}
            mt={"20px"}
            />}
          {props.cadHighRisk && <IconTextAndLink
            image={GiMedicinePills}
            text="Consider GLP1 treatment to lower the glucose level, the weight and the risk for CAD"
            linkText={null}
            link={null}
            isMobile={props.isMobile}
            size={'small'}
            mt={"20px"}
            />}
          {props.ckdHighRisk && <IconTextAndLink
            image={GiMedicinePills}
            text="Consider SGLT2 treatment to lower the glucose level, the weight and the risk for CKD"
            linkText={null}
            link={null}
            isMobile={props.isMobile}
            size={'small'}
            mt={"20px"}
            />}
          {obese && <IconTextAndLink
            imageSrc={dietImage}
            text="Preserve a healthy diet to reduce weight"
            linkText="more info..."
            link="https://www.heart.org/en/healthy-living/healthy-eating/losing-weight"
            isMobile={props.isMobile}
            size={'small'}
            mt={"10px"}
            linkStyle={'link'}
            textWidth={"65%"}
          />}
          {!obese && overweight && <IconTextAndLink
            imageSrc={dietImage}
            text="Preserve a healthy diet"
            linkText="more info..."
            link="https://www.heart.org/en/healthy-living/healthy-eating/losing-weight"
            isMobile={props.isMobile}
            size={'small'}
            mt={"10px"}
            linkStyle={'link'}
            textWidth={"65%"}
          />}
          {hypertension && props.bpTreated && <IconTextAndLink
            image={GiMedicinePills}
            text="Consider changing or intensifying anti-hypertension treatment to have blood pressure controlled"
            isMobile={props.isMobile}
            size={'small'}
            mt={"20px"}
            />
          }
          {!isLdlGreen && isHighLdl && <IconTextAndLink
            image={GiMedicinePills}
            text="Consider statin treatment to lower LDL level"
            linkText={null}
            link={null}
            isMobile={props.isMobile}
            size={'small'}
            mt={"20px"}
            />
          }
          {hypertension && !props.bpTreated && <IconTextAndLink
            image={GiMedicinePills}
            text="Consider anti-hypertension treatment"
            isMobile={props.isMobile}
            size={'small'}
            mt={"20px"}
            />
          }
          {props.smoking && <IconTextAndLink
            imageSrc={noSmokingImage}
            text="Smoking cessation"
            linkText="more info..."
            link="https://www.heart.org/en/healthy-living/healthy-lifestyle/quit-smoking-tobacco"
            isMobile={props.isMobile}
            size={'small'}
            mt={"20px"}
            linkStyle={'link'}
            textWidth={"65%"}
            />
          }
          {(props.bpTreated || hypertension) && <IconTextAndLink
            imageSrc={htnGaugeImage}
            text="Monitoring blood pressure regularly"
            linkText="more info..."
            link="https://www.heart.org/en/health-topics/high-blood-pressure/understanding-blood-pressure-readings/monitoring-your-blood-pressure-at-home"
            isMobile={props.isMobile}
            size={'small'}
            mt={"10px"}
            linkStyle={'link'}
            textWidth={"65%"}
          />}
          <IconTextAndLink
            imageSrc={runningImage}
            text="Exercise"
            linkText="more info..."
            link="https://www.heart.org/en/healthy-living/fitness/fitness-basics/aha-recs-for-physical-activity-in-adults"
            isMobile={props.isMobile}
            size={'small'}
            mt={"10px"}
            linkStyle={'link'}
            textWidth={"65%"}
          />
          <IconTextAndLink
            imageSrc={stressImage}
            text="Stress reduction"
            linkText="more info..."
            link="https://www.heart.org/en/healthy-living/healthy-lifestyle/stress-management"
            isMobile={props.isMobile}
            size={'small'}
            mt={"10px"}
            linkStyle={'link'}
            textWidth={"65%"}
          />
        </Box>
      </Box>
    </Box>
  );
}

T2dPersonalizedRecommendations.propTypes = {
  isMobile: PropTypes.bool
};

T2dPersonalizedRecommendations.defaultProps = {
  isMobile: false
};
