// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Image, Spacer, Text, Box } from '@chakra-ui/react';
import ExternalLinkButton from './ExternalLinkButton';
import COLORS from './colors';

type Props = {
  imageSrc?: any,
  image?: any,
  text: string,
  textColor?: string,
  linkText?: ?string,
  link?: ?string,
  size?: string,
  mt?: string,
  mb?: string,
  isMobile: boolean,
  linkImage?: any,
  linkStyle?: string,
  textWidth?: ?string
};

export default function IconTextAndLinkImpl(props: Props) {
  const imageSize = props.size === 'small' ? '18px' : '32px';
  const fontSize = props.size === 'small' ? 15 : null;
  let textWidth = props.size === 'small' ? '180px' : '250px';
  const linkHeight = props.size === 'small' ? '30px' : '50px';
  const linkWidth = props.size === 'small' ? '300px' : '350px';
  let linkFontWeight = props.size === 'small' ? 'semibold' : 'bold';
  if (props.linkStyle === 'link') {
    linkFontWeight = 'normal';
  }
  if (!props.link) textWidth += linkWidth;
  const linkBg = props.linkStyle === 'link' ? 'transparent' : undefined;
  const linkFontSize = props.size === 'small' ? 14 : null;

  return (
    <>
      {props.isMobile && (
        <Box>
          <Flex align="center" mt="30px" mb="10px">
            <Text color="gray.600" h="32px">
              {props.text}
            </Text>
            <Spacer />
            {props.imageSrc && <Image src={props.imageSrc} h="32px" alt="" ml="20px" mt="5px" />}
            {props.image && <Box as={props.image} h="32px" ml="20px" mt="5px"/> }
          </Flex>
          {props.link && <ExternalLinkButton
            minW="100%"
            maxW="100%"
            name={props.linkText ?? ""}
            link={props.link ?? ""}
            color={COLORS.REPORT_TEXT}
            borderWidth={1}
            borderColor={COLORS.REPORT_TEXT}
            fontWeight={linkFontWeight}
            fontSize={14}
            image={props.linkImage}
            bg={linkBg}
          />}
        </Box>
      )}
      {!props.isMobile && (
        <Flex align="center" mt={props.mt} mb={props.mb} fontSize={fontSize}>
          {props.imageSrc && <Image
            src={props.imageSrc}
            h={imageSize}
            minW={imageSize}
            alt=""
            mr="10px"
          />}
          {props.image && <Box as={props.image} h={imageSize} mr="10px" minW={imageSize}/> }
          <Text color={props.textColor} minW={props.textWidth ?? textWidth} maxW={props.textWidth ?? textWidth}>
            {props.text}
          </Text>
          {props.link && <ExternalLinkButton
            name={props.linkText ?? ""}
            link={props.link ?? ""}
            color={COLORS.REPORT_TEXT}
            h={linkHeight}
            minW={props.linkStyle === 'button' ? linkWidth : undefined}
            borderWidth={props.linkStyle === 'button' ? 1 : 0}
            borderColor={COLORS.REPORT_TEXT}
            fontWeight={linkFontWeight}
            fontSize={linkFontSize}
            image={props.linkImage}
            bg={linkBg}
          />}
        </Flex>
      )}
    </>
  );
}

IconTextAndLinkImpl.propTypes = {
  size: PropTypes.string,
  textColor: PropTypes.string,
  mt: PropTypes.string,
  mb: PropTypes.string,
  isMobile: PropTypes.bool,
  linkImage: PropTypes.any,
  linkStyle: PropTypes.string,
  link: PropTypes.string,
  linkText: PropTypes.string,
  textWidth: PropTypes.string,
  image: PropTypes.any,
  imageSrc: PropTypes.any
};

IconTextAndLinkImpl.defaultProps = {
  size: 'normal',
  textColor: 'gray.600',
  mt: '30px',
  mb: '10px',
  isMobile: false,
  linkImage: undefined,
  linkStyle: 'button',
  link: undefined,
  linkText: undefined,
  textWidth: undefined,
  image: undefined,
  imageSrc: undefined
};
