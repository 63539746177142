// @flow
import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Text,
  Flex,
  Stack,
  Spacer,
  Slider,
  SliderThumb,
  SliderTrack
} from '@chakra-ui/react';
import ChangeSliderShiftButtons from './ChangeSliderShiftButtons';
import PopoverInfo from "./PopoverInfo";
import COLORS from "./colors";

type Props = {
  val: ?number,
  minVal: number,
  maxVal: number,
  stepVal: number,
  leftLabel: string,
  callbackOnValueChange: any,
  callbackOnValueEndChange: any,
  callbackGetColor: any,
  callbackRightLabel: any,
  shiftButtons?: boolean,
  resetVal: number,
  shiftButtonsSize?: string,
  shiftButtonsBorderRadius?: string,
  shiftButtonsOnSide?: boolean,
  textMarginTop?: string,
  enabled?: boolean,
  popoverInfo?: ?Object,
  attrName?: ?string,
};

export default function ChangeSliderImpl(props: Props) {
  const { val, minVal, maxVal, stepVal, callbackOnValueEndChange, attrName } = props;
  const onStepUp = React.useCallback(() => {
    if (!val) return;
    const newVal =
      val + stepVal > maxVal
        ? maxVal
        : val + stepVal;
    callbackOnValueEndChange(newVal, attrName);
  }, [val, maxVal, stepVal, callbackOnValueEndChange, attrName]);
  const onStepDown = React.useCallback(() => {
    if (!val) return;
    const newVal =
      val - stepVal < minVal
        ? minVal
        : val - stepVal;
    callbackOnValueEndChange(newVal, attrName);
  }, [val, minVal, stepVal, callbackOnValueEndChange, attrName]);

  function getTrackBackground() {
    if (!props.val) return 'rgba(226, 232, 240, 1.0)';
    const fullRange = props.maxVal - props.minVal;
    const changeFillColor = 'rgba(60, 157, 168, 1.0)';
    const preGraySection =
      props.val > props.resetVal
        ? Math.round(((props.resetVal - props.minVal) / fullRange) * 100)
        : Math.round(((props.val - props.minVal) / fullRange) * 100);
    const midSection =
      props.val > props.resetVal
        ? Math.round(((props.val - props.minVal) / fullRange) * 100)
        : Math.round(((props.resetVal - props.minVal) / fullRange) * 100);
    return `linear-gradient(to right, rgba(226, 232, 240, 1.0) ${preGraySection}%, ${changeFillColor} ${preGraySection}%, ${changeFillColor} ${midSection}%, rgba(226, 232, 240, 1.0) ${midSection}%, rgba(226, 232, 240, 1.0) 100%)`;
  }

  function onValueChange(newVal: number) {
    if (!props.enabled) return;
    if (props.callbackOnValueChange) {
      props.callbackOnValueChange(newVal, attrName)
    }
  }

  function onValueEndChange(newVal: number) {
    if (!props.enabled) return;
    if (props.callbackOnValueEndChange) {
      props.callbackOnValueEndChange(newVal, attrName)
    }
  }

  const bkg = getTrackBackground();
  const asterisk = "*";
  return (
    <Flex align="center" minW="100%" justify="stretch">
      <Box w="100%">
        <Flex
          mt={props.textMarginTop}
          align={props.shiftButtons ? 'top' : 'center'}
          minW="100%"
        >
          {props.popoverInfo && (
            <PopoverInfo
              trigger={(
                <Stack direction={"horizontal"} minW="25%">
                  <Text textAlign="left"  fontSize={15}>
                    {props.leftLabel}
                  </Text>
                </Stack>)}
              header={props.popoverInfo.header}
              text={props.popoverInfo.text}
            />
          )}
          {!props.popoverInfo && (<Text textAlign="left" minW="25%" fontSize={15}>
            {props.leftLabel}
          </Text>)}
          <Spacer />
          {props.shiftButtons && !props.shiftButtonsOnSide && (
            <Box mt="10px" minW="50%">
              <ChangeSliderShiftButtons
                onStepUp={onStepUp}
                onStepDown={onStepDown}
                shiftButtonsSize={props.shiftButtonsSize}
                enabled={props.enabled}
              />
            </Box>
          )}
          <Spacer />
          <Text minW="25%" fontSize={13} textAlign="right">
            {props.callbackRightLabel(props.val, props.attrName)}
          </Text>
        </Flex>
        <Slider
          value={props.val === null ? props.minVal : props.val}
          onChange={onValueChange}
          onChangeEnd={onValueEndChange}
          min={props.minVal}
          max={props.maxVal}
          step={props.stepVal}
          isDisabled={!props.enabled}
        >
          <SliderTrack
            style={{
              background: bkg
            }}
          />
          <SliderThumb boxSize={10}>
            <Text
              color={props.enabled ? props.callbackGetColor(props.val, attrName) : 'transparent'}
              fontWeight="bold"
            >
              {props.val ? props.val.toFixed(1) : props.val}
            </Text>
          </SliderThumb>{' '}
        </Slider>
      {props.popoverInfo && props.popoverInfo.footer && (
        <PopoverInfo
          trigger={(
            <Stack direction={"horizontal"} minW="25%">
              <Text color={COLORS.RED_STATUS} ml="2px" textAlign="left" fontSize={13}>{asterisk}</Text>
              <Text color={COLORS.RED_STATUS} ml="2px" textAlign="left" fontSize={13}>{props.popoverInfo.footer}</Text>
            </Stack>)}
          header={props.popoverInfo.header}
          text={props.popoverInfo.text}
        />
      )}
      </Box>
      {props.shiftButtons && props.shiftButtonsOnSide && (
        <Box ml="20px">
          <ChangeSliderShiftButtons
            onStepUp={onStepUp}
            onStepDown={onStepDown}
            shiftButtonsSize={props.shiftButtonsSize}
            shiftButtonsBorderRadius={props.shiftButtonsBorderRadius}
          />
        </Box>
      )}
    </Flex>
  );
}

ChangeSliderImpl.propTypes = {
  shiftButtons: PropTypes.bool,
  shiftButtonsSize: PropTypes.string,
  shiftButtonsBorderRadius: PropTypes.string,
  shiftButtonsOnSide: PropTypes.bool,
  textMarginTop: PropTypes.string,
  enabled: PropTypes.bool,
  popoverInfo: PropTypes.object,
  attrName: PropTypes.string
};

ChangeSliderImpl.defaultProps = {
  shiftButtons: false,
  shiftButtonsSize: '60px',
  shiftButtonsBorderRadius: '30px',
  shiftButtonsOnSide: false,
  textMarginTop: '15px',
  enabled: true,
  popoverInfo: undefined,
  attrName: undefined
};
