import React, {useEffect, useState} from 'react';
import isMobileByWindowWidth from "../../utils/mobile";

export default function withMobile(WrappedComponent) {
	return function WithMobileComponent(props) {
		const [isMobile, setIsMobile] = useState(isMobileByWindowWidth( window.innerWidth));
		const [screenWidth, setScreenWidth] = useState(window.innerWidth);

		useEffect(() => {
				const updateDimension = () => {
					setIsMobile(isMobileByWindowWidth(window.innerWidth));
					setScreenWidth(window.innerWidth);
				}
				window.addEventListener('resize', updateDimension);

				return(() => {
						window.removeEventListener('resize', updateDimension);
				})
			}, [])

		return <WrappedComponent
			{...props}
			isMobile={isMobile}
			screenWidth={screenWidth}
		/>
	}
};
