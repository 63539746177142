import React, {useCallback, useState} from 'react';
import {Box, Flex, Input, InputRightElement, InputGroup, Text, Spacer, Checkbox, IconButton} from "@chakra-ui/react";
import COLORS from "./colors";
import { FiDelete } from 'react-icons/fi';
import PropTypes from "prop-types";

type Props = {
	label: ?string,
	onSearch: any,
	demoFilter?: boolean
}

export default function SearchTextBar(props: Props) {
	const label = props.label ?? 'Search text: ';
	const {onSearch} = props;
  const [isCaseSensitive, setIsCaseSensitive] = useState(false);
  const [showDemoPatients, setShowDemoPatients] = useState(false);


  const [searchText: ?string, setSearchText] = useState(undefined);
  const onTextChange = useCallback((event: any) => {
		setSearchText(event.target.value);
    onSearch(event.target.value, isCaseSensitive, showDemoPatients);
  }, [onSearch, isCaseSensitive, showDemoPatients]);

	const onCaseSensitiveChange = useCallback(
			(e) => {
				setIsCaseSensitive(e.target.checked);
				onSearch(searchText, e.target.checked, showDemoPatients);
			}, [onSearch, searchText, showDemoPatients]);

	const clearSearchText = useCallback(() => {
		setSearchText('');
		onSearch(undefined, isCaseSensitive, showDemoPatients);
	}, [isCaseSensitive, onSearch, showDemoPatients]);

  const onShowDemoPatientsChange = useCallback((event: any) => {
		setShowDemoPatients(event.target.checked);
    onSearch(event.target.value, isCaseSensitive, event.target.checked);
  }, [onSearch, isCaseSensitive, setShowDemoPatients]);

	return (
			<Box borderWidth={1} borderColor={"gray.50"} color={COLORS.REPORT_TEXT} bg={"gray.50"} borderRadius={8}>
				<Flex fontSize={13} mx={"50px"} my={"10px"} align={"center"}>
					<Text mr={"10px"}>{label}</Text>
					<InputGroup size='sm' maxW="200px">
						<Input
							onChange={onTextChange}
							value={searchText}
							size={"sm"}
							fontWeight={"normal"}
							placeholder={"search text"}
						/>
						<InputRightElement width='30px' ml={"10px"}>
								<IconButton
									icon={<Box as={FiDelete} h="18px" w="18px" mb={0} />}
									bg="transparent"
									aria-label="clear"
									title="clear"
									onClick={clearSearchText}
									size={"sm"}
								/>
						</InputRightElement>
					</InputGroup>
					<Checkbox
						ml={"50px"}
						size={"sm"}
						isChecked={isCaseSensitive}
						onChange={onCaseSensitiveChange}
						mt={"1px"}
					>
						case sensitive
					</Checkbox>
					<Spacer/>
					{props.demoFilter && <Checkbox
						ml={"50px"}
						size={"sm"}
						isChecked={showDemoPatients}
						onChange={onShowDemoPatientsChange}
						mt={"1px"}
					>
						show demo patients
					</Checkbox>}
				</Flex>
			</Box>
		);
}

SearchTextBar.propTypes = {
  demoFilter: PropTypes.bool
};

SearchTextBar.defaultProps = {
  demoFilter: true
};
