// @flow
import React, { useCallback } from 'react';
import {
  Flex,
  Box,
  Text,
  Stack,
  Spacer,
  Image,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  FormControl
} from '@chakra-ui/react';

import { LabeledTextWithSpacer } from '../../common/LabeledText';
import LabeledSwitch from '../../common/LabeledSwitch';
import COLORS from '../../common/colors';
import cautionImage from '../../images/caution-sign-blue.png';
import ActionButton from '../../common/ActionButton';
import MobileChangeSlider from '../../common/MobileChangeSlider';
import traitRanges from '../../common/reports/trait-ranges.json';
import {getAgeColor} from '../../common/calculator/calculator-common';
import {
  t2dCalculatorUserAttributes
} from '../../common/reports/report-common';
import withT2dCalculator from './WithT2dCalculator';
import EditablePatientAttributes from '../../common/reports/EditablePatientAttributes';
// import RelativeRiskChart from '../../common/RelativeRiskGauge';
import {
  getRiskColorByPercentile,
  getRiskLevelByPercentile,
  // getRiskColorByQuintile,
  // getRiskLevelByQuintile
} from '../../common/risk-colors';
// import { getRelativeRiskLabel } from '../../common/relative-risk';
import MobileMissingAttributes from '../../common/reports/MobileMissingAttributes';
import OverallRiskGauge from "../../common/OverallRiskGauge";
import {roundWithPrecision} from "../../utils/numbers";

type Props = {
  onGenderSelected: any,
  onCurrentAgeChange: any,
  onAgeSliderChange: any,
  getAgeDiffText: any,
  onCurrentBmiChange: any,
  onBmiSliderChange: any,
  getBmiDiffText: any,
  getSliderBmiColor: any,
  onCurrentTcChange: any,
  onTcSliderChange: any,
  getTcDiffText: any,
  getSliderTcColor: any,
  onCurrentHdlChange: any,
  onHdlSliderChange: any,
  getHdlDiffText: any,
  getSliderHdlColor: any,
  onCurrentA1cChange: any,
  onA1cSliderChange: any,
  getA1cDiffText: any,
  getSliderA1cColor: any,
  onCurrentSbpChange: any,
  onCurrentDbpChange: any,
  onCurrentBpTreatedChange: any,
  onCurrentSmokerChange: any,
  onCurrentFhDiabetesChange: any,
  onBpTreatedSwitchChange: any,
  onFhDiabetesSwitchChange: any,
  onResetAttributes: any,
  getSwitchColor: any,
  getSwitchText: any,
  resetAge: number,
  resetHdl: number,
  resetTc: number,
  resetBmi: number,
  resetA1c: number,
  currentAge: number,
  currentBmi: number,
  currentSbp: number,
  currentDbp: number,
  currentTc: number,
  currentHdl: number,
  currentA1c: number,
  gender: ?string,
  age: number,
  bmi: number,
  tc: number,
  hdl: number,
  a1c: number,
  fhDiabetes: boolean,
  smoker: boolean,
  bpTreated: boolean,
  minBmi: number,
  maxBmi: number,
  t2dQuintile: number,
  t2dPercentile: number,
  playingEnabled: boolean,
  ageSliderEnabled: boolean,
  bmiSliderEnabled: boolean,
  tcSliderEnabled: boolean,
  hdlSliderEnabled: boolean,
  a1cSliderEnabled: boolean,
  shortT2dText: string,
  t2dTrendIcon: any,
  changeColor: string,
  relativeRisk: number,
  relativeRiskOddsRatio: number,
  currentPercentile: number,
  expectedPercentile: number,
  shortT2dTextPercentile: string,
  longT2dTextPercentile: string,
  summaryChangeLinePercentile: string,
  t2dTrendIconPercentile: any,
  changeColorPercentile: string
};

export function MobileT2dCalculatorImpl(props: Props) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const initialRef = React.useRef();
  const finalRef = React.useRef();
  const refHeader = React.useRef();
  const scrollToTop = useCallback(() => {
    if (refHeader.current) refHeader.current.scrollIntoView();
  }, [refHeader]);

  const { onResetAttributes } = props;
  const onApplyAndClose = useCallback(() => {
    onResetAttributes();
    scrollToTop();
    onClose();
  }, [onResetAttributes, scrollToTop, onClose]);

  const getOverallRiskLabel = useCallback((current, expected) => {
    const diff = expected - current;
    if (diff === 0) return `${current + 1}%`;
    return `${expected}%`;
  }, []);

  const changeColor =
    props.changeColor === 'transparent'
      ? COLORS.REPORT_TEXT_GRAY
      : props.changeColor;

  return (
    <Box borderColor="gray.300" borderWidth={1} borderRadius="10px">
      <Box mx="2%" my="5px">
        <Flex
          my="5px"
          bg="transparent"
          align="center"
          borderWidth={1}
          borderRadius="10px"
          ref={refHeader}
        >
          <Text fontSize={13} fontWeight="semibold" ml="10px" my="10px">
            CLINICAL FACTORS EFFECT ON OVERALL RISK
          </Text>
          <Spacer />
          <Image src={cautionImage} h="24px" alt="" mx="20px" />
        </Flex>
        <Box mx="3px" mt="10px">
          {!props.playingEnabled && <MobileMissingAttributes onOpen={onOpen} />}
          <Text fontSize={13}>
            <i>
              Change risk factor values to check the effect on your relative
              risk to develop T2D
            </i>
          </Text>
          <Stack spacing="5px" mt="10px" mx="3px">
            {/* age */}
            <MobileChangeSlider
              val={props.age}
              minVal={props.resetAge}
              maxVal={traitRanges.age.highLimit}
              stepVal={1}
              resetVal={props.resetAge}
              leftLabel="AGE"
              callbackGetColor={getAgeColor}
              callbackRightLabel={props.getAgeDiffText}
              callbackOnValueChange={props.onAgeSliderChange}
              callbackOnValueEndChange={props.onAgeSliderChange}
              enabled={props.playingEnabled && props.ageSliderEnabled}
            />
            {/* BMI */}
            <MobileChangeSlider
              val={props.bmi}
              minVal={props.minBmi}
              maxVal={props.maxBmi}
              stepVal={0.5}
              resetVal={props.resetBmi}
              leftLabel="BMI"
              callbackGetColor={props.getSliderBmiColor}
              callbackRightLabel={props.getBmiDiffText}
              callbackOnValueChange={props.onBmiSliderChange}
              callbackOnValueEndChange={props.onBmiSliderChange}
              enabled={props.playingEnabled && props.bmiSliderEnabled}
            />
            {/* tc */}
            <MobileChangeSlider
              val={props.tc}
              minVal={traitRanges.tc.lowLimit}
              maxVal={traitRanges.tc.highLimit}
              stepVal={1}
              resetVal={props.resetTc}
              leftLabel="TC"
              callbackGetColor={props.getSliderTcColor}
              callbackRightLabel={props.getTcDiffText}
              callbackOnValueChange={props.onTcSliderChange}
              callbackOnValueEndChange={props.onTcSliderChange}
              enabled={props.playingEnabled && props.tcSliderEnabled}
            />
            {/* hdl */}
            <MobileChangeSlider
              val={props.hdl}
              minVal={traitRanges.hdl.lowLimit}
              maxVal={traitRanges.hdl.highLimit}
              stepVal={1}
              resetVal={props.resetHdl}
              leftLabel="HDL"
              callbackGetColor={props.getSliderHdlColor}
              callbackRightLabel={props.getHdlDiffText}
              callbackOnValueChange={props.onHdlSliderChange}
              callbackOnValueEndChange={props.onHdlSliderChange}
              enabled={props.playingEnabled && props.hdlSliderEnabled}
            />
            {/* a1c */}
            <MobileChangeSlider
              val={props.a1c}
              minVal={traitRanges.a1c.lowLimit}
              maxVal={traitRanges.a1c.highLimit}
              stepVal={0.1}
              resetVal={props.resetA1c}
              leftLabel="A1C"
              callbackGetColor={props.getSliderA1cColor}
              callbackRightLabel={props.getA1cDiffText}
              callbackOnValueChange={props.onA1cSliderChange}
              callbackOnValueEndChange={props.onA1cSliderChange}
              enabled={props.playingEnabled && props.a1cSliderEnabled}
            />
            <LabeledSwitch
              mt="10px"
              isChecked={props.bpTreated}
              leftLabel="Treated for high BP"
              leftLabelWidth="200px"
              callbackGetColor={props.getSwitchColor}
              callbackOnValueChange={props.onBpTreatedSwitchChange}
              callbackRightLabel={props.getSwitchText}
              enabled={props.playingEnabled}
            />
            <LabeledSwitch
              isChecked={props.fhDiabetes}
              leftLabel="Diabetes family history"
              leftLabelWidth="200px"
              callbackGetColor={props.getSwitchColor}
              callbackOnValueChange={props.onFhDiabetesSwitchChange}
              callbackRightLabel={props.getSwitchText}
              enabled={props.playingEnabled}
            />
            <Flex>
              <Spacer />
              <ActionButton
                width="100px"
                height="30px"
                px="10%"
                onClick={props.onResetAttributes}
                fontWeight="normal"
                fontSize={13}
                borderWidth={1}
                borderColor={COLORS.REPORT_TEXT}
                name="Reset"
              />
              <Spacer />
            </Flex>
          </Stack>
        </Box>
        <Box mb="20px" spacing="20px" minW="40%">
          <OverallRiskGauge
            percentile={props.t2dPercentile}
            callbackGetColor={getRiskColorByPercentile}
            callbackGetRiskLevel={getRiskLevelByPercentile}
            callbackGetOverallRiskLabel={getOverallRiskLabel}
            currentPercentile={props.currentPercentile}
            expectedPercentile={props.expectedPercentile}
            showSubtitle={false}
          />
          {/*<RelativeRiskChart*/}
          {/*  percentile={props.t2dPercentile}*/}
          {/*  quantile={props.t2dQuintile}*/}
          {/*  callbackGetColor={getRiskColorByQuintile}*/}
          {/*  callbackGetRiskLevel={getRiskLevelByQuintile}*/}
          {/*  callbackGetRelativeRiskLabel={getRelativeRiskLabel}*/}
          {/*  relativeRisk={props.relativeRisk}*/}
          {/*  relativeRiskOddsRatio={props.relativeRiskOddsRatio}*/}
          {/*  showSubtitle={false}*/}
          {/*  maxHeight={140}*/}
          {/*  isMobile*/}
          {/*/>*/}
        </Box>
        <Flex align="center" minH="30px">
          <Spacer />
          <Text
            fontSize={14}
            fontWeight="semibold"
            textAlign="center"
            color={changeColor}
            mr="40px"
          >
            Estimated risk percentile change :
          </Text>
          {props.t2dTrendIcon && (
            <Image src={props.t2dTrendIcon} h="24px" w="24px" mr="5px" />
          )}
          {!props.t2dTrendIcon && <Box minW="30px" />}
          <Text color={changeColor} fontSize={17} fontWeight="bold">
            <b>{props.shortT2dTextPercentile ? props.shortT2dTextPercentile : '0%'}</b>
          </Text>
          <Spacer />
        </Flex>
        <Box mt="10px" fontSize={13} color={COLORS.REPORT_TEXT_GRAY}>
          <Flex my="10px" bg="gray.300">
            <Text mx="20px" my="1px" />
          </Flex>
          <Flex align="center">
            <Text fontWeight="semibold">Your Attributes:</Text>
            <Spacer />
            <ActionButton
              borderColor={COLORS.REPORT_TEXT}
              borderWidth={1}
              fontSize={13}
              fontWeight="normal"
              color={COLORS.REPORT_TEXT}
              name="Edit..."
              h="24px"
              w="70px"
              onClick={onOpen}
            />
          </Flex>
          <Flex align="justify" fontSize={12} mt="5px">
            <Box w="40%" mr="2%">
              <LabeledTextWithSpacer
                align="left"
                labelWidth="60px"
                textWidth="60px"
                title="Gender"
                value={props.gender}
                marginBottom="1px"
                textWeight="semibold"
                labelColor={COLORS.REPORT_TEXT_GRAY}
                textColor={COLORS.REPORT_TEXT_GRAY}
              />
              <LabeledTextWithSpacer
                align="left"
                labelWidth="60px"
                textWidth="60px"
                title="Age"
                value={props.currentAge}
                marginBottom="1px"
                textWeight="semibold"
                labelColor={COLORS.REPORT_TEXT_GRAY}
                textColor={COLORS.REPORT_TEXT_GRAY}
              />
              <LabeledTextWithSpacer
                align="left"
                labelWidth="60px"
                textWidth="60px"
                title="BMI"
                value={props.currentBmi ? roundWithPrecision(props.currentBmi, 1) : ''}
                marginBottom="1px"
                textWeight="semibold"
                labelColor={COLORS.REPORT_TEXT_GRAY}
                textColor={COLORS.REPORT_TEXT_GRAY}
              />
              <LabeledTextWithSpacer
                align="left"
                labelWidth="60px"
                textWidth="60px"
                title="SBP"
                value={props.currentSbp}
                marginBottom="1px"
                textWeight="semibold"
                labelColor={COLORS.REPORT_TEXT_GRAY}
                textColor={COLORS.REPORT_TEXT_GRAY}
              />
              <LabeledTextWithSpacer
                align="left"
                labelWidth="60px"
                textWidth="60px"
                title="DBP"
                value={props.currentDbp}
                marginBottom="1px"
                textWeight="semibold"
                labelColor={COLORS.REPORT_TEXT_GRAY}
                textColor={COLORS.REPORT_TEXT_GRAY}
              />
              <LabeledTextWithSpacer
                align="left"
                labelWidth="60px"
                textWidth="60px"
                title="HDL"
                value={props.currentHdl}
                marginBottom="1px"
                textWeight="semibold"
                labelColor={COLORS.REPORT_TEXT_GRAY}
                textColor={COLORS.REPORT_TEXT_GRAY}
              />
              <LabeledTextWithSpacer
                align="left"
                labelWidth="60px"
                textWidth="60px"
                title="A1C"
                value={props.currentA1c ? props.currentA1c.toFixed(1) : ''}
                marginBottom="1px"
                textWeight="semibold"
                labelColor={COLORS.REPORT_TEXT_GRAY}
                textColor={COLORS.REPORT_TEXT_GRAY}
              />
            </Box>
            <Box w="50%">
              <LabeledTextWithSpacer
                align="left"
                labelWidth="150px"
                textWidth="70px"
                title="Total Cholesterol (TC)"
                value={props.currentTc}
                marginBottom="1px"
                textWeight="semibold"
                labelColor={COLORS.REPORT_TEXT_GRAY}
                textColor={COLORS.REPORT_TEXT_GRAY}
                useMaxTextWidth
              />
              <LabeledTextWithSpacer
                align="left"
                labelWidth="150px"
                textWidth="70px"
                title="Treated for high BP"
                value={props.bpTreated ? 'Yes' : 'No'}
                marginBottom="1px"
                textWeight="semibold"
                labelColor={COLORS.REPORT_TEXT_GRAY}
                textColor={COLORS.REPORT_TEXT_GRAY}
                useMaxTextWidth
              />
              <LabeledTextWithSpacer
                align="left"
                labelWidth="150px"
                textWidth="70px"
                title="Diabetes family history"
                value={props.fhDiabetes ? 'Yes' : 'No'}
                marginBottom="1px"
                textWeight="semibold"
                labelColor={COLORS.REPORT_TEXT_GRAY}
                textColor={COLORS.REPORT_TEXT_GRAY}
                useMaxTextWidth
              />
              <LabeledTextWithSpacer
                align="left"
                labelWidth="150px"
                textWidth="70px"
                title="Smoker"
                value={props.smoker ? 'Yes' : 'No'}
                marginBottom="1px"
                textWeight="semibold"
                labelColor={COLORS.REPORT_TEXT_GRAY}
                textColor={COLORS.REPORT_TEXT_GRAY}
                useMaxTextWidth
              />
              <LabeledTextWithSpacer
                align="left"
                labelWidth="120px"
                textWidth="110px"
                title="Genetic risk"
                value={getRiskLevelByPercentile(props.t2dPercentile).toLowerCase()}
                marginBottom="1px"
                textWeight="semibold"
                labelColor={COLORS.REPORT_TEXT_GRAY}
                textColor={COLORS.REPORT_TEXT_GRAY}
                useMaxTextWidth
              />
            </Box>
          </Flex>
        </Box>
      </Box>
      <Modal
        blockScrollOnMount={false}
        isOpen={isOpen}
        onClose={onClose}
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
      >
        <ModalOverlay />
        <ModalContent color={COLORS.REPORT_TEXT}>
          <ModalBody color={COLORS.REPORT_TEXT}>
            <FormControl>
              <EditablePatientAttributes
                gender={props.gender}
                age={props.currentAge}
                bmi={props.currentBmi}
                tc={props.currentTc}
                hdl={props.currentHdl}
                sbp={props.currentSbp}
                dbp={props.currentDbp}
                a1c={props.currentA1c}
                bpTreated={props.bpTreated}
                fhDiabetes={props.fhDiabetes}
                smoker={props.smoker}
                showGender
                showAge
                showBmi
                showTc
                showHdl
                showSbp
                showDbp
                showA1c
                showFhDiabetes
                showSmoker
                showBpTreated
                riskTrait={null}
                labelPrefix="Current "
                callbackGenderSelected={props.onGenderSelected}
                callbackAgeChange={props.onCurrentAgeChange}
                callbackBmiChange={props.onCurrentBmiChange}
                callbackTcChange={props.onCurrentTcChange}
                callbackHdlChange={props.onCurrentHdlChange}
                callbackSbpChange={props.onCurrentSbpChange}
                callbackDbpChange={props.onCurrentDbpChange}
                callbackA1cChange={props.onCurrentA1cChange}
                callbackSmokerChange={props.onCurrentSmokerChange}
                callbackFhDiabetesChange={props.onCurrentFhDiabetesChange}
                callbackBpTreatedChange={props.onCurrentBpTreatedChange}
                onCancel={onClose}
                onApply={onApplyAndClose}
                showAttributeFlags={t2dCalculatorUserAttributes}
              />
            </FormControl>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default withT2dCalculator(MobileT2dCalculatorImpl);
