// @flow
import React from 'react';
import { Box } from '@chakra-ui/react';
import { getFileNameOnly } from '../../common/reports/report-common';
import { getPatientId } from '../filters';
import GeneticReport from '../../common/reports/GeneticReport';
import StrokeRecommendationSection from './StrokeRecommendationSection';
import StrokeExplanatorySection from './StrokeExplanatorySection';
import strokeImage from '../../images/stroke-bw.png';
import withNavigation from '../../main/WithRoutes';
import StrokeChartsSection from './StrokeChartsSection';

type Props = {
  location: any,
  params: any
};
type State = {};

export class DemoStrokeRiskReportImpl extends React.Component<Props, State> {
  static loadDemoData() {
    // $FlowFixMe[prop-missing]
    const requireContext: any = require.context('./data', true, /\.json$/);
    const json = {};
    requireContext.keys().forEach(key => {
      const obj = requireContext(key);
      const simpleKey = getFileNameOnly(key);
      json[simpleKey] = obj;
    });
    return json;
  }

  constructor(props: Props) {
    super(props);
    this.patientId = getPatientId(props.params, props.location);
    const strokes = DemoStrokeRiskReportImpl.loadDemoData();
    const key = `stroke${this.patientId}`;
    this.stroke = strokes[key] === undefined ? strokes.stroke1 : strokes[key];
  }

  stroke: Object;
  patientId: number;

  render() {
    return (
      <Box>
        <GeneticReport
          reportTitle="STROKE RISK REPORT"
          configTrait="stroke"
          titleImage={strokeImage}
          patientNum={this.patientId}
          geneticKitId={`OD000${this.patientId}`}
          riskPercentile={this.stroke.stroke.percentile}
          chartsSection={
            <StrokeChartsSection
              percentile={this.stroke.stroke.percentile}
              quintile={Math.floor(this.stroke.stroke.percentile / 20)}
              deciles={this.stroke.stroke.deciles}
              oddsRatios={this.stroke.stroke.odds_list}
              isMobile={false}
            />
          }
          recommendationSection={
            <StrokeRecommendationSection isMobile={false} />
          }
          explanatorySection={<StrokeExplanatorySection isMobile={false} />}
        />
      </Box>
    );
  }
}

export default withNavigation(DemoStrokeRiskReportImpl);
