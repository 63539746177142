// @flow
import React from 'react';

import { Box } from '@chakra-ui/react';
import withNavigation from '../main/WithRoutes';
import htnGaugeImage from '../images/blood-pressure-gauge-light.png';

import GxgBpCalculator from '../bp/calculator/GxgBpCalculator';
import BpRecommendationSection from '../bp/BpRecommendationSection';
import BpExplanatorySection from '../bp/BpExplanatorySection';
import { getPatientId } from '../capilots/capilots-utils';
import { bpReportStrings } from '../bp/bp-report-common';
import GxgPatientAttributes from "../common/reports/GxgPatientAttributes";
import {getRiskLevelByPercentile} from "../common/risk-colors";
import {bpCalculatorUserAttribute} from "../common/reports/report-common";
import {roundWithPrecision} from "../utils/numbers";
import {isNullOrUndefined} from "url/util";
import MobileGxgBpCalculator from "../bp/calculator/MobileGxgBpCalculator";
import GeneticReportOrMobile from "../common/reports/GeneticReportOrMobile";
import withMobile from "../common/mobile/withMobile";

type Props = {
  params: any,
  location: any,
  visit: Object,
  age: ?number,
  printing: boolean,
  bp: Object,
  loading: boolean,
  isMobile: boolean
};


export class ConsumerBpRiskReportImpl extends React.Component<Props> {
  /* eslint-disable no-unused-vars */
  constructor(props: Props) {
    super();
    this.patientId = getPatientId(props.params, props.location);
  }
  
  patientId: string;

  render() {
    const {isMobile} = this.props;
    const showAlert = !this.props.loading && this.props.visit && (
        isNullOrUndefined(this.props.visit.sex) ||
        !this.props.visit.age ||
        !this.props.visit.weight ||
        !this.props.visit.height
    );
    const alertMessage = showAlert ? 'The current overall risk assessment is based on partial data.\nFill in your attributes in order to get a more accurate overall risk assessment.' : undefined;
    const calculator = this.props.bp && this.props.visit ? (
      <Box
        mt="20px"
        sx={{
          '@media print': {
            pageBreakBefore: 'always;'
          }
        }}
      >
        {!isMobile && <GxgBpCalculator
          sex={this.props.visit.sex ? 1 : 0}
          age={this.props.age}
          weight={this.props.visit.weight}
          height={this.props.visit.height}
          sbpScore={this.props.bp.sbp.stdized_overall_risk_sbp}
          dbpScore={this.props.bp.dbp.stdized_overall_risk_dbp}
          sbpLinReg={this.props.bp.sbp.lin_reg_all_params}
          dbpLinReg={this.props.bp.dbp.lin_reg_all_params}
          sbpQuartile={this.props.bp.sbp.quartile}
          sbpPercentile={this.props.bp.sbp.percentile + 1}
          dbpQuartile={this.props.bp.dbp.quartile}
          dbpPercentile={this.props.bp.dbp.percentile + 1}
          callbackOnChange={null}
          orderLabs={false}
          printing={this.props.printing}
          showEdit={false}
        />}
        {isMobile && <MobileGxgBpCalculator
          sex={this.props.visit.sex ? 1 : 0}
          age={this.props.age}
          weight={this.props.visit.weight}
          height={this.props.visit.height}
          sbpScore={this.props.bp.sbp.stdized_overall_risk_sbp}
          dbpScore={this.props.bp.dbp.stdized_overall_risk_dbp}
          sbpLinReg={this.props.bp.sbp.lin_reg_all_params}
          dbpLinReg={this.props.bp.dbp.lin_reg_all_params}
          sbpQuartile={this.props.bp.sbp.quartile}
          sbpPercentile={this.props.bp.sbp.percentile + 1}
          dbpQuartile={this.props.bp.dbp.quartile}
          dbpPercentile={this.props.bp.dbp.percentile + 1}
          callbackOnChange={null}
          orderLabs={false}
          printing={this.props.printing}
          showEdit={false}
        />}
      </Box>
    ) : null;

    return (
      <Box sx={{ '@media print': { '@page': { size: 'landscape;' } } }}>
        <GeneticReportOrMobile
          isDemo={false}
          reportTitle="HYPERTENSION RISK REPORT"
          configTrait="bp"
          loading={this.props.loading}
          titleImage={htnGaugeImage}
          patientNum={this.props.visit ? this.props.visit.patient_num : null}
          patientName={this.props.visit ? this.props.visit.patient_name : null}
          geneticKitId={this.props.visit ? this.props.visit.kit_id : ''}
          printing={this.props.printing}
          alertMessage={alertMessage}
          showAlert={showAlert}
          geneticRiskInfoText={[
            bpReportStrings.GENETIC_RISK_SBP_INFO_TEXT,
            bpReportStrings.GENETIC_RISK_DBP_INFO_TEXT
          ]}
          riskPercentile={
            this.props.bp
              ? [this.props.bp.sbp.percentile, this.props.bp.dbp.percentile]
              : null
          }
          personChartTitle={[
            'Systolic Blood Pressure (SBP)',
            'Diastolic Blood Pressure (DBP)'
          ]}
          patientAttributesSection={this.props.printing &&
            <Box w={"420px"} borderWidth={1} borderColor="gray.100">
              <GxgPatientAttributes
                  gender={this.props.visit.sex ? "Male": "Female"}
                  age={this.props.visit.age}
                  weight={roundWithPrecision(this.props.visit.weight, 1)}
                  height={roundWithPrecision(this.props.visit.height)}
                  riskTrait="HTN (systolic)"
                  riskLevel={getRiskLevelByPercentile(this.props.bp.sbp.percentile + 1)}
                  riskTrait2="HTN (diastolic)"
                  riskLevel2={getRiskLevelByPercentile(this.props.bp.dbp.percentile + 1)}
                  orderLabs={false}
                  labelPrefix="Current "
                  showBorder={false}
                  showAttributeFlags={bpCalculatorUserAttribute}
                  spacing={"7px"}
                >
                </GxgPatientAttributes>
              </Box>
          }
          chartsSection={null}
          calculatorSection={calculator}
          recommendationSection={<BpRecommendationSection isMobile={isMobile} />}
          explanatorySection={<BpExplanatorySection isMobile={isMobile} />}
        />
      </Box>
    );
  }
}

// $FlowFixMe
export default withNavigation(withMobile(ConsumerBpRiskReportImpl));
