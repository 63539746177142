// @flow
import React from 'react';
import PropTypes from 'prop-types';
import {Flex, Box, Text, Spacer} from '@chakra-ui/react';
import {v4 as uuid4} from "uuid";
import AbsRiskTrendChart from '../AbsRiskTrendChart';
import { getComparativeRiskText } from './report-common';
import { calcAbsRisk, calcAvgPrevalence } from '../../utils/absolute_risk';
import { getRiskColorByPercentile } from '../risk-colors';
import PopoverInfo from '../PopoverInfo';
import COLORS from "../colors";
import InProgressImpl from "../InProgress";

type Props = {
  shortTrait: string,
  longTrait: string,
  geneticPercentile: number,
  geneticDeciles: Array<number>,
  geneticOddsRatios: Array<number>,
  geneticMinY: number,
  geneticMaxY: number,
  geneticYTickInterval: number,
  geneticDecimalPoints?: number,
  geneticTrendInfoText: string,
  geneticXTitle?: string,
  overallPercentile: number,
  overallDeciles: Array<number>,
  overallOddsRatios: Array<number>,
  overallMinY: number,
  overallMaxY: number,
  overallYTickInterval: number,
  overallDecimalPoints?: number,
  overallTrendInfoText: string,
  overallXTitle?: string,
  printing: ?boolean,
  isMobile: boolean
};

export default function DoubleRiskChartsSection(props: Props) {
  const getRisk = (oddsRatios: Array<number>, deciles: Array<number>, percentile: number) => {
    const numOrQuantiles = oddsRatios.length;
    const quantile = Math.floor(percentile / (100 / numOrQuantiles));
    const prevalence = calcAvgPrevalence(deciles);
    const absRisk = calcAbsRisk(oddsRatios[quantile], prevalence) * 100;
    const avgRisk =
        calcAbsRisk(oddsRatios[Math.floor(numOrQuantiles / 2)], prevalence) *
        100;
    const compText = getComparativeRiskText(absRisk / avgRisk);
    const riskColor = getRiskColorByPercentile(percentile);
    return {absRisk, avgRisk, riskColor, compText};
  }

  const geneticRisk = getRisk(props.geneticOddsRatios, props.geneticDeciles, props.geneticPercentile);
  const overallRisk = getRisk(props.overallOddsRatios, props.overallDeciles, props.overallPercentile);
  const MIN_CHART_HEIGHT = 300;
  let height = (window.screen.width * 0.95) / 6;
  if (height < MIN_CHART_HEIGHT) height = MIN_CHART_HEIGHT;

  const geneticRiskTrend = (
    <Box key={uuid4()}>
      <AbsRiskTrendChart
        title={`${props.shortTrait} genetic risk in population`}
        xTitle={props.geneticXTitle}
        yTitle={`${props.shortTrait} Risk (%)`}
        annotationName={`Genetic risk`}
        annotationX={props.geneticPercentile}
        annotationColor={geneticRisk.riskColor}
        seriesData={props.geneticDeciles}
        seriesName={`${props.shortTrait} risk`}
        oddsRatios={props.geneticOddsRatios}
        minY={props.geneticMinY}
        maxY={props.geneticMaxY}
        yTickInterval={props.geneticYTickInterval}
        height={height}
        tooltipDecimalPoints={props.geneticDecimalPoints}
        gridlineWidth={0}
        printing={props.printing}
      />
    </Box>
  );
  const overallRiskTrend = (
    <Box key={uuid4()}>
      {props.overallPercentile && (
        <AbsRiskTrendChart
          title={`${props.shortTrait} overall risk in population`}
          xTitle={props.overallXTitle}
          yTitle={`${props.shortTrait} Risk (%)`}
          annotationName={`Overall Risk`}
          annotationX={props.overallPercentile}
          annotationColor={overallRisk.riskColor}
          seriesData={props.overallDeciles}
          seriesName={`${props.shortTrait} risk`}
          oddsRatios={props.overallOddsRatios}
          minY={props.overallMinY}
          maxY={props.overallMaxY}
          yTickInterval={props.overallYTickInterval}
          height={height}
          tooltipDecimalPoints={props.overallDecimalPoints}
          gridlineWidth={0}
          printing={props.printing}
        />)}
    </Box>
  );
  const lifetimeRiskHeader = `lifetime absolute risk to have ${props.longTrait}:`
  return (
    <>
      {!props.isMobile && (
        <Flex align="top" w="100%">
          <Flex minW="48%" maxW="48%" borderColor={"gray.100"} borderWidth={1} >
            <Spacer />
            <Box minW="100%" maxW="100%" m={"2%"}>
              <Box ml={"20px"}>
                <Text fontWeight={"bold"}>GENETIC RISK</Text>
                <Text mt={"10px"} fontSize={13}>Based on genetics only:</Text>
                <Flex color={COLORS.REPORT_TEXT} mb={"20px"}>
                  <Text>{lifetimeRiskHeader}</Text>
                  <Text ml="5px" fontWeight="bold">{geneticRisk.absRisk.toFixed(1)}%</Text>
                </Flex>
              </Box>
              <Box mr={"2%"}>
                <PopoverInfo
                  trigger={geneticRiskTrend}
                  header={`Lifetime absolute genetic risk`}
                  text={props.geneticTrendInfoText}
                />
              </Box>
              <Box ml={"2%"}>
                <Text mt="1px" ml="10px" fontSize={13}>
                  Your genetic risk to develop {props.longTrait} is
                </Text>
                <Text mt="1px" ml="10px" fontSize={13} color={COLORS.REPORT_TEXT}>
                  <b>{geneticRisk.compText}</b>
                </Text>
              </Box>
            </Box>
            <Spacer />
          </Flex>
          {!props.overallPercentile && <InProgressImpl label="Calculating overall risk ..." />}
          {!!props.overallPercentile && <Flex minW="48%" maxW="48%" borderColor={"gray.100"} borderWidth={1} ml={"4%"}>
            <Spacer />
            <Box minW="100%" maxW="100%" m={"2%"}>
              <Box ml={"20px"}>
                <Text fontWeight={"bold"}>OVERALL RISK</Text>
                <Text mt={"10px"} fontSize={13} mr={"10px"}>Based on a collection of risk factors including genetic risk and the latest existing clinical data:</Text>
                <Flex color={COLORS.REPORT_TEXT} mb={"20px"}>
                  <Text>{lifetimeRiskHeader}</Text>
                  <Text ml="5px" fontWeight="bold">{overallRisk.absRisk.toFixed(1)}%</Text>
                </Flex>
              </Box>
              <Box mr={"2%"}>
                <PopoverInfo
                  trigger={overallRiskTrend}
                  header={`Lifetime absolute overall risk`}
                  text={props.overallTrendInfoText}
                />
              </Box>
              <Box ml={"2%"}>
                <Text mt="1px" ml="10px" fontSize={13}>
                  Your overall risk to develop {props.longTrait} is
                </Text>
                <Text mt="1px" ml="10px" fontSize={13} color={COLORS.REPORT_TEXT}>
                  <b>{overallRisk.compText}</b>
                </Text>
              </Box>
            </Box>
            <Spacer />
          </Flex>}
        </Flex>)}
      {props.isMobile && (
        <Box align="top" w="100%">
          <Box ml={"20px"}>
            <Text fontWeight={"bold"}>GENETIC RISK</Text>
            <Text mt={"10px"} fontSize={13}>Based on genetics only:</Text>
            <Flex color={COLORS.REPORT_TEXT} mb={"20px"}>
              <Text>{'lifetime absolute risk:'}</Text>
              <Text ml="5px" fontWeight="bold">{geneticRisk.absRisk.toFixed(1)}%</Text>
            </Flex>
          </Box>
          <Box mr={"2%"}>
            <PopoverInfo
              trigger={geneticRiskTrend}
              header={`Lifetime absolute genetic risk`}
              text={props.geneticTrendInfoText}
            />
          </Box>
          <Box ml={"2%"}>
            <Text mt="1px" ml="10px" fontSize={13}>
              Your genetic risk to develop {props.longTrait} is
            </Text>
            <Text mt="1px" ml="10px" fontSize={13} color={COLORS.REPORT_TEXT}>
              <b>{geneticRisk.compText}</b>
            </Text>
          </Box>
          {!props.overallPercentile && <InProgressImpl label="Calculating overall risk ..." />}
          {props.overallPercentile && (
            <Box mt={"20px"}>
              <Box ml={"20px"}>
                <Text fontWeight={"bold"}>OVERALL RISK</Text>
                <Text mt={"10px"} fontSize={13} mr={"10px"}>Based on a collection of risk factors including genetic risk and the latest existing clinical data:</Text>
                <Flex color={COLORS.REPORT_TEXT} mb={"20px"}>
                  <Text>{'lifetime absolute risk:'}</Text>
                  <Text ml="5px" fontWeight="bold">{overallRisk.absRisk.toFixed(1)}%</Text>
                </Flex>
              </Box>
              <Box mr={"2%"}>
                <PopoverInfo
                  trigger={overallRiskTrend}
                  header={`Lifetime absolute overall risk`}
                  text={props.overallTrendInfoText}
                />
              </Box>
              <Box ml={"2%"}>
                <Text mt="1px" ml="10px" fontSize={13}>
                  Your overall risk to develop {props.longTrait} is
                </Text>
                <Text mt="1px" ml="10px" fontSize={13} color={COLORS.REPORT_TEXT}>
                  <b>{overallRisk.compText}</b>
                </Text>
              </Box>
            </Box>
          )}
        </Box>)}
    </>
  );
}

DoubleRiskChartsSection.propTypes = {
  geneticDecimalPoints: PropTypes.number,
  overallDecimalPoints: PropTypes.number,
  geneticXTitle: PropTypes.string,
  overallXTitle: PropTypes.string
};

DoubleRiskChartsSection.defaultProps = {
  geneticDecimalPoints: 1,
  overallDecimalPoints: 1,
  geneticXTitle: "percentiles of population by genetic risk",
  overallXTitle: "percentiles of population by overall risk"
};
