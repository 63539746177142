// @flow
import model from './model-curves.json';

export const HEART_AGE_QUANTILES = 5;
export const HEART_AGE_MID_QUANTILE = Math.floor(HEART_AGE_QUANTILES / 2);
export const HEART_AGE_MIN_AGE = 40;
export const HEART_AGE_MAX_AGE = 90;

export const HEART_AGE_MAX_LINEAR_AGE = 47;

export function calcPrevalence(age: number, q: number) {
  const key = `q${q}`;
  if (age < HEART_AGE_MAX_LINEAR_AGE) {
    const { a, b } = model.linear[key];
    return a * age + b;
  }
  const { a, b, c } = model.parabola[key];
  return a * age ** 2 + b * age + c;
}

export function findAgeByPrevParabola(q: number, prev: number) {
  const curve = model.parabola[`q${q}`];

  const { a, b, c } = curve;
  const c2 = c - prev;

  const x1 = (-b + Math.sqrt(b ** 2 - 4 * a * c2)) / (2 * a);
  const x2 = (-b - Math.sqrt(b ** 2 - 4 * a * c2)) / (2 * a);
  // console.log(`x1: ${x1}, x2: ${x2}`);
  return x1 >= x2 ? x1 : x2;
}

export function findAgeByPrevLinear(q: number, prev: number) {
  const linear = model.linear[`q${q}`];
  const { a, b } = linear;
  return (prev - b) / a;
}

export function calcHeartAge(age: number, q: number) {
  if (age < HEART_AGE_MIN_AGE) return age;
  const prev = calcPrevalence(age, q);
  if (age < HEART_AGE_MAX_LINEAR_AGE)
    return findAgeByPrevLinear(HEART_AGE_MID_QUANTILE, prev);
  return findAgeByPrevParabola(HEART_AGE_MID_QUANTILE, prev);
}

export function getCurve(q: number) {
  const key = `q${q}`;
  return model.parabola[key];
}

export function getHeartAgeQuantile(percentile: number) {
  return Math.floor(percentile / (100 / HEART_AGE_QUANTILES));
}

export function getMaxAgeForHeartAgeCalc(quantile: number) {
  let age;
  for (age = HEART_AGE_MAX_AGE; age > HEART_AGE_MIN_AGE; age -= 1) {
    if (calcHeartAge(age, quantile) <= HEART_AGE_MAX_AGE) break;
  }
  return age;
}
