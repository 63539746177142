// @flow
import React from 'react';
import { Flex, Box, Text, Image } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import COLORS from '../common/colors';
import IconTextAndLink from '../common/IconTextAndLink';
import htnGaugeImage from '../images/blood-pressure-gauge-light.png';
import dietImage from '../images/diet.png';
import runningImage from '../images/running.png';
import stressImage from '../images/yoga.png';
import noSmokingImage from '../images/no-smoking.png';
import firstAidImage from '../images/first-aid-kit.png';
import saltImage from '../images/salt.png';

type Props = {
  isMobile: boolean
};

export default function BpRecommendationSection(props: Props) {
  return (
    <Box
      sx={{
        '@media print': {
          pageBreakBefore: !props.isMobile ? 'always' : 'auto'
        }
      }}
    >
      <Text mt="40px" color={COLORS.REPORT_TEXT} fontWeight="semibold">
        To prevent hypertension and its risks we recommend following the
        guidelines below:
      </Text>
      <IconTextAndLink
        imageSrc={htnGaugeImage}
        text="Monitoring your blood pressure at home"
        linkText="Monitoring your BP at home"
        link="https://www.heart.org/en/health-topics/high-blood-pressure/understanding-blood-pressure-readings/monitoring-your-blood-pressure-at-home"
        isMobile={props.isMobile}
      />
      <IconTextAndLink
        imageSrc={dietImage}
        text="Diet"
        linkText="Managing BP with a heart-healthy diet"
        link="https://www.heart.org/en/health-topics/high-blood-pressure/changes-you-can-make-to-manage-high-blood-pressure/managing-blood-pressure-with-a-heart-healthy-diet"
        isMobile={props.isMobile}
      />
      <IconTextAndLink
        imageSrc={saltImage}
        text="Salt limitation"
        linkText="Shaking the salt habit to lower high BP"
        link="https://www.heart.org/en/health-topics/high-blood-pressure/changes-you-can-make-to-manage-high-blood-pressure/shaking-the-salt-habit-to-lower-high-blood-pressure"
        isMobile={props.isMobile}
      />
      <IconTextAndLink
        imageSrc={runningImage}
        text="Exercise"
        linkText="Getting active to control high BP"
        link="https://www.heart.org/en/health-topics/high-blood-pressure/changes-you-can-make-to-manage-high-blood-pressure/getting-active-to-control-high-blood-pressure"
        isMobile={props.isMobile}
      />
      <IconTextAndLink
        imageSrc={stressImage}
        text="Stress reduction"
        linkText="Managing stress to control high BP"
        link="https://www.heart.org/en/health-topics/high-blood-pressure/changes-you-can-make-to-manage-high-blood-pressure/managing-stress-to-control-high-blood-pressure"
        isMobile={props.isMobile}
      />
      <IconTextAndLink
        imageSrc={noSmokingImage}
        text="Smoking cessation"
        linkText="Smoking, high BP and your heart"
        link="https://www.heart.org/en/health-topics/high-blood-pressure/changes-you-can-make-to-manage-high-blood-pressure/smoking-high-blood-pressure-and-your-health"
        isMobile={props.isMobile}
      />
      <Flex mt="30px" mb="10px" align="center">
        <Image src={firstAidImage} h="32px" minW="32px" alt="" mr="10px" />
        <Text fontSize={20} color="gray.600">
          Medications - ask your doctor
        </Text>
      </Flex>
    </Box>
  );
}

BpRecommendationSection.propTypes = {
  isMobile: PropTypes.bool
};

BpRecommendationSection.defaultProps = {
  isMobile: false
};
