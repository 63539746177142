import React, {useCallback, useMemo} from 'react';
import {
  MenuItem, MenuGroup, Image
} from '@chakra-ui/react';
import { v4 as uuid4 } from 'uuid';

import withNavigation from '../main/WithRoutes';
// import {getPatientId} from "./filters";
// import {isMaleDemoPatient} from "./clinical";
import {LEFT_BAR_ITEMS} from "./demo-report-items";

type Props = {
  params: any,
  navigate: any,
  location: any,
  onSelectedItemChange: any,
  selectedGroup: string
};


export function DemoMobileReportsMenu(props: Props) {
  // const patientId = getPatientId(props.params, props.location);
  // const isMale = isMaleDemoPatient(patientId);

  const items = LEFT_BAR_ITEMS;

  const defaultSelection = useMemo(()=> {
    const defaultSelection = {};
    Object.keys(items).forEach((key) => {
      defaultSelection[key] = false;
      Object.keys(items[key].childItems).forEach((childKey) => {
        defaultSelection[childKey] = false;
      });
    });
    return defaultSelection;
  }, [items]);

  const itemKeys = useMemo(() => {
    const keysMap = {}
    Object.entries(items).forEach(([itemKey, item]) => {
      Object.entries(item.childItems).forEach(([childKey, childItem]) => {
        keysMap[childItem.name] = childKey;
      });
    });
    return keysMap;
  }, [items]);

  const [selection, setSelection] = React.useState(defaultSelection);
  const {onSelectedItemChange} = props;
  const getGroupAndItem = useCallback((token) => {
    if (Object.keys(items).includes(token)) {
      return {
        group: token,
        item: Object.keys(items[token].childItems)[0]
      };
    }
    const groupAndItem= {
      group: undefined,
      item: undefined
    };

    Object.entries(items).forEach(([group, groupItem]) => {
      Object.keys(groupItem.childItems).forEach((childKey) => {
        if (childKey === token) {
          groupAndItem.group = group;
          groupAndItem.item = childKey
        }
      });
    });
    return groupAndItem;
  }, [items]);
  const onItemSelected = useCallback((e) => {
    const token = e.target.innerText;
    const updatedSelection = {...defaultSelection}
    updatedSelection[itemKeys[token]] = true;
    setSelection(updatedSelection)
    const {group, item} = getGroupAndItem(itemKeys[token]);
    onSelectedItemChange(group, item);
  }, [onSelectedItemChange, defaultSelection, getGroupAndItem, itemKeys])

  // const female_only_items = useMemo(()=>  ['breast cancer', 'breast'], []);
  // const male_only_items = useMemo( () => ['prostate cancer', 'prostate'], []);

  // const isDisabledItem = useCallback(itemName => {
  //   let disabled = false;
  //   if (isMale && female_only_items.includes(itemName.toLowerCase())) {
  //     disabled = true;
  //   } else if (!isMale && male_only_items.includes(itemName.toLowerCase())) {
  //     disabled = true;
  //   }
  //   return disabled;
  // }, [isMale, female_only_items, male_only_items]);


  return (
    <>
      {Object.entries(items).map(([itemKey, item]) => (
        <MenuGroup key={uuid4()} title={item.name}>
          {Object.entries(item.childItems).map(([childKey, childItem]) => (
            <MenuItem
              key={uuid4()}
              isDisabled={false}
              onClick={onItemSelected}
              bg={selection[childKey] ? "gray.100" : "transparent" }
              fontWeight={selection[childKey] ? "bold" : "normal"}
            >
              <Image
                src={childItem.icon}
                boxSize='16px'
                alt={childItem.name}
                mr='12px'
              />
              <span>{childItem.name}</span>
            </MenuItem>
          ))}
        </MenuGroup>))}
     </>
  );
}

export default withNavigation(DemoMobileReportsMenu);
