// @flow
import React from 'react';
import { Box } from '@chakra-ui/react';
import { getFileNameOnly } from '../../common/reports/report-common';
import { getPatientId } from '../filters';
import GeneticReport from '../../common/reports/GeneticReport';
import CkdRecommendationSection from './CkdRecommendationSection';
import CkdExplanatorySection from './CkdExplanatorySection';
import ckdImage from '../../images/kidney-bw.png';
import withNavigation from '../../main/WithRoutes';
import CkdChartsSection from './CkdChartsSection';

type Props = {
  location: any,
  params: any
};
type State = {};

export class DemoCkdRiskReportImpl extends React.Component<Props, State> {
  static loadDemoData() {
    // $FlowFixMe[prop-missing]
    const requireContext: any = require.context('./data', true, /\.json$/);
    const json = {};
    requireContext.keys().forEach(key => {
      const obj = requireContext(key);
      const simpleKey = getFileNameOnly(key);
      json[simpleKey] = obj;
    });
    return json;
  }

  constructor(props: Props) {
    super(props);
    this.patientId = getPatientId(props.params, props.location);
    const ckds = DemoCkdRiskReportImpl.loadDemoData();
    const key = `ckd${this.patientId}`;
    this.ckd = ckds[key] === undefined ? ckds.ckd1 : ckds[key];
  }

  ckd: Object;
  patientId: number;

  render() {
    return (
      <Box>
        <GeneticReport
          reportTitle="CHRONIC KIDNEY DISEASE RISK REPORT"
          configTrait="ckd"
          titleImage={ckdImage}
          patientNum={this.patientId}
          geneticKitId={`OD000${this.patientId}`}
          riskPercentile={this.ckd.ckd.percentile}
          chartsSection={
            <CkdChartsSection
              percentile={this.ckd.ckd.percentile}
              quintile={Math.floor(this.ckd.ckd.percentile / 20)}
              oddsRatios={this.ckd.ckd.odds_list}
              deciles={this.ckd.ckd.deciles}
              isMobile={false}
            />
          }
          recommendationSection={<CkdRecommendationSection />}
          explanatorySection={<CkdExplanatorySection isMobile={false} />}
        />
      </Box>
    );
  }
}

export default withNavigation(DemoCkdRiskReportImpl);
