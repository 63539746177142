// @flow
import React from "react";
import {Box, Image} from "@chakra-ui/react";

export function imageComp(image: any, h: ?string, w: ?string) {
  return (<Image h={h ?? "16px"} w={w ?? "16px"} src={image}/>);
}

export function phosphorImageComp(image: any, h: ?string, w: ?string) {
  return (<Box as={image} h={h ?? "16px"} w={w ?? "16px"} src={image}/>)
}
