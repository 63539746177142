// @flow
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import routes, {isSelfUrl} from '../main/routes';
import NotFoundPage from '../main/NotFoundPage';
import withNavigation from '../main/WithRoutes';
import {canSeeSelfPages} from '../auth/permissions';
import ConsumerPatientAttributes from "../consumer/ConsumerPatientAttributes";
import PilotPatientRisks from "./PilotPatientRisks";

type Props = {
	patientId: string,
  params: Object,
  location: Object
};

function SelfPages(props: Props) {
  const urlPatientId = props.params.patient;
  if (isSelfUrl(props.location.pathname)) {
    const canSeePatient = canSeeSelfPages(urlPatientId, props.patientId);
    if (!canSeePatient) {
      return (
          <Routes>
            <Route path={routes.ROUTE_NOT_FOUND} element={<NotFoundPage/>}/>;
          </Routes>
      );
    }
  }
  return (
    <Routes>
      <Route
        path={routes.SELF_ATTRIBUTES}
        element={<ConsumerPatientAttributes />}
      />
      <Route
        path={routes.SELF_RISKS}
        element={<PilotPatientRisks />}
      />
      {/*<Route*/}
      {/*  path={routes.SELF_CARDIO}*/}
      {/*  element={<PilotCardioReport />}*/}
      {/*/>*/}
      {/*<Route*/}
      {/*  path={routes.SELF_BP}*/}
      {/*  element={<PilotBpRiskReport />}*/}
      {/*/>*/}
      {/*<Route*/}
      {/*  path={routes.SELF_CAD}*/}
      {/*  element={<PilotCadRiskReport />}*/}
      {/*/>*/}
      {/*<Route*/}
      {/*  path={routes.SELF_T2D}*/}
      {/*  element={<PilotT2dRiskReport />}*/}
      {/*/>*/}
      {/*<Route*/}
      {/*  path={routes.SELF_CHOLESTEROL}*/}
      {/*  element={<PilotHypercholesterolRiskReport />}*/}
      {/*/>*/}
      <Route path={routes.ROUTE_NOT_FOUND} element={<NotFoundPage />} />
    </Routes>
  );
}

export default withNavigation(SelfPages);
