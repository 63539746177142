// @flow
export default function getQuantile(
  val: number,
  quantileThresholds: Array<number>
) {
  let index = 0;
  quantileThresholds.forEach(thresh => {
    if (val >= thresh) index += 1;
  });
  // just in case - treat out high range quantile
  if (index === quantileThresholds.length - 1)
    index = quantileThresholds.length - 2;
  return index;
}
