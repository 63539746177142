// @flow
export function mmolL2mgdlNullAllowed(val: ?number) {
  if (val === null || val === undefined) return null;
  return 39 * val;
}

export function mmolL2mgdl(val: number) {
  return 39 * val;
}

export function mgdl2mmolLNullAllowed(val: ?number) {
  if (val === null || val === undefined) return null;
  return val / 39;
}

export function mgdl2mmolL(val: number) {
  return val / 39;
}
