// @flow
import React from 'react';

import COLORS from '../../common/colors';
import {
  getShortComparativeOddsRatioText,
  getSummaryOddsRatioText
} from '../../common/reports/report-common';
import upRed from '../../images/up-red.png';
import downGreen from '../../images/down-green.png';
import { mgdl2mmolL, mmolL2mgdl } from '../../utils/cholesterol';
import { isNanOrNullOrUndefined } from '../../utils/numbers';
import traitRanges from '../../common/reports/trait-ranges.json';
import {
  getDefaultAge,
  getDefaultGender,
  getDefaultSbp,
  getDefaultTc,
  getDefaultHdl,
  getDefaultLdl,
  getDefaultDbp
} from '../../common/reports/trait-defaults';
import getQuantile from '../../utils/quantile';
import type { RiskChange } from '../../common/reports/risk-change';

type Props = {
  cadQuintile: number,
  cadPercentile: number,
  age: ?number,
  sex: number,
  sbp: number,
  dbp: number,
  tc: number,
  hdl: number,
  ldl: number,
  diabetes: boolean,
  smoker: boolean,
  bpTreated: boolean,
  cadScore: number,
  stdizedCadScore: ?number,

  cadLogReg: Object,
  orderLabs: boolean,
  pcs: Array<number>,

  callbackInitRiskPercentile: any,

  apolipoproteinB: number,
  alcohol: boolean,
  statinsTreated: boolean,
  whiteBloodCellLeukocyteCount: number,
  alkalinePhosphatase: number,
  cholesterol: number,
  creatinine: number,
  cystatinC: number,
  gammaGlutamyltransferase: number,
  glucose: number,
  glycatedHaemoglobinHba1c: number,
  phosphate: number,
  shbg: number,
  totalBilirubin: number,
  testosterone: number,
  totalProtein: number,
  urate: number,
  vitaminD: number,
  lipoproteinA: number
};

type State = {
  playingEnabled: boolean,
  ageSliderEnabled: boolean,
  tcSliderEnabled: boolean,
  hdlSliderEnabled: boolean,
  ldlSliderEnabled: boolean,
  sbpSliderEnabled: boolean,
  gender: ?string,
  sex: number,
  age: ?number,
  sbp: number,
  dbp: number,
  tc: number,
  hdl: number,
  ldl: number,
  diabetes: boolean,
  smoker: boolean,
  bpTreated: boolean,
  current: ?number,
  expected: ?number,
  shortCadText: string,
  longCadText: string,
  summaryChangeLine: string,
  cadTrendIcon: any,
  changeColor: string,
  currentPercentile: ?number,
  expectedPercentile: ?number,
  shortCadTextPercentile: string,
  longCadTextPercentile: string,
  summaryChangeLinePercentile: string,
  cadTrendIconPercentile: any,
  changeColorPercentile: string
};


export default function withCadCalculatorEx(WrappedComponent: any) {
  return class WithCadCalculatorEx extends React.Component<Props, State> {
    static getRiskText(quintile: number) {
      if (quintile === 0) return 'Low';
      if (quintile < 4) return 'Moderate';
      return 'High';
    }

    static getSwitchColor() {
      return 'red';
    }

    static getSwitchText(isChecked: boolean) {
      return isChecked ? 'YES' : 'NO';
    }

    static getLongDiffText(
      expected: ?number,
      current: number,
      trait: string,
      units: string,
      fixed: number
    ) {
      if (expected === current || !expected) return '';
      const diff = expected - current;
      const trend = diff > 0 ? 'increase' : 'decrease';
      return `\u2022 ${trait} ${trend} of ${Math.abs(diff).toFixed(
        fixed
      )} ${units}\n`;
    }

    static getSmokingStateChange(expected: boolean, current: boolean) {
      if (expected === current) return '';
      if (!expected) return '\u2022 quitting smoking';
      return '\u2022 starting smoking';
    }

    static getBinaryStateText(checked: boolean) {
      return checked ? 'yes' : 'no';
    }

    static calcRelativeRiskForCallback(oddsRatio: number) {
      if (oddsRatio < 1) return -((1 - oddsRatio) * 100);
      if (oddsRatio > 2) return 100;
      return (oddsRatio - 1) * 100;
    }

    constructor(props: Props) {
      super(props);
      (this: any).onAgeSliderChange = this.onAgeSliderChange.bind(this);
      (this: any).getAgeDiffText = this.getAgeDiffText.bind(this);
      (this: any).onSbpSliderChange = this.onSbpSliderChange.bind(this);
      (this: any).getSbpDiffText = this.getSbpDiffText.bind(this);
      (this: any).getSliderSbpColor = this.getSliderSbpColor.bind(this);
      (this: any).onTcSliderChange = this.onTcSliderChange.bind(this);
      (this: any).getTcDiffText = this.getTcDiffText.bind(this);
      (this: any).getSliderTcColor = this.getSliderTcColor.bind(this);
      (this: any).getHdlDiffText = this.getHdlDiffText.bind(this);
      (this: any).getLdlDiffText = this.getLdlDiffText.bind(this);
      (this: any).onHdlSliderChange = this.onHdlSliderChange.bind(this);
      (this: any).onLdlSliderChange = this.onLdlSliderChange.bind(this);
      (this: any).getSliderHdlColor = this.getSliderHdlColor.bind(this);
      (this: any).getSliderLdlColor = this.getSliderLdlColor.bind(this);
      (this: any).onDiabetesSwitchChange = this.onDiabetesSwitchChange.bind(
        this
      );
      (this: any).onSmokerSwitchChange = this.onSmokerSwitchChange.bind(this);
      (this: any).onBpTreatedSwitchChange = this.onBpTreatedSwitchChange.bind(
        this
      );
      (this: any).onGenderSelected = this.onGenderSelected.bind(this);
      (this: any).onCurrentAgeChange = this.onCurrentAgeChange.bind(this);
      (this: any).onCurrentTcChange = this.onCurrentTcChange.bind(this);
      (this: any).onCurrentHdlChange = this.onCurrentHdlChange.bind(this);
      (this: any).onCurrentLdlChange = this.onCurrentLdlChange.bind(this);
      (this: any).onCurrentSbpChange = this.onCurrentSbpChange.bind(this);
      (this: any).onCurrentDbpChange = this.onCurrentDbpChange.bind(this);

      (this: any).onCurrentBpTreatedChange = this.onCurrentBpTreatedChange.bind(
        this
      );
      (this: any).onCurrentDiabetesChange = this.onCurrentDiabetesChange.bind(
        this
      );
      (this: any).onCurrentSmokerChange = this.onCurrentSmokerChange.bind(this);

      (this: any).updatePlayingEnabled = this.updatePlayingEnabled.bind(this);

      (this: any).runLogReg = this.runLogReg.bind(this);

      (this: any).calcExpectedCadRiskChange = this.calcExpectedCadRiskChange.bind(
        this
      );
      (this: any).onResetAttributes = this.onResetAttributes.bind(this);

      this.currentAge = this.props.age;
      this.currentSbp = this.props.sbp;
      this.currentDbp = this.props.dbp;
      this.currentTc = this.props.tc ? mmolL2mgdl(this.props.tc) : undefined;
      this.currentHdl = this.props.hdl ? mmolL2mgdl(this.props.hdl) : undefined;
      this.currentLdl = this.props.ldl ? mmolL2mgdl(this.props.ldl) : undefined;
      this.currentBpTreated = !!this.props.bpTreated;
      this.currentDiabetes = !!this.props.diabetes;
      this.currentSmoker = !!this.props.smoker;
      this.resetAge = getDefaultAge(this.props.age);
      this.resetSbp = getDefaultSbp(this.props.sbp);
      this.resetDbp = getDefaultDbp(this.props.dbp);
      this.resetTc = getDefaultTc(this.props.tc);
      this.resetHdl = getDefaultHdl(this.props.hdl);
      this.resetLdl = getDefaultLdl(this.props.ldl);
      this.minAge = this.props.age ? this.props.age : this.resetAge;
      if (this.minAge < traitRanges.age.lowLimit)
        this.minAge = traitRanges.age.lowLimit;
      if (this.minAge > traitRanges.age.highLimit)
        this.minAge = traitRanges.age.highLimit;
      const defaultGender = getDefaultGender(this.props.sex);
      const playingEnabled = !!defaultGender && !!this.currentAge;
      const initPercentile = this.calcInitialOverallRiskPercentile(
        this.props.sex ? this.props.sex : 0
      );
      if (this.props.callbackInitRiskPercentile) {
        this.props.callbackInitRiskPercentile(initPercentile);
      }

      this.state = {
        sex: this.props.sex ? this.props.sex : 0,
        gender: defaultGender,
        age: this.resetAge,
        sbp: this.resetSbp,
        dbp: this.resetDbp,
        tc: this.resetTc,
        hdl: this.resetHdl,
        ldl: this.resetLdl,
        diabetes: this.currentDiabetes,
        smoker: this.currentSmoker,
        bpTreated: this.currentBpTreated,
        playingEnabled,
        tcSliderEnabled: playingEnabled && !!this.props.tc,
        ageSliderEnabled: playingEnabled && !!this.props.age,
        hdlSliderEnabled: playingEnabled && !!this.props.hdl,
        ldlSliderEnabled: playingEnabled && !!this.props.ldl,
        sbpSliderEnabled: playingEnabled && !!this.props.sbp,
        current: 0,
        expected: 0,
        shortCadText: '',
        longCadText: '',
        summaryChangeLine: '',
        cadTrendIcon: undefined,
        changeColor: 'transparent',
        currentPercentile: initPercentile || 0,
        expectedPercentile: initPercentile || 0,
        shortCadTextPercentile: '',
        longCadTextPercentile: '',
        summaryChangeLinePercentile: '',
        cadTrendIconPercentile: undefined,
        changeColorPercentile: 'transparent'
      };
      this.recentDiffPercentile = undefined;
      this.recentRelativeRisk = 0;
      this.recentRelativeOddsRatio = 1;
    }

    onCalculatorChange() {
      const change = this.calcExpectedCadRiskChange();

      this.setState({
        current: change.relative.current,
        expected: change.relative.expected,
        shortCadText: change.relative.shortText,
        longCadText: change.relative.longText,
        summaryChangeLine: change.relative.summaryChangeLine,
        cadTrendIcon: change.relative.trendIcon,
        changeColor: change.relative.changeColor,
        currentPercentile: change.overall.current,
        expectedPercentile: change.overall.expected,
        shortCadTextPercentile: change.overall.shortText,
        longCadTextPercentile: change.overall.longText,
        summaryChangeLinePercentile: change.overall.summaryChangeLine,
        cadTrendIconPercentile: change.overall.trendIcon,
        changeColorPercentile: change.overall.changeColor
      });
      this.updatePlayingEnabled();
    }

    onGenderSelected(gender: string) {
      let sex = 0;
      if (gender === 'male') {
        sex = 1;
      }
      if (gender === 'female') {
        sex = 0;
      }
      this.setState({
        gender,
        sex
      });
    }

    onCurrentAgeChange(newAge: number) {
      this.currentAge = newAge;
      if (!this.currentAge || Number.isNaN(this.currentAge)) return;
      this.resetAge = this.currentAge;
      this.setState({ age: this.currentAge }, () => this.onResetAttributes());
    }

    onCurrentTcChange(newTc: number) {
      this.currentTc = newTc;
      if (!this.currentTc || Number.isNaN(this.currentTc)) return;
      this.resetTc = this.currentTc;
      this.setState({ tc: this.currentTc }, () => this.onResetAttributes());
    }

    onCurrentHdlChange(newHdl: number) {
      this.currentHdl = newHdl;
      if (!this.currentHdl || Number.isNaN(this.currentHdl)) return;
      this.resetHdl = this.currentHdl;
      this.setState({ hdl: this.currentHdl }, () => this.onResetAttributes());
    }

    onCurrentLdlChange(newLdl: number) {
      this.currentLdl = newLdl;
      if (!this.currentLdl || Number.isNaN(this.currentLdl)) return;
      this.resetLdl = this.currentLdl;
      this.setState({ ldl: this.currentLdl }, () => this.onResetAttributes());
    }

    onCurrentSbpChange(newSbp: number) {
      this.currentSbp = newSbp;
      if (!this.currentSbp || Number.isNaN(this.currentSbp)) return;
      this.resetSbp = this.currentSbp;
      this.setState({
        sbp: this.currentSbp
      });
    }

    onCurrentDbpChange(newDbp: number) {
      this.currentDbp = newDbp;
      if (!this.currentDbp || Number.isNaN(this.currentDbp)) return;
      this.resetDbp = this.currentDbp;
      this.setState({
        dbp: this.currentDbp
      });
    }

    onCurrentBpTreatedChange(bpTreated: boolean) {
      this.currentBpTreated = bpTreated;
      this.setState({
        bpTreated: this.currentBpTreated
      });
    }

    onCurrentDiabetesChange(diabetes: boolean) {
      this.currentDiabetes = diabetes;
      this.setState({
        diabetes: this.currentDiabetes
      });
    }

    onCurrentSmokerChange(smoker: boolean) {
      this.currentSmoker = smoker;
      this.setState({
        smoker: this.currentSmoker
      });
      // this.onCalculatorChange();
    }

    onDiabetesSwitchChange(isChecked: boolean) {
      this.setState(
        {
          diabetes: isChecked
        },
        this.onCalculatorChange
      );
    }

    onSmokerSwitchChange(isChecked: boolean) {
      this.setState(
        {
          smoker: isChecked
        },
        this.onCalculatorChange
      );
    }

    onBpTreatedSwitchChange(isChecked: boolean) {
      this.setState(
        {
          bpTreated: isChecked
        },
        this.onCalculatorChange
      );
    }

    onAgeSliderChange(newAge: number) {
      this.setState({
        age: newAge
      });
      this.onCalculatorChange();
    }

    onSbpSliderChange(newSbp: number) {
      this.setState({
        sbp: newSbp
      });
      this.onCalculatorChange();
    }

    onTcSliderChange(newTc: number) {
      this.setState({
        tc: newTc
      });
      this.onCalculatorChange();
    }

    onHdlSliderChange(newHdl: number) {
      this.setState({
        hdl: newHdl
      });
      this.onCalculatorChange();
    }

    onLdlSliderChange(newLdl: number) {
      this.setState({
        ldl: newLdl
      });
      this.onCalculatorChange();
    }

    onResetAttributes() {
      this.setState(
        {
          age: this.resetAge,
          sbp: this.resetSbp,
          hdl: this.resetHdl,
          ldl: this.resetLdl,
          tc: this.resetTc,
          bpTreated: this.currentBpTreated,
          smoker: this.currentSmoker,
          diabetes: this.currentDiabetes
        },
        this.onCalculatorChange
      );
    }

    getOverallRiskChangeDetails(expected: ?number, current: ?number) {
      if (!expected) {
        return WithCadCalculatorEx.getNoChangeDetails();
      }
      const diff: number =
        expected === undefined ||
        current === undefined ||
        expected === null ||
        current === null
          ? 0
          : expected - current;

      const trend: string = diff >= 0 ? 'increase' : 'decrease';
      let color = COLORS.REPORT_TEXT;
      let diffIcon: any = null;
      if (diff > 0) {
        color = COLORS.RED_STATUS;
        diffIcon = upRed;
      } else if (diff < 0) {
        color = COLORS.GREEN_STATUS;
        diffIcon = downGreen;
      }

      if (diff !== this.recentDiffPercentile) {
        // console.log(`diff percentile is ${diff}`);
        this.recentDiffPercentile = diff;
      }
      const shortRiskChange = `${diff}%`;
      const longText = this.getTextOfChangedAttributes(diff);
      const summaryChangeLine = `${trend} your overall risk to develop coronary artery disease`;
      const riskChange: RiskChange = {
        current,
        expected,
        shortText: shortRiskChange,
        longText,
        summaryChangeLine,
        trendIcon: diffIcon,
        changeColor: color
      };
      return riskChange;
    }

    static getNoChangeDetails() {
      const riskChange: RiskChange = {
        current: 0,
        expected: 0,
        shortText: '',
        longText: '',
        summaryChangeLine: '',
        trendIcon: undefined,
        changeColor: 'transparent'
      };
      return riskChange;
    }

    getRelativeRiskChangeDetails(expected: ?number, current: ?number) {
      if (!expected) {
        return WithCadCalculatorEx.getNoChangeDetails();
      }
      const diff: number =
        expected === undefined ||
        current === undefined ||
        expected === null ||
        current === null
          ? 0
          : expected - current;
      const trend: string = diff >= 0 ? 'increase' : 'decrease';
      const oddsRatio = expected / (current || 1);
      let shortRiskChange = getShortComparativeOddsRatioText(oddsRatio);

      const isSignificantDiff =
        shortRiskChange !== '-0%' && shortRiskChange !== '+0%';
      let summaryChangeLine = `${trend} your relative risk to develop coronary artery disease ${getSummaryOddsRatioText(
        oddsRatio
      )}`;
      let color = COLORS.REPORT_TEXT;

      if (isSignificantDiff) {
        color = diff > 0 ? COLORS.RED_STATUS : COLORS.GREEN_STATUS;
      } else {
        shortRiskChange = '~ 0 %';
        summaryChangeLine =
          'have a minor effect on your relative risk to develop coronary artery disease';
      }
      const longText = this.getTextOfChangedAttributes(diff);
      let diffIcon: any = null;
      if (isSignificantDiff && diff > 0) diffIcon = upRed;
      if (isSignificantDiff && diff < 0) diffIcon = downGreen;

      const relativeRisk = WithCadCalculatorEx.calcRelativeRiskForCallback(
        oddsRatio
      );
      if (
        relativeRisk !== this.recentRelativeRisk ||
        oddsRatio !== this.recentRelativeOddsRatio
      ) {
        // console.log(`relative risk ${relativeRisk}`);
        this.recentRelativeRisk = relativeRisk;
        this.recentRelativeOddsRatio = oddsRatio;
      }
      const riskChange: RiskChange = {
        current,
        expected,
        shortText: shortRiskChange,
        longText,
        summaryChangeLine,
        trendIcon: diffIcon,
        changeColor: color
      };
      return riskChange;
    }

    getTextOfChangedAttributes(diff: number) {
      let longText = '';
      if (diff !== 0) {
        longText = 'Changes to the following attributes:\n';
        longText += WithCadCalculatorEx.getLongDiffText(
          this.state.age,
          this.resetAge,
          'age',
          'years',
          0
        );
        longText += WithCadCalculatorEx.getLongDiffText(
          this.state.tc,
          this.resetTc,
          'total cholesterol',
          'mg/dL',
          1
        );
        longText += WithCadCalculatorEx.getLongDiffText(
          this.state.hdl,
          this.resetHdl,
          'HDL',
          'mg/dL',
          1
        );
        longText += WithCadCalculatorEx.getLongDiffText(
          this.state.ldl,
          this.resetLdl,
          'LDL',
          'mg/dL',
          1
        );
        longText += WithCadCalculatorEx.getLongDiffText(
          this.state.sbp,
          this.resetSbp,
          'SBP',
          'mmHg',
          0
        );
        longText += WithCadCalculatorEx.getLongDiffTextForBinary(
          this.state.bpTreated,
          this.currentBpTreated,
          'treated for high blood pressure'
        );
        longText += WithCadCalculatorEx.getLongDiffTextForBinary(
          this.state.diabetes,
          this.currentDiabetes,
          'diabetes'
        );
        longText += WithCadCalculatorEx.getSmokingStateChange(
          this.state.smoker,
          this.currentSmoker
        );
      }
      return longText;
    }

    static getLongDiffTextForBinary(
      expected: boolean,
      current: boolean,
      trait: string
    ) {
      if (expected === current) return '';
      return `\u2022 changing ${trait} from ${this.getBinaryStateText(
        current
      )} to ${this.getBinaryStateText(expected)}\n`;
    }

    getSbpDiffText() {
      if (this.state.sbp === this.resetSbp) return 'current';
      const diff: number = this.state.sbp - this.resetSbp;
      const sign: string = diff > 0 ? '+' : '-';
      return `${sign} ${Math.abs(diff).toFixed(1)} mmHg`;
    }

    getAgeDiffText() {
      if (!this.state.age) return 'current';
      if (this.state.age > this.resetAge)
        return `+ ${this.state.age - this.resetAge} years`;
      return 'current';
    }

    getTcDiffText() {
      if (this.state.tc === this.resetTc) return 'current';
      const diff: number = this.state.tc - this.resetTc;
      const sign: string = diff > 0 ? '+' : '-';
      return `${sign} ${Math.abs(diff).toFixed(1)} mg/dL\t`;
    }

    getHdlDiffText() {
      if (this.state.hdl === this.resetHdl) return 'current';
      const diff: number = this.state.hdl - this.resetHdl;
      const sign: string = diff > 0 ? '+' : '-';
      return `${sign} ${Math.abs(diff).toFixed(1)} mg/dL\t`;
    }

    getLdlDiffText() {
      if (this.state.ldl === this.resetLdl) return 'current';
      const diff: number = this.state.ldl - this.resetLdl;
      const sign: string = diff > 0 ? '+' : '-';
      return `${sign} ${Math.abs(diff).toFixed(1)} mg/dL\t`;
    }

    getSliderSbpColor() {
      if (this.state.sbp < 90) return COLORS.RED_STATUS;
      if (this.state.sbp > 140) return COLORS.RED_STATUS;
      if (this.state.sbp > 130) return COLORS.YELLOW_STATUS;
      return COLORS.GREEN_STATUS;
    }

    getSliderTcColor() {
      if (this.state.tc < mmolL2mgdl(5.18)) return COLORS.GREEN_STATUS;
      if (this.state.tc > mmolL2mgdl(6.18)) return COLORS.RED_STATUS;
      return COLORS.YELLOW_STATUS;
    }

    getSliderHdlColor() {
      const lowThresh = this.props.sex ? mmolL2mgdl(1) : mmolL2mgdl(1.3);
      if (this.state.hdl < lowThresh) return COLORS.RED_STATUS;
      return COLORS.GREEN_STATUS;
    }

    getSliderLdlColor() {
      if (this.state.ldl > mmolL2mgdl(4.138)) return COLORS.RED_STATUS;
      return COLORS.GREEN_STATUS;
    }

    updatePlayingEnabled() {
      const ageSliderEnabled = !isNanOrNullOrUndefined(this.currentAge);
      const sbpSliderEnabled = !isNanOrNullOrUndefined(this.currentSbp);
      const tcSliderEnabled = !isNanOrNullOrUndefined(this.currentTc);
      const hdlSliderEnabled = !isNanOrNullOrUndefined(this.currentHdl);
      const ldlSliderEnabled = !isNanOrNullOrUndefined(this.currentLdl);
      const playingEnabled =
        ageSliderEnabled && !!this.state.gender && ageSliderEnabled;
      this.setState({
        playingEnabled,
        ageSliderEnabled,
        tcSliderEnabled,
        hdlSliderEnabled,
        ldlSliderEnabled,
        sbpSliderEnabled
      });
    }

    recentDiffPercentile: ?number;
    recentRelativeRisk: number;
    recentRelativeOddsRatio: number;
    currentAge: ?number;
    currentSbp: ?number;
    currentDbp: ?number;
    currentTc: ?number;
    currentHdl: ?number;
    currentLdl: ?number;
    resetAge: number;
    resetSbp: number;
    resetDbp: number;
    resetTc: number;
    resetHdl: number;
    resetLdl: number;
    currentBpTreated: boolean;
    currentDiabetes: boolean;
    currentSmoker: boolean;
    minAge: number;

    calcInitialOverallRiskPercentile(sex: number) {
      const current = this.runLogReg(
        sex,
        this.resetAge,
        this.resetSbp,
        // mgdl2mmolL(this.resetTc),
        mgdl2mmolL(this.resetLdl),
        mgdl2mmolL(this.resetHdl),
        this.currentDiabetes ? 1 : 0,
        this.currentSmoker ? 1 : 0,
        this.currentBpTreated ? 1 : 0,
        this.props.cadScore
      );
      return current.percentile;
    }

    calcExpectedCadRiskChange() {
      const current = this.runLogReg(
        this.state.sex,
        this.resetAge,
        this.resetSbp,
        // mgdl2mmolL(this.resetTc),
        mgdl2mmolL(this.resetLdl),
        mgdl2mmolL(this.resetHdl),
        this.currentDiabetes ? 1 : 0,
        this.currentSmoker ? 1 : 0,
        this.currentBpTreated ? 1 : 0,
        this.props.cadScore
      );
      const expected = this.state.age
        ? this.runLogReg(
            this.state.sex,
            this.state.age,
            this.state.sbp,
            // mgdl2mmolL(this.state.tc),
            mgdl2mmolL(this.state.ldl),
            mgdl2mmolL(this.state.hdl),
            this.state.diabetes ? 1 : 0,
            this.state.smoker ? 1 : 0,
            this.state.bpTreated ? 1 : 0,
            this.props.cadScore
          )
        : undefined;
      return {
        relative: this.getRelativeRiskChangeDetails(
          expected ? expected.odds : undefined,
          current ? current.odds : undefined
        ),
        overall: this.getOverallRiskChangeDetails(
          expected ? expected.percentile : undefined,
          current ? current.percentile : undefined
        )
      };
    }

    standardize(val: number, colName: string) {
      const standardization = this.props.cadLogReg.col_standardization[colName];
      return (val - standardization.mean) / standardization.stddev;
    }

    runLogReg(
      sex: number,
      age: number,
      sbp: number,
      // tc: number,
      ldl: number,
      hdl: number,
      diabetes: number,
      smoker: number,
      bpTreated: number,
      cadScore: number = this.props.cadScore,
      stdizedCadScore: ?number = this.props.stdizedCadScore,
    ) {
      let stdizedPrs: number = 0;
      if (stdizedCadScore === null || stdizedCadScore === undefined) {
        stdizedPrs = this.standardize(cadScore, 'overall_risk_cad');
      } else {
        stdizedPrs = stdizedCadScore;
      }
      const consumesAlcohol = this.props.alcohol ? 1 : 0;
      const statinsTreated = this.props.statinsTreated ? 1 : 0;

      let s: number =
        sex * this.props.cadLogReg.coefficients.sex +
        this.standardize(age, 'age') * this.props.cadLogReg.coefficients.age +
        this.standardize(sbp, 'sbp') * this.props.cadLogReg.coefficients.sbp +
        // this.standardize(tc, 'tc') * this.props.cadLogReg.coefficients.tc +
        this.standardize(ldl, 'ldl_direct') * this.props.cadLogReg.coefficients.ldl_direct +
        this.standardize(hdl, 'hdl') * this.props.cadLogReg.coefficients.hdl +

        this.standardize(this.props.apolipoproteinB, 'apolipoprotein_b') * this.props.cadLogReg.coefficients.apolipoprotein_b +
        this.standardize(this.props.whiteBloodCellLeukocyteCount, 'white_blood_cell_leukocyte_count') * this.props.cadLogReg.coefficients.white_blood_cell_leukocyte_count +
        this.standardize(this.props.alkalinePhosphatase, 'alkaline_phosphatase') * this.props.cadLogReg.coefficients.alkaline_phosphatase +
        this.standardize(this.props.cholesterol, 'cholesterol') * this.props.cadLogReg.coefficients.cholesterol +
        this.standardize(this.props.creatinine, 'creatinine') * this.props.cadLogReg.coefficients.creatinine +
        this.standardize(this.props.cystatinC, 'cystatin_c') * this.props.cadLogReg.coefficients.cystatin_c +
        this.standardize(this.props.gammaGlutamyltransferase, 'gamma_glutamyltransferase') * this.props.cadLogReg.coefficients.gamma_glutamyltransferase +
        this.standardize(this.props.glucose, 'glucose') * this.props.cadLogReg.coefficients.glucose +
        this.standardize(this.props.glycatedHaemoglobinHba1c, 'glycated_haemoglobin_hba1c') * this.props.cadLogReg.coefficients.glycated_haemoglobin_hba1c +
        this.standardize(this.props.phosphate, 'phosphate') * this.props.cadLogReg.coefficients.phosphate +
        this.standardize(this.props.shbg, 'shbg') * this.props.cadLogReg.coefficients.shbg +
        this.standardize(this.props.totalBilirubin, 'total_bilirubin') * this.props.cadLogReg.coefficients.total_bilirubin +
        this.standardize(this.props.testosterone, 'testosterone') * this.props.cadLogReg.coefficients.testosterone +
        this.standardize(this.props.totalProtein, 'total_protein') * this.props.cadLogReg.coefficients.total_protein +
        this.standardize(this.props.urate, 'urate') * this.props.cadLogReg.coefficients.urate +
        this.standardize(this.props.vitaminD, 'vitamin_d') * this.props.cadLogReg.coefficients.vitamin_d +
        this.standardize(this.props.lipoproteinA, 'lipoprotein_a') * this.props.cadLogReg.coefficients.lipoprotein_a +

        stdizedPrs * this.props.cadLogReg.coefficients.overall_risk_cad +
        diabetes * this.props.cadLogReg.coefficients.diabetes +
        smoker * this.props.cadLogReg.coefficients.smoking +
        consumesAlcohol * this.props.cadLogReg.coefficients.alcohol +
        bpTreated * this.props.cadLogReg.coefficients.got_htn +
        statinsTreated * this.props.cadLogReg.coefficients.got_statins +
        this.props.cadLogReg.constant;

      for (let i = 0; i < this.props.pcs.length; i += 1) {
        s +=
          this.standardize(this.props.pcs[i], `pc${i + 1}`) *
          this.props.cadLogReg.coefficients[`pc${i + 1}`];
      }

      let percentile = null;
      if ('percentiles' in this.props.cadLogReg) {
        percentile = getQuantile(s, this.props.cadLogReg.percentiles);
      }
      console.log(
        `CAD: s id ${s}, std_prs is ${stdizedPrs}, percentile is ${percentile ||
          ''}`
      );
      const expS = Math.exp(s);
      const probability = expS / (1 + expS);
      return {
        odds: probability / (1 - probability),
        percentile
      };
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          onGenderSelected={this.onGenderSelected}
          onCurrentAgeChange={this.onCurrentAgeChange}
          onAgeSliderChange={this.onAgeSliderChange}
          getAgeDiffText={this.getAgeDiffText}
          onCurrentTcChange={this.onCurrentTcChange}
          onTcSliderChange={this.onTcSliderChange}
          getTcDiffText={this.getTcDiffText}
          getSliderTcColor={this.getSliderTcColor}
          onCurrentHdlChange={this.onCurrentHdlChange}
          onHdlSliderChange={this.onHdlSliderChange}
          getHdlDiffText={this.getHdlDiffText}
          getSliderHdlColor={this.getSliderHdlColor}
          onCurrentLdlChange={this.onCurrentLdlChange}
          onLdlSliderChange={this.onLdlSliderChange}
          getLdlDiffText={this.getLdlDiffText}
          getSliderLdlColor={this.getSliderLdlColor}
          onCurrentSbpChange={this.onCurrentSbpChange}
          onSbpSliderChange={this.onSbpSliderChange}
          getSbpDiffText={this.getSbpDiffText}
          getSliderSbpColor={this.getSliderSbpColor}
          onCurrentDbpChange={this.onCurrentDbpChange}
          onCurrentBpTreatedChange={this.onCurrentBpTreatedChange}
          onCurrentSmokerChange={this.onCurrentSmokerChange}
          onCurrentDiabetesChange={this.onCurrentDiabetesChange}
          onBpTreatedSwitchChange={this.onBpTreatedSwitchChange}
          onDiabetesSwitchChange={this.onDiabetesSwitchChange}
          onSmokerSwitchChange={this.onSmokerSwitchChange}
          onResetAttributes={this.onResetAttributes}
          getSwitchColor={WithCadCalculatorEx.getSwitchColor}
          getSwitchText={WithCadCalculatorEx.getSwitchText}
          age={this.state.age}
          tc={this.state.tc}
          hdl={this.state.hdl}
          ldl={this.state.ldl}
          sbp={this.state.sbp}
          dbp={this.state.dbp}
          gender={this.state.gender}
          currentAge={this.currentAge}
          currentTc={this.currentTc}
          currentHdl={this.currentHdl}
          currentLdl={this.currentLdl}
          currentSbp={this.currentSbp}
          currentDbp={this.currentDbp}
          resetAge={this.resetAge}
          resetTc={this.resetTc}
          resetHdl={this.resetHdl}
          resetLdl={this.resetLdl}
          resetSbp={this.resetSbp}
          resetDbp={this.resetDbp}
          relativeRisk={this.recentRelativeRisk}
          relativeRiskOddsRatio={this.recentRelativeOddsRatio}
          current={this.state.current}
          expected={this.state.expected}
          shortCadText={this.state.shortCadText}
          longCadText={this.state.longCadText}
          summaryChangeLine={this.state.summaryChangeLine}
          cadTrendIcon={this.state.cadTrendIcon}
          changeColor={this.state.changeColor}
          currentPercentile={this.state.currentPercentile}
          expectedPercentile={this.state.expectedPercentile}
          shortCadTextPercentile={this.state.shortCadTextPercentile}
          longCadTextPercentile={this.state.longCadTextPercentile}
          summaryChangeLinePercentile={this.state.summaryChangeLinePercentile}
          cadTrendIconPercentile={this.state.cadTrendIconPercentile}
          changeColorPercentile={this.state.changeColorPercentile}
          cadQuintile={this.props.cadQuintile}
          cadPercentile={this.props.cadPercentile}
          diabetes={this.state.diabetes}
          smoker={this.state.smoker}
          bpTreated={this.state.bpTreated}
          playingEnabled={this.state.playingEnabled}
          ageSliderEnabled={this.state.ageSliderEnabled}
          sbpSliderEnabled={this.state.sbpSliderEnabled}
          tcSliderEnabled={this.state.tcSliderEnabled}
          hdlSliderEnabled={this.state.hdlSliderEnabled}
          ldlSliderEnabled={this.state.ldlSliderEnabled}
          orderLabs={this.props.orderLabs}
          apolipoproteinB={this.props.apolipoproteinB}
          alcohol={this.props.alcohol}
          statinsTreated={this.props.statinsTreated}
          whiteBloodCellLeukocyteCount={this.props.whiteBloodCellLeukocyteCount}
          alkalinePhosphatase={this.props.alkalinePhosphatase}
          cholesterol={this.props.cholesterol}
          creatinine={this.props.creatinine}
          cystatinC={this.props.cystatinC}
          gammaGlutamyltransferase={this.props.gammaGlutamyltransferase}
          glucose={this.props.glucose}
          glycatedHaemoglobinHba1c={this.props.glycatedHaemoglobinHba1c}
          phosphate={this.props.phosphate}
          shbg={this.props.shbg}
          totalBilirubin={this.props.totalBilirubin}
          testosterone={this.props.testosterone}
          totalProtein={this.props.totalProtein}
          urate={this.props.urate}
          vitaminD={this.props.vitaminD}
          lipoproteinA={this.props.lipoproteinA}
          cadLogReg={this.props.cadLogReg}
        />
      );
    }
  };
}
