// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Text, Spacer, Flex } from '@chakra-ui/react';
import AbsRiskTrendChart from '../../common/AbsRiskTrendChart';
import { getComparativeRiskText } from './report-common';
import RiskVsAverageChartImpl from '../../common/RiskVsAverageChart';
import { calcAbsRisk, calcAvgPrevalence } from '../../utils/absolute_risk';
import { getRiskColorByPercentile } from '../risk-colors';

type Props = {
  percentile: number,
  deciles: Array<number>,
  oddsRatios: Array<number>,
  minY: number,
  maxY: number,
  yTickInterval: number,
  shortTrait: string,
  longTrait: string,
  decimalPoints: number
};

export default function MobileRiskChartsSection(props: Props) {
  const numOrQuantiles = props.oddsRatios.length;
  const quantile = Math.floor(props.percentile / (100 / numOrQuantiles));
  const prevalence = calcAvgPrevalence(props.deciles);
  const absRisk = calcAbsRisk(props.oddsRatios[quantile], prevalence) * 100;
  const avgRisk =
    calcAbsRisk(props.oddsRatios[Math.floor(numOrQuantiles / 2)], prevalence) *
    100;
  const compText = getComparativeRiskText(absRisk / avgRisk);
  const riskColor = getRiskColorByPercentile(props.percentile);
  const height = window.screen.width / 2;
  return (
    <Box>
      <Box mx="1%">
        <AbsRiskTrendChart
          title={`${props.shortTrait} risk in population`}
          xTitle="percentiles of population by genetic risk"
          yTitle={`${props.shortTrait} Risk (%)`}
          annotationName={`Your ${props.shortTrait} Risk`}
          annotationX={props.percentile}
          annotationColor={riskColor}
          seriesData={props.deciles}
          seriesName={`${props.shortTrait} risk`}
          oddsRatios={props.oddsRatios}
          minY={props.minY}
          maxY={props.maxY}
          yTickInterval={props.yTickInterval}
          height={height}
          isMobile
          tooltipDecimalPoints={props.decimalPoints}
        />
      </Box>
      <Flex mt="10px">
        <Spacer />
        <RiskVsAverageChartImpl
          yTitle={`${props.shortTrait} risk (%)`}
          title="Your risk vs average risk"
          risk={absRisk}
          riskColor={riskColor}
          avgRisk={avgRisk}
          seriesName={props.shortTrait}
          height={height}
          minY={props.minY}
          maxY={props.maxY}
          yTickInterval={props.yTickInterval}
        />
        <Spacer />
      </Flex>
      <Text mt="10px" mx="5%">
        Your genetic risk to develop {props.longTrait} is <b>{compText}</b>.
      </Text>
    </Box>
  );
}

MobileRiskChartsSection.propTypes = {
  decimalPoints: PropTypes.number
};

MobileRiskChartsSection.defaultProps = {
  decimalPoints: 1
};
