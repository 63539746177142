// @flow
import {API_URLS, SERVICES} from "../config/url_config";
import requests from "../utils/request";

export function sendSmsToUser(username: string, message: string): Promise<*> {
  const path = `${API_URLS.USERS.SMS}`;
  const body = {
		username: username,
		message: message
	}
  return requests.post(SERVICES.USERS, {}, path, {}, body);
}


export function forceChangePassword(username: string): Promise<*> {
  const path = `${API_URLS.USERS.CHANGE_PWD}`;
  const body = {
		username: username,
	}
  return requests.post(SERVICES.USERS, {}, path, {}, body);
}
