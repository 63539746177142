// @flow
import {AWS_REGIONS} from "./env_config";

const COGNITO_CONFIG = {
  dev: {
    // dev pool with optional MFA
    USERPOOL_ID: 'us-east-1_XJZVpot1K',
    IDENTITYPOOL_ID: 'us-east-1:99d016f3-d0fc-49f1-afe0-bf919fed8090',
    REGION: AWS_REGIONS["dev"],
    APPCLIENT_ID: '6h08716jegu1rugumk2vpo38u4'
  },
  // devMandatoryMfa: {
  //   // dev pool with optional MFA
  //   USERPOOL_ID: 'us-east-1_Sz4HwDqaa',
  //   IDENTITYPOOL_ID: 'us-east-1:83332e10-bd0e-42d1-890b-0f89891f6d71',
  //   REGION: AWS_REGIONS["dev"],
  //   APPCLIENT_ID: '7gomle45il5mt9dp1vul4sfavu'
  // },
  prod: {
    USERPOOL_ID: 'us-east-1_YuyDN3IX8',
    IDENTITYPOOL_ID: 'us-east-1:0204bb0b-de32-4384-8361-f96a5f3b333b',
    REGION: AWS_REGIONS["prod"],
    APPCLIENT_ID: '6uuibjk5407cdpa7mif5uf5fu2'
  }
  // prodMandatoryMfa: {
  //   USERPOOL_ID: 'us-east-1_1GnWXTJ2g',
  //   IDENTITYPOOL_ID: 'us-east-1:357ce662-68f4-4e8c-b0f8-d392fbcc9adf',
  //   REGION: AWS_REGIONS["prod"],
  //   APPCLIENT_ID: '42vqkg0mp3qvrdmrjol99jk3ka'
  // }
};

export default COGNITO_CONFIG;
