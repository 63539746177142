// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { Stack, Text, Flex, Spacer } from '@chakra-ui/react';
import COLORS from './colors';

type Props = {
  title: string,
  value: ?string | number | null,
  labelWidth: string,
  textWidth: string,
  align: string,
  marginBottom?: string,
  textWeight?: string,
  labelColor?: string,
  textColor?: string,
  alignStack?: string,
  useMaxTextWidth?: boolean
};

export default function LabeledTextImpl(props: Props) {
  const minW = props.useMaxTextWidth ? undefined : props.textWidth;
  const maxW = props.useMaxTextWidth ? props.textWidth : undefined;
  return (
    <Stack
      mb={props.marginBottom}
      spacing="10px"
      align={props.alignStack}
      direction={"row"}
      mr="10px"
    >
      <Text minW={props.labelWidth} color={props.labelColor}>
        {`${props.title}:`}
      </Text>
      <Text
        minW={minW}
        maxW={maxW}
        align={props.align}
        fontWeight={props.textWeight}
        color={props.textColor}
      >
        {props.value}
      </Text>
    </Stack>
  );
}

LabeledTextImpl.propTypes = {
  marginBottom: PropTypes.string,
  textWeight: PropTypes.string,
  labelColor: PropTypes.string,
  textColor: PropTypes.string,
  alignStack: PropTypes.string,
  useMaxTextWidth: PropTypes.bool
};

LabeledTextImpl.defaultProps = {
  marginBottom: '10px',
  textWeight: 'normal',
  labelColor: COLORS.REPORT_TEXT,
  textColor: COLORS.REPORT_TEXT,
  alignStack: 'center',
  useMaxTextWidth: false
};

export function LabeledTextWithSpacer(props: Props) {
  const minW = props.useMaxTextWidth ? undefined : props.textWidth;
  const maxW = props.useMaxTextWidth ? props.textWidth : undefined;
  return (
    <Flex mb={props.marginBottom} align={props.alignStack}>
      <Text
        minW={props.labelWidth}
        color={props.labelColor}
      >{`${props.title}:`}</Text>
      <Spacer />
      <Text
        minW={minW}
        maxW={maxW}
        align={props.align}
        fontWeight={props.textWeight}
        color={props.textColor}
      >
        {props.value}
      </Text>
    </Flex>
  );
}

LabeledTextWithSpacer.propTypes = {
  marginBottom: PropTypes.string,
  textWeight: PropTypes.string,
  labelColor: PropTypes.string,
  textColor: PropTypes.string,
  alignStack: PropTypes.string,
  useMaxTextWidth: PropTypes.bool
};

LabeledTextWithSpacer.defaultProps = {
  marginBottom: '10px',
  textWeight: 'normal',
  labelColor: COLORS.REPORT_TEXT,
  textColor: COLORS.REPORT_TEXT,
  alignStack: 'center',
  useMaxTextWidth: false
};
