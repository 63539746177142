// @flow
import COLORS, {CARDIO_COLORS} from '../common/colors';

export default class ColoredSectionCriteria {
  constructor(
    thresh: number,
    isAbove: boolean,
    matchCriteriaColor: string,
    failedCriteriaColor: string
  ) {
    this.thresh = thresh;
    this.isAbove = isAbove;
    this.matchCriteriaColor = matchCriteriaColor;
    this.failedCriteriaColor = failedCriteriaColor;
  }

  matchCriteria(value: number) {
    return this.isAbove ? value > this.thresh : value < this.thresh;
  }

  getBelowThreshColor() {
    return this.isAbove ? this.failedCriteriaColor : this.matchCriteriaColor;
  }

  getAboveThreshColor() {
    return this.isAbove ? this.matchCriteriaColor : this.failedCriteriaColor;
  }

  getSeriesColor() {
    return this.failedCriteriaColor;
  }

  thresh: number;
  isAbove: boolean;
  matchCriteriaColor: string;
  failedCriteriaColor: string;
}

export class AlwaysOkCriteria {
  // eslint-disable-next-line no-unused-vars,class-methods-use-this
  matchCriteria(value: number) {
    return false;
  }
}

export const a1cCriteria = new ColoredSectionCriteria(
  48,
  true,
  COLORS.RED_STATUS,
  COLORS.HIGHCHARTS_1ST_SERIES
);
export const a1cCriteriaPercent = new ColoredSectionCriteria(
  7,
  true,
  COLORS.RED_STATUS,
  COLORS.HIGHCHARTS_1ST_SERIES
);
export const glucoseCriteria = new ColoredSectionCriteria(
  6.9,
  true,
  COLORS.RED_STATUS,
  COLORS.HIGHCHARTS_1ST_SERIES
);

export const hdlCriteria = new ColoredSectionCriteria(
  1.0,
  false,
  COLORS.RED_STATUS,
  COLORS.HIGHCHARTS_1ST_SERIES
);
export const ldlCriteria = new ColoredSectionCriteria(
  5,
  true,
  COLORS.RED_STATUS,
  COLORS.HIGHCHARTS_2ND_SERIES
);
export const hdlMldlCriteria = new ColoredSectionCriteria(
  39,
  false,
  COLORS.RED_STATUS,
  COLORS.HIGHCHARTS_1ST_SERIES
);
export const ldlMldlCriteria = new ColoredSectionCriteria(
  160,
  true,
  COLORS.RED_STATUS,
  COLORS.HIGHCHARTS_2ND_SERIES
);
export const resistanceCriteria = new ColoredSectionCriteria(
  4,
  true,
  COLORS.RED_STATUS,
  COLORS.HIGHCHARTS_1ST_SERIES
);

export const bmiCriteria = new ColoredSectionCriteria(
  29.9,
  true,
  COLORS.RED_STATUS,
  COLORS.HIGHCHARTS_1ST_SERIES
);

export const triglyceridesCriteria = new ColoredSectionCriteria(
  2.3,
  true,
  COLORS.RED_STATUS,
  COLORS.HIGHCHARTS_1ST_SERIES
);

export const sbpCriteria = new ColoredSectionCriteria(
  140,
  true,
  COLORS.RED_STATUS,
  COLORS.HIGHCHARTS_1ST_SERIES
);

export const dbpCriteria = new ColoredSectionCriteria(
  90,
  true,
  COLORS.ORANGE_STATUS,
  COLORS.HIGHCHARTS_2ND_SERIES
);

export const overallRiskCriteria = new ColoredSectionCriteria(
  80,
  true,
  COLORS.RED_STATUS,
  COLORS.HIGHCHARTS_2ND_SERIES
);

export const cadOverallRiskCriteria = new ColoredSectionCriteria(
  80,
  true,
  COLORS.RED_STATUS,
  CARDIO_COLORS.CAD
);

export const t2dOverallRiskCriteria = new ColoredSectionCriteria(
  80,
  true,
  COLORS.RED_STATUS,
  CARDIO_COLORS.T2D
);

export const htnOverallRiskCriteria = new ColoredSectionCriteria(
  80,
  true,
  COLORS.RED_STATUS,
  CARDIO_COLORS.HTN
);

export const cholOverallRiskCriteria = new ColoredSectionCriteria(
  80,
  true,
  COLORS.RED_STATUS,
  CARDIO_COLORS.CHOL
);

export const alwaysOkCriteria = new AlwaysOkCriteria();
