// @flow
import React from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  AlertIcon,
  Box,
  Flex,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Stack,
  Text,
  Spacer,
  Divider
} from '@chakra-ui/react';
import LabeledSwitch from '../common/LabeledSwitch';
import {roundWithPrecision, undefinedForNan} from '../utils/numbers';
import ActionButton from '../common/ActionButton';
import traitRanges from '../common/reports/trait-ranges.json';
import {AiOutlineUnorderedList} from "react-icons/ai";
import {consumerUserAttributesFlags, consumerMandatoryUserAttributes} from "../common/reports/consumer-report-common";
import withNavigation from "../main/WithRoutes";
import COLORS from "../common/colors";
import {cmToFeetInch, feetInchToCm, kgToPounds, poundsToKg} from "../utils/unit_translation";
import {isNullOrUndefined} from "url/util";
import {getPatientId} from "../capilots/capilots-utils";
import {getPatient, unionPatientVisit, updatePatientAttributes, addVisit, updatePatientConsent} from "../api/capilots";
import routes from "../main/routes";
import calcBmi from "../utils/bmi";
import Consent from "./Consent";
import withMobile from "../common/mobile/withMobile";

type Props = {
  navigate: any,
  params: any,
  location: any,
  fontSize?: number,
  onApply: any,
  showAttributeFlags: number,
  mustHaveUserAttributes?: number,
  labelPrefix?: string,
  isMobile: boolean
};

type State = {
  error: ?string,
  isApplyEnabled: boolean,
  gender: ?string,
  birthYear: ?number,
  birthMonth: ?number,
  weight: ?number,
  feet: ?number,
  inch: ?number,
  smoker: boolean,
  hypertensive: boolean,
  hypercholesterol: boolean,
  exercise: boolean,
  diabetes: boolean,
  fhDiabetes: boolean,
  loading: boolean,
  visit: Object,
  consent: boolean
};

class ConsumerPatientAttributes extends React.Component<
  Props,
  State
> {
  static getSwitchColor() {
    return 'blue';
  }

  static getSwitchText(isChecked: boolean) {
    return isChecked ? 'YES' : 'NO';
  }

  static isInRange(val: ?number, lowLimit: number, highLimit: number) {
    if (val === undefined || val === null || Number.isNaN(val)) return false;
    const numVal: number = val;
    return numVal >= lowLimit && numVal <= highLimit;
  }

  static onFloatValChange(
    val: number,
    lowLimit: number,
    highLimit: number,
    forceLimits: boolean = true
  ) {
    let newVal = parseFloat(val);
    if (forceLimits) {
      if (newVal < lowLimit) newVal = lowLimit;
      if (newVal > highLimit) newVal = highLimit;
    }
    return newVal;
  }

  static onIntValChange(
    val: number,
    lowLimit: number,
    highLimit: number,
    forceLimits: boolean = true
  ) {
    let newVal = parseInt(val, 10);
    if (forceLimits) {
      if (newVal < lowLimit) newVal = lowLimit;
      if (newVal > highLimit) newVal = highLimit;
    }
    return newVal;
  }

  constructor(props: Props) {
    super(props);
    (this: any).onApply = this.onApply.bind(this);
    (this: any).onGenderSelected = this.onGenderSelected.bind(this);
    (this: any).onBirthYearChange = this.onBirthYearChange.bind(this);
    (this: any).onBirthMonthChange = this.onBirthMonthChange.bind(this);
    (this: any).onWeightChange = this.onWeightChange.bind(this);
    (this: any).onFeetChange = this.onFeetChange.bind(this);
    (this: any).onInchChange = this.onInchChange.bind(this);
    (this: any).onHypertensiveSwitchChange = this.onHypertensiveSwitchChange.bind(
      this
    );
    (this: any).onHypercholesterolSwitchChange = this.onHypercholesterolSwitchChange.bind(
      this
    );
    (this: any).onDiabetesSwitchChange = this.onDiabetesSwitchChange.bind(this);
    (this: any).onFhDiabetesSwitchChange = this.onFhDiabetesSwitchChange.bind(
      this
    );
    (this: any).onSmokerSwitchChange = this.onSmokerSwitchChange.bind(this);
    (this: any).onExerciseSwitchChange = this.onExerciseSwitchChange.bind(this);
    (this: any).getError = this.getError.bind(this);
    (this: any).getRangeAttributeError = this.getRangeAttributeError.bind(this);

    (this: any).updateError = this.updateError.bind(this);
    (this: any).onConsentAgreed = this.onConsentAgreed.bind(this);

    this.state = {
      gender: undefined,
      birthYear: undefined,
      birthMonth: undefined,
      weight: undefined,
      feet: undefined,
      inch: undefined,
      smoker: false,
      hypertensive: false,
      hypercholesterol: false,
      exercise: false,
      diabetes: false,
      fhDiabetes: false,
      error: undefined,
      isApplyEnabled: false,
      loading: true,
      visit: undefined,
      consent: false
    };
  }

  async componentDidMount() {
    this.patientId = getPatientId(this.props.params, this.props.location);
    const unionVisit = await unionPatientVisit(this.patientId);
    const patient = await getPatient(this.patientId);
    const visit = {
        ...patient.data,
        ...unionVisit.data.visit,
    }
    const gender = this.getGenderFromSex(visit.sex);
    const feetInch = isNullOrUndefined(visit.height) ? {feet: undefined, inch: undefined} : cmToFeetInch(visit.height);
    this.setState({
      gender: gender,
      birthYear: visit.birth_year,
      birthMonth: visit.birth_month,
      weight: kgToPounds(visit.weight),
      feet: feetInch.feet,
      inch: feetInch.inch,
      smoker: visit.smoker,
      hypertensive: visit.hypertension,
      hypercholesterol: visit.hypercholesterol,
      exercise: visit.exercise,
      diabetes: visit.diabetes,
      fhDiabetes: visit.fh_diabetes,
      loading: false,
      consent: visit.consent,
      visit: visit
    }, () => this.updateError());
  }

  getGenderFromSex(sex) {
    let gender = undefined;
    if (!isNullOrUndefined(sex)) gender = sex ? "Male" : "Female";
    return gender;
  }

  patientId: string;

  onGenderSelected(event: any) {
    this.setState({
          gender: event.target.value
        }, () => this.updateError()
    )
  }

  onBirthYearChange(val: number) {
    const birthYear = ConsumerPatientAttributes.onFloatValChange(
      val,
      traitRanges.birthYear.lowLimit,
      new Date().getFullYear()
    );
    this.setState({
          birthYear: birthYear
        }, () => this.updateError()
    )
  }

  onBirthMonthChange(val: number) {
    const birthMonth = ConsumerPatientAttributes.onFloatValChange(
      val,
      traitRanges.birthMonth.lowLimit,
      traitRanges.birthMonth.highLimit
    );
    this.setState({
          birthMonth: birthMonth
        }, () => this.updateError()
    )
  }

  onWeightChange(val: number) {
    const weight = ConsumerPatientAttributes.onFloatValChange(
      val,
      traitRanges.weight.lowLimit,
      traitRanges.weight.highLimit
    );
    this.setState({
          weight: weight
        }, () => this.updateError()
    )
  }

  onFeetChange(val: number) {
    const feet = ConsumerPatientAttributes.onFloatValChange(
      val,
      traitRanges.feet.lowLimit,
      traitRanges.feet.highLimit
    );
    this.setState({
          feet: feet
        }, () => this.updateError()
    )
  }
  onInchChange(val: number) {
    const inch = ConsumerPatientAttributes.onFloatValChange(
      val,
      traitRanges.inch.lowLimit,
      traitRanges.inch.highLimit
    );
    this.setState({
          inch: inch
        }, () => this.updateError()
    )
  }

  onHypertensiveSwitchChange(val: boolean) {
    this.setState({
          hypertensive: val
        }, () => this.updateError()
    )
  }
  onHypercholesterolSwitchChange(val: boolean) {
    this.setState({
          hypercholesterol: val
        }, () => this.updateError()
    )
  }
  onExerciseSwitchChange(val: boolean) {
    this.setState({
          exercise: val
        }, () => this.updateError()
    )
  }
  onDiabetesSwitchChange(val: boolean) {
    this.setState({
          diabetes: val
        }, () => this.updateError()
    )
  }
  onFhDiabetesSwitchChange(val: boolean) {
    this.setState({
          fhDiabetes: val
        }, () => this.updateError()
    )
  }
  onSmokerSwitchChange(val: boolean) {
    this.setState({
          smoker: val
        }, () => this.updateError()
    )
  }

  async checkAndUpdatePatient() {
    const heightCm = feetInchToCm(this.state.feet, this.state.inch);
    let needPatientRecordUpdate = false;
    if (this.state.gender !== this.getGenderFromSex(this.state.visit.sex)) needPatientRecordUpdate = true;
    if (this.state.birthYear !== this.state.visit.birth_year) needPatientRecordUpdate = true;
    if (this.state.birthMonth !== this.state.visit.birth_month) needPatientRecordUpdate = true;
    if (heightCm !== this.state.visit.height) needPatientRecordUpdate = true;
    if (needPatientRecordUpdate) {
      await updatePatientAttributes(
        this.patientId,
        this.state.gender === 'Male',
        this.state.birthYear,
        this.state.birthMonth,
        heightCm,
      )
    }

    let needPatientVisitUpdate = false;
    const weightKg = poundsToKg(this.state.weight);
    if (weightKg !== this.state.visit.weight) needPatientVisitUpdate = true;
    if (this.state.smoker !== this.state.visit.smoker ||
        this.state.hypertensive !== this.state.visit.hypertention ||
        this.state.hypercholesterol !== this.state.visit.hypercholesterol ||
        this.state.diabetes !== this.state.visit.diabetes ||
        this.state.diabetes !== this.state.visit.diabetes ||
        this.state.exercise !== this.state.visit.exercise) {
          needPatientVisitUpdate = true;
      }
    if (needPatientVisitUpdate) {
      await addVisit(
        this.patientId,
        weightKg,
        null,
        null,
        null,
        null,
        null,
        calcBmi(weightKg, heightCm / 100),
        null,
        this.state.diabetes,
        this.state.fhDiabetes,
        null,
        this.state.smoker,
        this.state.hypertensive,
        this.state.hypercholesterol,
        this.state.exercise
      )
    }
  }

  async onApply() {
    // update DB
    await this.checkAndUpdatePatient();

    // switch to cardio page view
    const url = `${routes.APP}/self/${this.patientId}/risks`;
    this.props.navigate(url);
  }
  getRangeAttributeError(
    val: ?number,
    lowLimit: number,
    highLimit: number,
    name: string
  ) {
    const labelPrefix = this.props.labelPrefix
      ? this.props.labelPrefix.toLowerCase()
      : '';
    if (val === undefined || val === null || Number.isNaN(val))
      return `Fill in your ${labelPrefix}${name}`;
    const numVal: number = val;
    if (numVal < lowLimit || numVal > highLimit) {
      return `Allowed range for ${name} is ${lowLimit} - ${highLimit}`;
    }
    return null;
  }

  getError() {
    if (!this.state.gender) return 'Select gender';
    if (!this.state.birthYear) return 'Fill in your birth year';
    if (!this.state.birthMonth) return 'Fill in your birth month';
    if (!this.state.feet || !this.state.inch) return 'Fill in your height';
    if (!this.state.weight) return 'Fill in your weight';
    return undefined;
  }

  getValidAttributeFlags() {
    let flags: number = 0;
    if (this.state.gender) {
      // eslint-disable-next-line no-bitwise
      flags |= consumerUserAttributesFlags.gender;
    }
    if (this.state.feet && this.state.inch) {
      // eslint-disable-next-line no-bitwise
      flags |= consumerUserAttributesFlags.height;
    }
    if (this.state.birthYear) {
      // eslint-disable-next-line no-bitwise
      flags |= consumerUserAttributesFlags.birthYear;
    }
    if (this.state.birthMonth) {
      // eslint-disable-next-line no-bitwise
      flags |= consumerUserAttributesFlags.birthMonth;
    }
    if (this.state.weight) {
      // eslint-disable-next-line no-bitwise
      flags |= consumerUserAttributesFlags.weight;
    }
    // eslint-disable-next-line no-bitwise
    flags |= consumerUserAttributesFlags.diabetes;
    // eslint-disable-next-line no-bitwise
    flags |= consumerUserAttributesFlags.hypertensive;
    // eslint-disable-next-line no-bitwise
    flags |= consumerUserAttributesFlags.hypercholesterol;
    // eslint-disable-next-line no-bitwise
    flags |= consumerUserAttributesFlags.exercise;
    // eslint-disable-next-line no-bitwise
    flags |= consumerUserAttributesFlags.fhDiabetes;
    // eslint-disable-next-line no-bitwise
    flags |= consumerUserAttributesFlags.smoker;
    return flags;
  }

  getApplyEnabled() {
    if (!this.state.consent) return false;
    if (!this.props.mustHaveUserAttributes) return true;
    const mustHaveUserAttributes: number = this.props.mustHaveUserAttributes;
    const validAttrFlags = this.getValidAttributeFlags();
    return (validAttrFlags & mustHaveUserAttributes) === mustHaveUserAttributes;
  }

  updateError() {
    const error = this.getError();
    const isApplyEnabled = this.getApplyEnabled();
    this.setState({
      error,
      isApplyEnabled
    });
  }

  showAttribute(flag: number) {
    // eslint-disable-next-line no-bitwise
    return flag & this.props.showAttributeFlags;
  }

  checkValueInRange(value: number, lowLimit: number, highLimit: number) {
    if (Number.isNaN(value) || value < lowLimit || value > highLimit) {
      this.updateError();
      return false;
    }
    return true;
  }

  async onConsentAgreed() {
    await updatePatientConsent(
        this.patientId,
        true
    )
    this.setState({
          consent: true
        }, () => this.updateError()
    );
  }

  render() {
    const labelPrefix = this.props.labelPrefix
      ? this.props.labelPrefix.toLowerCase()
      : '';
    const consentRequired = !this.state.loading && !this.state.consent;
    const attributeView = !this.state.loading && this.state.consent;
    let title = 'LOADING...';
    if (!this.state.loading) {
      title = consentRequired ? 'CONSENT REQUIRED' : 'YOUR ATTRIBUTES';
    }
    const {isMobile} = this.props;

    const binaryFieldsStack = <Stack fontSize={this.props.fontSize} spacing={isMobile ? "10px" : "20px"} ml={isMobile ? 0: "50px"}>
      <LabeledSwitch
        mt="10px"
        isChecked={!!this.state.hypertensive}
        leftLabel="High blood pressure:"
        leftLabelWidth="300px"
        callbackGetColor={
          ConsumerPatientAttributes.getSwitchColor
        }
        callbackOnValueChange={this.onHypertensiveSwitchChange}
        callbackRightLabel={
          ConsumerPatientAttributes.getSwitchText
        }
        enabled
      />
      <LabeledSwitch
        mt="10px"
        isChecked={!!this.state.hypercholesterol}
        leftLabel="High cholesterol:"
        leftLabelWidth="300px"
        callbackGetColor={
          ConsumerPatientAttributes.getSwitchColor
        }
        callbackOnValueChange={this.onHypercholesterolSwitchChange}
        callbackRightLabel={
          ConsumerPatientAttributes.getSwitchText
        }
        enabled
      />
      <LabeledSwitch
        mt="10px"
        isChecked={!!this.state.exercise}
        leftLabel="Exercise physical activity:"
        leftLabelWidth="300px"
        callbackGetColor={
          ConsumerPatientAttributes.getSwitchColor
        }
        callbackOnValueChange={this.onExerciseSwitchChange}
        callbackRightLabel={
          ConsumerPatientAttributes.getSwitchText
        }
        enabled
      />
      <LabeledSwitch
        mt="10px"
        isChecked={!!this.state.diabetes}
        leftLabel="Diabetes:"
        leftLabelWidth="300px"
        callbackGetColor={
          ConsumerPatientAttributes.getSwitchColor
        }
        callbackOnValueChange={this.onDiabetesSwitchChange}
        callbackRightLabel={
          ConsumerPatientAttributes.getSwitchText
        }
        enabled
      />
      <LabeledSwitch
        mt="10px"
        isChecked={!!this.state.fhDiabetes}
        leftLabel="Diabetes family history (1st degree only):"
        leftLabelWidth="300px"
        callbackGetColor={
          ConsumerPatientAttributes.getSwitchColor
        }
        callbackOnValueChange={this.onFhDiabetesSwitchChange}
        callbackRightLabel={
          ConsumerPatientAttributes.getSwitchText
        }
        enabled
      />
      <LabeledSwitch
        mt="10px"
        isChecked={!!this.state.smoker}
        leftLabel="Smoker:"
        leftLabelWidth="300px"
        callbackGetColor={
          ConsumerPatientAttributes.getSwitchColor
        }
        callbackOnValueChange={this.onSmokerSwitchChange}
        callbackRightLabel={
          ConsumerPatientAttributes.getSwitchText
        }
        enabled
      />
    </Stack>;

    return (
      <Box
        my={isMobile ? "20px" : "30px"}
        mx={isMobile ? "2%" : "8%"}
        color={COLORS.REPORT_TEXT}
      >
        <Flex mt="15px" my={isMobile ? "10px" : "20px"} align="center">
          <Text fontSize={24} color="gray.600">
            {title}
          </Text>
          {attributeView &&
            <Box as={AiOutlineUnorderedList} h="24px" w="24px" ml="20px" />
          }
          <Spacer />
        </Flex >
        {attributeView && <Box fontSize={isMobile ? 12 : 16} my={isMobile ? "10px" : "30px"} color={COLORS.REPORT_TEXT_GRAY}>
          <Text>Please take a moment to review and update your attributes.</Text>
          <Text mt={"10px"}>OpenDNA uses these attributes in risk calculations, having the correct values helps providing more accurate risk assessments.</Text>
        </Box>}
        <Divider my={isMobile ? "10px" : "30px"}/>
        {attributeView && (<Flex align={"top"}>
          <Stack fontSize={this.props.fontSize} spacing={isMobile ? "10px" : "10px"} minW={isMobile ? "100%" : "500px"}>
            <Flex align="center" mb={isMobile ? 0 : "10px"}>
              <Text w="170px">Gender:</Text>
              <Select
                placeholder="select gender"
                onChange={this.onGenderSelected}
                defaultValue={this.state.gender}
                w="200px"
              >
                <option key="Female" value="Female">
                  female
                </option>
                <option key="Male" value="Male">
                  male
                </option>
              </Select>
            </Flex>
            <Flex align="center" mb="10px">
              <Text w="170px">{`${labelPrefix}Birth Year:`}</Text>
              <NumberInput
                maxW="90px"
                defaultValue={undefinedForNan(this.state.birthYear)}
                min={traitRanges.birthYear.lowLimit}
                max={new Date().getFullYear()}
                onChange={this.onBirthYearChange}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </Flex>
            <Flex align="center" mb="10px">
              <Text w="170px">{`${labelPrefix}Birth Month:`}</Text>
              <NumberInput
                maxW="90px"
                defaultValue={undefinedForNan(this.state.birthMonth)}
                min={traitRanges.birthMonth.lowLimit}
                max={traitRanges.birthMonth.highLimit}
                onChange={this.onBirthMonthChange}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </Flex>
            <Flex align="center" mb="10px">
              <Text w="170px">{`${labelPrefix}Height:`}</Text>
              <NumberInput
                maxW="70px"
                defaultValue={undefinedForNan(this.state.feet)}
                min={traitRanges.feet.lowLimit}
                max={traitRanges.feet.highLimit}
                onChange={this.onFeetChange}
                step={1}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
              <Text ml="5px" mr="10px">
                feet
              </Text>
              <NumberInput
                maxW="70px"
                defaultValue={undefinedForNan(this.state.inch)}
                min={traitRanges.inch.lowLimit}
                max={traitRanges.inch.highLimit}
                onChange={this.onInchChange}
                step={1}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
              <Text ml="5px">inch</Text>
            </Flex>
            <Flex align="center" mb="10px">
              <Text w="170px">{`${labelPrefix}Weight:`}</Text>
              <NumberInput
                maxW="90px"
                defaultValue={undefinedForNan(
                  roundWithPrecision(this.state.weight, 1)
                )}
                min={traitRanges.weight.lowLimit}
                max={traitRanges.weight.highLimit}
                onChange={this.onWeightChange}
                step={1}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
              <Text ml="10px">pound</Text>
            </Flex>
            {isMobile && <Box>{binaryFieldsStack}</Box>}
          </Stack>
          {!isMobile && <Box>{binaryFieldsStack}</Box>}
        </Flex>)}
        {attributeView && <Box maxW={"930px"}>
          {this.state.error && (
            <Alert
              status={this.state.isApplyEnabled ? 'warning' : 'error'}
              mt="30px"
              borderRadius="10px"
            >
              <AlertIcon />
              {this.state.error}
            </Alert>
          )}
          {!this.state.error && (
            <Alert
              status={'success'}
              mt={isMobile ? "10px" : "30px"}
              borderRadius="10px"
            >
              <AlertIcon />
              All mandatory fields are present, we are ready to prepare your risk analysis.
            </Alert>
          )}
        </Box>}
        {consentRequired && <Box>
          <Consent onConsent={this.onConsentAgreed}/>
        </Box>}
        {attributeView && <Divider my={isMobile ? "20px" : "30px"}/>}
        {attributeView && <Flex mt={isMobile ? "10px" : "70px"}>
          {isMobile && <Spacer/>}
          <ActionButton
            name="View my reports..."
            onClick={this.onApply}
            isDisabled={!this.state.isApplyEnabled}
          />
          <Spacer />
        </Flex>}
      </Box>
    );
  }
}

ConsumerPatientAttributes.propTypes = {
  fontSize: PropTypes.number,
  gender: PropTypes.string,
  age: PropTypes.number,
  bmi: PropTypes.number,
  smoker: PropTypes.bool,
  riskTrait: PropTypes.string,
  riskLevel: PropTypes.string,
  hypertensive: PropTypes.bool,
  hypercholesterol: PropTypes.bool,
  exercise: PropTypes.bool,
  diabetes: PropTypes.bool,
  fhDiabetes: PropTypes.bool,
  labelPrefix: PropTypes.string,
  mustHaveUserAttributes: PropTypes.number
};
// $FlowFixMe[prop-missing]
ConsumerPatientAttributes.defaultProps = {
  fontSize: 14,
  age: undefined,
  bmi: undefined,
  smoker: undefined,
  hypertensive: false,
  hypercholesterol: false,
  exercise: false,
  diabetes: false,
  fhDiabetes: false,
  labelPrefix: '',
  mustHaveUserAttributes: consumerMandatoryUserAttributes,
};

export default withNavigation(withMobile(ConsumerPatientAttributes));
