// @flow
import React from 'react';
import { Box, Link, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import COLORS from '../../common/colors';
import IconTextAndLink from '../../common/IconTextAndLink';
import htnGaugeImage from '../../images/blood-pressure-gauge-light.png';
import dietImage from '../../images/diet.png';
import runningImage from '../../images/running.png';
import noSmokingImage from '../../images/no-smoking.png';
import MedicationsAskDoctorPanel from '../../common/reports/MedicationsAskDoctorPanel';

type Props = {
  isMobile: boolean
};

export default function StrokeRecommendationSection(props: Props) {
  return (
    <Box>
      <Text mt="40px" color={COLORS.REPORT_TEXT} fontWeight="semibold">
        To reduce risk for having a stroke you can refer to the following:
      </Text>
      <Link
        color="teal.500"
        href="https://www.cdc.gov/stroke/prevention.htm"
        isExternal
        mb="20px"
      >
        https://www.cdc.gov/stroke/prevention.htm
      </Link>
      <IconTextAndLink
        imageSrc={dietImage}
        text="Diet"
        linkText="Preserve a heart-healthy diet"
        link="https://www.heart.org/en/healthy-living/healthy-eating/losing-weight"
        isMobile={props.isMobile}
      />
      <IconTextAndLink
        imageSrc={runningImage}
        text="Exercise"
        linkText="Recommendations for physical activity"
        link="https://www.heart.org/en/healthy-living/fitness/fitness-basics/aha-recs-for-physical-activity-in-adults"
        isMobile={props.isMobile}
      />
      <IconTextAndLink
        imageSrc={noSmokingImage}
        text="Smoking cessation"
        linkText="Quit smoking, vaping and tobacco use"
        link="https://www.heart.org/en/healthy-living/healthy-lifestyle/quit-smoking-tobacco"
        isMobile={props.isMobile}
      />
      <IconTextAndLink
        imageSrc={htnGaugeImage}
        text="Monitoring your blood pressure regularly"
        linkText="Monitoring your blood pressure at home"
        link="https://www.heart.org/en/health-topics/high-blood-pressure/understanding-blood-pressure-readings/monitoring-your-blood-pressure-at-home"
        isMobile={props.isMobile}
      />
      <MedicationsAskDoctorPanel
        askText="ask your doctor for cholesterol, blood pressure and glucose management"
        isMobile={props.isMobile}
      />
    </Box>
  );
}

StrokeRecommendationSection.propTypes = {
  isMobile: PropTypes.bool
};

StrokeRecommendationSection.defaultProps = {
  isMobile: false
};
