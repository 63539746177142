// @flow
import traitRanges from './trait-ranges.json';
import { mmolL2mgdl } from '../../utils/cholesterol';
import { mmolmol2percents } from '../../utils/a1c';
import {calcBmiNumeric, getWeightFromBmiAndHeight} from "../../utils/bmi";

export function getDefaultAge(age: ?number) {
  if (!age) return traitRanges.age.default;
  if (age < traitRanges.age.lowLimit) return traitRanges.age.lowLimit;
  if (age > traitRanges.age.highLimit) return traitRanges.age.highLimit;
  return age;
}

export function getDefaultGender(sex: ?number) {
  let gender;
  if (sex === 0) gender = 'female';
  if (sex === 1) gender = 'male';
  return gender;
}

export function getDefaultSbp(sbp: ?number) {
  return sbp || traitRanges.sbp.default;
}

export function getDefaultDbp(dbp: ?number) {
  return dbp || traitRanges.dbp.default;
}

export function getDefaultBmi(bmi: ?number): number {
  if (!bmi) return traitRanges.bmi.default;
  const defaultBmi: number = bmi;
  return defaultBmi;
}

export function getDefaultTc(tc: ?number) {
  return tc ? mmolL2mgdl(tc) : traitRanges.tc.default;
}

export function getDefaultHdl(hdl: ?number) {
  return hdl ? mmolL2mgdl(hdl) : traitRanges.hdl.default;
}

export function getDefaultLdl(ldl: ?number) {
  return ldl ? mmolL2mgdl(ldl) : traitRanges.ldl.default;
}

export function getDefaultA1c(a1c: ?number) {
  return a1c ? mmolmol2percents(a1c) : traitRanges.a1c.default;
}

export function getMinBmi(bmi: ?number) {
  if (!bmi) return 18.5;
  if (bmi < 18.5) return bmi;
  return 18.5;
}

export function getMaxBmi(bmi: ?number) {
  if (!bmi) return 40;
  if (bmi > 40) return traitRanges.bmi.highLimit;
  return 40;
}


export function getMinWeight(weightKg: ?number, heightCm: number, outKg: boolean) {
  let maxWeight = undefined;
  if (!weightKg || heightCm) {
    maxWeight = getWeightFromBmiAndHeight(18.5, heightCm / 100, outKg);
  } else {
    const currentBmi = calcBmiNumeric(weightKg, heightCm / 100);
    if (currentBmi < 18.5) maxWeight = getWeightFromBmiAndHeight(currentBmi, heightCm, outKg);
    maxWeight = getWeightFromBmiAndHeight(18.5, heightCm / 100, outKg);
  }
  if (!maxWeight) {
    return outKg ? 30 : 60;
  }
  return Math.round(maxWeight);
}

export function getMaxWeight(weightKg: ?number, heightCm: number, outKg: boolean) {
  let maxWeight = undefined;
  if (!weightKg || !heightCm) {
    maxWeight = getWeightFromBmiAndHeight(40, heightCm / 100, outKg);
  } else {
    const currentBmi = calcBmiNumeric(weightKg, heightCm / 100);
    if (currentBmi > 40) maxWeight = getWeightFromBmiAndHeight(currentBmi, heightCm, outKg);
    else maxWeight = getWeightFromBmiAndHeight(40, heightCm / 100, outKg);
  }
  if (!maxWeight) {
    return outKg ? 150 : 300;
  }
  return Math.round(maxWeight);
}
