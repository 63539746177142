// @flow
import React, { useCallback } from 'react';
import { Button } from '@chakra-ui/react';
import { Auth } from 'aws-amplify';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { isLoggedIn } from './auth';
import withNavigation from '../main/WithRoutes';
import routes from '../main/routes';
import AWS from "aws-sdk";

type Props = {
  navigate: any
};

function SignOutButtonImpl(props: Props) {
  const { user } = useAuthenticator((context) => [context.user]);
  const { navigate } = props;
  const signOut = useCallback(async () => {
    if (user) {
      console.log("signing out")
      if (AWS.config.credentials) {
        AWS.config.credentials.clearCachedId();
      }
      await Auth.signOut();
      sessionStorage.removeItem("handledClockOffset");
      navigate(routes.LOGIN);
    }
  }, [user, navigate]);

  const loggedIn = isLoggedIn();
  return (       <Button
      fontSize={16}
      w="80px"
      color="orange.300"
      bg="transparent"
      fontWeight="400"
      onClick={signOut}
      isDisabled={!loggedIn}
    >
      Sign Out
    </Button>
  );
}

export default withNavigation(SignOutButtonImpl);
