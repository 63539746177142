// @flow
import {API_URLS, SERVICES} from "../config/url_config";
import requests from "../utils/request";

export function athenaCollectPatientInfo(athenaPatientId: number, practice: number, department: number) {
  const path = `${API_URLS.ATHENAAPI.PATIENT}/${athenaPatientId}`;
	const additionalParams = {
		queryParams: {
			practice: practice,
			department: department
		}
	};
  return requests.get(SERVICES.ATHENAAPI, {}, path, additionalParams);
}