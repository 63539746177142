// @flow
import React from 'react';
import { Box } from '@chakra-ui/react';
import { getPatientId } from './capilots-utils';
import ProstateRecommendationSection from '../prostate/ProstateRecommendationSection';
import ProstateExplanatorySection from '../prostate/ProstateExplanatorySection';
import prostateImage from '../images/prostate-cancer.png';
import withNavigation from '../main/WithRoutes';
import ProstateChartsSection from '../prostate/ProstateChartsSection';
import prostateReportCommon from "../prostate/prostate-report-common";
import {getRiskLevelByPercentile} from "../common/risk-colors";
import PatientAttributes from "../common/reports/PatientAttributes";
import {defaultCancerCalculatorUserAttribute} from "../common/reports/report-common";
import GeneticReportOrMobile from "../common/reports/GeneticReportOrMobile";
import withMobile from "../common/mobile/withMobile";

type Props = {
  location: any,
  params: any,
  visit: Object,
  printing: boolean,
  patientId: ?string,
  prostate: Object,
  loading: boolean,
  isMobile: boolean,
  isClinicianView: boolean,
  topMiddlePane: any
};

export class PilotProstateRiskReportImpl extends React.Component<
  Props
> {
  constructor(props: Props) {
    super(props);
    this.patientId = props.patientId ?? getPatientId(props.params, props.location);
  }

  patientId: string;

  render() {
    const {isMobile} = this.props;
    return (
      <Box sx={{ '@media print': { '@page': { size: 'landscape;' } } }}>
        <GeneticReportOrMobile
          isDemo={false}
          reportTitle="PROSTATE CANCER RISK REPORT"
          configTrait="prostate"
          loading={this.props.loading}
          titleImage={prostateImage}
          patientNum={this.props.visit ? this.props.visit.patient_num : null}
          patientName={this.props.visit ? this.props.visit.patient_name : null}
          geneticKitId={this.props.visit ? this.props.visit.kit_id : ''}
          printing={this.props.printing}
          geneticRiskInfoText={[
            prostateReportCommon.GENETIC_RISK_PROSTATE_INFO_TEXT
          ]}
          riskPercentile={
            this.props.prostate ? this.props.prostate.prostate_cancer.percentile : null
          }
          topMiddlePane={this.props.topMiddlePane}
          patientAttributesSection={this.props.printing && this.props.prostate &&
            <Box w={"420px"} borderWidth={1} borderColor="gray.100">
              <PatientAttributes
                  gender={this.props.visit.sex ? "Male": "Female"}
                  age={this.props.visit.age}
                  riskTrait="Prostate Cancer"
                  riskLevel={getRiskLevelByPercentile(this.props.prostate.prostate_cancer.percentile + 1)}
                  orderLabs={false}
                  labelPrefix="Current "
                  showBorder={false}
                  showAttributeFlags={defaultCancerCalculatorUserAttribute}
                  spacing={"7px"}
                >
                </PatientAttributes>
              </Box>
          }
          
          chartsSection={
            this.props.prostate ?
              <ProstateChartsSection
                percentile={this.props.prostate.prostate_cancer.percentile}
                quintile={Math.floor(
                  this.props.prostate.prostate_cancer.percentile / 20
                )}
                deciles={this.props.prostate.prostate_cancer.deciles}
                oddsRatios={this.props.prostate.prostate_cancer.odds_ratio_list}
                isMobile={isMobile}
                printing={this.props.printing}
              /> : null
          }
          recommendationSection={
            !this.props.isClinicianView && <ProstateRecommendationSection isMobile={isMobile} />
          }
          explanatorySection={<ProstateExplanatorySection isMobile={isMobile} />}
        />
      </Box>
    );
  }
}

export default withNavigation(withMobile(PilotProstateRiskReportImpl));
