// @flow
import React from 'react';
import { Box } from '@chakra-ui/react';
import { v4 as uuid4 } from 'uuid';
import {getFileNameOnly, hypercholesterolCalculatorUserAttribute} from '../../common/reports/report-common';
import { getPatientId } from '../filters';
import HypercholesterolRecommendationSection from './HypercholesterolRecommendationSection';
import HypercholesterolExplanatorySection from './HypercholesterolExplanatorySection';
import withNavigation from '../../main/WithRoutes';
import LdlChartsSection from './LdlChartsSection';
import HdlChartsSection from './HdlChartsSection';
import cholesterolImage from '../../images/cholesterol.png';
import hypercholesterolReportCommon from './hypercholesterol-report-common';
import PatientAttributes from "../../common/reports/PatientAttributes";
import {getRiskLevelByPercentile} from "../../common/risk-colors";
import DemoClinicalData1 from "../data/clinical1.json";
import DemoClinicalData2 from "../data/clinical2.json";
import DemoClinicalData3 from "../data/clinical3.json";
import DemoClinicalData4 from "../data/clinical4.json";
import DemoClinicalData5 from "../data/clinical5.json";
import {roundWithPrecision} from "../../utils/numbers";
import GeneticReportOrMobile from "../../common/reports/GeneticReportOrMobile";
import MobileLdlChartsSection from "./MobileLdlChartsSection";
import MobileHdlChartsSection from "./MobileHdlChartsSection";
import withMobile from "../../common/mobile/withMobile";

type Props = {
  location: any,
  params: any,
  printing: boolean,
  isMobile: boolean
};
type State = {};

export class DemoHypercholesterolRiskReportImpl extends React.Component<
  Props,
  State
> {
  static loadDemoData() {
    // $FlowFixMe[prop-missing]
    const requireContext: any = require.context('./data', true, /\.json$/);
    const json = {};
    requireContext.keys().forEach(key => {
      const obj = requireContext(key);
      const simpleKey = getFileNameOnly(key);
      json[simpleKey] = obj;
    });
    return json;
  }

  constructor(props: Props) {
    super(props);
    this.patientId = getPatientId(props.params, props.location);
    const cholesterols = DemoHypercholesterolRiskReportImpl.loadDemoData();
    const key = `cholesterol${this.patientId}`;
    this.cholesterol =
      cholesterols[key] === undefined
        ? cholesterols.cholesterol1
        : cholesterols[key];
    this.clinical = DemoClinicalData1;
    if (this.patientId === 2) this.clinical = DemoClinicalData2;
    if (this.patientId === 3) this.clinical = DemoClinicalData3;
    if (this.patientId === 4) this.clinical = DemoClinicalData4;
    if (this.patientId === 5) this.clinical = DemoClinicalData5;
    if (this.patientId > 5)
      this.clinical = {
        sex: undefined,
        age: undefined,
        bmi: undefined
      };
  }

  cholesterol: Object;
  patientId: number;
  clinical: Object;


  render() {
    const {isMobile} = this.props;

    const chartsSection = (<>
      {!isMobile && [
        <Box
          key={uuid4()}
          mt="30px"
          sx={{
            '@media print': {
              pageBreakBefore: 'always;'
            }
          }}
        >
          <LdlChartsSection
            percentile={this.cholesterol.ldl.percentile}
            quintile={Math.floor(this.cholesterol.ldl.percentile / 20)}
            oddsRatios={this.cholesterol.ldl.odds_list}
            deciles={this.cholesterol.ldl.deciles}
            isMobile={false}
            key="ldl_charts"
            printing={this.props.printing}
          />
        </Box>,
        <Box mt="30px" key={uuid4()}>
          <HdlChartsSection
            percentile={this.cholesterol.hdl.percentile}
            quintile={Math.floor(this.cholesterol.hdl.percentile / 20)}
            oddsRatios={this.cholesterol.hdl.odds_list}
            deciles={this.cholesterol.hdl.deciles}
            isMobile={false}
            key="hdl_charts"
            printing={this.props.printing}
          />
        </Box>
      ]}
      {isMobile && [
        <MobileLdlChartsSection
          percentile={this.cholesterol.ldl.percentile}
          quintile={Math.floor(this.cholesterol.ldl.percentile / 20)}
          oddsRatios={this.cholesterol.ldl.odds_list}
          deciles={this.cholesterol.ldl.deciles}
          isMobile
          key="ldl_charts"
        />,
        <MobileHdlChartsSection
          percentile={this.cholesterol.hdl.percentile}
          quintile={Math.floor(this.cholesterol.hdl.percentile / 20)}
          oddsRatios={this.cholesterol.hdl.odds_list}
          deciles={this.cholesterol.hdl.deciles}
          isMobile
          key="hdl_charts"
        />
      ]}
      </>);

    return (
      <Box sx={{ '@media print': { '@page': { size: 'landscape;' } } }}>
        <GeneticReportOrMobile
          reportTitle="HYPERCHOLESTEROLEMIA RISK REPORT"
          configTrait="cholesterol"
          titleImage={cholesterolImage}
          patientNum={this.patientId}
          geneticKitId={`OD000${this.patientId}`}
          printing={this.props.printing}
          riskPercentile={[
            this.cholesterol.ldl.percentile,
            99 - this.cholesterol.hdl.percentile
          ]}
          personChartTitle={[
            'Low-density Lipoprotein (LDL)',
            'High-density Lipoprotein (HDL)'
          ]}
          geneticRiskInfoText={[
            hypercholesterolReportCommon.GENETIC_RISK_LDL_INFO_TEXT,
            hypercholesterolReportCommon.GENETIC_RISK_HDL_INFO_TEXT
          ]}
          patientAttributesSection={this.props.printing && this.cholesterol &&
            <Box w={"420px"} borderWidth={1} borderColor="gray.100">
              <PatientAttributes
                  gender={this.clinical.sex ? "Male": "Female"}
                  age={this.clinical.age}
                  bmi={roundWithPrecision(this.clinical.bmi, 1)}
                  riskTrait="Hypercholesterolemia (LDL)"
                  riskLevel={getRiskLevelByPercentile(this.cholesterol.ldl.percentile + 1)}
                  riskTrait2="Hypercholesterolemia (HDL)"
                  riskLevel2={getRiskLevelByPercentile(100 - this.cholesterol.hdl.percentile)}
                  orderLabs={false}
                  labelPrefix="Current "
                  showBorder={false}
                  showAttributeFlags={hypercholesterolCalculatorUserAttribute}
                  spacing={"7px"}
                >
                </PatientAttributes>
              </Box>
          }
          chartsSection={chartsSection}
          recommendationSection={
            <Box
              mt="30px"
              sx={{
                '@media print': {
                  pageBreakBefore: 'always;'
                }
              }}
            >
              <HypercholesterolRecommendationSection isMobile={isMobile}/>
            </Box>
          }
          explanatorySection={
            <HypercholesterolExplanatorySection isMobile={isMobile} />
          }
        />
      </Box>
    );
  }
}

export default withNavigation(withMobile(DemoHypercholesterolRiskReportImpl));
