import {useCallback, useEffect} from "react";
import { useAuthenticator } from '@aws-amplify/ui-react';
import withNavigation from "../main/WithRoutes";
import routes from "../main/routes";

type Props = {
  navigate: any,
  children: any
};

export function RequireAuthImpl(props: Props) {
  const { route } = useAuthenticator((context) => [context.route]);
  const { navigate } = props;

  const navigateToLogin = useCallback(()=> {
    if (route !== 'authenticated') {
      navigate(routes.LOGIN)
    }
  }, [route, navigate])

  useEffect(() => {
    if (route !== 'authenticated') {
      console.log("navigating to login")
      setTimeout(() => navigateToLogin(), 1000);
    }
  }, [route, navigateToLogin]);

  return props.children;
}

export default withNavigation(RequireAuthImpl);
