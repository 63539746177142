// @flow
import React from 'react';
import { Flex, Box, Text } from '@chakra-ui/react';
import COLORS from '../common/colors';
import cadReportStrings from './cad-report-common';
import Disclaimers from '../common/reports/Disclaimers';

type Props = {
  isMobile: boolean
};

export default function CadExplanatorySection(props: Props) {
  const headerFontSize = props.isMobile ? 17 : 20;
  const textFontSize = props.isMobile ? 12 : 14;
  return (
    <Box fontSize={textFontSize}
      sx={{
        '@media print': {
          pageBreakBefore: !props.isMobile ? 'always' : 'auto'
        }
      }}
    >
      <Flex align="center" justify="space-between">
        <Text fontSize={headerFontSize} color="gray.600" mt="30px" mb="20px">
          What is Coronary Artery Disease ?
        </Text>
      </Flex>
      <Text mb="20px" color={COLORS.REPORT_TEXT}>
        {cadReportStrings.WHAT_IS_CAD_TEXT1}
        <br />
        <br />
        {cadReportStrings.WHAT_IS_CAD_TEXT2}
        <sup>*</sup>.
        <br />
      </Text>
      <Text mb="5px">
        <br />
        <i>
          <sup>*</sup> {cadReportStrings.WHAT_IS_CAD_REF1}
        </i>
      </Text>
      <Text>
        <i>
          <sup>*</sup> {cadReportStrings.WHAT_IS_CAD_REF2}
        </i>
      </Text>
      <Text fontSize={headerFontSize} color="gray.600" my="20px">
        How did we calculate your score ?
      </Text>
      <Text mb="15px" color={COLORS.REPORT_TEXT}>
        {cadReportStrings.HOW_DID_WE_CALC_TEXT1}
        <sup>*</sup>. {cadReportStrings.HOW_DID_WE_CALC_TEXT2}
      </Text>
      <Text mb="20px" fontSize={textFontSize}>
        <sup>*</sup>
        <i>{cadReportStrings.HOW_DID_WE_CALC_REF}</i>
      </Text>
      <Disclaimers
        isMobile={props.isMobile}
        texts={[
          cadReportStrings.DISCLAIMER1,
          cadReportStrings.DISCLAIMER2,
          cadReportStrings.DISCLAIMER3,
          cadReportStrings.DISCLAIMER4,
          cadReportStrings.DISCLAIMER5,
          cadReportStrings.DISCLAIMER6,
          cadReportStrings.DISCLAIMER7
        ]}
      />
    </Box>
  );
}
