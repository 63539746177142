// @flow
import React from 'react';
import { Box } from '@chakra-ui/react';
import { getFileNameOnly } from '../../common/reports/report-common';
import { getPatientId } from '../filters';
import OsteoporosisRecommendationSection from './OsteoporosisRecommendationSection';
import OsteoporosisExplanatorySection from './OsteoporosisExplanatorySection';
import osteoporosisImage from '../../images/osteoporosis.png';
import withNavigation from '../../main/WithRoutes';
import OsteoporosisChartsSection from './OsteoporosisChartsSection';
import osteoporosisReportCommon from "./osteoporosis-report-common";
import GeneticReportOrMobile from "../../common/reports/GeneticReportOrMobile";
import withMobile from "../../common/mobile/withMobile";

type Props = {
  location: any,
  params: any,
  printing: boolean,
  isMobile: boolean
};
type State = {};

export class DemoOsteoporosisRiskReportImpl extends React.Component<Props, State> {
  static loadDemoData() {
    // $FlowFixMe[prop-missing]
    const requireContext: any = require.context('./data', true, /\.json$/);
    const json = {};
    requireContext.keys().forEach(key => {
      const obj = requireContext(key);
      const simpleKey = getFileNameOnly(key);
      json[simpleKey] = obj;
    });
    return json;
  }

  constructor(props: Props) {
    super(props);
    this.patientId = getPatientId(props.params, props.location);
    const osteoporosises = DemoOsteoporosisRiskReportImpl.loadDemoData();
    const key = `osteoporosis${this.patientId}`;
    this.osteoporosis = osteoporosises[key] === undefined ? osteoporosises.osteoporosis1 : osteoporosises[key];
  }

  osteoporosis: Object;
  patientId: number;

  render() {
    const {isMobile} = this.props;
    return (
      <Box>
        <GeneticReportOrMobile
          reportTitle="OSTEOPOROSIS RISK REPORT"
          configTrait="osteoporosis"
          titleImage={osteoporosisImage}
          patientNum={this.patientId}
          geneticKitId={`OD000${this.patientId}`}
          printing={this.props.printing}
          riskPercentile={this.osteoporosis.osteoporosis.percentile}
          geneticRiskInfoText={[
            osteoporosisReportCommon.GENETIC_RISK_OSTEOPOROSIS_INFO_TEXT,
          ]}
          chartsSection={
            <OsteoporosisChartsSection
              percentile={this.osteoporosis.osteoporosis.percentile}
              quintile={Math.floor(
                this.osteoporosis.osteoporosis.percentile / 20
              )}
              deciles={this.osteoporosis.osteoporosis.deciles}
              oddsRatios={this.osteoporosis.osteoporosis.odds_list}
              printing={this.props.printing}
            />
          }
          recommendationSection={
            <OsteoporosisRecommendationSection isMobile={isMobile} />
          }
          explanatorySection={<OsteoporosisExplanatorySection isMobile={isMobile} />}
        />
      </Box>
    );
  }
}

export default withNavigation(withMobile(DemoOsteoporosisRiskReportImpl));
