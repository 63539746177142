// @flow

const ColorectalReportStrings = {
  WHAT_IS_COLORECTAL_TEXT1:
    'Colorectal cancer is a disease in which cells in the colon or rectum grow out of control. ' +
    'Sometimes it is called colon cancer. The colon is the large intestine or large bowel. ' +
    'The rectum is the passageway that connects the colon to the anus. ' +
    'Sometimes abnormal growths, called polyps, form in the colon or rectum. ' +
    'Over time, some polyps may turn into cancer. Screening tests can find polyps so they can be removed ' +
    'before turning into cancer. ' +
    'Screening also helps find colorectal cancer at an early stage, when treatment works best.',
  WHAT_IS_COLORECTAL_TEXT2:
    'Colorectal cancer is the third most common malignancy and the third most deadly cancer in the United States.',
  WHAT_IS_COLORECTAL_REF1:
    'Rawla P, Sunkara T, Barsouk A. Epidemiology of colorectal cancer: incidence, mortality, survival, and risk factors. Prz Gastroenterol.',
  HOW_DID_WE_CALC_TEXT1:
    'OpenDNA calculates your score based on analysis of your ' +
    'genetic variants. Genetic variation is the difference in DNA ' +
    'sequences between individuals within a population. These are the ' +
    'differences in DNA that make every individual different. OpenDNA ' +
    'scans 75 significant genetic variants and calculates a ' +
    'Polygenic Risk Score (PRS) based on the contribution of each ' +
    'genetic variant to your genetic risk for developing colorectal cancer.',
  HOW_DID_WE_CALC_TEXT2:
    'Once your PRS is calculated it is compared ' +
    'to a group of about 339,000 people of European descent to ' +
    'calculate your relative risk of developing colorectal cancer ' +
    'compared to that population.',
  HOW_DID_WE_CALC_REF1:
    'Law, P.J., Timofeeva, M., Fernandez-Rozadilla, C. et al. Association analyses identify 31 new ' +
    'risk loci for colorectal cancer susceptibility. Nat Commun 10, 2154 (2019). https://doi.org/10.1038/s41467-019-09775-w',
  HOW_DID_WE_CALC_REF2:
    'Schmit, S. L. et al. Novel common genetic susceptibility loci for colorectal cancer. J. Natl. Cancer Inst. 111, 146–157 (2018)',
  HOW_DID_WE_CALC_REF3:
    'Orlando, G. et al. Variation at 2q35 (PNKD and TMBIM1) influences colorectal cancer risk and identifies a pleiotropic effect with inflammatory bowel disease. Hum. Mol. Genet 25, 2349–2359 (2016).',
  DISCLAIMER1:
    'THIS REPORT MAY NOT INCLUDE EVERY GENETIC MARKER RELATED TO COLORECTAL CANCER.',
  DISCLAIMER2:
    'THIS TEST IS NOT DIAGNOSTIC, IT IS A PREDICTION BASED ON PREDISPOSITION. ' +
    'AN INCREASED RISK SCORE DOES NOT MEAN THAT A PERSON WILL DEFINITELY DEVELOP A DISEASE ' +
    'AND A DECREASED RISK SCORE DOES NOT MEAN THAT A PERSON WILL DEFINITELY NOT DEVELOP A DISEASE.',
  DISCLAIMER3:
    'THE TEST CANNOT REPLACE MEDICAL TESTING OR MEDICAL MANAGEMENT THROUGH A HEALTHCARE PROVIDER.',
  DISCLAIMER4:
    'THE RISK MAY ALSO BE INFLUENCED BY OTHER LIFESTYLE FACTORS, OTHER CLINICAL FACTORS AND ' +
    'GENETIC VARIANTS THAT ARE NOT INCLUDED IN THE ANALYSIS OR WERE NOT REPORTED PROPERLY.',
  DISCLAIMER5:
    'YOUR RISK IS COMPARED TO A POPULATION WHERE THE MAJORITY IS OF EUROPEAN DESCENT. ' +
    'THE ACCURACY OF THE RESULTS MAY VARY IF YOU HAVE A DIFFERENT BACKGROUND.',
  DISCLAIMER6:
    'TEST RESULTS SHOULD BE INTERPRETED BY A HEALTHCARE PROVIDER. MEDICAL MANAGEMENT AND ' +
    'DECISION-MAKING FOR PREVENTION PRACTICES SHOULD BE DONE IN THE CONTEXT OF THE PATIENT’S ' +
    'FULL CLINICAL HISTORY AND NOT BASED SOLELY ON THE TEST RESULTS.',
  GENETIC_RISK_COLORECTAL_INFO_TEXT:
    'The polygenic risk score (PRS) of the patient is calculated by analyzing ' +
    '75 variants known to be associated with colorectal cancer. ' +
    'The PRS is then compared to a reference scale, built from scores of ~339K individuals. ' +
    'The scale is divided into percentiles that reflect the genetic risk level. ' +
    "The patient risk percentile is determined by comparing the individual's PRS value against the threshold risk values of each percentile.",
  COLORECTAL_RISK_IN_POPULATION:
    'The graph shows the risk for having colorectal cancer vs. the genetic risk level. Genetic risk (PRS) was calculated for ' +
    'more than 339K individuals. The scores were divided into deciles and the risk of having colorectal cancer was calculated for each decile. ' +
    "Gray bars indicate the risk level per decile. The highlighted bar represents patient risk level based on the individual's genetic risk.",
  COLORECTAL_RISK_VS_AVERAGE:
    'The graph shows patient risk level for having colorectal cancer vs. the average risk in the population. ' +
    "The risk of the patient is determined based on the individual's genetic risk level. "
};

export default ColorectalReportStrings;
