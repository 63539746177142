import cadImage from '../images/heart-attack.png';
import cholesterolImage from '../images/cholesterol.png';
import breastCancerImage from '../images/breast-cancer.png';
import htnGaugeImage from '../images/blood-pressure-gauge-light.png';
import colorectalImage from '../images/colorectal-cancer.png';
import prostateImage from '../images/prostate-cancer.png';
import t2dImage from '../images/sugar-blood-level.png';
import atrialImage from '../images/heart-beat.png';
import osteoporosisImage from '../images/osteoporosis.png';
import melanomaImage from '../images/skin-cancer.png';
import cancerImage from '../images/cancer_bw.png';

export const LEFT_BAR_ITEMS = {
	cardio: {
		name: "Cardiometabolic",
		color: "gray.600",
		childItems: {
			cad: {
				name: "Coronary artery disease",
				icon: cadImage,
				color: "gray.900"
			},
			t2d: {
				name: "Type 2 diabetes",
				icon: t2dImage,
				color: "gray.900"
			},
			hchol: {
				name: "Hypercholesterolemia",
				icon: cholesterolImage,
				color: "gray.900"
			},
			htn: {
				name: "Hypertension",
				icon: htnGaugeImage,
				color: "gray.900"
			},
			af: {
				name: "Atrial fibrillation",
				icon: atrialImage,
				color: "gray.500"
			},
			follow: {
				name: "Follow Up",
				icon: atrialImage,
				color: "gray.500"
			}
		}
	},
	cancer: {
		name: "Cancer",
		color: "gray.600",
		childItems: {
			colorectal: {
				name: "Colorectal",
				icon: colorectalImage,
				color: "gray.900"
			},
			prostate: {
				name: "Prostate",
				icon: prostateImage,
				color: "gray.900"
			},
			breast: {
				name: "Breast",
				icon: breastCancerImage,
				color: "gray.900"
			},
			melanoma: {
				name: "Melanoma",
				icon: melanomaImage,
				color: "gray.500"
			},
			cancerMono: {
				name: "Monogenic",
				icon: cancerImage,
				color: "gray.900",
				dividerBefore: true
			}
		}
	},
	women: {
		name: "Women health",
		color: "gray.500",
		childItems: {
			wOsteoporosis: {
				name: "Osteoporosis",
				icon: osteoporosisImage,
				color: "gray.500"
			},
			wBreast: {
				name: "Breast cancer",
				icon: breastCancerImage,
				color: "gray.500"
			}
		}
	},
	bone: {
		name: "Bone diseases",
		color: "gray.500",
		childItems: {
			osteoporosis: {
				name: "Osteoporosis",
				icon: osteoporosisImage,
				color: "gray.500"
			},
		}
	}
};
