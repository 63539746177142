// @flow
import config from './report-config.json';

export function getRootConfigItem(location: Object) {
  if (location.pathname.includes('/gxg/')) {
    return config.genebygene;
  }
  if (location.pathname.includes('pilots')) {
    return config.pilots;
  }
  return config.default;
}

export function isShowTrait(location: Object, trait: string) {
  const root = getRootConfigItem(location);
  return !!root[trait];
}

export function loadConfig(location: Object, trait: string) {
  const root = getRootConfigItem(location);
  return root[trait];
}

export function isShowItem(location: any, trait: string, item: string) {
  const root = getRootConfigItem(location);
  // console.log(root);
  // console.log(trait);
  // console.log(root[trait]);
  return root[trait][item];
}
