//@flow
import clinicNames from "./clinic_names.json";
import capitalizeFirstLetter from "../utils/string";

export function getClinicDisplayName(clinic: string) {
    if (clinicNames[clinic]) return clinicNames[clinic];
    let clinicName: string = '';
    const tokens = clinic.split('_');
    tokens.forEach(token => {
      let capitalToken = capitalizeFirstLetter(token);
      if (!capitalToken) capitalToken = '';
      clinicName += ' ';
      clinicName += capitalToken;
    });
    return clinicName.slice(1);
}

export function isPilotsGroup(group: string) {
  const pilotGroups = ['mayo_clinic', 'consensus_health_pilots', 'mmc_care_pilots', 'cardiac_associates']
  return pilotGroups.includes(group);
}