import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import {Amplify} from "aws-amplify";
import { Authenticator } from '@aws-amplify/ui-react';
import {ThemeProvider} from "@aws-amplify/ui-react";
import {ChakraProvider, extendTheme} from "@chakra-ui/react";
import './index.css';
import '@aws-amplify/ui-react/styles.css';

import COGNITO_CONFIG from './config/auth_config';
import { ENV } from './config/env_config';
import Main from "./main/Main";
import {CurrentUserProvider} from "./context/CurrentUserContext";
import {amplifyTheme} from "./main/amplfy-theme";


// configure amplify settings
Amplify.configure({
  Auth: {
    authenticationFlowType: 'USER_PASSWORD_AUTH',
    userPoolId: COGNITO_CONFIG[ENV].USERPOOL_ID,
    userPoolWebClientId: COGNITO_CONFIG[ENV].APPCLIENT_ID,
    region: COGNITO_CONFIG[ENV].REGION
  }
});

// Call `extendTheme` and pass your custom values
const mainTheme = extendTheme({
  fonts: {
    heading: 'century-gothic, sans-serif', // "Avenir Next"
    body: 'century-gothic, sans-serif',
    mono: 'Menlo, monospace'
  }
});


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <ChakraProvider  theme={mainTheme}>
      <ThemeProvider theme={amplifyTheme}>
        <Authenticator.Provider>
          <BrowserRouter>
            <CurrentUserProvider>
              <Main />
            </CurrentUserProvider>
          </BrowserRouter>
        </Authenticator.Provider>
      </ThemeProvider>
    </ChakraProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
