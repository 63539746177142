// @flow
import React, {useCallback, useState} from 'react';
import PropTypes from 'prop-types';
import {Box, Stack, Text, Flex, Divider} from '@chakra-ui/react';
import {v4 as uuid4} from "uuid";
import LabeledTextWithUnits from "../LabelTextAndUnits";
import COLORS from "../colors";
import type {BooleanAttr, NumericAttr, SelectionAttr} from "./calculator-attr";
import LabeledSelection from "../LabeledSelection";
import ActionButton from "../ActionButton";
import EditableNumericAttrImpl from "../EditableNumericAttr";
import capitalizeFirstLetter from "../../utils/string";
import {isNullOrUndefined} from "../../utils/numbers";

const BINARY_KEYNAME_OPTIONS = {
  "0": "",
  "1": "no",
  "2": "yes"
}


type Props = {
  sex: boolean,
  title?: ?string,
  geneticRiskTrait?: ?string,
  geneticRiskLevel?: ?string,
	numericAttrs: Array<NumericAttr>,
	booleanAttrs: Array<BooleanAttr>,
	selectionAttrs: Array<SelectionAttr>,
  showBorder?: boolean,
  fontSize?: number,
  mbTitle?: string,
  labelPrefix?: string,
  titleFontWeight?: string,
  spacing?: string,
  callbackOnSave?: any,
  callbackOnCancelEdit?: any,
  showGender: boolean,
  allowEditing: boolean,
  allowFirstMissingEditing: boolean
};

export default function CalculatorPatientAttributes(props: Props) {
  const labelPrefix = props.labelPrefix ? props.labelPrefix : '';
  const [needSaving, setNeedSaving] = useState(false);
  // const [showEditButton, setShowEditButton] = useState(false);
  // const [isOnEdit, setIsOnEdit] = useState(false);
  const isOnEdit = false;

  function addEmptyKeyNameOption(keyNameOptions: Object) {
    const options = {...keyNameOptions}
    options["0"] = "";
    return options;
  }

  function getBinaryYesNoText(binaryField) {
    let yesNoText = '';
    if (binaryField !== undefined) yesNoText = binaryField ? 'yes' : 'no';
    return yesNoText;
  }

  const {booleanAttrs, selectionAttrs, numericAttrs} = props;
  const booleanAttrNames = props.booleanAttrs.map(attr => attr.name);
  const selectionAttrNames = props.selectionAttrs.map(attr => attr.name);
  const numericAttrNames = props.numericAttrs.map(attr => attr.name);

  const prepareNumericAttrByName = useCallback(()=>{
    const attrsByName = {}
    numericAttrs.forEach(attr => attrsByName[attr.name] = attr);
    return attrsByName;
  }, [numericAttrs]);
  const numericAttrByName = prepareNumericAttrByName();

  const {allowEditing, allowFirstMissingEditing} = props;
  const isAttrEditable = useCallback(attr => {
    if (!allowEditing) return false;
    if (booleanAttrNames.includes(attr.name) && !attr.isEditable) return false;
    if (selectionAttrNames.includes(attr.name) && !attr.isEditable) return false;
    if (numericAttrNames.includes(attr.name) && !attr.isEditable) return false;
    if (allowFirstMissingEditing && (attr.currentValue === null || attr.currentValue === undefined)) {
      return true;
    }
    return isOnEdit;
  }, [allowEditing, allowFirstMissingEditing, booleanAttrNames, selectionAttrNames, numericAttrNames, isOnEdit]);

  const getUpdatedEditableAttrsState = useCallback(() => {
    const initState = {}
    if (!allowEditing) return initState;
    for (let i = 0; i < numericAttrs.length; i += 1) {
      const attr = numericAttrs[i];
      initState[attr.name] = attr.currentValue;
      initState[`${attr.name}_editable`] = isAttrEditable(attr);
    }
    for (let i = 0; i < booleanAttrs.length; i += 1) {
      const attr = booleanAttrs[i];
      if (attr.isEditable) {
        initState[attr.name] = isNullOrUndefined(attr.currentValue) ? "0" : (attr.currentValue ? "2" : "1");
        initState[`${attr.name}_editable`] = isAttrEditable(attr);
      }
    }
    for (let i = 0; i < selectionAttrs.length; i += 1) {
      const attr = selectionAttrs[i];
      if (attr.isEditable) {
        initState[attr.name] = attr.currentValue;
        initState[`${attr.name}_editable`] = isAttrEditable(attr);
      }
    }
    return initState;
  }, [selectionAttrs, booleanAttrs, numericAttrs, allowEditing, isAttrEditable]);

  const [editableAttrsState, setEditableAttrsState] = useState(getUpdatedEditableAttrsState());

  function onEditableBoolChangeWrapper(fOnChange: any) {
    function f(value, attr) {
      if (value === "0") return;
      setNeedSaving(true);
      setEditableAttrsState((prevState) => ({...prevState, [attr]: value}));
      if (fOnChange) fOnChange(value === "2", attr);
    }
    return f;
  }
  function onEditableSelectionChangeWrapper(fOnChange: any) {
    function f(value, attr) {
      if (value === "0") return;
      setNeedSaving(true);
      setEditableAttrsState((prevState) => ({...prevState, [attr]: value}));
      if (fOnChange) fOnChange(value, attr);
    }
    return f;
  }
  function onEditableNumericChangeWrapper(fOnChange: any, attr: string) {
    function f(value) {
      setNeedSaving(true);
      setEditableAttrsState((prevState) => ({...prevState, [attr]: value}));
      if (fOnChange) fOnChange(Number.isNaN(value) ? null : Number(value), attr);
    }
    return f;
  }

  function getGenderBySex(sex: boolean) {
    return sex ? 'male' : 'female';
  }

  const wrappedBooleanAttrs = props.booleanAttrs.map(attr => {
    if (!editableAttrsState[`${attr.name}_editable`]) return attr;
    const wrappedAttr = {...attr};
    wrappedAttr.onChange = onEditableBoolChangeWrapper(attr.onChange);
    return wrappedAttr;
  });

  const wrappedSelectionAttrs = props.selectionAttrs.map(attr => {
    if (!editableAttrsState[`${attr.name}_editable`]) return attr;
    const wrappedAttr = {...attr};
    wrappedAttr.onChange = onEditableSelectionChangeWrapper(attr.onChange);
    return wrappedAttr;
  });

  const wrappedNumericAttrs = props.numericAttrs.map(attr => {
    if (!editableAttrsState[`${attr.name}_editable`]) return attr;
    const wrappedAttr = {...attr};
    wrappedAttr.onChange = onEditableNumericChangeWrapper(attr.onChange, attr.name);
    return wrappedAttr;
  });

	const getEditableAttrsOnChangeMap = useCallback(() => {
    const editableAttrs = {}
    booleanAttrs.forEach(attr => {
      if (editableAttrsState[`${attr.name}_editable`]) editableAttrs[attr.name] = attr.onChange;
    })
    selectionAttrs.forEach(attr => {
      if (editableAttrsState[`${attr.name}_editable`]) editableAttrs[attr.name] = attr.onChange;
    })
    numericAttrs.forEach(attr => {
      if (editableAttrsState[`${attr.name}_editable`]) editableAttrs[attr.name] = attr.onChange;
    })
    return editableAttrs;
	}, [selectionAttrs, booleanAttrs, numericAttrs, editableAttrsState]);

  const {callbackOnSave, callbackOnCancelEdit} = props;
  const onSaveVisitAttrs = useCallback(() => {
    const attrs = {}
    if (callbackOnSave) {
      Object.keys(editableAttrsState).forEach(attr => {
        if (editableAttrsState[`${attr}_editable`]) {
          if (booleanAttrNames.includes(attr) && editableAttrsState[attr] !== "0") {
            attrs[attr] = editableAttrsState[attr] === "2";
          } else if (selectionAttrNames.includes(attr) && !isNaN(editableAttrsState[attr]) && editableAttrsState[attr] !== "0" && editableAttrsState[attr] !== null) {
            attrs[attr] = Number(editableAttrsState[attr]);
          } else if (numericAttrNames.includes(attr) && editableAttrsState[attr] !== null && editableAttrsState[attr] !== undefined && !Number.isNaN(editableAttrsState[attr])) {
            let newVal = Number(editableAttrsState[attr]);
            if (numericAttrByName[attr].fTranslateFromDisplayValue) {
              newVal = numericAttrByName[attr].fTranslateFromDisplayValue(newVal);
            }
            attrs[attr] = newVal;
          }
        }
      });
      if (callbackOnSave) {
        callbackOnSave(attrs);
      }
      setNeedSaving(false);
    }
  }, [callbackOnSave, editableAttrsState, booleanAttrNames, selectionAttrNames, numericAttrNames, numericAttrByName]);

  const onCancelEditChanges = useCallback(() => {
    const editableAttrsOnChangeMap = getEditableAttrsOnChangeMap();
    const revertState = getUpdatedEditableAttrsState();
    Object.keys(revertState).forEach(attr => {
      if (attr in editableAttrsOnChangeMap) {
        editableAttrsOnChangeMap[attr](revertState[attr], attr);
      }
    });
    setEditableAttrsState(revertState);
    if (callbackOnCancelEdit) {
      callbackOnCancelEdit();
    }
    setNeedSaving(false);
  }, [getUpdatedEditableAttrsState, getEditableAttrsOnChangeMap, callbackOnCancelEdit])

  return (
    <Box
      minW="310px"
      borderColor="gray.100"
      borderWidth={props.showBorder ? 1 : 0}
      mx="15px"
      mb="10px"
    >
      <Box mt="25px" mb={props.mbTitle}>
        {props.title && <Text ml={"10px"} fontWeight={props.titleFontWeight}>
          {props.title}
      </Text>}
      </Box>
      <Flex align={"top"} ml={"10px"}>
        <Box w="90%" fontSize={props.fontSize}>
          <Stack spacing={props.spacing} ml={0} pl={0} mt={"0px"}>
            {props.showGender && <LabeledTextWithUnits
              align="left"
              labelWidth="210px"
              textWidth="70px"
              textAlign="left"
              labelColor={COLORS.REPORT_TEXT}
              textColor={COLORS.REPORT_TEXT}
              trendIcon={undefined}
              title="Gender"
              value={getGenderBySex(props.sex)}
              marginBottom={"0px"}
              unitsWidth="0px"
              unitsColor={COLORS.REPORT_TEXT_GRAY}
              units={''}
            />}
            {props.geneticRiskTrait && <LabeledTextWithUnits
              align="left"
              labelWidth="210px"
              textWidth="70px"
              textAlign="left"
              labelColor={COLORS.REPORT_TEXT}
              textColor={COLORS.REPORT_TEXT}
              title={`Genetic risk for ${props.geneticRiskTrait}`}
              value={props.geneticRiskLevel}
              trendIcon={undefined}
              unitsWidth="0px"
              unitsColor={COLORS.REPORT_TEXT_GRAY}
              units={''}
            />}
            {wrappedNumericAttrs.map(attr => <Box key={uuid4()}>
              {!editableAttrsState[`${attr.name}_editable`] && <LabeledTextWithUnits
                align="left"
                labelWidth="210px"
                textWidth="30px"
                textAlign="left"
                title={`${labelPrefix}${attr.displayText}`}
                trendIcon={undefined}
                labelColor={COLORS.REPORT_TEXT}
                textColor={COLORS.REPORT_TEXT}
                value={attr.resetValue ? attr.resetValue.toFixed(attr.fixDigits) : undefined}
                unitsWidth="45px"
                unitsColor={COLORS.REPORT_TEXT_GRAY}
                units={attr.units}
              />}
              {editableAttrsState[`${attr.name}_editable`] && <EditableNumericAttrImpl
                labelWidth="200px"
                numberWidth="50px"
                onChange={attr.onChange}
                label={attr.displayText}
                lowLimit={attr.min}
                highLimit={attr.max}
                value={editableAttrsState[attr.name]}
                units={attr.units}
                />}
            </Box>)}
            {wrappedBooleanAttrs.map(attr => <Box key={uuid4()}>
              {!editableAttrsState[`${attr.name}_editable`] && attr.name !== 'include_genetics' && attr.name !== 'include_non_traditional' && <LabeledTextWithUnits
                  align="left"
                  labelWidth="210px"
                  textWidth="30px"
                  textAlign="left"
                  title={attr.displayText}
                  labelColor={COLORS.REPORT_TEXT}
                  textColor={COLORS.REPORT_TEXT}
                  trendIcon={undefined}
                  value={isNullOrUndefined(attr.currentValue) ? "": capitalizeFirstLetter(getBinaryYesNoText(attr.currentValue))}
                  unitsWidth="45px"
                  unitsColor={COLORS.REPORT_TEXT_GRAY}
                  units={''}
                />}
              {editableAttrsState[`${attr.name}_editable`] && <Box h={"36px"} mt={"1px"}>
                <LabeledSelection
                    leftLabelWidth="200px"
                    currentValue={editableAttrsState[attr.name]}
                    keyNameOptions={BINARY_KEYNAME_OPTIONS}
                    leftLabel={attr.displayText}
                    optionWidth={"150px"}
                    callbackOnValueChange={attr.onChange}
                    enabled={!!props.callbackOnSave}
                    attrName={attr.name}
                    size={'sm'}
                  />
                </Box>
              }
              </Box>)
            }
            {wrappedSelectionAttrs.map(attr => <Box key={uuid4()}>
              {!editableAttrsState[`${attr.name}_editable`] && <LabeledTextWithUnits
                  align="left"
                  labelWidth="210px"
                  textWidth="85px"
                  textAlign="left"
                  title={attr.displayText}
                  labelColor={COLORS.REPORT_TEXT}
                  textColor={COLORS.REPORT_TEXT}
                  trendIcon={undefined}
                  value={attr.keyNameOptions[attr.resetValue ?? attr.defaultValue]}
                  unitsWidth="0px"
                  unitsColor={COLORS.REPORT_TEXT_GRAY}
                  units={''}
                />}
                {editableAttrsState[`${attr.name}_editable`] && <LabeledSelection
                    leftLabelWidth="200px"
                    currentValue={editableAttrsState[attr.name]}
                    keyNameOptions={addEmptyKeyNameOption(attr.keyNameOptions)}
                    leftLabel={attr.displayText}
                    optionWidth={"150px"}
                    callbackOnValueChange={attr.onChange}
                    enabled={!!props.callbackOnSave}
                    attrName={attr.name}
                    size={'sm'}
                  />}
            </Box>)}
            {needSaving && <Box>
              <Divider my={"10px"}/>
              <Flex align={"center"}>
                <Text w="150px" color={COLORS.RED_STATUS} fontWeight={"bold"}>Unsaved changes:</Text>
                <ActionButton
                  onClick={onSaveVisitAttrs}
                  borderWidth={0}
                  borderColor={COLORS.REPORT_TEXT}
                  name="Save"
                  w={"80px"}
                  mr={"5px"}
                  color={COLORS.REPORT_TEXT}
                />
                <ActionButton
                  onClick={onCancelEditChanges}
                  borderWidth={0}
                  borderColor={COLORS.REPORT_TEXT}
                  name="Revert"
                  w={"80px"}
                  color={COLORS.REPORT_TEXT}
                />
              </Flex>
            </Box>}
          </Stack >
        </Box>
      </Flex>
    </Box>
  );
}

CalculatorPatientAttributes.propTypes = {
  geneticRiskTrait: PropTypes.string,
  geneticRiskLevel: PropTypes.string,
  title: PropTypes.string,
  showBorder: PropTypes.bool,
  fontSize: PropTypes.number,
  mbTitle: PropTypes.string,
  labelPrefix: PropTypes.string,
  titleFontWeight: PropTypes.string,
  spacing: PropTypes.string,
  callbackOnSave: PropTypes.any,
  showGender: PropTypes.bool,
  allowEditing: PropTypes.bool,
  allowFirstMissingEditing: PropTypes.bool,
};

CalculatorPatientAttributes.defaultProps = {
  geneticRiskTrait: undefined,
  geneticRiskLevel: undefined,
  title: undefined,
  showBorder: true,
  fontSize: 12,
  mbTitle: '10px',
  labelPrefix: '',
  titleFontWeight: 'bold',
  spacing: '10px',
  callbackOnSave: undefined,
  callbackOnCancelEdit: undefined,
  showGender: true,
  allowEditing: false,
  allowFirstMissingEditing: false
};
