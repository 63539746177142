// @flow
import React from 'react';
import { Flex, Box, Link, Text, Image } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import COLORS from '../common/colors';
import IconTextAndLink from '../common/IconTextAndLink';
import htnGaugeImage from '../images/blood-pressure-gauge-light.png';
import dietImage from '../images/diet.png';
import runningImage from '../images/running.png';
import stressImage from '../images/yoga.png';
import noSmokingImage from '../images/no-smoking.png';
import firstAidImage from '../images/first-aid-kit.png';
import myDnaImage from '../images/my-dna.png';

type Props = {
  isMobile: boolean
};

export default function GxgCadRecommendationSection(props: Props) {
  return (
    <Box>
      <Text mt="40px" color={COLORS.REPORT_TEXT} fontWeight="semibold">
        To reduce risk for developing coronary artery disease you can refer to
        the following:
      </Text>
      <Link
        color="teal.500"
        href="https://www.cdc.gov/heartdisease/risk_factors.htm"
        isExternal
        mb="20px"
      >
        https://www.cdc.gov/heartdisease/risk_factors.htm
      </Link>
      <IconTextAndLink
        imageSrc={htnGaugeImage}
        text="Monitoring your blood pressure regularly"
        linkText="Monitoring your blood pressure at home"
        link="https://www.heart.org/en/health-topics/high-blood-pressure/understanding-blood-pressure-readings/monitoring-your-blood-pressure-at-home"
        isMobile={props.isMobile}
      />
      <IconTextAndLink
        imageSrc={dietImage}
        text="Diet"
        linkText="personalized diet"
        link="https://www.mydna.life/"
        isMobile={props.isMobile}
        linkImage={myDnaImage}
      />
      <IconTextAndLink
        imageSrc={runningImage}
        text="Exercise"
        linkText="personalized exercise plan"
        link="https://www.mydna.life/"
        isMobile={props.isMobile}
        linkImage={myDnaImage}
      />
      <IconTextAndLink
        imageSrc={stressImage}
        text="Stress reduction"
        linkText="Stress management"
        link="https://www.heart.org/en/healthy-living/healthy-lifestyle/stress-management"
        isMobile={props.isMobile}
      />
      <IconTextAndLink
        imageSrc={noSmokingImage}
        text="Smoking cessation"
        linkText="Quit smoking, vaping and tobacco use"
        link="https://www.heart.org/en/healthy-living/healthy-lifestyle/quit-smoking-tobacco"
        isMobile={props.isMobile}
      />
      <Flex align="center" mt="40px" mb="10px">
        <Image src={firstAidImage} h="32px" minW="32px" alt="" mr="10px" />
        <Text fontSize={17} color="gray.500" fontWeight="semibold">
          Medications - ask your doctor for cholesterol, blood pressure and
          glucose management
        </Text>
      </Flex>
    </Box>
  );
}

GxgCadRecommendationSection.propTypes = {
  isMobile: PropTypes.bool
};

GxgCadRecommendationSection.defaultProps = {
  isMobile: false
};
