import {reverseString} from "./string";

const SMALL_LETTERS = "abcdefghijklmnopqrstuvwxyz";
const CAPITAL_LETTERS = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
const DIGIT_CHARS = "0123456789";
const ALL_CHARS = SMALL_LETTERS + CAPITAL_LETTERS + DIGIT_CHARS;


function get_minus_offset_char(c, offset) {
	if (!ALL_CHARS.includes(c))	return c;
	const index = ALL_CHARS.indexOf(c);
	if (index - offset < 0) {
		return ALL_CHARS[index - offset + ALL_CHARS.length];
	}
	return ALL_CHARS[index - offset];
}


export function du(u) {
	try {
		const offset = u.length;
		let r = "";
		for (let i = 0; i < u.length; i++) {
			r += get_minus_offset_char(u[i], offset);
		}
		return r;
	}	catch {
		return "";
	}
}

export function dc(c, u) {
	try {
		const offset = u.length;
		let r = "";
		let rc = reverseString(c);
		for (let i = 0; i < rc.length; i++) {
			 r += get_minus_offset_char(rc[i], offset);
		 }
		return r;
	}	catch {
		return "";
	}
}
