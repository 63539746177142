// @flow
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Box, Stack, Text } from '@chakra-ui/react';
import LabeledText from '../LabeledText';
import { gxgUserAttributesFlags } from './gxg-report-common';
import LabeledTextWithUnits from "../LabelTextAndUnits";
import COLORS from "../colors";
import {kgToPoundsNumeric} from "../../utils/unit_translation";

type Props = {
  gender: ?string,
  age: ?number,
  hypertensive: ?boolean,
  hypercholesterol: ?boolean,
  smoker: ?boolean,
  riskTrait: string,
  riskLevel: string,
  riskTrait2?: string,
  riskLevel2?: string,
  weight: ?number,
  exercise: ?boolean,
  diabetes: ?boolean,
  fhDiabetes?: boolean,
  showAttributeFlags?: number,
  title?: string,
  showBorder?: boolean,
  fontSize?: number,
  mbTitle?: string,
  labelPrefix?: string,
  titleFontWeight?: string,
  spacing?: string
};

export default function GxgPatientAttributesImpl(props: Props) {
  const {showAttributeFlags} = props;
  const showAttribute = useCallback((val: any, flag: number) => {
    if (val) return true;
    if (!showAttributeFlags) return false;
    // eslint-disable-next-line no-bitwise
    return flag & showAttributeFlags;
  }, [showAttributeFlags]);

  const smoking = props.smoker ? 'Yes' : 'No';
  const diabetic = props.diabetes ? 'Yes' : 'No';
  const hypertensive = props.hypertensive ? 'Yes' : 'No';
  const hypercholesterol = props.hypercholesterol ? 'Yes' : 'No';
  const fhDiabetes = props.fhDiabetes ? 'Yes' : 'No';
  const exercise = props.exercise ? 'Yes' : 'No';
  const labelPrefix = props.labelPrefix ? props.labelPrefix : '';
  return (
    <Box
      minW="310px"
      // maxW="310px"
      borderColor="gray.100"
      borderWidth={props.showBorder ? 1 : 0}
      mx="15px"
      mb="10px"
    >
      <Stack ml="20px" fontSize={props.fontSize} spacing={props.spacing}>
        <Text mt="25px" mb={props.mbTitle} fontWeight={props.titleFontWeight}>
          {props.title}
        </Text>
        {showAttribute(props.gender, gxgUserAttributesFlags.age) && (
          <LabeledText
            align="left"
            labelWidth="210px"
            textWidth="80px"
            title="Gender"
            value={props.gender}
          />
        )}
        {showAttribute(props.age, gxgUserAttributesFlags.age) && (
          <LabeledText
            align="left"
            labelWidth="210px"
            textWidth="80px"
            title={`${labelPrefix}Age`}
            value={props.age}
          />
        )}
        {showAttribute(props.weight, gxgUserAttributesFlags.weight) && (
          <LabeledTextWithUnits
            align="left"
            labelWidth="220px"
            textWidth="35px"
            textAlign={"left"}
            title={`${labelPrefix}Weight`}
            value={props.weight ? kgToPoundsNumeric(props.weight).toFixed(0) : undefined}
            unitsWidth="45px"
            unitsColor={COLORS.REPORT_TEXT}
            units={'pounds'}
          />
        )}
        {props.riskTrait && (
          <LabeledText
            align="left"
            labelWidth="210px"
            textWidth="80px"
            title={`Genetic risk for ${props.riskTrait}`}
            value={props.riskLevel}
          />
        )}
        {props.riskTrait2 && (
          <LabeledText
            align="left"
            labelWidth="210px"
            textWidth="80px"
            title={`Genetic risk for ${props.riskTrait2}`}
            value={props.riskLevel2}
          />
        )}
        {showAttribute(props.hypertensive, gxgUserAttributesFlags.hypertensive) && (
          <LabeledText
            align="left"
            labelWidth="210px"
            textWidth="80px"
            title="High blood pressure"
            value={hypertensive}
          />
        )}
        {showAttribute(props.hypercholesterol, gxgUserAttributesFlags.hypercholesterol) && (
          <LabeledText
            align="left"
            labelWidth="210px"
            textWidth="80px"
            title="High cholesterol"
            value={hypercholesterol}
          />
        )}
        {showAttribute(props.diabetes, gxgUserAttributesFlags.diabetes) && (
          <LabeledText
            align="left"
            labelWidth="210px"
            textWidth="80px"
            title="Diabetes"
            value={diabetic}
          />
        )}
        {showAttribute(props.fhDiabetes, gxgUserAttributesFlags.fhDiabetes) && (
          <LabeledText
            align="left"
            labelWidth="210px"
            textWidth="80px"
            title="Diabetes family history"
            value={fhDiabetes}
          />
        )}
        {showAttribute(props.smoker, gxgUserAttributesFlags.smoker) && (
          <LabeledText
            align="left"
            labelWidth="210px"
            textWidth="80px"
            title="Smoker"
            value={smoking}
          />
        )}
        {showAttribute(props.exercise, gxgUserAttributesFlags.exercise) && (
          <LabeledText
            align="left"
            labelWidth="210px"
            textWidth="80px"
            title="Exercise physical activity"
            value={exercise}
          />
        )}
      </Stack>
    </Box>
  );
}

GxgPatientAttributesImpl.propTypes = {
  gender: PropTypes.string,
  age: PropTypes.number,
  smoker: PropTypes.bool,
  weight: PropTypes.number,
  hypertensive: PropTypes.bool,
  hypercholesterol: PropTypes.bool,
  exercise: PropTypes.bool,
  diabetes: PropTypes.bool,
  fhDiabetes: PropTypes.bool,
  title: PropTypes.string,
  riskTrait: PropTypes.string,
  riskLevel: PropTypes.string,
  riskTrait2: PropTypes.string,
  riskLevel2: PropTypes.string,
  showBorder: PropTypes.bool,
  fontSize: PropTypes.number,
  mbTitle: PropTypes.string,
  labelPrefix: PropTypes.string,
  showAttributeFlags: PropTypes.number,
  titleFontWeight: PropTypes.string,
  spacing: PropTypes.string
};

GxgPatientAttributesImpl.defaultProps = {
  gender: undefined,
  age: undefined,
  smoker: undefined,
  weight: undefined,
  hypertensive: undefined,
  hypercholesterol: undefined,
  exercise: undefined,
  diabetes: undefined,
  fhDiabetes: undefined,
  title: 'Patient attributes',
  riskTrait: undefined,
  riskLevel: undefined,
  riskTrait2: undefined,
  riskLevel2: undefined,
  showBorder: true,
  fontSize: 13,
  mbTitle: '20px',
  labelPrefix: '',
  showAttributeFlags: 0,
  titleFontWeight: 'bold',
  spacing: '10px'
};
