// @flow
import React from 'react';
import {Box} from '@chakra-ui/react';
import withNavigation from '../main/WithRoutes';
import cadImage from '../images/heart-attack.png';
import CadExplanatorySection from '../cad/CadExplanatorySection';
import CadRecommendationSection from '../cad/CadRecommendationSection';
import { getPatientId } from './capilots-utils';
import PatientAttributes from "../common/reports/PatientAttributes";

// $FlowFixMe
import cadReportCommon from '../cad/cad-report-common';
import {cadCalculatorUserAttributes} from "../common/reports/report-common";
import {
  getCadRiskColor,
  getCadRiskLevel,
  getRiskColorByPercentile,
  getRiskLevelByPercentile
} from "../common/risk-colors";
import {roundWithPrecision} from "../utils/numbers";
import {mmolL2mgdl} from "../utils/cholesterol";
import {isNullOrUndefined} from "url/util";
import {isSelfUrl} from "../main/routes";
import GeneticReportOrMobile from "../common/reports/GeneticReportOrMobile";
import withMobile from "../common/mobile/withMobile";
import Cad10YearsCalculator from "../cad/calculator/Cad10YearsCalculator";
import {addVisitDynamicAttrs} from "../api/capilots";
import InProgressImpl from "../common/InProgress";
import {
  getBasophillCountAttr, getBooleanAttr, getCReactiveProteinAttr, getCystatinCNumericAttr,
  getLpaNumericAttr, getMchAttr,
  getTestosteroneAttr, getUsualWalkingPaceAttr
} from "../common/calculator/calculator-attr";
import CadRiskSummaryPanel from "../cad/CadRiskSummaryPanel";

type Props = {
  location: any,
  params: any,
  visit: Object,
  age: ?number,
  printing: boolean,
  patientId: ?string,
  cad: Object,
  pca: Array<number>,
  loading: boolean,
  isMobile: boolean,
  isClinicianView: boolean,
  onReload: any
};

type State = {
  age: ?number,
  overallRiskPercentile: ?number,
  risk10yrs: ?number,
  risk10yrsPercentile: number,
  lifetimeRisk: ?number,
  lifetimeRiskPercentile: number,
  lifetimeRisk95: ?number
};

export class PilotCadRiskReportImpl extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    (this: any).setAge = this.setAge.bind(this);
    (this: any).onInitOverallRiskPercentile = this.onInitOverallRiskPercentile.bind(this);
    (this: any).getPartialDataAlert = this.getPartialDataAlert.bind(this);
    (this: any).onInitialRisks = this.onInitialRisks.bind(this);
    (this: any).onSavePatientAttrs = this.onSavePatientAttrs.bind(this);
    (this: any).getPrintingBoolAttrs = this.getPrintingBoolAttrs.bind(this);
    (this: any).getPrintingNumericAttrs = this.getPrintingNumericAttrs.bind(this);
    (this: any).getPrintingSelectionAttrs = this.getPrintingSelectionAttrs.bind(this);

    this.patientId = props.patientId ?? getPatientId(props.params, props.location);
    this.state = {
      age: this.props.age,
      overallRiskPercentile: undefined,
      risk10yrs: undefined,
      risk10yrsPercentile: 0,
      lifetimeRisk: undefined,
      lifetimeRiskPercentile: 0,
      lifetimeRisk95: undefined
    };
  }

  state: State;

  async componentDidMount() {
    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({
      age: this.props.age    });
  }
  setAge(newAge: number) {
    this.setState({
      age: newAge
    });
  }
  
  onInitOverallRiskPercentile(percentile: number) {
    this.setState({
      overallRiskPercentile: percentile
    });
  }

  patientId: string;

  getPartialDataAlert() {
    if (isSelfUrl(this.props.location.pathname)) {
      return 'The overall risk assessment is based on partial data.\nFill in your attributes in order to get a more accurate overall risk assessment.'
    }
    return 'NOTE: the calculated overall risk is based on partially available clinical data and therefore may be less accurate.'
  }

  onInitialRisks(risks: Object){
    this.setState({
      risk10yrs: risks.risk,
      risk10yrsPercentile: risks.percentile,
      lifetimeRisk: risks.lifetimeRisk,
      lifetimeRiskPercentile: risks.lifetimeRiskPercentile,
      lifetimeRisk95: risks.lifetimeRisk95
    })
  }
  async onSavePatientAttrs(attrs: Object) {
    const CALCULATOR_ATTR_TO_DB= {
      "smoking": "smoker",
      "seen_doctor_gp_for_nerves_anxiety_tension_or_depression": "seen_doctor_for_nerves_etc",
    };
    const dbAttrs = {}
    Object.keys(attrs).forEach(attr => {
      if (attr in CALCULATOR_ATTR_TO_DB) {
        dbAttrs[CALCULATOR_ATTR_TO_DB[attr]] = attrs[attr];
      } else {
        dbAttrs[attr] = attrs[attr];
      }
    });

    await addVisitDynamicAttrs(this.patientId, dbAttrs);
    if (this.props.onReload) this.props.onReload();
    return true;
  }

  getPrintingBoolAttrs() {
    return [
      getBooleanAttr("statins", "Treated with statins", this.props.visit.statins, false, false, undefined, false),
      getBooleanAttr("asthma", "Asthma", this.props.visit.asthma, false, false, undefined, false),
      getBooleanAttr("loneliness_isolation", "Loneliness, isolation", this.props.visit.loneliness_isolation, false, false, undefined, false),
      getBooleanAttr("seen_doctor_gp_for_nerves_anxiety_tension_or_depression", "Seen GP for nerves, anxiety, tension or depression", this.props.visit.seen_doctor_for_nerves_etc, false, false, undefined, false),
      getBooleanAttr("mood_swings", "Mood swings", this.props.visit.mood_swings, false, false, undefined, false)
    ]
  }

  getPrintingNumericAttrs() {
		return [
			getCystatinCNumericAttr(this.props.visit.cystatin_c),
			getLpaNumericAttr(this.props.visit.lpa),
			getBasophillCountAttr(this.props.visit.basophill_count),
			getCReactiveProteinAttr(this.props.visit.crp),
			getTestosteroneAttr(this.props.visit.testosterone),
			getMchAttr(this.props.visit.mch)
		]
  }

  getPrintingSelectionAttrs() {
    return [
  		getUsualWalkingPaceAttr(this.props.visit.usual_walking_pace, false)
    ]
  }


  render() {
    const {isMobile} = this.props;
    const oneBasedPercentile = this.props.cad
      ? this.props.cad.cad.percentile + 1
      : 0;
    const quintile = this.props.cad
      ? Math.floor(this.props.cad.cad.percentile / 20)
      : 0;

    const showAlert = !this.props.loading && this.props.visit && (
        isNullOrUndefined(this.props.visit.sex) ||
        !this.props.visit.dbp ||
        !this.props.visit.sbp ||
        !this.props.visit.age ||
        !this.props.visit.hdl ||
        !this.props.visit.ldl ||
        !this.props.visit.tc
    );
    const alertMessage = showAlert ? this.getPartialDataAlert() : undefined;

    const printingPatientAttr = (!this.props.loading && this.props.printing) ? (
      <Box w={"420px"} borderWidth={1} borderColor="gray.100">
      <PatientAttributes
          gender={this.props.visit.sex ? "Male": "Female"}
          age={this.props.visit.age}
          sbp={this.props.visit.sbp}
          dbp={this.props.visit.dbp}
          hdl={roundWithPrecision(mmolL2mgdl(this.props.visit.hdl), 0)}
          ldl={roundWithPrecision(mmolL2mgdl(this.props.visit.ldl), 0)}
          tc={roundWithPrecision(mmolL2mgdl(this.props.visit.tc), 0)}
          riskTrait="CAD"
          riskLevel={getRiskLevelByPercentile(this.props.cad.cad.percentile + 1)}
          bpTreated={this.props.visit.bp_treated}
          diabetes={this.props.visit.diabetes}
          smoker={this.props.visit.smoker}
          orderLabs={false}
          labelPrefix="Current "
          showBorder={false}
          showAttributeFlags={cadCalculatorUserAttributes}
          spacing={"6px"}
          additionalNumericAttrs={this.getPrintingNumericAttrs()}
          additionalBoolAttrs={this.getPrintingBoolAttrs()}
          additionalSelectionAttrs={this.getPrintingSelectionAttrs()}
        >
        </PatientAttributes>
      </Box>) : null;

    let cadChartsSection = null;
    let risk10yrs = '';
    if (this.state.risk10yrs) {
      risk10yrs = this.state.risk10yrs > 30 ? "> 30%" : `${this.state.risk10yrs.toFixed(1)}%`;
    }
    const riskLevel = getCadRiskLevel(this.state.risk10yrs).replace(" risk", "");
    const riskColor = getCadRiskColor(this.state.risk10yrs ?? 0);
    const lifetimeRiskColor = getRiskColorByPercentile(this.state.lifetimeRiskPercentile);
    const lifetimeRiskLevel = getRiskLevelByPercentile(this.state.lifetimeRiskPercentile);

    const riskSummaryPanel = <CadRiskSummaryPanel
      age={this.props.age}
      risk10yrs={risk10yrs}
      riskColor={riskColor}
      riskLevel={riskLevel}
      lifetimeRisk={this.state.lifetimeRisk}
      lifetimeRisk95={this.state.lifetimeRisk95}
      lifetimeRiskColor={lifetimeRiskColor}
      lifetimeRiskLevel={lifetimeRiskLevel}
      printing={this.props.printing}
      isMobile={isMobile}
      />

    const calculatorSection = this.props.cad && this.props.visit ? <Box
        mt="20px"
        sx={{
          '@media print': {
            pageBreakBefore: 'always;'
          }
        }}
      >
        <Cad10YearsCalculator
          riskQuintile={quintile}
          riskPercentile={oneBasedPercentile}
          stdizedOverallRiskCad={this.props.cad.cad.stdized_overall_risk_cad}
          sbp={this.props.visit.sbp}
          dbp={this.props.visit.dbp}
          tc={this.props.visit.tc}
          cystatinC={this.props.visit.cystatin_c}
          lpa={this.props.visit.lpa}
          apob={this.props.visit.apob}
          basophillCount={this.props.visit.basophill_count}
          crp={this.props.visit.crp}
          pcs={this.props.pca}
          age={this.props.age}
          hdl={this.props.visit.hdl}
          ldl={this.props.visit.ldl}
          smoking={this.props.visit.smoker}
          bpTreated={this.props.visit.bp_treated}
          statins={this.props.visit.statins}
          asthma={this.props.visit.asthma}
          t2d={this.props.visit.diabetes}
          lonelinessIsolation={this.props.visit.loneliness_isolation}
          seenDoctorForNervesAnxietyTensionDepression={this.props.visit.seen_doctor_for_nerves_etc}
          sex={this.props.visit.sex ? 1 : 0}
          usualWalkingPace={this.props.visit.usual_walking_pace}
          mch={this.props.visit.mch}
          testosterone={this.props.visit.testosterone}
          moodSwings={this.props.visit.mood_swings}
          cadNnProbs={this.props.visit.cad_nn_prob}
          cadRfProbs={this.props.visit.cad_rf_prob}
          callbackRiskChange={null}
          callbackInitialRisks={this.onInitialRisks}
          callbackOnSave={this.props.isClinicianView ? this.onSavePatientAttrs : null}
          printing={this.props.printing}
          showTreatmentRecommendations={this.props.isClinicianView && !this.props.printing}
          allowPatientAttrsEditing={true}
          allowPatientAttrsFirstMissingEditing={true}
          lifetimeBy75={true}
        />
      </Box> : <InProgressImpl label="Loading..."/>;
    return (
      <Box sx={{ '@media print': { '@page': { size: 'landscape;' } } }} >
        {/*<ActionButton*/}
        {/*  mt={"-6px"}*/}
        {/*  ml="6%"*/}
        {/*  onClick={this.onSwitchLayout}*/}
        {/*  borderWidth={0}*/}
        {/*  borderColor={COLORS.REPORT_TEXT}*/}
        {/*  name="Switch layout"*/}
        {/*  bg={"transparent"}*/}
        {/*  color={COLORS.REPORT_TEXT}*/}
        {/*  fontSize={12}*/}
        {/*/>*/}
        <GeneticReportOrMobile
          isDemo={false}
          reportTitle="CORONARY ARTERY DISEASE RISK REPORT"
          configTrait="cad"
          loading={this.props.loading}
          titleImage={cadImage}
          patientNum={this.props.visit ? this.props.visit.patient_num : null}
          patientName={this.props.visit ? this.props.visit.patient_name : null}
          geneticKitId={this.props.visit ? this.props.visit.kit_id : ''}
          printing={this.props.printing}
          riskPercentile={this.props.cad ? this.props.cad.cad.percentile : null}
          geneticRiskInfoText={cadReportCommon.GENETIC_RISK_INFO_TEXT}
          alertMessage={alertMessage}
          showAlert={showAlert}
          patientAttributesSection={printingPatientAttr}
          chartsSection={cadChartsSection}
          calculatorSection={calculatorSection}
          recommendationSection={!this.props.isClinicianView ? <CadRecommendationSection isMobile={isMobile}/> : null}
          explanatorySection={<CadExplanatorySection isMobile={isMobile} isClinicianView={this.props.isClinicianView}/>}
          topRightPane={riskSummaryPanel}
        />
      </Box>
    );
  }
}

// $FlowFixMe
export default withNavigation(withMobile(PilotCadRiskReportImpl));
