// @flow
import React from 'react';

import {
  Box,
  Text,
  Flex,
  Spacer,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay
} from '@chakra-ui/react';
import COLORS from '../common/colors';
import ActionButton from '../common/ActionButton';
import withNavigation from '../main/WithRoutes';

type Props = {
  remainingTime: number,
  onContinue: any,
  onSignOut: any
};

function IdlePromptImpl(props: Props) {
  return (
    <Modal blockScrollOnMount isOpen>
      <ModalOverlay />
      <ModalContent color={COLORS.REPORT_TEXT}>
        <ModalBody color={COLORS.REPORT_TEXT}>
          <Box>
            <Box bg={COLORS.REPORT_TEXT} h="50px" borderRadius="3px" mt="10px">
              <Flex>
                <Spacer />
                <Text color="white" fontWeight="bold" mt="14px" fontSize={16}>
                  Automatic Logoff
                </Text>
                <Spacer />
              </Flex>
            </Box>
            <Text mt="30px">You have been idle for a while.</Text>
            <Flex align="center" mt="10px">
              <Text>You will be logged out in</Text>
              <Box
                borderColor={COLORS.REPORT_TEXT_GRAY}
                borderWidth={1}
                ml="10px"
              >
                <Text m="10px" fontWeight="bold">
                  {props.remainingTime}
                </Text>
              </Box>
              <Text fontSize={12} ml="5px">
                sec
              </Text>
            </Flex>
            <Flex mt="30px" mb="20px">
              <Spacer />
              <ActionButton onClick={props.onContinue} name="Keep Working" />
              <ActionButton
                onClick={props.onSignOut}
                name="Sign Out"
                ml="20px"
              />
              <Spacer />
            </Flex>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default withNavigation(IdlePromptImpl);
