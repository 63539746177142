// @flow
export function or2rr(or: number, prevalence: number) {
  return or / (1 - prevalence + prevalence * or);
}

// default of prevalencePrognosticFactor is 0.5 for the case of PRS assuming normal distribution of PRS
export function calcAbsRisk(
  or: number,
  prevalenceEntireCohort: number,
  prevalencePrognosticFactor: number = 0.5
) {
  const rr = or2rr(or, prevalenceEntireCohort);
  return (
    (prevalenceEntireCohort * rr) /
    (prevalencePrognosticFactor * rr + (1 - prevalencePrognosticFactor))
  );
}

export function calcAvgPrevalence(prevalences: Array<number>) {
  let prevalence = 0;
  for (let i = 0; i < prevalences.length; i += 1) {
    prevalence += prevalences[i];
  }
  prevalence /= prevalences.length;
  return prevalence;
}
