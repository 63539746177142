// @flow
import React from 'react';
import PropTypes from 'prop-types';
import {
  Tooltip,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Box
} from '@chakra-ui/react';
import COLORS from './colors';

type Props = {
  trigger: any,
  text: string,
  header?: ?string,
  footer?: ?string
};

export default function PopoverInfo(props: Props) {
  return (
    <Popover placement="right" >
      <Tooltip
        hasArrow
        label="Click for more information"
        bg={COLORS.REPORT_TEXT}
        color="white"
      >
        <Box
          m={0}
          _hover={{
            cursor: 'pointer'
          }}
        >
          <PopoverTrigger>{props.trigger}</PopoverTrigger>
        </Box>
      </Tooltip>
      <PopoverContent minW={"500px"}>
        <PopoverArrow bg="black" />
        <PopoverCloseButton color="white" />
        {props.header && (
          <PopoverHeader
            bg={COLORS.REPORT_TEXT}
            color="white"
            fontWeight="bold"
          >
            {props.header}
          </PopoverHeader>
        )}
        <PopoverBody bg={COLORS.REPORT_TEXT} color="white" textAlign="justify" minW={"500px"}>
          {props.text}
          {props.footer && (
            <PopoverFooter fontSize={12} fontStyle="italic">
              {props.footer}
            </PopoverFooter>
          )}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

PopoverInfo.propTypes = {
  header: PropTypes.string,
  footer: PropTypes.string
};

PopoverInfo.defaultProps = {
  header: null,
  footer: null
};
