// @flow
export function isNullOrUndefined(val: ?any) {
  return val === undefined || val === null;
}

export function isNanOrNullOrUndefined(val: ?number) {
  if (isNullOrUndefined(val)) return true;
  return Number.isNaN(val);
}

export function undefinedForNan(val: ?number) {
  return Number.isNaN(val) ? undefined : val;
}

export function roundWithPrecision(
  val: ?number,
  digitsAfterDecimalPoint: number = 0
) {
  if (!val) return val;
  if (!digitsAfterDecimalPoint) return Math.round(val);
  const power = 10 ** digitsAfterDecimalPoint;
  return Math.round(val * power) / power;
}

export function toFixedHandleUndefined(
  val: ?number,
  digitsAfterDecimalPoint: number = 0
) {
  if (!val) return val;
  return val.toFixed(digitsAfterDecimalPoint);
}

export function boolToOneOrZero(value: boolean) {
  return value ? 1 : 0;
}