// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Spacer, Text } from '@chakra-ui/react';

type Props = {
  riskTitle?: string,
  mx?: string
};

export default function CalculatorHeaderImpl(props: Props) {
  return (
    <Flex mt="10px" bg="gray.200" mx={props.mx} align="center">
      <Spacer />
      <Text my="10px" fontWeight="semibold">
        {props.riskTitle}
      </Text>
      <Spacer />
      {/* <Image src={cautionImage} h="24px" alt="" mx="20px" /> */}
    </Flex>
  );
}

CalculatorHeaderImpl.propTypes = {
  riskTitle: PropTypes.string,
  mx: PropTypes.string
};

CalculatorHeaderImpl.defaultProps = {
  riskTitle: 'CLINICAL FACTORS EFFECT ON OVERALL RISK',
  mx: '1%'
};
