// @flow
import React from 'react';
import { Box, Image, Flex, Stack, Text } from '@chakra-ui/react';
import COLORS from '../colors';

type Props = {
  reportTitle: string,
  image: any,
  isDemo: ?boolean,
  hideRiskReportText: ?boolean
};

export default function MobileReportHeaderBarImpl(props: Props) {
  const reportTitle = props.reportTitle.replace("RISK REPORT", "");
  return (
    <Box>
      <Flex
        borderRadius="10px"
        borderWidth={0}
        borderColor={COLORS.REPORT_TEXT}
        align="center"
        ml="20px"
      >
        {props.image && <Image src={props.image} w="28px" h="28px" mr="20px" />}
        <Box mb="10px">
          <Text fontSize={18} color="gray.600" mt="10px">
            {reportTitle}
          </Text>
          <Flex>
            <Stack spacing="10px" mb="5px">
              {!props.hideRiskReportText && <Text fontSize={14} color="gray.600">
                RISK REPORT
              </Text>}
              {props.isDemo &&<Text fontSize={14} color="red.200">
                (DEMO)
              </Text>}
            </Stack>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
}
