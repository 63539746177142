// @flow
import React from 'react';
import { Box } from '@chakra-ui/react';

import withNavigation from '../../main/WithRoutes';
import htnGaugeImage from '../../images/blood-pressure-gauge-light.png';
import BpCalculator from '../../bp/calculator/BpCalculator';
import DemoClinicalData1 from '../data/clinical1.json';
import DemoClinicalData2 from '../data/clinical2.json';
import DemoClinicalData3 from '../data/clinical3.json';
import DemoClinicalData4 from '../data/clinical4.json';
import DemoClinicalData5 from '../data/clinical5.json';
import {bpCalculatorUserAttribute, getFileNameOnly} from '../../common/reports/report-common';
import { getPatientId } from '../filters';
import BpExplanatorySection from '../../bp/BpExplanatorySection';
import BpRecommendationSection from '../../bp/BpRecommendationSection';
import BpAssocRiskText from '../../bp/BpAssocRiskText';
import { bpReportStrings } from '../../bp/bp-report-common';
import PatientAttributes from "../../common/reports/PatientAttributes";
import {getRiskLevelByPercentile} from "../../common/risk-colors";
import {roundWithPrecision} from "../../utils/numbers";
import MobileBpCalculator from "../../bp/calculator/MobileBpCalculator";
import GeneticReportOrMobile from "../../common/reports/GeneticReportOrMobile";
import withMobile from "../../common/mobile/withMobile";

type Props = {
  location: any,
  params: any,
  printing: boolean,
  isMobile: boolean
};
type State = {};

export class DemoBpRiskReportImpl extends React.Component<Props, State> {
  static loadDemoData() {
    // $FlowFixMe[prop-missing]
    const requireContext: any = require.context('./data', true, /\.json$/);
    const json = {};
    requireContext.keys().forEach(key => {
      const obj = requireContext(key);
      const simpleKey = getFileNameOnly(key);
      json[simpleKey] = obj;
    });
    return json;
  }

  constructor(props: Props) {
    super(props);
    this.patientId = getPatientId(props.params, props.location);

    const bps = DemoBpRiskReportImpl.loadDemoData();
    const key = `bp${this.patientId}`;
    this.bp = bps[key] === undefined ? bps.bp1 : bps[key];
    this.clinical = DemoClinicalData1;
    if (this.patientId === 2) this.clinical = DemoClinicalData2;
    if (this.patientId === 3) this.clinical = DemoClinicalData3;
    if (this.patientId === 4) this.clinical = DemoClinicalData4;
    if (this.patientId === 5) this.clinical = DemoClinicalData5;
    if (this.patientId > 5)
      this.clinical = {
        sex: undefined,
        age: undefined,
        bmi: undefined
      };
    this.state = {};
  }
  state: State;

  patientId: number;
  clinical: Object;
  bp: Object;

  render() {
    const {isMobile} = this.props;

    const calculator = <Box
        mt="20px"
        sx={{
          '@media print': {
            pageBreakBefore: 'always;'
          }
        }}
      >
        {isMobile && <MobileBpCalculator
          sex={this.clinical.sex}
          age={this.clinical.age}
          bmi={this.clinical.bmi}
          sbpScore={this.bp.sbp.insights.stdized_overall_risk_sbp}
          dbpScore={this.bp.dbp.insights.stdized_overall_risk_dbp}
          sbpLinReg={this.bp.sbp.insights.lin_reg}
          dbpLinReg={this.bp.dbp.insights.lin_reg}
          sbpQuartile={this.bp.sbp.quartile}
          sbpPercentile={this.bp.sbp.percentile + 1}
          dbpQuartile={this.bp.dbp.quartile}
          dbpPercentile={this.bp.dbp.percentile + 1}
          callbackOnChange={null}
          orderLabs={false}
          printing={this.props.printing}
        />}
        {!isMobile && <BpCalculator
          sex={this.clinical.sex}
          age={this.clinical.age}
          bmi={this.clinical.bmi}
          sbpScore={this.bp.sbp.insights.stdized_overall_risk_sbp}
          dbpScore={this.bp.dbp.insights.stdized_overall_risk_dbp}
          sbpLinReg={this.bp.sbp.insights.lin_reg}
          dbpLinReg={this.bp.dbp.insights.lin_reg}
          sbpQuartile={this.bp.sbp.quartile}
          sbpPercentile={this.bp.sbp.percentile + 1}
          dbpQuartile={this.bp.dbp.quartile}
          dbpPercentile={this.bp.dbp.percentile + 1}
          callbackOnChange={null}
          orderLabs={false}
          printing={this.props.printing}
        />}
      </Box>

    return (
      <Box sx={{ '@media print': { '@page': { size: 'landscape;' } } }}>
        <GeneticReportOrMobile
          reportTitle="HYPERTENSION RISK REPORT"
          configTrait="bp"
          titleImage={htnGaugeImage}
          patientNum={this.patientId}
          geneticKitId={`OD000${this.patientId}`}
          riskPercentile={[this.bp.sbp.percentile, this.bp.dbp.percentile]}
          personChartTitle={[
            'Systolic Blood Pressure (SBP)',
            'Diastolic Blood Pressure (DBP)'
          ]}
          geneticRiskInfoText={[
            bpReportStrings.GENETIC_RISK_SBP_INFO_TEXT,
            bpReportStrings.GENETIC_RISK_DBP_INFO_TEXT
          ]}
          patientAttributesSection={this.props.printing &&
            <Box w={"420px"} borderWidth={1} borderColor="gray.100">
              <PatientAttributes
                  gender={this.clinical.sex ? "Male": "Female"}
                  age={this.clinical.age}
                  bmi={roundWithPrecision(this.clinical.bmi,1)}
                  riskTrait="HTN (systolic)"
                  riskLevel={getRiskLevelByPercentile(this.bp.sbp.percentile + 1)}
                  riskTrait2="HTN (diastolic)"
                  riskLevel2={getRiskLevelByPercentile(this.bp.dbp.percentile + 1)}
                  orderLabs={false}
                  labelPrefix="Current "
                  showBorder={false}
                  showAttributeFlags={bpCalculatorUserAttribute}
                  spacing={"7px"}
                >
                </PatientAttributes>
              </Box>
          }
          topMiddlePane={[
            <BpAssocRiskText trait="systolic" />,
            <BpAssocRiskText trait="diastolic" />
          ]}
          topRightPane={'patient'}
          chartsSection={null}
          calculatorSection={calculator}
          recommendationSection={
            <Box
              sx={{
                '@media print': {
                  pageBreakBefore: 'always'
                }
              }}
            >
              <BpRecommendationSection isMobile={isMobile} />
            </Box>
          }
          explanatorySection={<BpExplanatorySection isMobile={isMobile} />}
        />
      </Box>
    );
  }
}

export default withNavigation(withMobile(DemoBpRiskReportImpl));
