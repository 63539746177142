// @flow
import React from 'react';
import {cmToFeetInch, kgToPounds} from '../utils/unit_translation';
import { mmolL2mgdl } from '../utils/cholesterol';
import { mmolmol2percents } from '../utils/a1c';
import IdentifiedPatientAttributes from "../common/reports/IdentifiedPatientAttributes";

type Props = {
  patient: Object,
  visit: Object,
  onCancel: any,
  onApply: any
};

export default function PilotLabEditPatient(props: Props) {
  const heightFeetInch = cmToFeetInch(props.patient.height)
  return (
    <IdentifiedPatientAttributes
      title="UPDATE PATIENT"
      riskTrait={null}
      labelPrefix=""
      adding={false}
      name={props.patient.name}
      kitId={props.patient.kitId}
      gender={props.patient.sex}
      birthYear={props.patient.birthYear}
      birthMonth={props.patient.birthMonth}
      feet={heightFeetInch.feet}
      inch={heightFeetInch.inch}
      externalId={props.patient.externalId}
      email={props.patient.email}
      phone={props.patient.phone}
      weight={props.visit.weight ? kgToPounds(props.visit.weight) : undefined}
      hdl={props.visit.hdl ? mmolL2mgdl(props.visit.hdl) : undefined}
      ldl={props.visit.ldl ? mmolL2mgdl(props.visit.ldl) : undefined}
      tc={props.visit.tc ? mmolL2mgdl(props.visit.tc) : undefined}
      sbp={props.visit.sbp}
      dbp={props.visit.dbp}
      a1c={props.visit.a1c ? mmolmol2percents(props.visit.a1c) : undefined}
      bpTreated={props.visit.bp_treated}
      fhDiabetes={props.visit.fh_diabetes}
      diabetes={props.visit.diabetes}
      smoker={props.visit.smoker}
      onCancel={props.onCancel}
      onApply={props.onApply}
    />
  );
}
