// @flow
import React from 'react';
import MobileRiskChartsSection from '../../common/reports/MobileRiskChartsSection';

type Props = {
  percentile: number,
  quintile: number,
  oddsRatios: Array<number>,
  deciles: Array<number>
};

export default function MobileHdlChartsSection(props: Props) {
  return (
    <MobileRiskChartsSection
      percentile={99 - props.percentile}
      quintile={props.quintile}
      deciles={props.deciles.reverse()}
      oddsRatios={props.oddsRatios.reverse()}
      minY={0}
      maxY={40}
      yTickInterval={5}
      shortTrait="HDL"
      longTrait="low levels of HDL"
    />
  );
}
