import queryString from "query-string";
import {ENV, get_env_from_domain} from "../../config/env_config";
import {ROOT_DOMAIN} from "../../config/constants";

var url = require('url');

export function linkFromPresigned(presignedUrl: string, isLocalHost: boolean) {
	const parsedUrl = url.parse(presignedUrl);
	const searchParams = queryString.parse(parsedUrl.search);

	const folder = parsedUrl.pathname.slice(parsedUrl.pathname.lastIndexOf('/') + 1, -5);
	const accessKey = searchParams.AWSAccessKeyId;
	const signature = encodeURIComponent(searchParams.Signature);
	const expires = searchParams.Expires;
	const xAmz = isLocalHost ? undefined : encodeURIComponent(searchParams["x-amz-security-token"]);

	let domain = `${ENV}.${ROOT_DOMAIN}`;
	if (isLocalHost) domain = "localhost:3000";
	else if (ENV === 'prod') domain = ROOT_DOMAIN;
	const protocol = isLocalHost ? 'http' : 'https';
	let link = `${protocol}://${domain}/bc?f=${folder}&a=${accessKey}&s=${signature}&e=${expires}`;
	if (xAmz) {
		link += `&x=${xAmz}`;
	}
	return link;
}

export function getFolderFromPresigned(presignedUrl: string) {
	const parsedUrl = url.parse(presignedUrl)
	return parsedUrl.pathname.slice(parsedUrl.pathname.lastIndexOf('/') + 1).slice(0, -5);
}

export function presignedFromLink(linkUrl: string) {
	const parsedUrl = url.parse(linkUrl)
	const env = get_env_from_domain(parsedUrl.hostname);
	const searchParams = queryString.parse(parsedUrl.search);

	const folder = searchParams.f
	const accessKey = searchParams.a
	const signature = encodeURIComponent(searchParams.s);
	const expires = searchParams.e
	if (searchParams.x) {
		const xAmz = encodeURIComponent(searchParams.x)
		return `https://opendna-workflow-${env}.s3.amazonaws.com/barcode/${folder}/${folder}.json?AWSAccessKeyId=${accessKey}&Signature=${signature}&content-type=application%2Fjson&x-amz-security-token=${xAmz}&Expires=${expires}`
	}
	return `https://opendna-workflow-${env}.s3.amazonaws.com/barcode/${folder}/${folder}.json?AWSAccessKeyId=${accessKey}&Signature=${signature}&content-type=application%2Fjson&Expires=${expires}`
}


