// @flow
import React from 'react';
import { Box } from '@chakra-ui/react';
import withNavigation from '../main/WithRoutes';
import cadImage from '../images/heart-attack.png';
import CadExplanatorySection from '../cad/CadExplanatorySection';
import CadChartsSection from '../cad/CadChartsSection';
import { getPatientId } from '../capilots/capilots-utils';
import GxgPatientAttributes from "../common/reports/GxgPatientAttributes";

// $FlowFixMe
import cadReportCommon from '../cad/cad-report-common';
import {gxgCadCalculatorUserAttributes} from "../common/reports/gxg-report-common";
import {getRiskLevelByPercentile} from "../common/risk-colors";
import {isNullOrUndefined} from "url/util";
import cadConsumerLogReg from "./cad-consumer-log-reg.json";
import CadRecommendationSection from "../cad/CadRecommendationSection";
import GeneticReportOrMobile from "../common/reports/GeneticReportOrMobile";
import ConsumerCadCalculator from "../cad/calculator/ConsumerCadCalculator";
import withMobile from "../common/mobile/withMobile";

type Props = {
  location: any,
  params: any,
  visit: Object,
  age: ?number,
  printing: boolean,
  cad: Object,
  pca: Array<number>,
  loading: boolean,
  isMobile: boolean
};

type State = {
  age: ?number,
  overallRiskPercentile: ?number
};


const CONSUMER_OVERALL_RISK_ODDS_RATIOS = [
  0.05738654799981458,
  0.1503156590616064,
  0.294327271954399,
  0.43376761481029247,
  0.6219295040422655,
  1.0,
  1.4293936514486247,
  1.9746445562797348,
  2.9502932848659773,
  4.606219697997351,
  8.573716494924517
]
const CONSUMER_OVERALL_RISK_PREVALENCE = [
  0.0016540748357101345,
  0.004336552217453505,
  0.008583307255576914,
  0.012786123032904148,
  0.0196253744020743,
  0.032904148783977114,
  0.04452789699570815,
  0.06540301309848451,
  0.10269134477825465,
  0.18123295632348338
]

export class ConsumerCadRiskReportImpl extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    (this: any).setAge = this.setAge.bind(this);
    (this: any).onInitOverallRiskPercentile = this.onInitOverallRiskPercentile.bind(this);
    this.patientId = getPatientId(props.params, props.location);
    this.state = {
      age: this.props.age,
      overallRiskPercentile: undefined
    };
  }

  state: State;

  setAge(newAge: number) {
    this.setState({
      age: newAge
    });
  }
  
  onInitOverallRiskPercentile(percentile: number) {
    this.setState({
      overallRiskPercentile: percentile
    });
  }

  patientId: number;

  render() {
    console.log(this.props.loading)
    const {isMobile} = this.props;
    const oneBasedPercentile = this.props.cad
      ? this.props.cad.cad.percentile + 1
      : 0;
    const quintile = this.props.cad
      ? Math.floor(this.props.cad.cad.percentile / 20)
      : 0;

    const showAlert = !this.props.loading && this.props.visit && (
        isNullOrUndefined(this.props.visit.sex) ||
        !this.props.visit.weight ||
        !this.props.visit.height ||
        !this.props.visit.age
    );
    const alertMessage = showAlert ? 'The current overall risk assessment is based on partial data.\nFill in your attributes in order to get a more accurate overall risk assessment.' : undefined;

    let overallPercentile = 0;
    if (this.state.overallRiskPercentile !== undefined && this.state.overallRiskPercentile !== null) {
      overallPercentile = this.state.overallRiskPercentile + 1;
      console.log(`overallPercentile is ${overallPercentile}`)
    }

    const printingPatientAttr = this.props.printing && (
      <Box w={"420px"} borderWidth={1} borderColor="gray.100">
        <GxgPatientAttributes
            gender={this.props.visit.sex ? "Male": "Female"}
            age={this.props.visit.age}
            bmi={this.props.visit.bmi}
            hypertensive={!!this.props.visit.hypertension}
            hypercholesterol={!!this.props.visit.hypercholesterol}
            exercise={this.props.visit.exercise}
            riskTrait="CAD"
            riskLevel={getRiskLevelByPercentile(this.props.cad.cad.percentile + 1)}
            diabetes={this.props.visit.diabetes}
            smoker={this.props.visit.smoker}
            labelPrefix="Current "
            showBorder={false}
            showAttributeFlags={gxgCadCalculatorUserAttributes}
          />
        </Box>);

    let cadChartsSection = null;
    if (this.props.cad) {
      cadChartsSection = <CadChartsSection
        percentile={this.props.cad.cad.percentile}
        quintile={quintile}
        oddsRatios={this.props.cad.cad.odds_ratio_list}
        deciles={this.props.cad.cad.deciles}
        age={this.state.age}
        showHeartAge={false}
        overallRiskPercentile={overallPercentile}
        overallRiskDeciles={CONSUMER_OVERALL_RISK_PREVALENCE}
        overallRiskOddsRatios={CONSUMER_OVERALL_RISK_ODDS_RATIOS}
        printing={this.props.printing}
        isMobile={isMobile}
      />;
    }
    const calculatorSection = this.props.cad && this.props.visit ? <Box
        mt="20px"
        sx={{
          '@media print': {
            pageBreakBefore: 'always;'
          }
        }}
      >
        <ConsumerCadCalculator
          sex={this.props.visit.sex ? 1 : 0}
          age={this.props.age}
          weight={this.props.visit.weight}
          height={this.props.visit.height}
          hypertensive={this.props.visit.hypertension}
          hypercholesterol={this.props.visit.hypercholesterol}
          exercise={this.props.visit.exercise}
          diabetes={this.props.visit.diabetes}
          smoker={this.props.visit.smoker}
          cadPercentile={oneBasedPercentile}
          cadScore={this.props.cad.cad.overall_risk_cad}
          stdizedCadScore={this.props.cad.cad.stdized_overall_risk_cad}
          printing={this.props.printing}
          callbackInitRiskPercentile={this.onInitOverallRiskPercentile}
          logReg={cadConsumerLogReg}
          geneticRiskPercentile={oneBasedPercentile}
        />
      </Box> : null;

    return (
      <Box sx={{ '@media print': { '@page': { size: 'landscape;' } } }}>
        <GeneticReportOrMobile
          isDemo={false}
          reportTitle="CORONARY ARTERY DISEASE RISK REPORT"
          configTrait="cad"
          loading={this.props.loading}
          titleImage={cadImage}
          patientNum={this.props.visit ? this.props.visit.patient_num : null}
          patientName={this.props.visit ? this.props.visit.patient_name : null}
          geneticKitId={this.props.visit ? this.props.visit.kit_id : ''}
          printing={this.props.printing}
          riskPercentile={this.props.cad ? oneBasedPercentile : null}
          geneticRiskInfoText={cadReportCommon.GENETIC_RISK_INFO_TEXT}
          alertMessage={alertMessage}
          showAlert={showAlert}
          patientAttributesSection={printingPatientAttr}
          chartsSection={cadChartsSection}
          calculatorSection={calculatorSection}
          recommendationSection={<CadRecommendationSection isMobile={isMobile}/>}
          explanatorySection={<CadExplanatorySection isMobile={isMobile} />}
        />
      </Box>
    );
  }
}

// $FlowFixMe
export default withNavigation(withMobile(ConsumerCadRiskReportImpl));
