// @flow
import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import COLORS from './colors';

type Props = {
  yTitle: string,
  title: string,
  risk: number,
  riskColor: string,
  avgRisk: number,
  seriesName: string,
  height: number,
  minY: number,
  maxY: number,
  yTickInterval: number,
  printing?: boolean
};

type State = {};

export default class RiskVsAverageChartImpl extends React.Component<
  Props,
  State
> {
  static defaultProps = {
    printing: false
  }
  constructor(props: Props) {
    super(props);
    (this: any).prepareDatasets = this.prepareDatasets.bind(this);
    (this: any).getRoundedYtickValue = this.getRoundedYtickValue.bind(this);

    this.yUnit = this.props.yTickInterval / 5;
    this.yTickRoundingFactor = this.getYtickRoundingFactor();
  }

  getRoundedYtickValue(val: number) {
    return (
      Math.round(val * this.yTickRoundingFactor) / this.yTickRoundingFactor
    );
  }

  getYtickRoundingFactor() {
    let multiple = 1;
    for (let i = 0; i < 5; i += 1) {
      multiple = 10 ** i;
      if (
        this.props.yTickInterval ===
        Math.floor(this.props.yTickInterval * multiple) / multiple
      )
        break;
    }
    return multiple;
  }

  prepareDatasets() {
    return [
      {
        type: 'column',
        name: this.props.seriesName,
        // groupPadding: 0.2,
        dataLabels: {
          enabled: true,
          align: 'center',
          color: COLORS.REPORT_TEXT,
          formatter() {
            return `${this.y.toFixed(1)}%`;
          }
        },
        data: [
          {
            y: this.props.risk,
            color: this.props.riskColor
          },
          {
            y: this.props.avgRisk,
            color: COLORS.GRAY_192
          }
        ]
      }
    ];
  }

  yUnit: number;
  yTickRoundingFactor: number;

  render() {
    const yTicks: Array<number> = [];
    for (
      let i = this.props.minY;
      i <= this.props.maxY;
      i += this.props.yTickInterval
    ) {
      yTicks.push(this.getRoundedYtickValue(i));
    }
    const options = {
      credits: {
        enabled: false
      },
      chart: {
        backgroundColor: 'transparent',
        height: this.props.height,
        width: this.props.printing ? 420 : this.props.height * 1.4,
        marginLeft: 50
      },
      title: {
        text: this.props.title,
        style: {
          color: COLORS.HIGHCHARTS_TITLE,
          fontSize: '12px',
          fontWeight: 'bold'
        }
      },
      xAxis: {
        categories: ['Your Risk', 'Average Risk'],
        margin: 0,
        title: {
          text: 'risk comparison'
        }
      },
      yAxis: {
        min: this.props.minY,
        max: this.props.maxY,
        title: {
          text: this.props.yTitle
        },
        tickPositioner() {
          return yTicks;
        },
        opposite: false
      },
      legend: false,
      series: this.prepareDatasets(),
      tooltip: {
        formatter() {
          return `</b>${this.x}</b>, ${this.series.name}=<b>${this.y.toFixed(
              1
          )}%</b>`;
        }
      },
      plotOptions: {
        series: {
          animation: !this.props.printing
        }
      }
    };
    return <HighchartsReact highcharts={Highcharts} options={options} />;
  }
}
