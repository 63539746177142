// @flow
import React from 'react';
import COLORS from '../../common/colors';
import {
  getShortComparativeOddsRatioText,
  getSummaryOddsRatioText
} from '../../common/reports/report-common';
import traitRanges from '../../common/reports/trait-ranges.json';
import { calcRelativeRiskForCallback } from '../../common/relative-risk';
import { mgdl2mmolL, mmolL2mgdl } from '../../utils/cholesterol';
import upRed from '../../images/up-red.png';
import downGreen from '../../images/down-green.png';
import { mmolmol2percents, percents2mmolmol } from '../../utils/a1c';
import { isNanOrNullOrUndefined } from '../../utils/numbers';
import {
  getDefaultAge,
  getDefaultGender,
  getDefaultSbp,
  getDefaultDbp,
  getDefaultTc,
  getDefaultHdl,
  getDefaultA1c,
  getDefaultBmi,
  getMinBmi,
  getMaxBmi
} from '../../common/reports/trait-defaults';
import getQuantile from '../../utils/quantile';
import type { RiskChange } from '../../common/reports/risk-change';

type Props = {
  t2dQuintile: number,
  t2dPercentile: number,
  age: ?number,
  bmi: ?number,
  sex: number,
  sbp: number,
  dbp: number,
  tc: number,
  hdl: number,
  a1c: number,
  fhDiabetes: boolean,
  smoker: boolean,
  bpTreated: boolean,
  t2dScore: number,
  stdizedT2dScore: ?number,
  t2dLogReg: Object,
  t2dLogRegA1c: Object,
  pcs: Array<number>,
  callbackInitRiskPercentile: any
};

type State = {
  playingEnabled: boolean,
  ageSliderEnabled: boolean,
  bmiSliderEnabled: boolean,
  tcSliderEnabled: boolean,
  hdlSliderEnabled: boolean,
  a1cSliderEnabled: boolean,
  gender: ?string,
  sex: number,
  age: number,
  bmi: number,
  sbp: number,
  dbp: number,
  tc: number,
  hdl: number,
  a1c: number,
  fhDiabetes: boolean,
  smoker: boolean,
  bpTreated: boolean,
  current: ?number,
  expected: ?number,
  shortT2dText: string,
  longT2dText: string,
  summaryChangeLine: string,
  t2dTrendIcon: any,
  changeColor: string,
  currentPercentile: ?number,
  expectedPercentile: ?number,
  shortT2dTextPercentile: string,
  longT2dTextPercentile: string,
  summaryChangeLinePercentile: string,
  t2dTrendIconPercentile: any,
  changeColorPercentile: string
};

export default function withT2dCalculator(WrappedComponent: any) {
  return class WithT2dCalculator extends React.Component<Props, State> {
    static getRiskText(quintile: number) {
      if (quintile === 0) return 'Low';
      if (quintile < 4) return 'Moderate';
      return 'High';
    }

    static getSwitchColor() {
      return 'red';
    }

    static getSwitchText(isChecked: boolean) {
      return isChecked ? 'YES' : 'NO';
    }

    static getLongDiffText(
      expected: number,
      current: number,
      trait: string,
      units: string,
      fixed: number
    ) {
      if (expected === current) return '';
      const diff = expected - current;
      const trend = diff > 0 ? 'increase' : 'decrease';
      return `\u2022 ${trait} ${trend} of ${Math.abs(diff).toFixed(
        fixed
      )} ${units}\n`;
    }

    static getBinaryStateText(checked: boolean) {
      return checked ? 'checked' : 'unchecked';
    }

    static standardize(val: number, colName: string, logReg: Object) {
      const standardization = logReg.col_standardization[colName];
      // if (!standardization || !standardization.mean) {
      //   debugger;
      // }
      return (val - standardization.mean) / standardization.stddev;
    }

    constructor(props: Props) {
      super(props);
      (this: any).onAgeSliderChange = this.onAgeSliderChange.bind(this);
      (this: any).getAgeDiffText = this.getAgeDiffText.bind(this);
      (this: any).onBmiSliderChange = this.onBmiSliderChange.bind(this);
      (this: any).getBmiDiffText = this.getBmiDiffText.bind(this);
      (this: any).getSliderBmiColor = this.getSliderBmiColor.bind(this);
      (this: any).onSbpSliderChange = this.onSbpSliderChange.bind(this);
      (this: any).getSbpDiffText = this.getSbpDiffText.bind(this);
      (this: any).getSliderSbpColor = this.getSliderSbpColor.bind(this);
      (this: any).onTcSliderChange = this.onTcSliderChange.bind(this);
      (this: any).getTcDiffText = this.getTcDiffText.bind(this);
      (this: any).getSliderTcColor = this.getSliderTcColor.bind(this);
      (this: any).getHdlDiffText = this.getHdlDiffText.bind(this);
      (this: any).onHdlSliderChange = this.onHdlSliderChange.bind(this);
      (this: any).getSliderHdlColor = this.getSliderHdlColor.bind(this);
      (this: any).getA1cDiffText = this.getA1cDiffText.bind(this);
      (this: any).onA1cSliderChange = this.onA1cSliderChange.bind(this);
      (this: any).getSliderA1cColor = this.getSliderA1cColor.bind(this);
      (this: any).onFhDiabetesSwitchChange = this.onFhDiabetesSwitchChange.bind(
        this
      );
      (this: any).onSmokerSwitchChange = this.onSmokerSwitchChange.bind(this);
      (this: any).onBpTreatedSwitchChange = this.onBpTreatedSwitchChange.bind(
        this
      );
      (this: any).onGenderSelected = this.onGenderSelected.bind(this);
      (this: any).onCurrentAgeChange = this.onCurrentAgeChange.bind(this);
      (this: any).onCurrentTcChange = this.onCurrentTcChange.bind(this);
      (this: any).onCurrentHdlChange = this.onCurrentHdlChange.bind(this);
      (this: any).onCurrentSbpChange = this.onCurrentSbpChange.bind(this);
      (this: any).onCurrentDbpChange = this.onCurrentDbpChange.bind(this);
      (this: any).onCurrentA1cChange = this.onCurrentA1cChange.bind(this);
      (this: any).onCurrentBmiChange = this.onCurrentBmiChange.bind(this);

      (this: any).onCurrentBpTreatedChange = this.onCurrentBpTreatedChange.bind(
        this
      );
      (this: any).onCurrentFhDiabetesChange = this.onCurrentFhDiabetesChange.bind(
        this
      );
      (this: any).onCurrentSmokerChange = this.onCurrentSmokerChange.bind(this);

      (this: any).updatePlayingEnabled = this.updatePlayingEnabled.bind(this);

      (this: any).runLogReg = this.runLogReg.bind(this);

      (this: any).calcExpectedT2dRiskChange = this.calcExpectedT2dRiskChange.bind(
        this
      );
      (this: any).onResetAttributes = this.onResetAttributes.bind(this);

      this.resetAge = getDefaultAge(this.props.age);
      this.resetSbp = getDefaultSbp(this.props.sbp);
      this.resetDbp = getDefaultDbp(this.props.dbp);
      this.resetTc = getDefaultTc(this.props.tc);
      this.resetHdl = getDefaultHdl(this.props.hdl);
      this.resetBmi = getDefaultBmi(this.props.bmi);
      this.resetA1c = getDefaultA1c(this.props.a1c);
      this.currentFhDiabetes = !!this.props.fhDiabetes;
      this.currentSmoker = !!this.props.smoker;
      this.currentBpTreated = !!this.props.bpTreated;
      this.currentAge = this.props.age;
      this.currentSbp = this.props.sbp;
      this.currentDbp = this.props.dbp;
      this.currentTc = this.props.tc ? mmolL2mgdl(this.props.tc) : undefined;
      this.currentHdl = this.props.hdl ? mmolL2mgdl(this.props.hdl) : undefined;
      this.currentA1c = this.props.a1c
        ? mmolmol2percents(this.props.a1c)
        : undefined;
      this.currentBmi = this.props.bmi;
      const defaultGender = getDefaultGender(this.props.sex);
      const playingEnabled = !!defaultGender && !!this.currentAge;
      const initPercentile = this.calcInitialOverallRiskPercentile(
        this.props.sex ? this.props.sex : 0
      );
      if (this.props.callbackInitRiskPercentile) {
        this.props.callbackInitRiskPercentile(initPercentile);
      }

      this.state = {
        sex: this.props.sex ? this.props.sex : 0,
        gender: defaultGender,
        age: this.resetAge,
        sbp: this.resetSbp,
        dbp: this.currentDbp ? this.currentDbp : traitRanges.dbp.default,
        tc: this.resetTc,
        hdl: this.resetHdl,
        a1c: this.resetA1c,
        bmi: this.resetBmi,
        fhDiabetes: this.currentFhDiabetes,
        smoker: this.currentSmoker,
        bpTreated: this.currentBpTreated,
        playingEnabled,
        ageSliderEnabled: playingEnabled && !!this.props.age,
        bmiSliderEnabled: playingEnabled && !!this.props.bmi,
        tcSliderEnabled: playingEnabled && !!this.props.tc,
        hdlSliderEnabled: playingEnabled && !!this.props.hdl,
        a1cSliderEnabled: playingEnabled && !!this.props.a1c,
        current: 0,
        expected: 0,
        shortT2dText: '',
        longT2dText: '',
        summaryChangeLine: '',
        t2dTrendIcon: undefined,
        changeColor: 'transparent',
        currentPercentile: initPercentile || 0,
        expectedPercentile: initPercentile || 0,
        shortT2dTextPercentile: '',
        longT2dTextPercentile: '',
        summaryChangeLinePercentile: '',
        t2dTrendIconPercentile: undefined,
        changeColorPercentile: 'transparent'
      };
      this.recentRelativeRisk = 0;
      this.recentOddsRatio = 1;
      this.recentDiffPercentile = undefined;
    }

    onCalculatorChange() {
      const change = this.calcExpectedT2dRiskChange();
      this.setState({
        current: change.relative.current,
        expected: change.relative.expected,
        shortT2dText: change.relative.shortText,
        longT2dText: change.relative.longText,
        summaryChangeLine: change.relative.summaryChangeLine,
        t2dTrendIcon: change.relative.trendIcon,
        changeColor: change.relative.changeColor,
        currentPercentile: change.overall.current,
        expectedPercentile: change.overall.expected,
        shortT2dTextPercentile: change.overall.shortText,
        longT2dTextPercentile: change.overall.longText,
        summaryChangeLinePercentile: change.overall.summaryChangeLine,
        t2dTrendIconPercentile: change.overall.trendIcon,
        changeColorPercentile: change.overall.changeColor
      });
      this.updatePlayingEnabled();
    }

    onGenderSelected(gender: string) {
      let sex = 0;
      if (gender === 'male') {
        sex = 1;
      }
      if (gender === 'female') {
        sex = 0;
      }
      this.setState({
        gender,
        sex
      });
    }

    onCurrentAgeChange(newAge: number) {
      this.currentAge = newAge;
      if (!this.currentAge || Number.isNaN(this.currentAge)) return;
      this.resetAge = this.currentAge;
      this.setState({ age: this.currentAge }, () => this.onResetAttributes());
    }

    onCurrentTcChange(newTc: number) {
      this.currentTc = newTc;
      if (!this.currentTc || Number.isNaN(this.currentTc)) return;
      this.resetTc = this.currentTc;
      this.setState({ tc: this.currentTc }, () => this.onResetAttributes());
    }

    onCurrentHdlChange(newHdl: number) {
      this.currentHdl = newHdl;
      if (!this.currentHdl || Number.isNaN(this.currentHdl)) return;
      this.resetHdl = this.currentHdl;
      this.setState({ hdl: this.currentHdl }, () => this.onResetAttributes());
    }

    onCurrentSbpChange(newSbp: number) {
      this.currentSbp = newSbp;
      if (!this.currentSbp || Number.isNaN(this.currentSbp)) return;
      this.resetSbp = this.currentSbp;
      this.setState(
        {
          sbp: this.currentSbp
        },
        () => this.onResetAttributes()
      );
    }

    onCurrentBmiChange(newBmi: number) {
      this.currentBmi = newBmi;
      if (!this.currentBmi || Number.isNaN(this.currentBmi)) return;
      this.resetBmi = this.currentBmi;
      this.setState(
        {
          bmi: this.currentBmi
        },
        () => this.onResetAttributes()
      );
    }

    onCurrentA1cChange(newA1c: number) {
      this.currentA1c = newA1c;
      this.resetA1c = this.currentA1c;
      if (!this.currentA1c || Number.isNaN(this.currentA1c)) return;
      this.setState(
        {
          a1c: this.currentA1c
        },
        () => this.onResetAttributes()
      );
    }

    onCurrentDbpChange(newDbp: number) {
      this.currentDbp = newDbp;
      if (!this.currentDbp || Number.isNaN(this.currentDbp)) return;
      this.resetDbp = this.currentDbp;
      this.setState(
        {
          dbp: this.currentDbp
        },
        () => this.onResetAttributes()
      );
    }

    onCurrentBpTreatedChange(bpTreated: boolean) {
      this.currentBpTreated = bpTreated;
      this.setState({
        bpTreated: this.currentBpTreated
      });
    }

    onCurrentFhDiabetesChange(diabetes: boolean) {
      this.currentFhDiabetes = diabetes;
      this.setState({
        fhDiabetes: this.currentFhDiabetes
      });
    }

    onCurrentSmokerChange(smoker: boolean) {
      this.currentSmoker = smoker;
      this.setState({
        smoker: this.currentSmoker
      });
    }

    onFhDiabetesSwitchChange(isChecked: boolean) {
      this.setState(
        {
          fhDiabetes: isChecked
        },
        this.onCalculatorChange
      );
    }

    onSmokerSwitchChange(isChecked: boolean) {
      this.setState(
        {
          smoker: isChecked
        },
        this.onCalculatorChange
      );
    }

    onBpTreatedSwitchChange(isChecked: boolean) {
      this.setState(
        {
          bpTreated: isChecked
        },
        this.onCalculatorChange
      );
    }

    onAgeSliderChange(newAge: number) {
      this.setState(
        {
          age: newAge
        },
        this.onCalculatorChange
      );
    }

    onBmiSliderChange(newBmi: number) {
      this.setState(
        {
          bmi: newBmi
        },
        this.onCalculatorChange
      );
    }

    onSbpSliderChange(newSbp: number) {
      this.setState(
        {
          sbp: newSbp
        },
        this.onCalculatorChange
      );
    }

    onTcSliderChange(newTc: number) {
      this.setState(
        {
          tc: newTc
        },
        this.onCalculatorChange
      );
    }

    onHdlSliderChange(newHdl: number) {
      this.setState(
        {
          hdl: newHdl
        },
        this.onCalculatorChange
      );
    }

    onA1cSliderChange(newA1c: number) {
      this.setState(
        {
          a1c: newA1c
        },
        this.onCalculatorChange
      );
    }

    onResetAttributes() {
      this.setState(
        {
          age: this.resetAge,
          bmi: this.resetBmi,
          sbp: this.resetSbp,
          hdl: this.resetHdl,
          tc: this.resetTc,
          a1c: this.resetA1c,
          bpTreated: this.currentBpTreated,
          smoker: this.currentSmoker,
          fhDiabetes: this.currentFhDiabetes
        },
        this.onCalculatorChange
      );
    }

    getOverallRiskChangeDetails(expected: ?number, current: ?number) {
      if (!expected) {
        return WithT2dCalculator.getNoChangeDetails();
      }
      const diff: number =
        expected === undefined ||
        current === undefined ||
        expected === null ||
        current === null
          ? 0
          : expected - current;

      const trend: string = diff >= 0 ? 'increase' : 'decrease';
      let color = COLORS.REPORT_TEXT;
      let diffIcon: any = null;
      if (diff > 0) {
        color = COLORS.RED_STATUS;
        diffIcon = upRed;
      } else if (diff < 0) {
        color = COLORS.GREEN_STATUS;
        diffIcon = downGreen;
      }

      if (diff !== this.recentDiffPercentile) {
        // console.log(`diff percentile is ${diff}`);
        this.recentDiffPercentile = diff;
      }
      const shortRiskChange = `${diff}%`;
      const longText = this.getTextOfChangedAttributes(diff);
      const summaryChangeLine = `${trend} your overall risk to develop type 2 diabetes`;
      const riskChange: RiskChange = {
        current,
        expected,
        shortText: shortRiskChange,
        longText,
        summaryChangeLine,
        trendIcon: diffIcon,
        changeColor: color
      };
      return riskChange;
    }

    static getNoChangeDetails() {
      const riskChange: RiskChange = {
        current: 0,
        expected: 0,
        shortText: '',
        longText: '',
        summaryChangeLine: '',
        trendIcon: undefined,
        changeColor: 'transparent'
      };
      return riskChange;
    }

    getRelativeRiskChangeDetails(expected: ?number, current: ?number) {
      if (!expected) {
        return WithT2dCalculator.getNoChangeDetails();
      }
      const diff: number =
        expected === undefined ||
        current === undefined ||
        expected === null ||
        current === null
          ? 0
          : expected - current;
      const trend: string = diff >= 0 ? 'increase' : 'decrease';
      const oddsRatio = expected / (current || 1);
      let shortRiskChange = getShortComparativeOddsRatioText(oddsRatio);
      const isSignificantDiff =
        shortRiskChange !== '-0%' && shortRiskChange !== '+0%';
      let color = COLORS.REPORT_TEXT;
      let summaryChangeLine = '';
      if (isSignificantDiff) {
        summaryChangeLine = `${trend} your relative risk to develop type 2 diabetes mellitus ${getSummaryOddsRatioText(
          oddsRatio
        )}`;
        color = diff > 0 ? COLORS.RED_STATUS : COLORS.GREEN_STATUS;
      } else {
        shortRiskChange = '0%';
      }
      const longText = isSignificantDiff
        ? this.getTextOfChangedAttributes(diff)
        : '';

      let diffIcon: any = null;
      if (isSignificantDiff && diff > 0) diffIcon = upRed;
      if (isSignificantDiff && diff < 0) diffIcon = downGreen;

      const relativeRisk = calcRelativeRiskForCallback(oddsRatio);
      if (
        Math.abs(relativeRisk - this.recentRelativeRisk) >= 1 ||
        Math.abs(oddsRatio - this.recentOddsRatio) >= 0.01
      ) {
        this.recentRelativeRisk = relativeRisk;
        this.recentOddsRatio = oddsRatio;
      }
      const riskChange: RiskChange = {
        current,
        expected,
        shortText: shortRiskChange,
        longText,
        summaryChangeLine,
        trendIcon: diffIcon,
        changeColor: color
      };
      return riskChange;
    }

    getTextOfChangedAttributes(diff: number) {
      let longText = '';
      if (diff !== 0) {
        longText = 'Changes to the following attributes:\n';
        longText += WithT2dCalculator.getLongDiffText(
          this.state.age,
          this.resetAge,
          'age',
          'years',
          0
        );
        longText += WithT2dCalculator.getLongDiffText(
          this.state.bmi,
          this.resetBmi,
          'BMI',
          'units',
          1
        );
        longText += WithT2dCalculator.getLongDiffText(
          this.state.tc,
          this.resetTc,
          'total cholesterol',
          'mg/dL',
          1
        );
        longText += WithT2dCalculator.getLongDiffText(
          this.state.hdl,
          this.resetHdl,
          'HDL',
          'mg/dL',
          1
        );
        longText += WithT2dCalculator.getLongDiffText(
          this.state.sbp,
          this.resetSbp,
          'SBP',
          'mmHg',
          0
        );
        longText += WithT2dCalculator.getLongDiffText(
          this.state.a1c,
          this.resetA1c,
          'A1C',
          '%',
          1
        );
        longText += WithT2dCalculator.getLongDiffTextForBinary(
          this.state.bpTreated,
          this.currentBpTreated,
          'treated for high blood pressure'
        );
        longText += WithT2dCalculator.getLongDiffTextForBinary(
          this.state.fhDiabetes,
          this.currentFhDiabetes,
          'diabetes family history'
        );
        longText += WithT2dCalculator.getLongDiffTextForBinary(
          this.state.smoker,
          this.currentSmoker,
          'smoker'
        );
      }
      return longText;
    }

    static getLongDiffTextForBinary(
      expected: boolean,
      current: boolean,
      trait: string
    ) {
      if (expected === current) return '';
      return `\u2022 changing ${trait} from ${this.getBinaryStateText(
        current
      )} to ${this.getBinaryStateText(expected)}\n`;
    }

    getSbpDiffText() {
      if (this.state.sbp === this.resetSbp) return 'current';
      const diff: number = this.state.sbp - this.resetSbp;
      const sign: string = diff > 0 ? '+' : '-';
      return `${sign} ${Math.abs(diff).toFixed(1)} mmHg`;
    }

    getAgeDiffText() {
      if (this.state.age > this.resetAge)
        return `+ ${this.state.age - this.resetAge} years`;
      return 'current';
    }

    getTcDiffText() {
      if (this.state.tc === this.resetTc) return 'current';
      const diff: number = this.state.tc - this.resetTc;
      const sign: string = diff > 0 ? '+' : '-';
      return `${sign} ${Math.abs(diff).toFixed(1)} mg/dL\t`;
    }

    getHdlDiffText() {
      if (this.state.hdl === this.resetHdl) return 'current';
      const diff: number = this.state.hdl - this.resetHdl;
      const sign: string = diff > 0 ? '+' : '-';
      return `${sign} ${Math.abs(diff).toFixed(1)} mg/dL\t`;
    }

    getA1cDiffText() {
      if (this.state.a1c === this.resetA1c) return 'current';
      const diff: number = this.state.a1c - this.resetA1c;
      const sign: string = diff > 0 ? '+' : '-';
      return `${sign} ${Math.abs(diff).toFixed(1)}%\t`;
    }

    getBmiDiffText() {
      if (this.state.bmi === this.resetBmi) return 'current';
      const diff: number = this.state.bmi - this.resetBmi;
      const sign: string = diff > 0 ? '+' : '-';
      return `${sign} ${Math.abs(diff).toFixed(1)} units\t`;
    }

    getSliderHdlColor() {
      const lowThresh = this.props.sex ? mmolL2mgdl(1) : mmolL2mgdl(1.3);
      if (this.state.hdl < lowThresh) return COLORS.RED_STATUS;
      return COLORS.GREEN_STATUS;
    }

    getSliderA1cColor() {
      if (this.state.a1c < mmolmol2percents(38.8)) return COLORS.GREEN_STATUS;
      if (this.state.a1c > mmolmol2percents(46.4)) return COLORS.RED_STATUS;
      return COLORS.YELLOW_STATUS;
    }

    getSliderSbpColor() {
      if (this.state.sbp < 90) return COLORS.RED_STATUS;
      if (this.state.sbp > 140) return COLORS.RED_STATUS;
      if (this.state.sbp > 130) return COLORS.YELLOW_STATUS;
      return COLORS.GREEN_STATUS;
    }

    getSliderBmiColor() {
      if (this.state.bmi > 30) return COLORS.RED_STATUS;
      if (this.state.bmi < 18.5 || this.state.bmi > 25)
        return COLORS.YELLOW_STATUS;
      return COLORS.GREEN_STATUS;
    }

    getSliderTcColor() {
      if (this.state.tc < mmolL2mgdl(5.18)) return COLORS.GREEN_STATUS;
      if (this.state.tc > mmolL2mgdl(6.18)) return COLORS.RED_STATUS;
      return COLORS.YELLOW_STATUS;
    }

    updatePlayingEnabled() {
      const ageSliderEnabled = !isNanOrNullOrUndefined(this.currentAge);
      const bmiSliderEnabled = !isNanOrNullOrUndefined(this.currentBmi);
      const tcSliderEnabled = !isNanOrNullOrUndefined(this.currentTc);
      const hdlSliderEnabled = !isNanOrNullOrUndefined(this.currentHdl);
      const a1cSliderEnabled = !isNanOrNullOrUndefined(this.currentA1c);
      const playingEnabled = !!this.state.gender && ageSliderEnabled;
      this.setState({
        playingEnabled,
        ageSliderEnabled,
        bmiSliderEnabled,
        tcSliderEnabled,
        hdlSliderEnabled,
        a1cSliderEnabled
      });
    }

    recentDiffPercentile: ?number;
    recentRelativeRisk: number;
    recentOddsRatio: number;
    currentAge: ?number;
    currentSbp: ?number;
    currentDbp: ?number;
    currentTc: ?number;
    currentHdl: ?number;
    currentA1c: ?number;
    currentBmi: ?number;
    resetAge: number;
    resetSbp: number;
    resetDbp: number;
    resetTc: number;
    resetHdl: number;
    resetA1c: number;
    resetBmi: number;
    currentBpTreated: boolean;
    currentFhDiabetes: boolean;
    currentSmoker: boolean;

    calcInitialOverallRiskPercentile(sex: number) {
      const current = this.runLogReg(
        !!this.props.a1c,
        sex,
        this.resetAge,
        this.resetBmi,
        this.resetSbp,
        this.resetDbp,
        mgdl2mmolL(this.resetTc),
        mgdl2mmolL(this.resetHdl),
        percents2mmolmol(this.resetA1c),
        this.currentFhDiabetes ? 1 : 0,
        this.currentSmoker ? 1 : 0,
        this.currentBpTreated ? 1 : 0,
        this.props.t2dScore,
        this.props.stdizedT2dScore
      );
      return current.percentile;
    }

    calcExpectedT2dRiskChange() {
      const current = this.runLogReg(
        this.state.a1cSliderEnabled,
        this.state.sex,
        this.resetAge,
        this.resetBmi,
        this.resetSbp,
        this.resetDbp,
        mgdl2mmolL(this.resetTc),
        mgdl2mmolL(this.resetHdl),
        percents2mmolmol(this.resetA1c),
        this.currentFhDiabetes ? 1 : 0,
        this.currentSmoker ? 1 : 0,
        this.currentBpTreated ? 1 : 0,
        this.props.t2dScore
      );
      const expected = this.runLogReg(
        this.state.a1cSliderEnabled,
        this.state.sex,
        this.state.age,
        this.state.bmi,
        this.state.sbp,
        this.resetDbp,
        mgdl2mmolL(this.state.tc),
        mgdl2mmolL(this.state.hdl),
        percents2mmolmol(this.state.a1c),
        this.state.fhDiabetes ? 1 : 0,
        this.state.smoker ? 1 : 0,
        this.state.bpTreated ? 1 : 0,
        this.props.t2dScore
      );
      return {
        relative: this.getRelativeRiskChangeDetails(
          expected ? expected.odds : undefined,
          current ? current.odds : undefined
        ),
        overall: this.getOverallRiskChangeDetails(
          expected ? expected.percentile : undefined,
          current ? current.percentile : undefined
        )
      };
    }

    runLogReg(
      useA1c: boolean,
      sex: number,
      age: number,
      bmi: number,
      sbp: number,
      dbp: number,
      tc: number,
      hdl: number,
      a1c: number,
      fhDiabetes: number,
      smoker: number,
      bpTreated: number,
      t2dScore: number = this.props.t2dScore,
      stdizedT2dScore: ?number = this.props.stdizedT2dScore
    ) {
      let stdizedPrs: number = 0;
      const logReg = useA1c ? this.props.t2dLogRegA1c : this.props.t2dLogReg;
      if (stdizedT2dScore === null || stdizedT2dScore === undefined) {
        stdizedPrs = WithT2dCalculator.standardize(
          t2dScore,
          'overall_risk_t2d',
          logReg
        );
      } else {
        stdizedPrs = stdizedT2dScore;
      }
      const s: number =
        sex * logReg.coefficients.sex +
        WithT2dCalculator.standardize(age, 'age', logReg) *
          logReg.coefficients.age +
        WithT2dCalculator.standardize(bmi, 'bmi', logReg) *
          logReg.coefficients.bmi +
        WithT2dCalculator.standardize(sbp, 'sbp', logReg) *
          logReg.coefficients.sbp +
        (useA1c
          ? 0
          : WithT2dCalculator.standardize(dbp, 'dbp', logReg) *
            logReg.coefficients.dbp) +
        WithT2dCalculator.standardize(tc, 'tc', logReg) *
          logReg.coefficients.tc +
        WithT2dCalculator.standardize(hdl, 'hdl', logReg) *
          logReg.coefficients.hdl +
        (useA1c
          ? WithT2dCalculator.standardize(a1c, 'a1c', logReg) *
            logReg.coefficients.a1c
          : 0) +
        stdizedPrs * logReg.coefficients.overall_risk_t2d +
        fhDiabetes * logReg.coefficients.diabetes_family_history +
        (useA1c ? 0 : smoker * logReg.coefficients.smoking) +
        bpTreated * logReg.coefficients.bp_treated +
        logReg.constant;

      // console.log(`s is ${s}`)
      // PCA is not used as it causes too large offsets
      // for (let i = 0; i < this.props.pcs.length; i += 1) {
      //   s +=
      //     WithT2dCalculator.standardize(
      //       this.props.pcs[i],
      //       `pc${i + 1}`,
      //       logReg
      //     ) * logReg.coefficients[`pc${i + 1}`];
      // }
      let percentile = null;
      if ('percentiles' in logReg) {
        percentile = getQuantile(s, logReg.percentiles);
      }
      const expS = Math.exp(s);
      // console.log(
      //   `T2D: s id ${s}, std_prs is ${stdizedPrs}, percentile is ${percentile ||
      //     ''}, A1C used: ${useA1c ? 'yes' : 'no'}`
      // );

      const probability = expS / (1 + expS);
      return {
        odds: probability / (1 - probability),
        percentile
      };
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          onGenderSelected={this.onGenderSelected}
          onCurrentAgeChange={this.onCurrentAgeChange}
          onAgeSliderChange={this.onAgeSliderChange}
          getAgeDiffText={this.getAgeDiffText}
          onCurrentBmiChange={this.onCurrentBmiChange}
          onBmiSliderChange={this.onBmiSliderChange}
          getBmiDiffText={this.getBmiDiffText}
          getSliderBmiColor={this.getSliderBmiColor}
          onCurrentTcChange={this.onCurrentTcChange}
          onTcSliderChange={this.onTcSliderChange}
          getTcDiffText={this.getTcDiffText}
          getSliderTcColor={this.getSliderTcColor}
          onCurrentHdlChange={this.onCurrentHdlChange}
          onHdlSliderChange={this.onHdlSliderChange}
          getHdlDiffText={this.getHdlDiffText}
          getSliderHdlColor={this.getSliderHdlColor}
          onCurrentA1cChange={this.onCurrentA1cChange}
          onA1cSliderChange={this.onA1cSliderChange}
          getA1cDiffText={this.getA1cDiffText}
          getSliderA1cColor={this.getSliderA1cColor}
          onCurrentSbpChange={this.onCurrentSbpChange}
          onSbpSliderChange={this.onSbpSliderChange}
          getSbpDiffText={this.getSbpDiffText}
          getSliderSbpColor={this.getSliderSbpColor}
          onCurrentDbpChange={this.onCurrentDbpChange}
          onCurrentBpTreatedChange={this.onCurrentBpTreatedChange}
          onCurrentSmokerChange={this.onCurrentSmokerChange}
          onCurrentFhDiabetesChange={this.onCurrentFhDiabetesChange}
          onBpTreatedSwitchChange={this.onBpTreatedSwitchChange}
          onFhDiabetesSwitchChange={this.onFhDiabetesSwitchChange}
          onSmokerSwitchChange={this.onSmokerSwitchChange}
          onResetAttributes={this.onResetAttributes}
          getSwitchColor={WithT2dCalculator.getSwitchColor}
          getSwitchText={WithT2dCalculator.getSwitchText}
          age={this.state.age}
          bmi={this.state.bmi}
          tc={this.state.tc}
          hdl={this.state.hdl}
          sbp={this.state.sbp}
          dbp={this.state.dbp}
          a1c={this.state.a1c}
          gender={this.state.gender}
          minBmi={getMinBmi(this.state.bmi)}
          maxBmi={getMaxBmi(this.state.bmi)}
          currentAge={this.currentAge}
          currentBmi={this.currentBmi}
          currentTc={this.currentTc}
          currentHdl={this.currentHdl}
          currentSbp={this.currentSbp}
          currentDbp={this.currentDbp}
          currentA1c={this.currentA1c}
          resetAge={this.resetAge}
          resetBmi={this.resetBmi}
          resetTc={this.resetTc}
          resetHdl={this.resetHdl}
          resetSbp={this.resetSbp}
          resetA1c={this.resetA1c}
          relativeRisk={this.recentRelativeRisk}
          relativeRiskOddsRatio={this.recentOddsRatio}
          current={this.state.current}
          expected={this.state.expected}
          shortT2dText={this.state.shortT2dText}
          longT2dText={this.state.longT2dText}
          summaryChangeLine={this.state.summaryChangeLine}
          t2dTrendIcon={this.state.t2dTrendIcon}
          changeColor={this.state.changeColor}
          currentPercentile={this.state.currentPercentile}
          expectedPercentile={this.state.expectedPercentile}
          shortT2dTextPercentile={this.state.shortT2dTextPercentile}
          longT2dTextPercentile={this.state.longT2dTextPercentile}
          summaryChangeLinePercentile={this.state.summaryChangeLinePercentile}
          t2dTrendIconPercentile={this.state.t2dTrendIconPercentile}
          changeColorPercentile={this.state.changeColorPercentile}
          t2dQuintile={this.props.t2dQuintile}
          t2dPercentile={this.props.t2dPercentile}
          fhDiabetes={this.state.fhDiabetes}
          smoker={this.state.smoker}
          bpTreated={this.state.bpTreated}
          playingEnabled={this.state.playingEnabled}
          ageSliderEnabled={this.state.ageSliderEnabled}
          bmiSliderEnabled={this.state.bmiSliderEnabled}
          tcSliderEnabled={this.state.tcSliderEnabled}
          hdlSliderEnabled={this.state.hdlSliderEnabled}
          a1cSliderEnabled={this.state.a1cSliderEnabled}
        />
      );
    }
  };
}
