// @flow
import React from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Box, Text, Flex, Spacer } from '@chakra-ui/react';
import COLORS, { rgbaToHex } from '../common/colors';

require('highcharts/highcharts-more')(Highcharts);
require('highcharts/modules/solid-gauge')(Highcharts);

type Props = {
  percentile: number,
  quantile: number,
  callbackGetColor: any,
  callbackGetRiskLevel: any,
  callbackGetRelativeRiskLabel: any,
  title?: string,
  maxHeight?: number,
  relativeRisk: number,
  relativeRiskOddsRatio: number,
  minRelativeRisk?: number,
  maxRelativeRisk?: number,
  relativeRiskTitle?: string,
  showSubtitle?: boolean,
  isMobile?: boolean
};

export default function RelativeRiskGaugeImpl(props: Props) {
  const maxHeight =
    props.maxHeight && props.maxHeight > 0 ? props.maxHeight : 225;
  const targetRef = React.useRef();
  const [width, setWidth] = React.useState(maxHeight * 2);

  const updateWidth = () => {
    setWidth(targetRef.current ? targetRef.current.offsetWidth : maxHeight * 2);
  };

  React.useEffect(() => {
    window.addEventListener('resize', updateWidth);
    return () => window.removeEventListener('resize', updateWidth);
  });

  const geneticRiskLevel = props
    .callbackGetRiskLevel(props.quantile)
    .toLowerCase();
  const relativeRiskLabel = props.callbackGetRelativeRiskLabel(
    props.relativeRisk,
    props.relativeRiskOddsRatio
  );
  const geneticRiskColor = props.callbackGetColor(props.quantile);
  let relativeRiskColor = COLORS.REPORT_TEXT_GRAY;
  let relativeRiskLabelColor = COLORS.REPORT_TEXT_GRAY;
  let relativeRiskStop1 = 0;
  let relativeRiskStop2 = 0;
  if (props.relativeRisk > 0 && relativeRiskLabel !== '0%') {
    relativeRiskColor = COLORS.RED_LIGHT;
    relativeRiskStop1 = 0;
    relativeRiskStop2 = props.relativeRisk;
    relativeRiskLabelColor = COLORS.RED_STATUS;
  } else if (props.relativeRisk < 0 && relativeRiskLabel !== '0%') {
    relativeRiskColor = COLORS.GREEN_LIGHT;
    relativeRiskStop1 = props.relativeRisk;
    relativeRiskStop2 = 0;
    relativeRiskLabelColor = COLORS.GREEN_STATUS;
  }

  const spanStyle = `"font-size:20px; color:${rgbaToHex(relativeRiskColor)}"`;
  const spanItem = `<span style=${spanStyle}>${relativeRiskLabel}</span><br/>`;
  const item = props.isMobile
    ? ''
    : `<div>${spanItem}<span style="color:transparent">.</span></div>`;

  const options = {
    credits: {
      enabled: false
    },
    chart: {
      type: 'gauge',
      backgroundColor: null,
      style: {
        fontFamily: 'century-gothic'
      },
      marginBottom: 0,
      height: width > 0 && width / 2 < maxHeight ? width / 2 : maxHeight,
      animation: {
        duration: 0
      }
    },
    tooltip: {
      enabled: false
    },
    pane: [
      {
        center: ['50%', '90%'],
        size: '160%',
        startAngle: -90,
        endAngle: 90,
        background: [
          {
            backgroundColor:
              Highcharts.defaultOptions.legend.backgroundColor || '#EEE',
            innerRadius: '60%',
            outerRadius: '100%',
            shape: 'arc'
          }
        ]
      },
      {
        center: ['50%', '90%'],
        size: '92%',
        startAngle: -90,
        endAngle: 90,
        background: [
          {
            backgroundColor:
              Highcharts.defaultOptions.legend.backgroundColor || '#EEE',
            innerRadius: '96%',
            outerRadius: '99%',
            shape: 'arc'
          }
        ]
      }
    ],

    // the value axis
    yAxis: [
      {
        min: props.minRelativeRisk,
        max: props.maxRelativeRisk,
        labels: {
          enabled: false
        },
        title: {
          text: props.relativeRiskTitle,
          y: width < 400 && !props.isMobile ? width / 8 : 25,
          style: {
            fontWeight: 'bold',
            fontSize: props.isMobile ? '8px' : '14px',
            color: relativeRiskLabelColor
          }
        },
        plotBands: [
          {
            from: -100,
            to: relativeRiskStop1,
            color: COLORS.GRAY_192_TRANSPARENCY_0_2,
            thickness: '40%'
          },
          {
            from: relativeRiskStop1,
            to: relativeRiskStop2,
            color: relativeRiskColor,
            thickness: '40%'
          },
          {
            from: 0,
            to: relativeRiskStop2,
            color: COLORS.GRAY_192_TRANSPARENCY_0_2,
            thickness: '40%'
          }
        ]
      },
      {
        pane: 1,
        min: 0,
        max: 100,
        labels: {
          enabled: false
        },
        title: {
          y: -25,
          text: '',
          style: {
            fontWeight: 'bold',
            fontSize: '14px',
            color: COLORS.REPORT_TEXT_GRAY
          }
        },
        minorTickInterval: null,
        tickAmount: 0,
        tickWidth: 0,
        plotBands: [
          {
            from: 0,
            to: 50,
            color: 'rgba(70, 168, 83, 0.5)',
            thickness: '2%'
          },
          {
            from: 50,
            to: 100,
            color: 'rgba(188, 15, 41, 0.5)',
            thickness: '2%'
          }
        ]
      }
    ],
    title: '',
    series: [
      {
        data: [props.relativeRisk],
        dataLabels: {
          borderColor: 'transparent',
          y: -50,
          formatter: () => item
        },
        dial: {
          backgroundColor: 'rgba(192, 192, 192, 0.5)', // COLORS.GRAY_192_TRANSPARENCY_0_2,
          borderColor: 'rgba(192, 192, 192, 0.5)',
          baseLength: '50%',
          borderWidth: 1,
          baseWidth: 2,
          topWidth: 1,
          zIndex: 999
        }
      },
      {
        yAxis: 1,
        data: [props.percentile],
        dataLabels: {
          enabled: false
        },
        dial: {
          backgroundColor: 'transparent', // geneticRiskColor,
          borderColor: 'transparent' // geneticRiskColor
        }
      }
    ],
    plotOptions: {
      series: {
        animation: false
      }
    }
  };
  return (
    <Box ref={targetRef}>
      {props.title && (
        <Text textAlign="center" color="gray.600">
          {props.title}
        </Text>
      )}
      {props.showSubtitle && (
        <Flex>
          <Spacer />
          <Text mr="10px">genetic risk level:</Text>
          <Text fontWeight="bold" color={geneticRiskColor}>
            {geneticRiskLevel}
          </Text>
          <Spacer />
        </Flex>
      )}
      <HighchartsReact highcharts={Highcharts} options={options} />
    </Box>
  );
}

RelativeRiskGaugeImpl.propTypes = {
  maxHeight: PropTypes.number,
  minRelativeRisk: PropTypes.number,
  maxRelativeRisk: PropTypes.number,
  relativeRiskTitle: PropTypes.string,
  showSubtitle: PropTypes.bool,
  title: PropTypes.string,
  isMobile: PropTypes.bool
};

RelativeRiskGaugeImpl.defaultProps = {
  maxHeight: undefined,
  minRelativeRisk: -100,
  maxRelativeRisk: 100,
  relativeRiskTitle: 'RELATIVE RISK',
  showSubtitle: true,
  title: undefined,
  isMobile: false
};
