// @flow
import React, {useCallback, useMemo} from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Box, RadioGroup, Radio, Stack, Text } from '@chakra-ui/react';
import COLORS from './colors';

type Props = {
  measurements: Object,
  traits: Array<string>,
  traitSectionCriteria: Object,
  isMobile: boolean

};

export default function FollowUpTrendChartImpl(props: Props) {
  const [showPhenotype, setShowPhenotype] = React.useState('bmi');
  const [activeRiskTrait, setActiveRiskTrait] = React.useState('CAD');
  const riskTraits = useMemo(() => ['CAD', 'T2D', 'HTN', 'HCHOL'], []);
  const bpTraits = useMemo(() =>  ['SBP', 'DBP'], []);
  const cholTraits = useMemo(() => ['TC', 'HDL', 'LDL'], []);

  const { measurements } = props;
  const getScaleRange = useCallback((traits: Array<string>) => {
    let to = null;
    let from = null;
    traits.forEach(trait => {
      if (measurements[trait]) {
        const maxTrait = Math.max(
          ...measurements[trait].map(m => m.value)
        );
        const minTrait = Math.min(
          ...measurements[trait].map(m => m.value)
        );
        if (to === null || maxTrait > to) to = maxTrait;
        if (from === null || minTrait < from) from = minTrait;
      }
    });
    return {
      from,
      to
    };
  }, [measurements]);
  const getCholesterolRange = useCallback(() => getScaleRange(cholTraits),[getScaleRange, cholTraits]);
  const getBpRange = useCallback(() => {
    const range = getScaleRange(bpTraits);
    if (range.from && range.to) {
      range.to += 5;
      range.from = 80;
    }
    return range;
  }, [getScaleRange, bpTraits]);
  const getA1cRange = useCallback(() => {
    const range = getScaleRange(['A1C']);
    if (range.from && range.to) {
      range.to += 0.5;
      range.from -= 0.5;
    }
    return range;
  }, [getScaleRange]);
  const getBmiRange = useCallback(() => getScaleRange(['BMI']), [getScaleRange]);
  const getRiskRange = useCallback(trait => {
    const range = getScaleRange([trait]);
    if (range.from && range.to) {
      range.to += 2;
      range.from -= 2;
      if (range.to > 100) range.to = 100;
      if (range.from < 0) range.to = 0;
    }
    return range;
  }, [getScaleRange]);

  const getTraitYAxis = useCallback((trait: string) => {
    if (bpTraits.includes(trait)) return 1;
    if (cholTraits.includes(trait)) return 2;
    if (trait === 'A1C') return 3;
    if (trait === 'BMI') return 4;
    return 0;
  }, [bpTraits, cholTraits]);

  const isTraitIncluded = useCallback((trait: string) => {
    if (riskTraits.includes(trait)) return true;
    if (cholTraits.includes(trait)) return showPhenotype === 'chol';
    if (bpTraits.includes(trait)) return showPhenotype === 'bp';
    if (trait === 'BMI') return showPhenotype === 'bmi';
    if (trait === 'A1C') return showPhenotype === 'a1c';
    return false;
  }, [riskTraits, cholTraits, bpTraits, showPhenotype]);

  const getTraitColor = useCallback((trait: string) => {
    if (riskTraits.includes(trait)) return COLORS.REPORT_TEXT;
    if (trait === 'HDL' || trait === 'DBP') return 'rgb(98, 115, 142, 0.7)';
    return 'rgb(124, 181, 236, 0.7)';
  }, [riskTraits]);

  const { traits, traitSectionCriteria } = props;
  const prepareDatasets = useCallback(() => {
    const dataset = [];
    traits.forEach((trait, index) => {
      const isRiskTrait = riskTraits.includes(trait);
      if (isTraitIncluded(trait)) {
        const traitData: Array<Object> = measurements[trait].map(
          measurement => ({
            x: Date.parse(measurement.eventDate),
            y: measurement.value
          })
        );
        const yAxisIndex = getTraitYAxis(trait);
        const traitColor = getTraitColor(trait);
        const aboveThreshColor = traitSectionCriteria[index].isAbove
          ? traitSectionCriteria[index].getAboveThreshColor()
          : traitColor;
        const belowThreshColor = traitSectionCriteria[index].isAbove
          ? traitColor
          : traitSectionCriteria[index].getBelowThreshColor();

        dataset.push({
          type: isRiskTrait ? 'spline' : 'column',
          name: trait.toUpperCase(),
          yAxis: yAxisIndex,
          visible: !isRiskTrait || trait === activeRiskTrait,
          showInLegend:
            isRiskTrait ||
            cholTraits.includes(trait) ||
            bpTraits.includes(trait),
          data: traitData,
          zones: [
            {
              value: traitSectionCriteria[index].thresh,
              color: belowThreshColor
            },
            {
              color: aboveThreshColor
            }
          ],
          color: isRiskTrait ? COLORS.REPORT_TEXT : traitColor,
          dashStyle: isRiskTrait ? 'line' : 'dash',
          zIndex: isRiskTrait ? 100 : 1
        });
      }
    });
    return dataset;
  }, [traits, activeRiskTrait, getTraitColor, bpTraits, cholTraits, riskTraits, getTraitYAxis, isTraitIncluded, measurements, traitSectionCriteria]);

  const cholRange = getCholesterolRange();
  const bpRange = getBpRange();
  const a1cRange = getA1cRange();
  const bmiRange = getBmiRange();
  const traitAxisColor = 'rgb(124, 181, 236, 1)';
  const defaultPhenotype = {
    CAD: 'bmi',
    HCHOL: 'chol',
    HTN: 'bp',
    T2D: 'a1c'
  };

  const chartFontSize = props.isMobile ? '10px' : '15px';

  const options = {
    credits: {
      enabled: false
    },
    chart: {
      backgroundColor: 'transparent',
      style: {
        fontFamily: 'century-gothic'
      },
      marginLeft: props.isMobile ? 70 : 90,
      marginRight: props.isMobile ? 60 : 100,
      height: undefined
    },
    plotOptions: {
      series: {
        lineWidth: 2,
        events: {
          legendItemClick() {
            if (cholTraits.includes(this.name)) return true;
            if (bpTraits.includes(this.name)) return true;
            setActiveRiskTrait(this.name);
            setShowPhenotype(defaultPhenotype[this.name]);
            return false;
          }
        }
      },
      spline: {
        marker: {
          // enabled: true,
          radius: 5,
          fillColor: '#FFFFFF',
          symbol: 'circle',
          lineWidth: 1,
          lineColor: null // inherit from series
        }
      },
      line: {
        marker: {
          // enabled: true,
          radius: 3,
          symbol: 'circle',
          lineWidth: 1,
          lineColor: null // inherit from series
        }
      }
    },
    title: {
      text: null,
      style: {
        color: COLORS.HIGHCHARTS_TITLE,
        fontSize: '12px',
        fontWeight: 'bold'
      }
    },
    xAxis: {
      type: 'datetime',
      labels: {
        formatter() {
          return Highcharts.dateFormat('%m/%Y', this.value);
        },
        style: {
          fontSize: chartFontSize
        }
      },
      title: {
        text: null
      }
    },
    yAxis: [
      {
        title: {
          useHTML: true,
          text: 'risk percentile<sup>*</sup>',
          style: {
            fontSize: chartFontSize,
            fontWeight: 'bold'
          }
        },
        labels: {
          format: '{value}%',
          style: {
            fontSize: props.isMobile ? '12px' :'15px'
          }
        },
        visible: true,
        offset: 0,
        opposite: false,
        gridLineDashStyle: 'dash',
        min: getRiskRange(activeRiskTrait).from,
        max: getRiskRange(activeRiskTrait).to
      },
      {
        // 2nd yAxis - BP
        gridLineWidth: 0,
        opposite: true,
        title: {
          text: 'blood presuure',
          style: {
            color: traitAxisColor,
            fontSize: chartFontSize
          }
        },
        labels: {
          format: '{value} mmHg',
          style: {
            color: traitAxisColor,
            fontSize: chartFontSize
          }
        },
        min: bpRange.from,
        max: bpRange.to,
        alignTicks: false,
        tickInterval: 10,
        visible: showPhenotype === 'bp'
      },
      {
        // 3rd yAxis - HCHOL
        gridLineWidth: 0,
        opposite: true,
        title: {
          text: 'cholesterol',
          style: {
            color: traitAxisColor,
            fontSize: chartFontSize
          }
        },
        labels: {
          format: '{value} (mg/dL)',
          style: {
            color: traitAxisColor,
            fontSize: chartFontSize
          }
        },
        softMin: cholRange.from,
        max: cholRange.to,
        alignTicks: false,
        tickInterval: 5,
        visible: showPhenotype === 'chol'
      },
      {
        // 4th yAxis - A1C
        gridLineWidth: 0,
        opposite: true,
        title: {
          text: 'A1c',
          style: {
            color: traitAxisColor,
            fontSize: chartFontSize
          }
        },
        labels: {
          format: '{value}%',
          style: {
            color: traitAxisColor,
            fontSize: chartFontSize
          }
        },
        min: a1cRange.from,
        softMax: a1cRange.to,
        alignTicks: false,
        tickInterval: 0.2,
        visible: showPhenotype === 'a1c'
      },
      {
        // 5th yAxis - BMI
        gridLineWidth: 0,
        opposite: true,
        title: {
          text: 'BMI (kg/m2)',
          style: {
            color: traitAxisColor,
            fontSize: chartFontSize,
          }
        },
        labels: {
          format: '{value}',
          style: {
            color: traitAxisColor,
            fontSize: chartFontSize
          }
        },
        min: bmiRange.from,
        max: bmiRange.to,
        alignTicks: false,
        tickInterval: 0.5,
        visible: showPhenotype === 'bmi'
      }
    ],
    legend: {
      align: props.isMobile ? 'left' : 'center',
      itemWidth: props.isMobile ? 70 : 150,
      width: props.isMobile ? window.screen.width - 10 : 600,
      verticalAlign: 'top',
      itemMarginBottom: 10,
      itemStyle: {
        color: COLORS.REPORT_TEXT,
        fontSize: props.isMobile ? '12px' : '16px',
        fontWeight: 'bold'
      }
    },
    series: prepareDatasets()
  };
  const radioSize = props.isMobile ? 'sm' : 'md';
  return (
    <Box>
      <HighchartsReact highcharts={Highcharts} options={options} />
      <RadioGroup
        onChange={setShowPhenotype}
        value={showPhenotype}
        mt="10px"
        color={COLORS.REPORT_TEXT}
        fontSize={props.isMobile ? 10 : 14}
      >
        <Stack direction="row" align="center" justify="center" spacing={props.isMobile ? "10px" : "50px"}>
          <Radio size={radioSize} value="bp">Blood Pressure</Radio>
          <Radio size={radioSize} value="chol">Cholesterol</Radio>
          <Radio size={radioSize} value="a1c">A1c</Radio>
          <Radio size={radioSize} value="bmi">BMI</Radio>
          <Radio size={radioSize} value="none">None</Radio>
        </Stack>
      </RadioGroup>
      <Box
        borderWidth="1px"
        borderColor="gray.100"
        borderRadius={props.isMobile ? "10px" : "20px"}
        mt={props.isMobile ? "20px" : "50px"}
        fontSize={props.isMobile ? 12 : 16}
        mx={props.isMobile ? "10px" : "40px"}
        bg={props.isMobile ? "transparent" : COLORS.REPORT_TEXT}
        color={props.isMobile ? COLORS.REPORT_TEXT : "white"}
      >
        <Text m={props.isMobile ? "10px" : "30px"}>
          <sup>*</sup>Risk percentile relative to a reference population, for
          example if you are on risk percentile 84 it means that your risk level
          is higher than 84% of the population.
        </Text>
      </Box>
    </Box>
  );
}
