import {v4 as uuid4} from "uuid";
import {AWS_ACCOUNT_IDS, ENV} from "../config/env_config";
import {g} from "../utils/a";

export function getFederatedRoleArn() {
	return `arn:aws:iam::${AWS_ACCOUNT_IDS[ENV]}:role/athena-health-federated-role-${ENV}`
}

export function getFederatedAnauthorizedRoleArn() {
	return `arn:aws:iam::${AWS_ACCOUNT_IDS[ENV]}:role/athena-health-federated-unauthorized-role-${ENV}`
}

export function getPostAuthUrl() {
	const prefix = ENV === "prod" ? "" : `${ENV}.`;
	const isLocalHost = window.location.hostname === "localhost";
	return isLocalHost ? "http://localhost:3000/athenahealth/auth" : `https://${prefix}open-dna.io/athenahealth/auth`;
}

export function extractSecondNumericToken(token: string) {
  const pointIndex = token.indexOf(".");
  let secondToken = token.substring(pointIndex + 1);
  let dashIndex = secondToken.indexOf("-");
  secondToken = secondToken.substring(dashIndex + 1);
	return parseInt(secondToken)
}

export function extractFirstNumericToken(token: string) {
  const pointIndex = token.indexOf(".");
  let firstToken = token.substring(0, pointIndex);
  let dashIndex = firstToken.indexOf("-");
  firstToken = firstToken.substring(dashIndex + 1);
	return parseInt(firstToken)
}

export function splitPatientAndPractice(athenaPatient: string) {
  const practice = extractFirstNumericToken(athenaPatient);
  const externalId = extractSecondNumericToken(athenaPatient);
  return {"patient": externalId, "practice": practice};
}

export function extractDepartment(athenaDepartment: string) {
  return extractSecondNumericToken(athenaDepartment)
}


function request(
  method: RequestMethodTypes,
  domain: string,
  params: Object,
  pathTemplate: string,
  additionalParams: ?Object,
  body: ?RequestBodyType,
	headers: ?Object
): Promise<*> {
	let url = `${domain}${pathTemplate}`;
	if (params) {
		url += "?";
		url += new URLSearchParams(params);
	}
	const options = {
		method: method,
		headers: headers
	};
	if (method === "GET") {
		options.redirect = "manual";
		options.mode =  "cors";
	} else {
		options.body = new URLSearchParams(body);
	}
	return fetch(url, options);
}

const requests = {
  get: (
    domain: string,
    params: Object,
    pathTemplate: string,
    additionalParams: Object,
		headers: Object
  ): Promise<*> => {
    return request(
      'GET',
      domain,
      params,
      pathTemplate,
      additionalParams,
      undefined,
			headers
    );
  }
};


function createCodeVerifier() {
		let token = uuid4().replace(/-/g, "");
		while (token.length < 43) {
			token += uuid4().replace(/-/g, "");
		}
		return token;
}

function sha256(plain) {
		// returns promise ArrayBuffer
		const encoder = new TextEncoder();
		const data = encoder.encode(plain);
		return window.crypto.subtle.digest('SHA-256', data);
}


function base64urlencode(a) {
	let str = "";
	const bytes = new Uint8Array(a);
	const len = bytes.byteLength;
	for (let i = 0; i < len; i++) {
		str += String.fromCharCode(bytes[i]);
	}
	return window.btoa(str)
			.replace(/\+/g, "-")
			.replace(/\//g, "_")
			.replace(/=+$/, "");
}
async function generateCodeChallengeFromVerifier(verifier) {
	const hashed = await sha256(verifier);
	const base64encoded = base64urlencode(hashed);
	return base64encoded;
}

export function getAthenaHealthDomain() {
	const domainPrefix = ENV === "prod" ? "" : "preview.";
	return `https://api.${domainPrefix}platform.athenahealth.com`;
}

export async function authorizeAthenaHealth(iss: string, launch: string) {
	const domain = getAthenaHealthDomain();
	const path = "/oauth2/v1/authorize";
	const postAuthUrl = getPostAuthUrl();
	console.log(`post auth URL: ${postAuthUrl}`)
	const scope = "launch openid";
	const aud = iss;
	const codeVerifier = createCodeVerifier();
	const nonce = uuid4();

	const cid = g(ENV, false);

	const queryParams = {
		client_id: cid,
		response_type: 'code',
		redirect_uri: postAuthUrl,
		scope: scope,
		state: nonce,
		code_challenge: await generateCodeChallengeFromVerifier(codeVerifier),
		code_challenge_method: "S256"
	}
	queryParams['aud'] = aud;
	queryParams['launch'] = launch

	sessionStorage.setItem("athenaCC", queryParams["code_challenge"]);
	sessionStorage.setItem("athenaCV", codeVerifier);
	sessionStorage.setItem("athenaNN", nonce);
	const res = requests.get(
			domain,
			queryParams,
			path,
			undefined,
			{
        'Content-Type': 'application/json'
    	}
	);
	return res;
}


export function performTokenRequest(code) {
	const prefix = ENV === "prod" ? "" : `${ENV}.`;
	const domain = getAthenaHealthDomain();
  	const path = "/oauth2/v1/token";
	const postTokenUrl = `https://${prefix}open-dna.io/athenahealth/auth`;

	const myHeaders = new Headers();
	myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

	let urlencoded = new URLSearchParams();
	urlencoded.append("grant_type", "authorization_code");
	urlencoded.append("code", code);
	urlencoded.append("redirect_uri", postTokenUrl);
	urlencoded.append("client_id", g(ENV, false));

	const codeVerifier = sessionStorage.getItem("athenaCV");
	urlencoded.append("code_verifier", codeVerifier)
	sessionStorage.removeItem("athenaCC");
	sessionStorage.removeItem("athenaCV");
	sessionStorage.removeItem("athenaNN");

	const requestOptions = {
		method: 'POST',
		headers: myHeaders,
		body: urlencoded,
		redirect: 'follow'
	};

	return fetch(`${domain}${path}`, requestOptions);
}
