import React from 'react';
import { Box, Flex, Spacer, Text } from '@chakra-ui/react';
import WhitePanel from './WhitePanel';
import COLORS from '../colors';

export default function MobileYourResultsPanel() {
  return (
    <WhitePanel>
      {/* <Flex my="5px" bg="gray.300"> */}
      {/*  <Text mx="20px" my="1px" /> */}
      {/* </Flex> */}
      <Box mx="2%" color="gray.500" fontSize={18}>
        <Flex align="center">
          <Text fontWeight="bold" mr="20px" color={COLORS.REPORT_TEXT}>
            Your results:
          </Text>
          {/* <Image src={htnImage} h="24px" alt="" mr="5px" /> */}
          <Spacer />
        </Flex>
      </Box>
    </WhitePanel>
  );
}
