// @flow
import React from 'react';
import { Flex, Box, Text } from '@chakra-ui/react';
import COLORS from '../../common/colors';
import OsteoporosisReportStrings from './osteoporosis-report-common';
import Disclaimers from '../../common/reports/Disclaimers';

type Props = {
  isMobile: boolean
};

export default function OsteoporosisExplanatorySection(props: Props) {
  const headerFontSize = props.isMobile ? 17 : 20;
  const textFontSize = props.isMobile ? 12 : 14;
  return (
    <Box fontSize={textFontSize}>
      <Flex align="center" justify="space-between">
        <Text fontSize={headerFontSize} color="gray.600" mt="30px" mb="20px">
          What is Osteoporosis ?
        </Text>
      </Flex>
      <Text mb="20px" color={COLORS.REPORT_TEXT}>
        {OsteoporosisReportStrings.WHAT_IS_OSTEOPOROSIS_TEXT1}
        <br />
        <br />
        {OsteoporosisReportStrings.WHAT_IS_OSTEOPOROSIS_TEXT2}
        <sup>*</sup>.
        <br />
      </Text>
      <Text mb="5px">
        <i>
          <sup>*</sup> {OsteoporosisReportStrings.WHAT_IS_OSTEOPOROSIS_REF1}
        </i>
      </Text>
      <Text fontSize={headerFontSize} color="gray.600" my="20px">
        How did we calculate your score ?
      </Text>
      <Text mb="15px" color={COLORS.REPORT_TEXT}>
        {OsteoporosisReportStrings.HOW_DID_WE_CALC_TEXT1}
        <sup>*</sup>. {OsteoporosisReportStrings.HOW_DID_WE_CALC_TEXT2}
      </Text>
      <Text mb="20px" fontSize={textFontSize}>
        <sup>*</sup>
        <i>{OsteoporosisReportStrings.HOW_DID_WE_CALC_REF1}</i>
      </Text>
      <Disclaimers
        isMobile={props.isMobile}
        texts={[
          OsteoporosisReportStrings.DISCLAIMER1,
          OsteoporosisReportStrings.DISCLAIMER2,
          OsteoporosisReportStrings.DISCLAIMER3,
          OsteoporosisReportStrings.DISCLAIMER4,
          OsteoporosisReportStrings.DISCLAIMER5,
          OsteoporosisReportStrings.DISCLAIMER6
        ]}
      />
    </Box>
  );
}
