// @flow
import { CognitoUser } from 'amazon-cognito-identity-js';
import {
  isClinicsUser,
  isDemoUser,
  isDeveloperUser,
  isInternalDemoUser,
  isPilotUser,
  isUserInGroup
} from './auth';

export function canSeePilotPages(authenticatedUser: typeof CognitoUser) {
  return isPilotUser(authenticatedUser) || isDeveloperUser(authenticatedUser);
}

export function canSeeClinicsPages(authenticatedUser: typeof CognitoUser) {
  return isClinicsUser(authenticatedUser) || isDeveloperUser(authenticatedUser);
}

export function canSeeSelfPages(
  urlPatientId: ?string,
  loggedInPatientId: ?string,
  strict: boolean=true
) {
  if (!loggedInPatientId) {
    console.log("cannot see self pages");
    return false;
  }
  if (strict) {
    console.log("strict:");
    console.log(urlPatientId && loggedInPatientId === urlPatientId);
    return urlPatientId && loggedInPatientId === urlPatientId;
  }
  return true;
}


export function canSeePilotClinicPages(
  authenticatedUser: typeof CognitoUser,
  clinic: string
) {
  if (!canSeePilotPages(authenticatedUser)) return false;
  return (
    isUserInGroup(authenticatedUser, clinic) ||
    isDeveloperUser(authenticatedUser)
  );
}

export function canSeeClinicsClinicPages(
  authenticatedUser: typeof CognitoUser,
  clinic: string
) {
  if (!canSeeClinicsPages(authenticatedUser)) return false;
  return (
    isUserInGroup(authenticatedUser, clinic) ||
    isDeveloperUser(authenticatedUser)
  );
}

export function canSeeClientDemoPages(authenticatedUser: typeof CognitoUser) {
  return isDemoUser(authenticatedUser) || isDeveloperUser(authenticatedUser);
}

export function canSeeInternalDemoPages(authenticatedUser: typeof CognitoUser) {
  return (
    isInternalDemoUser(authenticatedUser) || isDeveloperUser(authenticatedUser)
  );
}
