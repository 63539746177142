import React, {useCallback, useState} from "react";
import {
  // Step,
  // StepDescription,
  // StepIcon,
  // StepIndicator,
  // StepNumber,
  // StepSeparator,
  // StepStatus,
  // StepTitle,
  // Stepper,
  useSteps, Box, Flex, Spacer, Image,
} from '@chakra-ui/react'
import { ArrowForwardIcon, ArrowBackIcon } from '@chakra-ui/icons'
import COLORS from "../../common/colors";
import ActionButtonImpl from "../../common/ActionButton";
import opendnaBackround from "../../images/OpenDNA_background.png";
import opendnaBackround2 from "../../images/OpenDNA_background2.png";
import opendnaBackround3 from "../../images/OpenDNA_background3.png";
import DemoMyGeneInsightsPatientAttributes from "./DemoMyGeneInsightsPatientAttributes";
import DemoPatientRisks from "../DemoPatientRisks";

const steps = [
  { title: 'My Attributes', description: null },
  { title: 'Results', description: null }
]

export function MyGeneInsightsPatientWorkflow() {
  const { activeStep, setActiveStep } = useSteps({
    index: 1,
    count: steps.length,
  })
  const [ areAttributesFilled, setAreAttributesFilled ] = useState(false)
  const onAttributesApplyChange = useCallback((filled)=>{
      setAreAttributesFilled(filled)
    }, [setAreAttributesFilled]
  )

  const onNextStep = useCallback(()=>{
      setActiveStep(activeStep + 1)
    }, [setActiveStep, activeStep]
  )
  const onPreviousStep = useCallback(()=>{
      setActiveStep(activeStep - 1)
    }, [setActiveStep, activeStep]
  )
  if (areAttributesFilled) {
    console.log("all conditions met for results viewing")
  }
  return (
    <Flex minH={"93vh"}>
      {/*<Box bg={"gray.50"}>*/}
      {/*  <Stepper index={activeStep} orientation='vertical' height='500px' gap='2' colorScheme={'blue'} pl={"40px"} pr={"40px"} mt={"70px"}>*/}
      {/*    {steps.map((step, index) => (*/}
      {/*      <Step key={index}>*/}
      {/*        <StepIndicator>*/}
      {/*          <StepStatus*/}
      {/*            complete={<StepIcon />}*/}
      {/*            incomplete={<StepNumber />}*/}
      {/*            active={<StepNumber />}*/}
      {/*          />*/}
      {/*        </StepIndicator>*/}

      {/*        <Box flexShrink='0'>*/}
      {/*          <StepTitle>{step.title}</StepTitle>*/}
      {/*          <StepDescription>{step.description}</StepDescription>*/}
      {/*        </Box>*/}

      {/*        <StepSeparator />*/}
      {/*      </Step>*/}
      {/*    ))}*/}
      {/*  </Stepper>*/}
      {/*</Box>*/}
      <Box ml={activeStep === 1 ? "5%" : 0} mr="2%" mt="32px">
        <Box minH={"580px"} color={COLORS.REPORT_TEXT}>
          <Box my={"10px"}>
            {activeStep === 1 && <DemoMyGeneInsightsPatientAttributes onApplyChange={onAttributesApplyChange}/>}
            {activeStep === 2 && <DemoPatientRisks/>}
          </Box>
        </Box>
        <Flex my={"20px"}>
          <ActionButtonImpl
            name={"Previous" }
            fontWeight="bold"
            fontSize={14}
            bg="gray.100"
            color={COLORS.REPORT_TEXT}
            h="40px"
            w="180px"
            mx="20px"
            onClick={onPreviousStep}
            isDisabled={activeStep === 1}
            leftIcon={<ArrowBackIcon/>}
          >
          </ActionButtonImpl>
            <ActionButtonImpl
              name={"View my results" }
              fontWeight="bold"
              fontSize={14}
              bg="gray.100"
              color={COLORS.REPORT_TEXT}
              h="40px"
              w="180px"
              mx="20px"
              onClick={onNextStep}
              isDisabled={(activeStep === 1 && !areAttributesFilled) || activeStep === 2}
              rightIcon={<ArrowForwardIcon/>}
            >
            </ActionButtonImpl>
          <Spacer/>
        </Flex>
      </Box>
      {(activeStep === 1) && <Image src={opendnaBackround} w="60%" alt="Open-DNA" ml="8%" />}
      {/*{(activeStep === 2) && <Image src={opendnaBackround} w="30%" alt="Open-DNA" ml="0px" />}*/}
      {(activeStep === 3) && <Image src={opendnaBackround2} w="60%" alt="Open-DNA" ml="10%" />}
      {(activeStep === 4) && <Image src={opendnaBackround3} w="60%" alt="Open-DNA" ml="6%" />}
    </Flex>
  )
}
