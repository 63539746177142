// @flow
import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import COLORS from '../common/colors';
import { bpReportStrings } from './bp-report-common';
import Disclaimers from '../common/reports/Disclaimers';

type Props = {
  isMobile: boolean
};

export default function BpExplanatorySection(props: Props) {
  const headerFontSize = props.isMobile ? 17 : 20;
  const textFontSize = props.isMobile ? 12 : 14;
  return (
    <Box fontSize={textFontSize}>
      <Flex align="center" justify="space-between">
        <Text fontSize={headerFontSize} color="gray.600" mt="30px" mb="20px">
          What is Hypertension ?
        </Text>
      </Flex>
      <Text mb="20px" color={COLORS.REPORT_TEXT}>
        {bpReportStrings.WHAT_IS_HYPERTENSION_TEXT1}
        <br />
        <br />
        {bpReportStrings.WHAT_IS_HYPERTENSION_TEXT2}
        <sup>*</sup>.
        <br />
      </Text>
      <Text mb="5px">
        <br />
        <i>
          <sup>*</sup> {bpReportStrings.WHAT_IS_HYPERTENSION_REF}
        </i>
      </Text>
      <Text fontSize={headerFontSize} color="gray.600" my="20px">
        How did we calculate your score ?
      </Text>
      <Text mb="10px" color={COLORS.REPORT_TEXT}>
        {bpReportStrings.HOW_DID_WE_CALC_TEXT1}
        <sup>*</sup>. {bpReportStrings.HOW_DID_WE_CALC_TEXT2}
      </Text>
      <Text mb="20px" fontSize={textFontSize}>
        <sup>*</sup>
        <i>{bpReportStrings.HOW_DID_WE_CALC_REF}</i>
      </Text>
      <Text fontSize={headerFontSize} color="gray.600" my="20px">
        What does it mean ?
      </Text>
      <Text mb="20px" color={COLORS.REPORT_TEXT}>
        {bpReportStrings.WHAT_DOES_IT_MEAN}
      </Text>
      <Disclaimers
        isMobile={props.isMobile}
        texts={[
          bpReportStrings.DISCLAIMER1,
          bpReportStrings.DISCLAIMER2,
          bpReportStrings.DISCLAIMER3,
          bpReportStrings.DISCLAIMER4,
          bpReportStrings.DISCLAIMER5,
          bpReportStrings.DISCLAIMER6
        ]}
      />
    </Box>
  );
}
