import e from '../athena/athena_config.json';

export function d(r) {
  return r.split('').reverse().join('');
}

export function f(c, a) {
  console.log(e);
  const r = a ? e[c].a : e[c].b;
  return d(r) + '=';
}

export function g(c, a) {
    return window.atob(f(c, a));
}
