// @flow
import React, { useCallback } from 'react';
import {
  Flex,
  Box,
  Text,
  Stack,
  Spacer,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  FormControl,
  useDisclosure,
  Alert,
  AlertIcon,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel
} from '@chakra-ui/react';

import ChangeSlider from '../../common/ChangeSlider';
import LabeledSwitch from '../../common/LabeledSwitch';
import COLORS from '../../common/colors';
import ActionButton from '../../common/ActionButton';
import RelativeRiskGauge from '../../common/RelativeRiskGauge';
import OverallRiskGauge from '../../common/OverallRiskGauge';
import {
  getRiskColorByQuintile,
  getRiskColorByPercentile,
  getRiskLevelByQuintile,
  getRiskLevelByPercentile
} from '../../common/risk-colors';
import { getRelativeRiskLabel } from '../../common/relative-risk';
import CalculatorHeader from '../../common/reports/CalculatorHeaderBar';
import EditablePatientAttributes from '../../common/reports/EditablePatientAttributes';
import {getAgeColor} from '../../common/calculator/calculator-common';
import CadExPatientAttributes from '../../common/reports/CadExPatientAttributes';
import withCadCalculatorEx from './WithCadCalculatorEx';
import { cadCalculatorUserAttributes } from '../../common/reports/report-common';
import traitRanges from '../../common/reports/trait-ranges.json';
import { roundWithPrecision } from '../../utils/numbers';
import GaugeChangeDetails from '../../common/GaugeChangeDetails';
import capitalizeFirstLetter from '../../utils/string';
import PopoverInfo from '../../common/PopoverInfo';
import {
  agePopInfoTexts,
  olderParticipant,
  youngerParticipant
} from '../../common/reports/report-common';
import cadReportStrings from '../cad-report-common';
import InfoButton from '../../common/InfoButton';

type Props = {
  onGenderSelected: any,
  onCurrentAgeChange: any,
  onAgeSliderChange: any,
  getAgeDiffText: any,
  onCurrentTcChange: any,
  onCurrentHdlChange: any,
  onCurrentLdlChange: any,
  onLdlSliderChange: any,
  getLdlDiffText: any,
  getSliderLdlColor: any,
  onCurrentSbpChange: any,
  onSbpSliderChange: any,
  getSbpDiffText: any,
  getSliderSbpColor: any,
  onCurrentDbpChange: any,
  onCurrentBpTreatedChange: any,
  onCurrentSmokerChange: any,
  onCurrentDiabetesChange: any,
  onBpTreatedSwitchChange: any,
  onDiabetesSwitchChange: any,
  onSmokerSwitchChange: any,
  onResetAttributes: any,
  getSwitchColor: any,
  getSwitchText: any,
  gender: ?string,
  age: ?number,
  resetAge: number,
  resetLdl: number,
  resetSbp: number,
  currentAge: number,
  currentSbp: number,
  currentDbp: number,
  currentTc: number,
  currentLdl: number,
  currentHdl: number,
  sbp: number,
  ldl: number,
  diabetes: boolean,
  smoker: boolean,
  bpTreated: boolean,
  cadQuintile: number,
  cadPercentile: number,
  playingEnabled: boolean,
  ageSliderEnabled: boolean,
  sbpSliderEnabled: boolean,
  ldlSliderEnabled: boolean,
  orderLabs: boolean,
  relativeRisk: number,
  relativeRiskOddsRatio: number,
  current: number,
  expected: number,
  shortCadText: string,
  longCadText: string,
  summaryChangeLine: string,
  cadTrendIcon: any,
  changeColor: string,
  currentPercentile: number,
  expectedPercentile: number,
  shortCadTextPercentile: string,
  longCadTextPercentile: string,
  summaryChangeLinePercentile: string,
  cadTrendIconPercentile: any,
  changeColorPercentile: string,
  printing: boolean,

  apolipoproteinB: number,
  alcohol: boolean,
  statinsTreated: boolean,
  whiteBloodCellLeukocyteCount: number,
  alkalinePhosphatase: number,
  cholesterol: number,
  creatinine: number,
  cystatinC: number,
  gammaGlutamyltransferase: number,
  glucose: number,
  glycatedHaemoglobinHba1c: number,
  phosphate: number,
  shbg: number,
  totalBilirubin: number,
  testosterone: number,
  totalProtein: number,
  urate: number,
  vitaminD: number,
  lipoproteinA: number,

  cadLogReg: Object
};

function CadCalculatorExImpl(props: Props) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const initialRef = React.useRef();
  const finalRef = React.useRef();

  const longCadLines = props.longCadText ? props.longCadText.split('\n') : [''];
  const longCadLinesPercentile = props.longCadTextPercentile
    ? props.longCadTextPercentile.split('\n')
    : [''];
  const { onResetAttributes } = props;
  const onApplyAndClose = useCallback(() => {
    onResetAttributes();
    onClose();
  }, [onClose, onResetAttributes]);
  const missingAttrText = `Missing attributes, click the 'Edit' button to provide the missing information`;

  const getOverallRiskLabel = useCallback((current, expected) => {
    const diff = expected - current;
    if (diff === 0) return `${current + 1}%`;
    return `${expected}%`;
  }, []);

  const [tabIndex, setTabIndex] = React.useState(0);
  const onPopulationClick = useCallback(() => setTabIndex(0), [setTabIndex]);
  const onCurrentClick = useCallback(() => setTabIndex(1), [setTabIndex]);
  const handleTabsChange = useCallback(index => {
    setTabIndex(index)}, [setTabIndex]
  );

  const leftSizeWidth = props.printing ? "30%" : "40%";
  const isYoungerThanMin = props.currentAge && props.currentAge < traitRanges.age.lowLimit;
  const isOlderThanMax = props.currentAge && props.currentAge > traitRanges.age.highLimit;

  return (
    <Stack borderWidth={1} borderColor="gray.200" color={COLORS.REPORT_TEXT}>
      <CalculatorHeader />
      <Flex mt="10px" minW="100%">
        <Flex borderColor="gray.100" borderWidth={1} flex="1" mx="1%" w="55%">
          <Box minW={leftSizeWidth} w={leftSizeWidth} ml="3%" mr="2%" my="20px" fontSize={13}>
            <Stack spacing={props.printing ? "5px" : "15px"}>
              {!props.printing && <Text fontSize={13}>
                <i>
                  Change risk factor values to check the effect on your relative
                  risk to develop coronary artery disease (CAD)
                </i>
              </Text>}
              {/* age */}
              <ChangeSlider
                val={props.age}
                minVal={props.resetAge}
                maxVal={traitRanges.age.highLimit}
                stepVal={1}
                resetVal={props.resetAge}
                leftLabel="Age"
                callbackGetColor={getAgeColor}
                callbackRightLabel={props.getAgeDiffText}
                callbackOnValueChange={props.onAgeSliderChange}
                callbackOnValueEndChange={props.onAgeSliderChange}
                enabled={props.playingEnabled && props.ageSliderEnabled}
                popoverInfo={isYoungerThanMin || isOlderThanMax ? agePopInfoTexts : undefined}
              />
              {/* ldl */}
              <ChangeSlider
                val={props.ldl}
                minVal={traitRanges.ldl.lowLimit}
                maxVal={traitRanges.ldl.highLimit}
                stepVal={1}
                resetVal={props.resetLdl}
                leftLabel="LDL (mg/dL)"
                callbackGetColor={props.getSliderLdlColor}
                callbackRightLabel={props.getLdlDiffText}
                callbackOnValueChange={props.onLdlSliderChange}
                callbackOnValueEndChange={props.onLdlSliderChange}
                enabled={props.playingEnabled && props.ldlSliderEnabled}
              />
              {/* sbp */}
              <ChangeSlider
                val={props.sbp}
                minVal={traitRanges.sbp.lowLimit}
                maxVal={traitRanges.sbp.highLimit}
                stepVal={1}
                resetVal={props.resetSbp}
                leftLabel="SBP (mmHg)"
                callbackGetColor={props.getSliderSbpColor}
                callbackRightLabel={props.getSbpDiffText}
                callbackOnValueChange={props.onSbpSliderChange}
                callbackOnValueEndChange={props.onSbpSliderChange}
                enabled={props.playingEnabled && props.sbpSliderEnabled}
              />
              <LabeledSwitch
                mt="10px"
                isChecked={props.bpTreated}
                leftLabel="Treated for high blood pressure"
                leftLabelWidth="200px"
                callbackGetColor={props.getSwitchColor}
                callbackOnValueChange={props.onBpTreatedSwitchChange}
                callbackRightLabel={props.getSwitchText}
                enabled={props.playingEnabled}
              />
              <LabeledSwitch
                isChecked={props.diabetes}
                leftLabel="Diabetes"
                leftLabelWidth="200px"
                callbackGetColor={props.getSwitchColor}
                callbackOnValueChange={props.onDiabetesSwitchChange}
                callbackRightLabel={props.getSwitchText}
                enabled={props.playingEnabled}
              />
              <LabeledSwitch
                isChecked={props.smoker}
                leftLabel="Smoker"
                leftLabelWidth="200px"
                callbackGetColor={props.getSwitchColor}
                callbackOnValueChange={props.onSmokerSwitchChange}
                callbackRightLabel={props.getSwitchText}
                enabled={props.playingEnabled}
              />
              <ActionButton
                fontSize={14}
                onClick={props.onResetAttributes}
                borderWidth={1}
                borderColor={COLORS.REPORT_TEXT}
                name="Reset"
                color={COLORS.REPORT_TEXT}
              />
            </Stack>
          </Box>
          <Tabs
            variant="soft-rounded"
            my="10px"
            size="lg"
            index={tabIndex}
            mb="20px"
            spacing="20px"
            minW="50%"
            onChange={handleTabsChange}
          >
            <TabList>
              <Box>
                <Tab>Relative to Population</Tab>
                <PopoverInfo
                  trigger={
                    <Box>
                      <InfoButton
                        onClick={onPopulationClick}
                        mt="-20px"
                        minW="100px"
                        minH="20px"
                      />
                    </Box>
                  }
                  header={cadReportStrings.RELATIVE_TO_POPULATION_INFO_HEADER}
                  text={cadReportStrings.RELATIVE_TO_POPULATION_INFO_TEXT}
                  footer={
                    cadReportStrings.INFO_FOOTER
                  }
                />
              </Box>
              <Box>
                <Tab>Relative to Current</Tab>
                <PopoverInfo
                  trigger={
                    <Box>
                      <InfoButton
                        onClick={onCurrentClick}
                        mt="-20px"
                        minW="100px"
                        minH="20px"
                      />
                    </Box>
                  }
                  header={cadReportStrings.RELATIVE_TO_CURRENT_INFO_HEADER}
                  text={cadReportStrings.RELATIVE_TO_CURRENT_INFO_TEXT}
                  footer={
                    cadReportStrings.INFO_FOOTER
                  }
                />
              </Box>
              <Spacer/>
            </TabList>
            <TabPanels>
              <TabPanel>
                <OverallRiskGauge
                  percentile={props.cadPercentile}
                  callbackGetColor={getRiskColorByPercentile}
                  callbackGetRiskLevel={getRiskLevelByPercentile}
                  callbackGetOverallRiskLabel={getOverallRiskLabel}
                  currentPercentile={props.currentPercentile}
                  expectedPercentile={props.expectedPercentile}
                  showSubtitle={false}
                />
                {props.currentPercentile !== props.expectedPercentile && (
                  <GaugeChangeDetails
                    headerLine="Estimated risk percentile change"
                    changeColor={props.changeColorPercentile}
                    changeLines={longCadLinesPercentile}
                    trendIcon={props.cadTrendIconPercentile}
                    shortText={props.shortCadTextPercentile}
                    summaryLine={props.summaryChangeLinePercentile}
                    printing={props.printing}
                  />
                )}
              </TabPanel>
              <TabPanel>
                <RelativeRiskGauge
                  percentile={props.cadPercentile}
                  quantile={props.cadQuintile}
                  callbackGetColor={getRiskColorByQuintile}
                  callbackGetRiskLevel={getRiskLevelByQuintile}
                  callbackGetRelativeRiskLabel={getRelativeRiskLabel}
                  relativeRisk={props.relativeRisk}
                  relativeRiskOddsRatio={props.relativeRiskOddsRatio}
                  showSubtitle={false}
                />
                {props.current !== props.expected && (
                  <GaugeChangeDetails
                    headerLine="Estimated relative risk change"
                    changeColor={props.changeColor}
                    changeLines={longCadLines}
                    trendIcon={props.cadTrendIcon}
                    shortText={props.shortCadText}
                    summaryLine={props.summaryChangeLine}
                    printing={props.printing}
                  />
                )}
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Flex>
        {!props.printing && <Box borderColor="gray.100" borderWidth={1} mr="1%" w="45%" minW="45%">
          <CadExPatientAttributes
            gender={capitalizeFirstLetter(props.gender)}
            age={props.currentAge}
            sbp={props.currentSbp}
            dbp={props.currentDbp}
            hdl={roundWithPrecision(props.currentHdl, 0)}
            ldl={roundWithPrecision(props.currentLdl, 0)}
            tc={roundWithPrecision(props.currentTc, 0)}
            riskTrait="CAD"
            riskLevel={getRiskLevelByPercentile(props.cadPercentile)}
            smoker={!!props.smoker}
            alcohol={!!props.alcohol}
            bpTreated={!!props.bpTreated}
            statinsTreated={!!props.statinsTreated}
            diabetes={!!props.diabetes}
            orderLabs={props.orderLabs}
            labelPrefix=""
            showBorder={false}
            showAttributeFlags={cadCalculatorUserAttributes}
            apolipoproteinB={props.apolipoproteinB}
            whiteBloodCellLeukocyteCount={props.whiteBloodCellLeukocyteCount}
            alkalinePhosphatase={props.alkalinePhosphatase}
            cholesterol={props.cholesterol}
            creatinine={props.creatinine}
            cystatinC={props.cystatinC}
            gammaGlutamyltransferase={props.gammaGlutamyltransferase}
            glucose={props.glucose}
            glycatedHaemoglobinHba1c={props.glycatedHaemoglobinHba1c}
            phosphate={props.phosphate}
            shbg={props.shbg}
            totalBilirubin={props.totalBilirubin}
            testosterone={props.testosterone}
            totalProtein={props.totalProtein}
            urate={props.urate}
            vitaminD={props.vitaminD}
            lipoproteinA={props.lipoproteinA}
            cadLogReg={props.cadLogReg}
          />
          <Flex align="center" my="10px">
            <ActionButton
                name="Edit..."
                size="sm"
                w="70px"
                onClick={onOpen}
                ml="30px"
                color={COLORS.REPORT_TEXT}
            />
            <Text ml="15px" color={COLORS.REPORT_TEXT_GRAY} fontSize={10}>editing in order to check risk effect scenarios.<br/>{`the changes are not persistent.`}</Text>
          </Flex>
          {!props.playingEnabled && (
              <Box mx="30px" fontSize={13}>
                <Alert status="warning" mt="30px" borderRadius="10px">
                  <AlertIcon/>
                  {missingAttrText}
                </Alert>
              </Box>
          )}
        </Box>
        }
      </Flex>
      <Flex mb="10px" minW="100%">
        <Box mx="20px" mb="20px">
          <Text fontSize={13}>
            <i>
              Calculations are based on a machine learning (AI) model derived
              from analyzing data of ~260K people of multiple ethnicities and
              includes genetic risk (PRS), age, sex, cholesterol levels, blood
              pressure levels, treatment for high blood pressure, statins treatment, diabetes,
              smoking, alcohol consumption status and various blood measurements. The estimated risk change for coronary artery
              disease is a statistical measurement and reflects the risk change
              in the test population. Coronary artery disease risk depends on
              other factors such as physical activity, stress and other factors
              and therefore the values presented serve as estimations only.
            </i>
          </Text>
          {isYoungerThanMin && (<Text mt="10px" color={COLORS.RED_STATUS} fontSize={13} fontWeight={"bold"}>
            <sup>*</sup>
            <i>
              {youngerParticipant}
            </i>
          </Text>)}
          {isOlderThanMax && (<Text mt="10px" color={COLORS.RED_STATUS} fontSize={13} fontWeight={"bold"}>
            <sup>*</sup>
            <i>
              {olderParticipant}
            </i>
          </Text>)}
        </Box>
      </Flex>
      <Modal
        blockScrollOnMount={false}
        isOpen={isOpen}
        onClose={onClose}
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
      >
        <ModalOverlay />
        <ModalContent color={COLORS.REPORT_TEXT}>
          <ModalBody color={COLORS.REPORT_TEXT}>
            <FormControl>
              <EditablePatientAttributes
                gender={props.gender}
                age={props.currentAge}
                sbp={props.currentSbp}
                dbp={props.currentDbp}
                hdl={props.currentHdl}
                ldl={props.currentLdl}
                tc={props.currentTc}
                bpTreated={props.bpTreated}
                diabetes={props.diabetes}
                smoker={props.smoker}
                riskTrait={null}
                labelPrefix="Current "
                callbackGenderSelected={props.onGenderSelected}
                callbackAgeChange={props.onCurrentAgeChange}
                callbackTcChange={props.onCurrentTcChange}
                callbackHdlChange={props.onCurrentHdlChange}
                callbackLdlChange={props.onCurrentLdlChange}
                callbackSbpChange={props.onCurrentSbpChange}
                callbackDbpChange={props.onCurrentDbpChange}
                callbackSmokerChange={props.onCurrentSmokerChange}
                callbackDiabetesChange={props.onCurrentDiabetesChange}
                callbackBpTreatedChange={props.onCurrentBpTreatedChange}
                onCancel={onClose}
                onApply={onApplyAndClose}
                showAttributeFlags={cadCalculatorUserAttributes}
              />
            </FormControl>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Stack>
  );
}

export default withCadCalculatorEx(CadCalculatorExImpl);
