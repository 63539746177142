// @flow
import React from 'react';

import {Box, Flex, Spacer, Input, Text} from "@chakra-ui/react";
import COLORS from "../../common/colors";
import {TestTube} from "@phosphor-icons/react";
import {phosphorImageComp} from "../../common/image";

export default function DemoOrderTest() {
  return (
		<Flex color={COLORS.REPORT_TEXT} align={"top"}>
			<Box m="10px">
				<Flex align={"center"}>
					<Text  fontSize={17} color={COLORS.REPORT_TEXT} fontWeight={"bold"}>Order test</Text>
					<Spacer/>
					<Box color={COLORS.REPORT_TEXT_GRAY}>
						{phosphorImageComp(TestTube, "20px", "20px")}
					</Box>
				</Flex>
				<Text mt={"15px"} color={COLORS.REPORT_TEXT_GRAY} mb={"10px"}>Fill in the address that the genetic test kit will be sent to</Text>
				<Box color={COLORS.REPORT_TEXT}>
					<Text mt={"15px"} ml={"3px"} fontSize={15}>Address line 1</Text>
					<Input placeholder={'address line 1'} ></Input>
					<Text mt={"15px"} ml={"3px"} fontSize={15}>Address line 2 (optional)</Text>
					<Input placeholder={'address line 2'} ></Input>
					<Text mt={"15px"} ml={"3px"} fontSize={15}>City</Text>
					<Input placeholder={'city'} ></Input>
					<Text mt={"15px"} ml={"3px"} fontSize={15}>State</Text>
					<Input placeholder={'state'} ></Input>
					<Text mt={"15px"} ml={"3px"} fontSize={15}>ZIP/ postcode</Text>
					<Input placeholder={'zip / postcode'} ></Input>
				</Box>
			</Box>
			{/*<Box mt={"110px"} ml={"100px"} >*/}
			{/*	<Box mx={"50px"} mt={"0px"}>*/}
      {/*		<Image src={dnaSampleImage} h="290px" alt=""   />*/}
			{/*	</Box>*/}
			{/*</Box>*/}
		</Flex>
  );
}

