// @flow
import React from 'react';
import {Box, Text, Flex, Spacer} from "@chakra-ui/react";

import "../../dbr";
import ActionButton from "../ActionButton";
import COLORS from "../colors";
import DynamsoftBarcodeReader from "./DynamsoftBarcodeReader";
import withMobile from "../mobile/withMobile";

type Props = {
	title: string,
	barcodeLabel: string,
	onScanned: any,
	onScanning: any,
	isLocalhost: boolean,
	scannedText: string,
	isMobile: boolean

}
type State = {
	barcode: ?string,
	scanning: boolean,
	scanned: boolean
}

class BarcodeReaderWrapper extends React.Component<Props, State> {
  constructor(props: Props) {
		super(props);
    (this: any).startScan = this.startScan.bind(this);
    (this: any).stopScan = this.stopScan.bind(this);
    (this: any).onBarcodeError = this.onBarcodeError.bind(this);
    (this: any).onScannerReady = this.onScannerReady.bind(this);
    (this: any).onDynamsoftBarcodeRead = this.onDynamsoftBarcodeRead.bind(this);

		this.dynamsoft = true;
		this.pScanner = null;
		this.elRef = React.createRef();
		this.licenseKey = this.getLicenseKey();
		this.renderCalled = false;
		this.state = {
			scanning: false,
			barcode: undefined,
			scanned: false
		}
	}
	getLicenseKey() {
		if (this.dynamsoft) {
			return "DLS2eyJoYW5kc2hha2VDb2RlIjoiMTAxNDUyMzcyLVRYbFhaV0pRY205cVgyUmljZyIsIm9yZ2FuaXphdGlvbklEIjoiMTAxNDUyMzcyIiwiY2hlY2tDb2RlIjotMTY5NTQ3ODI4MX0="
		}
		if (this.props.isLocalhost) {
	  	return "AdIy+x7IPHUuMe47LxbkqnUQRdUcP6zCqwphek97W9HsNgWRvhWwvDlX33D/E9Oa631NW5YQugDFQvYbEy6+z1skNVydQNopPnWHgPdgHWnmQSZa5RzFrMIZUvRzLxOgWHPCWeybVJpM0ceD13a/PXpCTddUHqpRtzexjQuVZjZ8czsmKzNhWOK9p/mTml+CYf9+cKQU5TZtbewadw3RQxECRnWEyC2eRMYwkalj6c/7WarSxfJNq7hXlqXAZPvADL0rjYNZz0QxP6TPIfSgDTLqMjioj9eyz/fxIUr4i3Rqp/K9ql/noGb8QErHSpx2WW3rViRzlFG4knc/8vVPCWEsWMDGUBAvAb5NLFJS6kiRa+hyGSgBeQcXQKYXtFoZ9IIUaaMy+AZUxHtG47jRnP/+/RdixKF359AaP0GXSiwqPSfK+gvZ1EaPF1rFpIOPO/C5vaqsSWQUcNcBOZM1Xz4ZyafyhDL7WVo1/mOdqLT4vO/ZHwbjNsuDTHDZfQ9IssxlWyyhfcRItURoAoOGSPCV4HhdY+yXy2OhoeoRBVZFOUzprbHujpBt01YVNmogJxyg1w9XCcyh0gLHC7NL1/C2JDre3z5ofcCckgox3j/O+Cx9hN0f4Uy+Nj/9PwKVj1s/t1lruec9qqsdBunzgVe7la7+Fi99y3OGKH9/3N7vOdaP01NZA9ko2JwSoJjEkPI6IhM8zqf/VcXednXAdorHLgUnXLJpn950z8PCDQI+cyKCTbOnCvsZheBGs+N0qb9+eRGZv0Um63VzZoJQH6admNCcLSZkkP+fbdC9Rg=="
		}
 		return "AXSSqQDIIrj8PZzEoSyKCQA2MPRnE2eCKXNzT+ZtEl4sPJUK1TUbugVK04i7Dy5kkyT4Lvdxl46ycwNyWnMXEOpmz633f1BA9wEo7gxiuXOnes3XSnsDU8MqxlQxUcvIiXsuB7xajTpVQFjLPHdFb4V6EcWRbIQWLUflj9Zh9f4RA8LrL0k86LFN8w/bVNkKLHg7moZ1CRnyOPMhRk9Kt/hZZdvAeQRl2V4uej5l7Gf2bdrR/m96tSZ7lrbAWoUBKVMHv/NM1pOjVw+PEU/zBQ9dOIYlc9p1cXYuzvFh1mlMU2Yd/G4RXWR/im99dT1xrGV+4qNp8VxuQVL8sG2t50Z4oAJeSkXRL3YHsqh836//X9/NglrMgT9Zvqe5ZRJOg0v5fXV8WalASc340nSWUwNG9DUTbDrzUncUZzh6K2o9bgj5TmNk69NB50jLQCYhFVgjyWl9YlVqAAMy3XbbQQJ5jl2oQBXPJkNiGShFMlySfH9iUmnmjXVaZdldKgi2cTGB0ckrGeqKOVs3TEBsKDglbrRM/NOpmsYSFvT9BPn19fdX+gnTNqzlx8du3vSpQBI67qi4RNiiOXucwr6M1E0CePsDjRxdcYNu44uo9/14eLdGi/0aCz8Rf0umcLaFGNhsej089cz8vVPDGqJQnnR1uahT7lX6vwkLXi7P051+kHjSGT1m9tq6p2EEHAMhBJHyJA/xBookp9ZZLr5T8F+ABvhru2qeKxq/ltRh6FUzBLx+yTt6u6SLvphtGF4kOhjyMW+iZvKivPPms/73NzD0nmmOZGC86Ckzw6OmpKBkogcRXvjQsP7po9wPOb8kjKluRTYuXxfzX5fDTIU/1GJ/YHz1oqi4V/DTiKma8wa8X+pxQoomaSgy2iBD8tNgvJX7SC1WDkWkqPAf2TKwWf1zDAZTvBE/EpunwA6PeuPFg3x3hAGSUa+5YiJLPInbfB9t8gL3pa4CaMGMM72mVZ8OzZSMvSrpMX+lXYalMKPL19mb1yQdSZmx+TrE++yg9ddHTRUDGa02eTBOwn+sgqyYfex/whPLIlIIDnzEKOU8bHiWt12YfUGpHu7tUOHMn0WiaRH9XCQaiWO1xydeG1Rnw6DYYmwKhxA7Yt8lz27PGD3svqmpWPyDqfO16WWWuMZazPFs1sAxmWX1FNHHBJ7hQSv+AZtTVUpbxQGErCTAaNQ1YEAU5GZBNcb3eSJc"
	}
	licenseKey: string;
	renderCalled: boolean;
	dynamsoft: boolean;
  pScanner: any;
	elRef: any;

	startScan()	{
		this.setState({scanning: true, scanned: false});
		if (this.props.onScanning) this.props.onScanning(true);
	}
	stopScan() {
		this.setState({scanning: false});
		if (this.props.onScanning) this.props.onScanning(false);
	}

	onDynamsoftBarcodeRead(code: string) {
		this.setState({barcode: code, scanning: false, scanned: true});
		if (this.props.onScanned) {
			this.props.onScanned(code);
		}
	}

	onBarcodeError(error: string) {
		console.log(error);
	}

	onScannerReady() {
		console.log("scanner ready");
	}
	render() {
		const {isMobile} = this.props;

		const scannerWidth = isMobile ? `${(window.screen.width * 0.8).toFixed(0)}px` : "400px";
		const scannerHeight = isMobile ? `${(window.screen.width * 0.8 / 1.777).toFixed(0)}px` : "300px";
		return (
				<Box color={COLORS.REPORT_TEXT}>
					{this.props.title && <Text fontSize={20} color={COLORS.REPORT_TEXT_GRAY} fontWeight={"bold"}>{this.props.title}</Text>}
					<Flex my={isMobile ? "20px" : "30px"}>
						<ActionButton onClick={this.stopScan} name="Stop Scanning"  isDisabled={!this.state.scanning} ml={"20px"}/>
						<ActionButton onClick={this.startScan} name="Start Scanning" isDisabled={this.state.scanning}/>
					</Flex>
					<Box >
						{this.dynamsoft && (
								<Box w={scannerWidth} h={scannerHeight} >
									{this.state.scanning && <DynamsoftBarcodeReader onScanning={this.props.onScanning} onScanned={this.onDynamsoftBarcodeRead} isMobile={isMobile}/>}
									{!this.state.scanning && (
										<Box borderWidth={1} borderColor={"gray.100"} h={"100%"} bg={"gray.50"}>
											<Flex align={"center"} mx={"2%"} my={"3%"} fontSize={13}>
												<Spacer/>
												{!this.state.scanned && <Text w={"80%"}>Click on 'Start Scanning' to open the camera and scan the barcode.<br/><br/>Make sure that the camera view points to the barcode.</Text>}
												{this.state.scanned && <Text color={COLORS.GREEN_STATUS} w={"80%"}>{this.props.scannedText}</Text>}
												<Spacer/>
											</Flex>
										</Box>
									)}
								</Box>
						)}
						<Flex my={"20px"} minH={"36px"} align={"center"} maxW={"400px"}>
							<Text minW="15%" color={COLORS.REPORT_TEXT_GRAY}>{this.props.barcodeLabel ?? 'Barcode:'}</Text>
							<Text px="10px" pt={"4px"} fontSize={18} fontWeight={"bold"} minH="36px" borderColor={"gray.100"} borderWidth={1} minW="82%" ml={"3%"}>
								{this.state.barcode}
							</Text>
						</Flex>
					</Box>
				</Box>
		);
	}
}

export default withMobile(BarcodeReaderWrapper);