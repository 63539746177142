// @flow
import React from 'react';
import { Box, Link, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import COLORS from '../common/colors';
import IconTextAndLink from '../common/IconTextAndLink';
import dietImage from '../images/diet.png';
import runningImage from '../images/running.png';
import noSmokingImage from '../images/no-smoking.png';
import alcoholImage from '../images/alcohol.png';

type Props = {
  isMobile: boolean
};

export default function HypercholesterolRecommendationSection(props: Props) {
  return (
    <Box
      sx={{
        '@media print': {
          pageBreakBefore: !props.isMobile ? 'always' : 'auto'
        }
      }}
    >
      <Text mt="40px" color={COLORS.REPORT_TEXT} fontWeight="semibold">
        To reduce risk for having hypercholesterolemia you can refer to the
        following:
      </Text>
      <Link
        color="teal.500"
        href="https://www.heart.org/en/health-topics/cholesterol/prevention-and-treatment-of-high-cholesterol-hyperlipidemia"
        isExternal
        mb="20px"
      >
        https://www.heart.org/en/health-topics/cholesterol/prevention-and-treatment-of-high-cholesterol-hyperlipidemia
      </Link>
      <IconTextAndLink
        imageSrc={dietImage}
        text="Diet"
        linkText="Preserve a heart-healthy diet"
        link="https://www.heart.org/en/healthy-living/healthy-eating/losing-weight"
        isMobile={props.isMobile}
      />
      <IconTextAndLink
        imageSrc={runningImage}
        text="Exercise"
        linkText="Recommendations for physical activity"
        link="https://www.heart.org/en/healthy-living/fitness/fitness-basics/aha-recs-for-physical-activity-in-adults"
        isMobile={props.isMobile}
      />
      <IconTextAndLink
        imageSrc={noSmokingImage}
        text="Smoking cessation"
        linkText="Quit smoking, vaping and tobacco use"
        link="https://www.heart.org/en/healthy-living/healthy-lifestyle/quit-smoking-tobacco"
        isMobile={props.isMobile}
      />
      <IconTextAndLink
        imageSrc={alcoholImage}
        text="Limited alcohol"
        linkText="Limit alcohol consumption"
        link="https://www.wcrf-uk.org/preventing-cancer/our-cancer-prevention-recommendations/limit-alcohol-consumption/"
        isMobile={props.isMobile}
      />
    </Box>
  );
}

HypercholesterolRecommendationSection.propTypes = {
  isMobile: PropTypes.bool
};

HypercholesterolRecommendationSection.defaultProps = {
  isMobile: false
};
