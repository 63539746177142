// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { Spacer, Flex, Image } from '@chakra-ui/react';

type Props = {
  image: any,
  marginTop?: number,
  marginBottom?: number
};

export default function HomeImagePane(props: Props) {
  return (
    <Flex mt={props.marginTop} mb={props.marginBottom}>
      <Spacer />
      <Image src={props.image} />
      <Spacer />
    </Flex>
  );
}

HomeImagePane.propTypes = {
  marginTop: PropTypes.number,
  marginBottom: PropTypes.number
};

HomeImagePane.defaultProps = {
  marginTop: 50,
  marginBottom: 50
};
