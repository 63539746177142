// @flow
export const userAttributesFlags = {
  gender: 1,
  age: 2,
  bmi: 4,
  tc: 8,
  hdl: 16,
  sbp: 32,
  dbp: 64,
  a1c: 128,
  bpTreated: 256,
  diabetes: 512,
  fhDiabetes: 1024,
  smoker: 2048,
  name: 4096,
  kitId: 8192,
  ldl: 16384,
  num: 32768,
  birthYear: 65536,
  birthMonth: 131072,
  feet: 262144,
  inch: 524288,
  weight: 1048576
};

/* eslint-disable no-bitwise */
export const allUserAttributes: number =
  userAttributesFlags.gender |
  userAttributesFlags.age |
  userAttributesFlags.bmi |
  userAttributesFlags.tc |
  userAttributesFlags.hdl |
  userAttributesFlags.sbp |
  userAttributesFlags.dbp |
  userAttributesFlags.a1c |
  userAttributesFlags.bpTreated |
  userAttributesFlags.diabetes |
  userAttributesFlags.fhDiabetes |
  userAttributesFlags.smoker;

/* eslint-disable no-bitwise */
export const t2dCalculatorUserAttributes: number =
  userAttributesFlags.gender |
  userAttributesFlags.age |
  userAttributesFlags.bmi |
  userAttributesFlags.tc |
  userAttributesFlags.hdl |
  userAttributesFlags.sbp |
  userAttributesFlags.dbp |
  userAttributesFlags.a1c |
  userAttributesFlags.bpTreated |
  userAttributesFlags.fhDiabetes |
  userAttributesFlags.smoker;

/* eslint-disable no-bitwise */
export const cadCalculatorUserAttributes: number =
  userAttributesFlags.gender |
  userAttributesFlags.age |
  userAttributesFlags.ldl |
  userAttributesFlags.hdl |
  userAttributesFlags.sbp |
  userAttributesFlags.dbp |
  userAttributesFlags.bpTreated |
  userAttributesFlags.diabetes |
  userAttributesFlags.smoker;

/* eslint-disable no-bitwise */
export const bpCalculatorUserAttribute: number =
  userAttributesFlags.gender |
  userAttributesFlags.age |
  userAttributesFlags.bmi;

/* eslint-disable no-bitwise */
export const hypercholesterolCalculatorUserAttribute: number =
  userAttributesFlags.gender |
  userAttributesFlags.age;


/* eslint-disable no-bitwise */
export const defaultCancerCalculatorUserAttribute: number =
  userAttributesFlags.gender |
  userAttributesFlags.age;


/* eslint-disable no-bitwise */
export const accCalculatorUserAttribute: number =
  userAttributesFlags.gender |
  userAttributesFlags.age |
  userAttributesFlags.sbp |
  userAttributesFlags.tc |
  userAttributesFlags.hdl |
  userAttributesFlags.tc |
  userAttributesFlags.diabetes |
  userAttributesFlags.smoker |
  userAttributesFlags.bpTreated;


/* eslint-disable no-bitwise */
export const minCalculatorUserAttributes: number =
  userAttributesFlags.gender | userAttributesFlags.age;

export function getComparativeRiskText(oddsFactor: number) {
  if (oddsFactor < 1) {
    return `${((1 - oddsFactor) * 100).toFixed(
      0
    )}% lower than the average population.`;
  }
  if (oddsFactor > 1) {
    if (oddsFactor > 2) {
      return `${oddsFactor.toFixed(
        2
      )} times higher than the average population.`;
    }
    return `${((oddsFactor - 1) * 100).toFixed(
      0
    )}% higher than the average population.`;
  }
  return 'similar to the average population.';
}

export function getShortComparativeRiskText(oddsFactor: number) {
  if (oddsFactor < 1) {
    return `${((1 - oddsFactor) * 100).toFixed(0)}% lower than average.`;
  }
  if (oddsFactor > 1) {
    if (oddsFactor > 2) {
      return `${oddsFactor.toFixed(2)} times higher than average.`;
    }
    return `${((oddsFactor - 1) * 100).toFixed(0)}% higher than average.`;
  }
  return 'average.';
}

export function getShortComparativeOddsRatioText(oddsRatio: number) {
  if (oddsRatio < 1) {
    if (`-${((1 - oddsRatio) * 100).toFixed(0)}%` === '-100%') return `~ -100%`;
    return `-${((1 - oddsRatio) * 100).toFixed(0)}%`;
  }
  if (oddsRatio > 1) {
    if (oddsRatio > 2) {
      return `x ${oddsRatio.toFixed(2)}`;
    }
    return `+${((oddsRatio - 1) * 100).toFixed(0)}%`;
  }
  return '';
}

export function getSummaryOddsRatioText(oddsRatio: number) {
  if (oddsRatio < 1) {
    if (`${((1 - oddsRatio) * 100).toFixed(0)}%` === '100%')
      return `by almost 100%`;
    return `by ${((1 - oddsRatio) * 100).toFixed(0)}%`;
  }
  if (oddsRatio > 1) {
    if (oddsRatio > 2) {
      return `by a factor of ${oddsRatio.toFixed(2)}`;
    }
    return `by ${((oddsRatio - 1) * 100).toFixed(0)}%`;
  }
  return '';
}

export function getFileNameOnly(filePath: string) {
  return filePath
    .split('/')
    .pop()
    .split('.')
    .shift();
}

export function getRiskText(quantile: number, numQuantiles: number = 4) {
  if (quantile === 0) return 'Low';
  if (quantile < numQuantiles - 1) return 'Moderate';
  return 'High';
}

export const agePopInfoTexts = {
  "header": "Age range limitation",
  "text": "This risk assessment model is based on data of participants of age range 38-73 and therefore can only assess the risk using an age within this range. As risk is associated with ageing, a participant younger than 38 will have lower risk than the assessed and an participant older than 73 will have higher risk than the assessed.",
  "footer": "NOTE: age range limitation"
}

export const youngerParticipant = "This risk assessment model is based on data of participants of age range 38-73 and therefore can assess the risk using an age within this range. The assessed risk is based on the minimum age (38). As risk is associated with ageing, a younger participant will have lower risk than the assessed."
export const olderParticipant = "This risk assessment model is based on data of participants of age range 38-73 and therefore can assess the risk using an age within this range. The assessed risk is based on the maximum age (73). As risk is associated with ageing, an older participant will have higher risk than the assessed."

export const agePopInfoTextsHtn = {
  "header": "Age range limitation",
  "text": "This model is based on data of participants of age range 38-73 and therefore can only assess the levels using an age within this range.",
  "footer": "NOTE: age range limitation"
}

export const youngerParticipantHtn = "This model is based on data of participants of age range 38-73 and therefore can assess the blood pressure levels using an age within this range. The assessed blood pressure levels are based on the minimum age (38). A participant younger than 38 is expected to have lower blood pressure levels than the assessed."
export const olderParticipantHtn = "This model is based on data of participants of age range 38-73 and therefore can assess the blood pressure levels using an age within this range. The assessed blood pressure levels are based on the maximum age (73)). A participant older than 73 is expected to have higher systolic blood pressure than the assessed."


