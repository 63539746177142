/* eslint-disable react/prop-types */
import React from 'react';

import { getPatient, unionPatientVisit } from '../api/capilots';
import { getPatientId } from './capilots-utils';
import getPatientAge from '../utils/age';

type Props = {};

type State = {
  visit: ?Object
};

export default function withPilotPatientVisit(WrappedComponent) {
  class WithPilotPatientVisitContainer extends React.Component<Props, State> {
    /* eslint-disable no-unused-vars */
    constructor(props) {
      super(props);
      (this: any).onReload = this.onReload.bind(this);
      this.state = {
        visit: undefined
      };
    }
    async componentDidMount() {
      await this.loadAndSetPatientVisit();
    }

    async onReload() {
      await this.loadAndSetPatientVisit();
    }

    async loadAndSetPatientVisit() {
      const patientId = this.props.patientId ?? getPatientId(this.props.params, this.props.location);
      const unionVisit = await unionPatientVisit(patientId);
      const patient = await getPatient(patientId);
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({
        visit: {
          ...patient.data,
          ...unionVisit.data.visit,
          age: getPatientAge(patient.data.birth_year, patient.data.birth_month)
        }
      });
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          visit={this.state.visit}
          age={this.state.visit ? this.state.visit.age : undefined}
          onReload={this.onReload}
        />
      );
    }
  }
  return React.forwardRef((props, ref) => (
    <WithPilotPatientVisitContainer {...props} ref={ref} />
  ));
}
